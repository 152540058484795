import { Component, Input, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";




@Component({

  selector: "CorporateviewComponent",

  template: `

 <mat-dialog-content>

 <h2 mat-dialog-title style="margin: 0px 0px 0px 10px !important;">Corporate Brand</h2>

 <div id="Customercustomergroup">

 <div class="mat-table-container">

  <table class="full-width-table">

   <thead>

    <tr style="color:#03538B ;background-color: #dfdfdfdf !important;text-align: left;">

     <th style="padding:10px 20px 10px 20px;">Corporate Brand Code</th>

     <th style="padding:10px 20px 10px 20px;">Corporate Brand</th>

     <th style="padding:10px 20px 10px 20px;">Sort Order</th>

     <th style="padding:10px 20px 10px 20px;">Updated Date</th>

    </tr>

   </thead>

   <tbody class="mat-grid-tile scroller" Id="tblscroll">

  

     <td style="padding:10px 20px 10px 20px;border-bottom: 0.5px solid #dfdfdfdf;">

     {{rowData.corporateBrandCode}}

     </td>

     <td style="padding:10px 20px 10px 20px;border-bottom: 0.5px solid #dfdfdfdf;">

    {{rowData.corporateBrandName}}

     </td>

     <td style="padding:10px 20px 10px 20px;border-bottom: 0.5px solid #dfdfdfdf;">

     {{rowData.corporateBrandSortOrder}}

     </td>

     <td style="padding:10px 20px 10px 20px;border-bottom: 0.5px solid #dfdfdfdf;">

     {{rowData.lastUpdatedTime | customDateTime}}

     </td>

<tbody>

  </table>

 </div>

</div> 
<br>
<mat-checkbox [disableRipple]="true"
(click)="$event.preventDefault()" 
 id="inpactive" [checked]="rowData.active" class="example-margin width-20 ml-2" color='primary'>Active</mat-checkbox>
 <mat-checkbox  [disableRipple]="true"
 (click)="$event.preventDefault()" id="inpdraft" [checked]="rowData.draft"
 class="example-margin width-12  ml-2" color='primary'>Draft</mat-checkbox>
 </mat-dialog-content>

 <mat-dialog-actions align="end">

  <button mat-button mat-dialog-close>Cancel</button>

 </mat-dialog-actions>

 `

})

export class CorporateviewComponent {

  rowData: any;

  constructor(

    private _mdr: MatDialogRef<CorporateviewComponent>,

    @Inject(MAT_DIALOG_DATA) data: any

  ) {

    this.rowData = data.rowData;

  }

  CloseDialog() {

    this._mdr.close(false)

  }

}
