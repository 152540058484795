<div class="fixed-content mat-elevation-z2 form-border" id="scrolltop">
  <form [formGroup]="_garmentgroupform" id="garmentgroupform">
    <section class="form-section">
      <h2 mat-dialog-title class="align">{{isgarmentGroupEdit === true ? 'Update' : 'New'}} Garment Group</h2>
    </section>
    <div class="example-container add-form-field ">
      <mat-form-field [ngClass]="(isgarmentGroupEdit === true) ? 'displaycls':'hiddedencls'" appearance="legacy">
        <mat-label>Garment Group Code</mat-label>
        <input id="inpgarmentGroupCode" formControlName="garmentGroupCode" [(ngModel)]="updategarmentGroupForm.garmentGroupCode" matInput readonly>

      </mat-form-field>
      <mat-form-field appearance="legacy">
        <mat-label>Garment Group</mat-label>
        <input id="inpgarmentgroupname" [(ngModel)]="updategarmentGroupForm.garmentGroupName" formControlName="garmentGroupName" matInput>
        <mat-error *ngIf="_garmentgroupform.controls['garmentGroupName'].errors?.maxlength">Maximum 50 Characters allowed</mat-error>
      </mat-form-field>
      <br />
      <mat-form-field appearance="legacy">
        <mat-label>Sort Order</mat-label>
        <input id="inpSortOrder" formControlName="garmentGroupSortOrder" [(ngModel)]="updategarmentGroupForm.garmentGroupSortOrder" min="0" matInput type="number" pattern="^(0|[1-9][0-9]*)$">
        <mat-error *ngIf="_garmentgroupform.controls['garmentGroupSortOrder'].errors?.max">The range for sort order is 1-9999</mat-error>
        <mat-error *ngIf="_garmentgroupform.controls['garmentGroupSortOrder'].errors?.pattern">Only Numeric Values allowed</mat-error>

      </mat-form-field>
      <br />
      <section class="example-section">
        <mat-checkbox [disabled]="isActiveDisable" id="inpactive" formControlName="active" [(ngModel)]="updategarmentGroupForm.active" [checked]="true" class="example-margin width-25" color='primary'
                      [disabled]="true">Active</mat-checkbox>
        <mat-checkbox id="inpdraft" formControlName="draft" [(ngModel)]="updategarmentGroupForm.draft"
                      class="example-margin width-15" color='primary'>
          Draft
        </mat-checkbox>
      </section>
      <input type="hidden" id="inpdepartmentid" formControlName="departmentId" value="">
      <input type="hidden" id="inpgarmentgroupid" formControlName="garmentGroupId" [(ngModel)]="updategarmentGroupForm.garmentGroupId">
      <br>
      <div>
        <app-multiselect #multiselect id="multiselect" *ngIf="multiselectenable" [datacollection]=departmentFilterList>   </app-multiselect> &nbsp;
        <button id="inpdepartmentadd" class="mat-raised-button mat-primary  mp-2" (click)="onAddRow()">Add</button>
        <div [ngClass]="(isAddRow === true) ? 'displaycls':'hiddedencls'" class="mat-elevation-z8 data-table">
          <div class="mat-table-container ">
            <table id="tblgarmentwithdepartment" [dataSource]="dataSource" mat-table class="full-width-table" matSort aria-label="Elements">
              <ng-container matColumnDef="department">
                <th mat-header-cell *matHeaderCellDef id="departmentlist">Department</th>
                <td mat-cell *matCellDef="let row" Id="st">
                  {{row.departmentCode}}-{{row.departmentName}}
                </td>
              </ng-container>
             <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="8" style="text-align: center;">No records found</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <br>
      <mat-dialog-actions *ngIf="userrole" style="display: block !important">
        <button [disabled]="(isgarmentGroupEdit === true) ?  !_garmentgroupform.dirty : false" [ngClass]="(isgarmentGroupEdit === true) ? 'mat-raised-button ml-1':'mat-raised-button ml-1 mat-primary'" id="btnSaveUpdate"
                type="submit" (click)="onSubmit()">
          {{isgarmentGroupEdit === true ? 'Update' : 'Save'}}
        </button>
        <button id="btnCancel" class="mat-raised-button ml-1 mat-primary" (click)="onClear()">Cancel</button>
      </mat-dialog-actions>
      <span *ngIf="!userrole" style="color: #ff0202;font-size: 12px;font-weight: 400;">You can't save changes since you have Read access.</span>
    </div>
  </form>
</div>
<style>
  .mat-raised-button.ml-1:not([disabled]) {
    color: #FFFFFF;
    background-color: #00558d !important
  }
</style>

