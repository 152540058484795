import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonUtilityService } from 'src/app/services/organization/commonutility/commonutility.service';
import { DepartmentService } from 'src/app/services/organization/department/department.service';
import { MarkupGroupService } from 'src/app/services/organization/department/markupgroup.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { DialogBoxService } from 'src/app/shared/services/dialog-box.service';
import { AccountService } from '../../../../../shared/services/account.service'

@Component({
  selector: 'department-markupgroupform',
  templateUrl: './markupgroupform.component.html',
  styleUrls: ['./markupgroupform.component.css']
})
export class DepartmentmarkupgroupformComponent implements OnInit {

  @Input() parentCorporateBrandName: string;
  @Input() parentDepartmentId;
  @Input() depMarkupGroupList;
  @Input() markupSeaasonlist;
  @Input() seasonList;
  @Input() markupGroupList;
  @Input() departmentDraft;
  @Output() markupgroupcall = new EventEmitter();
  startSeasonMasterlist: any[] = [];
  startSeasonFilterList: any[] = [];
  endSeasonMasterlist: any[] = [];
  endSeasonFilterList: any[] = [];
  markupGroupMasterlist: any[] = [];
  markupGroupFilterList: any[] = [];
  updateDepMarkupGroupForm: any;
  isEditDepMarkupGroup = false;
  isDeleteDepMarkupGroup = false;
  dptmentMarkupGroupId: any;
  validationMessages: any;
  depMarkupGroupForm = false;
  validOptions;
  subscription: Subscription;
  userrole: boolean;
  constructor(public _departmentService: DepartmentService,
    public _departmentMarkupGroupService: MarkupGroupService,
    public _commonutilityService: CommonUtilityService,
    private toastr: ToastrService,
    public _dialogService: DialogBoxService, 
    public account: AccountService,
    private applicationInsightService:ApplicationInsightService) { }

  ngOnInit(): void {
    this.applicationInsightService.logEvent("Department markupgroup form Initialization started")
    this.account.getUserInfo();
    let list = this.account.userInfo.Role;
    let rolelist = list.split(",");

    this.userrole = rolelist.length <= 1 ? rolelist[0] == 'sds.Reader' ? true : false : false;
    if (rolelist.length > 1) {
      for (let i = 0; i < rolelist.length; i++) {
        if (rolelist[i] != 'sds.Reader') {
          this.userrole = false;
        }
      }
    }
    this.dptMarkupGroupForm.controls.lastUpdatedBy.setValue(this.account.userInfo.Email);
    this.updateDepMarkupGroupForm = Object.assign({});
    this.validOptions = this.fnGetRangeValues();
    this.subscription = this._departmentMarkupGroupService.departmentMarkupGroupId$.subscribe(
      departmentMarkupGroupInfoId => {
        this.fnUpdateDepartmentMarkupGroup(departmentMarkupGroupInfoId)
      });
    this.validationMessages = this._commonutilityService.validationMsgs;
    this.applicationInsightService.logEvent("Department markupgroup form Initialization ended")
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }
  ngOnChanges(changes: SimpleChanges) {
    this.applicationInsightService.logEvent("Department markupgroup  form ngOnChanges started")
    this.startSeasonMasterlist = this.startSeasonFilterList = this.endSeasonMasterlist = this.endSeasonFilterList = this.markupSeaasonlist;
    this.validOptions = this.fnGetRangeValues();
    if (this.parentDepartmentId != undefined && this.parentDepartmentId != 0)
    this.depMarkupGroupForm = true;
    if (this.markupGroupList != undefined && this.markupGroupList != null && this.markupGroupList.length > 0) {   
    this.markupGroupMasterlist = this.markupGroupFilterList = this.markupGroupList.sort((a, b) => (a.markupGroupCode > b.markupGroupCode ? 1 : -1));
    
    }
    this.onClear()
    this.applicationInsightService.logEvent("Department markupgroup  form ngOnChanges ended")
    }

  displayTextSeason(season: string) {
    this.applicationInsightService.logEvent("Department markupgroup displayTextSeason started")
    if (season == null || season == "" || season.includes("99"))
      return null;
    else if (this.startSeasonMasterlist != undefined && season != null)
      return this.startSeasonMasterlist.find(x => x.seasonNumber === season).displayName;
      this.applicationInsightService.logEvent("Department markupgroup displayTextSeason ended")
  }
  filterStartSeason(filterKeySS: string) {
    this.applicationInsightService.logEvent("Department markupgroup filterStartSeason started")
    if (filterKeySS != null && filterKeySS != "")
      this.startSeasonFilterList = this.startSeasonMasterlist.filter(rs => rs.displayName.toLowerCase().includes(filterKeySS.toString().toLowerCase()))
    else
      {this.startSeasonFilterList = this.startSeasonMasterlist;}
      this.applicationInsightService.logEvent("Department markupgroup filterStartSeason ended")
  }
  filterEndSeason(filterKeyES: string) {
    this.applicationInsightService.logEvent("Department markupgroup filterEndSeason started")
    if (filterKeyES != null && filterKeyES != "")
      this.endSeasonFilterList = this.endSeasonMasterlist.filter(rs => rs.displayName.toLowerCase().includes(filterKeyES.toString().toLowerCase()))
    else
      {this.endSeasonFilterList = this.endSeasonMasterlist;}
      this.applicationInsightService.logEvent("Department markupgroup filterEndSeason ended");
  }
  displayTextMG(markGroupId: string) {
    if (markGroupId == null || markGroupId == "")
      return null;
    else if (this.markupGroupMasterlist != undefined && markGroupId != null)
      var mgConcate = this.markupGroupMasterlist.find(x => x.markupGroupId === markGroupId);
    if (mgConcate != null)
      return mgConcate.markupGroupCode + '-' + mgConcate.markupGroupName;
    return null;
  }
  filterItemMG(filterKeyMG: string) {
    this.applicationInsightService.logEvent("Department markupgroupCode filter started");
    if (filterKeyMG != null && filterKeyMG != "") {
      this.markupGroupFilterList = this.markupGroupMasterlist.filter(mg => (mg.markupGroupCode + '-' + mg.markupGroupName).toString().toLowerCase().includes(filterKeyMG.toString().toLowerCase()))
      this.markupGroupFilterList = this.markupGroupFilterList.sort((a, b) => (a.markupGroupCode > b.markupGroupCode ? 1 : -1));
    } else
      {this.markupGroupFilterList = this.markupGroupMasterlist;}
      this.applicationInsightService.logEvent("Department markupgroupCode filter ended");
  }
  onSubmit() {
    this.applicationInsightService.logEvent("Department markupgroup form submit started");
    console.log("DEP Markup Group BEFORE:" + JSON.stringify(this.dptMarkupGroupForm.value))
    if (this.dptMarkupGroupForm.controls.endSeason.value == "")
      this.dptMarkupGroupForm.controls.endSeason.setValue(null);
    if (this.dptMarkupGroupForm.controls.departmentId.value === undefined
      || this.dptMarkupGroupForm.controls.departmentId.value === null
      || this.dptMarkupGroupForm.controls.departmentId.value === "") { this.dptMarkupGroupForm.controls.departmentId.setValue(this.parentDepartmentId); }
    this.dptMarkupGroupForm.controls.startSeason.setValidators([this.acStartSeasonValidator("Save"), Validators.required]);
    this.dptMarkupGroupForm.controls.departmentDraft.setValue(this.departmentDraft);
    this.dptMarkupGroupForm.controls.markupGroupId.updateValueAndValidity();
    this.dptMarkupGroupForm.controls.startSeason.updateValueAndValidity();
    this.dptMarkupGroupForm.controls.endSeason.updateValueAndValidity();
    console.log("DEPARTMENT Save: " + JSON.stringify(this.dptMarkupGroupForm.value))
    /* istanbul ignore if */
    if (this.dptMarkupGroupForm.valid) {
      console.log("DEP Markup Group After:" + JSON.stringify(this.dptMarkupGroupForm.value))
      if (this.dptMarkupGroupForm.value.departmentMarkupGroupInfoId == undefined || this.dptMarkupGroupForm.value.departmentMarkupGroupInfoId == 0 || this.dptMarkupGroupForm.value.departmentMarkupGroupInfoId == null) {
        this.dptMarkupGroupForm.controls.departmentMarkupGroupInfoId.setValue(0);
        this._departmentMarkupGroupService.updateDepartmentMarkupGroup(this.dptMarkupGroupForm.value).subscribe
          (data => {
            this.markupgroupcall.emit();
            this.toastr.success(data.message, "", { timeOut: 10000 });
            this.onClear();
          },
            error => { this.toastr.error(error, "", { timeOut: 10000 }) })
      }
      else {
        this._departmentMarkupGroupService.updateDepartmentMarkupGroup(this.dptMarkupGroupForm.value).subscribe
          (data => {
            this.markupgroupcall.emit();
            this.toastr.success(data.message, "", { timeOut: 10000 });
            this.onClear();
          },
            error => { this.toastr.error(error, "", { timeOut: 10000 }) })
      }
    }
    this.applicationInsightService.logEvent("Department markupgroup form submit ended");
  }
 
  fnUpdateDepartmentMarkupGroup(departmentMarkupGroupInfoId: any) {
    this.applicationInsightService.logEvent("UpdateDepartmentMarkupGroup started");
    this.fnSeasonControlClear();
    console.log("Dep Markup Group ID: " + departmentMarkupGroupInfoId);
    this.dptMarkupGroupForm.controls.departmentMarkupGroupInfoId.setValue(departmentMarkupGroupInfoId);
    this.dptmentMarkupGroupId = departmentMarkupGroupInfoId;
    this.isEditDepMarkupGroup = true;
    /* istanbul ignore next */
    this._departmentMarkupGroupService.getDepartmentMarkupGroupById(departmentMarkupGroupInfoId).subscribe((data: any) => {
      this.updateDepMarkupGroupForm = data.departmentMarkUpGroup[0];
      this._departmentService.getSeasonAll().subscribe((data: any) => {

        let foundStart = this.startSeasonMasterlist.some(el => el.seasonNumber === this.updateDepMarkupGroupForm.startSeason);
        if (!foundStart)
          this.startSeasonFilterList = this.startSeasonMasterlist = data.seasonByRange.filter(x => x.seasonNumber >= this.updateDepMarkupGroupForm.startSeason);

        this.dptMarkupGroupForm.controls.startSeason.setValue(this.updateDepMarkupGroupForm.startSeason);
        this.endSeasonFilterList = data.seasonByRange.filter(x => x.seasonNumber >= this.updateDepMarkupGroupForm.endSeason);
        this.dptMarkupGroupForm.controls.endSeason.setValue(this.updateDepMarkupGroupForm.endSeason);
        let foundEnd = this.startSeasonMasterlist.some(el => el.seasonNumber === this.updateDepMarkupGroupForm.startSeason);
        if (this.endSeasonFilterList.length == 0 || foundEnd)
          this.endSeasonFilterList = this.endSeasonMasterlist;
        else
          this.endSeasonFilterList = this.endSeasonMasterlist = data.seasonByRange.filter(x => x.seasonNumber >= this.updateDepMarkupGroupForm.endSeason);

      });
      this.dptMarkupGroupForm.controls.startSeason.setValidators([this.acStartSeasonValidator('Edit'), Validators.required]);
      this.isDeleteDepMarkupGroup = false;
      if (new Date(this.updateDepMarkupGroupForm.startSeason.substring(0, 4) + "-" + this.updateDepMarkupGroupForm.startSeason.slice(-2) + "-01") >= new Date(new Date().getFullYear() + "-" + new Date().getMonth() + "-01")) { this.isDeleteDepMarkupGroup = true; }
      else if (this.updateDepMarkupGroupForm.endSeason == null || this.updateDepMarkupGroupForm.endSeason == '') { this.isDeleteDepMarkupGroup = true; }
      else if ((new Date(this.updateDepMarkupGroupForm.startSeason.substring(0, 4) + "-" + this.updateDepMarkupGroupForm.startSeason.slice(-2) + "-01")) <= new Date() && data.departmentMarkUpGroup[0].endSeason != '' && data.departmentMarkUpGroup[0].endSeason != null && (new Date(data.departmentMarkUpGroup[0].endSeason.substring(0, 4) + "-" + data.departmentMarkUpGroup[0].endSeason.slice(-2) + "-01")) >= new Date()) { this.isDeleteDepMarkupGroup = true; }
      this.fnGetRangeValues();
    }),
      error => { this.toastr.error(error, "", { timeOut: 10000 }); };
      this.applicationInsightService.logEvent("UpdateDepartmentMarkupGroup ended");
  }

  deleteDepMarkupGroup() {
    this.applicationInsightService.logEvent("DeleteDepMarkupGroup started");
     this.dptMarkupGroupForm.controls.departmentId.setValue(this.parentDepartmentId);
     this.dptMarkupGroupForm.controls.departmentDraft.setValue(this.departmentDraft);
     /* istanbul ignore next */
     this._dialogService.openConfirmDialog('Are you sure you want to delete this record?')
      .afterClosed().subscribe(res => {
        if (res) {
          this._departmentMarkupGroupService.DeleteDepartmentMarkupGroup(this.dptMarkupGroupForm.value).subscribe
            (data => {
              this.markupgroupcall.emit();
              this.toastr.success(data.message, "", { timeOut: 10000 });
              this.onClear();
            },
              error => { this.toastr.error(error, "", { timeOut: 10000 }) });
        }
      })
      this.applicationInsightService.logEvent("DeleteDepMarkupGroup ended");
  }

  onClear() {
    this.applicationInsightService.logEvent("Department markupgroup form clear started");
    this.isEditDepMarkupGroup = false;
    this.isDeleteDepMarkupGroup = false;
    this.dptMarkupGroupForm.controls.markupGroupId.clearValidators();
    this.dptMarkupGroupForm.controls.departmentMarkupGroupInfoId.setValue('');
    this.dptMarkupGroupForm.controls.departmentId.setValue('');
    this.dptMarkupGroupForm.controls.markupGroupId.setValue('');
    this.dptMarkupGroupForm.controls.markupGroupId.setValidators([this._commonutilityService.acObjectValidator(), Validators.required]);
    this.fnSeasonControlClear();
    this.applicationInsightService.logEvent("Department markupgroup form clear ended");
  }

  fnSeasonControlClear() {
    this.applicationInsightService.logEvent("Department markupgroup fnSeasonControlClear started");
    this.dptmentMarkupGroupId = undefined;
    this.dptMarkupGroupForm.controls.startSeason.clearValidators();
    this.dptMarkupGroupForm.controls.endSeason.clearValidators();
    this.dptMarkupGroupForm.controls.startSeason.setValue(null);
    this.dptMarkupGroupForm.controls.endSeason.setValue(null);
    this.dptMarkupGroupForm.controls.startSeason.setValidators([this.acStartSeasonValidator("New"), Validators.required]);
    this.dptMarkupGroupForm.controls.endSeason.setValidators([this.acEndSeasonValidator()]);
    this.applicationInsightService.logEvent("Department markupgroup fnSeasonControlClear ended");
  }

  dptMarkupGroupForm: FormGroup = new FormGroup({
    departmentMarkupGroupInfoId: new FormControl(''),
    departmentId: new FormControl(''),
    markupGroupId: new FormControl('', [this._commonutilityService.acObjectValidator(), Validators.required]),
    startSeason: new FormControl(null, [this.acStartSeasonValidator("New"), Validators.required]),
    endSeason: new FormControl(null, [this.acEndSeasonValidator()]),
    lastUpdatedBy: new FormControl(''),
    departmentDraft: new FormControl(''),
  })

  fnGetRangeValues() {
    let validOptions: any[] = [];
    if (this.seasonList != undefined && this.seasonList != null) {
      this.seasonList.forEach(x => {
        if (x.seasonNumber != null) {
          validOptions.push(x.seasonNumber.toString());
        }
      })
    }
    return (this.seasonList != undefined && this.seasonList != null) ? validOptions : undefined;
  }
/* istanbul ignore next */
  acStartSeasonValidator(mode: string): ValidatorFn {
   return (control: AbstractControl): { [key: string]: any } | null => {
    this.applicationInsightService.logEvent("Department markupgroup StartSeasonValidator started");
      if (control.value != null && control.value != ""
        && control.value != undefined && this.validOptions != null
        && this.validOptions != undefined && this.validOptions.indexOf(control.value.toString()) !== -1) {
        if (this.startSeasonOverLap(control.value, mode) == true) {
          return { 'markupGroupOverLappingSeason': { value: control.value } };
        }
        if (this.getMaxStartSeason() != null && mode == "Save" && this.getMaxStartSeason() >= control.value && (this.dptMarkupGroupForm.controls.endSeason.value == null || this.dptMarkupGroupForm.controls.endSeason.value == undefined)) {
          return { 'markupGroupOverLappingSeason': { value: control.value } };
        }
        if (this.dptMarkupGroupForm.controls.endSeason.value != null && this.dptMarkupGroupForm.controls.endSeason.value != undefined && this.dptMarkupGroupForm.controls.endSeason.value != "") {
          if (control.value > this.dptMarkupGroupForm.controls.endSeason.value) { return { 'invalidStartSeason': { value: control.value } }; }
        }
        return null  /* valid option selected */
      }
      else
      this.applicationInsightService.logEvent("Department markupgroup StartSeasonValidator ended");
      return (control.value != null) ? { 'invalidAutocomplete': { value: control.value } } : null;
    }
  }
/* istanbul ignore next */
  acEndSeasonValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value != null && control.value != ""
        && control.value != undefined && this.validOptions != null
        && this.validOptions != undefined && this.validOptions.indexOf(control.value.toString()) !== -1) {
        if (this.endSeasonOverLap(control.value) == true) {
          return { 'markupGroupOverLappingSeason': { value: control.value } };
        }
        if (this.getMaxEndSeason() != null && this.getMaxEndSeason() >= this.dptMarkupGroupForm.controls.startSeason.value && this.getMaxEndSeason() <= control.value) {
          return { 'markupGroupOverLappingSeason': { value: control.value } };
        }
        if (this.dptMarkupGroupForm.controls.startSeason.value != null && this.dptMarkupGroupForm.controls.startSeason.value != undefined && this.dptMarkupGroupForm.controls.startSeason.value != "") {
          if (this.dptMarkupGroupForm.controls.startSeason.value > control.value) { return { 'invalidEndSeason': { value: control.value } }; }
        }
        return null  /* valid option selected */
      }
      else
        return (control.value != null && control.value != ""  && !control.value.includes("99")) ? { 'invalidAutocomplete': { value: control.value } } : null;
    }
  }

  startSeasonOverLap(value: number, mode: string) {
    let retutnValue = false;
    /* istanbul ignore if */
    if (this.depMarkupGroupList != undefined && this.depMarkupGroupList != null && this.depMarkupGroupList != "") {
      this.applicationInsightService.logEvent("Department markupgroup startSeasonOverLap started");
      if (this.dptmentMarkupGroupId == undefined && this.depMarkupGroupList.filter(x => x.endSeason == null).length >= 1) {
        retutnValue = true;
      }
      else {
        for (let x of this.depMarkupGroupList.filter(x => x.departmentMarkupGroupInfoId != this.dptmentMarkupGroupId)) {
          if (mode == "New" || mode == "Save") {
            if (Number(x.startSeason) <= value && x.endSeason == null) { retutnValue = true; break }
            else if (Number(x.startSeason) >= value && x.endSeason == null) { retutnValue = true; break }
            else if (x.endSeason != null && Number(x.startSeason) <= value && Number(x.endSeason) >= value) { retutnValue = true; break }
          }
        }
      }
      this.applicationInsightService.logEvent("Department markupgroup startSeasonOverLap ended");
    } return retutnValue;
  }

  endSeasonOverLap(value: number) {
    let retutnValue = false;
    let fltrData;
     /* istanbul ignore if */
    if (this.depMarkupGroupList != undefined && this.depMarkupGroupList != null && this.dptMarkupGroupForm.controls.startSeason.value != undefined && this.dptMarkupGroupForm.controls.startSeason.value != null) {
      fltrData = this.depMarkupGroupList.filter(x => x.departmentMarkupGroupInfoId != this.dptmentMarkupGroupId)
      for (let x of fltrData) {
        if (Number(x.startSeason) <= value && x.endSeason == null) { retutnValue = true; break }
        else if (Number(x.startSeason) <= value && Number(x.endSeason) >= value) { retutnValue = true; break }
        else if (x.endSeason != null && Number(x.startSeason) >= this.dptMarkupGroupForm.controls.startSeason.value && Number(x.endSeason) <= value) { retutnValue = true; break }
      }
    } fltrData = []; return retutnValue;
  }

  getMaxEndSeason() {
    if (this.depMarkupGroupList != undefined && this.depMarkupGroupList != null && this.dptMarkupGroupForm.controls.startSeason.value != undefined && this.dptMarkupGroupForm.controls.startSeason.value != null) {
      this.applicationInsightService.logEvent("Department markupgroup getMaxEndSeason started");
      var max;
      let fltrData = this.depMarkupGroupList.filter(x => x.departmentMarkupGroupInfoId != this.dptmentMarkupGroupId)
      for (var i = 0; i < fltrData.length; i++) {
        if (max == null || parseInt(fltrData[i]['endSeason']) > parseInt(max['endSeason']))
          max = fltrData[i];
      }
      this.applicationInsightService.logEvent("Department markupgroup getMaxEndSeason ended");
    }
    return max != null ? max.endSeason : null;
  }

  getMaxStartSeason() {
    if (this.depMarkupGroupList != undefined && this.depMarkupGroupList != null) {
      this.applicationInsightService.logEvent("Department markupgroup getMaxStartSeason started");
      var max;
      let fltrData = this.depMarkupGroupList.filter(x => x.departmentMarkupGroupInfoId != this.dptmentMarkupGroupId)
      for (var i = 0; i < fltrData.length; i++) {
        if (max == null || parseInt(fltrData[i]['startSeason']) > parseInt(max['startSeason']))
          max = fltrData[i];
      }
      this.applicationInsightService.logEvent("Department markupgroup getMaxStartSeason ended");
    }
    return max != null ? max.startSeason : null;
  }
}
