import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { iif } from 'rxjs';
import { CommonUtilityService } from 'src/app/services/organization/commonutility/commonutility.service';
import { CustomCustomerGroupService } from 'src/app/services/organization/department/customcustomergroup.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { AccountService } from '../../../../../shared/services/account.service'
@Component({
  selector: 'department-customcustomergroupform',
  templateUrl: './customcustomergroupform.component.html',
  styleUrls: ['./customcustomergroupform.component.css']
})
export class DepartmentcustomcustomergroupformComponent implements OnInit {

  @Output() customergroupcall = new EventEmitter();
  customCustomerGroupMasterlist: any[] = [];
  customCustomerGroupFilterList: any[] = [];

  updateDepCustomCustomerGroupForm: any;
  isCustomCustomerGroupEdit: false;
  @Input() parentDepartmentId;
  @Input() departmentDraft;
  @Input() customCustomerGrouplist;
  validationMessages: any;
  depCustomCustomerGroupForm = false;
  userrole: boolean;
  constructor(public _depCustomCustomerGroupService: CustomCustomerGroupService,
    public _commonutilityService: CommonUtilityService,
    private toastr: ToastrService,
    public account: AccountService,
    private applicationInsightService: ApplicationInsightService) { }

  ngOnInit(): void {

    this.account.getUserInfo();
    let list = this.account.userInfo.Role;
    let rolelist = list.split(",");
    this.userrole = rolelist.length <= 1 ? rolelist[0] == 'sds.Reader' ? true : false : false;
    if (rolelist.length > 1) {
      for (let i = 0; i < rolelist.length; i++) {
        if (rolelist[i] != 'sds.Reader') {
          this.userrole = false;
        }
      }
    }
    this.dptCustomCustomerGroupForm.controls.lastUpdatedBy.setValue(this.account.userInfo.Email);
    this.updateDepCustomCustomerGroupForm = Object.assign({});

    this.validationMessages = this._commonutilityService.validationMsgs;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.applicationInsightService.logEvent("Department ngonchange Customergroup listing started");
    this.depCustomCustomerGroupForm = false;
    if (this.parentDepartmentId != undefined && this.parentDepartmentId != null)
      this.depCustomCustomerGroupForm = true;
    if (this.customCustomerGrouplist != undefined && this.customCustomerGrouplist != null && this.customCustomerGrouplist != '')
      this.customCustomerGroupMasterlist = this.customCustomerGroupFilterList = this.customCustomerGrouplist;
    this.onClear()
    this.applicationInsightService.logEvent("Department ngonchange Customergroup listing ended");
  }

  displayTextCCG(customsCustomerGroupId: string) {
    this.applicationInsightService.logEvent("Department display Customergroup name started");
    if (customsCustomerGroupId == null || customsCustomerGroupId == "")
      return null;
    else if (this.customCustomerGroupMasterlist != undefined && customsCustomerGroupId != null)
      return this.customCustomerGroupMasterlist.find(x => x.customsCustomerGroupId === customsCustomerGroupId).customsCustomerGroupName;
    this.applicationInsightService.logEvent("Department display Customergroup name ended");
  }

  filterItemCCG(filterKeyCCG: string) {
    this.applicationInsightService.logEvent("Department filter Customergroup name started");
    let newCCGList: any[] = [];
    newCCGList = this.customCustomerGroupMasterlist;
    if (filterKeyCCG != null && filterKeyCCG != "")
      this.customCustomerGroupFilterList = newCCGList.filter(ccg => ccg.customsCustomerGroupName.toLowerCase().includes(filterKeyCCG.toString().toLowerCase()))
    else { this.customCustomerGroupFilterList = this.customCustomerGroupMasterlist; }
    this.applicationInsightService.logEvent("Department filter Customergroup name ended");
  }

  onSubmit() {
    this.applicationInsightService.logEvent("Department Customergroup form submit started");
    if (this.dptCustomCustomerGroupForm.controls.departmentId.value === undefined
      || this.dptCustomCustomerGroupForm.controls.departmentId.value === null
      || this.dptCustomCustomerGroupForm.controls.departmentId.value === "") { this.dptCustomCustomerGroupForm.controls.departmentId.setValue(this.parentDepartmentId); }
    this.dptCustomCustomerGroupForm.controls.customCustomerGroupId.updateValueAndValidity();
    this.dptCustomCustomerGroupForm.controls.departmentDraft.setValue(this.departmentDraft);
    console.log("Custom Customer Group Save: " + JSON.stringify(this.dptCustomCustomerGroupForm.value))
    if (this.dptCustomCustomerGroupForm.valid) {
      console.log("Custom Customer Group After:" + JSON.stringify(this.dptCustomCustomerGroupForm.value))
      if (this.dptCustomCustomerGroupForm.value.departmentCustomCustomerGroupId == undefined || this.dptCustomCustomerGroupForm.value.departmentCustomCustomerGroupId == 0 || this.dptCustomCustomerGroupForm.value.departmentCustomCustomerGroupId == null) {
        this._depCustomCustomerGroupService.createDepartmentCustomCustomerGroup(this.dptCustomCustomerGroupForm.value).subscribe
          (data => {
            this.toastr.success(data.message, "", { timeOut: 10000 });
            this.onClear();
            this.customergroupcall.emit();
            //this._depCustomCustomerGroupService.refreshCustomCustomerGroupList();
          },
            error => { this.toastr.error(error, "", { timeOut: 10000 }) })
      }
      else {
        this._depCustomCustomerGroupService.updateDepartmentCustomCustomerGroup(this.dptCustomCustomerGroupForm.value).subscribe
          (data => {
            this.toastr.success(data.message, "", { timeOut: 10000 });
            this.onClear();
            this.customergroupcall.emit();
            //this._depCustomCustomerGroupService.refreshCustomCustomerGroupList();
          },
            error => { this.toastr.error(error, "", { timeOut: 10000 }) })
      }
    }
    this.applicationInsightService.logEvent("Department Customergroup form submit ended");
  }

  onClear() {
    this.applicationInsightService.logEvent("Department  Customergroup form clear started");
    this.isCustomCustomerGroupEdit = false;
    this.dptCustomCustomerGroupForm.controls.customCustomerGroupId.clearValidators();
    this.dptCustomCustomerGroupForm.controls.departmentCustomCustomerGroupId.setValue('');
    this.dptCustomCustomerGroupForm.controls.departmentId.setValue('');
    this.dptCustomCustomerGroupForm.controls.customCustomerGroupId.setValue('');
    this.dptCustomCustomerGroupForm.controls.customCustomerGroupId.setValidators([this._commonutilityService.acObjectValidator(), Validators.required]);
    this.applicationInsightService.logEvent("Department  Customergroup form clear ended");
  }

  dptCustomCustomerGroupForm: FormGroup = new FormGroup({
    departmentCustomCustomerGroupId: new FormControl(''),
    departmentId: new FormControl(''),
    customCustomerGroupId: new FormControl('', [this._commonutilityService.acObjectValidator(), Validators.required]),
    lastUpdatedBy: new FormControl(),
    departmentDraft: new FormControl(''),
  })

}
