import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { SectionService } from 'src/app/services/organization/section/section.service';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { SectionviewComponent } from './sectionview.componet';
import { ModulePermission } from 'src/app/models/enum';
export interface iSection {
  sectionName: any;
  corporateBrandId: any;
  corporateBrandName: any;
  divisionId: any;
  divisionName: any;
  sectionCode: any;
  description: any;
  active: any;
  draft: any;
  lastUpdatedTime: any;
}
@Component({
  selector: 'app-sectionlist',
  templateUrl: './sectionlist.component.html',
  styleUrls: ['./sectionlist.component.css']
})
export class SectionlistComponent implements OnInit {

  @Input() sectionSearch: any;
  @Output() childButtonEvent = new EventEmitter();
  public dataSource: MatTableDataSource<iSection>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<iSection>;
  corporateBrandName: any;
  sectionCode: any;
  indexCode: any;
  active: any;
  draft: any;
  // MatPaginator Inputs
  pageNumber: any = 1;
  length: any;
  pageSize: any = 15;
  sortOrderColumn: any = "SectionSortOrder";
  sortByOrder: any = "asc";
  Serviceactive: any = true;
  ServiceDraft: any = false;
  pageEvent: PageEvent;
  sectionSortOrder: any;
  sectionList: any;
  errorMsg: any;
  customDateTime: string;
  source: any;
  subscription: Subscription;
  userrole: boolean;
  users: any;
  getpagesizeoptions(): number[] {
    if (this.length > 25)
      return [10, 20, 25, this.length];
    else if (this.length > 20)
      return [10, 20, this.length];
    else if (this.length > 10)
      return [10, this.length];
    return null;
  }

  displayedColumns = [
    'corporateBrandName',
    'divisionCode',
    'sectionCode',
    'sectionName',
    'sectionSortOrder',
    'lastUpdatedTime',
    'Actions'
  ];
  matDialogRef: MatDialogRef<SectionviewComponent>;

  constructor(
    public _sectionService: SectionService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    private applicationInsightService: ApplicationInsightService,
    public router: Router
  ) {this.users = JSON.parse(localStorage.getItem("users") || "[]")}

  ngOnInit() {
    this.userrole = this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this.subscription = this._sectionService.refreshTablesection$.subscribe(
      refreshTablesection => {
        this.BindSectionData();
      });
  }
  public ngOnDestroy(): void {
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }
  ngOnChanges(changes: SimpleChanges) {
    this.pageNumber = 1;
    this.BindSectionData();
  }

  sortData(sort: Sort) {
    this.applicationInsightService.logEvent("Sorting started");
    this.sortOrderColumn = sort.active != null ? sort.active.charAt(0).toUpperCase() + sort.active.slice(1) : sort.active;
    this.sortByOrder = (sort.direction == null || sort.direction == "") ? "desc" : sort.direction;
    this.BindSectionData();
    this.applicationInsightService.logEvent("Sorting ended");
  }

  public GetSectionDatawithfilters(event?: PageEvent) {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.BindSectionData();
    return event;
  }

  BindSectionData() {
    this.applicationInsightService.logEvent("Bind Section started");
    let filterData: any[] = [];
    if (this.sectionSearch != null) {
      if (this.sectionSearch.status == "active") { this.active = true; this.draft = false; }
      if (this.sectionSearch.status == "inactive") { this.active = false; this.draft = false; }
      if (this.sectionSearch.status == "draft") { this.draft = true; this.active = ""; }
      if (this.sectionSearch.status == "all") { this.draft = ""; this.active = ""; }
      this._sectionService.getSectionAllByFilter(
        this.pageNumber,
        this.pageSize,
        this.sectionSearch.corporateBrandName == null ? "" : this.sectionSearch.corporateBrandName,
        this.sectionSearch.sectionName == null ? "" : this.sectionSearch.sectionName,
        this.sectionSearch.sectionCode,
        this.sectionSearch.division == null ? "" : this.sectionSearch.division,
        this.sectionSortOrder == null ? 0 : this.sectionSortOrder,
        this.active,
        this.draft,
        this.sortOrderColumn === null ? 0 : this.sortOrderColumn,
        this.sortByOrder
      ).subscribe((data: any) => {
        this.applicationInsightService.logEvent("Section listing and filter started");
        filterData = data.section;
        this.dataSource = new MatTableDataSource(filterData);
        this.childButtonEvent.emit();
        this.length = data.totalCount;
        this.sort.active = this.sortOrderColumn;
        this.sort.direction = this.sortByOrder;
        this.dataSource.sort = this.sort;
        this.applicationInsightService.logEvent("Section listing and filter ended");
      },
        error => { this.errorMsg = error; });
    }
    this.applicationInsightService.logEvent("Bind Section ended");
  }

  //While clicking Edit button load respective data to entry page
  updateRecord(updateRowData: any) {
    this._sectionService.getsectionIdFromList(updateRowData.sectionId);
  }

  openDialog(row: any) {
    this.matDialogRef = this.dialog.open(SectionviewComponent, {
      data: { rowData: row },
      disableClose: false
    });
    this.matDialogRef.afterClosed().subscribe(res => {
      if ((res == true)) {
        row = "";
      }
    });
  }
}
