
<div class="main-div">
    <mat-card class="example-card">
    <mat-card-title></mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
    <mat-card-content>
      <p>You don't have permission to access please contact system administrator</p>
    </mat-card-content>
    <mat-card-actions>
      <button id="btnBack" class="mat-raised-button ml-1 mat-primary" (click)="backClicked()">Back</button>
    </mat-card-actions>
  </mat-card>
  </div>