import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { EarlierplanningService } from 'src/app/services/organization/earlierplanning/earlierplanning.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { SouthernhemispherdepartmentlistComponent } from '../../southernhemisphere/southernhemispherelist/southernhemispheredepartmentlist';
export interface getearlierplanningAll {
  earlierPlanningId: any,
  season: any,
  corporateBrandName: any,
  sectionName: any,
  weekNumber: any
  channelId: any,
  channelName: any,
  departmentCode: any,
  departmentName: any,
  active: true,
  lastUpdatedTime: any,
  draft: false,
  isPublished: any,
}

@Component({
  selector: 'app-earlierplanninglist',
  templateUrl: './earlierplanninglist.component.html',
  styleUrls: ['./earlierplanninglist.component.css']
})
export class EarlierplanninglistComponent implements OnInit, OnChanges {
  @Input() earlierPlanningSearch: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  public dataSource: MatTableDataSource<getearlierplanningAll>;
  // MatPaginator Inputs
  pageNumber: any = 1;
  length: any;
  pageIndex: any;
  pageSize: any = 15;
  sortByColumnName: any = "LastUpdatedTime";
  sortByOrder: any = "asc";
  pageEvent: PageEvent;
  errorMsg: any;
  customDateTime: any = "";
  active: any;
  draft: any;
  subscription: Subscription;
  emitter: number = 1;
  searchParams: any;
  EarlierPlanningList: any[] = [];
  Serviceactive: any = true;
  ServiceDraft: any = false;
  seasonnumberFilterList: any[] = [];
  displayedColumns = [
    'seasonNumber',
    'channelName',
    'corporateBrandName',
    'departmentName',
    'ISW',
    'lastUpdatedTime',
    'Actions'
  ];
  matDialogRef: MatDialogRef<SouthernhemispherdepartmentlistComponent>;
  department: string = "";
  filteredEPList: any;
  constructor(
    public _earlierplanningService: EarlierplanningService,
    public route: ActivatedRoute,
    private toastr: ToastrService,
    private matDialog: MatDialog,
    private applicationInsightService: ApplicationInsightService,
  ) { }

  getpagesizeoptions(): number[] {
    if (this.length > 25)
      return [10, 20, 25, this.length];
    else if (this.length > 20)
      return [10, 20, this.length];
    else if (this.length > 10)
      return [10, this.length];
    return null;
  }
  public GetEarlierPlanningDatawithfilters(event?: PageEvent) {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.BindEarlierPlanning();
    return event;
  }

  sortData(sort: Sort) {
    this.applicationInsightService.logEvent("Sorting and Bind EarlierPlanningData started");
    this.sortByColumnName = sort.active != null ? sort.active.charAt(0).toUpperCase() + sort.active.slice(1) : sort.active;
    this.sortByOrder = (sort.direction == null || sort.direction == "") ? "desc" : sort.direction;
    this.BindEarlierPlanning();
    this.applicationInsightService.logEvent("Sorting and Bind EarlierPlanningData ended")
  }
  ngOnInit(): void {
    this.subscription = this._earlierplanningService.refreshTableEarlierPlanning$.subscribe(x => { this.BindEarlierPlanning(); });
  }
  ngOnChanges(changes: SimpleChanges) { this.pageNumber = 1; this.BindEarlierPlanning(); }
  BindEarlierPlanning() {
    this.applicationInsightService.logEvent("Bind Earlierplanning started");
    if (this.earlierPlanningSearch.seasonlist != null && this.earlierPlanningSearch.seasonlist != "") {
      if (this.earlierPlanningSearch.status == "active") { this.Serviceactive = true; this.ServiceDraft = false; }
      if (this.earlierPlanningSearch.status == "inactive") { this.Serviceactive = false; this.ServiceDraft = false; }
      if (this.earlierPlanningSearch.status == "draft") { this.ServiceDraft = true; this.Serviceactive = null; }
      if (this.earlierPlanningSearch.status == "all") { this.ServiceDraft = null; this.Serviceactive = null; }
      this.searchParams = this.earlierPlanningSearch;
      this.searchParams.corporateBrandName = this.earlierPlanningSearch.corporateBrandName = null ? "" : this.earlierPlanningSearch.corporateBrandName;
      this.searchParams.sectionName = this.earlierPlanningSearch.sectionName = null ? "" : this.earlierPlanningSearch.sectionName;
      this.searchParams.season = this.earlierPlanningSearch.seasonNumber == null || this.earlierPlanningSearch.seasonNumber == '' || this.earlierPlanningSearch.seasonNumber.length < 6 ? this.earlierPlanningSearch.seasonlist : this.earlierPlanningSearch.seasonNumber;
      this.searchParams.channelName = this.earlierPlanningSearch.channelName = null ? "" : this.earlierPlanningSearch.channelName;
      this.searchParams.departmentName = this.earlierPlanningSearch.departmentName = null ? "" : this.earlierPlanningSearch.departmentName;
      this.searchParams.pageNumber = this.pageNumber;
      this.searchParams.pageSize = this.pageSize;
      this.searchParams.sortByColumnName = this.sortByColumnName;
      this.searchParams.sortByOrder = this.sortByOrder;
      this.searchParams.active = this.Serviceactive;
      this.searchParams.draft = this.ServiceDraft;
      this._earlierplanningService.getEarlierPlanningAll(this.searchParams).subscribe((data: any) => {
        this.applicationInsightService.logEvent("Earlier Planning listing and filter started");
        this.EarlierPlanningList = data.earlierPlanning;
        if (this.earlierPlanningSearch.seasonNumber != null && this.earlierPlanningSearch.seasonNumber != "" && this.earlierPlanningSearch.seasonNumber.length < 6) {
          this.EarlierPlanningList = this.EarlierPlanningList.filter(i => i.seasonNumber.includes(this.earlierPlanningSearch.seasonNumber));
          this.dataSource = new MatTableDataSource(this.EarlierPlanningList);
          this.length = this.EarlierPlanningList.length;
          this.sort.active = this.sortByColumnName;
          this.sort.direction = this.sortByOrder;
          this.dataSource.sort = this.sort;
        }
        else {
          this.dataSource = new MatTableDataSource(this.EarlierPlanningList);
          this.length = data.totalCount;
          this.sort.active = this.sortByColumnName;
          this.sort.direction = this.sortByOrder;
          this.dataSource.sort = this.sort;
        }
        this.applicationInsightService.logEvent("Earlier Planning listing and filter ended");
      },
        error => { this.errorMsg = error; });

      this.applicationInsightService.logEvent("Bind Earlier Planning listing ended");
    }
  }
  OpenModal(row: any) {
    this.matDialogRef = this.matDialog.open(SouthernhemispherdepartmentlistComponent, {
      data: { department: row.departmentName },
      disableClose: false
    });

    this.matDialogRef.afterClosed().subscribe(res => {
      if ((res == true)) {
        this.department = "";
      }
    });
  }
  //While clicking Edit button load respective data to entry page
  updateRecord(updateRowData: any) {
    this._earlierplanningService.getEarlierPlanningIdFromList(updateRowData.earlierPlanningId);
  }
}
