
<mat-drawer-container id="outerdrawer">
  <mat-drawer id="drawerleft" #drawer mode="side" opened="false" [position]="nav_position" style="height:100% !important;">
    <div class="wrap">
      <div class="fright">
       <app-departmentform #departmentform (dropDownCollections)="dropDownCollections($event)" [events]="eventsSubject.asObservable()"></app-departmentform>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content id="drawerright">
    <div class="fleft" style="width:100%">
     <button id="accToggle" mat-raised-button (click)="toggleclick()" style="float:right;margin: 1% 0% 0% 0%;" color="primary" aria-label="Example icon button with a menu icon">
      </button>
      <div class="add-div container">
        <form [formGroup]="dptSearchForm">
          <mat-form-field appearance="legacy" class="width-15 ml-2">
            <mat-label>Corporate Brand</mat-label>
            <input type="text" id="Searchcorporatebrand" matInput [matAutocomplete]="autoCB"
                   formControlName="corporateBrandName" [(ngModel)]="corporateBrandName"
                   (ngModelChange)="filterItemCorporateBrand($event)">
            <mat-autocomplete autoActiveFirstOption #autoCB="matAutocomplete">
              <mat-option *ngFor="let CBname of corporateBrandFilterList" [value]="CBname.corporateBrandCodeName" [matTooltipDisabled]="CBname.corporateBrandName.length<8"  matTooltip="{{CBname.corporateBrandCode}}&nbsp;{{CBname.corporateBrandName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
                {{CBname.corporateBrandCode}} {{CBname.corporateBrandName}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
       <mat-form-field appearance="legacy" class="width-15 ml-2">
            <mat-label>Department</mat-label>
            <input type="text" id="Searchdepartment" matInput [matAutocomplete]="autoDN"
                   formControlName="departmentName" [(ngModel)]="departmentName" 
                   (ngModelChange)="filterItemdepartmentName($event)">
            <mat-autocomplete autoActiveFirstOption #autoDN="matAutocomplete">
              <mat-option *ngFor="let Dname of departmentNameFilterList" [value]='Dname.departmentCodeName' [matTooltipDisabled]="Dname.departmentName.length<8"  matTooltip="{{Dname.departmentCode}}&nbsp;{{Dname.departmentName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
              {{Dname.departmentCode}} {{Dname.departmentName}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field appearance="legacy" class="width-15 ml-2">
            <mat-label>Section</mat-label>
            <input type="text" id="Searchsection" matInput [matAutocomplete]="autoS" formControlName="section"
                   [(ngModel)]="section" (ngModelChange)="filterItemSection($event)">
            <mat-autocomplete autoActiveFirstOption #autoS="matAutocomplete">
              <mat-option *ngFor="let section of sectionNameFilterList" [value]="section.sectionCodeName"  [matTooltipDisabled]="section.sectionName.length<8"  matTooltip="{{section.sectionCode}}&nbsp;{{section.sectionName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
                {{section.sectionCode}} {{section.sectionName}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field appearance="legacy" class="width-15 ml-2">
            <mat-label>Division</mat-label>
            <input type="text" id="Searchdivisionname" matInput [matAutocomplete]="autoDiN"
                   formControlName="divisionName" [(ngModel)]="divisionName"
                   (ngModelChange)="filterItemDivisionName($event)">
            <mat-autocomplete autoActiveFirstOption #autoDiN="matAutocomplete">
              <mat-option *ngFor="let DiName of divisionNameFilterList" [value]="DiName.divisionCodeName" [matTooltipDisabled]="DiName.divisionName.length<8"  matTooltip="{{DiName.divisionCode}}&nbsp;{{DiName.divisionName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
                {{DiName.divisionCode}} {{DiName.divisionName}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field appearance="legacy" class="width-10  ml-2">
            <mat-label>Sub Index</mat-label>
            <input type="text" id="SearchsubIndexCode" matInput [matAutocomplete]="autosi"
                   formControlName="subIndex" [(ngModel)]="subIndex" (ngModelChange)="filterItemSubIndex($event)">
            <mat-autocomplete autoActiveFirstOption #autosi="matAutocomplete">
              <mat-option *ngFor="let si of subIndexCodeFilterList" [value]="si.subIndexCodeName" [matTooltipDisabled]="si.subIndexName.length<8"  matTooltip="{{si.subIndexCode}}&nbsp;{{si.subIndexName}}"  matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
                {{si.subIndexCode}} {{si.subIndexName}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field appearance="legacy" class="width-15 ml-2">
            <mat-label>Garment Group</mat-label>
            <input type="text" id="Searchgarmentgroup" matInput [matAutocomplete]="autoGG"
                   formControlName="garmentGroup" [(ngModel)]="garmentGroup"
                   (ngModelChange)="filterItemGarmentGroup($event)">
            <mat-autocomplete autoActiveFirstOption #autoGG="matAutocomplete">
              <mat-option *ngFor="let garmentgroup of garmentGroupNameFilterList" [value]="garmentgroup.garmentGroupCodeName"  [matTooltipDisabled]="garmentgroup.garmentGroupName.length<8"  matTooltip="{{garmentgroup.garmentGroupCode}}&nbsp;{{garmentgroup.garmentGroupName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
                {{garmentgroup.garmentGroupCode}} {{garmentgroup.garmentGroupName}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <!-- <mat-form-field appearance="legacy" class="width-15 ml-2">
            <mat-label>Customer Group</mat-label>
            <input type="text" id="Searchcustomergroup" matInput [matAutocomplete]="autoCG"
                   formControlName="customerGroup" [(ngModel)]="customerGroup"
                   (ngModelChange)="filterItemCustomerGroup($event)">
            <mat-autocomplete autoActiveFirstOption #autoCG="matAutocomplete">
              <mat-option *ngFor="let customergroup of customerGroupNameFilterList" [value]="customergroup.customerGroupCodeName">
                {{customergroup.customerGroupCode}} {{customergroup.customerGroupName}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field> -->
          <mat-form-field appearance="legacy" class="width-10 ml-2">
            <mat-label>Status</mat-label>
            <mat-select id="Searchstatus" formControlName="status" [(ngModel)]="status">
              <mat-option value="all">
                All
              </mat-option>
              <mat-option value="active">
                Active
              </mat-option>
              <mat-option value="inactive">
                Inactive
              </mat-option>
              <mat-option value="draft">
                Draft
              </mat-option>
            </mat-select>
          </mat-form-field>          
          <button mat-button class="add-button-container mat-raised-button mat-primary ml-2" 
          id="searchClear" type="submit" (click)="onSearchClear()" >Clear</button>
        </form>
      </div>
      <div class="Clear"></div>
      <app-departmentlist #DepartmentlistComponent  (childButtonEvent)="receivedChildHandler()" [departmentSearch]=dptSearchForm.value></app-departmentlist>
  </div>
  </mat-drawer-content>
</mat-drawer-container>
<style>
  .mat-drawer.mat-drawer-end {
    position: relative;
    overflow: auto;
    float: right;
  }
</style>
