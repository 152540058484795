import { Component, OnInit, ViewChild, Input, SimpleChanges, OnChanges } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ChannelService } from '../../../../services/market/channel/channel.service';
import { channelModel } from 'src/app/models/market/channel/channelModel'

@Component({
  selector: 'app-channellist',
  templateUrl: './channellist.component.html',
  styleUrls: ['./channellist.component.css']
})

export class ChannellistComponent implements OnInit , OnChanges {

  @Input() channelSearch: any;

  public dataSource: MatTableDataSource<channelModel>;

  displayedColumns = [
    'channelName',
    'channelSortOrder',
    'lastUpdatedTime',
    'Actions'
  ];

  @ViewChild(MatPaginator, {static:false}) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<channelModel>;

   pageNumber: any = 1;
   length: any;
   pageIndex:any;
   pageSize: any = 15;
   sortByColumnName: any = "channelSortOrder";
   sortByOrder: any = "asc";
   pageEvent: PageEvent;
   errorMsg:any;
  customDateTime: string;

  constructor(public _channelService: ChannelService) { }  

  ngOnInit(): void {
     this.BindChannelData();
    this._channelService.refreshTablechannel$.subscribe(
      x => {
          this.BindChannelData();
      });
  }

  getPageSizeOptions(): number[] {
    if (this.length > 25)
      return [10, 20, 25, this.length];
    else if (this.length > 20)
      return [10, 20, this.length];
    else if (this.length > 10)
      return [10, this.length];
    return null;
  }
  
  ngOnChanges(changes: SimpleChanges) {
    this.BindChannelData();
   }

  sortData(sort: Sort) {
    this.sortByColumnName = sort.active;
    this.sortByOrder = sort.direction;
    this.BindChannelData();
  }

  BindChannelData() {  
    let newchannelList: any[] = [];
    let filterData:any[]=[];
    let active: any;
    let draft: any;
    if( this.channelSearch!=null ){
    if (this.channelSearch.status === "draft") { draft = true; active = ""; }
    if (this.channelSearch.status === "inactive") { active = false; draft = false; }
    if (this.channelSearch.status === "active") { active = true; draft = false; }
    if (this.channelSearch.status === "all") { active = ""; draft = ""; }    
    }
    this._channelService.getChannelAll().subscribe((data: any) => {
      newchannelList=data.getChannelAll;
     if(newchannelList != null)
     {
      filterData = newchannelList.filter(x=>x.channelName.toString().toLowerCase().includes(this.channelSearch.channelName.toString().toLowerCase()))
      .filter(x=>x.active.toString().includes(active.toString()))
      .filter(x=>x.draft.toString().includes(draft.toString()));
      this.dataSource = new MatTableDataSource(filterData);
      this.sort.active = this.sortByColumnName;
      this.sort.direction= this.sortByOrder;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.length = this.dataSource.data.length;     
    }},
    error => {
      this.errorMsg = error;
      //this.toastr.error(error);
    });
  }

  updateRecord(updateRowData: any) {
    this._channelService.getchannelIDFromList(updateRowData.channelId);
  }

  public GetChannelDataWithFilters(event?: PageEvent) {
    this.pageSize = event.pageSize;
    this.BindChannelData();
    return event;
  }
  
}
