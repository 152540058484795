<div class="fixed-content mat-elevation-z2 form-border" id="scrolltop">
  <form [formGroup]="_earlierplanningform">
    <section class="form-section">
      <h2 mat-dialog-title class="align">{{isEarlierplanningEdit === true ? 'Update' : 'New'}} Earlier Planning</h2>
    </section>
    <div class="example-container add-form-field ">
      <mat-form-field appearance="legacy">
        <mat-label>Season</mat-label>
        <input type="text" id="Searchseason" matInput [matAutocomplete]="autoSN" formControlName="seasonNumber"
        (keydown)="changeInput($event)" (ngModelChange)="filterSeason($event)"
          [(ngModel)]="updateEarlierplanningform.seasonNumber">
        <mat-autocomplete autoActiveFirstOption #autoSN="matAutocomplete" [displayWith]="displayTextSeason.bind(this)">
          <mat-option *ngFor="let season of seasonFilterList" [value]="season.seasonNumber">
            {{season.displayName}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngFor="let validation of validationMessages?.autoCompleteControl">
          <div *ngIf="_earlierplanningform.controls['seasonNumber'].hasError(validation.type)">
            {{validation.message}}
          </div>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field appearance="legacy">
        <mat-label>ISW</mat-label>
        <input readonly matInput placeholder="ISW" formControlName="weekNumber"
          [(ngModel)]="updateEarlierplanningform.weekNumber">
      </mat-form-field>
      <br>
      <mat-form-field appearance="legacy">
        <mat-label>Corporate Brand</mat-label>
        <input type="text" id=inpcorporatebrand matInput [matAutocomplete]="autoCB" formControlName="corporateBrandId"
          (keydown)="changeInput($event)" (ngModelChange)="filterItemCorporateBrand($event)"
          [(ngModel)]="updateEarlierplanningform.corporateBrandId"
          [readonly]="isEarlierplanningEdit=='fill'?true:false">
        <mat-autocomplete autoActiveFirstOption #autoCB="matAutocomplete" [displayWith]="displayTextCB.bind(this)">
          <mat-option *ngFor="let CBname of corporateBrandFilterList" [value]="CBname.corporateBrandId">
            {{CBname.corporateBrandName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <br>
      <app-earlierplanningdepartment #seasonDepartments id="emitEarlierplanningdep"
        [departmentparam]=_earlierplanningform.value (emitEarlierplanningdep)="receivedChildHandler($event)">
      </app-earlierplanningdepartment>

      <section class="example-section">
        <mat-checkbox id="inpactive" formControlName="active" [(ngModel)]="updateEarlierplanningform.active"
          [checked]="true" class="example-margin width-25" color='primary' [disabled]=isActiveDisable>Active
        </mat-checkbox>
        <mat-checkbox id="inpdraft" formControlName="draft" [(ngModel)]="updateEarlierplanningform.draft"
          class="example-margin width-15" color='primary'>Draft
        </mat-checkbox>
      </section>
      <input type="hidden" formControlName="earlierPlanningId"
        [(ngModel)]="updateEarlierplanningform.earlierPlanningId">
      <br>
      <mat-dialog-actions *ngIf="userrole">
     <button [disabled]="(isEarlierplanningEdit === true) ? !_earlierplanningform.dirty : false"
          [ngClass]="(isEarlierplanningEdit === true) ? 'mat-raised-button ml-1':'mat-raised-button ml-1 mat-primary'"
          id="btnSaveUpdate" type="submit" (click)="onSubmit()">{{isEarlierplanningEdit === true ? 'Update'
          :'Save'}}</button>
        <button id="btnCancel" class="mat-raised-button ml-1 mat-primary" (click)="onClear()">Cancel</button>
      </mat-dialog-actions>
      <div clas="clearfix"></div>
      <br>
      <span *ngIf="!userrole" class="form-note">You can't save changes since you have Read access.</span>
      <span class="form-note">Note: Departments can't modify if it has orders on top of it.</span>
    </div>
    <div>
    </div>
  </form>
</div>