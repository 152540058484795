import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { GarmentgroupService } from 'src/app/services/organization/garmentgroup/garmentgroup.service';
import { Subscription } from 'rxjs';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GarmentGroupComponent } from '../garmentgrouplist/garmentgroupview.component';
import { constant } from 'src/app/shared/const/constant';
import { ModulePermission } from '../../../../models/enum';

export interface garmentGroup {
  garmentGroupId: any;
  garmentGroupName: any;
  garmentGroupSortOrder: any;
  active: any;
  draft: any;
  lastUpdatedTime: any;
}

@Component({
  selector: 'app-garmentgrouplist',
  templateUrl: './garmentgrouplist.component.html',
  styleUrls: ['./garmentgrouplist.component.css']
})
export class GarmentgrouplistComponent implements OnInit {

  @Input() garmentGroupSearch: any;
  @Output() childButtonEvent = new EventEmitter();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<garmentGroup>;
  // MatPaginator Inputs
  status: any = "";
  data: any = "";
  pageNumber: any = 1;
  length: any;
  pageIndex: any = 0;
  pageSize: any = 15;
  sortByColumnName: any = "garmentGroupSortOrder";
  sortByOrder: any = "asc";
  pageEvent: PageEvent;
  errorMsg: any;
  subscription: Subscription;
  customDateTime: string;
  emitter: number = 1;
  sortClick:boolean=false;
  public dataSource: MatTableDataSource<garmentGroup>;
  displayedColumns = [
    'garmentGroupCode',
    'garmentGroupName',
    'garmentGroupSortOrder',
    'lastUpdatedTime',
    'Actions'
  ];
  matDialogRef: MatDialogRef<GarmentGroupComponent>;
  userrole: boolean;
  users: any;
  constructor(
    public _garmentGroupService: GarmentgroupService,
    private applicationInsightService:ApplicationInsightService,
    private router: Router,
    public route: ActivatedRoute,
    private toastr: ToastrService,
    public dialog: MatDialog,
  ) { this.users = JSON.parse(localStorage.getItem("users") || "[]") }

  getpagesizeoptions(): number[] {
    if (this.length > 25)
      return [10, 20, 25, this.length];
    else if (this.length > 20)
      return [10, 20, this.length];
    else if (this.length > 10)
      return [10, this.length];
    return null;
  }

  ngOnInit(): void {
    this.userrole = this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this.subscription =
      this._garmentGroupService.refreshTableGarmentGroup$.subscribe(
        x => {
          this.emitter = 1;
          this.bindgarmentGroupData();
        });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }
  ngOnChanges(changes: SimpleChanges) {
    this.pageNumber = 1;
    this.bindgarmentGroupData();
  }

  sortData(sort: Sort) {
    this.sortByColumnName = sort.active;
    this.sortByOrder = sort.direction;
    this.sortClick = true;
    this.bindgarmentGroupData();
  }

  bindgarmentGroupData() {
    this.applicationInsightService.logEvent("Garmentgroup listing and searching started");
    let active: any;
    let draft: any;
    let newGGList: any[] = [];
    let FilterData: any[] = [];

    if (this.garmentGroupSearch != null) {
      if (this.garmentGroupSearch.status === "draft") { draft = true; active = ""; }
      if (this.garmentGroupSearch.status === "inactive") { active = false; draft = false; }
      if (this.garmentGroupSearch.status === "active") { active = true; draft = false; }
      if (this.garmentGroupSearch.status === "all") { active = ""; draft = ""; }
     
      var hasNumber = /\d/;
      if(!this.sortClick){
        if(isNaN(this.garmentGroupSearch?.garmentGroupName)){
          if(hasNumber.test(this.garmentGroupSearch?.garmentGroupName)){
            this.garmentGroupSearch.garmentGroupCodeName = this.garmentGroupSearch?.garmentGroupName;
            this.garmentGroupSearch.garmentGroupCode ='';
            this.garmentGroupSearch.garmentGroupName='';
          }else{
            this.garmentGroupSearch.garmentGroupName = this.garmentGroupSearch?.garmentGroupName;
            this.garmentGroupSearch.garmentGroupCode ='';
            this.garmentGroupSearch.garmentGroupCodeName = '';
          }
        }else{
          this.garmentGroupSearch.garmentGroupCode =  this.garmentGroupSearch.garmentGroupName;
          this.garmentGroupSearch.garmentGroupName='';
          this.garmentGroupSearch.garmentGroupCodeName = '';
        }
      }
      
      this._garmentGroupService.getGarmentGroupList().subscribe((data: any) => {
        newGGList = data.garmentGroup
        FilterData = newGGList
          .filter(GG => GG.garmentGroupCode.toString().includes(this.garmentGroupSearch.garmentGroupCode == null ? "" : this.garmentGroupSearch.garmentGroupCode.toString()))
          .filter(GG => GG.garmentGroupName.toString().toLowerCase().includes(this.garmentGroupSearch.garmentGroupName.toString().toLowerCase()))
          .filter(GG => GG.garmentGroupCodeName.toString().includes(this.garmentGroupSearch.garmentGroupCodeName.toString()))
          .filter(GG => GG.active.toString().includes(active.toString()))
          .filter(GG => GG.draft.toString().includes(draft.toString()));         
        this.dataSource = new MatTableDataSource(FilterData);
        this.sort.active = this.sortByColumnName;
        this.sort.direction = this.sortByOrder;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.length = this.dataSource.data.length;
        this.sortClick = false;
        if (this.emitter == 1) {
          this.emitter = 2;
          this.childButtonEvent.emit(data.garmentGroup);
        }

      },
        error => {
          this.dataSource = new MatTableDataSource([]);
        });

    }
    this.applicationInsightService.logEvent("Garmentgroup listing and searching ended");
  }

  //While clicking Edit button load respective data to entry page
  updateRecord(updateRowData: any) {
    this._garmentGroupService.getgarmentGroupIdFromList(updateRowData.garmentGroupId);
  }

  openDialog(row: any) {
    let departmentList:any;
    let rowData:any;
    this._garmentGroupService.getDepartmentByGarmentGroupId(row.garmentGroupId).subscribe(data=>{
      departmentList = data.departmentGarmentGroup
         this.matDialogRef = this.dialog.open(GarmentGroupComponent, {
            data: { departmentList: departmentList,rowData:row },
            disableClose: false
          });
    },error => { this.toastr.error(error, "", { timeOut: constant.toasterTimeout }) })
    
    this.matDialogRef.afterClosed().subscribe(res => {
      if ((res == true)) {
        departmentList = "";
      }
    });
  }


}
