import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ModulePermission } from 'src/app/models/enum';
import { UserAccess } from 'src/app/models/userAccess';
import { CommonValidationService } from 'src/app/services/organization/commonutility/commonValidation.service';
import { CorporatebrandService } from 'src/app/services/organization/corporatebrand/corporatebrand.service';
import { IndexService } from 'src/app/services/organization/index/index.service';
import { IndexgroupService } from 'src/app/services/organization/indexgroup/indexgroup.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { AccountService } from '../../../../shared/services/account.service'

@Component({
  selector: 'app-indexgroupform',
  templateUrl: './indexgroupform.component.html',
  styleUrls: ['./indexgroupform.component.css']
})
export class IndexgroupformComponent implements OnInit {

  @Output() childButtonEvent = new EventEmitter();
  corporateBrandMasterIGList: any[] = [];
  corporateBrandFilterIGList: any[] = [];
  validationMessages: any;
  updateIndexGroupForm: any;
  isIndexGroupEdit: any = false;
  isDisableActive: any = true;
  IndexForm: any;
  isIndexGroupEditCB: any = 'legacy';
  subscription: Subscription;
  userrole: boolean;
  users:any;
  constructor(public _indexgroupService: IndexgroupService,
    public _corporatebrandService: CorporatebrandService,
    private _indexService: IndexService,
    private toastr: ToastrService,
    public _commonvalidationService: CommonValidationService,
    public account: AccountService,
    private applicationInsightService:ApplicationInsightService,
    public _userAccess: UserAccess) {
       this.updateIndexGroupForm = Object.assign({ active: true, draft: true }); 
       this.users = JSON.parse(localStorage.getItem("users") || "[]")
      }

  ngOnInit(): void {
    this.applicationInsightService.logEvent("Indexgroup form page initialization started");   
    this.userrole = this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this._indexgroupform.controls.lastUpdatedBy.setValue(JSON.parse(localStorage.getItem("UserInfo")).Email || "[]");
    this.validationMessages = this._commonvalidationService.validationMsgs;
    this.onClear();
    this._corporatebrandService.getCorporateBrandList().subscribe((data: any) => {
      this.corporateBrandMasterIGList = this.corporateBrandFilterIGList = data.getCorporateBrandAll.filter(object => { return object['active'] !== false; });
      this.corporateBrandFilterIGList = this.corporateBrandMasterIGList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
      this.childButtonEvent.emit(data.getCorporateBrandAll);
    },
      error => {
        this.toastr.error(error);
      });
    this.subscription = this._indexgroupService.indexGroupId$.subscribe(
      indexGroupId => { this.updateIndexGroup(indexGroupId) });
      this.applicationInsightService.logEvent("Indexgroup form page initialization ended");  
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }

  displayTextCB(corporateBrandId: string) {
    if (corporateBrandId === null || corporateBrandId === "")
      return null;
    else if (this.corporateBrandMasterIGList != undefined && corporateBrandId != null)
      return this.corporateBrandMasterIGList.find(x => x.corporateBrandId == corporateBrandId).corporateBrandCodeName;
  }

  filterItemCorporateBrand(filterKey: string) {
    this.applicationInsightService.logEvent("Indexgroup form filterItemCorporateBrand started");  
    if (filterKey != null && filterKey != "") {
      this.corporateBrandFilterIGList = this.corporateBrandMasterIGList.filter(cb => cb.corporateBrandName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
      this.corporateBrandFilterIGList = this.corporateBrandFilterIGList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
    } else
      {this.corporateBrandFilterIGList = this.corporateBrandMasterIGList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));}
      this.applicationInsightService.logEvent("Indexgroup form filterItemCorporateBrand ended");
  }

  updateIndexGroup(indexGroupId: any) {
    this.applicationInsightService.logEvent("updateIndexGroup started");
    this.isIndexGroupEdit = true;
    this.isDisableActive = false;
    this._indexgroupService.getIndexGroupById(indexGroupId)
      .subscribe((data: any) => {
        this.updateIndexGroupForm = data.indexGroup;
        this.isIndexGroupEditCB = this.updateIndexGroupForm.active == true ? 'fill' : 'legacy';
      });
    this._indexService.getIndexList().subscribe((data: any) => {
      this.IndexForm = data.index.filter(object => {
        return object['indexGroupId'] == indexGroupId;
      })
      if (this.IndexForm.length == 0) {
        this.isDisableActive = false;
      }
    }),
      error => { this.toastr.error(error, "", { timeOut: 10000 }); };
      document.getElementById("scrolltop").scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      this.applicationInsightService.logEvent("updateIndexGroup ended");
  }

  onSubmit() {
    this.applicationInsightService.logEvent("Indexgroup form onSubmit started");
    this._indexgroupform.controls['corporateBrandId'].updateValueAndValidity();
    this._indexgroupform.controls['indexGroupCode'].updateValueAndValidity();
    this._indexgroupform.controls['indexGroupName'].updateValueAndValidity();
    this._indexgroupform.controls['indexGroupSortOrder'].updateValueAndValidity();
    console.log("Index Group:" + JSON.stringify(this._indexgroupform.value))
    if (this._indexgroupform.valid) {
      if (this._indexgroupform.value.indexGroupId == undefined || this._indexgroupform.value.indexGroupId == 0 || this._indexgroupform.value.indexGroupId == null) {
        this._indexgroupService.createIndexGroup(this._indexgroupform.value).subscribe(data => {

          this.toastr.success(data.message, "", { timeOut: 10000 });
          this.onClear();
          this._indexgroupService.refreshIndexGroupList();
          this.childButtonEvent.emit();
        },
          error => { this.toastr.error(error, "", { timeOut: 10000 }) }
        )
      }
      else {
        this._indexgroupService.UpdateIndexGroupRow(this._indexgroupform.value).subscribe(data => {

          this.toastr.success(data.message, "", { timeOut: 10000 });
          this.onClear();
          this._indexgroupService.refreshIndexGroupList();
          this.childButtonEvent.emit();
        },
          error => { this.toastr.error(error, "", { timeOut: 10000 }) }
        )
      }
    }
    this.applicationInsightService.logEvent("Indexgroup form onSubmit ended");
  }

  onClear() {
    this.applicationInsightService.logEvent("Indexgroup form onClear started");
    //Clearing validation 
    this._indexgroupform.controls.corporateBrandId.clearValidators();
    this._indexgroupform.controls.indexGroupCode.clearValidators();
    this._indexgroupform.controls.indexGroupName.clearValidators();
    this._indexgroupform.controls.indexGroupSortOrder.clearValidators();

    //Clearing control values 
    this._indexgroupform.controls.corporateBrandId.reset();
    this._indexgroupform.controls.indexGroupCode.reset();
    this._indexgroupform.controls.indexGroupName.reset();
    this._indexgroupform.controls.indexGroupId.reset();
    this._indexgroupform.controls.draft.reset();
    this._indexgroupform.controls.indexGroupSortOrder.reset();

    this._indexgroupform.controls.corporateBrandId.setValidators([this._commonvalidationService.acObjectValidator(), Validators.required]);
    this._indexgroupform.controls.indexGroupCode.setValidators([Validators.required, Validators.min(1), Validators.max(9999), Validators.pattern('^(?![-]).*')]);
    this._indexgroupform.controls.indexGroupName.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(20)]);
    this._indexgroupform.controls.indexGroupSortOrder.setValidators([Validators.required, Validators.min(1), Validators.max(9999), Validators.pattern('^(?![-]).*')]);
    this._indexgroupform.controls.draft.setValue(true);
    this._indexgroupform.controls.active.setValue(true);

    this.isIndexGroupEdit = false;
    this.IndexForm = [];
    this.isIndexGroupEditCB = 'legacy';
    this.isDisableActive=true;
    this.applicationInsightService.logEvent("Indexgroup form onClear ended");
  }

  _indexgroupform: FormGroup = new FormGroup({
    indexGroupId: new FormControl(''),
    corporateBrandId: new FormControl('', [this._commonvalidationService.acObjectValidator(), Validators.required]),
    indexGroupCode: new FormControl('', [Validators.required, Validators.minLength(1), Validators.max(999), Validators.pattern('^(?![-]).*')]),
    indexGroupName: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(20)]),
    indexGroupSortOrder: new FormControl('', [Validators.required, Validators.min(1), Validators.max(9999), Validators.pattern('^(?![-]).*')]),
    active: new FormControl(true),
    lastUpdatedBy: new FormControl(''),
    isPublished: new FormControl(''),
    draft: new FormControl(true)
  });

}
