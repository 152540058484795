import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ColDef, ColumnApi, GridApi, GridReadyEvent, ICellRendererParams, GridOptions } from 'ag-grid-community';
import { ProductbrandformComponent } from '../productbrandform/productbrandform.component';
import { productBrandDepartmentListComponent } from './productbranddepartmentlist'
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProductbrandService } from 'src/app/services/organization/productbrand/productbrand.service'
import { ExcelService } from 'src/app/shared/services/excel.service'
import * as alasql from 'alasql';
import { map } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-prouctbrandcomponent',
  templateUrl: './prouctbrandcomponent.component.html',
  styleUrls: ['./prouctbrandcomponent.component.css']
})

export class ProuctbrandcomponentComponent {
  matDialogRefForDepartment: MatDialogRef<productBrandDepartmentListComponent>;
  department: string = "";
  gridColumnApi: ColumnApi;
  gridLength: any;
  gridCount: any;
  status: any = "active";
  public columnDefs: ColDef[] = [
    { field: 'corporateBrandCodeAndName', headerName: 'Corporate Brand' },
    { field: 'productBrandNameAndSAPId', headerName: 'Product Brand' },
    {
      field: 'departments',
      cellStyle: { "text-decoration": "underline", "cursor": "pointer" },
      resizable: false,
      valueGetter: (row: any) => {
        let deplist: any = []
        row?.data?.departments?.forEach(element => {
          deplist.push(element?.departmentCodeAndName)
        });
        return deplist;
      }
    },
    { field: 'sortOrder' },
    {
      field: 'startWeek',
    },
    {
      field: 'endWeek',
    },
    {
      field: 'Edit | View', filter: false,
      floatingFilter: false,
      cellStyle: { "text-decoration": "underline", "cursor": "pointer" },
      cellRenderer: BtnCellRenderer,
    },
  ];
  public gridOptions: GridOptions = {
    pagination: true,
    paginationPageSize: 10,
    paginationNumberFormatter: (params) => {
      return params.value.toLocaleString();
    },
    headerHeight: 45,
    getRowHeight: function (params) {
      return 22;
    },
    animateRows: true,
    rowClassRules: {
      'ActiveBg': (params) => {
        return params.data.active
      },
      'DraftBg': (params) => {
        return !params.data.active
      },
    },
  }
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 150,
    filter: true,
    floatingFilter: true,
    resizable: true,
    sortable: true,
  };

  public rowData!: any[];
  private gridApi!: GridApi;

  constructor(private excelService: ExcelService, public dialog: MatDialog, private http: HttpClient, private productbrandService: ProductbrandService) {
    this.productbrandService.resfreshCall.subscribe(event => {
      if (event) {
        this.onGridReady(null)
      }
    })
  }
  weekdate(d: any) {
    if (d === null || d === '' || d === undefined) {
      return d;
    }
    else {
     let my_string:any = d;
     let my_insert:any  = "-W";
     let my_insert_location = 4;
      my_string = my_string.split('');  
      my_string.splice( my_insert_location , 0, my_insert );
      my_string = my_string.join('');
      return my_string
    }
  }
  onFirstDataRendered(params) {
    params.api.paginationGoToPage(4);
  }

  onChange(count) {
    this.gridOptions.api.paginationSetPageSize(Number(count));
  }

  getpagesizeoptions(): number[] {
    if (this.gridLength > 25)
      return [10, 20, 25, this.gridLength];
    else if (this.gridLength > 20)
      return [10, 20, this.gridLength];
    else if (this.gridLength > 10)
      return [10, this.gridLength];
    else if (this.gridLength > 5)
    return [5, this.gridLength];
    return null;
  }



  onGridReady(params: GridReadyEvent<any>) {
    this.productbrandService.getProductBrandList().pipe(map(data => {
      data?.getProductBrandAll.map(ele => {
        ele.startWeek = this.weekdate(ele.startWeek)
        ele.endWeek = this.weekdate(ele.endWeek)
      })
      return data
    })).subscribe(data => {
      if (data) {
        this.gridLength = data?.getProductBrandAll.length;
        this.rowData = data?.getProductBrandAll;
        switch (this._searchform.value.status) {
          case "all":
            this.rowData = data?.getProductBrandAll;
            break;
          case "active":
            this.rowData = this.rowData.filter(r => r.active === true)
            break;
          case "draft":
            this.rowData = this.rowData.filter(r => r.active === false)
            break;
        }
        this.gridApi = params?.api;
        this.gridCount = this.getpagesizeoptions();
      }
    })
  }
  onBtnExport() {
    var dataWithDepartment = JSON.stringify(this.rowData).replace(/null/g, '""');//Remove null Department   
    var rowDataWithDepartment = JSON.parse(dataWithDepartment); //convert back to array  
    var res = alasql('SEARCH / AS @data \ departments / AS @departments \ RETURN(@data->productBrandId as ProductBrandNumber, @data->corporateBrandCodeAndName as CorporateBrand, @data->productBrandNameAndSAPId as ProductBrand, @departments->divisionCodeAndName as Division, @departments->sectionCodeAndName as Section, @departments->departmentCodeAndName as Departments, @data->sortOrder as SortOrder, @data->validFrom as ValidFrom, @data->validTo as ValidTo, @data->lastUpdatedBy as LastUpdated) \ FROM ?', [rowDataWithDepartment])
    this.excelService.exportAsExcelFile(res, 'ProductBrand');
  }


  onCellClicked(params: any) {
    if (params?.colDef?.field === 'departments') {
      this.openDepartmentPopUp(params)
    }
  }

  openDepartmentPopUp(data: any) {
    this.matDialogRefForDepartment = this.dialog.open(productBrandDepartmentListComponent, {
      data: { department: data?.data?.departments },
      disableClose: false
    });

    this.matDialogRefForDepartment.afterClosed().subscribe(res => {
      if ((res == true)) {
        this.department = "";
      }
    });
  }
  //status  Search Form
  _searchform: FormGroup = new FormGroup({
    status: new FormControl(),
  });
}

// Below interface is for handling button events inside grid
@Component({
  selector: 'btn-cell-renderer',
  template: `<a (click)="editClickedHandler()">Edit</a> | <a (click)="viewClickedHandler2()" >View</a>`,
})
export class BtnCellRenderer implements ICellRendererAngularComp {

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    throw new Error('Method not implemented.');
  }
  private params: any;
  matDialogRefForForm: MatDialogRef<ProductbrandformComponent>;
  constructor(public dialog: MatDialog) {
  }

  agInit(params: any): void {
    this.params = params;
  }
  openDialog(msg) {
    this.matDialogRefForForm = this.dialog.open(ProductbrandformComponent, {
      data: { rowData: this.params, msg: msg },
      disableClose: false,
    });
    this.matDialogRefForForm?.afterClosed()?.subscribe(res => {
      if ((res == true)) {
        this.params = ''
      }
    });
  }

  editClickedHandler() {
    let msg = "editHit"
    this.openDialog(msg)
  }
  viewClickedHandler2() {
    let msg = "viewHit"
    this.openDialog(msg)
  }
}
