import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, Subject } from 'rxjs';
import { ServiceApi } from '../../../services/ServiceApi';
  let headers = new HttpHeaders()
  if (!headers.has('content-type'))
  {
      headers=headers.append('content-type','application/json')
  }
  @Injectable({
  providedIn: 'root'
  })
export class CorporatebrandService {

  constructor(
    private http: HttpClient,
    public _serviceApi: ServiceApi
  ) { }

  headers = new HttpHeaders().set('content-type','application/json').set('accept','application/json');
  httpOptions ={
      headers:this.headers
  }
   
  //Observable string sources
    public corporateBrandIdSource = new Subject<string>();
  //Observable string streams
    public corporateBrandId$ = this.corporateBrandIdSource.asObservable();
    getCorporateBrandIdFromList(mission: string)
    {
      this.corporateBrandIdSource.next(mission);
    }
  //Observable string sources
    public refreshTable = new Subject<string>();
  //Observable string streams
    public refreshTableCorporateBrand$ = this.refreshTable.asObservable();
  // Service message commands
  refreshCorporateBrandList()
  {
    this.refreshTable.next();
  }
  //List CorporateBrand from Service
  getCorporateBrandList(): Observable<any> 
  {   
    let url =  this._serviceApi.CorporateBrandGetAll;     
    return this.http.get<any>(url)      
  }
  
  //List CorporateBrand from BOCOS Service
  getCorporateBrandBOCOSList(): Observable<any> 
  {   
    let url =  this._serviceApi.GetCorporateBrandAllBocos;     
    return this.http.get<any>(url)      
  }
  //Create corporate Brand 
  CreateCorporateBrand(createnew: any): Observable<any>
  {
    let url = this._serviceApi.CorporateBrandCreate;  
    var body=(JSON.stringify(createnew));
    return this.http.post(url,body,{'headers':headers}).pipe();
  }
  //Get corporatebrand By Id
  getCorporateBrandId(corporateBrandId:number): Observable<any> {
    let url = this._serviceApi.CorporateBrandGetById
    const params = new HttpParams({fromString: 'corporateBrandId='+corporateBrandId});
    return this.http.get<any>(url,{params});
  }
  //Update Corporate-Band By Id
  UpdateCorporatebrand(updatedData: any): Observable<any>
  {
    let url = this._serviceApi.CorporateBrandUpdate
    var body = (JSON.stringify(updatedData));
    return this.http.put(url, body, { 'headers': headers }).pipe();
  }
 // delete Coporate-Brand By Id and LastupadatedBy 
  deleteCorporatebrand(deleteCBRecordData: any): Observable<any>
  {
    let url = this._serviceApi.CorporateBrandDelete;
    var deletecorporateBrandRequest =(JSON.stringify(deleteCBRecordData));
    return this.http.post(url,deletecorporateBrandRequest,{'headers':headers}).pipe();
  }
  //Get token from service Head.
  getToken()
  {
    return localStorage.getItem('token')
  }
}
