
import { MatDateFormats, NativeDateAdapter } from "@angular/material/core";
export class AppDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: Object): string {
      let convertedDate = new Date(date +'Z');
if (displayFormat === 'input') {
  
 
let day: string = convertedDate.getDate().toString();
day = +day < 10 ? '0' + day : day;
let month: string = (convertedDate.getMonth() + 1).toString();
month = +month < 10 ? '0' + month : month;
let year = convertedDate.getFullYear();
if(year==2001){
  year=new Date().getFullYear();
}
return `${year}-${month}-${day}`;
}
return convertedDate.toDateString();
}}

