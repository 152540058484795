import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { CorporatebrandService } from 'src/app/services/organization/corporatebrand/corporatebrand.service';
import { IndexgroupService } from 'src/app/services/organization/indexgroup/indexgroup.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';

@Component({
  selector: 'indexgroup',
  templateUrl: './indexgroup.component.html',
  styleUrls: ['./indexgroup.component.css']
})
export class IndexgroupComponent implements OnInit {
  
  title="Index Group"; 
  corporateBrandMasterIGList: any[] = [];
  corporateBrandFilterIGList: any[] = [];
  indexGroupCodeFilterList: any[] = [];
  indexGroupCodeMasterList: any[] = [];
  cloneindexGroupCodeMasterList: any[] = [];
  indexGroupNameFilterList: any[] = [];
  indexGroupNameMasterList: any[] = [];
  cloneindexGroupNameMasterList: any[] = [];
  corporateBrandName: any = "";
  indexGroupCode: any = "";
  indexGroupName: any = "";
  status: any = "active";
  obj:any[]=[];
  distinctIndexGroupCode : any[]=[];
  distinctIndexGroupName : any[]=[];
  constructor(public _corporatebrandService: CorporatebrandService,
              public _indexgroupservice:IndexgroupService, 
              private titleService:Title,
              private applicationInsightService:ApplicationInsightService
              ) { }

  ngOnInit(): void { this.titleService.setTitle(this.title);  }
  /* istanbul ignore next */
  receivedChildHandler(obj:any)
  {
    this.applicationInsightService.logEvent("Indexgroup receivedChildHandler started");
    if(obj.sort!=null || undefined)
    { 
    this.corporateBrandMasterIGList = this.corporateBrandFilterIGList = obj.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
  }  
    this.applicationInsightService.logEvent("Indexgroup receivedChildHandler ended");
  }
  /* istanbul ignore next */
  indexGroupDropDownList(obj:any)
  {
    this.applicationInsightService.logEvent("Indexgroup DropDownList binding started");
    if(obj.filter!=null || undefined){
    this.distinctIndexGroupCode = obj;
    this.distinctIndexGroupName = obj;
    }
    this.indexGroupCodeFilterList = this.indexGroupCodeMasterList = this.distinctIndexGroupCode.sort((a, b)  => (a.indexGroupCode > b.indexGroupCode ? 1 : -1));
    this.indexGroupNameFilterList = this.indexGroupNameMasterList = this.distinctIndexGroupName.sort((a, b)  => (a.indexGroupCode > b.indexGroupCode ? 1 : -1));
    this.applicationInsightService.logEvent("Indexgroup DropDownList binding ended");
  }

  filterItemGN(filterKey: any) {
    this.applicationInsightService.logEvent("IndexGroupName filtering started");    
   if (filterKey != null  && filterKey != ""){
    if (isNaN(filterKey)) {
      this.indexGroupNameFilterList = this.indexGroupNameMasterList.filter(gn => gn.indexGroupName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
    } else {
      this.indexGroupNameFilterList = this.indexGroupNameMasterList.filter(gg => gg.indexGroupCode.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
    }
    this.indexGroupNameFilterList = this.indexGroupNameFilterList.sort((a,b) => (a.indexGroupCode > b.indexGroupCode ? 1 : -1));
    }else
    {this.indexGroupNameFilterList=this.indexGroupNameMasterList.sort((a,b) => (a.indexGroupCode > b.indexGroupCode ? 1 : -1));}
    this.applicationInsightService.logEvent("IndexGroupName filtering ended"); 
 }
  filterItemCorporateBrand(filterKey: any) {
    this.applicationInsightService.logEvent("Filter Corporatebrand Started");
    if (isNaN(filterKey)) {
      this.corporateBrandFilterIGList = this.corporateBrandMasterIGList.filter(cb => cb.corporateBrandName.toLowerCase().includes(filterKey.toLowerCase()));
      this.corporateBrandFilterIGList = this.corporateBrandFilterIGList.sort((a, b) => (a.corporateBrandCode > b.corporateBrandCode ? 1 : -1));
    } else {
      this.corporateBrandFilterIGList = this.corporateBrandMasterIGList.filter(cb => cb.corporateBrandCode.toLowerCase().includes(filterKey.toLowerCase()));
      this.corporateBrandFilterIGList = this.corporateBrandFilterIGList.sort((a, b) => (a.corporateBrandCode > b.corporateBrandCode ? 1 : -1));
    }
      this.onFilter()
      this.applicationInsightService.logEvent("Filter Corporatebrand Ended");
  }

  // filter function based on selection
  onFilter(){
    setTimeout(() => {
       if(this.cloneindexGroupNameMasterList.length === 0){
        this.cloneindexGroupNameMasterList = this.indexGroupNameMasterList;
      }

      if(this._searchform?.controls?.corporateBrandName?.value){
        this.indexGroupNameMasterList = this.cloneindexGroupNameMasterList.filter(x => x.corporateBrandCodeName === this._searchform?.controls?.corporateBrandName?.value)
        this.indexGroupNameFilterList = this.indexGroupNameMasterList;
      }
      else {
        this.indexGroupNameFilterList = this.cloneindexGroupNameMasterList;
        this.indexGroupNameMasterList = this.cloneindexGroupNameMasterList;
      }

        if(this._searchform?.controls?.corporateBrandName?.value !== '' && 
         this._searchform.controls.indexGroupCode.value){
        this.indexGroupNameMasterList = this.cloneindexGroupNameMasterList.filter(x=>{
          return x.indexGroupCodeName === this._searchform.controls.indexGroupCode.value;
        })
        this.indexGroupNameFilterList = this.indexGroupNameMasterList;
       
      }
    }, 1000);
    
  }
  //clear button click event
  onSearchClear() {  
    this.applicationInsightService.logEvent("Indexgroup onSearchClear started");   
    this._searchform.controls.corporateBrandName.setValue('');
    this._searchform.controls.corporateBrandCodeName.setValue('');
    this._searchform.controls.indexGroupCode.setValue('');
    this._searchform.controls.indexGroupName.setValue('');
    this._searchform.controls.indexGroupCodeName.setValue('');
    this._searchform.controls['status'].setValue("active");

    this.filterItemCorporateBrand("");
    this.filterItemGN("");
    this.applicationInsightService.logEvent("Indexgroup onSearchClear ended");   
 }

  //Index group Search Form
    _searchform:FormGroup = new FormGroup({
    corporateBrandName: new FormControl(''),
    corporateBrandCodeName: new FormControl(''),
    indexGroupCode: new FormControl(''),
    indexGroupName: new FormControl(''),
    indexGroupCodeName: new FormControl(''),
    status: new FormControl()
  })

}
