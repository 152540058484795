export enum State {
  nochange = 0,
  change = 1,
  delete =2
}

export function MyEnumAware(constructor: Function) {
  constructor.prototype._state = State;
}
export enum SDSModule {
Organization="101",
Currency="103",
Country="105",
Custom="107",
Cost="109",
Market="111",
Delivery="113"
}
export enum ModulePermission {
Organization_Read="101", 
Organization_Write="102", 
Currency_Read="103",
Currency_Write="104",
Country_Read="105",
Country_Write="106",
Custom_Read="107",
Custom_Write="108",
Cost_Read="109",
Cost_Write="110",
Market_Read="111",
Market_Write="112",
Delivery_Read="113",
Delivery_Write="114"

}
export enum Organization{
corporatebrand,
index,
indexgroup,
customergroup,
subindex,
department,
garmentgroup,
markupgroup,
division,
section,
earlierplanning,
southernhemisphere,
productbrand
}
export enum Market{
channel
}
export enum Country{
geographicalcountry,
fiscalcountry
}

