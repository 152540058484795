<div class="data-table" class="org-table">
  <table id="tblIndex" [dataSource]="dataSource" #table mat-table class="full-width-table" (matSortChange)="sortData($event)" matSort
         aria-label="Elements">

    <ng-container matColumnDef="corporateBrandName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="corporateBrandName">Corporate Brand </th>
      <td mat-cell *matCellDef="let row"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')" [matTooltipDisabled]="row.corporateBrandName.length<25" matTooltip={{row.corporateBrandName}} matTooltipPosition="above" matTooltipClass="my-custom-tooltip">
          {{row.corporateBrandCode}} {{row.corporateBrandName}}
      </td>
    </ng-container>
    <ng-container matColumnDef="indexName">
      <th matSort (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header id="indexName">
        Index
      </th>
      <td mat-cell *matCellDef="let row" [matTooltipDisabled]="row.indexName.length<25" matTooltip={{row.indexName}} matTooltipPosition="above"  matTooltipClass="tooltip"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.indexName}}
      </td>
    </ng-container>
    <ng-container matColumnDef="indexCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="indexCode">
        Index Code
      </th>
      <td mat-cell *matCellDef="let row" id="custom-col100"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.indexCode}}
      </td>
    </ng-container>

    <ng-container matColumnDef="indexGroupName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="indexGroup">
        Index Group
      </th>
      <td mat-cell *matCellDef="let row" 
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.indexGroupName}}
      </td>
    </ng-container>

    <ng-container matColumnDef="indexSortOrder">
      <th matSort (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header id="sortOrder">Sort Order</th>

      <td mat-cell *matCellDef="let row" id="custom-col100"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.indexSortOrder}}
      </td>
    </ng-container>

    <ng-container matColumnDef="lastUpdatedTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="updatedDate">
        Updated
        Date
      </th>
      <td mat-cell *matCellDef="let row"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.lastUpdatedTime | customDateTime}}
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef id="Actions custom-col2"><span *ngIf="userrole">Edit | </span>View</th>
      <td mat-cell *matCellDef="let row" id="custom-col100"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
          <a id="btnEdit" *ngIf="userrole" color="primary" (click)="updateRecord(row)" matTooltip="Edit" matTooltipPosition="right"
          class="org-edit">Edit | </a>
          <a id="btnView"(click)="openDialog(row)" matTooltip="View Details" matTooltipPosition="right"
          class="org-edit">View</a>
      </td>
    </ng-container>

   <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell aligncenter" colspan="8">No records found</td>
    </tr>
  </table>
  <div id="main-wrap">
    <section id="sidebar">
      <div class="example-button-row">
        <div class="example-flex-container indicator">
          <button mat-icon-button aria-label="Datatable color code">
            <mat-icon><span class="material-icons activeindicator">
                stop
              </span></mat-icon>
            Active
          </button>
          <button mat-icon-button aria-label="Datatable color code">
            <mat-icon><span class="material-icons inactiveindicator">
                stop
              </span></mat-icon>
            Inactive
          </button>
          <button mat-icon-button aria-label="Datatable color code">
            <mat-icon><span class="material-icons draftindicator">
                stop
              </span></mat-icon>
            Draft
          </button>
          <button mat-icon-button aria-label="Datatable color code">
            <mat-icon><span class="material-icons firstdraftindicator">
                stop
              </span></mat-icon>
            First Draft
          </button>
        </div>
      </div>
    </section>
    <mat-paginator #paginator id="tblPaginator" [(length)]="length" [(pageSize)]="pageSize"
      [pageSizeOptions]="getpagesizeoptions()" (page)="pageEvent = GetIndexDatawithfilters($event)" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
