import { Component, Input, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";




@Component({

  selector: "CurrencyviewComponent",

  template: `

 <mat-dialog-content>



 </mat-dialog-content>

 <mat-dialog-actions align="end">

  <button mat-button mat-dialog-close>Cancel</button>

 </mat-dialog-actions>

 `

})

export class CurrencyviewComponent {

  rowData: any;

  constructor(

    private _mdr: MatDialogRef<CurrencyviewComponent>,

    @Inject(MAT_DIALOG_DATA) data: any

  ) {

    this.rowData = data.rowData;

  }

  CloseDialog() {

    this._mdr.close(false)

  }

}
