 <div class="form-border deppad">
    <form [formGroup]="departmentForm" class="mat-elevation-z2">
      <section class="form-section">
        <h2 mat-dialog-title>{{isDepartmentEdit === true ? 'Update' : 'New'}} Department</h2>
      </section>
      <div class="example-container add-form-field ">
        <br>
        <mat-form-field [appearance]="isDepartmentEdit==true ? 'fill': 'legacy'"
                        class="width-30">
          <mat-label> Department Code </mat-label>
          <input formControlName="departmentCode" [(ngModel)]="updateDepartmentForm.departmentCode"
                 id="inpdepartmentCode" type="number" min="1000" matInput [readonly]="isDepartmentEdit==true ?true:false">
          <mat-error *ngIf="departmentForm.controls['departmentCode'].errors?.min">
            The range for department code is 1000-9999
          </mat-error>
          <mat-error *ngIf="departmentForm.controls['departmentCode'].errors?.max">
            Department Code should be 4 digit number
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="width-35 ml-2">
          <mat-label>Department Name</mat-label>
          <input formControlName="departmentName" [(ngModel)]="updateDepartmentForm.departmentName" id="inpdepartmentname"
                 matInput>
          <mat-error *ngIf="departmentForm.controls['departmentName'].errors?.maxlength">
            Maximum 50 Characters allowed
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="width-30 ml-2">
          <mat-label>Start Season</mat-label>
          <input type="text" matInput [matAutocomplete]="autoRSS" formControlName="startSeason"
                 (ngModelChange)="filterStartSeason($event)" [(ngModel)]="updateDepartmentForm.startSeason"
                 id="inpstartseason">
          <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
            <div *ngIf="departmentForm.controls['startSeason'].hasError(validation.type)">
              {{validation.message}}
            </div>
          </mat-error>
          <mat-autocomplete autoActiveFirstOption #autoRSS="matAutocomplete"
                            [displayWith]="displayTextSeason.bind(this)">
            <mat-option *ngFor="let season of startSeasonFilterList" [value]="season.seasonNumber">
              {{season.displayName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <br>
        <mat-form-field appearance="legacy" class="width-30">
          <mat-label>End Season</mat-label>
          <input type="text" matInput [matAutocomplete]="autoRES" formControlName="endSeason"
                 (ngModelChange)="filterEndSeason($event)" [(ngModel)]="updateDepartmentForm.endSeason" id="inpendseason">
          <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
            <div *ngIf="departmentForm.controls['endSeason'].hasError(validation.type)">
              {{validation.message}}
            </div>
          </mat-error>
          <mat-autocomplete autoActiveFirstOption #autoRES="matAutocomplete"
                            [displayWith]="displayTextSeason.bind(this)">
            <mat-option *ngFor="let season of endSeasonFilterList" [value]="season.seasonNumber">
              {{season.displayName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field [appearance]="isDepartmentEdit==true ? 'fill': 'legacy'" class="width-40 ml-2">
          <mat-label>Corporate Brand</mat-label>
          <input type="text" id="inpcorporatebrand" matInput [matAutocomplete]="autoCB"
                 (ngModelChange)="filterItemCB($event)" [formControl]="corporateBrandId" [readonly]="isDepartmentEdit==true ?true:false">
          <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
            <div *ngIf="this.corporateBrandId.hasError(validation.type)">{{validation.message}}</div>
          </mat-error>
          <mat-autocomplete autoActiveFirstOption #autoCB="matAutocomplete" [displayWith]="displayTextCB.bind(this)">
            <mat-option (onSelectionChange)="updateDepartmentForm.sectionId=''" *ngFor="let CBname of corporateBrandFilterList" [value]="CBname.corporateBrandId">
              {{CBname.corporateBrandCode}} {{CBname.corporateBrandName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <br>
        <mat-form-field appearance="legacy" class="width-30">
          <mat-label>Section</mat-label>
          <input type="text" matInput [matAutocomplete]="autoS" formControlName="sectionId"
                 (ngModelChange)="filterItemSection($event)" [(ngModel)]="updateDepartmentForm.sectionId" id="inpsectionid">
          <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
            <div *ngIf="departmentForm.controls['sectionId'].hasError(validation.type)">{{validation.message}}</div>
          </mat-error>
          <mat-autocomplete autoActiveFirstOption #autoS="matAutocomplete" [displayWith]="displayTextSection.bind(this)">
            <mat-option *ngFor="let section of sectionFilterList" [value]="section.sectionId">
              {{section.sectionCode+"-"+section.sectionName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="width-35 ml-2">
          <mat-label>Garment Group</mat-label>
          <input type="text" matInput [matAutocomplete]="autoGG" formControlName="garmentGroupId"
                 (ngModelChange)="filterItemGarmentGroup($event)" [(ngModel)]="updateDepartmentForm.garmentGroupId"
                 id="inpgarmentgroupid">
          <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
            <div *ngIf="departmentForm.controls['garmentGroupId'].hasError(validation.type)">{{validation.message}}</div>
          </mat-error>
          <mat-autocomplete autoActiveFirstOption #autoGG="matAutocomplete"
                            [displayWith]="displayTextGarmentGroup.bind(this)">
            <mat-option *ngFor="let garmentGroup of garmentGroupFilterList" [value]="garmentGroup.garmentGroupId">
              {{garmentGroup.garmentGroupCode+"-"+garmentGroup.garmentGroupName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <br>
        <mat-checkbox formControlName="globalInShopWeek" [(ngModel)]="updateDepartmentForm.globalInShopWeek"
                      id="inpglobalinshopweek" class="example-margin width-20" color='primary'>Global ISW </mat-checkbox>
        <mat-checkbox formControlName="active" [disabled]="!isDepartmentEdit" [(ngModel)]="updateDepartmentForm.active"
                      id="inpactive" class="example-margin width-20 ml-2" color='primary'>Active</mat-checkbox>
        <mat-checkbox (change)="draftChange($event)" formControlName="draft" [disabled]="!isDepartmentEdit" [(ngModel)]="updateDepartmentForm.draft" id="inpdraft"
                      class="example-margin width-12  ml-2" color='primary'>Draft</mat-checkbox>
        <br>
        <label [ngClass]="(isDraftWarning === true) ? 'displaycls':'hiddedencls'" style="color: #ff0202;font-size: 12px;font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Department will be moved to draft state only when you click on Update button.</label>
        <input type="hidden" id="inpdepartmentid" formControlName="departmentId"
               [(ngModel)]="updateDepartmentForm.departmentId">
        <br>
        <div class="tab" id="tab" *ngIf=depSubTab>
          <button id="depSubIndexTab" [ngClass]="{'tabActive' : depSubIndexTab==true}"
                  (click)="loadTabComponentOnClck('Dep-SubIndex')">
            Sub Index
          </button>
          <button id="depCustomCustomerGroupTab" [ngClass]="{'tabActive' : depCustomCustomerGroupTab==true}"
                  (click)="loadTabComponentOnClck('Dep-CustCustomerGroup')">
            Custom Customer Group
          </button>
          <button id="depMarkupGroupTab" [ngClass]="{'tabActive' : depMarkupGroupTab==true}"
                  (click)="loadTabComponentOnClck('Dep-MarkupGroup')">
            Markup Group
          </button>
        </div>
        <department-subindexlist *ngIf="depSubIndexTab" id="subindexlist" (departmentcall)="fnUpdateDepartment(parentDepartmentId,2)" [departmentDraft]="departmentDraft" [subIndexSeasonlist]=subIndexSeasonlist [subIndexList]="subIndexList" [seasonList]=seasonList [parentCorporateBrandName]=corporateBrandNameFrCascade [parentDepartmentId]=parentDepartmentId [depSubIndex]=depSubIndexList>
        </department-subindexlist>
        <department-customcustomergrouplist id="customcustomergrouplist" *ngIf="depCustomCustomerGroupTab" [parentDepartmentId]=parentDepartmentId [departmentDraft]="departmentDraft" [customCustomerGrouplist]="customCustomerGrouplist"
                                            [depCustomCustomerGroup]=depCustomCustomerGroupList (departmentcall)="fnUpdateDepartment(parentDepartmentId,2)">
        </department-customcustomergrouplist>
        <department-markupgrouplist id="markupgrouplist" *ngIf="depMarkupGroupTab" (departmentcall)="fnUpdateDepartment(parentDepartmentId,2)" [departmentDraft]="departmentDraft" [markupGroupList]="markupGroupList" [parentCorporateBrandName]=corporateBrandNameFrCascade [parentDepartmentId]=parentDepartmentId [markupSeaasonlist]=markupSeaasonlist [seasonList]=seasonList
                                    [depMarkupGroup]=depMarkupGroupList>
        </department-markupgrouplist>
        <br>
        <mat-dialog-actions *ngIf="userrole" style="display: block !important">
          <button [disabled]="(isDepartmentEdit === true) ?  !departmentForm.dirty : false"
                  [ngClass]="(isDepartmentEdit === true) ? 'mat-raised-button ml-1':'mat-raised-button ml-1 mat-primary'"
                  id="inpbtnsubmit" type="submit"
                  (click)="onSubmit()">
            {{isDepartmentEdit === true ? 'Update' : 'Save & Proceed'}}
          </button>
          <button class="mat-raised-button mat-primary  ml-2" id="inpbtncancel" (click)="onClear()">Cancel</button>
        </mat-dialog-actions>
        <span *ngIf="!userrole" style="color: #ff0202;font-size: 12px;font-weight: 400;">You can't save changes since you have Read access.</span>
      </div>
    </form>
  </div>

