import { Component, OnInit, ViewChild, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AccountService } from 'src/app/shared/services/account.service';
import { CustomeDateValidator } from 'src/app/shared/components/customedatevalidator/customedatevalidator'
import { FiscalcountryService } from 'src/app/services/country/fiscalcountry/fiscalcountry.service';
import { State } from '../../../../../models/enum';
import { DialogBoxService } from 'src/app/shared/services/dialog-box.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
export interface iVatAll {
  fiscalCountryVATId: any;
  vat: any;
  toDate: any;
  fromDate: any;
  lastUpdatedBy: any;
  isDelete: any;
  isFirstDraft: any;
}
@Component({
  selector: 'app-vat',
  templateUrl: './vat.component.html',
  styleUrls: ['./vat.component.css']
})
export class VatComponent implements OnInit {
  @Output() isVatFormValid: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatTable) table: MatTable<iVatAll>;
  @ViewChild(MatSort) sort: MatSort;
  updatefcvatForm: any;
  vatForm = true;
  rows: any;
  vatControl = new FormControl();
  vat: any;
  fromDate: any;
  toDate: any;
  lastUpdatedBy: any;
  tempRowIndex: number = 0;
  TotalVatLength: number = 0;
  isDelete: boolean = false;
  fiscalCountryVATId: any = 0;
  vatStatus: any = 0;
  isEditFcVat: boolean = false;
  isFiscalCountryDraft: boolean;
  dataSource: MatTableDataSource<iVatAll>;
  sortByColumnName: any = "vat";
  sortByOrder: any = "asc";
  displayedColumns: any;
  errorMsg: any;
  isFirstDraft: boolean = false;
  userrole: boolean;
  constructor(
    public _fiscalcountryService: FiscalcountryService,
    public _dialogService: DialogBoxService,
    private applicationInsightService: ApplicationInsightService,
    public account: AccountService) {
    this.rows = [];
    this.updatefcvatForm = Object.assign({ active: true, draft: true });
  }
  ngOnInit(): void {
    this.displayedColumns = ["vat", "fromDate", "toDate", "Edit", "Delete"];
    this.fromDate = new FormControl(new Date());
    this.toDate = new FormControl(new Date());
    this.account.getUserInfo();
    let list = this.account.userInfo.Role;
    let rolelist = list.split(",");

    this.userrole = rolelist.length <= 1 ? rolelist[0] == 'sds.Reader' ? true : false : false;
    if (rolelist.length > 1) {
      for (let i = 0; i < rolelist.length; i++) {
        if (rolelist[i] != 'sds.Reader') {
          this.userrole = false;
        }
      }
    }
    this.dataSource = new MatTableDataSource([]);
  }
  public validateVatChildForm(data: any) {
    if (data === true) {
      if (this._fcvatForm.valid === true) {
        this.isVatFormValid.emit(true);
      } else {
        this.isVatFormValid.emit(false);
      }
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    this.onClear()
  }

  onAddRow() {
    this.applicationInsightService.logEvent("VAT Add Row Started");
    let isvalid = true;
    this._fcvatForm.controls.vat.updateValueAndValidity();
    this._fcvatForm.controls.fromDate.updateValueAndValidity();
    if (this._fcvatForm.valid && this._fcvatForm.controls.vat.value != '' && this._fcvatForm.controls.fromDate.value != '') {
      let todaysdate = new Date().getFullYear();
      if (this.rows.length >= 1 && todaysdate > new Date(this._fcvatForm.controls.fromDate.value).getFullYear()) {
        if (this._fcvatForm.controls.toDate.value == null || this._fcvatForm.controls.toDate.value == '') {
          this._fcvatForm.get('toDate').setValidators(Validators.required);
          this._fcvatForm.controls['toDate'].updateValueAndValidity();
          isvalid = false;
        }
      }
      else {
        this._fcvatForm.controls.toDate.clearValidators();
      }
      /* istanbul ignore if */
      if (isvalid) {
        this.vat = this._fcvatForm.controls.vat.value;
        this.fromDate = this._fcvatForm.controls.fromDate.value;
        this.fromDate = this._fiscalcountryService.fnToConvertDate(this.fromDate);
        this.toDate = this._fcvatForm.controls.toDate.value == '' ? null : this._fcvatForm.controls.toDate.value;
        this.toDate = this.toDate!=null ? this._fiscalcountryService.fnToConvertDate(this.toDate) : this.toDate;
        this.lastUpdatedBy = this.account.userInfo.Email;

        if (this._fcvatForm.controls.fiscalCountryVATId.value > 0 || this._fcvatForm.controls.tempRowIndex.value > 0) {
          this.vatStatus = State.change;
          if (this.vatStatus = 1) {
            this.isFirstDraft = true;
          }
          let index = this._fcvatForm.controls.fiscalCountryVATId.value > 0 ? this.rows.findIndex(d => d.fiscalCountryVATId === this._fcvatForm.controls.fiscalCountryVATId.value)
            : this.rows.findIndex(d => d.tempRowIndex === this._fcvatForm.controls.tempRowIndex.value); //find index in your array
          this.rows[index].fromDate = this.fromDate;
          this.rows[index].toDate = this.toDate;
          this.rows[index].lastUpdatedBy = this.lastUpdatedBy;
          this.rows[index].vat = this.vat;
          this.rows[index].isFirstDraft = this.isFirstDraft;
          this.rows[index].fiscalCountryVATId = this._fcvatForm.controls.fiscalCountryVATId.value;
          if (this.rows.length != 0)
            this.rows = this._fiscalcountryService.updateValidTo(this.rows, this._fcvatForm);

        }
        else {
          this.vatStatus = this.isEditFcVat == true ? State.change : State.nochange;
          if (this.rows.length != 0)
            this.rows = this._fiscalcountryService.updateValidTo(this.rows, this._fcvatForm);
          if (this.vatStatus = 1) {
            this.isFirstDraft = true;
          }
          this.rows.push(this.createVatFormGroup());
        }

        this.dataSource = new MatTableDataSource(this.rows);
        this.onClear();
      }
    }
    this.applicationInsightService.logEvent("VAT Add Row Ended");
  }
  //While clicking Edit button load respective data to entry page
  updateRecord(rowIndex: any) {
    this.applicationInsightService.logEvent("VAT Edit by id Started");
    this._fcvatForm.controls.vat.setValue(rowIndex.vat);
    this._fcvatForm.controls.fromDate.setValue(rowIndex.fromDate);
    this._fcvatForm.controls.toDate.setValue(rowIndex.toDate);
    this._fcvatForm.controls.fiscalCountryVATId.setValue(rowIndex.fiscalCountryVATId);
    this._fcvatForm.controls.tempRowIndex.setValue(rowIndex.tempRowIndex);
    this.applicationInsightService.logEvent("VAT Edit by id Ended");
  }
  onRemoveRow(rowIndex: any) {
    this.applicationInsightService.logEvent("VAT Delete Started");
    let index = rowIndex.fiscalCountryVATId > 0 ? this.rows.findIndex(d => d.fiscalCountryVATId === rowIndex.fiscalCountryVATId) : this.rows.findIndex(d => d.fromDate === rowIndex.fromDate); //find index in your array
    this.vatStatus = rowIndex.fiscalCountryVATId > 0 ? State.change : State.nochange;
    this.rows.splice(index, 1);
    this.dataSource = new MatTableDataSource(this.rows);
    this.vatStatus = (this.rows.length == 0 && this.TotalVatLength > 0) ? State.delete : this.vatStatus;
    this.applicationInsightService.logEvent("VAT Delete Ended");
  }
  createVatFormGroup() {
    return {
      vat: this.vat,
      fromDate: this.fromDate,
      toDate: this.toDate,
      lastUpdatedBy: this.lastUpdatedBy,
      fiscalCountryVATId: this.fiscalCountryVATId,
      tempRowIndex: (this.tempRowIndex = this.tempRowIndex + 1),
      isDelete: true,
      isFirstDraft: this.isFirstDraft
    };
  }


  onClear() {
    this.applicationInsightService.logEvent("VAT Clear Started");
    this._fcvatForm.controls.vat.clearValidators();
    this._fcvatForm.controls.fromDate.clearValidators();
    this._fcvatForm.controls.toDate.clearValidators();
    this._fcvatForm.controls.vat.setValue('');
    this._fcvatForm.controls.fromDate.setValue('');
    this._fcvatForm.controls.toDate.setValue('');
    this._fcvatForm.controls.fiscalCountryVATId.setValue('');
    this._fcvatForm.controls.tempRowIndex.setValue('');
    this.fnSetControlsValidation();
    this.applicationInsightService.logEvent("VAT Clear Ended");
  }
  //to clear after saving from parent component
  clearScope() {
    this.rows = [];
    this.dataSource = new MatTableDataSource([]);
    this.tempRowIndex = 0;
    this.fnSetControlsValidation();
  }

  fnFromDateValidation() {
    this.applicationInsightService.logEvent("VAT FromDateValidation Started");
    if (this._fcvatForm.controls.fromDate.value != null && this._fcvatForm.controls.fromDate.value != "") {
      let result = this._fiscalcountryService.fromDateValidatorfn(this.rows, "VAT", this._fcvatForm.controls.fromDate);
      if (result == 'Invalid') {
        this._dialogService.openConfirmDialog('vat already exist  . Do you want to continue ?')
          .afterClosed().subscribe(res => {
            if (res) { }
            else {
              this._fcvatForm.controls.fromDate.setValue('');
            }
          })
      }
      else if (result == 'NotAllowed') {
        this._fcvatForm.get('fromDate').setValidators(Validators.required);
        this._fcvatForm.controls['fromDate'].updateValueAndValidity();
        this._fcvatForm.get('fromDate')?.setErrors({ invalidDate: 'Valid To already exists in selected vat %' });
      }
    }
    this.applicationInsightService.logEvent("VAT FromDateValidation Ended");
  }
  fnToDateValidation() {
    this.applicationInsightService.logEvent("VAT ToDateValidation Started");
    if (this._fcvatForm.controls.fromDate.value != null && this._fcvatForm.controls.toDate.value != "") {
      let result = this._fiscalcountryService.toDateValidatorfn(this.rows, "VAT", this._fcvatForm.controls.toDate);
      if (result == 'Invalid') {
        this._fcvatForm.get('toDate').setValidators(Validators.required);
        this._fcvatForm.controls['toDate'].updateValueAndValidity();
        this._fcvatForm.get('toDate')?.setErrors({ invalidDate: 'Valid To already exists in selected vat %' });
      }
    }
    this.applicationInsightService.logEvent("VAT ToDateValidation Ended");
  }

  _fcvatForm: FormGroup = new FormGroup({
    fiscalCountryVATId: new FormControl(''),
    vat: new FormControl('', Validators.required),
    fromDate: new FormControl(null, Validators.required),
    toDate: new FormControl(null),
    lastUpdatedBy: new FormControl(''),
    tempRowIndex: new FormControl(''),
  })
  fnSetControlsValidation() {
    this._fcvatForm.controls.vat.setValidators([Validators.required]);
    this._fcvatForm.controls.fromDate.setValidators([Validators.required]);
  }
}
