import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonUtilityService } from 'src/app/services/organization/commonutility/commonutility.service';
import { CorporatebrandService } from 'src/app/services/organization/corporatebrand/corporatebrand.service';
import { EarlierplanningService } from 'src/app/services/organization/earlierplanning/earlierplanning.service';
import { constant } from 'src/app/shared/const/constant';
import { AccountService } from 'src/app/shared/services/account.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { EarlierplanningdepartmentComponent } from '../earlierplanningdepartment/earlierplanningdepartment/earlierplanningdepartment.component';
import { ModulePermission } from 'src/app/models/enum';
import { UserAccess } from "src/app/models/userAccess";
export class dropDownCollections {
  SeasonFilterList: any;
  CorporateBrandFilterList: any;
}
export class depparamCollections {
  seasonNumber: any;
  weekNumber: any;
  corporateBrandId: any;
  isEarlierplanningEdit: any
}
@Component({
  selector: 'app-earlierplanningform',
  templateUrl: './earlierplanningform.component.html',
  styleUrls: ['./earlierplanningform.component.css']
})
export class EarlierplanningformComponent implements OnInit {
  @Output() dropDownCollections = new EventEmitter<any>();
  @ViewChild('seasonDepartments') public seasonDepartments: EarlierplanningdepartmentComponent;
  corporateBrandMasterList: any[] = [];
  corporateBrandFilterList: any[] = [];
  seasonnumberFilterList: any[] = [];
  seasonnumberMasterFilterList: any[] = [];
  seasonMasterlist: any;
  seasonFilterList: any;
  isPublished: boolean = true;
  isActiveDisable: boolean = true;
  updateEarlierplanningform: any;
  isEarlierplanningEdit: any = false;
  userrole: boolean;
  obj = new dropDownCollections();
  depcollection = new depparamCollections();
  subscription: Subscription;
  seasonNumber: any = "";
  globalSeason: any;
  weekNumber: any = "";
  corporateBrandId: any = null;
  seasonyear: any;
  editevent: any = false;
  departmentIds: any;
  isShown: any
  globalEpId: any;
  validationMessages: any;
  users:any;

  constructor(private changeDetector: ChangeDetectorRef, public toastr: ToastrService, public account: AccountService, public _corporatebrandService: CorporatebrandService, public _earlierplanningService: EarlierplanningService, private applicationInsightService: ApplicationInsightService, public _userAccess: UserAccess, public _commonutilityService: CommonUtilityService) {
    this.updateEarlierplanningform = Object.assign({ active: true, draft: true });
    this.users = JSON.parse(localStorage.getItem("users") || "[]")
  }

  ngOnInit(): void {
    this._commonutilityService.userAccessSource.next();
    this.userrole =this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this._earlierplanningform.controls.lastUpdatedBy.setValue(JSON.parse(localStorage.getItem("UserInfo")).Email || "[]");
    this._earlierplanningform.controls.weekNumber.reset();
    this._earlierplanningService.getSeasonByRange(2).subscribe((data: any) => {
      this.applicationInsightService.logEvent("Season listing started");
      this.seasonMasterlist = this.seasonFilterList = data.seasonByRange;
      this.seasonMasterlist = this.seasonMasterlist?.sort((a, b) => (a?.seasonNumber < b?.seasonNumber ? 1 : -1));
      this.dropDownCollectionfn();
      this.fnSetControlsValidation();
      this.validationMessages = this._commonutilityService.validationMsgs;
      this.applicationInsightService.logEvent("Season listing ended");
    });

    // Once organization is deployed we can call Organization service instead of BOCOS.
    this._corporatebrandService.getCorporateBrandBOCOSList().subscribe((data: any) => {
      this.applicationInsightService.logEvent("CorporateBrand listing Started");
      this.corporateBrandMasterList = this.corporateBrandFilterList = data.getCorporateBrandAll.filter(object => {
        return object['active'] !== false;
      })
      this.corporateBrandFilterList = this.corporateBrandMasterList;
      this.dropDownCollectionfn();
      this.applicationInsightService.logEvent("CorporateBrand listing Ended");
    });
    this.subscription = this._earlierplanningService.earlierPlanningId$.subscribe(
      earlierPlanningId => {
        this.updateEarlierplanning(earlierPlanningId);
      });

  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  public dropDownCollectionfn() {
    this.obj.SeasonFilterList = this.seasonMasterlist;
    this.obj.CorporateBrandFilterList = this.corporateBrandMasterList;
    //Adding this condition to restrict multiple calls to load dropdown
    if (this.seasonMasterlist != undefined && this.seasonMasterlist.length > 0 && this.corporateBrandMasterList.length > 0) {
      this.dropDownCollections.emit(this.obj);
    }
  }
  receivedChildHandler(emitEarlierplanningdep: any) {
    this.applicationInsightService.logEvent("Earlierplanning receivedChildHandler Started");
    this._earlierplanningform.controls.earlierPlanningId.setValue(0)
    this._earlierplanningform.controls.departmentIds.setValue(emitEarlierplanningdep.depIds);
    this._earlierplanningform.controls.earlierPlanningCreateDepartments.setValue(emitEarlierplanningdep.EarlierPlanningDepartments);
    this._earlierplanningform.controls.earlierPlanningUpdateDepartments.setValue(emitEarlierplanningdep.EarlierPlanningDepartments);
    if (emitEarlierplanningdep.isDirtyEarlierplanning != false) {
      this.isEarlierplanningEdit = true;
      this._earlierplanningform.markAsDirty();
      this._earlierplanningform.controls.earlierPlanningId.setValue(1);
    }
    this.applicationInsightService.logEvent("Earlierplanning receivedChildHandler Ended");
  }
  displayTextSeason(seasonNumber: any) {
    this.globalSeason = seasonNumber;
    if (this.isEarlierplanningEdit == false && seasonNumber != "" && seasonNumber != null && seasonNumber != undefined) {
      this.isOdd(seasonNumber);
    }
    if (seasonNumber == null || seasonNumber == "")
      return null;
    else if (this.seasonMasterlist != undefined && seasonNumber != null)
      return this.seasonMasterlist.find(x => x.seasonNumber === seasonNumber).displayName;
  }
  isOdd(num: any) {
    if (num != null) {
      if (num % 2 == 0) {
        this._earlierplanningform.controls.weekNumber.setValue("17");
      }
      else
        this._earlierplanningform.controls.weekNumber.setValue("45");
      this.seasonyear = this.seasonNumber.substring(0, 4);
      this.depcollection.weekNumber = this.seasonyear.concat(this._earlierplanningform.controls.weekNumber.value);
      this._earlierplanningform.controls.corporateBrandId.setValue("");
    }
  }
  displayTextCB(corporateBrandId: any) {
    if (corporateBrandId === 0 || corporateBrandId != "" && this.isEarlierplanningEdit != true) {
      this.clearDep();
      this.corporateBrandId = corporateBrandId;
      this.depcollection.seasonNumber = this.globalSeason;
      this.depcollection.corporateBrandId = this.corporateBrandId;
      this.seasonDepartments.checklistSelection.clear();
      this._earlierplanningService.getEarlierPlanningDepartmentFromList(this.depcollection);
      this.depcollection.seasonNumber = '';
      this.depcollection.corporateBrandId = '';
    }
    if (corporateBrandId === null || corporateBrandId === "")
      return null;
    else if (this.corporateBrandMasterList != undefined && corporateBrandId != null)
      return this.corporateBrandMasterList.find(x => x.corporateBrandId == corporateBrandId).corporateBrandName;
  }
  filterSeason(filterKeySS: string) {
    this.applicationInsightService.logEvent("filter Season started");
    if (filterKeySS != null && filterKeySS != "") {
      this.seasonFilterList = this.seasonMasterlist.filter(cb => cb.displayName.toString().toLowerCase().includes(filterKeySS.toString().toLowerCase()));
      this.seasonFilterList = this.seasonFilterList?.sort((a, b) => (a?.seasonNumber < b?.seasonNumber ? 1 : -1));
      this.fnSetControlsValidation();
    }
    else if (this.seasonMasterlist != null || undefined) { this.seasonFilterList = this.seasonMasterlist?.sort((a, b) => (a?.seasonNumber < b?.seasonNumber ? 1 : -1)); }
    this.applicationInsightService.logEvent("filter Season ended");
  }
  changeInput(e: any) {
    if(this.isEarlierplanningEdit){
      if (e.keyCode === 8 || e.keyCode === 46 ) {
        this.onClear();
      }
    }
  }
  filterItemCorporateBrand(filterKey: string) {
    this.applicationInsightService.logEvent("EarlierPlanning filtering CorporateBrand Started");
    if (filterKey != null && filterKey != "") {
      if(this.seasonDepartments.checklistSelection.selected.length>0){
        this.onClear();
      }
      this.corporateBrandFilterList = this.corporateBrandMasterList.filter(cb => cb.corporateBrandName.toLowerCase().includes(filterKey.toString().toLowerCase()));
    } else { this.corporateBrandFilterList = this.corporateBrandMasterList; }
    this.applicationInsightService.logEvent("EarlierPlanning filtering CorporateBrand Ended");
  }
  onSubmit() {
    this._earlierplanningform.controls['seasonNumber'].updateValueAndValidity();
    this._earlierplanningform.controls['corporateBrandId'].updateValueAndValidity();
    this.fnvalidationdepartment();
    if (this._earlierplanningform.controls.departmentIds.value != "" && this._earlierplanningform.controls.departmentIds.value != undefined && this._earlierplanningform.controls.departmentIds.value != -1) {
      if (this._earlierplanningform.value.earlierPlanningId == undefined || this._earlierplanningform.value.earlierPlanningId == 0) {
        this.seasonDepartments._departmentForm.controls['departmentIds'].setErrors(null);
        this._earlierplanningService.createEarlierPlanning(this._earlierplanningform.value).subscribe
          (data => {
            this.toastr.success(data.message, "", { timeOut: constant.toasterTimeout });
            this._earlierplanningService.refreshEarlierPlanningList();
            this.onClear();

          },
            error => { this.toastr.error(error, "", { timeOut: constant.toasterTimeout }) })
      }
      else {
        this.seasonDepartments._departmentForm.controls['departmentIds'].setErrors(null);
        this._earlierplanningService.updateEarlierPlanning(this._earlierplanningform.value).subscribe
          (data => {
            this.onClear();
            this.toastr.success(data.message, "", { timeOut: constant.toasterTimeout });
            this._earlierplanningService.refreshEarlierPlanningList();

          },
            error => { this.toastr.error(error, "", { timeOut: constant.toasterTimeout }) })
      }
    }
  }
  updateEarlierplanning(earlierPlanningId: any) {
    this.applicationInsightService.logEvent("EarlierplanningForm Edit by Id Started");
    if (earlierPlanningId != undefined) {
      this.isEarlierplanningEdit = true;
      this.isActiveDisable = false;
      if (this.globalEpId != earlierPlanningId) {
        this._earlierplanningService.getEarlierPlanningById(earlierPlanningId).subscribe((data: any) => {
          this.globalEpId = earlierPlanningId;
          if (this.editevent == false) {
            let obj = new depparamCollections();
            let seasonedit = data.earlierPlanning.seasonNumber.substring(0, 4);
            obj.weekNumber = seasonedit.concat(data.earlierPlanning.weekNumber);
            obj.seasonNumber = data.earlierPlanning.seasonNumber;
            obj.corporateBrandId = data.earlierPlanning.corporateBrandId;
            this.editevent = true;
            this._earlierplanningService.getEarlierPlanningDepartmentFromList(obj)
          }
          this.updateEarlierplanningform = data.earlierPlanning;
          if (!this.seasonDepartments.checklistSelection.selected.length) {
            this._earlierplanningService.getEarlierPlanningIdFromList(earlierPlanningId);
          }
          this.seasonDepartments.updateEarlierplanningDepartment(this.updateEarlierplanningform.earlierplanningdepartments);
        });
      }
    }
    this.applicationInsightService.logEvent("EarlierplanningForm Edit by Id Ended");
  }
  onClear() {
    this.applicationInsightService.logEvent("EarlierplanningForm Clear Started");
    this._earlierplanningform.controls.corporateBrandId.clearValidators();
    this._earlierplanningform.controls.weekNumber.clearValidators();
    this._earlierplanningform.controls.seasonNumber.clearValidators();
    this._earlierplanningform.controls.corporateBrandId.reset();
    this._earlierplanningform.controls.seasonNumber.reset();
    this._earlierplanningform.controls.earlierPlanningId.reset('');
    this._earlierplanningform.controls.seasonNumber.setValue('');
    this._earlierplanningform.controls.weekNumber.setValue('');
    this._earlierplanningform.controls.earlierPlanningId.setValue(0);
    this._earlierplanningform.controls.draft.setValue(true);
    this._earlierplanningform.controls.active.setValue(true);
    this._earlierplanningform.controls['seasonNumber'].updateValueAndValidity();
    this._earlierplanningform.controls['corporateBrandId'].updateValueAndValidity();
    this.fnSetControlsValidation();
    this.clearDep();
    this.isEarlierplanningEdit = false;
    this.isActiveDisable = true;
    this.editevent = false;
    this.globalEpId="";
    this.applicationInsightService.logEvent("EarlierplanningForm Clear Ended");
  }
  clearDep() {
    if (this.seasonDepartments != undefined || null) {
      this.seasonDepartments._departmentForm.controls.departmentIds.clearValidators();
      this.seasonDepartments._departmentForm.controls.departmentIds.setValue('');
      this.seasonDepartments._departmentForm.controls.departmentIds.reset();
      this.seasonDepartments.clearAll();
      this.seasonDepartments.clearTree();
      this.seasonDepartments.isDepartmentSelect = false;
      this.seasonDepartments.isUpdate = false;
    }
  }

  //validating earlierplanning
  fnvalidationdepartment() {
    if (this.departmentIds != -1) {
      if (this.departmentIds == '' || this.departmentIds == null || this.departmentIds == undefined) {
        if (this.seasonDepartments._departmentForm != null || '') {
          this.seasonDepartments._departmentForm.markAllAsTouched();
          this._earlierplanningform.controls.seasonNumber.setValidators([Validators.required]);
          this._earlierplanningform.controls.corporateBrandId.setValidators([Validators.required]);
          this.seasonDepartments._departmentForm.controls.departmentIds.setValidators([Validators.required]);
          this.seasonDepartments._departmentForm.controls['departmentIds'].updateValueAndValidity();
        }
      }
      else {
        if (this.seasonDepartments._departmentForm != null || '') {
          this.seasonDepartments._departmentForm.controls.departmentIds.clearValidators();
          this._earlierplanningform.controls.seasonNumber.clearValidators();
          this._earlierplanningform.controls.corporateBrandId.clearValidators();
          this.seasonDepartments._departmentForm.controls.departmentIds.setValue('');
        }
      }
    }

  }
  fnSetControlsValidation() {
    this._earlierplanningform.controls.seasonNumber.setValidators([this._commonutilityService.acSeasonValidator(this._earlierplanningform.controls.seasonNumber.value, null), Validators.required])

  }
  //EarlierPlanning create Form
  _earlierplanningform: FormGroup = new FormGroup({
    earlierPlanningId: new FormControl(''),
    corporateBrandId: new FormControl('', [Validators.required]),
    departmentIds: new FormControl(''),
    earlierPlanningCreateDepartments: new FormControl(''),
    earlierPlanningUpdateDepartments: new FormControl(''),
    weekNumber: new FormControl(''),
    seasonNumber: new FormControl(null, [Validators.required]),
    lastUpdatedBy: new FormControl(''),
    active: new FormControl(true),
    draft: new FormControl(true),
  })
}

