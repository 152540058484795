import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import { Component, EventEmitter, Injectable, Input, OnInit, Output } from "@angular/core";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { BehaviorSubject } from "rxjs";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { SouthernhemisphereService } from "src/app/services/organization/southernhemisphere/southernhemisphere.service";
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { LoaderService } from "src/app/shared/services/loader.service";
export class emitCollections {
  datasource: any = [];
  depIds: any;
  SouthernHemisphereDepartments: any = [];
  isDirtySouthernhemisphere;
}
export class SouthernHemisphereDepartments {
  departmentId: any;
}
export class SouthernHemisphereUpdateDepartments {
  departmentCode: any;
  isDirty: any;
  isDeleted: any;
  isOrderAvaible: any;
}
/**
 * Node for to-do item
 */
export class DepNode {
  children?: DepNode[];
  item: any;
  id: any;
}

/** Flat to-do item node with expandable and level information */
export class DepFlatNode {
  item: any;
  id: any;
  level: number;
  expandable: boolean;
}

/**
 * The Json object for to-do list data.
 */
const TREE_DATA: DepNode[] = [];

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable({ providedIn: "root" })
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<DepNode[]>([]);
  treeData: any[];
  departmentFilterList: any[];
  isDepartmentSelect = false;
  isfilter=false;
  get data(): DepNode[] {
    return this.dataChange.value;
  }

  constructor(public _southernhemisphereService: SouthernhemisphereService, private applicationInsightService: ApplicationInsightService) {
    this._southernhemisphereService.southernhemisphereServiceDepartment$.subscribe(
      depparamCollections => {
        this.initialize(depparamCollections);
      });
  }

  getAllNodes(array: any) {
    this.applicationInsightService.logEvent("Southernhemisphere DepartmentDropdown data Binding started");
    let arr = [];
    array.forEach((item: any) => {
      let childArr = [];
      item.departments.forEach((children: any) => {
        childArr.push({ 'item': children.departmentCode + ',' + children.departmentName.replace(/[^\w\s]/gi, '') + ',' + children.departmentId + ',' + children.isSouthernHemisphereDepartment })

   })
      arr.push({ 'item': item.sectionCode + ',' + item.sectionName, 'children': childArr })
    });
    this.applicationInsightService.logEvent("Southernhemisphere DepartmentDropdown data Binding ended");
    return arr;
  }

  initialize(depParam: any): void {
    this.applicationInsightService.logEvent("Bind SouthernHemisphere DepartmentDropdown Api call started");
    if (depParam.seasonNumber != "" && depParam.channelId != "" && depParam.channelId != null && depParam.seasonNumber != null && depParam.corporateBrandId != null)
      this._southernhemisphereService.getSouthernHemisphereDepartmentsAndSections(depParam).toPromise().then((data: any) => {
        this.departmentFilterList = this.getAllNodes(data.southernHemisphereSectionsAndDepartments);
        const TREE_DATA: DepNode[] = this.departmentFilterList;
        this.treeData = TREE_DATA;
        const datan = TREE_DATA;
        this.dataChange.next(datan);
      }
      );
    else {
      const TREE_DATA: DepNode[] = [];
      this.treeData = TREE_DATA;
      const datan = TREE_DATA;
      this.dataChange.next(datan);
    }
    this.applicationInsightService.logEvent("Bind SouthernHemisphere DepartmentDropdown Api call ended");
  }
}

@Component({
  selector: 'app-southernhemispheredepartment',
  templateUrl: './southernhemispheredepartment.component.html',
  styleUrls: ['./southernhemispheredepartment.component.css'],
  providers: [ChecklistDatabase]
})
export class SouthernhemispheredepartmentComponent implements OnInit {
  searchTextboxControl = new FormControl();
  public searchString = '';
  @Output() emitSouthernhemispheredep = new EventEmitter();
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<DepFlatNode, DepNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<DepNode, DepFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: DepFlatNode | null = null;

  /** The new item's name */
  newItemName = "";

  treeControl: FlatTreeControl<DepFlatNode>;

  treeFlattener: MatTreeFlattener<DepNode, DepFlatNode>;

  dataSource: MatTreeFlatDataSource<DepNode, DepFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<DepFlatNode>(true /* multiple */);

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  SouthernHemisphereUpdateDepartments: any = [];
  depIds: string[];
  depCodes: any;
  selectedDepartments: any[];
  isDepartmentSelect = false;
  isUpdate = false;
  constructor(private _database: ChecklistDatabase,public loaderService: LoaderService) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<DepFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );

    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
      if(this._database.isfilter!=true)
      this._database.isfilter=false;
      this.checkbydefault(data)
    });
  }
  ngOnInit() {
    this.checklistSelection.clear();
 }
  ngAfterContentChecked() {
    this.isDepartmentSelect = true;
  }
  getLevel = (node: DepFlatNode) => node.level;

  isExpandable = (node: DepFlatNode) => node.expandable;

  getChildren = (node: DepNode): DepNode[] => node.children;

  hasChild = (_: number, _nodeData: DepFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: DepFlatNode) => _nodeData.item === "";

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: DepNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item
        ? existingNode
        : new DepFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: DepFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: DepFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: DepFlatNode, checked: any): void {
  this.checklistSelection.toggle(node);
    this.treeControl.expand(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
      if(node.level==0 && checked.checked==false){
        this.checklistSelection.deselect(...descendants);
      }
      else{
        this.checklistSelection.select(...descendants)
      }
    // Force update for the parent
    descendants.every(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node, checked);
    for (let i = 0; i < descendants.length; i++) {
      let ShUpdateDepartment = new SouthernHemisphereUpdateDepartments();
      ShUpdateDepartment.departmentCode = parseInt(descendants[i].item.split(',')[0]);
      ShUpdateDepartment.isDirty = checked.checked;
      if (checked.checked == false && descendants[i].item.split(',')[3] == 'true') {
        ShUpdateDepartment.isDeleted = true;
      }
      else {
        ShUpdateDepartment.isDeleted = false;
      }
      ShUpdateDepartment.isOrderAvaible = JSON.parse(descendants[i].item.split(',')[3].toLowerCase());
      var stringify = JSON.parse(JSON.stringify(ShUpdateDepartment));
      this.SouthernHemisphereUpdateDepartments.push(stringify);
    }
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: DepFlatNode, checked: any): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node, checked);
    let ShUpdateDepartment = new SouthernHemisphereUpdateDepartments();
    ShUpdateDepartment.departmentCode = parseInt(node.item.split(',')[0]);
    ShUpdateDepartment.isDirty = checked.checked;
    if (checked.checked == false && node.item.split(',')[3] == 'true') {
      ShUpdateDepartment.isDeleted = true;
    }
    else {
      ShUpdateDepartment.isDeleted = false;
    }
    var stringify = JSON.parse(JSON.stringify(ShUpdateDepartment));
    this.SouthernHemisphereUpdateDepartments.push(stringify);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: DepFlatNode, checked: any): void {
    let parent: DepFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
    this._departmentForm.controls.allDpt.setValue(false);
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: DepFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: DepFlatNode): DepFlatNode | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  getSelectedItems(): string {
    let southernhemisphereDepId: any;
    let obj = new emitCollections();
    if (!this.checklistSelection.selected.length) {
      obj.isDirtySouthernhemisphere = this.isUpdate;
      this.emitSouthernhemispheredep.emit(obj);
      return "Select Departments";
    } else {
      for (let i = 0; i < this.checklistSelection.selected.length; i++) {
        if (this.checklistSelection.selected[i].level == 1) {
          southernhemisphereDepId = this.checklistSelection.selected[i].item.split(',')[2];
          if (southernhemisphereDepId != undefined) {
            let objshdep = new SouthernHemisphereDepartments();
            const departmentId = southernhemisphereDepId
            objshdep.departmentId = departmentId;
            var stringify = JSON.parse(JSON.stringify(objshdep));
            if (obj.SouthernHemisphereDepartments.indexOf(stringify) == -1) {
              obj.SouthernHemisphereDepartments.push(stringify);
            }
            obj.isDirtySouthernhemisphere = this.isUpdate;
          }
        }
      }
      this.emitSouthernhemispheredep.emit(obj);
      this.isDepartmentSelect = true;
      return (this.checklistSelection.selected.filter(s=>s.level!=0).map(s => s.item.split(',')[1]).join(","));
    }
  }

  updateSouthernhemisphereDepartment(southernhemispheredepartments: any[]) {
    this.clearAll();
    this.clearTree();
    let earlierDepId: any[] = [];
    let actualvalue: any = '';
    let exactvalue: any = '';
    let actualselectedId: any = '';
    let depId: any = "";
    let depIds: any = "";
    if (southernhemispheredepartments != null) {
      for (let i = 0; i < southernhemispheredepartments.length; i++) {
        depId = southernhemispheredepartments[i].departmentId
        if (i > 0) {
          depId = ',' + depId;
        }
        depIds += depId;
      }
      depIds = depIds.replace(/,\s*$/, "");
      earlierDepId = depIds.split(',')
    }
    this.selectedDepartments = earlierDepId;
    for (let i = 0; i < this.selectedDepartments.length; i++) {
      for (let j = 0; j < this.dataSource.data.length; j++) {
        for (let k = 0; k < this.dataSource.data[j].children.length; k++) {
          let str1 = this.selectedDepartments[i];
          let str2 = this.dataSource.data[j].children[k].item.split(',')[2];
          if (str2 == str1) {
            actualvalue = this.dataSource.data[j].children[k].item.split(',')[1];
            for (let q = 0; q < this.treeControl.dataNodes.length; q++) {
              exactvalue = this.treeControl.dataNodes[q].item.split(',')[1];
              let str3 = this.treeControl.dataNodes[q].item.split(',')[2];
              if (actualvalue == exactvalue && str3 == str1) {
                actualselectedId = [q];
                if (!this.checklistSelection.isSelected(this.treeControl.dataNodes[actualselectedId])) {
                  this.checklistSelection.toggle(this.treeControl.dataNodes[actualselectedId]);
                  this.treeControl.expand(this.treeControl.dataNodes[actualselectedId])
                }
              }
            }
          }
        }
      }
    }
  }

  checkbydefault(southernhemispheredepartments: any[]) {
    this.clearAll();
    this.clearTree();
    let actualvalue: any = '';
    let exactvalue: any = '';
    let actualselectedId: any = '';
    let depId: any = "";
    if (southernhemispheredepartments != null) {
      for (let i = 0; i < southernhemispheredepartments.length; i++) {
        for (let j = 0; j < southernhemispheredepartments[i].children.length; j++) {
          if (southernhemispheredepartments[i].children[j].item.split(',')[3].split(',') == 'true') {
            this.isUpdate = true;
            depId = southernhemispheredepartments[i].children[j].item.split(',')[2].split(',')
            this.selectedDepartments = depId;
            for (let i = 0; i < this.selectedDepartments.length; i++) {
              for (let j = 0; j < this.dataSource.data.length; j++) {
                for (let k = 0; k < this.dataSource.data[j].children.length; k++) {
                  let str1 = this.selectedDepartments[i];
                  let str2 = this.dataSource.data[j].children[k].item.split(',')[2];
                  if (str2 == str1) {
                    actualvalue = this.dataSource.data[j].children[k].item.split(',')[1];
                    for (let q = 0; q < this.treeControl.dataNodes.length; q++) {
                      exactvalue = this.treeControl.dataNodes[q].item.split(',')[1];
                      let str3 = this.treeControl.dataNodes[q].item.split(',')[2];
                      if (actualvalue == exactvalue && str3 == str1) {
                        actualselectedId = [q];
                        if (!this.checklistSelection.isSelected(this.treeControl.dataNodes[actualselectedId])) {
                          this.checklistSelection.toggle(this.treeControl.dataNodes[actualselectedId]);
                          this.treeControl.expand(this.treeControl.dataNodes[actualselectedId])
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  checkAll() {
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      this.checklistSelection.deselect(this.treeControl.dataNodes[i]);
      this.treeControl.collapse(this.treeControl.dataNodes[i])
      if (!this.checklistSelection.isSelected(this.treeControl.dataNodes[i]) && this._departmentForm.controls.allDpt.value == true) {
        this.checklistSelection.toggle(this.treeControl.dataNodes[i]);
        this.treeControl.expand(this.treeControl.dataNodes[i])
      }
      else {
        this.checklistSelection.deselect(this.treeControl.dataNodes[i]);
        this.treeControl.collapse(this.treeControl.dataNodes[i])
      }
    }
  }

  clearAll() {
    this._departmentForm.controls.departmentIds.clearValidators();
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      this.checklistSelection.deselect(this.treeControl.dataNodes[i]);
      this.treeControl.collapse(this.treeControl.dataNodes[i])
    }
    this.getSelectedItems()
  }
  clearTree() {
    this.checklistSelection.clear();
    this.getSelectedItems()
  }
  // search filter logic start
  filterLeafNode(node: DepFlatNode): boolean {
    if (!this.searchString) {
      return false
    }
    return node.item.toLowerCase()
      .indexOf(this.searchString?.toLowerCase()) === -1
    
  }

  filterParentNode(node: DepFlatNode): boolean {

    if (
      !this.searchString ||
      node.item.toLowerCase().indexOf(this.searchString?.toLowerCase()) !==
        -1
    ) {
      return false
    }
    const descendants = this.treeControl.getDescendants(node)

    if (
      descendants.some(
        (descendantNode) =>
          descendantNode.item
            .toLowerCase()
            .indexOf(this.searchString?.toLowerCase()) !== -1
      )
    ) {
      return false
    }

    return true
  }
  // search filter logic end
  /**
  * Clearing search textbox value 
  */
  clearSearch(event) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
  }
  _departmentForm: FormGroup = new FormGroup({
    departmentIds: new FormControl(''),
    allDpt: new FormControl(false),
  });
}
