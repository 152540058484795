import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import { Component, EventEmitter, Injectable, Input, OnInit, Output, ViewChild } from "@angular/core";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener
} from "@angular/material/tree";
import { BehaviorSubject } from "rxjs";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { FiscalcountryService } from "src/app/services/country/fiscalcountry/fiscalcountry.service";
export class emitCollections {
  datasource: any=[];
  productTypeIds: any;
}
/**
 * Node for to-do item
 */
export class productNode {
  children?: productNode[];
  item: any;
  id:any;
}

export class productTypeGroup {
  productTypeGroupId: any;
  productTypeIds:any;
  
}

/** Flat to-do item node with expandable and level information */
export class productFlatNode {
  item: any;
  id:any;
  level: number;
  expandable: boolean;
}

/**
 * The Json object for to-do list data.
 */


/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable({ providedIn: "root" })
export class ChecklistDatabase {
parentArray = [];
resultParent=""
childArray = [];
resultChild=""
  dataChange = new BehaviorSubject<productNode[]>([]);
  treeData: any[];
  productTypeFilterList: any[]=[];

  get data(): productNode[] {
    return this.dataChange.value;
  }

  constructor( public _fiscalcountryService: FiscalcountryService) {
   this.initialize()
  }
  getAllNodes(array:any) {
    let arr= [];
    array.forEach((item:any)=>{
    let childArr =[];
    item.productType.forEach((children:any)=>{
      childArr.push({'item':children.productTypeName+','+children.productTypeId})
     
    })
    arr.push({'item':item.productGroupName+','+item.productGroupId, 'children':childArr})
    });
    return arr;
   }
  initialize(): void {
      this._fiscalcountryService.getProductTypeAndGroupAll().subscribe((data: any) => {
      this.productTypeFilterList = this.getAllNodes(data.productTypeGroups);
      const TREE_DATA: productNode[] =this.productTypeFilterList;
      this.treeData = TREE_DATA;
      const datan = TREE_DATA;
      this.dataChange.next(datan);
     
   });
 }
 

  public filter(filterText: string) {
    let filteredTreeData;
    if (filterText) {
      // Filter the tree
      function filter(array, text) {
        const getChildren = (result, object) => {
          if (object.item .toLowerCase() === text.toLowerCase() ) {
            result.push(object);
            return result;
          }
          if (Array.isArray(object.children)) {
            const children = object.children.reduce(getChildren, []);
            if (children.length) result.push({ ...object, children });
          }
          return result;
        };

        return array.reduce(getChildren, []);
      }

      filteredTreeData = filter(this.treeData, filterText);
    } else {
      // Return the initial tree
      filteredTreeData = this.treeData;
    }

    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    // file node as children.
    const data = filteredTreeData;
    // Notify the change.
    this.dataChange.next(data);
  }
}

@Component({
  selector: 'app-producttypeandgroup',
  templateUrl: './producttypeandgroup.component.html',
  styleUrls: ['./producttypeandgroup.component.css'],
  providers: [ChecklistDatabase]
})
export class ProducttypeandgroupComponent implements OnInit  {
  @Input() _fcvatExForm: FormGroup;
  @Output() emitproductType = new EventEmitter();
  public _fiscalcountryService: FiscalcountryService;
  productTypeFilterList: any[]=[];
  selectedproductTypegroups: any[]=[];
  productGroupId:any;
  productTypeId:any;
  productTypeIds:any;
  rows: any;
  myArray = [];
  _vatExForm: FormGroup = new FormGroup({
    productTypeGroupIds: new FormControl('',[Validators.required]),
    allPTG: new FormControl(false),
  });
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<productFlatNode, productNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<productNode, productFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: productFlatNode | null = null;

  /** The new item's name */
  newItemName = "";

  treeControl: FlatTreeControl<productFlatNode>;

  treeFlattener: MatTreeFlattener<productNode, productFlatNode>;

  dataSource: MatTreeFlatDataSource<productNode, productFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<productFlatNode>(true /* multiple */);

  /// Filtering
  myControl = new FormControl();
   filteredOptions: Observable<string[]>;
  _vatExceptionForm: FormGroup; 

constructor(private _database: ChecklistDatabase,private formBuilder: FormBuilder) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<productFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );

    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
    this._vatExceptionForm = this.formBuilder.group({
      productTypeGroupIds: ['', Validators.required],
      selectAll :new FormControl('', [Validators.required]),
  });
  }
ngOnInit(){}


  getLevel = (node: productFlatNode) => node.level;

  isExpandable = (node: productFlatNode) => node.expandable;

  getChildren = (node: productNode): productNode[] => node.children;

  hasChild = (_: number, _nodeData: productFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: productFlatNode) => _nodeData.item === "";

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: productNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item
        ? existingNode
        : new productFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: productFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: productFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child =>
    this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: productFlatNode): void {
    this.checklistSelection.toggle(node);
    this.treeControl.expand(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
     
     // Force update for the parent
    descendants.every(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: productFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: productFlatNode): void {
    let parent: productFlatNode | null = this.getParentNode(node);
     while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
      }
      this._vatExForm.controls.allPTG.setValue(false);
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: productFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: productFlatNode): productFlatNode | null {
    const currentLevel = this.getLevel(node);
   if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  getSelectedItems(): string {
    let groupId:any;
    let typeId:any="";
    let productType:any;
     let knowledgeData = this.dataSource.data;
     if (!this.checklistSelection.selected.length){
      this.productTypeIds=["-1"];
      let obj = new emitCollections();
      obj.datasource = knowledgeData;
      obj.productTypeIds = this.productTypeIds;
      this.emitproductType.emit(obj);
      return "";
    }
    for (let i =0; i < this.checklistSelection.selected.length; i++) {
      if(this.checklistSelection.selected[i].level==1)
      {
        productType = this.checklistSelection.selected[i].item.split(',')[1];
        let result = knowledgeData.filter(cl => cl.children.some(c => c.item.split(',')[1] == productType));
        groupId = result[0].item.split(',')[1];
        if(productType!=undefined){
          typeId += productType;
          typeId += ',';
        }
      }
     }
    this.productTypeIds=typeId.replace(/,\s*$/, "");
    if (this.dataSource.data.length > 0 && this.productTypeIds.length > 0) {
      let obj = new emitCollections();
      obj.datasource = knowledgeData;
      obj.productTypeIds = this.productTypeIds;
      this.emitproductType.emit(obj);
    }
    return this.checklistSelection.selected.map(s => s.item.split(',')[0]).join(",");
  }


  clearTree(){
    this.checklistSelection.selected.length=0;
    this.getSelectedItems()
   }

  filterChanged(filterText: string) {
    console.log("filterChanged", filterText);
    // ChecklistDatabase.filter method which actually filters the tree and gives back a tree structure
    this._database.filter(filterText);
    if (filterText) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
  }
  updateProductTypeandGroup(selectedproductTypegroups:any){
  this.clearAll();  
  let productTypegroupsIds = selectedproductTypegroups;   
  let productTypegroupsId:any[] = [];  
  let actualvalue:any; 
  let exactvalue:any;
  let actualselectedId:any;
  let typeId:any="";
  let typeIds:any="";
  if(productTypegroupsIds!=null){
    if (productTypegroupsIds.indexOf(',') > -1){
      productTypegroupsId=productTypegroupsIds.split(',')
    }
    else{
      for(let i=0;i<productTypegroupsIds.length;i++){
        typeId = productTypegroupsIds[i].productTypeIds
        if(i>0){
              typeId =','+typeId;
             }
             typeIds += typeId;
          }
          typeIds=typeIds.replace(/,\s*$/, "");
          productTypegroupsId=typeIds.split(',')
    }
  }
  this.selectedproductTypegroups = productTypegroupsId;
  for (let i = 0; i < this.selectedproductTypegroups.length; i++) {
      for(let j=0;j<this.dataSource.data.length;j++){
        for(let k=0;k<this.dataSource.data[j].children.length;k++)
        {
          let str1 = this.selectedproductTypegroups[i];
          let str2 = this.dataSource.data[j].children[k].item.split(',')[1];
          if(str2==str1){
            actualvalue=this.dataSource.data[j].children[k].item.split(',')[0];
            for(let q=0;q<this.treeControl.dataNodes.length;q++){
              exactvalue= this.treeControl.dataNodes[q].item.split(',')[0];
              let str3 = this.treeControl.dataNodes[q].item.split(',')[1];
              if(actualvalue==exactvalue && str3==str1){
              actualselectedId=[q];
              if(!this.checklistSelection.isSelected(this.treeControl.dataNodes[actualselectedId]))
              {
                this.checklistSelection.toggle(this.treeControl.dataNodes[actualselectedId]);
                this.treeControl.expand(this.treeControl.dataNodes[actualselectedId])
              }
              }
            }
          }
        }
      }
     
    }
  }
 
  checkAll(){
   for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
    this.checklistSelection.deselect(this.treeControl.dataNodes[i]);
    this.treeControl.collapse(this.treeControl.dataNodes[i])
    if(!this.checklistSelection.isSelected(this.treeControl.dataNodes[i])&&this._vatExForm.controls.allPTG.value==true)
      {
        this.checklistSelection.toggle(this.treeControl.dataNodes[i]);
        this.treeControl.expand(this.treeControl.dataNodes[i])
      }
       else 
       {
        this.checklistSelection.deselect(this.treeControl.dataNodes[i]);
        this.treeControl.collapse(this.treeControl.dataNodes[i])
       }
    }
  }
  clearAll(){
    this._vatExForm.controls.productTypeGroupIds.clearValidators();
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
        this.checklistSelection.deselect(this.treeControl.dataNodes[i]);
        this.treeControl.collapse(this.treeControl.dataNodes[i])
    }
    this.getSelectedItems()
   }

}
