import { Pipe, PipeTransform } from '@angular/core';
   import { DatePipe } from '@angular/common';
   
   @Pipe({
     name: 'customDateTime'
   })
   export class CustomDatePipe extends 
                DatePipe implements PipeTransform {
     transform(value: any, args?: any): any {   
       var convertDate = new Date(value +'Z');
       return super.transform(convertDate, "yyyy-MM-dd");
     }
   }