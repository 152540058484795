import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs';
import { ServiceApi } from '../../../services/ServiceApi';
import { Subject } from 'rxjs';


let headers = new HttpHeaders()

    if (!headers.has('content-type')) {

      headers=headers.append('content-type','application/json')

    }
@Injectable({
  providedIn: 'root'

})
export class IndexgroupService {
  headers = new HttpHeaders().set('content-type','application/json').set('accept','application/json');
  httpOptions ={
    headers:this.headers
  }

   // Observable string sources
   public indexGroupIdSource = new Subject<string>();
   // Observable string streams
  public indexGroupId$ = this.indexGroupIdSource.asObservable();

  // Service message commands
  getIndexGroupIDFromList(mission: string) {
    this.indexGroupIdSource.next(mission);
  }
// Observable string sources
public refreshTable = new Subject<string>();
// Observable string streams
 public refreshTableIndexGroup$ = this.refreshTable.asObservable();

// Service message commands
refreshIndexGroupList() {
  this.refreshTable.next();
}

  constructor(private http: HttpClient,public _serviceApi: ServiceApi) {}

 
//List IndexGroup from Service
 getIndexGroupAll():Observable<any> 
 {
   let url = this._serviceApi.IndexGroupGetAll;
   return this.http.get(url);    
 }

   //Create IndexGroup
  createIndexGroup(createnewindex:any):Observable<any>{  
  let url = this._serviceApi.IndexGroupCreate
  var body=(JSON.stringify(createnewindex));
 return this.http.post(url,body,{'headers':headers});  
  }

  //Get IndexGroupById
  getIndexGroupById(IndexGroupId  :number):Observable<any> {
    let url=this._serviceApi.IndexGroupGetbytId
    return this.http.get<any>(url + IndexGroupId);
  }
 
 //Update Indexgroup By Id
 UpdateIndexGroupRow(updatedData:any):Observable<any> {
    let url=this._serviceApi.IndexGroupUpdate
    var body=(JSON.stringify(updatedData));
    return this.http.put(url,body,{'headers':headers});
}
}
