import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl,ValidatorFn } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { UserAccess } from 'src/app/models/userAccess';
import { DepartmentService } from 'src/app/services/organization/department/department.service';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { ServiceApi } from '../../ServiceApi';
import { EarlierplanningService } from '../earlierplanning/earlierplanning.service';

@Injectable({
  providedIn: 'root'
})

//This TypeScript used in Department Autocomplete controls,Start Season ,End Season controls
//Also in Department SubIndex,Markup Group,Custom Customer Group Controls
//Autocomplete Input Validator, Start Season ,End Season Value Compare Validator 

export class CommonUtilityService {

  validSeasons: any[] = [];
  validEpShSeason: any[] = [];

 constructor(public _departmentService: DepartmentService,public _earlierplanningService: EarlierplanningService, public _serviceApi: ServiceApi, private http: HttpClient, public _userAccess: UserAccess,public _configurationService: ConfigurationService) {
    this.validSeasons = this.fnGetRangeValues();
    this.validEpShSeason= this.fnGetEpSeasonValues();
 }
  //Observable string sources
  public userAccessSource = new Subject<string>();
  //Observable string streams
  public userAccessSource$ = this.userAccessSource.asObservable();
  getUserAccessFromList(mission: any) {
    this.userAccessSource.next(mission);
  }
  getUserInfo(upn:any): Observable<any> {
    if(this._configurationService.RunEnvironment=='dev'){
      var url ="assets/Json/responseUserInfo.json"
    }
    else{
      var url =this._serviceApi.GetUserInfo+upn
    }
    return this.http.get(url);
  }
  fnGetEpSeasonValues() {
    let validOptions: any[] = [];
    this._earlierplanningService.getSeasonByRange(2).subscribe((data: any) => {
      data.seasonByRange.forEach(x => {
        if (x.seasonNumber != null) { validOptions.push(x.seasonNumber); }
      })
    });
    return validOptions;
  }
  fnGetRangeValues() {
    let validOptions: any[] = [];
    this._departmentService.getSeasonAll().subscribe((data: any) => {
      data.seasonByRange.forEach(x => {
        if (x.seasonNumber != null) { validOptions.push(x.seasonNumber); }
      })
    });
    return validOptions;
  }
  acObjectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value != null && control.value.length != 0 && typeof control.value === 'string') {
        return { 'invalidAutocomplete': { value: control.value } }
      }
      return null  /* valid option selected */
    }
  }
  //validator for string values in dropdown

  ObjectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value != null && control.value.length != 0 && typeof control.value != 'string') {
        return { 'invalidAutocomplete': { value: control.value } }
      }
      return null  /* valid option selected */
    }
  }
  acSeasonValidator(endSeason: any, listData: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value != null && control.value != "" && control.value != undefined
        && this.validSeasons != null && this.validSeasons != undefined && this.validEpShSeason.indexOf(control.value) !== -1) {
        return null  /* valid option selected */
      }
      else
        return control.value != null ? { 'invalidAutocomplete': { value: control.value } } : null;
    }
  }
  acStartSeasonValidator(endSeason: any, listData: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value != null && control.value != "" && control.value != undefined
        && this.validSeasons != null && this.validSeasons != undefined && this.validSeasons.indexOf(control.value) !== -1) {
        if (endSeason != null && endSeason != undefined && endSeason != "" && control.value > endSeason) {
          return { 'invalidStartSeason': { value: control.value } }
        }
        return null  /* valid option selected */
      }
      else
        return control.value != null ? { 'invalidAutocomplete': { value: control.value } } : null;
    }
  }

  acEndSeasonValidator(startSeason: any, listData: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value != null && control.value != "" && control.value != undefined
        && this.validSeasons != null && this.validSeasons != undefined && this.validSeasons.indexOf(control.value) !== -1) {
        if (startSeason != null && startSeason != undefined && startSeason != "" && control.value < startSeason) {
          return { 'invalidEndSeason': { value: control.value } }
        }
        return null  /* valid option selected */
      }
      else
        return (control.value != null && control.value != "" && !control.value.includes("99")) ? { 'invalidAutocomplete': { value: control.value } } : null;
    }
  }

  public validationMsgs = {
    'autoCompleteControl':
      [{ type: 'invalidAutocomplete', message: 'Please select a valid input' },
      { type: 'invalidStartSeason', message: 'Start Season should be less than End Season' },
      { type: 'invalidEndSeason', message: 'End Season should be greater than Start Season' },
      { type: 'invalidValidFrom', message: 'Valid Form should be less than Valid To' },
      { type: 'invalidValidTo', message: 'Valid To should be greater than Valid Form' },
      { type: 'overLappingSeason', message: 'Seasons should not be Overlapping' },
      { type: 'subIndexOverLappingSeason', message: 'SubIndex already exist for selected season' },
      { type: 'markupGroupOverLappingSeason', message: 'MarkupGroup already exist for selected season' },]
  }
}
