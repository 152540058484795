import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CorporatebrandService } from 'src/app/services/organization/corporatebrand/corporatebrand.service';
import { SouthernhemisphereService } from 'src/app/services/organization/southernhemisphere/southernhemisphere.service';
import { constant } from 'src/app/shared/const/constant';
import { AccountService } from 'src/app/shared/services/account.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { CommonUtilityService } from 'src/app/services/organization/commonutility/commonutility.service';
import { SouthernhemispheredepartmentComponent } from '../southernhemispheredepartment/southernhemispheredepartment.component';
import { UserAccess } from 'src/app/models/userAccess';
import { ModulePermission } from 'src/app/models/enum';

export class dropDownCollections {
  SeasonFilterList: any;
  ChannelnameFilterList: any;
  CorporateBrandFilterList: any;
}
export class depparamCollections {
  seasonNumber: any;
  corporateBrandId: any;
  channelId: any;

}
@Component({
  selector: 'app-southernhemisphereform',
  templateUrl: './southernhemisphereform.component.html',
  styleUrls: ['./southernhemisphereform.component.css']
})
export class SouthernhemisphereformComponent implements OnInit {
  @Output() dropDownCollections = new EventEmitter<any>();
  @ViewChild('seasonDepartments') public seasonDepartments: SouthernhemispheredepartmentComponent;
  corporateBrandMasterList: any[] = [];
  corporateBrandFilterList: any[] = [];
  seasonnumberFilterList: any[] = [];
  seasonnumberMasterFilterList: any[] = [];
  channelnameFilterList: any[] = [];
  channelnameMasterFilterList: any[] = [];
  seasonMasterlist: any;
  seasonFilterList: any;
  isPublished: boolean = true;
  isActiveDisable: boolean = true;
  updateShform: any;
  isShEdit: any = false;
  userrole: boolean;
  obj = new dropDownCollections();
  depcollection = new depparamCollections();
  subscription: Subscription;
  seasonNumber: any = "";
  globalSeason: any;
  channelId: any = "";
  corporateBrandId: any = null;
  seasonyear: any;
  editevent: any = false;
  departmentIds: any;
  globalShId: any;
  globalchannelId: any;
  globalCorporateBrandId: any;
  validationMessages: any;
  users:any;

  constructor(public _commonutilityService: CommonUtilityService,private changeDetector: ChangeDetectorRef, public toastr: ToastrService, public account: AccountService, public _corporatebrandService: CorporatebrandService, public _southernhemisphereService: SouthernhemisphereService, private applicationInsightService: ApplicationInsightService,public _userAccess: UserAccess) {
    this.updateShform = Object.assign({ active: true, draft: true });
    this.users = JSON.parse(localStorage.getItem("users") || "[]")
  }

  ngOnInit(): void {
    this.userrole =this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this._southernhemisphereform.controls.lastUpdatedBy.setValue(JSON.parse(localStorage.getItem("UserInfo")).Email || "[]");
    this._southernhemisphereform.controls.channelId.reset();
    this._southernhemisphereService.getSeasonByRange(2).subscribe((data: any) => {
      this.applicationInsightService.logEvent("Season listing started");
      this.seasonMasterlist = this.seasonFilterList = data.seasonByRange;
      this.seasonMasterlist = this.seasonMasterlist?.sort((a, b) => (a?.seasonNumber < b?.seasonNumber ? 1 : -1));
      this.dropDownCollectionfn();
      this.fnSetControlsValidation();
      this.validationMessages = this._commonutilityService.validationMsgs;
      this.applicationInsightService.logEvent("Season listing ended");
    });

    // Once organization is deployed we can call Organization service instead of BOCOS.
    this._corporatebrandService.getCorporateBrandBOCOSList().subscribe((data: any) => {
      this.applicationInsightService.logEvent("CorporateBrand listing Started");
      this.corporateBrandMasterList = this.corporateBrandFilterList = data.getCorporateBrandAll.filter(object => {
        return object['active'] !== false;
      })
      this.corporateBrandFilterList = this.corporateBrandMasterList;
      this.dropDownCollectionfn();
      this.applicationInsightService.logEvent("CorporateBrand listing Ended");
    });
    this._southernhemisphereService.getChannels().subscribe((data: any) => {
      this.channelnameMasterFilterList = this.channelnameFilterList = data.getChannelAll;
      this.channelnameFilterList = this.channelnameFilterList.sort((a, b) => (a.channelName > b.channelName ? 1 : -1));
    });
    this.subscription = this._southernhemisphereService.southernHemisphereId$.subscribe(
      southernHemisphereId => {
        this.updateSouthernHemisphere(southernHemisphereId);
      });

  }
  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
   }

  public dropDownCollectionfn() {
    this.obj.SeasonFilterList = this.seasonMasterlist;
    this.obj.CorporateBrandFilterList = this.corporateBrandMasterList;
    this.obj.ChannelnameFilterList = this.channelnameFilterList;
    //Adding this condition to restrict multiple calls to load dropdown
    if (this.seasonMasterlist != undefined && this.seasonMasterlist.length > 0 && this.corporateBrandMasterList.length > 0) {
      this.dropDownCollections.emit(this.obj);
    }
  }
  receivedChildHandler(emitdep: any) {
    this.applicationInsightService.logEvent("southernHemisphere receivedChildHandler Started");
    this._southernhemisphereform.controls.southernHemisphereId.setValue(0)
    this._southernhemisphereform.controls.southernHemisphereCreateDepartments.setValue(emitdep.SouthernHemisphereDepartments);
    this._southernhemisphereform.controls.southernHemisphereUpdateDepartments.setValue(emitdep.SouthernHemisphereDepartments);
    if (emitdep.isDirtySouthernhemisphere != false) {
      this.isShEdit = true;
      this._southernhemisphereform.markAsDirty();
      this._southernhemisphereform.controls.southernHemisphereId.setValue(1);
    }
    this.applicationInsightService.logEvent("southernHemisphere receivedChildHandler Ended");
  }
  displayTextSeason(seasonNumber: any) {
    this.globalSeason = seasonNumber;
    if (seasonNumber == null || seasonNumber == "")
      return null;
    else if (this.seasonMasterlist != undefined && seasonNumber != null)
    return this.seasonMasterlist.find(x => x.seasonNumber === seasonNumber).displayName;
  }
  displayTextCB(corporateBrandId: any) {
    if (corporateBrandId === 0 || corporateBrandId != "" && this.isShEdit != true) {
      this.clearDep();
      this.globalCorporateBrandId = corporateBrandId;
      this.depcollection.seasonNumber = this.globalSeason;
      this.depcollection.corporateBrandId = corporateBrandId;
    }
    if (corporateBrandId === null || corporateBrandId === "")
      return null;
    else if (this.corporateBrandMasterList != undefined && corporateBrandId != null)
      return this.corporateBrandMasterList.find(x => x.corporateBrandId == corporateBrandId).corporateBrandName;
  }

  displayTextCL(channelId: any) {
    if (channelId === 0 || channelId != "" && this.isShEdit != true) {
      this.clearDep();
      this.depcollection.seasonNumber = this.globalSeason;
      this.depcollection.corporateBrandId = this.globalCorporateBrandId
      this.depcollection.channelId = channelId;
      this._southernhemisphereService.getSouthernHemisphereDepartmentFromList(this.depcollection);
         this._southernhemisphereService.getSouthernHemisphereDepartmentsAndSections(this.depcollection).toPromise().then((data: any) => {
          for (let i = 0; i <  data.southernHemisphereSectionsAndDepartments.length; i++) {
            for (let j = 0; j < data.southernHemisphereSectionsAndDepartments[i].departments.length; j++) {
              if (data.southernHemisphereSectionsAndDepartments[i].departments[j].isSouthernHemisphereDepartment == true) {
                this._southernhemisphereform.controls.draft.setValue(data.draft);
                this._southernhemisphereform.controls.active.setValue(data.active);
                break;
            }}}  
       }
     );
   
      this.depcollection.seasonNumber = '';
      this.depcollection.corporateBrandId = '';
   }
     if (channelId === null || channelId === "")
      return null;
    else if (this.channelnameMasterFilterList != undefined && channelId != null)
      return this.channelnameMasterFilterList.find(x => x.channelId == channelId).channelName;
  }
 filterSeason(filterKeySS: string) {
    this.applicationInsightService.logEvent("filter Season started");
    if (filterKeySS != null && filterKeySS != "") {
      this.seasonFilterList = this.seasonMasterlist.filter(cb => cb.displayName.toString().toLowerCase().includes(filterKeySS.toString().toLowerCase()));
      this.seasonFilterList = this.seasonFilterList?.sort((a, b) => (a?.seasonNumber < b?.seasonNumber ? 1 : -1));
      this.fnSetControlsValidation();
    }
    else if (this.seasonMasterlist != null || undefined) { this.seasonFilterList = this.seasonMasterlist?.sort((a, b) => (a?.seasonNumber < b?.seasonNumber ? 1 : -1)); }
    this.applicationInsightService.logEvent("filter Season ended");
  }
  changeInput(e: any) {
    if(this.isShEdit){
      if (e.keyCode === 8 || e.keyCode === 46 ) {
        this.onClear();
     }
    }
  }
  filterItemCorporateBrand(filterKey: string) {
    this.applicationInsightService.logEvent("southernHemisphere filtering CorporateBrand Started");
    if (filterKey != null && filterKey != "") {
      if(this.seasonDepartments.checklistSelection.selected.length>0){
        this.onClear();
      }
      this.corporateBrandFilterList = this.corporateBrandMasterList.filter(cb => cb.corporateBrandName.toLowerCase().includes(filterKey.toString().toLowerCase()));
    } else { this.corporateBrandFilterList = this.corporateBrandMasterList;}
    this.applicationInsightService.logEvent("southernHemisphere filtering CorporateBrand Ended");
  }
  filterchannel(filterKey: any) {
    this.applicationInsightService.logEvent("Southern Hemipshere filtering ChannelName Started");
    if (filterKey != null && filterKey != "") {
      this.channelnameFilterList = this.channelnameMasterFilterList.filter(sn => sn.channelName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
      this.channelnameFilterList = this.channelnameFilterList.sort((a, b) => a.channelName.toString().localeCompare(b.channelName));
    } else { this.channelnameFilterList = this.channelnameMasterFilterList.sort((a, b) => a.channelName.toString().localeCompare(b.channelName)); }
    this.applicationInsightService.logEvent("Southern Hemisphere filtering ChannelName Ended");
  }
  onSubmit() {
    this._southernhemisphereform.controls['seasonNumber'].updateValueAndValidity();
    this._southernhemisphereform.controls['corporateBrandId'].updateValueAndValidity();
    this._southernhemisphereform.controls['channelId'].updateValueAndValidity();
    this.fnvalidationdepartment();
    if (this._southernhemisphereform.controls.seasonNumber.value != undefined && this._southernhemisphereform.controls.corporateBrandId.value != null && this._southernhemisphereform.controls.channelId.value != '') {
      if (this._southernhemisphereform.value.southernHemisphereId == undefined || this._southernhemisphereform.value.southernHemisphereId == 0) {
        this.seasonDepartments._departmentForm.controls['departmentIds'].setErrors(null);
        this._southernhemisphereService.createSouthernHemisphere(this._southernhemisphereform.value).subscribe
          (data => {
            this.toastr.success(data.message, "", { timeOut: constant.toasterTimeout });
            this._southernhemisphereService.refreshSouthernHemisphereList();
            this.onClear();

          },
            error => { this.toastr.error(error, "", { timeOut: constant.toasterTimeout }) })
      }
      else {
        this.seasonDepartments._departmentForm.controls['departmentIds'].setErrors(null);
        this._southernhemisphereService.updateSouthernHemisphere(this._southernhemisphereform.value).subscribe
          (data => {
            this.onClear();
            this.toastr.success(data.message, "", { timeOut: constant.toasterTimeout });
            this._southernhemisphereService.refreshSouthernHemisphereList();

          },
            error => { this.toastr.error(error, "", { timeOut: constant.toasterTimeout }) })
      }
    }
  }
  updateSouthernHemisphere(southernHemisphereId: any) {
    this.applicationInsightService.logEvent("southernHemisphereForm Edit by Id Started");
    if (southernHemisphereId != undefined) {
      this.isShEdit = true;
      this.isActiveDisable = false;
      if (this.globalShId != southernHemisphereId ) {
        this._southernhemisphereService.getSouthernHemisphereById(southernHemisphereId).subscribe((data: any) => {
          this.globalShId = southernHemisphereId;
          if (this.editevent == false ||  this.seasonDepartments.checklistSelection.selected.length==0) {
            let obj = new depparamCollections();
            let seasonedit = data.southernHemisphere.seasonNumber.substring(0, 4);
            obj.channelId = data.southernHemisphere.channelId;
            obj.seasonNumber = data.southernHemisphere.seasonNumber;
            obj.corporateBrandId = data.southernHemisphere.corporateBrandId;
            this.editevent = true;
            this._southernhemisphereService.getSouthernHemisphereDepartmentFromList(obj)
          }
          this.updateShform = data.southernHemisphere;
          if (!this.seasonDepartments.checklistSelection.selected.length) {
            this._southernhemisphereService.getSouthernHemisphereIdFromList(southernHemisphereId);
          }
          this.seasonDepartments.updateSouthernhemisphereDepartment(this.updateShform.southernHemispheredepartments);
        });
      }
    }
    document.getElementById("scrolltop").scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    this.applicationInsightService.logEvent("southernHemisphereForm Edit by Id Ended");
  }
  onClear() {
    this.applicationInsightService.logEvent("southernHemisphereForm Clear Started");
    this._southernhemisphereform.controls.corporateBrandId.clearValidators();
    this._southernhemisphereform.controls.channelId.clearValidators();
    this._southernhemisphereform.controls.seasonNumber.clearValidators();
    this._southernhemisphereform.controls.corporateBrandId.reset();
    this._southernhemisphereform.controls.seasonNumber.reset();
    this._southernhemisphereform.controls.channelId.reset('');
    this._southernhemisphereform.controls.southernHemisphereId.reset('');
    this._southernhemisphereform.controls.seasonNumber.setValue('');
    this._southernhemisphereform.controls.channelId.setValue('');
    this._southernhemisphereform.controls.southernHemisphereId.setValue(0);
    this._southernhemisphereform.controls.draft.setValue(true);
    this._southernhemisphereform.controls.active.setValue(true);
    this._southernhemisphereform.controls['seasonNumber'].updateValueAndValidity();
    this._southernhemisphereform.controls['corporateBrandId'].updateValueAndValidity();
    this.fnSetControlsValidation();
    this.clearDep();
    this.isShEdit = false;
    this.isActiveDisable = true;
    this.editevent = false;
    this.globalShId="";
    this.applicationInsightService.logEvent("southernHemisphereForm Clear Ended");
  }
  clearDep() {
    if (this.seasonDepartments != undefined || null) {
      this.seasonDepartments._departmentForm.controls.departmentIds.clearValidators();
      this.seasonDepartments._departmentForm.controls.departmentIds.setValue('');
      this.seasonDepartments._departmentForm.controls.departmentIds.reset();
      this.seasonDepartments.clearAll();
      this.seasonDepartments.clearTree();
      this.seasonDepartments.isDepartmentSelect = false;
      this.seasonDepartments.isUpdate = false;
     }
  }
  //validating southernHemisphere
  fnvalidationdepartment() {
    if (this.departmentIds != -1) {
      if (this.departmentIds == '' || this.departmentIds == null || this.departmentIds == undefined) {
        if (this.seasonDepartments._departmentForm != null || '') {
          this.seasonDepartments._departmentForm.markAllAsTouched();
          this._southernhemisphereform.controls.seasonNumber.setValidators([Validators.required]);
          this._southernhemisphereform.controls.corporateBrandId.setValidators([Validators.required]);
          this.seasonDepartments._departmentForm.controls.departmentIds.setValidators([Validators.required]);
          this.seasonDepartments._departmentForm.controls['departmentIds'].updateValueAndValidity();
        }
      }
      else {
        if (this.seasonDepartments._departmentForm != null || '') {
          this.seasonDepartments._departmentForm.controls.departmentIds.clearValidators();
          this._southernhemisphereform.controls.seasonNumber.clearValidators();
          this._southernhemisphereform.controls.corporateBrandId.clearValidators();
          this.seasonDepartments._departmentForm.controls.departmentIds.setValue('');
        }
      }
    }

  }
  fnSetControlsValidation() {
    this._southernhemisphereform.controls.seasonNumber.setValidators([this._commonutilityService.acSeasonValidator(this._southernhemisphereform.controls.seasonNumber.value, null), Validators.required])
  }
  //southernHemisphere create Form
  _southernhemisphereform: FormGroup = new FormGroup({
    southernHemisphereId: new FormControl(''),
    corporateBrandId: new FormControl('', [Validators.required]),
    departmentIds: new FormControl(''),
    southernHemisphereCreateDepartments: new FormControl(''),
    southernHemisphereUpdateDepartments: new FormControl(''),
    channelId: new FormControl('', [Validators.required]),
    seasonNumber: new FormControl(null, [Validators.required]),
    lastUpdatedBy: new FormControl(''),
    active: new FormControl(true),
    draft: new FormControl(true),
  })
}

