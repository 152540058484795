import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { AccountService } from '../../../../shared/services/account.service'
import { ChannelService } from 'src/app/services/market/channel/channel.service';
import { CommonUtilityService } from 'src/app/services/organization/commonutility/commonutility.service';
import { ModulePermission } from 'src/app/models/enum';
import { UserAccess } from 'src/app/models/userAccess';

@Component({
  selector: 'app-channelform',
  templateUrl: './channelform.component.html',
  styleUrls: ['./channelform.component.css']
})
export class ChannelformComponent implements OnInit {

  @Output() childButtonEvent = new EventEmitter();

  title = "Channel";
  updateChannelForm: any;
  isChannelEdit: any = false;
  isDisableActive: any = true;
  chuserrole: boolean;

  constructor(public toastr: ToastrService, public router: Router, public route: ActivatedRoute, public titleService: Title, public account: AccountService, public _channelService: ChannelService,public _commonutilityServic: CommonUtilityService,public _userAccess: UserAccess) {
    this.updateChannelForm = Object.assign({ active: true, draft: true });
  }

  ngOnInit(): void {
    this.chuserrole=this._userAccess.getismenuEnable.some((_m: any) => this._userAccess.getismenuEnable.includes(ModulePermission.Market_Write) ? true : false);
    this.titleService.setTitle(this.title);
    this._channelService.channelId$.subscribe(channelId => { this.updateChannel(channelId) });
    this.fnSetControlsValidation();
  }
  updateChannel(channelId: any) {
    if(channelId!=undefined){
      this.isChannelEdit = true;
      this.isDisableActive = true;
      this._channelService.getChannelById(channelId).subscribe((data: any) => {
        this.isDisableActive = false;
        this.updateChannelForm = data.channelDto;
      });
    }

  }

  onSubmit() {
    this._channelform.controls['channelName'].updateValueAndValidity();
    this._channelform.controls['channelSortOrder'].updateValueAndValidity();
    if (this._channelform.controls.active.value === undefined || this._channelform.controls.active.value === null) { this._channelform.controls.active.setValue(false); }
    if (this._channelform.controls.draft.value === undefined || this._channelform.controls.draft.value === null) { this._channelform.controls.draft.setValue(true); }

    this._channelform.controls.lastUpdatedBy.setValue(this.account.userInfo.Email)
    console.log("Channel Save " + JSON.stringify(this._channelform.value));
    if (this._channelform.valid) {
      if (this._channelform.value.channelId == undefined || this._channelform.value.channelId == 0 || this._channelform.value.channelId == null) {
        this._channelService.createChannel(this._channelform.value).subscribe
          (data => {
            this.toastr.success(data.message, "", { timeOut: 10000 });
            this.onClear();
            this._channelService.refreshChannelList();
            this.childButtonEvent.emit();
          },
            error => { this.toastr.error(error, "", { timeOut: 10000 }) })
      }
      else {
        this._channelService.updateChannel(this._channelform.value).subscribe
          (data => {
            this.toastr.success(data.message, "", { timeOut: 10000 });
            this.onClear();
            this._channelService.refreshChannelList();
            this.childButtonEvent.emit();
          },
            error => { this.toastr.error(error, "", { timeOut: 10000 }) })
      }
    }
  }

  onClear() {
    this._channelform.controls.channelName.clearValidators();
    this._channelform.controls.channelSortOrder.clearValidators();
    this._channelform.controls.channelId.setValue('');
    this._channelform.controls.channelName.setValue('');
    this._channelform.controls.channelSortOrder.setValue('');
    this._channelform.controls.active.setValue(true);
    this._channelform.controls.draft.setValue(true);

    this.fnSetControlsValidation();
    this.isChannelEdit = false;
    this.isDisableActive = true;
  }

  _channelform: FormGroup = new FormGroup({
    channelId: new FormControl(''),
    channelName: new FormControl('',[Validators.required, Validators.maxLength(30)]),
    channelSortOrder: new FormControl('',[Validators.required, Validators.min(1), Validators.max(9999)]),
    active: new FormControl(true),
    lastUpdatedBy: new FormControl(this.account.userInfo.Email),
    draft: new FormControl(true),
  });


  fnSetControlsValidation() {
    this._channelform.controls.channelName.setValidators([Validators.required, Validators.maxLength(30)]);
    this._channelform.controls.channelSortOrder.setValidators([Validators.required, Validators.min(1), Validators.max(9999)]);
  }

}


