<div class="mat-elevation-z2 form-border" id="scrolltop">
  <form [formGroup]="_corporateBrandform">
    <section class="form-section">
      <h2 mat-dialog-title class="align">{{isCorporateBrandEdit === true ? 'Update' : 'New'}} Corporate Brand</h2>
    </section>
    <div class="example-container add-form-field ">
      <mat-form-field [appearance]="isEditCB">
        <mat-label>Corporate Brand</mat-label>
        <input id="inpCorporateBrand" formControlName="corporateBrandName" matInput
          [(ngModel)]="updateCorporateBrandForm.corporateBrandName" aria-label="Enter corporate brand"
          [readonly]="isEditCB=='fill' ?true:false">
        <mat-error *ngIf="_corporateBrandform.controls['corporateBrandName'].errors?.maxlength">Maximum 30
          charactor allowed</mat-error>
      </mat-form-field>
      <br>
      <mat-form-field appearance="legacy">
        <mat-label>Sort Order</mat-label>
        <input id="inpCoporateBrandSortOrder" formControlName="corporateBrandSortOrder" matInput
          [(ngModel)]="updateCorporateBrandForm.corporateBrandSortOrder" type="number" min="1" step="1"
          pattern="^(0|[1-9][0-9]*)$">
        <mat-error *ngIf="_corporateBrandform.controls['corporateBrandSortOrder'].errors?.max"> The range for sort order
          is 1-9999. </mat-error>
        <mat-error *ngIf="_corporateBrandform.controls['corporateBrandSortOrder'].errors?.pattern">Only Numeric Values
          allowed</mat-error>
      </mat-form-field>
      <br>
      <section class="example-section">
        <mat-checkbox id="inpactive" formControlName="active" [(ngModel)]="updateCorporateBrandForm.active"
          [checked]="true" class="example-margin width-25" color='primary' [disabled]=isActiveDisable>Active
        </mat-checkbox>
        <mat-checkbox id="inpdraft" formControlName="draft" [(ngModel)]="updateCorporateBrandForm.draft"
          class="example-margin width-15" color='primary'>Draft
        </mat-checkbox>
      </section>
      <br>
      <input type="hidden" formControlName="corporateBrandId" [(ngModel)]="updateCorporateBrandForm.corporateBrandId">
      <br>
      <mat-dialog-actions *ngIf="userrole">
        <button [disabled]="(isCorporateBrandEdit === true) ? !_corporateBrandform.dirty : false"
          [ngClass]="(isCorporateBrandEdit === true) ? 'mat-raised-button ml-1':'mat-raised-button ml-1 mat-primary'"
          id="btnSaveUpdate" type="submit" (click)="onSubmit()">{{isCorporateBrandEdit === true ? 'Update' :
          'Save'}}</button>

        <button disabled class="mat-raised-button ml-1"
          [ngClass]="(isCorporateBrandEdit === true) ? 'displaycls':'hiddedencls'" [disabled]="isPublished"
          id="btnDelete" type="submit" (click)="deleteCorporateBrand()">Delete</button>

        <button id="btnCancel" class="mat-raised-button ml-1 mat-primary" (click)="onClear()">Cancel</button>
      </mat-dialog-actions>
      <span *ngIf="!userrole" style="color: #ff0202;font-size: 12px;font-weight: 400;">You can't save changes since you
        have Read access.</span>
    </div>
    <div>
    </div>
  </form>
</div>