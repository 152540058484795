<div class="wrap">
  <div class="filter-div">
    <div class="org-container">

      <form [formGroup]="_searchform">

        <mat-form-field  appearance="legacy" class="customform">
          <mat-label>Corporate Brand</mat-label>

          <input type="text" id="Searchcorporatebrand" matInput [matAutocomplete]="autoCB"
            formControlName="corporateBrandName" [(ngModel)]="corporateBrandName"
            (ngModelChange)="filterItemCorporateBrand($event)">

          <mat-autocomplete autoActiveFirstOption #autoCB="matAutocomplete">
            <mat-option *ngFor="let CBname of corporateBrandFilterIGList" [value]="CBname.corporateBrandCodeName" [matTooltipDisabled]="CBname.corporateBrandName.length<8"  matTooltip="{{CBname.corporateBrandCode}}&nbsp;{{CBname.corporateBrandName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
              {{CBname.corporateBrandCode}} {{CBname.corporateBrandName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field  appearance="legacy" class="customform">
          <mat-label>Index Group</mat-label>
          <input type="text" id="Searchindexgroupname" matInput [matAutocomplete]="autoGN"
            formControlName="indexGroupName" [(ngModel)]="indexGroupName" (ngModelChange)="filterItemGN($event)">
            <mat-autocomplete autoActiveFirstOption #autoGN="matAutocomplete">
            <mat-option *ngFor="let indexGroupName of indexGroupNameFilterList" [value]="indexGroupName.indexGroupCodeName" [matTooltipDisabled]="indexGroupName.indexGroupName.length<8"  matTooltip="{{indexGroupName.indexGroupCode}}&nbsp;{{indexGroupName.indexGroupName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
              {{indexGroupName.indexGroupCode}} {{indexGroupName.indexGroupName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field  appearance="legacy" class="statusclass">
          <mat-label>Status</mat-label>
          <mat-select id="Searchstatus" formControlName="status" [(ngModel)]="status">
            <mat-option value="all">
              All
            </mat-option>
            <mat-option value="active">
              Active
            </mat-option>
            <mat-option value="inactive">
              Inactive
            </mat-option>
            <mat-option value="draft">
              Draft
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-button class="add-button-container mat-raised-button mat-primary ml-2" 
        id="searchClear" type="submit" (click)="onSearchClear()" >Clear</button>
      </form>
    </div>
  </div>
    <div class="clearfix"></div>
    <div class="tblleft">
      <app-indexgrouplist (childButtonEvent)="indexGroupDropDownList($event)" [indexGroupSearch]=_searchform.value>
      </app-indexgrouplist>
    </div>
    <div class="frmright">
      <app-indexgroupform (childButtonEvent)="receivedChildHandler($event)"></app-indexgroupform>
    </div>
  </div>