import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ServiceApi } from '../../../services/ServiceApi';



let headers = new HttpHeaders()
if (!headers.has('content-type')) {
  headers = headers.append('content-type', 'application/json')
}

@Injectable({
  providedIn: 'root'
})
export class HmcountryService {
  resfreshCall = new BehaviorSubject<boolean>(false);
  headers = new HttpHeaders().set('content-type', 'application/json').set('accept', 'application/json');
  httpOptions = {
    headers: this.headers
  }
 
  constructor(private http: HttpClient,
    public _serviceApi: ServiceApi) {

  }

  GetGeographicalCountryExceptHMCountry(){
    let url = this._serviceApi.GetGeographicalCountryExceptHMCountry;
    return this.http.get<any>(url)
  }

  getHmCountryList(): Observable<any> {
    let url = this._serviceApi.HmCountryGetAll;
    return this.http.get<any>(url)
  }

  CreateHandMCountry(createData: any): Observable<any>
  {
    let url = this._serviceApi.CreateHandMCountry
    return this.http.post(url, createData, { 'headers': headers }).pipe();
  }

  UpdateHandMCountry(updatedData: any): Observable<any>
  {
    let url = this._serviceApi.UpdateHandMCountry
    return this.http.put(url, updatedData, { 'headers': headers }).pipe();
  }
}

