<!-- <div class="wrap">
  <div class="fleft"> -->
    <div class="data-table subtable">
      <table id="tblDepSubIndex" [dataSource]="dataSource" #table mat-table class="full-width-table" (matSortChange)="sortData($event)" matSort aria-label="Elements">
       <ng-container matColumnDef="startSeason">
          <th (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header class="custom-color" id="depSIStartSeasonColumn">Start Season </th>
          <td mat-cell *matCellDef="let row" >
          <span *ngIf="!seasonDate">{{ row.startSeason | seasonDate }}</span>
        </td>
        </ng-container>
        <ng-container matColumnDef="endSeason">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-color" id="depSIEndSeasonColumn">End Season</th>
          <td mat-cell *matCellDef="let row" >
            <span *ngIf="!seasonDate">{{(row.endSeason !=null && row.endSeason!='' && row.endSeason.includes("99")) ? '':row.endSeason  | seasonDate }}</span></td>
        </ng-container>
        <ng-container matColumnDef="subIndexCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-color" id="depSICodeColumn">Sub Index </th>
          <td mat-cell *matCellDef="let row" >{{row.subIndexCode}}</td>
        </ng-container>
        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef class="custom-color" id="depSIActionsColumn">Edit</th>
          <td mat-cell *matCellDef="let row" >
            <mat-icon  [ngClass]="(row.isDeleteDepSubIdex === true) ? 'isdeletetrue':'isdeletefalse'" (click)="!row.isDeleteDepSubIdex?'':updateRecord(row)"
              style="background-color: rgb(255 255 255 / 0%);cursor: pointer;">drive_file_rename_outline</mat-icon>
          </td>
        </ng-container>
       <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="8" style="text-align: center;">No records found</td>
        </tr>
      </table>
    </div>
<department-subindexform  id="subindexform" (subindexcall)="receivedChildHandlerSI()" [departmentDraft]="departmentDraft" [subIndexList]=subIndexList [subIndexSeasonlist]=subIndexSeasonlist [seasonList]=seasonList [parentDepartmentId]=parentDepartmentId [parentCorporateBrandName]=parentCorporateBrandName [depSubIndexList]=depSubIndex></department-subindexform>
