import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CustomerGroupService } from '../../../../services/organization/customerGroup/customer-group.service'
import { CorporatebrandService } from '../../../../services/organization/corporatebrand/corporatebrand.service';
import { DivisionService } from '../../../../services/organization/division/division.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DivisionlistComponent } from '../divisionlist/divisionlist.component';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';

@Component({
  selector: 'app-division',
  templateUrl: './division.component.html',
  styleUrls: ['./division.component.css']
})
export class DivisionComponent implements OnInit {
  @ViewChild('divisionlist') public divisionList: DivisionlistComponent;

  divisionNameMasterList: any[] = [];
  divisionNameFilterList: any[] = [];
  clonedivisionNameMasterList: any[] = [];

  corporateBrandMasterCGList: any[] = [];
  corporateBrandFilterCGList: any[] = [];

  divisionCodeFilterCGList: any[] = [];
  divisionCodeMasterCGList: any[] = [];
  clonedivisionCodeMasterCGList: any[] = [];

  customerGroupNameFilterCGList: any[] = [];
  customerGroupNameMasterCGList: any[] = [];
  cloneCustomerGroupNameMasterCGList: any[] = [];

  divisionCode: any = "";
  customerGroupName: any = "";
  status: any = "active";
  divisionName: any = "";
  corporateBrandName: any = "";
  customerGroupList: any[] = [];
  distinctDivisionName: any[] = [];
  distinctDivisionCode: any[] = [];
  distinctCustomerGroupName: any[] = [];
  obj: any[] = [];
  numarray: any[] = [];


  constructor(public _divisionService: DivisionService, public _corporatebrandService: CorporatebrandService, public route: ActivatedRoute, public _customerGroup: CustomerGroupService, private applicationInsightService: ApplicationInsightService, private titleService: Title) { }

  ngOnInit(): void { }
  receivedChildHandler(obj: any) {
    this.applicationInsightService.logEvent("Division receivedChildHandler Started");
    if (obj.corporateBrandList != null || undefined) {
      this.corporateBrandMasterCGList = this.corporateBrandFilterCGList = obj.corporateBrandList.sort((a, b) => a.corporateBrandCode.toString().localeCompare(b.corporateBrandCode));
    }
    if (obj.customerGroupList != null || undefined) {
      this.distinctCustomerGroupName = obj.customerGroupList.filter((arr, index, self) => index === self.findIndex((t) => (t.customerGroupName === arr.customerGroupName)));
    }
    this.customerGroupNameMasterCGList = this.customerGroupNameFilterCGList = this.distinctCustomerGroupName.sort((a, b) => (a.customerGroupCode > b.customerGroupCode ? 1 : -1));
    this.applicationInsightService.logEvent("Division receivedChildHandler Ended");
  }
  divisionDropDownList(obj: any) {
    this.applicationInsightService.logEvent("Division DropDownList Started");
    this.distinctDivisionName = obj;
    this.distinctDivisionCode = obj;
    this.divisionNameMasterList = this.divisionNameFilterList = this.distinctDivisionName.sort((a, b) => a.divisionCode.toString().localeCompare(b.divisionCode));
    this.divisionCodeMasterCGList = this.divisionCodeFilterCGList = this.distinctDivisionCode.sort((a, b) => a.divisionCode.toString().localeCompare(b.divisionCode));
    this.applicationInsightService.logEvent("Division DropDownList Ended");
  }

  filterItemGNAM(filterKey: any) {
    this.applicationInsightService.logEvent("Filtering customerGroupName Started");
    if(this._searchform?.controls?.corporateBrandName?.value){
      this.onFilter()
    }
    setTimeout(() => {
      if (filterKey != null && filterKey != "") {
      if (isNaN(filterKey)) {
        this.customerGroupNameFilterCGList = this.customerGroupNameMasterCGList.filter(gn => gn.customerGroupName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
        this.customerGroupNameFilterCGList = this.customerGroupNameFilterCGList.sort((a, b) => (a.customerGroupCode > b.customerGroupCode ? 1 : -1));
      }else {
        this.customerGroupNameFilterCGList = this.customerGroupNameMasterCGList.filter(gn => gn.customerGroupCode.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
      }}
      else { this.customerGroupNameFilterCGList = this.customerGroupNameMasterCGList.sort((a, b) => (a.customerGroupCode > b.customerGroupCode ? 1 : -1)); }
      }, 500);
      this.applicationInsightService.logEvent("Filtering customerGroupName Ended");
  }

  filterItemDivisionName(filterKeyDiN) {
    if(this._searchform?.controls?.corporateBrandName?.value){
      this.onFilter()
    }
    this.applicationInsightService.logEvent("Filtering DivisionName Started");
    setTimeout(() => {
      if (filterKeyDiN != null && filterKeyDiN != "") {
        if(isNaN(filterKeyDiN)){
          this.divisionNameFilterList = this.divisionNameMasterList.filter(din => din.divisionName.toString().toLowerCase().includes(filterKeyDiN.toString().toLowerCase()));
          this.divisionNameFilterList = this.divisionNameFilterList.sort((a, b) => a.divisionCode.toString().localeCompare(b.divisionCode));
        }else{
          this.divisionNameFilterList = this.divisionNameMasterList.filter(din => din.divisionCode.toString().toLowerCase().includes(filterKeyDiN.toString().toLowerCase()));
          this.divisionNameFilterList = this.divisionNameFilterList.sort((a, b) => a.divisionCode.toString().localeCompare(b.divisionCode));
        }
      }
      else {
        this.divisionNameFilterList = this.divisionNameMasterList.sort((a, b) => (a.divisionCode > b.divisionCode ? 1 : -1));
      }
    }, 500);
    

    this.applicationInsightService.logEvent("Filtering DivisionName Ended");
  }


  filterItemCorporateBrand(filterKey: any) {
    this.applicationInsightService.logEvent("Filter Corporatebrand Started");
    this.onFilter()
    if (isNaN(filterKey)) {
      this.corporateBrandFilterCGList = this.corporateBrandMasterCGList.filter(cb => cb.corporateBrandName.toLowerCase().includes(filterKey.toLowerCase()));
      this.corporateBrandFilterCGList = this.corporateBrandFilterCGList.sort((a, b) => (a.corporateBrandCode > b.corporateBrandCode ? 1 : -1));
    } else {
      this._searchform.controls.customerGroupName.setValue('');
      this.corporateBrandFilterCGList = this.corporateBrandMasterCGList.filter(cb => cb.corporateBrandCode.toLowerCase().includes(filterKey.toLowerCase()));
      this.corporateBrandFilterCGList = this.corporateBrandFilterCGList.sort((a, b) => (a.corporateBrandCode > b.corporateBrandCode ? 1 : -1));
    }
    this.applicationInsightService.logEvent("Filter Corporatebrand Ended");
  }
  // filter function based on selection
  onFilter(){
    setTimeout(() => {
      if (this.cloneCustomerGroupNameMasterCGList?.length === 0) {
        this.cloneCustomerGroupNameMasterCGList = this.customerGroupNameMasterCGList;
      }

      if (this.clonedivisionNameMasterList.length === 0) {
        this.clonedivisionNameMasterList = this.divisionNameMasterList;
      }

      // this code for filter the divion,section code and section based on co-brand selection 
      if(this._searchform?.controls?.corporateBrandName?.value){
        this.customerGroupNameMasterCGList = this.cloneCustomerGroupNameMasterCGList.filter(x => x.corporateBrandCodeName === this._searchform?.controls?.corporateBrandName?.value)
        this.customerGroupNameFilterCGList = this.customerGroupNameMasterCGList
        this.divisionNameMasterList = this.clonedivisionNameMasterList.filter(x => x.corporateBrandCodeName === this._searchform?.controls?.corporateBrandName?.value)
        this.divisionNameFilterList = this.divisionNameMasterList
      }
      else {
        this.customerGroupNameFilterCGList = this.cloneCustomerGroupNameMasterCGList;
        this.customerGroupNameMasterCGList = this.cloneCustomerGroupNameMasterCGList
        this.divisionNameFilterList = this.clonedivisionNameMasterList;
        this.divisionNameMasterList = this.clonedivisionNameMasterList;
      }

      if(this._searchform?.controls?.corporateBrandName?.value !== '' && 
        this._searchform.controls.customerGroupName.value){
          this.divisionNameMasterList = this.clonedivisionNameMasterList.filter(x=>{
          return x.customerGroupCodeName === this._searchform.controls.customerGroupName.value;
        }).filter(x=>x.corporateBrandCodeName === this._searchform?.controls?.corporateBrandName?.value)
        this.divisionNameFilterList =  this.divisionNameMasterList;
      }
    // this code for filter the divion and setion code based on section name dropdown
    if(this._searchform?.controls?.corporateBrandName?.value !== '' && 
        this._searchform.controls.divisionName.value){
        this.customerGroupNameMasterCGList = this.clonedivisionNameMasterList.filter(x=>{
        return x.divisionCodeName ===  this._searchform.controls.divisionName.value;
      }).filter(x=>x.corporateBrandCodeName === this._searchform?.controls?.corporateBrandName?.value)
      this.customerGroupNameFilterCGList =  this.customerGroupNameMasterCGList;
    }
    }, 500);
    
  }
  //clear button click event
  onSearchClear() {
    this.applicationInsightService.logEvent("Division Search clear event started");
    this._searchform.controls.corporateBrandName.setValue('');
    this._searchform.controls.corporateBrandCodeName.setValue('');
    this._searchform.controls.divisionCode.setValue('');
    this._searchform.controls.divisionName.setValue('');
    this._searchform.controls.customerGroupCodeName.setValue('');
    this._searchform.controls.customerGroupName.setValue('');
    this._searchform.controls.divisionCodeName.setValue('');
    this._searchform.controls['status'].setValue("active");

    this.filterItemCorporateBrand("");
    // this.filterItemGNUM("");
    this.filterItemDivisionName("");
    this.applicationInsightService.logEvent("Division Search clear event ended");
  }

  //Customer group Search Form
  _searchform: FormGroup = new FormGroup({
    corporateBrandName: new FormControl(''),
    corporateBrandCodeName: new FormControl(''),
    divisionCode: new FormControl(''),
    divisionName: new FormControl(''),
    divisionCodeName:new FormControl(''),
    customerGroupCodeName :new FormControl(''),
    customerGroupName: new FormControl(''),
    status: new FormControl()
  })

}
