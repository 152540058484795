import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, Subject } from 'rxjs';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { stringify } from '@angular/compiler/src/util';
import { ServiceApi } from '../../../services/ServiceApi';
let headers = new HttpHeaders()
if (!headers.has('content-type')) {
  headers = headers.append('content-type', 'application/json')
}

@Injectable({
  providedIn: 'root'
})
export class SectionService {


  constructor(private http: HttpClient, public _serviceApi: ServiceApi) {
  }

  headers = new HttpHeaders().set('content-type', 'application/json').set('accept', 'application/json');
  httpOptions = {
    headers: this.headers
  }
  // Observable string sources
  public refreshTable = new Subject<string>();
  // Observable string streams
  public refreshTablesection$ = this.refreshTable.asObservable();

  // Service message commands
  refreshsectionList() {
    this.refreshTable.next();
  }

  // Observable string sources
  public sectionIdSource = new Subject<string>();
  // Observable string streams
  public sectionId$ = this.sectionIdSource.asObservable();

  getsectionIdFromList(mission: string) {
    this.sectionIdSource.next(mission);
  }

  //Get Section All by filter from Service
  getSectionAll(): Observable<any> {
    var ServiceURL = this._serviceApi.SectionGetAll
    return this.http.get(ServiceURL);
  }
  //Get Section All by filter from BOCOS Service
  getSectionAllBOCOS(): Observable<any> {
    var ServiceURL = this._serviceApi.GetSectionAllBocos
    return this.http.get(ServiceURL);
  }
  //List Section from Service
  getSectionAllByFilter(
    pageNumber: number,
    pageSize: number,
    corporateBrandName: string,
    sectionName: string,
    sectionCode: number,
    divisionName: string,
    sectionSortOrder: string,
    active: boolean,
    draft: boolean,
    sortOrderColumn: string,
    sortByOrder: string): Observable<any> {

    var ServiceURL = this._serviceApi.SectionGetAll + "?"
      + "pageNumber=" + pageNumber
      + "&pageSize=" + pageSize
      + "&corporateBrandName=" + encodeURIComponent(corporateBrandName)
      + "&sectionName=" + sectionName
      + "&sectionCode=" + sectionCode
      + "&divisionName=" + divisionName
      + "&sectionSortOrder=" + sectionSortOrder
      + "&active=" + active
      + "&draft=" + draft
      + "&sortOrderColumn=" + sortOrderColumn
      + "&sortByOrder=" + sortByOrder;
    console.log(ServiceURL)
    return this.http.get(ServiceURL);
  }

  //Create the new section
  createsection(createNewsection: any): Observable<any> {

    return this.http.post(this._serviceApi.SectionCreate, JSON.stringify(createNewsection), { 'headers': headers });
  }

  //Update the exisiting section
  updatesection(updatesection: any): Observable<any> {

    return this.http.put(this._serviceApi.SectionUpdate, JSON.stringify(updatesection), { 'headers': headers });
  }

  //Get section By Id
  getsectionById(sectionId: any): Observable<any> {

    let url = this._serviceApi.SectionGetByID
    return this.http.get(url + sectionId)

  }
}
