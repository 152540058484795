import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs';
import { ServiceApi } from '../../../services/ServiceApi';
import { Subject } from 'rxjs';

let headers = new HttpHeaders()
if (!headers.has('content-type')) {
  headers = headers.append('content-type', 'application/json')
}

@Injectable({
  providedIn: 'root'
})


export class SubindexService {

  constructor(private http: HttpClient,public _serviceApi: ServiceApi) { }

  headers = new HttpHeaders().set('content-type', 'application/json').set('accept', 'application/json');
  httpOptions = {
    headers: this.headers
  }

   // Observable string sources
   public subIndexIdSource = new Subject<string>();
   // Observable string streams
    public subIndexId$ = this.subIndexIdSource.asObservable();

    // Service message commands
    getSubindexIDFromList(mission: string) {
      this.subIndexIdSource.next(mission);
    }
  
      // Observable string sources
      public refreshTable = new Subject<string>();
      // Observable string streams
       public refreshTableSubIndex$ = this.refreshTable.asObservable();
   
      // Service message commands
      refreshSubindexList() {
        this.refreshTable.next();
      }

  //Get all SubIndex from the Service
  getSubIndexAll(pageNum: number, pageSize: number, sortColumn: string, sortBy: string, corporateBrandName: string, IndexCode: string, subIndexCode: string, subIndexName: string, active: boolean, draft: boolean): Observable<any> {

    var ServiceURL = this._serviceApi.SubIndexAll + "?" + "pageNumber=" + pageNum + "&pageSize=" + pageSize + "&SortOrderColumn=" + sortColumn + "&sortByOrder=" + sortBy + "&corporateBrandName=" + encodeURIComponent(corporateBrandName) + "&IndexCode=" + IndexCode + "&subIndexCode=" + subIndexCode  + "&subIndexName=" + subIndexName + "&active=" + active + "&draft=" + draft;
    console.log(ServiceURL);
    return this.http.get(ServiceURL);
  }

   //Get all Sub Index List from the Service
   getSubIndexList(): Observable<any> {
     return this.http.get(this._serviceApi.SubIndexAll);
  }
   
//get Season by range
      getDepartmentSeasonByRange(current: number,
        prec: number, 
        succ: number
       ): Observable<any> {
      var ServiceURL = this._serviceApi.DepartmentGetSeasonByRange + "?" +
        "current=" + current +
        "&prec=" + prec +
        "&succ=" + succ ;
        return this.http.get(ServiceURL);
      }
  //Create SubIndex
  createSubIndex(createnewsubindex: any): Observable<any> {
    
    var body = (JSON.stringify(createnewsubindex));
    return this.http.post(this._serviceApi.SubIndexCreate, body, { 'headers': headers });

  }

  //GetById SubIndex
  getSubIndexById(subIndexId: number): Observable<any> {
    let url = this._serviceApi.SubIndexById
    return this.http.get(url + subIndexId)
  }

  //Update SubIndex
  updateSubIndex(updatesubindex: any): Observable<any> {
    
    var body = (JSON.stringify(updatesubindex));
    return this.http.put(this._serviceApi.SubIndexUpdate, body, { 'headers': headers });
  }

}
