import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countryPipe'
})
export class countryPipe implements PipeTransform {
  transform(value: any, args?: any) {
    if(value=="createHit"){
      return "Create New H&M Country"
    }else if(value=="editHit"){
      return "Update H&M Country"
    }else {
      return "View H&M Country"
    }
   
  }
}