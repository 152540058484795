import { Component, Input, ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";

@Component({
  selector: "mat-select-all",
  templateUrl: "./mat-select-all.component.html",
  styleUrls: ["./mat-select-all.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class MatSelectAllComponent {
  @Input() model: FormControl;
  @Input() values = [];
  @Input() text = "All";

  isChecked(): boolean {
    return (
      this.model.value &&
      this.values.length &&
      this.model.value.length === this.values.length
    );
  }

  isIndeterminate(): boolean {
    return (
      this.model.value &&
      this.values.length &&
      this.model.value.length !== 0 &&
      this.model.value.length < this.values.length
    );
  }

  toggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.model.setValue(this.values);
    } else {
      this.model.setValue([]);
    }
    console.log(this.model.value);
  }
}
