import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, of, Subject } from 'rxjs';
import { ServiceApi } from '../../../services/ServiceApi';

let headers = new HttpHeaders()
if (!headers.has('content-type')) {
  headers = headers.append('content-type', 'application/json')
}

@Injectable({
  providedIn: 'root'
})

export class GeographicalcountryService {

  constructor(private http: HttpClient, public _serviceApi: ServiceApi) {
  }

  headers = new HttpHeaders().set('content-type', 'application/json').set('accept', 'application/json');
  httpOptions = {headers: this.headers}

  // Observable string sources
  public refreshTable = new Subject<string>();
  // Observable string streams
  public refreshTablegeoraphicalcountry$ = this.refreshTable.asObservable();
  // Service message commands
  refreshCountryList() {
    this.refreshTable.next();
  }
  // Observable string sources
  public geographicalCountryIdSource = new Subject<string>();
  // Observable string streams
  public geographicalCountryId$ = this.geographicalCountryIdSource.asObservable();

  getGeographicalcountryIDFromList(mission: string) {
    this.geographicalCountryIdSource.next(mission);
  }
 
  //Get TradeUnion List
  gettradeUnionList(): Observable<any> {
    return of({
      "tradeUnionList": [
        { "name": 'EU',"id": "1" },
        { "name": 'SH',"id": "2" }
       ]
    })
  }
  //Get All Country
  getCountryAll(): Observable<any> {
    return this.http.get(this._serviceApi.CountryGetAll);
  }

  //Create the new Country
  createCountry(createNewCountry: any): Observable<any> {
    return this.http.post(this._serviceApi.CountryCreate, JSON.stringify(createNewCountry), { 'headers': headers });
  }

  //Update the exisiting Country
  updateCountry(updateCountry: any): Observable<any> {
    return this.http.put(this._serviceApi.CountryUpdate, JSON.stringify(updateCountry), { 'headers': headers });
  }

  //Get Country By Id
  getCountryById(geographicalCountryId: any): Observable<any> {
    
    return this.http.get(this._serviceApi.CountryGetByID + geographicalCountryId)
  }

}
