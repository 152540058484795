<div style="width: 400px">
  <form [formGroup]="hmCountryForm">
    <section class="titlebg">
      <h2 mat-dialog-title class="align">{{recivedBtnMsg | countryPipe }}</h2>
    </section>
    <!-- <p>Select an allowed Geographical Country for which to create the new HM Country </p> -->
    <div *ngIf="hideGeographicalDropdown">
    <mat-form-field [hidden]="true" [appearance]="false">
      <mat-label>Geographical Country</mat-label>
      <input
        type="text"
        id="inpgeographicalCountry"
        matInput
        [matAutocomplete]="autoGC"
        formControlName="geographicalCountryId"
        (ngModelChange)="filterItemgeographicalCountry($event)"
      />
      <mat-autocomplete
        autoActiveFirstOption
        (optionSelected)="getGeoraphicalCountryId($event.option.value)"
        #autoGC="matAutocomplete"
        [displayWith]="displayTextGC.bind(this)"
      >
        <mat-option
          *ngFor="let CBname of geographicalCountryFilterCGList"
          [value]="CBname.geographicalCountryId"
        >
          {{ CBname.geographicalCountryName }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <span >
      <mat-spinner *ngIf="spinner" [diameter]="20"></mat-spinner>
    </span>
  </div>
    <div *ngIf="isGeocountryslected">
      <mat-form-field [appearance]="true">
        <mat-label>Geographical Country Name</mat-label>
        <input
          type="text"
          [readonly]="true"
          formControlName="hmCountryName"
          matInput
        />
      </mat-form-field>
      <mat-form-field [appearance]="true">
        <mat-label>ISO Code</mat-label>
        <input
          type="text"
          [readonly]="true"
          formControlName="hmCountryISOCode"
          matInput
        />
      </mat-form-field>
      <mat-form-field [appearance]="false">
        <mat-label>Served by another fiscal country</mat-label>
        <input
          type="text"
          id="inpfisicalCountry"
          matInput
          [matAutocomplete]="autoFC"
          formControlName="servedByAnotherFiscalCountryId"
          (ngModelChange)="filterItemfiscalCountry($event)"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #autoFC="matAutocomplete"
          [displayWith]="displayTextFC.bind(this)"
        >
          <mat-option
            *ngFor="let Fcname of fiscalcountryFilterCGList"
            [value]="Fcname.fiscalCountryId"
          >
            {{ Fcname.geographicalCountryName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-card class="example-card">
        <div class="outersqure">
          <div class="parttype">
            <p>Type</p>
            <mat-checkbox class="example-margin" [disabled]="true" formControlName="fiscalCountry" >Fiscal</mat-checkbox>
            <mat-checkbox class="example-margin" [disabled]="true" formControlName="logisticCountry" >Logistic</mat-checkbox>
            <mat-checkbox class="example-margin" [disabled]="true" formControlName="countryOfProduction" >Production</mat-checkbox>
            <mat-checkbox class="example-margin" [disabled]="true" formControlName="countryOfDelivery" >Delivery</mat-checkbox>
            <mat-checkbox class="example-margin" [disabled]="true" formControlName="countryOfOrigin" >Origin</mat-checkbox>
          </div>
          <div class="partallowed">
            <p>Allowed</p>
            <mat-checkbox class="example-margin padding" formControlName="fiscalCountryAllowed" ></mat-checkbox>
            <mat-checkbox class="example-margin padding" formControlName="logisticCountryAllowed" ></mat-checkbox>
            <mat-checkbox class="example-margin padding" formControlName="countryOfProductionAllowed"></mat-checkbox>
            <mat-checkbox class="example-margin padding" formControlName="countryOfOriginAllowed" ></mat-checkbox>
            <mat-checkbox class="example-margin padding" formControlName="countryOfDeliveryAllowed"></mat-checkbox>
          </div>
          <div class="partthreestatus">
            <p>Status</p>
            <mat-checkbox
              id="active"
              formControlName="active"
              class="example-margin"
              color="primary"
              (change)="ActiveCheck($event)"
              >Active
            </mat-checkbox>
            <mat-checkbox
              id="draft"
              formControlName="draft"
              class="example-margin"
              color="primary"
              (change)="DraftCheck($event)"
              >Draft
            </mat-checkbox>
          </div>
        </div>
      </mat-card>
      <div mat-dialog-actions *ngIf="userrole">
        <button
        *ngIf="hidesaveBtn"
          mat-button
          class="add-button-container mat-raised-button mat-primary ml-2"
          type="submit"
          [disabled]="!hmCountryForm.dirty"
          (click)="createOrUpdateHmCountry(recivedBtnMsg)"
        >
          Save
        </button>
        
        <button
          mat-button
          class="add-button-container mat-raised-button mat-primary ml-2"
          (click)="cancel()"
        >
          Cancel
        </button>
      </div>
      <span *ngIf="!userrole" class="validerror">You can't update changes since you
        have Read access.
        <br> <button mat-button class="add-button-container mat-raised-button mat-primary ml-2"
            (click)="cancel()">Cancel</button></span>
    </div>
  </form>
</div>
