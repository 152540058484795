<div class="data-table" class="org-table">
  <table id="tblGarmentGroup" [dataSource]="dataSource" mat-table class="full-width-table" matSort (matSortChange)="sortData($event)" matSortActive="lastUpdatedTime" matSortStart="desc"
         aria-label="Elements">
    <ng-container matColumnDef="garmentGroupCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="garmentGroupCode">Garment Group Code</th>
      <td mat-cell *matCellDef="let row" id="custom-col150"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.garmentGroupCode}}
      </td>
    </ng-container>
    <ng-container matColumnDef="garmentGroupName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="garmentGroupName">Garment Group </th>
      <td mat-cell *matCellDef="let row" [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')" matTooltip={{row.garmentGroupName}} [matTooltipDisabled]="row.garmentGroupName.length<40" matTooltipPosition="left"  matTooltipClass="my-custom-tooltip">
        {{row.garmentGroupName}}<span *ngIf="row.garmentGroupName.length>40">...</span></td>
    </ng-container>
    <ng-container matColumnDef="garmentGroupSortOrder">
      <th matSort (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header id="garmentGroupSortOrder">Sort Order</th>
      <td mat-cell *matCellDef="let row" 
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.garmentGroupSortOrder}}
      </td>
    </ng-container>
    <ng-container matColumnDef="lastUpdatedTime">
      <th matSort (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header id="updatedDate">Updated Date</th>
      <td mat-cell *matCellDef="let row"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
          <span  *ngIf="!customDateTime">{{ row.lastUpdatedTime | customDateTime }}</span> 
      </td>
    </ng-container>
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef id="Actions custom-col2"><span *ngIf="userrole">Edit | </span>View</th>
      <td mat-cell *matCellDef="let row" id="custom-col100"
        [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        <a id="btnEdit" *ngIf="userrole" color="primary" (click)="updateRecord(row)" matTooltip="Edit" matTooltipPosition="right"
          class="org-edit">Edit | </a>
          <a id="btnView"(click)="openDialog(row)" matTooltip="View Details" matTooltipPosition="right"
          class="org-edit">View</a>
      </td>
    </ng-container>
   <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell aligncenter" colspan="8">No records found</td>
    </tr>
  </table>
  <div id="main-wrap">
    <section id="sidebar">
      <div class="example-button-row">
        <div class="example-flex-container indicator">
          <button mat-icon-button aria-label="Datatable color code">
            <mat-icon><span class="material-icons activeindicator">
                stop
              </span></mat-icon>
            Active
          </button>
          <button mat-icon-button aria-label="Datatable color code">
            <mat-icon><span class="material-icons inactiveindicator">
                stop
              </span></mat-icon>
            Inactive
          </button>
          <button mat-icon-button aria-label="Datatable color code">
            <mat-icon><span class="material-icons draftindicator">
                stop
              </span></mat-icon>
            Draft
          </button>
          <button mat-icon-button aria-label="Datatable color code">
            <mat-icon><span class="material-icons firstdraftindicator">
                stop
              </span></mat-icon>
            First Draft
          </button>
        </div>
      </div>
    </section>
    <mat-paginator #paginator id="tblPaginator" [(length)]="length" [(pageSize)]="pageSize"
                   [pageSizeOptions]="getpagesizeoptions()" (page)="pageEvent = $event"
                   showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
