import { Component, OnInit, ViewChild, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { GeographicalcountryService } from '../../../../services/country/geographicalcountry/geographicalcountry.service';
import { geographicalCountryModel } from '../../../../models/country/geographicalcountry/geographicalCountryModel'
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
@Component({
  selector: 'app-geographicalcountrylist',
  templateUrl: './geographicalcountrylist.component.html',
  styleUrls: ['./geographicalcountrylist.component.css']
})
export class GeographicalcountrylistComponent implements OnInit {
  @Input() countrySearch: any;
  @Output() childButtonEvent = new EventEmitter();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<geographicalCountryModel>;
  public dataSource: MatTableDataSource<geographicalCountryModel>;
  pageNumber: any = 1;
  length: any;
  pageIndex: any;
  pageSize: any = 15;
  sortByColumnName: any = "lastUpdatedTime";
  sortByOrder: any = "asc";
  pageEvent: PageEvent;
  errorMsg: any;
  customDateTime: string;
  emitter: number = 1;
  displayedColumns = [
    'geographicalCountryName',
    'geographicalCountryAlpha2ISOCode',
    'geographicalCountryAlpha3ISOCode',
    'geographicalCountryNumericISOCode',
    'hMCountry',
    'tradeUnionName',
    'actualGeographicalCountryName',
    'lastUpdatedTime',
    'Actions'
  ];
  constructor(public _geocountryService: GeographicalcountryService,
    private applicationInsightService: ApplicationInsightService,) { }

getPageSizeOptions(): number[] {
    if (this.length > 25)
      return [10, 20, 25, this.length];
    else if (this.length > 20)
      return [10, 20, this.length];
    else if (this.length > 10)
      return [10, this.length];
    return null;
  }

  ngOnInit(): void {
    if(this._geocountryService.refreshTablegeoraphicalcountry$!=null||undefined){
      this._geocountryService.refreshTablegeoraphicalcountry$.subscribe(
        refreshTableFiscalCountry => {
          this.emitter = 1;
          this.BindCountryData();
        });
    }
  }
 
  ngOnChanges(changes: SimpleChanges) {
    this.BindCountryData();
  }
  sortData(sort: Sort) {
    this.applicationInsightService.logEvent("GeographicalCountry sort Started");
    this.sortByColumnName = sort.active;
    this.sortByOrder = sort.direction;
    this.BindCountryData();
    this.applicationInsightService.logEvent("GeographicalCountry sort Ended");
  }

  BindCountryData() {
    this.applicationInsightService.logEvent("GeographicalCountry Binding list Started");
    let newcountryList: any[] = [];
    let filterData: any[] = [];
    this._geocountryService.getCountryAll().subscribe((data: any) => {
      newcountryList = data.geographicalCountry;
      if (newcountryList != null  && newcountryList != []) {
      var templist = data.geographicalCountry;
      for (let i = 0; i < newcountryList.length; i++) {
        for (let j = 0; j < templist.length; j++) {
          if (newcountryList[i].actualGeographicalCountryId == templist[j].geographicalCountryId)
            newcountryList[i].actualGeographicalCountryName = templist[j].geographicalCountryName;
        }
      }
      
        filterData = newcountryList.filter(x => x.geographicalCountryName.toString().toLowerCase().includes(this.countrySearch.geographicalCountryName.toString().toLowerCase()))
          .filter(x => x.geographicalCountryAlpha2ISOCode.toString().toLowerCase().includes(this.countrySearch.geographicalCountryAlpha2ISOCode.toString().toLowerCase()))
          .filter(x => x.geographicalCountryAlpha3ISOCode.toString().toLowerCase().includes(this.countrySearch.geographicalCountryAlpha3ISOCode.toString().toLowerCase()))
          .filter(x => x.geographicalCountryNumericISOCode.toString().toLowerCase().includes(this.countrySearch.geographicalCountryNumericISOCode.toString().toLowerCase()));
        if (this.countrySearch.hMCountry == true) {
          filterData = filterData.filter(x => x.hmCountry == true)
        }
        this.dataSource = new MatTableDataSource(filterData);
        this.sort.active = this.sortByColumnName;
        this.sort.direction = this.sortByOrder;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.length = this.dataSource.data.length;
        if (this.emitter == 1) {
          this.emitter = 2;
          this.childButtonEvent.emit(data.geographicalCountry);
        }
      }
    },
      error => {
        this.errorMsg = error;
        //this.toastr.error(error);
      });
    this.applicationInsightService.logEvent("GeographicalCountry Binding list Ended");
  }

  updateRecord(updateRowData: any) {
    this._geocountryService.getGeographicalcountryIDFromList(updateRowData.geographicalCountryId);
  }

  public GetCountryDataWithFilters(event?: PageEvent) {
    this.applicationInsightService.logEvent("GeographicalCountry paginator Started");
    this.pageSize = event.pageSize;
    this.BindCountryData();
    this.applicationInsightService.logEvent("GeographicalCountry paginator Ended");
    return event;

  }


}
