import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, Subject } from 'rxjs';
import { ServiceApi } from '../../../services/ServiceApi';

let headers = new HttpHeaders()
if (!headers.has('content-type')) {
  headers = headers.append('content-type', 'application/json')
}

@Injectable({
  providedIn: 'root'
})

export class ChannelService {

  constructor(private http: HttpClient, public _serviceApi: ServiceApi) {
  }

  headers = new HttpHeaders().set('content-type', 'application/json').set('accept', 'application/json');
  httpOptions = {headers: this.headers}

  // Observable string sources
  public refreshTable = new Subject<string>();
  // Observable string streams
  public refreshTablechannel$ = this.refreshTable.asObservable();
  // Service message commands
  refreshChannelList() {
    this.refreshTable.next();
  }
  // Observable string sources
  public channelIdSource = new Subject<string>();
  // Observable string streams
  public channelId$ = this.channelIdSource.asObservable();

  getchannelIDFromList(mission: string) {
    this.channelIdSource.next(mission);
  }

  //Get All Channel
  getChannelAll(): Observable<any> {
    return this.http.get(this._serviceApi.ChannelGetAll);
  }

  //Create the new Channel
  createChannel(createNewChannel: any): Observable<any> {
    return this.http.post(this._serviceApi.ChannelCreate, JSON.stringify(createNewChannel), { 'headers': headers });
  }

  //Update the exisiting Channel
  updateChannel(updateChannel: any): Observable<any> {
    return this.http.put(this._serviceApi.ChannelUpdate, JSON.stringify(updateChannel), { 'headers': headers });
  }

  //Get Channel By Id
  getChannelById(channelId: any): Observable<any> {
    return this.http.get(this._serviceApi.ChannelGetByID + channelId)
  }

}
