
<div class="mat-elevation-z8 data-table subtable">
  <table id="tblVat" [dataSource]="dataSource" mat-table class="full-width-table" matSort matSortActive="vat" matSortStart="desc"
         aria-label="Elements">
    <ng-container matColumnDef="vat">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-color" id="depSIStartSeasonColumn">VAT</th>
      <td mat-cell *matCellDef="let row" [ngClass]="row.isFirstDraft==true ? 'FiscalDraftBg' : 'ActiveBg'">
        {{row.vat}}
      </td>
    </ng-container>
    <ng-container matColumnDef="fromDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-color" id="depSIEndSeasonColumn"> Valid From</th>
      <td mat-cell *matCellDef="let row" [ngClass]="row.isFirstDraft==true ? 'FiscalDraftBg' : 'ActiveBg'">{{row.fromDate | customDate }}</td>
    </ng-container>
    <ng-container matColumnDef="toDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-color" id="depSIEndSeasonColumn"> Valid To</th>
      <td mat-cell *matCellDef="let row" [ngClass]="row.isFirstDraft==true ? 'FiscalDraftBg' : 'ActiveBg'">{{row.toDate | customDate }}</td>
    </ng-container>
    <ng-container matColumnDef="Edit">
      <th mat-header-cell *matHeaderCellDef class="custom-color" id="vatEditColumn">Edit</th>
      <td mat-cell *matCellDef="let row" [ngClass]="row.isFirstDraft==true ? 'FiscalDraftBg' : 'ActiveBg'">
        <mat-icon color="primary" (click)="updateRecord(row)"
                  style="background-color: rgb(255 255 255 / 0%);cursor: pointer;">drive_file_rename_outline</mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="Delete">
      <th mat-header-cell *matHeaderCellDef class="custom-color" id="vatDeleteColumn">Delete</th>
      <td mat-cell *matCellDef="let row" [ngClass]="row.isFirstDraft==true ? 'FiscalDraftBg' : 'ActiveBg'">
        <mat-icon [ngClass]="(row.isDelete === true) ? 'isdeletetrue':'isdeletefalse'" (click)="row.isDelete ? onRemoveRow(row) :''" matTooltip="Delete" matTooltipPosition="right"
                  style="cursor: pointer;">delete_outline</mat-icon>
      </td>
    </ng-container>
   <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="8" style="text-align: center;">No records found</td>
    </tr>
  </table>
  <div id="main-wrap">
    <section style="background-color: #00558d;color:#fff">
  <div class="example-button-row">
    <div class="example-flex-container">
      <button mat-icon-button style="width:25%;" aria-label="Datatable color code">

        <mat-icon>
          <span class="material-icons"
                style="color:#fff; text-shadow: 0px 1px 0 #000, 1px 1px 0 #000, 1px 1px 0 #000, 1px 1px 0 #000;">
            stop
          </span>
        </mat-icon>
        Saved
      </button>
      <button mat-icon-button style="width:27%;" aria-label="Datatable color code">
         <mat-icon>
          <span class="material-icons"
                style="color: #d7bd5e;text-shadow: 0px 1px 0 #000, 1px 1px 0 #000, 1px 1px 0 #000, 1px 1px 0 #000;">
            stop
          </span>
        </mat-icon>
        Unsaved
      </button>
    </div>
  </div>
    </section>
  </div>
</div>
<!-- Form Section -->
<div class="mat-elevation-z8 add-form-field" *ngIf="vatForm">
  <form [formGroup]="_fcvatForm">
    <div class="example-container">

      <mat-form-field floatLabel='always' appearance='outline' class="width-20">
        <input type="number" hidden formControlName="fiscalCountryVATId" [(ngModel)]="updatefcvatForm.fiscalCountryVATId">
        <input type="number" hidden formControlName="tempRowIndex" [(ngModel)]="updatefcvatForm.tempRowIndex">
        <mat-label>VAT</mat-label>
        <input type="number" min="1" matInput formControlName="vat" [(ngModel)]="updatefcvatForm.vat" id="inpvat">
      </mat-form-field>
      <span style="font-size: 20px;color: #00558d;margin-top: 2%;">&nbsp;%</span>
      <mat-form-field floatLabel='always' appearance='outline' class="width-30 ml-2">
        <mat-label>Valid From</mat-label>
        <input [matDatepicker]="pickerfrom" (ngModelChange)=fnFromDateValidation() matInput formControlName="fromDate" [(ngModel)]="updatefcvatForm.fromDate" id="inpvatvalidfrom">
        <mat-datepicker-toggle matSuffix [for]="pickerfrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerfrom></mat-datepicker>

        <mat-error *ngIf="_fcvatForm.get('fromDate').errors">{{_fcvatForm.get('fromDate').errors['invalidDate']}}</mat-error>
      </mat-form-field>
      <mat-form-field floatLabel='always' appearance='outline' class="width-30 ml-2">
        <mat-label>Valid To</mat-label>
        <input [matDatepicker]="pickerto" (ngModelChange)=fnToDateValidation() matInput formControlName="toDate" [(ngModel)]="updatefcvatForm.toDate" id="inpvatvalidto">
        <mat-datepicker-toggle matSuffix [for]="pickerto"></mat-datepicker-toggle>
        <mat-datepicker #pickerto></mat-datepicker>
        <mat-error *ngIf="_fcvatForm.get('toDate').errors">{{_fcvatForm.get('toDate').errors['invalidDate']}}</mat-error>
      </mat-form-field>
      <br>
      <mat-dialog-actions *ngIf="!userrole">
        <button id="inpbtnsavevat" mat-raised-button class="add-button-container mat-raised-button mat-primary" (click)="onAddRow()">Add</button>
        <button id="inpbtnclearvat" mat-raised-button class="add-button-container mat-raised-button mat-primary ml-2" (click)="onClear()">Clear</button>
      </mat-dialog-actions>
      <span *ngIf="userrole" style="color: #ff0202;font-size: 12px;font-weight: 400;">You can't save changes since you have Read access.</span>

    </div>
  </form>

</div>
