import { Injectable } from '@angular/core';
import { HttpClient,  HttpHeaders } from '@angular/common/http'
import { Observable, Subject } from 'rxjs';
import { ServiceApi } from '../../../services/ServiceApi';

let headers = new HttpHeaders()

    if (!headers.has('content-type')) {

      headers=headers.append('content-type','application/json')

    }
@Injectable({
  providedIn: 'root'
}) 
export class CustomerGroupService {
  headers = new HttpHeaders().set('content-type','application/json').set('accept','application/json');
  httpOptions ={
    headers:this.headers
  }

// Observable string sources
public refreshTable = new Subject<string>();
// Observable string streams
 public refreshTableCustomerGroup$ = this.refreshTable.asObservable();

// Service message commands
refreshCustomerGroupList() {
  this.refreshTable.next();
}


  constructor(private http: HttpClient,public _serviceApi: ServiceApi) {}

   // Observable string sources
   public customerGroupIdSource = new Subject<string>();
   // Observable string streams
  public customerGroupId$ = this.customerGroupIdSource.asObservable();
  
  getCustomerGroupIDFromList(mission: string) {
    this.customerGroupIdSource.next(mission);
  }

  getCustomerGroupAll():Observable<any>{
    let url = this._serviceApi.CustomerGroupGetAll
    return this.http.get(url);
  }
  
  getCustomerGroupByCBId(corporateBrandId:number):Observable<any>{
    let url = this._serviceApi.CustomerGroupGetByCBID 
    console.log(url + corporateBrandId)
    return this.http.get(url + corporateBrandId)
  }
  //List Customer Group from Service
 getCustomerGroupList():Observable<any> 
 {
   let url = this._serviceApi.CustomerGroupGetAll;
   return this.http.get(url);
    
 }
  createCustomerGroup(createNewCustomer:any):Observable<any>{
    let url = this._serviceApi.CustomerGroupCreate
    var requestBody =(JSON.stringify(createNewCustomer));
    return  this.http.post(url,requestBody,{'headers':headers});
  }
 
  
  getCustomerGroupById(customerGroupId:number):Observable<any>{
    let url = this._serviceApi.CustomerGroupById 
    return this.http.get(url + customerGroupId)
  }

  updateCustomerGroup(updateCustomerGroup:any):Observable<any> {
    let url=this._serviceApi.CustomerGroupUpdate;
    var body=(JSON.stringify(updateCustomerGroup));        
    return this.http.put(url,body,{'headers':headers});
  }
 
}
