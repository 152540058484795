import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServiceApi } from '../../../services/ServiceApi';
import { Observable, Subject } from 'rxjs';

let headers = new HttpHeaders()
if (!headers.has('content-type')) {
  headers = headers.append('content-type', 'application/json')
}

@Injectable({
  providedIn: 'root'
})
export class MarkupGroupService {

  headers = new HttpHeaders().set('content-type', 'application/json').set('accept', 'application/json');
  httpOptions = {
    headers: this.headers
  }

  constructor(private http: HttpClient,public _serviceApi: ServiceApi) { }

  // Observable string sources
  public departmentMarkupGroupIdSource = new Subject<string>();

  // Observable string streams
  public departmentMarkupGroupId$ = this.departmentMarkupGroupIdSource.asObservable();

  // Service message commands
  getdepartmentMarkupGroupIdFromList(mission: string) {
    this.departmentMarkupGroupIdSource.next(mission);
  }

  // Observable string sources
  public refreshTable = new Subject<string>();
  // Observable string streams
  public refreshTableDepartmentMarkupGroup$ = this.refreshTable.asObservable();

  // Service message commands
  refreshTableDepartmentMarkupGroupList() {
    this.refreshTable.next();
  }

  //Create Department Markup Group
  createDepartmentMarkupGroup(createNewDepartmentMarkupGroup: any): Observable<any> {
    return this.http.post(this._serviceApi.DptMarkupGroupCreate, JSON.stringify(createNewDepartmentMarkupGroup), { 'headers': headers });
  }

  //Update Department MarkupGroup
  updateDepartmentMarkupGroup(updateDepartmentMarkupGroup: any): Observable<any> {
    return this.http.put(this._serviceApi.DptMarkupGroupUpdate, JSON.stringify(updateDepartmentMarkupGroup), { 'headers': headers });
  }

  //Get Department MarkupGroup By Id
  getDepartmentMarkupGroupById(departmentMarkupGroupInfoId: any): Observable<any> {
    return this.http.get(this._serviceApi.DptMarkupGroupGetByID + departmentMarkupGroupInfoId);
  }
 
  //Delete Department MarkupGroup
  DeleteDepartmentMarkupGroup(deleteDepartmentMarkupGroup: any): Observable<any> {
    return this.http.post(this._serviceApi.DptMarkupGroupDelete, JSON.stringify(deleteDepartmentMarkupGroup), { 'headers': headers });
  }

  getDepartmentMarkupGroupAll(){
    return this.http.get(this._serviceApi.MarkupgroupGetAll)
  }

}
