import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { AccountService } from '../../../../shared/services/account.service'
import { GeographicalcountryService } from 'src/app/services/country/geographicalcountry/geographicalcountry.service';
import { constant } from '../../../../shared/const/constant'
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { UserAccess } from 'src/app/models/userAccess';
import { ModulePermission } from 'src/app/models/enum';

@Component({
  selector: 'app-geographicalcountryform',
  templateUrl: './geographicalcountryform.component.html',
  styleUrls: ['./geographicalcountryform.component.css']
})
export class GeographicalcountryformComponent implements OnInit {
  @Input() countryList: any;
  title = "Geographical Country";
  updateCountryForm: any;
  isGeoCountryEdit: any = false;
  isDisableAllowed: any = true;
  isFakeCountryActive: any = true;
  displayErrorMsg: any = false;
  countryMasterlist: any[] = [];
  countryFilterList: any[] = [];
  validationMessages: any;
  tradeUnionList: any[] = [];
  selectedTrade: any
  isIndexEditCB: any = 'outline';
  userrole: boolean;
  constructor(
    public toastr: ToastrService,
    public router: Router,
    public route: ActivatedRoute,
    public titleService: Title,
    public account: AccountService,
    private applicationInsightService: ApplicationInsightService,
    public _geocountryService: GeographicalcountryService,
    public _userAccessService: UserAccess) {

    this.updateCountryForm = Object.assign({ active: true, geographicalCountryAllowed: true, draft: true });
  }

  ngOnInit(): void {
    this.userrole =this._userAccessService.getismenuEnable.some((_m: any)=> this._userAccessService.getismenuEnable.includes(ModulePermission.Country_Write) ? true : false);
    this.titleService.setTitle(this.title);
    this._geocountryform.controls['actualGeographicalCountryId'].disable();
    this._geocountryform.controls['fakeCountryInfo'].disable();
    this._geocountryService.gettradeUnionList().subscribe((data: any) => {
      this.tradeUnionList = this.tradeUnionList = data.tradeUnionList
    });
    if (this._geocountryService.geographicalCountryId$ != null || undefined) {
      this._geocountryService.geographicalCountryId$.subscribe(geographicalCountryId => { this.updateCountry(geographicalCountryId) });
    }
    this.fnSetControlsValidation();
  }
  ngOnChanges() {
    this.countryMasterlist = this.countryFilterList = this.countryList;
  }
  ngAfterViewInit() {
    this.activeFakeCountry();
  }
  activeFakeCountry() {
    this.applicationInsightService.logEvent("GeographicalCountryForm activeFakeCountry Started");
    if (this._geocountryform.controls.isFakeCountry.value != undefined) {
      if (this._geocountryform.controls.isFakeCountry.value == true) {
        this._geocountryform.controls['actualGeographicalCountryId'].enable();
        this._geocountryform.controls['fakeCountryInfo'].enable();
      }
      else {
        this._geocountryform.controls.isFakeCountry.setValue('');
        this._geocountryform.controls.actualGeographicalCountryId.setValue('');
        this._geocountryform.controls.fakeCountryInfo.setValue('');
        this._geocountryform.controls['actualGeographicalCountryId'].disable();
        this._geocountryform.controls['fakeCountryInfo'].disable();
      }
    }
    this.applicationInsightService.logEvent("GeographicalCountryForm activeFakeCountry Ended");
  }
  filterItemGC(filterKeyGC: string) {
    this.applicationInsightService.logEvent("GeographicalCountryForm filter GeoCountryName Started");
    this.isFakeCountryActive = false;
    let newGCList: any[] = [];
    newGCList = this.countryMasterlist;
    if (filterKeyGC !== null && filterKeyGC !== "" && filterKeyGC !== undefined) {
      this.countryFilterList = newGCList.filter(gc => gc.geographicalCountryName.toLowerCase().includes(filterKeyGC.toString().toLowerCase()))
    }
    if (this.isGeoCountryEdit) {
      this.countryFilterList = this.countryMasterlist;
    }
    this.applicationInsightService.logEvent("GeographicalCountryForm filter GeoCountryName Ended");
  }
  displayTextGC(actualGeographicalCountryId: string) {
    if (actualGeographicalCountryId === null || actualGeographicalCountryId === "" || actualGeographicalCountryId === undefined)
      return null;
    else if (this.countryMasterlist != undefined && actualGeographicalCountryId != null)
      return this.countryMasterlist.find(x => x.geographicalCountryId == actualGeographicalCountryId).geographicalCountryName;
  }
  updateCountry(geographicalCountryId: any) {
    this.applicationInsightService.logEvent("GeographicalCountryForm get by id GeoCountry Started");
    this._geocountryform.controls.actualGeographicalCountryId.setValue('');
    this._geocountryform.controls.fakeCountryInfo.setValue('');
    if (geographicalCountryId != undefined) {
      this.isGeoCountryEdit = true;
      this._geocountryService.getCountryById(geographicalCountryId).subscribe((data: any) => {
        this.isDisableAllowed = false;
        this.isFakeCountryActive = data.geographicalCountry.isFakeCountry == true ? true : false;
        if (!this.isFakeCountryActive) {
          this._geocountryform.controls['actualGeographicalCountryId'].disable();
          this._geocountryform.controls['fakeCountryInfo'].disable();
        }
        else {
          this._geocountryform.controls['actualGeographicalCountryId'].enable();
          this._geocountryform.controls['fakeCountryInfo'].enable();
        }

        this.updateCountryForm = data.geographicalCountry;
        const result = this.tradeUnionList.filter(x => x.id == this.updateCountryForm.tradeUnionId);
        this._geocountryform.controls['tradeUnionId'].setValue(result[0].id);
      });
    }
    this.applicationInsightService.logEvent("GeographicalCountryForm get by id GeoCountry Ended");
  }

  onSubmit() {
    this._geocountryform.controls['geographicalCountryName'].updateValueAndValidity();
    this._geocountryform.controls['geographicalCountryAlpha2ISOCode'].updateValueAndValidity();
    this._geocountryform.controls['geographicalCountryAlpha3ISOCode'].updateValueAndValidity();
    this._geocountryform.controls['geographicalCountryNumericISOCode'].updateValueAndValidity();
    this._geocountryform.controls['tradeUnionId'].updateValueAndValidity();
    this._geocountryform.controls['isFakeCountry'].updateValueAndValidity();

    if (this._geocountryform.controls.isFakeCountry.value == true) {
      this._geocountryform.get('actualGeographicalCountryId').setValidators(Validators.required);
      this._geocountryform.get('fakeCountryInfo').setValidators(Validators.required);

      let FakeValue = this._geocountryform.controls.isFakeCountry.value;
      FakeValue.length > 255 ? this.displayErrorMsg = true : this.displayErrorMsg = false;
      this._geocountryform.get('fakeCountryInfo').setValidators([Validators.required, Validators.maxLength(255)]);
      this._geocountryform.controls['actualGeographicalCountryId'].updateValueAndValidity();
      this._geocountryform.controls['fakeCountryInfo'].updateValueAndValidity();
    }
    else {
      this._geocountryform.controls.actualGeographicalCountryId.clearValidators();
      this._geocountryform.controls.fakeCountryInfo.clearValidators();
    }
    if (this._geocountryform.controls.active.value === undefined || this._geocountryform.controls.active.value === null) { this._geocountryform.controls.active.setValue(false); }
    if (this._geocountryform.controls.draft.value === undefined || this._geocountryform.controls.draft.value === null) { this._geocountryform.controls.draft.setValue(true); }

    this._geocountryform.controls.lastUpdatedBy.setValue(this.account.userInfo.Email)
    console.log("Country Save " + JSON.stringify(this._geocountryform.value));
    if (this._geocountryform.valid) {
      if (this._geocountryform.value.geographicalCountryId == undefined || this._geocountryform.value.geographicalCountryId == 0 || this._geocountryform.value.geographicalCountryId == null) {
        this._geocountryService.createCountry(this._geocountryform.value).subscribe
          (data => {
            this.toastr.success(data.message, "", { timeOut: constant.toasterTimeout });
            this.onClear();
            this._geocountryService.refreshCountryList();
          },
            error => { this.toastr.error(error, "", { timeOut: constant.toasterTimeout }) })
      }
      else {
        this._geocountryService.updateCountry(this._geocountryform.value).subscribe
          (data => {
            this.toastr.success(data.message, "", { timeOut: constant.toasterTimeout });
            this.onClear();
            this._geocountryService.refreshCountryList();
          },
            error => { this.toastr.error(error, "", { timeOut: constant.toasterTimeout }) })
      }
    }
  }

  onClear() {
    this.applicationInsightService.logEvent("GeographicalCountryForm Clear Started");
    this._geocountryform.controls.geographicalCountryName.clearValidators();
    this._geocountryform.controls.geographicalCountryAlpha2ISOCode.clearValidators();
    this._geocountryform.controls.geographicalCountryAlpha3ISOCode.clearValidators();
    this._geocountryform.controls.geographicalCountryNumericISOCode.clearValidators();
    this._geocountryform.controls.tradeUnionId.clearValidators();
    this._geocountryform.controls.isFakeCountry.clearValidators();
    this._geocountryform.controls.actualGeographicalCountryId.clearValidators();
    this._geocountryform.controls.fakeCountryInfo.clearValidators();
    this._geocountryform.controls.geographicalCountryId.setValue('');
    this._geocountryform.controls.geographicalCountryName.setValue('');
    this._geocountryform.controls.geographicalCountryAlpha2ISOCode.setValue('');
    this._geocountryform.controls.geographicalCountryAlpha3ISOCode.setValue('');
    this._geocountryform.controls.geographicalCountryNumericISOCode.setValue('');
    this._geocountryform.controls.isFakeCountry.setValue(false);
    this._geocountryform.controls.actualGeographicalCountryId.setValue('');
    this._geocountryform.controls.fakeCountryInfo.setValue('');
    this._geocountryform.controls.tradeUnionId.setValue('');
    this._geocountryform.controls.hmCountry.setValue('');
    this._geocountryform.controls.geographicalCountryAllowed.setValue(true);
    this._geocountryform.controls.isFakeCountry.setValue(false);
    this._geocountryform.controls.active.setValue(true);
    this._geocountryform.controls.draft.setValue(true);
    this._geocountryform.controls.hmCountry.setValue(false);
    this.activeFakeCountry();
    this.fnSetControlsValidation();
    this.isGeoCountryEdit = false;
    this.isDisableAllowed = true;
    this.isFakeCountryActive = false;
    this.applicationInsightService.logEvent("GeographicalCountryForm Clear Ended");
  }

  _geocountryform: FormGroup = new FormGroup({
    geographicalCountryId: new FormControl(''),
    geographicalCountryName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    geographicalCountryAlpha2ISOCode: new FormControl('', [Validators.required, Validators.maxLength(2)]),
    geographicalCountryAlpha3ISOCode: new FormControl('', [Validators.required, Validators.maxLength(3)]),
    geographicalCountryNumericISOCode: new FormControl('', [Validators.required, Validators.pattern('[0-9][0-9][0-9]')]),
    tradeUnionId: new FormControl('', [Validators.required]),
    geographicalCountryAllowed: new FormControl(true),
    isFakeCountry: new FormControl(false),
    actualGeographicalCountryId: new FormControl(null),
    fakeCountryInfo: new FormControl(null),
    active: new FormControl(true),
    lastUpdatedBy: new FormControl(this.account.userInfo.Email),
    draft: new FormControl(true),
    hmCountry: new FormControl(false),
  });

  fnSetControlsValidation() {
    this._geocountryform.controls.geographicalCountryName.setValidators([Validators.required, Validators.maxLength(50)]);
    this._geocountryform.controls.geographicalCountryAlpha2ISOCode.setValidators([Validators.required, Validators.maxLength(2)]);
    this._geocountryform.controls.geographicalCountryAlpha3ISOCode.setValidators([Validators.required, Validators.maxLength(3)]);
    this._geocountryform.controls.geographicalCountryNumericISOCode.setValidators([Validators.required, Validators.pattern('[0-9][0-9][0-9]')]);
    this._geocountryform.controls.tradeUnionId.setValidators([Validators.required]);
  }

}
