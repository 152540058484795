<div class="org-container">
    <div class="export-button-container">
     <form [formGroup]="_searchform">
      <!-- <button class="add-button-container mat-raised-button mat-primary" (click)="onBtnExport()"
      matTooltip="ProductBrand table to Excel document" mat-raised-button>
      Export as Excel
      <mat-icon>get_app</mat-icon>
    </button> -->
        <mat-form-field appearance="legacy" class="statusclass">
          <mat-label>Status </mat-label>
          <mat-select id="Searchstatus" formControlName="status" [(ngModel)]="status" (selectionChange)="onGridReady(null)">
            <mat-option value="all">
              All
            </mat-option>
            <mat-option value="active">
              Active
            </mat-option>
            <mat-option value="draft">
              Draft
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="createbtnclass">
          <button class="add-button-container mat-raised-button mat-primary" (click)="openCreate()"
          matTooltip="Create New H&M Country" mat-raised-button>
          Create New H&M Country
          <!-- <mat-icon>get_app</mat-icon> -->
        </button>
        </div>
      </form>
    </div>
    <ag-grid-angular class="aggridcustom ag-theme-alpine" domLayout='autoHeight' [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
    [gridOptions]="gridOptions" [rowData]="rowData" (gridReady)="onGridReady($event)"
     [enableCellChangeFlash]="true">
    </ag-grid-angular>
    <div id="main-ag">
      <section id="sidebar-ag">
        <div class="example-button-row">
          <div class="example-flex-container indicator">
            <button mat-icon-button aria-label="Datatable color code">
              <mat-icon><span class="material-icons activeindicator">
                  stop
                </span></mat-icon>
              Active
            </button>
            <button mat-icon-button aria-label="Datatable color code">
              <mat-icon><span class="material-icons draftindicator">
                  stop
                </span></mat-icon>
              Draft
            </button>
         </div>
        </div>
      </section>
      <section id="Paginator-ag">
        Items per page:
        <select class="itempage" (change)="onChange($event.target.value)"  id="page-size">
          <option *ngFor="let option of gridCount" [value]="option">
            {{option}}
          </option>
        </select>
      </section>
    </div>
  </div>
  