import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GeographicalcountryService } from '../../../../services/country/geographicalcountry/geographicalcountry.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';


interface GeoCountryList {
  geographicalCountryId: any;
  geographicalCountryName: any;
}

@Component({
  selector: 'app-geographicalcountry',
  templateUrl: './geographicalcountry.component.html',
  styleUrls: ['./geographicalcountry.component.css']
})

export class GeographicalcountryComponent implements OnInit {
  title = "Geographical Country";
  ddgeographicalCountryName: GeoCountryList[];
  geographicalCountryNameMasterList: any[] = [];
  geographicalCountryNameFilterList: any[] = [];
  geographicalCountryAlpha3ISOCodeFilterList: any[] = [];
  geographicalCountryAlpha3ISOCodeMasterList: any[] = [];
  geographicalCountryAlpha2ISOCodeFilterList: any[] = [];
  geographicalCountryAlpha2ISOCodeMasterList: any[] = [];
  geographicalCountryNumericMasterList: any[] = [];
  geographicalCountryNumericFilterList: any[] = [];
  geographicalCountryName: any = "";
  geographicalCountryAlpha3ISOCode: any = "";
  geographicalCountryAlpha2ISOCode: any = "";
  geographicalCountryNumericISOCode: any = "";
  hMCountry: any = "";
  obj: any;

  constructor(
    public _geocountryService: GeographicalcountryService,
    private router: Router,
    public route: ActivatedRoute,
    private toastr: ToastrService,
    private applicationInsightService: ApplicationInsightService,
    private titleService: Title) { }

  ngOnInit(): void {
  }

  /* istanbul ignore next */
  receivedChildHandler(obj: any) {
    this.applicationInsightService.logEvent("GeographicalCountry receivedChildHandler Started");
    this.titleService.setTitle(this.title);   
    this.geographicalCountryNameMasterList = this.geographicalCountryNameFilterList = obj;
    if(this.geographicalCountryNameMasterList !=null||undefined){
      this.geographicalCountryNameFilterList = this.geographicalCountryNameMasterList.sort((a, b) => a.geographicalCountryName.localeCompare(b.geographicalCountryName));
      this.geographicalCountryAlpha2ISOCodeFilterList = this.geographicalCountryAlpha2ISOCodeMasterList = obj;
      this.geographicalCountryAlpha2ISOCodeFilterList = this.geographicalCountryAlpha2ISOCodeMasterList.sort((a, b) => a.geographicalCountryAlpha2ISOCode.localeCompare(b.geographicalCountryAlpha2ISOCode));
      this.geographicalCountryAlpha3ISOCodeFilterList = this.geographicalCountryAlpha3ISOCodeMasterList = obj;
      this.geographicalCountryAlpha3ISOCodeFilterList = this.geographicalCountryAlpha3ISOCodeMasterList.sort((a, b) => a.geographicalCountryAlpha3ISOCode.localeCompare(b.geographicalCountryAlpha3ISOCode));
    }
    this.applicationInsightService.logEvent("GeographicalCountry receivedChildHandler Ended");
    let newvar = this.geographicalCountryNumericFilterList = this.geographicalCountryNumericMasterList = obj.filter(object => {
      return object['active'] !== false;
    });;
    this.geographicalCountryNumericFilterList = newvar.sort((a,b) =>  (a.geographicalCountryNumericISOCode > b.geographicalCountryNumericISOCode ? 1 : -1));
    
  }

  filterItemGC(filterKey: string) {
    this.applicationInsightService.logEvent("GeographicalCountry filter GeoCountryName Started");
    if (filterKey != null && filterKey != "") {
      this.geographicalCountryNameFilterList = this.geographicalCountryNameMasterList.filter(x => x.geographicalCountryName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
      this.geographicalCountryNameFilterList = this.geographicalCountryNameFilterList.sort((a, b) => a.geographicalCountryName.localeCompare(b.geographicalCountryName));
    } else
      this.geographicalCountryNameFilterList = this.geographicalCountryNameMasterList.sort((a, b) => a.geographicalCountryName.localeCompare(b.geographicalCountryName));
    this.applicationInsightService.logEvent("GeographicalCountry filter GeoCountryName Ended");
  }
  filterItemGCNumeric(filterKey: string) {
    this.applicationInsightService.logEvent("GeographicalCountry filter IsoCode Started");
    if (filterKey != null && filterKey != "") {
      this.geographicalCountryNumericFilterList = this.geographicalCountryNumericMasterList.filter(x => x.geographicalCountryNumericISOCode.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
      this.geographicalCountryNumericFilterList = this.geographicalCountryNumericFilterList.sort((a, b) => a.geographicalCountryNumericISOCode.localeCompare(b.geographicalCountryNumericISOCode));
    } else
      this.geographicalCountryNumericFilterList = this.geographicalCountryNumericMasterList.sort((a, b) => a.geographicalCountryNumericISOCode.localeCompare(b.geographicalCountryNumericISOCode));
    this.applicationInsightService.logEvent("GeographicalCountry filter IsoCode Ended");
  }
  filterItemGCAlpha2(filterKey: string) {
    this.applicationInsightService.logEvent("GeographicalCountry filter Alpha2code Started");
    if (filterKey != null && filterKey != "") {
      this.geographicalCountryAlpha2ISOCodeFilterList = this.geographicalCountryAlpha2ISOCodeMasterList.filter(x => x.geographicalCountryAlpha2ISOCode.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
      this.geographicalCountryAlpha2ISOCodeFilterList = this.geographicalCountryAlpha2ISOCodeFilterList.sort((a, b) => a.geographicalCountryAlpha2ISOCode.localeCompare(b.geographicalCountryAlpha2ISOCode));
    } else
      this.geographicalCountryAlpha2ISOCodeFilterList = this.geographicalCountryAlpha2ISOCodeMasterList.sort((a, b) => a.geographicalCountryAlpha2ISOCode.localeCompare(b.geographicalCountryAlpha2ISOCode));
    this.applicationInsightService.logEvent("GeographicalCountry filter Alpha2code Ended");
  }
  filterItemGCAlpha3(filterKey: string) {
    this.applicationInsightService.logEvent("GeographicalCountry filter Alpha3code Started");
    if (filterKey != null && filterKey != "") {
      this.geographicalCountryAlpha3ISOCodeFilterList = this.geographicalCountryAlpha3ISOCodeMasterList.filter(x => x.geographicalCountryAlpha3ISOCode.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
      this.geographicalCountryAlpha3ISOCodeFilterList = this.geographicalCountryAlpha3ISOCodeFilterList.sort((a, b) => a.geographicalCountryAlpha3ISOCode.localeCompare(b.geographicalCountryAlpha3ISOCode));
    } else
      this.geographicalCountryAlpha3ISOCodeFilterList = this.geographicalCountryAlpha3ISOCodeMasterList.sort((a, b) => a.geographicalCountryAlpha3ISOCode.localeCompare(b.geographicalCountryAlpha3ISOCode));
    this.applicationInsightService.logEvent("GeographicalCountry filter Alpha3code Ended");
  }
  onSearchClear() {
    this.applicationInsightService.logEvent("GeographicalCountry ClearSearch Started");
    this.searchform.controls['geographicalCountryName'].setValue('');
    this.searchform.controls['geographicalCountryAlpha3ISOCode'].setValue('');
    this.searchform.controls['geographicalCountryAlpha2ISOCode'].setValue('');
    this.searchform.controls['geographicalCountryNumericISOCode'].setValue('');
    this.searchform.controls['hMCountry'].setValue(false);
    this.applicationInsightService.logEvent("GeographicalCountry ClearSearch Ended");
  }

  searchform: FormGroup = new FormGroup({
    geographicalCountryName: new FormControl(''),
    geographicalCountryAlpha3ISOCode: new FormControl(''),
    geographicalCountryAlpha2ISOCode: new FormControl(''),
    geographicalCountryNumericISOCode: new FormControl(''),
    hMCountry: new FormControl(''),
    status: new FormControl('')
  })
}
