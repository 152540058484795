import { Component, OnInit, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DivisionService } from '../../../../services/organization/division/division.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { disvisonviewComponent } from './divisonview.component';
import { ModulePermission } from '../../../../models/enum';
export class getdivisionAll {
  divisionId: any;
  corporateBrandId: any;
  corporateBrandName: any;
  customerGroupId: any;
  customerGroupName: any;
  divisionCode: any;
  divisionName: any;
  divisionSortOrder: any;
  active: any;
  draft: any;
  lastUpdatedTime: any;
}
@Component({
  selector: 'app-divisionlist',
  templateUrl: './divisionlist.component.html',
  styleUrls: ['./divisionlist.component.css']
})
export class DivisionlistComponent implements OnInit {
  @Input() divisionSearch: any;
  @Output() childButtonEvent = new EventEmitter();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<getdivisionAll>;
  corporateBrandName: any = '';
  divisionCode: any = "";
  divisionName: any = "";
  active: any;
  draft: any;
  status: any = "";
  data: any = "";
  // MatPaginator Inputs
  pageNumber: any = 1;
  length: any;
  pageIndex: any;
  pageSize: any = 15;
  sortByColumnName: any = "divisionSortOrder";
  sortByOrder: any = "asc";
  pageEvent: PageEvent;
  Serviceactive: any = true;
  ServiceDraft: any = false;
  divisionList: any;
  errorMsg: any;
  customDateTime: string;
  subscription: Subscription;
  emitter: number = 1;
  userrole: boolean;
  users: any;
  sortClick:boolean=false;
  getpagesizeoptions(): number[] {
    if (this.length > 25)
      return [10, 20, 25, this.length];
    else if (this.length > 20)
      return [10, 20, this.length];
    else if (this.length > 10)
      return [10, this.length];
    return null;
  }
  public dataSource: MatTableDataSource<getdivisionAll>;
  displayedColumns = [
    'corporateBrandName',
    'customerGroupName',
    'divisionCode',
    'divisionName',
    "divisionSortOrder",
    'lastUpdatedTime',
    'Actions'
  ];
  matDialogRef: MatDialogRef<disvisonviewComponent>;

  constructor(
    public _division: DivisionService,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    private applicationInsightService: ApplicationInsightService,
    public toastr: ToastrService
  ) { this.users = JSON.parse(localStorage.getItem("users") || "[]") }
  ngOnInit() {
    this.userrole = this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this.subscription = this._division.refreshTabledivision$.subscribe(
      refreshTabledivision => {
        this.emitter = 1;
        this.BindData();
      });
  }
  public ngOnDestroy(): void {
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }
  ngOnChanges(changes: SimpleChanges) {
    this.pageNumber = 1;
    this.BindData();
  }
  sortData(sort: Sort) {
    this.applicationInsightService.logEvent("Sorting started");
    this.sortByColumnName = sort.active;
    this.sortByOrder = sort.direction;
    this.sortClick =true;
    this.BindData();
    this.applicationInsightService.logEvent("Sorting ended");
  }
  public GetdivisionDatawithfilters(event?: PageEvent) {
    this.pageSize = event.pageSize;
    this.sortClick =true;
    this.BindData();
    return event;
  }
  BindData() {
    this.applicationInsightService.logEvent("Bind Division started");
    let newdivisionList: any[] = [];
    let filterData: any[] = [];
    if (this.divisionSearch != null) {
      if (this.divisionSearch.status == "active") { this.Serviceactive = true; this.ServiceDraft = false; }
      if (this.divisionSearch.status == "inactive") { this.Serviceactive = false; this.ServiceDraft = false; }
      if (this.divisionSearch.status == "draft") { this.ServiceDraft = true; this.Serviceactive = ""; }
      if (this.divisionSearch.status == "all") { this.ServiceDraft = ""; this.Serviceactive = ""; }
    }
    var hasNumber = /\d/;
    // below code is for number plus string search
    if(!this.sortClick){
    if (isNaN(this.divisionSearch?.corporateBrandName)) {
      if(hasNumber.test(this.divisionSearch?.corporateBrandName)){
        this.divisionSearch.corporateBrandCodeName = this.divisionSearch.corporateBrandName;
        this.divisionSearch.corporateBrandCode = '';
        this.divisionSearch.corporateBrandName = '';
      }else{
      this.divisionSearch.corporateBrandName = this.divisionSearch.corporateBrandName;
      this.divisionSearch.corporateBrandCode = '';
      this.divisionSearch.corporateBrandCodeName = '';
      }
    } else {
      this.divisionSearch.corporateBrandCode = this.divisionSearch.corporateBrandName;
      this.divisionSearch.corporateBrandName = '';
      this.divisionSearch.corporateBrandCodeName = '';
    }

    if (isNaN(this.divisionSearch?.customerGroupName)) {
      if (hasNumber.test(this.divisionSearch?.customerGroupName)) {
        this.divisionSearch.customerGroupCodeName = this.divisionSearch.customerGroupName;
        this.divisionSearch.customerGroupCode = '';
        this.divisionSearch.customerGroupName = '';
      } else {
        this.divisionSearch.customerGroupName = this.divisionSearch.customerGroupName;
        this.divisionSearch.customerGroupCode = '';
        this.divisionSearch.customerGroupCodeName = '';
      }
    } else {
      this.divisionSearch.customerGroupCode = this.divisionSearch.customerGroupName;
      this.divisionSearch.customerGroupName = '';
      this.divisionSearch.customerGroupCodeName = '';
    }

    if (isNaN(this.divisionSearch?.divisionName)) {
      if (hasNumber.test(this.divisionSearch?.divisionName)) {
        this.divisionSearch.divisionCodeName = this.divisionSearch.divisionName;
        this.divisionSearch.divisionCode = '';
        this.divisionSearch.divisionName = '';
      } else {
        this.divisionSearch.divisionName = this.divisionSearch.divisionName;
        this.divisionSearch.divisionCode = '';
        this.divisionSearch.divisionCodeName = ''
      }
    } else {
      this.divisionSearch.divisionCode = this.divisionSearch.divisionName;
      this.divisionSearch.divisionName = '';
      this.divisionSearch.divisionCodeName = ''
    }
  }
    this._division.getDivisionAll().subscribe((data: any) => {
      this.applicationInsightService.logEvent("CustomerGroup listing and filter started");
      this.divisionList = newdivisionList = data.division;
      if (newdivisionList != null) {
        filterData = newdivisionList
          .filter(d => d.corporateBrandName.toString().toLowerCase().includes(this.divisionSearch.corporateBrandName.toString().toLowerCase()))
          .filter(d => d.corporateBrandCodeName.toString().includes(this.divisionSearch.corporateBrandCodeName.toString()))
          .filter(c => c.corporateBrandCode.includes(this.divisionSearch.corporateBrandCode))
          .filter(d => d.divisionCode.toString().includes(this.divisionSearch.divisionCode == null ? "" : this.divisionSearch.divisionCode.toString()))
          .filter(d => d.divisionName.toString().toLowerCase().includes(this.divisionSearch.divisionName.toString().toLowerCase()))
          .filter(d => d.divisionCodeName.toString().includes(this.divisionSearch.divisionCodeName.toString()))
          .filter(d => d.customerGroupCode == null ? "null" : d.customerGroupCode.toString().includes(this.divisionSearch.customerGroupCode.toString()))
          .filter(d => d.customerGroupName == null ? "null" : d.customerGroupName.toString().toLowerCase().includes(this.divisionSearch.customerGroupName.toString().toLowerCase()))
          .filter(d => d.customerGroupCodeName.toString().includes(this.divisionSearch.customerGroupCodeName.toString()))
          .filter(d => d.active.toString().includes(this.Serviceactive.toString()))
          .filter(d => d.draft.toString().includes(this.ServiceDraft.toString()));
        if (this.divisionSearch.customerGroupName != undefined && this.divisionSearch.customerGroupName != "" && this.divisionSearch.customerGroupName != null) {
          filterData = filterData.filter(item => item.customerGroupName !== null);
        }
        this.dataSource = new MatTableDataSource(filterData);
        this.sort.active = this.sortByColumnName;
        this.sort.direction = this.sortByOrder;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.length = this.dataSource.data.length;
        this.sortClick = false;
        if (this.emitter == 1) { this.emitter = 2; this.childButtonEvent.emit(data.division); }
      }
      this.applicationInsightService.logEvent("CustomerGroup listing and filter ended");
    },
      error => { this.errorMsg = error; });
    this.applicationInsightService.logEvent("Bind Division ended");
  }
  //While clicking Edit button load respective data to entry page
  updateRecord(updateRowData: any) {
    this._division.getdivisionIDFromList(updateRowData.divisionId);
  }
  openDialog(row: any) {
    this.matDialogRef = this.dialog.open(disvisonviewComponent, {
      data: { rowData: row },
      disableClose: false
    });
    this.matDialogRef.afterClosed().subscribe(res => {
      if ((res == true)) {
        row = "";
      }
    });
  }
}

