
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, Subject } from 'rxjs';
import { ServiceApi } from '../../../services/ServiceApi';
let headers = new HttpHeaders()
  if (!headers.has('content-type'))
  {
      headers=headers.append('content-type','application/json')
  }
  @Injectable({
  providedIn: 'root'
  })
 
export class EarlierplanningService {

  constructor(
    private http: HttpClient,
    public _serviceApi: ServiceApi
  ) { }

  headers = new HttpHeaders().set('content-type','application/json').set('accept','application/json');
  httpOptions ={
      headers:this.headers
  }
   
  //Observable string sources
    public earlierplanningServiceIdSource = new Subject<string>();
    public earlierplanningServiceDepartmentSource = new Subject<string>();
    public earlierplanningDepIsappearSource = new Subject<string>();
   
  //Observable string streams
    public earlierPlanningId$ = this.earlierplanningServiceIdSource.asObservable();
    getEarlierPlanningIdFromList(mission: string)
    {
      this.earlierplanningServiceIdSource.next(mission);
    }
    public earlierplanningServiceDepartment$ = this.earlierplanningServiceDepartmentSource.asObservable();
    getEarlierPlanningDepartmentFromList(mission: any)
    {
      this.earlierplanningServiceDepartmentSource.next(mission);
    }
    public earlierisAppear$ = this.earlierplanningDepIsappearSource.asObservable();
    getDepartmentisAppear(mission: string)
    {
      this.earlierplanningDepIsappearSource.next(mission);
    }
  //Observable string sources
    public refreshTable = new Subject<string>();
  //Observable string streams
    public refreshTableEarlierPlanning$ = this.refreshTable.asObservable();
  // Service message commands
  refreshEarlierPlanningList()
  {
    this.refreshTable.next();
  }
  getSeasonByRange(prec: any): Observable<any>  {
    var serviceURL = this._serviceApi.DepartmentGetSeasonByRange + "?current=current&prec=" + prec + "&succ=4";
    return this.http.get(serviceURL);
  }
  //List EarlierPlanning from Service
 
  getEarlierPlanningAll(searchParams: any): Observable<any> {
    return this.http.post(this._serviceApi.EarlierPlanningGetAll, JSON.stringify(searchParams), { 'headers': headers });
    //return this.http.get("assets/Json/response_Earlier.json"); 
  }

  getDepartments(Id: number): Observable<any> {
   return this.http.get<any>(this._serviceApi.GarmentGroupGetDepById + Id);
  }
  getBOCOSDepartments(season: string): Observable<any> {
    return this.http.get<any>(this._serviceApi.GetdepartmentAllBocos + season);
   }
  getChannels(): Observable<any> {
    return this.http.get<any>(this._serviceApi.ChannelGetAll);
  }
 
  //Create EarlierPlanning 
  createEarlierPlanning(createnew: any): Observable<any>
  {
    var body = (JSON.stringify(createnew));
    return this.http.post(this._serviceApi.EarlierPlanningCreate, body, { 'headers': headers });
  }
  getEarlierPlanningDepartmentsAndSections(getdepparams: any): Observable<any> 
  {
    //return this.http.get("assets/Json/responseDepartmentSeason.json");  
    getdepparams.channelId=1;
    return this.http.post(this._serviceApi.EarlierPlanningGetEarlierPlanningDepartmentsAndSections, JSON.stringify(getdepparams), { 'headers': headers });
  }
 //Get EarlierPlanning By Id
  getEarlierPlanningById(earlierPlanningId:number): Observable<any> {
    return this.http.get(this._serviceApi.EarlierPlanningGetById + earlierPlanningId)
  }
 updateEarlierPlanning(updatedData: any): Observable<any> {
  var body = (JSON.stringify(updatedData));
  return this.http.put(this._serviceApi.EarlierPlanningUpdate, body, { 'headers': headers });
}
 //Get token from service Head.
  getToken()
  {
    return localStorage.getItem('token')
  }
}
