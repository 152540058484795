<div class="data-table" class="org-table">
  <table id="tblEarlierPlanning" [dataSource]="dataSource" mat-table class="full-width-table" matSort
    (matSortChange)="sortData($event)" matSortActive="sectionName" matSortStart="desc" aria-label="Elements">
    <ng-container matColumnDef="seasonNumber">
      <th matSort (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header
        id="seasonNumber">Season</th>
      <td mat-cell *matCellDef="let row"
        [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.seasonNumber | seasonDate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="channelName">
      <th matSort (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header id="channelName">
        Channel</th>
      <td mat-cell *matCellDef="let row" id="custom-col150"
        [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.channelName }}<span *ngIf="!row.isdisableCB"></span></td>
    </ng-container>
    <ng-container matColumnDef="corporateBrandName">
      <th matSort (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header
        id="corporateBrandName">Corporate Brand</th>
      <td mat-cell *matCellDef="let row" id="custom-col200"
        [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.corporateBrandName}}
      </td>
    </ng-container>
    <ng-container matColumnDef="sectionName">
      <th matSort (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header id="sectionName">
        Section</th>
      <td mat-cell *matCellDef="let row" id="custom-col200" [matTooltipDisabled]="row.sectionName.length<25"
        matTooltip={{row.sectionName}} matTooltipPosition="left" matTooltipClass="tooltip"
        [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.sectionName | slice:0:25 }}<span *ngIf="row.sectionName.length>25">...</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="departmentName">
      <th matSort (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header
        id="departmentName">Departments</th>
      <td mat-cell *matCellDef="let row" id="custom-col300"
        [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        <a (click)="OpenModal(row)" class="org-openmodel">{{row.departmentName | slice:0:30}}<span
            *ngIf="row.departmentName.length>30">...</span></a>
      </td>
    </ng-container>
    <ng-container matColumnDef="ISW">
      <th matSort (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header id="isw">ISW</th>
      <td mat-cell *matCellDef="let row" id="custom-col50"
        [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{ row.weekNumber }}
      </td>
    </ng-container>
    <ng-container matColumnDef="lastUpdatedTime">
      <th mat-header-cell matSort (matSortChange)="sortData($event)" *matHeaderCellDef mat-sort-header
        id="lastUpdatedTime">Updated Date</th>
      <td mat-cell *matCellDef="let row" id="custom-col150"
        [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        <span *ngIf="!customDateTime">{{ row.lastUpdatedTime | customDateTime }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef id="Actions"></th>
      <td mat-cell *matCellDef="let row" id="custom-col50"
        [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        <a id="btnEdit" color="primary" (click)="updateRecord(row)" matTooltip="Edit" matTooltipPosition="right"
          class="org-edit">Edit</a>
      </td>
    </ng-container>
   <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell aligncenter" colspan="8">No records found</td>
    </tr>
  </table>
  <div id="main-wrap">
    <section id="sidebar">
      <div class="example-button-row">
        <div class="example-flex-container indicator">
          <button mat-icon-button aria-label="Datatable color code">
            <mat-icon><span class="material-icons activeindicator">
                stop
              </span></mat-icon>
            Active
          </button>
          <button mat-icon-button aria-label="Datatable color code">
            <mat-icon><span class="material-icons inactiveindicator">
                stop
              </span></mat-icon>
            Inactive
          </button>
          <button mat-icon-button aria-label="Datatable color code">
            <mat-icon><span class="material-icons draftindicator">
                stop
              </span></mat-icon>
            Draft
          </button>
          <button mat-icon-button aria-label="Datatable color code">
            <mat-icon><span class="material-icons firstdraftindicator">
                stop
              </span></mat-icon>
            First Draft
          </button>
        </div>
      </div>
    </section>
    <mat-paginator #paginator id="tblPaginator" [(length)]="length" [(pageSize)]="pageSize"
      [pageSizeOptions]="getpagesizeoptions()" (page)="pageEvent = GetEarlierPlanningDatawithfilters($event)"
      showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
