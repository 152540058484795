
   <mat-form-field [formGroup]="_vatExForm" floatLabel='always' appearance='outline' class="full-width cust ml-2" >
    <mat-label>Product Group and Type</mat-label>
    <div style="position: absolute;">{{getSelectedItems() | slice:0:25}}</div>
    
   <mat-select autoActiveFirstOption formControlName="productTypeGroupIds">
   <mat-checkbox class="checklist-leaf-node custom-All" formControlName="allPTG" (change)="checkAll()" style="margin: 21px;">All</mat-checkbox>
      <mat-option  [ngStyle]="{display :'none'}"></mat-option>
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                <button mat-icon-button disabled></button>
                <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
                    (change)="todoLeafItemSelectionToggle(node)">{{node.item.split(',')[0]}}</mat-checkbox>
            </mat-tree-node>

            <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                <button mat-icon-button matTreeNodeToggle
    [attr.aria-label]="'toggle ' + node.filename">
<mat-icon class="mat-icon-rtl-mirror">
{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
</mat-icon>
</button>
                <mat-checkbox [checked]="descendantsAllSelected(node)"
                    [indeterminate]="descendantsPartiallySelected(node)"
                    (change)="todoItemSelectionToggle(node)">
                    {{node.item.split(',')[0]}}</mat-checkbox>
            </mat-tree-node>
        </mat-tree>
    
    </mat-select>
</mat-form-field>


<style>
    ::ng-deep .mat-option:first-child .mat-pseudo-checkbox{ display: none; }
    ::ng-deep .mat-form-field-outline .ng-tns-c47-41 .ng-star-inserted {height:10px !important;}
  </style>
