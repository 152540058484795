import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonUtilityService } from 'src/app/services/organization/commonutility/commonutility.service';
import { DepartmentService } from 'src/app/services/organization/department/department.service';
import { DepartmentSubIndexService } from 'src/app/services/organization/department/subindex.service';
import { SubindexService } from 'src/app/services/organization/subindex/subindex.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { DialogBoxService } from 'src/app/shared/services/dialog-box.service';
import { AccountService } from '../../../../../shared/services/account.service'
@Component({
  selector: 'department-subindexform',
  templateUrl: './subindexform.component.html',
  styleUrls: ['./subindexform.component.css']
})

export class DepartmentsubindexformComponent implements OnInit {

  @Output() subindexcall = new EventEmitter();
  @Input() subIndexSeasonlist;
  @Input() seasonList;
  @Input() departmentDraft;

  startSeasonMasterlist: any[] = [];
  startSeasonFilterList: any[] = [];

  endSeasonMasterlist: any[] = [];
  endSeasonFilterList: any[] = [];

  subIndexCodeFilterList: any[] = [];
  subIndexCodeMasterList: any[] = [];

  updateDepSubIndexForm: any;
  isEditDepSubIndex = false;
  isDeleteDepSubIndex = false;
  validationMessages: any;
  depSubIndexForm = false;
  validOptions;
  userrole: boolean;
  
  @Input() parentDepartmentId;
  @Input() parentCorporateBrandName: string;
  @Input() depSubIndexList;
  @Input() subIndexList;
  dptSubIndexId: any;
  subscription: Subscription;
  constructor(public _departmentSubIndexService: DepartmentSubIndexService,
    public _departmentService: DepartmentService,
    public _commonutilityService: CommonUtilityService,
    public _subIndexService: SubindexService,
    public _dialogService: DialogBoxService,
    private toastr: ToastrService,
    public account: AccountService,
    private applicationInsightService:ApplicationInsightService) { }

  ngOnInit(): void {
    this.applicationInsightService.logEvent("Department subindexform page Initialization started");
    this.account.getUserInfo();
    let list = this.account.userInfo.Role;
    let rolelist = list.split(",");
    this.userrole = rolelist.length <= 1 ? rolelist[0] == 'sds.Reader' ? true : false : false;
    if (rolelist.length > 1) {
      for (let i = 0; i < rolelist.length; i++) {
        if (rolelist[i] != 'sds.Reader') {
          this.userrole = false;
        }
      }
    }
    this.dptSubIndexForm.controls.lastUpdatedBy.setValue(this.account.userInfo.Email);
    this.updateDepSubIndexForm = Object.assign({});
    this.validOptions = this.fnGetRangeValues();
    this.subscription = this._departmentSubIndexService.departmentSubIndexInfoId$.subscribe(
      departmentSubIndexInfoId => {
        this.fnUpdateDepartmentSubIndex(departmentSubIndexInfoId)
      });
    this.validationMessages = this._commonutilityService.validationMsgs;
    this.applicationInsightService.logEvent("Department subindexform page Initialization ended");
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }
  ngOnChanges(changes: SimpleChanges) {
    this.applicationInsightService.logEvent("Department subindexform page Onchange started");
    this.startSeasonMasterlist = this.startSeasonFilterList = this.endSeasonMasterlist = this.endSeasonFilterList = this.subIndexSeasonlist;
    this.validOptions = this.fnGetRangeValues();
    this.dptSubIndexForm.dirty==false;
    this.depSubIndexForm = false;
    if (this.parentDepartmentId != undefined && this.parentDepartmentId != 0 && this.parentDepartmentId != null)
      this.depSubIndexForm = true;
      /* istanbul ignore if */
    if (this.subIndexList != undefined && this.subIndexList != null && this.subIndexList.length > 0) {
      if (this.parentCorporateBrandName != undefined)
        this.subIndexCodeFilterList = this.subIndexCodeMasterList = this.subIndexList.filter(x => { return (x['active'] === true && (x['draft'] === false && x['corporateBrandCodeName'] != null && x['corporateBrandCodeName'] != "") ? x['corporateBrandCodeName'].toString().trim().trim().toUpperCase() == this.parentCorporateBrandName.toString().toUpperCase() : "") })
      else
        this.subIndexCodeFilterList = this.subIndexCodeMasterList = this.subIndexList;
    }
    this.subIndexCodeFilterList = this.subIndexCodeFilterList.sort((a, b) => (a.subIndexCode > b.subIndexCode ? 1 : -1));
    this.onClear()
    this.applicationInsightService.logEvent("Department subindexform page Onchange ended");
  }

  displayTextSeason(season: string) {  
    if (season == null || season == "" || season.includes("99"))
      return null;
    else if (this.startSeasonMasterlist != undefined && season != null)
      return this.startSeasonMasterlist.find(x => x.seasonNumber === season).displayName;
  }

  filterStartSeason(filterKeySS: string) {
    this.applicationInsightService.logEvent("Department subindexform page filterStartSeason started");
    if (filterKeySS != null && filterKeySS != "") {
      this.startSeasonFilterList = this.startSeasonMasterlist.filter(rs => rs.displayName.toLowerCase().includes(filterKeySS.toString().toLowerCase()))
      this.startSeasonFilterList = this.startSeasonFilterList?.sort((a, b) => (a?.seasonNumber < b?.seasonNumber ? 1 : -1));
    } else {
      this.startSeasonFilterList = this.startSeasonMasterlist;
      this.startSeasonFilterList = this.startSeasonFilterList?.sort((a, b) => (a?.seasonNumber < b?.seasonNumber ? 1 : -1));
    }
    this.applicationInsightService.logEvent("Department subindexform page filterStartSeason ended");
  }

  filterEndSeason(filterKeyES: string) {
    this.applicationInsightService.logEvent("Department subindexform page filterEndSeason started");
    if (filterKeyES != null && filterKeyES != "") {
      this.endSeasonFilterList = this.endSeasonMasterlist.filter(rs => rs.displayName.toLowerCase().includes(filterKeyES.toString().toLowerCase()))
      this.endSeasonFilterList = this.endSeasonFilterList?.sort((a, b) => (a?.seasonNumber < b?.seasonNumber ? 1 : -1));
    } else {
      this.endSeasonFilterList = this.endSeasonMasterlist;
      this.endSeasonFilterList = this.endSeasonFilterList?.sort((a, b) => (a?.seasonNumber < b?.seasonNumber ? 1 : -1));
    }
      this.applicationInsightService.logEvent("Department subindexform page filterEndSeason ended");
  }
/* istanbul ignore next */
  displayTextSubIndex(subIndex: string) {
    if (subIndex == null || subIndex == "")
      return null;
    else if (this.subIndexCodeMasterList != undefined && subIndex != null)
      return this.subIndexCodeMasterList.find(x => x.subIndexId === subIndex).subIndexCode;
  }

  filterItemSubIndex(filterKeySI: string) {
    this.applicationInsightService.logEvent("Department subindexform page filterItemSubIndex started");
    if (filterKeySI != null && filterKeySI != "") {
      this.subIndexCodeFilterList = this.subIndexCodeMasterList.filter(s => s.subIndexCode.toLowerCase().includes(filterKeySI.toString().toLowerCase()))
    }
    else {
      this.subIndexCodeFilterList = this.subIndexCodeMasterList;
    }
    this.subIndexCodeFilterList = this.subIndexCodeFilterList.sort((a, b) => (a.subIndexCode > b.subIndexCode ? 1 : -1));
    this.applicationInsightService.logEvent("Department subindexform page filterItemSubIndex ended");
  }

  onSubmit() {
    this.applicationInsightService.logEvent("Department subindexform page submit started");
    console.log("DEP Sub Index BEFORE:" + JSON.stringify(this.dptSubIndexForm.value))
    this.dptSubIndexForm.controls.departmentId.setValue(this.parentDepartmentId);
    if (this.dptSubIndexForm.controls.endSeason.value == "")
      this.dptSubIndexForm.controls.endSeason.setValue(null);
    this.dptSubIndexForm.controls.startSeason.setValidators([this.acStartSeasonValidator("Save"), Validators.required]);
    this.dptSubIndexForm.controls.subIndexId.updateValueAndValidity();
    this.dptSubIndexForm.controls.startSeason.updateValueAndValidity();
    this.dptSubIndexForm.controls.endSeason.updateValueAndValidity();
    this.dptSubIndexForm.controls.lastUpdatedBy.setValue(this.account.userInfo.Email);
    this.dptSubIndexForm.controls.departmentDraft.setValue(this.departmentDraft);
    console.log("DEP Sub Index Save: " + JSON.stringify(this.dptSubIndexForm.value))
      /* istanbul ignore if */
    if (this.dptSubIndexForm.valid) {
      console.log("DEP Sub Index After:" + JSON.stringify(this.dptSubIndexForm.value));
      if (this.dptSubIndexForm.value.departmentSubIndexInfoId == undefined || this.dptSubIndexForm.value.departmentSubIndexInfoId == 0 || this.dptSubIndexForm.value.departmentSubIndexInfoId == null) {
        this.dptSubIndexForm.controls.departmentSubIndexInfoId.setValue(0);
        this._departmentSubIndexService.updateDepartmentSubIndex(this.dptSubIndexForm.value).subscribe
          (data => {
            this.subindexcall.emit();
            this.toastr.success(data.message, "", { timeOut: 10000 });
            this.onClear();
            this._departmentService.refreshDepartmentList();
          },
            error => { this.toastr.error(error, "", { timeOut: 10000 }) })
      }
      else {
        this._departmentSubIndexService.updateDepartmentSubIndex(this.dptSubIndexForm.value).subscribe
          (data => {
            this.subindexcall.emit();
            this.toastr.success(data.message, "", { timeOut: 10000 });
            this.onClear();
            this._departmentService.refreshDepartmentList();
          },
            error => { this.toastr.error(error, "", { timeOut: 10000 }) })
      }
    }
    this.applicationInsightService.logEvent("Department subindexform page submit ended");
  }

  fnUpdateDepartmentSubIndex(departmentSubIndexInfoId: any) {
    this.applicationInsightService.logEvent("UpdateDepartmentSubIndex started");
    this.fnSeasonControlClear();
    console.log("Dep Sub Index ID: " + departmentSubIndexInfoId);
    this.dptSubIndexForm.controls.departmentSubIndexInfoId.setValue(departmentSubIndexInfoId);
    this.dptSubIndexId = departmentSubIndexInfoId;
    this.isEditDepSubIndex = true;
      /* istanbul ignore next */
    this._departmentSubIndexService.getDepartmentSubIndexById(departmentSubIndexInfoId).subscribe((data: any) => {
      this.updateDepSubIndexForm = data.departmentSubIndex;
      this._departmentService.getSeasonAll().subscribe((data: any) => {
      let foundStart = this.startSeasonMasterlist.some(el => el.seasonNumber === this.updateDepSubIndexForm.startSeason);
        if (!foundStart)
          this.startSeasonFilterList = this.startSeasonMasterlist = data.seasonByRange.filter(x => x.seasonNumber >= this.updateDepSubIndexForm.startSeason);
        this.dptSubIndexForm.controls.startSeason.setValue(this.updateDepSubIndexForm.startSeason);
        this.endSeasonFilterList = data.seasonByRange.filter(x => x.seasonNumber >= this.updateDepSubIndexForm.endSeason);
        this.dptSubIndexForm.controls.endSeason.setValue(this.updateDepSubIndexForm.endSeason);
        let foundEnd = this.startSeasonMasterlist.some(el => el.seasonNumber === this.updateDepSubIndexForm.startSeason);
        if (this.endSeasonFilterList.length == 0 || foundEnd)
          this.endSeasonFilterList = this.endSeasonMasterlist;
        else
          this.endSeasonFilterList = this.endSeasonMasterlist = data.seasonByRange.filter(x => x.seasonNumber >= this.updateDepSubIndexForm.endSeason);

      });
      this.dptSubIndexForm.controls.startSeason.setValidators([this.acStartSeasonValidator('Edit'), Validators.required]);
      this.isDeleteDepSubIndex = false;
      if (new Date(this.updateDepSubIndexForm.startSeason.substring(0, 4) + "-" + this.updateDepSubIndexForm.startSeason.slice(-2) + "-01") >= new Date(new Date().getFullYear() + "-" + new Date().getMonth() + "-01")) { this.isDeleteDepSubIndex = true; }
      else if (this.updateDepSubIndexForm.endSeason == null || this.updateDepSubIndexForm.endSeason == '') { this.isDeleteDepSubIndex = true; }
      else if ((new Date(this.updateDepSubIndexForm.startSeason.substring(0, 4) + "-" + this.updateDepSubIndexForm.startSeason.slice(-2) + "-01")) <= new Date() && this.updateDepSubIndexForm.endSeason != null && (new Date(this.updateDepSubIndexForm.endSeason.substring(0, 4) + "-" + this.updateDepSubIndexForm.endSeason.slice(-2) + "-01")) >= new Date()) { this.isDeleteDepSubIndex = true; }
      this.fnGetRangeValues();
    }),
      error => { this.toastr.error(error, "", { timeOut: 10000 }); };
      this.applicationInsightService.logEvent("UpdateDepartmentSubIndex ended");
  }

  deleteDepSubIndex() {
    this.applicationInsightService.logEvent("DeleteDepSubIndex started");
      this.dptSubIndexForm.controls.departmentId.setValue(this.parentDepartmentId);
      this.dptSubIndexForm.controls.departmentDraft.setValue(this.departmentDraft);
    console.log(JSON.stringify(this.dptSubIndexForm.value));
    this._dialogService.openConfirmDialog('Are you sure you want to delete this record?')
      .afterClosed().subscribe(res => {
        /* istanbul ignore if */
        if (res) {
          this._departmentSubIndexService.DeleteDepartmentSubIndex(this.dptSubIndexForm.value).subscribe
            (data => {
              this.subindexcall.emit();
              this.toastr.success(data.message, "", { timeOut: 10000 });
              this.onClear();
              //this._departmentSubIndexService.refreshDepartmentSubIndexList();
              this._departmentService.refreshDepartmentList();
            },
              error => { this.toastr.error(error, "", { timeOut: 10000 }) });
        }
      })
      this.applicationInsightService.logEvent("DeleteDepSubIndex ended");
  }
  onClear() {
    this.applicationInsightService.logEvent("Department subindex clear form started");
    this.isEditDepSubIndex = false;
    this.isDeleteDepSubIndex = false;
    this.fnSeasonControlClear();
    this.dptSubIndexForm.controls.subIndexId.clearValidators();
    this.dptSubIndexForm.controls.departmentSubIndexInfoId.setValue('');
    this.dptSubIndexForm.controls.departmentId.setValue('');
    this.dptSubIndexForm.controls.subIndexId.setValue('');
    this.dptSubIndexForm.controls.subIndexId.setValidators([this._commonutilityService.acObjectValidator(), Validators.required]);
    this.applicationInsightService.logEvent("Department subindex clear form ended");
  }

  fnSeasonControlClear() {
    this.applicationInsightService.logEvent("Department subindex SeasonControlClear started");
    this.dptSubIndexId = undefined;
    this.dptSubIndexForm.controls.startSeason.clearValidators();
    this.dptSubIndexForm.controls.endSeason.clearValidators();
    this.dptSubIndexForm.controls.startSeason.setValue(null);
    this.dptSubIndexForm.controls.endSeason.setValue(null);
    this.dptSubIndexForm.controls.startSeason.setValidators([this.acStartSeasonValidator("New"), Validators.required]);
    this.dptSubIndexForm.controls.endSeason.setValidators([this.acEndSeasonValidator()]);
    this.applicationInsightService.logEvent("Department subindex SeasonControlClear ended");
  }

  dptSubIndexForm: FormGroup = new FormGroup({
    departmentSubIndexInfoId: new FormControl(''),
    departmentId: new FormControl(''),
    subIndexId: new FormControl('', [this._commonutilityService.acObjectValidator(), Validators.required]),
    startSeason: new FormControl(null, [this.acStartSeasonValidator("New"), Validators.required]),
    endSeason: new FormControl(null, [this.acEndSeasonValidator()]),
    lastUpdatedBy: new FormControl(''),
    departmentDraft: new FormControl(''),
  })

  fnGetRangeValues() {
    let validOptions: any[] = [];
    if (this.seasonList != undefined && this.seasonList != null) {
      this.seasonList.forEach(x => {
        if (x.seasonNumber != null) {
          validOptions.push(x.seasonNumber.toString());
        }
      })
    }
    return (this.seasonList != undefined && this.seasonList != null) ? validOptions : undefined;
  }

  acStartSeasonValidator(mode: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        /* istanbul ignore if */
      if (control.value != null && control.value != ""
        && control.value != undefined && this.validOptions != null
        && this.validOptions != undefined && this.validOptions.indexOf(control.value.toString()) !== -1) {
        if (this.startSeasonOverLap(control.value, mode) == true) {
          return { 'subIndexOverLappingSeason': { value: control.value } };
        }
        if (this.getMaxStartSeason() != null && mode == "Save" && this.getMaxStartSeason() >= control.value && (this.dptSubIndexForm.controls.endSeason.value == null || this.dptSubIndexForm.controls.endSeason.value == undefined)) {
          return { 'subIndexOverLappingSeason': { value: control.value } };
        }
        if (this.dptSubIndexForm.controls.endSeason.value != null && this.dptSubIndexForm.controls.endSeason.value != undefined && this.dptSubIndexForm.controls.endSeason.value != "") {
          if (control.value > this.dptSubIndexForm.controls.endSeason.value) { return { 'invalidStartSeason': { value: control.value } }; }
        }
        return null  /* valid option selected */
      }
      else
        return (control.value != null) ? { 'invalidAutocomplete': { value: control.value } } : null;
    }
  }

  acEndSeasonValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value != null && control.value != ""
        && control.value != undefined && this.validOptions != null
        && this.validOptions != undefined && this.validOptions.indexOf(control.value.toString()) !== -1) {
        if (this.endSeasonOverLap(control.value) == true) {
          return { 'subIndexOverLappingSeason': { value: control.value } };
        }
        if (this.getMaxEndSeason() != null && this.getMaxEndSeason() >= this.dptSubIndexForm.controls.startSeason.value && this.getMaxEndSeason() <= control.value) {
          return { 'subIndexOverLappingSeason': { value: control.value } };
        }
        if (this.dptSubIndexForm.controls.startSeason.value != null && this.dptSubIndexForm.controls.startSeason.value != undefined && this.dptSubIndexForm.controls.startSeason.value != "") {
          if (this.dptSubIndexForm.controls.startSeason.value > control.value) { return { 'invalidEndSeason': { value: control.value } } }
        }
        return null  /* valid option selected */
      }
      else
        return (control.value != null && control.value != "" && !control.value.includes("99") ) ? { 'invalidAutocomplete': { value: control.value } } : null;
    }
  }

  startSeasonOverLap(value: number, mode: string) {
    let retutnValue = false;
    if (this.depSubIndexList != undefined && this.depSubIndexList != null && this.depSubIndexList != "") {
      if (this.depSubIndexList == undefined && this.depSubIndexList.filter(x => x.endSeason == null).length >= 1) {
        retutnValue = true;
      }
      else {
        for (let x of this.depSubIndexList.filter(x => x.departmentSubIndexInfoId != this.dptSubIndexId)) {
          if (mode == "New" || mode == "Save") {
            if (Number(x.startSeason) <= value && x.endSeason == null) { retutnValue = true; break }
            else if (Number(x.startSeason) >= value && x.endSeason == null) { retutnValue = true; break }
            else if (x.endSeason != null && Number(x.startSeason) <= value && Number(x.endSeason) >= value) { retutnValue = true; break }
          }
        }
      }
    } return retutnValue;
  }
  /* istanbul ignore next */
  endSeasonOverLap(value: number) {
    let retutnValue = false;
    let fltrData;
    if (this.depSubIndexList != undefined && this.depSubIndexList != null && this.dptSubIndexForm.controls.startSeason.value != undefined && this.dptSubIndexForm.controls.startSeason.value != null) {
      fltrData = this.depSubIndexList.filter(x => x.departmentSubIndexInfoId != this.dptSubIndexId)
      for (let x of fltrData) {
        if (Number(x.startSeason) <= value && x.endSeason == null) { retutnValue = true; break }
        else if (Number(x.startSeason) <= value && Number(x.endSeason) >= value) { retutnValue = true; break }
        else if (x.endSeason != null && Number(x.startSeason) >= this.dptSubIndexForm.controls.startSeason.value && Number(x.endSeason) <= value) { retutnValue = true; break }
      }
    } fltrData = []; return retutnValue;
  }

  getMaxEndSeason() {
    if (this.depSubIndexList != undefined && this.depSubIndexList != null && this.dptSubIndexForm.controls.startSeason.value != undefined && this.dptSubIndexForm.controls.startSeason.value != null) {
      var max;
      let fltrData = this.depSubIndexList.filter(x => x.departmentSubIndexInfoId != this.dptSubIndexId)
      for (var i = 0; i < fltrData.length; i++) {
        if (max == null || parseInt(fltrData[i]['endSeason']) > parseInt(max['endSeason']))
          max = fltrData[i];
      }
    }
    return max != null ? max.endSeason : null;
  }

  getMaxStartSeason() {
    if (this.depSubIndexList != undefined && this.depSubIndexList != null) {
      var max;
      let fltrData = this.depSubIndexList.filter(x => x.departmentSubIndexInfoId != this.dptSubIndexId)
      for (var i = 0; i < fltrData.length; i++) {
        if (max == null || parseInt(fltrData[i]['startSeason']) > parseInt(max['startSeason']))
          max = fltrData[i];
      }
    }
    return max != null ? max.startSeason : null;
  }

}

