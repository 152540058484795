import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HmcountryService } from 'src/app/services/country/hmcountry/hmcountry.service';
import { GeographicalcountryService } from 'src/app/services/country/geographicalcountry/geographicalcountry.service';
import { FiscalcountryService } from '../../../../services/country/fiscalcountry/fiscalcountry.service';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModulePermission } from 'src/app/models/enum';
import { countryPipe } from '../../../../pipes/countryPipe';
import { getAriaDescribedBy } from 'ag-grid-community/dist/lib/utils/aria';
// import { Component, Inject, Injectable } from "@angular/core";

@Component({
  selector: 'app-hmcountry-form',
  templateUrl: './hmcountry-form.component.html',
  styleUrls: ['./hmcountry-form.component.css'],
})
export class HmcountryFormComponent implements OnInit {
  ddgeographicalcountryList: any[];
  geographicalCountryMasterCGList: any[] = [];
  geographicalCountryFilterCGList: any[] = [];
  fiscalcountryList: any[];
  fiscalcountryMasterCGList: any[] = [];
  fiscalcountryFilterCGList: any[] = [];
  userrole: boolean;
  users: any;
  public isGeocountryslected: boolean = false;
  public spinner: boolean = false;
  public recivedData: any;
  public recivedBtnMsg: any;
  public hidesaveBtn: boolean = true;
  public hideGeographicalDropdown: boolean = true;
  constructor(
    private _mdr: MatDialogRef<HmcountryFormComponent>,
    private hmcountryService: HmcountryService,
    private geographicalcountryService: GeographicalcountryService,
    public fiscalcountryService: FiscalcountryService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.recivedData = data?.rowData?.data;
    this.recivedBtnMsg = data?.msg;
    this.hideShowElements(this.recivedBtnMsg);
    this.users = JSON.parse(localStorage.getItem('users') || '[]');
  }

  ngOnInit(): void {
    this.userrole = this.users.includes(ModulePermission.Country_Write)
      ? true
      : false;
    // get the geographical list
    this.hmcountryService
      .GetGeographicalCountryExceptHMCountry()
      .subscribe((data: any) => {
        this.ddgeographicalcountryList =
          this.geographicalCountryMasterCGList =
          this.geographicalCountryFilterCGList =
            data;

        this.geographicalCountryFilterCGList =
          this.geographicalCountryMasterCGList.sort((a, b) =>
            a.geographicalCountryName.localeCompare(b.geographicalCountryName)
          );
      });

    // get fisical country all
    this.fiscalcountryService.getFiscalCountryList().subscribe((data: any) => {
      this.setUpdateForm(this.recivedData);
      this.fiscalcountryList =
        this.fiscalcountryMasterCGList =
        this.fiscalcountryFilterCGList =
          data.fiscalCountry.filter((object) => {
            return object['active'] !== false;
          });
      this.fiscalcountryFilterCGList = this.fiscalcountryMasterCGList.sort(
        (a, b) =>
          a.geographicalCountryName.localeCompare(b.geographicalCountryName)
      );
    });
  }
  // get the geographical list
  displayTextGC(geographicalCountryId: string) {
    if (geographicalCountryId === null || geographicalCountryId === '')
      return null;
    else if (
      this.ddgeographicalcountryList != undefined &&
      geographicalCountryId != null
    )
      return this.ddgeographicalcountryList.find(
        (x) => x.geographicalCountryId == geographicalCountryId
      ).geographicalCountryName;
  }
  filterItemgeographicalCountry(filterKey: string) {
    let newGCList: any[] = [];
    newGCList = this.geographicalCountryMasterCGList;
    if (filterKey != null && filterKey != '') {
      this.geographicalCountryFilterCGList = newGCList.filter((cb) =>
        cb.geographicalCountryName
          .toLowerCase()
          .includes(filterKey.toString().toLowerCase())
      );
    } else
      this.geographicalCountryFilterCGList =
        this.geographicalCountryMasterCGList.sort((a, b) =>
          a.geographicalCountryName.localeCompare(b.geographicalCountryName)
        );
  }
  getGeoraphicalCountryId(Gcid) {
    this.spinner = true;
    if (Gcid) {
      this.geographicalcountryService.getCountryById(Gcid).subscribe((data) => {
        this.hmCountryForm.controls.hmCountryName.setValue(
          data?.geographicalCountry?.geographicalCountryName
        );
        this.hmCountryForm.controls.hmCountryISOCode.setValue(
          data?.geographicalCountry?.geographicalCountryAlpha2ISOCode
        );
        this.hmCountryForm.controls.hmCountryCode.setValue(
          data?.geographicalCountry?.geographicalCountryNumericISOCode
        );
      });
      this.spinner = false;
      this.isGeocountryslected = true;
      this.hideGeographicalDropdown = false;
    }
  }

  // get fisical country all
  displayTextFC(fisicalCountryId: string) {
    if (fisicalCountryId === null || fisicalCountryId === '') return null;
    else if (this.fiscalcountryList != undefined && fisicalCountryId != null)
      return this.fiscalcountryList.find(
        (x) => x.fiscalCountryId == fisicalCountryId
      )?.geographicalCountryName;
  }
  filterItemfiscalCountry(filterKey: string) {
    let newFCList: any[] = [];
    newFCList = this.fiscalcountryMasterCGList;
    if (filterKey != null && filterKey != '') {
      this.fiscalcountryFilterCGList = newFCList.filter((cb) =>
        cb.geographicalCountryName
          .toLowerCase()
          .includes(filterKey.toString().toLowerCase())
      );
    } else
      this.fiscalcountryFilterCGList = this.fiscalcountryMasterCGList.sort(
        (a, b) =>
          a.geographicalCountryName.localeCompare(b.geographicalCountryName)
      );
  }

  //handle active draf checkbox event
  ActiveCheck(active) {
    if (active.checked) {
      this.hmCountryForm.controls.draft.setValue(false);
    }
  }

  DraftCheck(draft) {
    if (draft.checked) {
      this.hmCountryForm.controls.active.setValue(false);
    }
  }

  //create and update call on same function HM country
  createOrUpdateHmCountry(clickType) {
    if (clickType == 'createHit') {
      this.hmcountryService
        .CreateHandMCountry(this.hmCountryForm.value)
        .subscribe(
          (res) => {
            this._mdr.close(false);
            this.toastr.success(res.message, '', { timeOut: 10000 });
            this.hmcountryService.resfreshCall.next(true);
          },
          (error) => {
            this.toastr.error(error, '', { timeOut: 10000 });
          }
        );
    } else {
      this.hmcountryService
        .UpdateHandMCountry(this.hmCountryForm.value)
        .subscribe(
          (res) => {
            this._mdr.close(false);
            this.toastr.success(res.message, '', { timeOut: 10000 });
            this.hmcountryService.resfreshCall.next(true);
          },
          (error) => {
            this.toastr.error(error, '', { timeOut: 10000 });
          }
        );
    }
  }

  hideShowElements(recivedMsg) {
    if (recivedMsg == 'editHit') {
      this.hideGeographicalDropdown = false;
      this.isGeocountryslected = true;
    } else if (recivedMsg == 'viewHit') {
      this.hideGeographicalDropdown = false;
      this.isGeocountryslected = true;
      this.hmCountryForm.disable();
      this.hidesaveBtn = false;
    } else {
      this.hideGeographicalDropdown = true;
      this.isGeocountryslected = false;
      this.hidesaveBtn = true;
    }
  }

  //setting update form
  setUpdateForm(recivedData) {
    if (recivedData) {
      this.hmCountryForm.addControl(
        'hmCountryId',
        new FormControl(recivedData?.hmCountryId)
      );
      this.hmCountryForm.addControl('lastUpdatedTime', new FormControl(''));
      this.hmCountryForm.controls.hmCountryName.setValue(
        recivedData?.hmCountryName
      );
      this.hmCountryForm.controls.hmCountryISOCode.setValue(
        recivedData?.hmCountryISOCode
      );
      this.hmCountryForm.controls.hmCountryCode.setValue(
        recivedData?.hmCountryCode
      );
      this.hmCountryForm.controls.servedByAnotherFiscalCountryId.setValue(
        recivedData?.servedByAnotherFiscalCountryId
      );
      this.hmCountryForm.controls.geographicalCountryId.setValue(
        recivedData?.geographicalCountryId
      );
      this.hmCountryForm.controls.fiscalCountry.setValue(
        recivedData?.fiscalCountry == 'YES' ? true : false
      );
      this.hmCountryForm.controls.logisticCountry.setValue(
        recivedData?.logisticCountry == 'YES' ? true : false
      );
      this.hmCountryForm.controls.countryOfDelivery.setValue(
        recivedData?.countryOfDelivery == 'YES' ? true : false
      );
      this.hmCountryForm.controls.countryOfOrigin.setValue(
        recivedData?.countryOfOrigin == 'YES' ? true : false
      );
      this.hmCountryForm.controls.countryOfProduction.setValue(
        recivedData?.countryOfProduction == 'YES' ? true : false
      );
      this.hmCountryForm.controls.fiscalCountryAllowed.setValue(
        recivedData?.fiscalCountryAllowed
      );
      this.hmCountryForm.controls.logisticCountryAllowed.setValue(
        recivedData?.logisticCountryAllowed
      );
      this.hmCountryForm.controls.countryOfProductionAllowed.setValue(
        recivedData?.countryOfProductionAllowed
      );
      this.hmCountryForm.controls.countryOfDeliveryAllowed.setValue(
        recivedData?.countryOfDeliveryAllowed
      );
      this.hmCountryForm.controls.countryOfOriginAllowed.setValue(
        recivedData?.countryOfOriginAllowed
      );
      this.hmCountryForm.controls.active.setValue(recivedData?.active);
      this.hmCountryForm.controls.draft.setValue(recivedData?.draft);
      this.hmCountryForm.controls.lastUpdatedBy.setValue(
        JSON.parse(localStorage.getItem('UserInfo')).Email
      );
    }
  }

  cancel() {
    this._mdr.close(false);
  }

  // hmCountryForm form control
  hmCountryForm: FormGroup = new FormGroup({
    geographicalCountryId: new FormControl(0),
    hmCountryName: new FormControl(''),
    hmCountryCode: new FormControl(''),
    hmCountryISOCode: new FormControl(''),
    servedByAnotherFiscalCountryId: new FormControl(0),
    fiscalCountryAllowed: new FormControl(true),
    fiscalCountry: new FormControl(false),
    logisticCountryAllowed: new FormControl(true),
    logisticCountry: new FormControl(false),
    countryOfProductionAllowed: new FormControl(true),
    countryOfProduction: new FormControl(false),
    countryOfOriginAllowed: new FormControl(true),
    countryOfOrigin: new FormControl(false),
    countryOfDeliveryAllowed: new FormControl(true),
    countryOfDelivery: new FormControl(false),
    // euCountry:new FormControl(true),
    // shCountry:new FormControl(true),
    active: new FormControl(false),
    draft: new FormControl(false),
    lastUpdatedBy: new FormControl(
      JSON.parse(localStorage.getItem('UserInfo')).Email
    ),
  });
}
