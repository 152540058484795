<div class="fixed-content mat-elevation-z2 form-border" id="scrolltop">
  <form [formGroup]="_sectionform" id="sectionform">
    <section class="form-section">
      <h2 mat-dialog-title class="align">{{issectionEdit === true ? 'Update' : 'New'}} Section</h2>
    </section>
    <div class="example-container add-form-field ">
      <mat-form-field [appearance]="isSectionEditCB">
        <mat-label>Corporate Brand</mat-label>
        <input type="text" id=inpcorporatebrand matInput [matAutocomplete]="autoCB" formControlName="corporateBrandId"
          (ngModelChange)="filterItemCorporateBrand($event)" [(ngModel)]="updateSectionForm.corporateBrandId"
          [readonly]="isSectionEditCB=='fill'?true:false">

        <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
          <div *ngIf="_sectionform.controls['corporateBrandId'].hasError(validation.type)">{{validation.message}}</div>
        </mat-error>

        <mat-autocomplete autoActiveFirstOption #autoCB="matAutocomplete" [displayWith]="displayTextCB.bind(this)">
          <mat-option (onSelectionChange)="updateSectionForm.divisionId=''"
            *ngFor="let CBname of corporateBrandFilterList" [value]="CBname.corporateBrandId">
            {{CBname.corporateBrandCode}} {{CBname.corporateBrandName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="legacy">
        <mat-label>Division </mat-label>
        <input type="text" id=inpdivision matInput [matAutocomplete]="autoDIV" formControlName="divisionId"
          (ngModelChange)="filterItemDIV($event)" [(ngModel)]="updateSectionForm.divisionId">
        <mat-error *ngFor="let validation of  validationMessages.autoCompleteControl">
          <div *ngIf="_sectionform.controls['divisionId'].hasError(validation.type)">
            {{validation.message}}
          </div>
        </mat-error>
        <mat-autocomplete autoActiveFirstOption #autoDIV="matAutocomplete" [displayWith]="displayTextDIV.bind(this)">
          <mat-option *ngFor="let division of divisionNameFilterList" [value]="division.divisionId">
            {{division.divisionCode}} {{division.divisionName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field [appearance]="issectionEdit==true ? 'fill': 'legacy'">
        <mat-label>Section Code</mat-label>
        <input formControlName="sectionCode" id="inpsectionCode" [readonly]=issectionEdit
          [(ngModel)]="updateSectionForm.sectionCode" matInput>
        <mat-error *ngIf="_sectionform.controls['sectionCode'].errors?.pattern">Section Code should be 2 digit number,
          Ex:- 01 to 99</mat-error>
      </mat-form-field>
      <mat-form-field appearance="legacy">
        <mat-label>Section</mat-label>
        <input formControlName="sectionName" id="inpsectionname" [(ngModel)]="updateSectionForm.sectionName" matInput>
        <mat-error *ngIf="_sectionform.controls['sectionName'].errors?.maxlength">
          Maximum 50
          charactor allowed
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field appearance="legacy">
        <mat-label>Sort Order</mat-label>
        <input id="inpSortOrder" formControlName="sectionSortOrder" [(ngModel)]="updateSectionForm.sectionSortOrder"
          min="0" matInput type="number" pattern="^(0|[1-9][0-9]*)$">
        <mat-error *ngIf="_sectionform.controls['sectionSortOrder'].errors?.max">The range for sort order is
          1-9999</mat-error>
        <mat-error *ngIf="_sectionform.controls['sectionSortOrder'].errors?.pattern">Only Numeric Values
          allowed</mat-error>

      </mat-form-field>
      <section class="example-section">
        <mat-checkbox id="inpactive" formControlName="active" [disabled]="isDisableActive"
          [(ngModel)]="updateSectionForm.active" class="example-margin width-25" color='primary'>Active</mat-checkbox>
        <mat-checkbox id="inpdraft" formControlName="draft" [(ngModel)]="updateSectionForm.draft"
          class="example-margin width-15" color='primary'>Draft</mat-checkbox>
      </section>
      <input type="hidden" formControlName="sectionId" id="inpsectionid" [(ngModel)]="updateSectionForm.sectionId">
      <br>
      <mat-dialog-actions *ngIf="userrole">
        <button [disabled]="(issectionEdit === true) ?  !_sectionform.dirty : false"
          [ngClass]="(issectionEdit === true) ? 'mat-raised-button ml-1':'mat-raised-button ml-1 mat-primary'"
          id="btnSaveUpdate" type="submit" (click)="onSubmit()">
          {{issectionEdit === true ? 'Update' : 'Save'}}
        </button>

        <button class="mat-raised-button mat-primary ml-1" id="btnclear" (click)="onClear()">Cancel</button>
      </mat-dialog-actions>
      <span *ngIf="!userrole" style="color: #ff0202;font-size: 12px;font-weight: 400;">You can't save changes since you
        have Read access.</span>
    </div>
  </form>
</div>