<div class="mat-elevation-z8 add-form-field" *ngIf="depMarkupGroupForm">
    <form [formGroup]="dptMarkupGroupForm">
      <div class="example-container">
        <!-- <h3 mat-dialog-title>{{isEditDepMarkupGroup === true ? 'Update' : 'New'}} Markup Group</h3> -->
        <mat-form-field [appearance]="(isEditDepMarkupGroup == true && isDeleteDepMarkupGroup==false) ?'fill': 'legacy'" class="width-40">
          <mat-label>Start Season</mat-label>
          <input type="text" matInput [matAutocomplete]="autoRSS" formControlName="startSeason"
                 (ngModelChange)="filterStartSeason($event)" [(ngModel)]="updateDepMarkupGroupForm.startSeason"
                 id="inpmgstartseason" [readonly]="(isEditDepMarkupGroup == true && isDeleteDepMarkupGroup==false) ?true:false">
          <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
            <div *ngIf="dptMarkupGroupForm.controls['startSeason'].hasError(validation.type)" style="margin-right: 50px;">
              {{validation.message}}
            </div>
          </mat-error>
          <mat-autocomplete autoActiveFirstOption #autoRSS="matAutocomplete"
                            [displayWith]="displayTextSeason.bind(this)">
            <mat-option *ngFor="let season of startSeasonFilterList" [value]="season.seasonNumber">
              {{season.displayName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field [appearance]="(isEditDepMarkupGroup == true && isDeleteDepMarkupGroup==false) ?'fill': 'legacy'" class="width-40 ml-2">
          <mat-label>End Season</mat-label>
          <input type="text" matInput [matAutocomplete]="autoRES" formControlName="endSeason"
                 (ngModelChange)="filterEndSeason($event)" [(ngModel)]="updateDepMarkupGroupForm.endSeason"
                 id="inpmgendseason" [readonly]="(isEditDepMarkupGroup == true && isDeleteDepMarkupGroup==false) ?true:false">
          <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
            <div *ngIf="dptMarkupGroupForm.controls['endSeason'].hasError(validation.type)" style="margin-right: 50px;">
              {{validation.message}}
            </div>
          </mat-error>
          <mat-autocomplete autoActiveFirstOption #autoRES="matAutocomplete"
                            [displayWith]="displayTextSeason.bind(this)">
            <mat-option *ngFor="let season of endSeasonFilterList" [value]="season.seasonNumber">
              {{season.displayName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <br>
        <mat-form-field [appearance]="(isEditDepMarkupGroup == true && isDeleteDepMarkupGroup==false) ?'fill': 'legacy'" class="width-50">
          <mat-label>Markup Group</mat-label>
          <input type="text" matInput [matAutocomplete]="autoMG" formControlName="markupGroupId"
                 (ngModelChange)="filterItemMG($event)" [(ngModel)]="updateDepMarkupGroupForm.markupGroupId"
                 id="inpmgmarkgroupid" [readonly]="(isEditDepMarkupGroup == true && isDeleteDepMarkupGroup==false) ?true:false">
          <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
            <div *ngIf="dptMarkupGroupForm.controls['markupGroupId'].hasError(validation.type)">
              {{validation.message}}
            </div>
          </mat-error>
          <mat-autocomplete autoActiveFirstOption #autoMG="matAutocomplete"
                            [displayWith]="displayTextMG.bind(this)">
            <mat-option *ngFor="let markupgrp of markupGroupFilterList"
                        [value]="markupgrp.markupGroupId">
              {{markupgrp.markupGroupCode+'-'+markupgrp.markupGroupName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <input type="hidden" id="inpmgdepartmentid" formControlName="departmentId"
               [(ngModel)]="updateDepMarkupGroupForm.departmentId">
        <input type="hidden" id="inpmgdepartmentmarkupgroupid" formControlName="departmentMarkupGroupInfoId"
               [(ngModel)]="updateDepMarkupGroupForm.departmentMarkupGroupInfoId">
        <br>
        <br>  
        <div [style.display]="!dptMarkupGroupForm.dirty==false ? 'block' : 'none'" >
        <mat-dialog-actions *ngIf="!userrole">
          <button [disabled]="(isEditDepMarkupGroup == true && isDeleteDepMarkupGroup==false) ?true:false"
                  [ngClass]="(isEditDepMarkupGroup === true) ? 'mat-raised-button ml-1':'mat-raised-button ml-1 mat-primary'" id="inpmgbtnsubmit" type="submit"
                  (click)="onSubmit()">
            {{isEditDepMarkupGroup === true ? 'Update' : 'Save'}}
          </button>
          <button *ngIf="isDeleteDepMarkupGroup" class="mat-raised-button mat-primary  ml-2" id="inpmgbtndelete"
                  type="button"
                  (click)="deleteDepMarkupGroup(updateDepMarkupGroupForm.departmentMarkupGroupId)">
            Delete
          </button>
          <button class="mat-raised-button mat-primary   ml-2" id="inpmgbtncancel"
                  (click)="onClear()">
            Cancel
          </button>
        </mat-dialog-actions>
        </div>
        <span *ngIf="userrole" style="color: #ff0202;font-size: 12px;font-weight: 400;">You can't save changes since you have Read access.</span>
      </div>
    </form>
</div>
