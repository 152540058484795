import { Component, Input, OnInit, Output } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UserAccess } from 'src/app/models/userAccess';
import { DepartmentService } from 'src/app/services/organization/department/department.service';
import { GarmentgroupService } from 'src/app/services/organization/garmentgroup/garmentgroup.service';
import { AccountService } from 'src/app/shared/services/account.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { DialogBoxService } from 'src/app/shared/services/dialog-box.service';
import { GarmentgroupformComponent } from '../garmentgroupform/garmentgroupform.component';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.css']
})
export class MultiselectComponent implements OnInit {
  @ViewChild('selectids') selectids: MatSelect;
  @ViewChild('search') searchTextBox: ElementRef;
  @ViewChild('validcheck') public validcheck: GarmentgroupformComponent;
  @Input() datacollection:any
  selectFormControl = new FormControl();
  searchTextboxControl = new FormControl();
  selectedValues = [];
  data: any[];
  filteredList:any;
  departmentName:any;
  filteredOptions: Observable<any[]>;
  updategarmentGroupForm: any;
  myCompOneObj:any;
  rows: any[];
  editdeps:any[]=[];
  constructor(
    public _garmentGroupService: GarmentgroupService,
    public _departmentService: DepartmentService,
    private toastr: ToastrService,
    public route: ActivatedRoute,
    public _dialogService: DialogBoxService,
    public account: AccountService,
    private applicationInsightService:ApplicationInsightService,
    public _userAccessService: UserAccess
  ) {
    this.updategarmentGroupForm = Object.assign({ active: true, draft: true });
    this.rows = [];
  }
  ngOnInit() {
    this.myCompOneObj = new GarmentgroupformComponent(this._garmentGroupService,this._departmentService,this.toastr,this.route,this._dialogService,this.account,this.applicationInsightService,this._userAccessService );
    this.data=this.datacollection.filter(data=>{
      return data.active
    });
    this.filteredOptions = this.searchTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this._filter(name))
        );
   
  }

  /**
   * Used to filter data based on search input 
   */
    private _filter(name: string): String[] {
    const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state 
    this.setSelectedValues();
    this.selectFormControl.patchValue(this.selectedValues);
    if(filterValue==null ||""||undefined){
      this.filteredList = this.data;
    }
    else{
      this.filteredList=this.data.filter(item => (item.departmentName.toString().toLowerCase().includes(filterValue.toString().toLowerCase())) ||
      item.departmentCode.toString().toLowerCase().includes(filterValue.toString().toLowerCase()))
    }
   return this.filteredList;
  }

/**
 * Remove from selected values based on uncheck
 */
  selectionChange(departmentId: any, event: any) {
  this.fnReplaceDept(departmentId, event);
  if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedValues.indexOf(event.source.value);
      this.selectedValues.splice(index, 1)
     
    }
    this.departmentName=this.displayDepartmentname(departmentId);
  }
  displayDepartmentname(departmentId: string) {
    if (departmentId == null || departmentId == "")
      return null;
    else if (this.data != undefined && departmentId != null)
      return this.data.find(x => x.departmentId === departmentId).departmentName;
  }

  openedChange(e) {
    // Set search textbox value as empty while opening selectbox 
    this.searchTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  /**
   * Clearing search textbox value 
   */
  clearSearch(event) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
  }

  /**
   * Set selected values to retain the state 
   */
  setSelectedValues() {
    console.log('selectFormControl', this.selectFormControl.value);
    if (this.selectFormControl.value && this.selectFormControl.value.length > 0) {
      this.selectFormControl.value.forEach((e) => {
        if (this.selectedValues.indexOf(e) == -1) {
          this.selectedValues.push(e);
        }
      });
    }
  }
  getSelectedItems(): any {
    if (this.selectedValues.length) return "";
    return this.selectedValues;
  }
  fnReplaceDept(departmentId: any, event: any) {
  this.selectids.options.forEach((item: MatOption) => {
      if (item.selected && this.editdeps.length > 0) {
        for (var i = 0; i < this.editdeps.length; i++) {
          if (this.editdeps[i] == item.value) {
            item.disabled = true;
          }
        }
      }
      else {
        item.disabled = false;
      }
    });
    if (event.isUserInput) {
       if (event.source.selected) {
        let deptId = departmentId;
        this._garmentGroupService.getDepartmentByGarmentGroupId(0).subscribe((data: any) => {
          let exstngRcrd = data.departmentGarmentGroup.filter(x => { return (x['departmentId'] == deptId && x['garmentGroupId'] != this.myCompOneObj._garmentgroupform.controls.garmentGroupId.value) })[0];
          if (exstngRcrd != null) {
            this._dialogService.openConfirmDialog('Department ' + exstngRcrd.departmentCode + '-' + exstngRcrd.departmentName + ' already has garment group ' + exstngRcrd.garmentGroupCode + '-' + exstngRcrd.garmentGroupName + ' . Do you want to replace the old garment group ?')
              .afterClosed().subscribe(res => {
                if (res) { }
                else { event.source.deselect(); }
              })
          }
        });
      }
     }
  }
}
