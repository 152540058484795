import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CorporatebrandService } from 'src/app/services/organization/corporatebrand/corporatebrand.service';
import { CustomerGroupService } from 'src/app/services/organization/customerGroup/customer-group.service';
import { DivisionService } from 'src/app/services/organization/division/division.service';
import { Title } from '@angular/platform-browser';
import { CommonValidationService } from 'src/app/services/organization/commonutility/commonValidation.service';
import { AccountService } from '../../../../shared/services/account.service'
import { Subscription } from 'rxjs';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { ModulePermission } from 'src/app/models/enum';
import { UserAccess } from 'src/app/models/userAccess';
export class dropDownCollections {
  corporateBrandList: any;
  customerGroupList: any;
}
@Component({
  selector: 'app-divisionform',
  templateUrl: './divisionform.component.html',
  styleUrls: ['./divisionform.component.css']
})
export class DivisionformComponent implements OnInit {
  @Output() childButtonEvent = new EventEmitter();
  title = "Division";
  corporateBrandMasterCGList: any[] = [];
  corporateBrandFilterCGList: any[] = [];
  customerGroupNameFilterCGList: any[] = [];
  customerGroupNameMasterCGList: any[] = [];
  isDivisionEditCB = 'legacy';
  validationMessages: any;
  updatedivisionForm: any;
  isdivisionEdit: any = false;
  isDisableActive: any = true;
  subscription: Subscription;
  userrole: boolean;
  users:any;

  constructor(public _corporatebrandService: CorporatebrandService,
    public _customergroupService: CustomerGroupService,
    public _divisionService: DivisionService,
    public _commonvalidationService: CommonValidationService,
    public toastr: ToastrService,
    public router: Router,
    public route: ActivatedRoute,
    public titleService: Title,
    private applicationInsightService: ApplicationInsightService,
    public account: AccountService,
    public _userAccess: UserAccess
  ) {
     this.updatedivisionForm = Object.assign({ active: true, draft: true });
     this.users = JSON.parse(localStorage.getItem("users") || "[]")
    }

  ngOnInit(): void {
    this.userrole = this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this._divisionform.controls.lastUpdatedBy.setValue(JSON.parse(localStorage.getItem("UserInfo")).Email || "[]");
    this.validationMessages = this._commonvalidationService.validationMsgs;
    this.titleService.setTitle(this.title);
    this.onClear();
    this._corporatebrandService.getCorporateBrandList().subscribe((data: any) => {
      this.applicationInsightService.logEvent("Division CorporateBrand listing Started");
      this.corporateBrandMasterCGList = this.corporateBrandFilterCGList = data.getCorporateBrandAll.filter(object => {
        return object['active'] !== false;
      });
      this.corporateBrandFilterCGList = this.corporateBrandMasterCGList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
      this.loadDropdown();
      this.applicationInsightService.logEvent("Division CorporateBrand listing Ended");
    });
    this._customergroupService.getCustomerGroupAll().subscribe((data: any) => {
      this.applicationInsightService.logEvent("Division Customergroup listing Started");
      this.customerGroupNameMasterCGList = data.customerGroup.filter(object => {
        return object['active'] !== false;
      });
      this.loadDropdown();
      this.applicationInsightService.logEvent("Division Customergroup listing Ended");
    });
    this.subscription = this._divisionService.divisionId$.subscribe(
      divisionId => {
        this.updateDivision(divisionId)
      });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }
  loadDropdown() {
    this.applicationInsightService.logEvent("Division loadDropdown Started");
    if (this.corporateBrandMasterCGList.length > 0 && this.customerGroupNameMasterCGList.length > 0) {
      let obj = new dropDownCollections();
      obj.corporateBrandList = this.corporateBrandMasterCGList;
      obj.customerGroupList = this.customerGroupNameMasterCGList;
      this.childButtonEvent.emit(obj);
    }
    this.applicationInsightService.logEvent("Division loadDropdown Ended");
  }
  updateDivision(divisionId: any) {
    this.applicationInsightService.logEvent("Division Edit GetDivisionId Started");
    this.isdivisionEdit = true;
    this.isDisableActive = true;
    this._divisionService.getDivisionById(divisionId).subscribe((data: any) => {
       document.getElementById("scrolltop").scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      this.isDisableActive = false;
      this.updatedivisionForm = data.division;
      this.isDivisionEditCB = this.updatedivisionForm.active == true ? 'fill' : 'legacy';
    });
    this.applicationInsightService.logEvent("Division Edit GetDivisionId Ended");
  }

  displayTextCG(customerGroupId: string) {
    if (customerGroupId == null || customerGroupId == "")
      return null;
    else if (this.customerGroupNameMasterCGList != undefined && customerGroupId != null)
      return this.customerGroupNameMasterCGList.find(x => x.customerGroupId === customerGroupId).customerGroupCodeName;
  }

  filterItemGNAM(filterKey: string) {
    this.applicationInsightService.logEvent("Division form filtering Customergroupname Started");
    if (filterKey != null && filterKey != "" && filterKey !== undefined && typeof this._divisionform.controls.corporateBrandId.value === "number") {
      this.customerGroupNameFilterCGList = this.customerGroupNameMasterCGList.filter(x => x.corporateBrandId == this._divisionform.controls.corporateBrandId.value).filter(gn => gn.customerGroupName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
      this.customerGroupNameFilterCGList = this.customerGroupNameFilterCGList.sort((a, b) => (a.customerGroupCode> b.customerGroupCode? 1 : -1));
    } else if (typeof this._divisionform.controls.corporateBrandId.value === "number")
      {this.customerGroupNameFilterCGList = this.customerGroupNameMasterCGList.filter(x => x.corporateBrandId == this._divisionform.controls.corporateBrandId.value).sort((a, b) => (a.customerGroupCode> b.customerGroupCode? 1 : -1));}
    this.applicationInsightService.logEvent("Division form filtering Customergroupname Ended");
  }

  displayTextCB(corporateBrandId: string) {
    if (corporateBrandId === null || corporateBrandId === "")
      return null;
    else if (this.corporateBrandMasterCGList !== undefined && corporateBrandId !== null && corporateBrandId !== undefined)

      return this.corporateBrandMasterCGList.find(x => x.corporateBrandId == corporateBrandId).corporateBrandCodeName;
  }

  filterItemCorporateBrand(filterKey: string) {
    this.applicationInsightService.logEvent("Division form filtering CorporateBrand Started");
    if (filterKey !== null && filterKey !== "" && filterKey !== undefined) {
      this.corporateBrandFilterCGList = this.corporateBrandMasterCGList.filter(cb => cb.corporateBrandName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
      this.corporateBrandFilterCGList = this.corporateBrandFilterCGList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
      if (typeof filterKey === "number")
        this.filterCustomerGroupByCBId(this._divisionform.controls.corporateBrandId.value);
    }
    else {
      this.customerGroupNameFilterCGList = [];
      this.corporateBrandFilterCGList = this.corporateBrandMasterCGList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
      this._divisionform.controls.customerGroupId.reset();
    }
    this.applicationInsightService.logEvent("Division form filtering CorporateBrand Ended");
  }

  filterCustomerGroupByCBId(corporateBrandId: any) {
    this.applicationInsightService.logEvent("Division form filtering Customergroup by corporatebrand id Started");
    this.customerGroupNameFilterCGList = this.customerGroupNameMasterCGList.filter(x => x.corporateBrandId == corporateBrandId && x.active == true);
    this.customerGroupNameFilterCGList = this.customerGroupNameFilterCGList.sort((a, b) => (a.customerGroupCode> b.customerGroupCode? 1 : -1));
    this.applicationInsightService.logEvent("Division form filtering Customergroup by corporatebrand id Ended");
  }

  onSubmit() {
    if (this._divisionform.controls.divisionName.value != undefined && this._divisionform.controls.divisionName.value != null && this._divisionform.controls.divisionName.value != '') { this._divisionform.controls.divisionName.setValue(this._divisionform.controls.divisionName.value.trim()) }
    this._divisionform.controls['corporateBrandId'].updateValueAndValidity();
    this._divisionform.controls['customerGroupId'].updateValueAndValidity();
    this._divisionform.controls['divisionCode'].updateValueAndValidity();
    this._divisionform.controls['divisionName'].updateValueAndValidity();
    this._divisionform.controls['divisionSortOrder'].updateValueAndValidity();
    if (this._divisionform.controls.customerGroupId.value === '' || this._divisionform.controls.customerGroupId.value == null) { this._divisionform.controls.customerGroupId.setValue(null) }
    console.log("Division Save " + JSON.stringify(this._divisionform.value));
    if (this._divisionform.valid) {
      if (this._divisionform.value.divisionId == undefined || this._divisionform.value.divisionId == 0 || this._divisionform.value.divisionId == null) {
        this._divisionService.createDivision(this._divisionform.value).subscribe
          (data => {
            this.toastr.success(data.message, "", { timeOut: 10000 });
            this.onClear();
            this._divisionService.refreshDivisionList();
          },
            error => { this.toastr.error(error, "", { timeOut: 10000 }) })
      }
      else {
        this._divisionService.updateDivision(this._divisionform.value).subscribe
          (data => {
            this.toastr.success(data.message, "", { timeOut: 10000 });
            this.onClear();
            this._divisionService.refreshDivisionList();
          },
            error => { this.toastr.error(error, "", { timeOut: 10000 }) })
      }
    }
  }

  onClear() {

    this.applicationInsightService.logEvent("Division form clear Started");
    //Clearing validation 
    this._divisionform.controls.corporateBrandId.clearValidators();
    this._divisionform.controls.customerGroupId.clearValidators();
    this._divisionform.controls.divisionCode.clearValidators();
    this._divisionform.controls.divisionName.clearValidators();
    this._divisionform.controls.divisionSortOrder.clearValidators();

    //Clearing control values 
    this._divisionform.controls.divisionId.reset();
    this._divisionform.controls.corporateBrandId.reset();
    this._divisionform.controls.customerGroupId.reset();
    this._divisionform.controls.divisionCode.reset();
    this._divisionform.controls.divisionSortOrder.reset();
    this._divisionform.controls.divisionName.reset();
    this._divisionform.controls.draft.reset();

    //Setting up the validation
    this._divisionform.controls.corporateBrandId.setValidators([this._commonvalidationService.acObjectValidator(), Validators.required]);
    this._divisionform.controls.customerGroupId.setValidators([this._commonvalidationService.acObjectValidator()]);
    this._divisionform.controls.divisionCode.setValidators([Validators.required, Validators.pattern('[0-9][0-9]')]);
    this._divisionform.controls.divisionName.setValidators([Validators.required, Validators.maxLength(50)]);
    this._divisionform.controls.divisionSortOrder.setValidators([Validators.required, Validators.min(1), Validators.max(9999)])
    this._divisionform.controls.draft.setValue(true);
    this._divisionform.controls.active.setValue(true);

    this.filterItemGNAM("");
    this.isdivisionEdit = false;
    this.isDisableActive = true;
    this.isDivisionEditCB = 'legacy';
    this.applicationInsightService.logEvent("Division form clear Ended");
  }

  //Used for New Division Creation.It will build the JSON
  _divisionform: FormGroup = new FormGroup({
    divisionId: new FormControl(''),
    corporateBrandId: new FormControl('', [this._commonvalidationService.acObjectValidator(), Validators.required]),
    customerGroupId: new FormControl(null, [this._commonvalidationService.acObjectValidator()]),
    divisionCode: new FormControl('', [Validators.required, Validators.pattern('[0-9][0-9]')]),
    divisionSortOrder: new FormControl('', [Validators.required, Validators.min(1), Validators.max(9999)]),
    divisionName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    active: new FormControl(true),
    lastUpdatedBy: new FormControl(),
    draft: new FormControl(true),
  });


}

