<div class="fixed-content mat-elevation-z2 form-border" id="scrolltop">
  <form [formGroup]="_divisionform">
    <section class="form-section">
      <h2 mat-dialog-title class="align">{{isdivisionEdit === true ? 'Update' : 'New'}} Division</h2>
    </section>
    <div class="example-container add-form-field ">
      <mat-form-field [appearance]="isDivisionEditCB">
        <mat-label>Corporate Brand</mat-label>
        <input type="text" id=inpcorporatebrand matInput [matAutocomplete]="autoCB" formControlName="corporateBrandId"
               (ngModelChange)="filterItemCorporateBrand($event)" [(ngModel)]="updatedivisionForm.corporateBrandId"
               [readonly]="isDivisionEditCB=='fill' ?true:false">

        <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
          <div *ngIf="_divisionform.controls['corporateBrandId'].hasError(validation.type)">{{validation.message}}</div>
        </mat-error>

        <mat-autocomplete autoActiveFirstOption #autoCB="matAutocomplete" [displayWith]="displayTextCB.bind(this)">
          <mat-option (onSelectionChange)="updatedivisionForm.customerGroupId=''" *ngFor="let CBname of corporateBrandFilterCGList" [value]="CBname.corporateBrandId">
            {{CBname.corporateBrandCode}} {{CBname.corporateBrandName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="legacy">
        <mat-label>Customer Group </mat-label>
        <input type="text" id=inpcustomergroup matInput [matAutocomplete]="autoCG" formControlName="customerGroupId"
               (ngModelChange)="filterItemGNAM($event)" [(ngModel)]="updatedivisionForm.customerGroupId">
        <mat-error *ngFor="let validation of  validationMessages.autoCompleteControl">
          <div *ngIf="_divisionform.controls['customerGroupId'].hasError(validation.type)">
            {{validation.message}}
          </div>
        </mat-error>
        <mat-autocomplete autoActiveFirstOption #autoCG="matAutocomplete" [displayWith]="displayTextCG.bind(this)">
          <mat-option *ngFor="let CGname of customerGroupNameFilterCGList" [value]="CGname.customerGroupId">
            {{CGname.customerGroupCode}} {{CGname.customerGroupName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field [appearance]="isdivisionEdit==true ? 'fill': 'legacy'" >
        <mat-label>Division Code</mat-label>
        <input formControlName="divisionCode" id=inpdivisioncode [readonly]=isdivisionEdit
               [(ngModel)]="updatedivisionForm.divisionCode" matInput>
        <mat-error *ngIf="_divisionform.controls['divisionCode'].errors?.pattern">Division Code should be 2 digit number, Ex:- 01 to 99</mat-error>
      </mat-form-field>
      <mat-form-field appearance="legacy">
        <mat-label>Division</mat-label>
        <input formControlName="divisionName" id=inpdivisionname [(ngModel)]="updatedivisionForm.divisionName" matInput>
        <mat-error *ngIf="_divisionform.controls['divisionName'].errors?.maxlength">
          Maximum 50 Characters
          allowed
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="legacy">
        <mat-label>Sort Order</mat-label>
        <input formControlName="divisionSortOrder" id=inpdivisionsortorder type="number" min="1"
               [(ngModel)]="updatedivisionForm.divisionSortOrder" matInput type="number" pattern="^(0|[1-9][0-9]*)$">
        <mat-error *ngIf="_divisionform.controls['divisionSortOrder'].errors?.max">The range for sort order is 1-9999. </mat-error>
        <mat-error *ngIf="_divisionform.controls['divisionSortOrder'].errors?.pattern">Only Numeric Values allowed</mat-error>
      </mat-form-field>
      <section class="example-section">
        <mat-checkbox formControlName="active" id=inpdivisionactive [disabled]="isDisableActive" [(ngModel)]="updatedivisionForm.active"
                      class="example-margin width-25" color='primary'>Active</mat-checkbox>
        <mat-checkbox formControlName="draft" id=inpdivisiondraft [(ngModel)]="updatedivisionForm.draft"
                      class="example-margin width-15" color='primary'>Draft</mat-checkbox>
      </section>
      <input type="hidden" id=inpdivisionid formControlName="divisionId" [(ngModel)]="updatedivisionForm.divisionId">
      <br>
      <mat-dialog-actions *ngIf="userrole">
        <button [disabled]="(isdivisionEdit === true) ?  !_divisionform.dirty : false" [ngClass]="(isdivisionEdit === true) ? 'mat-raised-button ml-1':'mat-raised-button ml-1 mat-primary'" id="btnSaveUpdate"
                type="submit" (click)="onSubmit()">
          {{isdivisionEdit === true ? 'Update' : 'Save'}}
        </button>

        <button class="mat-raised-button mat-primary ml-1" id="btnclear" (click)="onClear()">Cancel</button>
      </mat-dialog-actions>
      <span *ngIf="!userrole" style="color: #ff0202;font-size: 12px;font-weight: 400;">You can't save changes since you have Read access.</span>
    </div>
  </form>
</div>
