import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { DivisionService } from 'src/app/services/organization/division/division.service';
import { Subject } from 'rxjs';
import { DepartmentlistComponent } from '../departmentlist/departmentlist.component';
import { MatDrawer } from '@angular/material/sidenav';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { DepartmentService } from 'src/app/services/organization/department/department.service';


@Component({
  selector: 'department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})
export class DepartmentComponent implements OnInit {
  obj: any;
  departmentCode: any = "";
  departmentName:any ="";
  departmentNameMasterList: any[] = [];
  departmentNameFilterList:  any[] = [];
  cloneDepartmentNameMasterList:any[]=[];
  corporateBrandMasterList: any[] = [];
  corporateBrandFilterList: any[] = [];
  corporateBrandName: any = "";
  divisionNameMasterList: any[];
  cloneDivisionNameMasterList: any[]=[];
  divisionNameFilterList: any[];
  divisionName: any = "";
  sectionNameMasterList: any[] = [];
  cloneSectionNameMasterList: any[] = [];
  sectionNameFilterList: any[] = [];
  section: any = "";
  garmentGroupNameMasterList: any[] = [];
  cloneGarmentGroupNameMasterList: any[] = [];
  garmentGroupNameFilterList: any[] = [];
  garmentGroup: any = "";
  // customerGroupNameMasterList: any[] = [];
  // cloneCustomerGroupNameMasterList: any[] = [];
  // customerGroupNameFilterList: any[] = [];
  // customerGroup: any = "";
  markupGroupNameMasterList: any[] = [];
  markupGroupNameFilterList: any[] = [];
  markUpGroup: any = "";
  subIndexCodeFilterList: any[] = [];
  subIndexCodeMasterList: any[] = [];
  cloneSubIndexCodeMasterList: any[] = [];
  subIndex: any = "";  
  status: any = "active";
  showFiller = false;
  nav_position: string = 'end';
  //declaring for testcase
  corporateBrandList:any;
  sectionList:any;
  garmentGroupList:any;
  searchParams: any;

  @Input()opened: boolean=false;
  @Output() childButtonEvent = new EventEmitter();
  @ViewChild('drawer') public drawer: MatDrawer;
  @ViewChild('DepartmentlistComponent') public isTooltip: DepartmentlistComponent;
  eventsSubject: Subject<void> = new Subject<void>();
   constructor(
    public _divisionService: DivisionService, 
    public _departmentService: DepartmentService,
    private titleService: Title,
    public element: ElementRef,
    private applicationInsightService:ApplicationInsightService) { } 
    toggleclick()
    {
      this.childButtonEvent.emit(this.drawer?.toggle());
      this.emitEventToChild();
      if(!this.drawer.opened){
      this.isTooltip.isdisable=true;
    }
    else{
      this.isTooltip.isdisable=false;
    }
    }
    //Event Emitter to child
    emitEventToChild() 
    {
      this.eventsSubject?.next();
    }
  ngOnInit(): void 
  {
    this.applicationInsightService.logEvent("Department ngOnInit Division listing started");
    this.titleService.setTitle(this.element.nativeElement.tagName?.charAt(0)?.toUpperCase() + this.element.nativeElement.tagName?.slice(1)?.toLowerCase());
    this._divisionService?.getDivisionList()?.subscribe((data: any) => 
    {
      this.divisionNameMasterList = this.divisionNameFilterList = data.division;
      this.divisionNameFilterList = this.divisionNameMasterList?.sort((a,b) =>  a.divisionCode?.localeCompare(b.divisionCode));
    });
    this._departmentService.getDepartmentByBrand({
    "seasonNumber": "current"
     }).subscribe((data: any)=>{
      this.departmentNameMasterList=this.departmentNameFilterList=data.department;
      this.departmentNameFilterList = this.departmentNameMasterList?.sort((a, b) => (a.departmentCode > b.departmentCode ? 1 : -1));
    });
    this.applicationInsightService.logEvent("Department ngOnInit Division listing ended");  
  }  
  public receivedChildHandler() 
  {  
    if(this.opened=true)
    {
     this.childButtonEvent.emit(this.drawer.open());
     this.isTooltip.isdisable=false;
    }
    else
    {
      this.childButtonEvent.emit(this.drawer.close());
      this.isTooltip.isdisable=true;
    }
  }
  public dropDownCollections(obj:any)
  {
    this.applicationInsightService.logEvent("Department Corporatebrand, Section, Garmentgroup, Subindex listing started");
    this.corporateBrandMasterList = this.corporateBrandFilterList = obj?.corporateBrandList;
    var distinctSectionName =  obj?.sectionList;
    this.sectionNameMasterList = this.sectionNameFilterList = distinctSectionName?.sort((a,b) =>  a?.sectionCode?.localeCompare(b.sectionCode));
    this.garmentGroupNameMasterList = this.garmentGroupNameFilterList = obj?.garmentGroupList;
    //this.customerGroupNameMasterList = this.customerGroupNameFilterList = obj?.customerGroupList;
    this.subIndexCodeFilterList = this.subIndexCodeMasterList = obj?.subIndexList;
    this.applicationInsightService.logEvent("Department Corporatebrand, Section, Garmentgroup, Subindex listing ended");
  }    
  filterItemSubIndex(filterKeySI: any) 
  {
    this.applicationInsightService.logEvent("Department filter subindex started");
    var hasNumber = /\d/;   
    if (filterKeySI != null && filterKeySI != "")
    {
      if (hasNumber.test(filterKeySI)){
        this.subIndexCodeFilterList = this.subIndexCodeMasterList?.filter(si => si?.subIndexCode.toString()?.toLowerCase().includes(filterKeySI?.toString()?.toLowerCase()))
        this.subIndexCodeFilterList = this.subIndexCodeFilterList?.sort((a,b) =>  a?.subIndexCode?.localeCompare(b?.subIndexCode));
      }else{
        this.subIndexCodeFilterList = this.subIndexCodeMasterList?.filter(si => si?.subIndexName?.toString()?.toLowerCase()?.includes(filterKeySI?.toString()?.toLowerCase()));
        this.subIndexCodeFilterList = this.subIndexCodeFilterList?.sort((a,b) =>  a?.subIndexCode?.localeCompare(b?.subIndexCode));
      }
    }
    else
    this.subIndexCodeFilterList = this.subIndexCodeMasterList.sort((a,b) =>  a.subIndexCode?.localeCompare(b?.subIndexCode));
     this.applicationInsightService.logEvent("Department filter subindex ended");
  }
  filterItemdepartmentName(filterKeyDN : any){
    this.applicationInsightService.logEvent("Department filter corporatebrand started");
     if(isNaN(filterKeyDN)){
        this.departmentNameFilterList = this.departmentNameMasterList?.filter(din => din?.departmentName?.toString()?.toLowerCase()?.includes(filterKeyDN?.toString()?.toLowerCase()));
        this.departmentNameFilterList = this.departmentNameFilterList?.sort((a, b) => a?.departmentCode?.localeCompare(b?.departmentCode));
      }else{
        this.departmentNameFilterList = this.departmentNameMasterList?.filter(din => din?.departmentCode?.toString()?.toLowerCase()?.includes(filterKeyDN?.toString()?.toLowerCase()));
        this.departmentNameFilterList = this.departmentNameFilterList?.sort((a, b) => a?.departmentCode?.localeCompare(b?.departmentCode));
      }
    this.applicationInsightService.logEvent("Department filter corporatebrand ended");
  }
  filterItemCorporateBrand(filterKey: any) {
    this.applicationInsightService.logEvent("Filter Corporatebrand Started");
    this.onFilter();
    if (isNaN(filterKey)) {
      this.corporateBrandFilterList = this.corporateBrandMasterList?.filter(cb => cb?.corporateBrandName?.toLowerCase()?.includes(filterKey?.toLowerCase()));
      this.corporateBrandFilterList = this.corporateBrandFilterList?.sort((a, b) => (a?.corporateBrandCode > b?.corporateBrandCode ? 1 : -1));
    } else {
      this.dptSearchForm.controls.divisionName.setValue("");
      this.dptSearchForm.controls.section.setValue("");
      this.corporateBrandFilterList = this.corporateBrandMasterList?.filter(cb => cb.corporateBrandCode?.toLowerCase()?.includes(filterKey?.toLowerCase()));
      this.corporateBrandFilterList = this.corporateBrandFilterList?.sort((a, b) => (a?.corporateBrandCode > b?.corporateBrandCode ? 1 : -1));
    }
    this.applicationInsightService.logEvent("Filter Corporatebrand Ended");
  }
  filterItemDivisionName(filterKeyDiN:any) 
  {
    this.applicationInsightService.logEvent("Department filter DivisionName started");
    // this.onFilter()
    if (filterKeyDiN != null && filterKeyDiN != "")
    {
      if(isNaN(filterKeyDiN)){
        this.divisionNameFilterList = this.divisionNameMasterList?.filter(din => din?.divisionName?.toString()?.toLowerCase()?.includes(filterKeyDiN?.toString()?.toLowerCase()));
        this.divisionNameFilterList = this.divisionNameFilterList?.sort((a,b) =>  a.divisionCode?.localeCompare(b.divisionCode));
      }else{
        this.divisionNameFilterList = this.divisionNameMasterList?.filter(din => din?.divisionCode?.toString()?.toLowerCase()?.includes(filterKeyDiN?.toString()?.toLowerCase()));
        this.divisionNameFilterList = this.divisionNameFilterList?.sort((a,b) =>  a?.divisionCode?.localeCompare(b?.divisionCode));
      }
    }
    else {
      this.divisionNameFilterList = this.divisionNameMasterList!=undefined? this.divisionNameMasterList.sort((a,b) =>  a.divisionCode.localeCompare(b.divisionCode)):this.divisionNameMasterList;
    }
    this.applicationInsightService.logEvent("Department filter DivisionName ended");
  }
  filterItemSection(filterKeySN: any) 
  {
    this.applicationInsightService.logEvent("Department filter section started");
    if (filterKeySN != null && filterKeySN != "")
    {
      if(isNaN(filterKeySN)){
        this.sectionNameFilterList = this.sectionNameMasterList?.filter(s => s?.sectionName?.toString()?.toLowerCase()?.includes(filterKeySN.toString()?.toLowerCase()))
        this.sectionNameFilterList = this.sectionNameFilterList?.sort((a,b) =>  a.sectionCode?.localeCompare(b?.sectionCode));
      }else{
        this.sectionNameFilterList = this.sectionNameMasterList?.filter(s => s?.sectionCode?.toString()?.toLowerCase()?.includes(filterKeySN.toString()?.toLowerCase()))
        this.sectionNameFilterList = this.sectionNameFilterList?.sort((a,b) =>  a.sectionCode?.localeCompare(b?.sectionCode));
      }
    }
    else
    this.sectionNameFilterList = this.sectionNameMasterList?.sort((a,b) =>  a.sectionCode?.localeCompare(b.sectionCode));
    this.applicationInsightService.logEvent("Department filter section ended");
  }
  filterItemGarmentGroup(filterKeyGG: any) 
  {
    this.applicationInsightService.logEvent("Department filter garmentgroup started");
    setTimeout(() => {
      if (filterKeyGG)
      {
        if(isNaN(filterKeyGG)){
          this.garmentGroupNameFilterList = this.garmentGroupNameMasterList?.filter(gg => gg?.garmentGroupName?.toString()?.toLowerCase()?.includes(filterKeyGG?.toString()?.toLowerCase()))
          this.garmentGroupNameFilterList = this.garmentGroupNameFilterList?.sort((a,b) =>  a?.garmentGroupCode?.localeCompare(b?.garmentGroupCode));
        }else{
        this.garmentGroupNameFilterList = this.garmentGroupNameMasterList?.filter(gg => gg?.garmentGroupCode?.toString().toLowerCase().includes(filterKeyGG?.toString()?.toLowerCase()))
        this.garmentGroupNameFilterList = this.garmentGroupNameFilterList?.sort((a,b) =>  a?.garmentGroupCode?.localeCompare(b?.garmentGroupCode));
        }
      }
      else{
        this.garmentGroupNameFilterList = this.garmentGroupNameMasterList
        this.applicationInsightService.logEvent("Department filter garmentgroup ended");
      }
     
    }, 1000);
    
  }
  // filterItemCustomerGroup(filterKeyCG: any) 
  // {
  //   this.applicationInsightService.logEvent("Department filter customergroup started");
  //   setTimeout(() => {
  //     if (filterKeyCG)
  //     {
  //       if(isNaN(filterKeyCG)){
  //         this.customerGroupNameFilterList = this.customerGroupNameMasterList?.filter(cg => cg?.customerGroupName?.toString()?.toLowerCase()?.includes(filterKeyCG?.toString()?.toLowerCase()))
  //         this.garmentGroupNameFilterList = this.customerGroupNameFilterList?.sort((a,b) =>  a?.customerGroupCode?.localeCompare(b?.customerGroupCode));
  //       }else{
  //       this.customerGroupNameFilterList = this.customerGroupNameMasterList?.filter(cg => cg?.customerGroupCode?.toString().toLowerCase().includes(filterKeyCG?.toString()?.toLowerCase()))
  //       this.customerGroupNameFilterList = this.customerGroupNameFilterList?.sort((a,b) =>  a?.customerGroupCode?.localeCompare(b?.customerGroupCode));
  //       }
  //     }
  //     else{
  //       this.customerGroupNameFilterList = this.customerGroupNameMasterList
  //       this.applicationInsightService.logEvent("Department filter customergroup ended");
  //     }
     
  //   }, 1000);
    
  // }
  // filter function based on selection
  onFilter(){
    setTimeout(() => {
      // filtering based on co-brand
      if(this.cloneDivisionNameMasterList?.length === 0){
        this.cloneDivisionNameMasterList = this.divisionNameMasterList; 
      }

      if(this.cloneSectionNameMasterList?.length === 0){
        this.cloneSectionNameMasterList = this.sectionNameMasterList;
      }

      if(this.cloneDepartmentNameMasterList?.length === 0){
        this.cloneDepartmentNameMasterList = this.departmentNameFilterList;
      }

      // if(this.cloneGarmentGroupNameMasterList?.length === 0){
      //   this.cloneGarmentGroupNameMasterList = this.garmentGroupNameMasterList;
      // }

      if(this.cloneSubIndexCodeMasterList?.length === 0){
        this.cloneSubIndexCodeMasterList = this.subIndexCodeMasterList;
      }

      if(this.dptSearchForm?.controls?.corporateBrandName?.value){
        this.sectionNameMasterList = this.cloneSectionNameMasterList?.filter(x => x?.corporateBrandCodeName === this.dptSearchForm?.controls?.corporateBrandName?.value)
        this.divisionNameMasterList = this.cloneDivisionNameMasterList?.filter(x => x?.corporateBrandCodeName === this.dptSearchForm?.controls?.corporateBrandName?.value)
        this.subIndexCodeMasterList = this.cloneSubIndexCodeMasterList?.filter(x => x?.corporateBrandCodeName === this.dptSearchForm?.controls?.corporateBrandName?.value)
        this.departmentNameMasterList = this.cloneDepartmentNameMasterList?.filter(x => x?.corporateBrandCodeName === this.dptSearchForm?.controls?.corporateBrandName?.value)
        this.divisionNameFilterList = this.divisionNameMasterList;
        this.sectionNameFilterList = this.sectionNameMasterList;
        this.subIndexCodeFilterList = this.subIndexCodeMasterList;
        this.departmentNameFilterList = this.departmentNameMasterList;
      } else {
        this.divisionNameFilterList = this.cloneDivisionNameMasterList;
        this.divisionNameMasterList = this.cloneDivisionNameMasterList;

        this.sectionNameFilterList = this.cloneSectionNameMasterList;
        this.sectionNameMasterList = this.cloneSectionNameMasterList;

        this.subIndexCodeFilterList = this.cloneSubIndexCodeMasterList;
        this.subIndexCodeMasterList = this.cloneSubIndexCodeMasterList;

        this.departmentNameFilterList = this.cloneDepartmentNameMasterList;
        this.departmentNameMasterList = this.cloneDepartmentNameMasterList;
      }
   }, 1000);
  }

  //Clear button 
  onSearchClear() 
  {    
    this.applicationInsightService.logEvent("Department searchclear started");
    this.dptSearchForm.controls.corporateBrandName.setValue("");
    this.dptSearchForm.controls.departmentCode.setValue("");
    this.dptSearchForm.controls.departmentName.setValue("");
    this.dptSearchForm.controls.divisionName.setValue("");
    this.dptSearchForm.controls.section.setValue("");
    this.dptSearchForm.controls.garmentGroup.setValue("");
    //this.dptSearchForm.controls.customerGroup.setValue("");
    this.dptSearchForm.controls.subIndex.setValue("");
    this.dptSearchForm.controls.status.setValue("active");
    this.applicationInsightService.logEvent("Department searchclear ended");
  }
  //Used for search option
  dptSearchForm: FormGroup = new FormGroup(
  {
    departmentCode: new FormControl(''),
    departmentName: new FormControl(''),
    corporateBrandName: new FormControl(''),
    divisionName: new FormControl(''),
    section: new FormControl(''),
    garmentGroup: new FormControl(''),
    //customerGroup: new FormControl(''),
    subIndex: new FormControl(''),
    markUpGroup: new FormControl(''),
    status: new FormControl('')
  })

}
