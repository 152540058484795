import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, Subject } from 'rxjs';
import { ServiceApi } from '../../../services/ServiceApi';

let headers = new HttpHeaders()
if (!headers.has('content-type')) {
  headers = headers.append('content-type', 'application/json')
}

@Injectable({
  providedIn: 'root'
})

export class DepartmentSubIndexService {
  headers = new HttpHeaders().set('content-type','application/json').set('accept','application/json');
  httpOptions ={
    headers:this.headers
  }

  constructor(private http: HttpClient,public _serviceApi: ServiceApi) {}

   // Observable string sources
   public departmentSubIndexIdSource = new Subject<string>();

   // Observable string streams
   public departmentSubIndexInfoId$ = this.departmentSubIndexIdSource.asObservable();
 
    // Service message commands
    getdepartmentSubIndexIdFromList(mission: string) {
     this.departmentSubIndexIdSource.next(mission);
   }

   // Observable string sources
  public refreshTable = new Subject<string>();
  // Observable string streams
  public refreshTableDepartmentSubIndex$ = this.refreshTable.asObservable();

  // Service message commands
  refreshDepartmentSubIndexList() {
    this.refreshTable.next();
  }

  //Create Department SubIndex
  createDepartmentSubIndex(createNewDepartmentSubIndex: any): Observable<any> {
 return this.http.post(this._serviceApi.DptSubIndexCreate, JSON.stringify(createNewDepartmentSubIndex), { 'headers': headers });
  }

  //Update Department SubIndex
  updateDepartmentSubIndex(updateDepartmentSubIndex: any): Observable<any> {
   return this.http.put(this._serviceApi.DptSubIndexUpdate, JSON.stringify(updateDepartmentSubIndex), { 'headers': headers });
  }

  //Get Department SubIndex By Id
  getDepartmentSubIndexById(departmentSubIndexInfoId: any): Observable<any> {
     return this.http.get(this._serviceApi.DptSubIndexGetByID+departmentSubIndexInfoId);
  }
 
    //Delete Department SubIndex
    DeleteDepartmentSubIndex(deleteDepartmentSubIndex: any): Observable<any> {
      return this.http.post(this._serviceApi.DptSubIndexDelete, JSON.stringify(deleteDepartmentSubIndex), { 'headers': headers });
    }
}

