<div class="wrap">
    <div class="fleft">
        <div class="ribbon">
            <div class="ribbon-stitches-top"></div>
            <div class="ribbon-content">
                <p>&nbsp;&nbsp;Channel</p>
            </div>
            <div class="ribbon-stitches-bottom"></div>
        </div>
        <div class="add-div">
            <form [formGroup]="searchform">
                <mat-form-field [floatLabel]="'always'" appearance="outline" class="width-20">
                    <mat-label>Channel</mat-label>
                    <input type="text" id="SearchChannelName" matInput [matAutocomplete]="autoC"
                        formControlName="channelName" [(ngModel)]="channelName" (ngModelChange)="filterItemCN($event)">
                    <mat-autocomplete autoActiveFirstOption #autoC="matAutocomplete">
                        <mat-option *ngFor="let Cname of channelNameFilterList" [value]="Cname.channelName">
                            {{Cname.channelName}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field [floatLabel]="'always'" appearance="outline" class="width-11 ml-1">
                    <mat-label>Status</mat-label>
                    <mat-select id="Searchstatus" formControlName="status" [(ngModel)]="status">
                        <mat-option value="all">
                            All
                        </mat-option>
                        <mat-option value="active">
                            Active
                        </mat-option>
                        <mat-option value="inactive">
                            Inactive
                        </mat-option>
                        <mat-option value="draft">
                            Draft
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button id="SearchClear" type="submit" (click)="onSearchClear()" mat-raised-button
                    class="add-button-container mat-raised-button mat-primary ml-2">
                    Clear
                </button>
            </form>
        </div>
        <app-channellist [channelSearch]=searchform.value></app-channellist>
    </div>
    <div class="clear">
    </div>
    <div class="fright">
        <app-channelform (childButtonEvent)="receivedChildHandler()"></app-channelform>
    </div>
</div>