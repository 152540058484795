import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, Subject } from 'rxjs';
import { ServiceApi } from '../../../services/ServiceApi';

let headers = new HttpHeaders()
    if (!headers.has('content-type')) {
      headers=headers.append('content-type','application/json')
    }

@Injectable({
  providedIn: 'root'
})
export class MarkupgroupService {

  constructor(private http: HttpClient,public _serviceApi: ServiceApi) { 
  }

  headers = new HttpHeaders().set('content-type','application/json').set('accept','application/json');
  httpOptions ={
    headers:this.headers
  }
// Observable string sources
public refreshTable = new Subject<string>();
// Observable string streams
 public refreshTableMarkupgroup$ = this.refreshTable.asObservable();

// Service message commands
refreshMarkupgroupList() {
  this.refreshTable.next();
}

 // Observable string sources
 public markupgroupIdSource = new Subject<string>();
 // Observable string streams
public markupgroupId$ = this.markupgroupIdSource.asObservable();

getMarkupgroupIdFromList(mission: string) {
  this.markupgroupIdSource.next(mission);
}

  //Get Markupgroup All by filter from Service
 getMarkupgroupAll(): Observable<any> {
  var ServiceURL = this._serviceApi.MarkupgroupGetAll 
 return this.http.get(ServiceURL);
}

  //Create the new Markupgroup
  createMarkupgroup(createNewMarkupgroup:any):Observable<any>{
    
    let url = this._serviceApi.MarkupgroupCreate;  
    var body=(JSON.stringify(createNewMarkupgroup));
    return this.http.post(url,body,{'headers':headers})
  }

  //Update the exisiting Markupgroup
  updateMarkupgroup(updateMarkupgroup:any):Observable<any> {
        
    return this.http.put(this._serviceApi.MarkupgroupUpdate,JSON.stringify(updateMarkupgroup),{'headers':headers});
  }

  //Get Markupgroup By Id
  getMarkupgroupById(MarkupgroupId:any):Observable<any>{
      
    let url = this._serviceApi.MarkupgroupGetByID
    console.log(url + MarkupgroupId)
    return this.http.get(url + MarkupgroupId)
  }  

 getDepartmentByMarkUpGroupId(markupGroupId:number,seasonNumber:number):Observable<any>{  
  let url = this._serviceApi.MarkUpGroupDepartmentBySeasonGet+"?markupGroupId="+markupGroupId+"&seasonNumber="+seasonNumber;
  console.log( url);
  return this.http.get(url) 
}  


}

