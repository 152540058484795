<div class="wrap">
  <div class="filter-div">
    <div class="add-div container">
      <form [formGroup]="_searchform">
        <mat-form-field id="custom-col110" class="customform" appearance="legacy">
          <mat-label>Season</mat-label>
          <input type="text" matInput [matAutocomplete]="autoSN" formControlName="seasonNumber"
            (ngModelChange)="filterSeason($event)">
          <mat-autocomplete autoActiveFirstOption #autoSN="matAutocomplete"
            [displayWith]="displayTextSeason.bind(this)">
            <mat-option *ngFor="let season of seasonFilterList" [value]="season.seasonNumber">
              {{season.displayName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="customform" appearance="legacy">
          <mat-label>Channel</mat-label>
          <input type="text" id="ChannelName" matInput [matAutocomplete]="autoCL" formControlName="channelName"
            (ngModelChange)="filterchannelName($event)">
          <mat-autocomplete autoActiveFirstOption #autoCL="matAutocomplete">
            <mat-option *ngFor="let CLname of channelnameFilterList" [value]="CLname.channelName">
              {{CLname.channelName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="customform" appearance="legacy">
          <mat-label>Corporate Brand</mat-label>
          <input type="text" id="Searchcorporatebrand" matInput [matAutocomplete]="autoCB"
            formControlName="corporateBrandName" [(ngModel)]="corporateBrandName"
            (ngModelChange)="filterItemCorporateBrand($event)">
          <mat-autocomplete autoActiveFirstOption #autoCB="matAutocomplete">
            <mat-option *ngFor="let CBname of corporateBrandFilterList" [value]="CBname.corporateBrandName" [matTooltipDisabled]="CBname.corporateBrandName.length<8"  matTooltip={{CBname.corporateBrandName}} matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
              {{CBname.corporateBrandCode}} {{CBname.corporateBrandName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="customform" appearance="legacy">
          <mat-label>Departments</mat-label>
          <input type="text" id="DepartmentName" matInput [matAutocomplete]="autoDP" formControlName="departmentName"
            (ngModelChange)="filterdepartment($event)">
          <mat-autocomplete autoActiveFirstOption #autoDP="matAutocomplete">
            <mat-option *ngFor="let DPname of departmentFilterList" [value]="DPname.departmentName"  [matTooltipDisabled]="DPname.departmentName.length<8"  matTooltip={{DPname.departmentName}} matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
              {{DPname.departmentCode}}  {{DPname.departmentName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <!-- <mat-form-field class="customform" appearance="legacy">
          <mat-label>Section</mat-label>
          <input type="text" id="SectionName" matInput [matAutocomplete]="autoSNN" formControlName="sectionName"
            (ngModelChange)="filtersectionName($event)">
          <mat-autocomplete autoActiveFirstOption #autoSNN="matAutocomplete">
            <mat-option *ngFor="let SNname of sectionFilterList" [value]="SNname.sectionName">
              {{SNname.sectionName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field> -->
        <mat-form-field [floatLabel]="'always'" appearance="legacy" class="statusclass">
          <mat-label>Status</mat-label>
          <mat-select id="Searchstatus" formControlName="status" [(ngModel)]="status">
            <mat-option value="all">
              All
            </mat-option>
            <mat-option value="active">
              Active
            </mat-option>
            <mat-option value="inactive">
              Inactive
            </mat-option>
            <mat-option value="draft">
              Draft
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-button class="add-button-container mat-raised-button mat-primary ml-2" 
        id="searchClear" type="submit" (click)="onSearchClear()" >Clear</button>
      </form>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="tblleft">
    <app-southernhemispherelist [southernHemisphereSearch]=_searchform.value></app-southernhemispherelist>
  </div>
  <div class="frmright">
    <app-southernhemisphereform #earlierplanningform (dropDownCollections)="dropDownCollections($event)">
    </app-southernhemisphereform>
  </div>
</div>
