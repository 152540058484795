<mat-form-field [floatLabel]="(isDepartmentSelect === true) ? 'always' : 'standard'" [formGroup]="_departmentForm"
    appearance="legacy">
    <mat-label>Departments</mat-label>
    <span [ngClass]="isDepartmentSelect === false  ? 'InActiveFont' : 'ActiveFont'">{{getSelectedItems() |
        slice:0:40}}<span *ngIf="getSelectedItems().length>40">&nbsp;....</span>&nbsp;&nbsp;<span style="float:right" class=spinner *ngIf="loaderService.isLoading$ | async">
            <mat-spinner [diameter]="20"></mat-spinner>
        </span></span>
    <mat-select autoActiveFirstOption formControlName="departmentIds">
        <mat-form-field class="depsearch">
            <input #search autocomplete="off" placeholder="Search Departments" aria-label="Search" matInput
                [formControl]="searchTextboxControl" [(ngModel)]="searchString">
            <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearSearch($event)">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-checkbox class="checklist-leaf-node custom-All" formControlName="allDpt" (change)="checkAll()">All
        </mat-checkbox>
        <mat-option [ngStyle]="{display :'none'}"></mat-option>
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding 
            [style.display]=" filterLeafNode(node) ? 'none' : 'block'">
                <button mat-icon-button disabled></button>
                <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
                    (change)="todoLeafItemSelectionToggle(node,$event)">
                    {{node.item.split(',')[0]}}-{{node.item.split(',')[1]}}</mat-checkbox>
            </mat-tree-node>
            <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
            [style.display]="filterParentNode(node) ? 'none' : 'block'">
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                </button>
                <mat-checkbox [checked]="descendantsAllSelected(node)"
                    [indeterminate]="descendantsPartiallySelected(node)"
                    (change)="todoItemSelectionToggle(node,$event)">
                    {{node.item.split(',')[0]}}-{{node.item.split(',')[1]}}</mat-checkbox>
            </mat-tree-node>
        </mat-tree>
    </mat-select>
</mat-form-field>