<div class="data-table subtable">
  <table id="tblDepCustomCustomerGroup" [dataSource]="dataSource" #table mat-table class="full-width-table" matSort
    aria-label="Elements">
    <ng-container matColumnDef="customCustomerGroupName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-color" id="depCCGColumn">Custom Customer Group
      </th>
      <td mat-cell *matCellDef="let row">
        {{row.customCustomerGroupName}}
      </td>
    </ng-container>
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef class="custom-color" id="depCCGActionsColumn">Delete</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon color="primary" (click)="deleteCustomCustomerGroup(row.departmentCustomCustomerGroupInfoId)"
          matTooltipPosition="right" style="background-color: rgb(255 255 255 / 0%);">
          delete_outline
        </mat-icon>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="8" style="
       text-align: center;">
        No records found
      </td>
    </tr>
  </table>
</div>
<department-customcustomergroupform id="customcustomergroupform" (customergroupcall)="receivedChildHandlerCCG()"
  [departmentDraft]="departmentDraft" [parentDepartmentId]=parentDepartmentId
  [customCustomerGrouplist]=customCustomerGrouplist></department-customcustomergroupform>