
import { Component, OnInit, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { FiscalcountryService } from 'src/app/services/country/fiscalcountry/fiscalcountry.service';
import { AccountService } from 'src/app/shared/services/account.service';
import { State } from '../../../../../models/enum';
import { ProducttypeandgroupComponent } from '../producttypeandgroup/producttypeandgroup.component';
import { DialogBoxService } from 'src/app/shared/services/dialog-box.service';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';
export interface iVatExceptionAll {
  vatExId: any;
  vat: any;
  validTo: any; validFrom: any;
  description: any;
  isFirstDraft: any;
}
export class productTypeGroup {
  productTypeGroupId: any;
  productTypeIds: any;

}
@Component({
  selector: 'app-vatexception',
  templateUrl: './vatexception.component.html',
  styleUrls: ['./vatexception.component.css']
})
export class VatexceptionComponent implements OnInit {
  @Output() isVatExFormValid: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('clearproducts') public clearproducts: ProducttypeandgroupComponent;
  @ViewChild(MatTable) table: MatTable<iVatExceptionAll>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('selectTOC') selectTOC: MatSelect;
  @ViewChild('selectCCG') selectCCG: MatSelect;
  @ViewChild('selectCMG') selectCMG: MatSelect;
  updatefcvatForm: any;
  vatForm = true;
  rows: any;
  vatControl = new FormControl();
  vat: any;
  fromDate: any;
  toDate: any;
  typeOfConstructionIds: any;
  customsCustomerGroupIds: any;
  productTypeGroupIds: any;
  customsMaterialGroupIds: any;
  description: any;
  lastUpdatedBy: any;
  errorMsg: any;
  update_fcvatExForm: any;
  isAddRow: boolean = false;
  sortByColumnName: any = "vat";
  sortByOrder: any = "asc";
  displayedColumns: any;
  selectedproductTypegroups: any[] = [];
  typeofConstructions = new FormControl();
  customCustomergroups = new FormControl();
  productTypegroups = new FormControl();
  customMaterialgroups = new FormControl();
  typeOfConMasterList: any[] = [];
  typeOfConFilterList: any[] = [];
  customsMatMasterList: any[] = [];
  customsMatFilterList: any[] = [];
  customsCustFilterList: any;
  customsCustMasterList: any;
  tempRowIndex: number = 0;
  TotalVatExLength: number = 0;
  isDelete: boolean = false;
  fiscalCountryVATExceptionId: any = 0;
  vatExceptionStatus: any = 0;
  isEditFcVatEx: boolean = false;
  isFiscalCountryDraft: boolean;
  vatExStatus: any = null;
  rowIndex: any;
  objCollection: any;
  dataSource: MatTableDataSource<iVatExceptionAll>;
  allSelectedTOC = false;
  allSelectedCCG = false;
  allSelectedCMG = false;
  isFirstDraft: boolean = false;
  userrole: boolean;

  //===================
  productGroupId: any;
  productTypeId: any;
  myArray = [];
  constructor(
    public _fiscalcountryService: FiscalcountryService,
    public _dialogService: DialogBoxService,
    private toastr: ToastrService,
    private applicationInsightService: ApplicationInsightService,
    public account: AccountService) {
    this.rows = [];
    this.updatefcvatForm = Object.assign({ active: true, draft: true });
  }
  ngOnInit(): void {
    this.displayedColumns = ["vat", "fromDate", "toDate", "description", "Edit", "Delete"];
    this.fromDate = new FormControl(new Date());
    this.toDate = new FormControl(new Date());
    this.account.getUserInfo();
    let list = this.account.userInfo.Role;
    let rolelist = list.split(",");

    this.userrole = rolelist.length <= 1 ? rolelist[0] == 'sds.Reader' ? true : false : false;
    if (rolelist.length > 1) {
      for (let i = 0; i < rolelist.length; i++) {
        if (rolelist[i] != 'sds.Reader') {
          this.userrole = false;
        }
      }
    }
    this.dataSource = new MatTableDataSource([]);
    this._fiscalcountryService.getTypeOfConstructionALL().subscribe((data: any) => {
      this.typeOfConMasterList = this.typeOfConFilterList = data.typeOfConstructions;
      this.typeOfConFilterList = this.typeOfConMasterList.sort((a, b) => a.typeOfConstructionName.localeCompare(b.typeOfConstructionName).toString());
      this.typeofConstructions = new FormControl(this.typeOfConFilterList);
    });
    this._fiscalcountryService.getCustomMaterialGroupALL().subscribe((data: any) => {
      this.customsMatMasterList = this.customsMatFilterList = data.customMaterialGroups;
      this.customsMatFilterList = this.customsMatMasterList.sort((a, b) => a.customsMaterialGroupName.localeCompare(b.customsMaterialGroupName).toString());
      this.customMaterialgroups = new FormControl(this.customsMatFilterList);
    });
    this._fiscalcountryService.getCustomCustomGroupALL().subscribe((data: any) => {
      this.customsCustMasterList = this.customsCustFilterList = data.departmentCustomCustomerGroup;
      this.customsCustFilterList = this.customsCustMasterList.sort((a, b) => a.customsCustomerGroupName.localeCompare(b.customsCustomerGroupName).toString());
      this.customCustomergroups = new FormControl(this.customsCustFilterList);
    });

  }
  receivedChildHandler(emitproductType: any) {
    this.applicationInsightService.logEvent("VATException receivedChildHandler Started");
    this.objCollection = emitproductType;
    this.applicationInsightService.logEvent("VATException receivedChildHandler Ended");
  }
  public validateVatExChildForm(data: any) {
    if (data === true) {
      if (this._fcvatExForm.valid === true) {
        this.isVatExFormValid.emit(true);
      } else {
        this.isVatExFormValid.emit(false);
      }
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    this.onClear();
  }
  onAddRow() {
    this.applicationInsightService.logEvent("VATException Add row Started");
    let isvalid = true;
    let resutypeOfConstId = "";
    let resultcustMaterialGrpId = "";
    let resutCustomerGroupId = "";
    if (this.objCollection == -1) {
      this.objCollection = '';
    }

    this._fcvatExForm.controls.vat.updateValueAndValidity();
    this._fcvatExForm.controls.fromDate.updateValueAndValidity();
    this._fcvatExForm.controls.typeOfConstructionIds.updateValueAndValidity();
    this._fcvatExForm.controls.customsCustomerGroupIds.updateValueAndValidity();
    this._fcvatExForm.controls.customsMaterialGroupIds.updateValueAndValidity();
    if (this.clearproducts._vatExForm != null || undefined) {
      this.clearproducts._vatExForm.controls.productTypeGroupIds.updateValueAndValidity();
    }
    /* istanbul ignore if */
    if (this._fcvatExForm.valid && this._fcvatExForm.controls.vat.value != '' && this._fcvatExForm.controls.fromDate.value != '') {
      this.fnvalidationforproducttype();
      let todaysdate = new Date().getFullYear();
      if (this.rows.length >= 1 && todaysdate > new Date(this._fcvatExForm.controls.fromDate.value).getFullYear()) {
        if (this._fcvatExForm.controls.toDate.value == null || this._fcvatExForm.controls.toDate.value == '') {
          this._fcvatExForm.get('toDate').setValidators(Validators.required);
          this._fcvatExForm.controls['toDate'].updateValueAndValidity();
          isvalid = false;
        }
      }
      else {
        this._fcvatExForm.controls.toDate.clearValidators();
      }
      if (this.rows.length >= 1) {
        var tempTOP = new Array();
        var tempCCG = new Array();
        var tempCMG = new Array();
        var tempPTG = new Array();
        let z = 0, y = 0, x = 0, w = 0;
        let ptgids: any = "";
        for (var b = 0; b < this.rows.length; b++) {
          if (this.rows[b].tempRowIndex == undefined || this.rows[b].tempRowIndex != this._fcvatExForm.controls.tempRowIndex.value) {
            z = y = x = w = 0;
            tempTOP = this.rows[b].typeOfConstructionIds.split(",");
            for (var a in tempTOP) {
              tempTOP[a] = parseInt(tempTOP[a], 10);
            }
            if (this.typeOfConstructionIds.length == tempTOP.length) {
              for (var i in tempTOP) {
                for (var j in this.typeOfConstructionIds) {
                  if (this.typeOfConstructionIds[j] == tempTOP[i])
                    z += 1;
                }
              }
            }
            //customer custome group
            tempCCG = this.rows[b].customsCustomerGroupIds.split(",");
            for (var a in tempCCG) {
              tempCCG[a] = parseInt(tempCCG[a], 10);
            }
            if (this.customsCustomerGroupIds.length == tempCCG.length) {
              for (var i in tempCCG) {
                for (var j in this.customsCustomerGroupIds) {
                  if (this.customsCustomerGroupIds[j] == tempCCG[i])
                    y += 1;
                }
              }
            }
            //customer material group
            tempCMG = this.rows[b].customsMaterialGroupIds.split(",");
            for (var a in tempCMG) {
              tempCMG[a] = parseInt(tempCMG[a], 10);
            }
            /* istanbul ignore if */
            if (this.customsMaterialGroupIds.length == tempCMG.length) {
              for (var i in tempCMG) {
                for (var j in this.customsMaterialGroupIds) {
                  if (this.customsMaterialGroupIds[j] == tempCMG[i])
                    x += 1;
                }
              }
            }
            ptgids = this.objCollection.productTypeIds.split(",");
            for (var a in ptgids) {
              ptgids[a] = parseInt(ptgids[a], 10);
            }
            let newvalue='';
            for (let x in this.rows[b].productTypeGroupIds){
              newvalue += this.rows[b].productTypeGroupIds[x].productTypeIds.replace(/,\s*$/, "")+',';
          }
          tempPTG = newvalue.replace(/,\s*$/, "").split(',');
            for (var a in tempPTG) {
              tempPTG[a] = parseInt(tempPTG[a], 10);
            }
          
            if (ptgids.length == tempPTG.length) {
              for (var i in tempPTG) {
                for (var j in ptgids) {
                  if (ptgids[j] == tempPTG[i])
                    w += 1;
                }
              }
            }

            if (z == this.typeOfConstructionIds.length && x == this.customsMaterialGroupIds.length && y == this.customsCustomerGroupIds.length && w == ptgids.length) {
              isvalid = false;
              this.toastr.error("Vat Exception Combination Already Exist", "", { timeOut: 10000 });
              break;
            }

          }
        }
      }
      if (isvalid) {
        this.getProductTypeGroup()
        if (this.typeOfConstructionIds != null || undefined) {
          for (let i = 0; i < this.typeOfConstructionIds.length; i++) {
            var typeOfConstructionId = this.typeOfConstructionIds[i];
            resutypeOfConstId = resutypeOfConstId + "," + typeOfConstructionId;
          }
        }
        resutypeOfConstId = resutypeOfConstId.replace(/^,/, '');

        if (this.customsMaterialGroupIds != null || undefined) {
          for (let i = 0; i < this.customsMaterialGroupIds.length; i++) {
            var customsMaterialGroupId = this.customsMaterialGroupIds[i];
            resultcustMaterialGrpId = resultcustMaterialGrpId + "," + customsMaterialGroupId;
          }
        }
        resultcustMaterialGrpId = resultcustMaterialGrpId.replace(/^,/, '');
        if (this.customsCustomerGroupIds != null || undefined) {
          for (let i = 0; i < this.customsCustomerGroupIds.length; i++) {
            var customsCustomerGroupId = this.customsCustomerGroupIds[i];
            resutCustomerGroupId = resutCustomerGroupId + "," + customsCustomerGroupId;
          }
        }
        resutCustomerGroupId = resutCustomerGroupId.replace(/^,/, '');
        this.vat = this._fcvatExForm.controls.vat.value;
        this.fromDate = this._fcvatExForm.controls.fromDate.value;
        this.fromDate = this._fiscalcountryService.fnToConvertDate(this.fromDate);
        this.toDate = this._fcvatExForm.controls.toDate.value == '' ? null : this._fcvatExForm.controls.toDate.value;
        this.toDate = this.toDate!=null ? this._fiscalcountryService.fnToConvertDate(this.toDate) : this.toDate;
        this.description = this._fcvatExForm.controls.description.value;
        this.typeOfConstructionIds = resutypeOfConstId;
        this.customsCustomerGroupIds = resutCustomerGroupId;
        this.customsMaterialGroupIds = resultcustMaterialGrpId;
        this.lastUpdatedBy = this.account.userInfo.Email;
        if (this.rows.length != 0) {
          this.rows = this._fiscalcountryService.updateValidTo(this.rows, this._fcvatExForm);
        }
        if (this._fcvatExForm.controls.fiscalCountryVATExceptionId.value > 0 || this._fcvatExForm.controls.tempRowIndex.value > 0) {
          this.vatExceptionStatus = State.change;
          if (this.vatExceptionStatus = 1) {
            this.isFirstDraft = true;
          }
          let index = this._fcvatExForm.controls.fiscalCountryVATExceptionId.value > 0 ? this.rows.findIndex(d => d.fiscalCountryVATExceptionId === this._fcvatExForm.controls.fiscalCountryVATExceptionId.value)
            : this.rows.findIndex(d => d.tempRowIndex === this._fcvatExForm.controls.tempRowIndex.value); //find index in your array
          this.rows[index].fromDate = this.fromDate;
          this.rows[index].toDate = this.toDate;
          this.rows[index].lastUpdatedBy = this.lastUpdatedBy;
          this.rows[index].vat = this.vat;
          this.rows[index].isFirstDraft = this.isFirstDraft;
          this.rows[index].fiscalCountryVATExceptionId = this._fcvatExForm.controls.fiscalCountryVATExceptionId.value;
          this.rows[index].typeOfConstructionIds = this.typeOfConstructionIds;
          this.rows[index].customsCustomerGroupIds = this.customsCustomerGroupIds;
          this.rows[index].customsMaterialGroupIds = this.customsMaterialGroupIds;
          this.rows[index].productTypeGroupIds = this.productTypeGroupIds;
          this.rows[index].description = this.description;
          if (this.rows.length != 0)
            this.rows = this._fiscalcountryService.updateValidTo(this.rows, this._fcvatExForm);
        }
        else {
          this.vatExceptionStatus = this.isEditFcVatEx == true ? State.change : State.nochange;
          if (this.rows.length != 0)
            this.rows = this._fiscalcountryService.updateValidTo(this.rows, this._fcvatExForm);
          if (this.vatExceptionStatus = 1) {
            this.isFirstDraft = true;
          }
          this.rows.push(this.createVatExFormGroup());
        }
        this.dataSource = new MatTableDataSource(this.rows);
        this.onClear();
      }
    }
    else {
      this.fnvalidationforproducttype();
    }
    this.applicationInsightService.logEvent("VATException Add row Ended");
  }
  /* istanbul ignore next */
  getProductTypeGroup() {
    let productType: any = 0;
    let groupId: any = 0;
    let typeId: any = "";
    let parenttemp: any = 0;
    let childLength: any = 0;
    let i: any;
    let knowledgeData = this.objCollection.datasource;
    let temp = this.objCollection.productTypeIds.split(",");
    for (var a in temp) {
      temp[a] = parseInt(temp[a], 10);
    }
    for (i = 0; i < temp.length; i++) {
      productType = temp[i];
      let result = knowledgeData.filter(cl => cl.children.some(c => c.item.split(',')[1] == productType));
      groupId = result[0].item.split(',')[1];
      typeId += productType;
      typeId += ',';
      if (parenttemp != 0 && parenttemp != undefined && parenttemp != groupId) {
        typeId = "" + productType + ',';
        this.push();
      }
      parenttemp = groupId;
      this.productGroupId = groupId;

      this.productTypeId = typeId;

    }
    if (this.objCollection.productTypeIds.split(',').length == i) {
      this.push();
    }

  }

  push() {
    let commentData = {} as productTypeGroup;
    commentData.productTypeGroupId = this.productGroupId;
    commentData.productTypeIds = this.productTypeId;
    this.myArray.push(commentData);
    this.productTypeGroupIds = this.myArray;
  }
  //validating product type
  fnvalidationforproducttype() {
    if (this.productTypeGroupIds != -1) {
      if (this.productTypeGroupIds == '' || this.productTypeGroupIds == null || this.productTypeGroupIds == undefined) {
        if (this.clearproducts._vatExForm != null || '') {
          this.clearproducts._vatExForm.markAllAsTouched();
          this.clearproducts._vatExForm.controls.productTypeGroupIds.setValidators([Validators.required]);
          this.clearproducts._vatExForm.controls['productTypeGroupIds'].updateValueAndValidity();
        }
      }
      else {
        if (this.clearproducts._vatExForm != null || '') {
          this.clearproducts._vatExForm.controls.productTypeGroupIds.clearValidators();
          this.clearproducts._vatExForm.controls.productTypeGroupIds.setValue('');
        }
      }
    }

  }
  //While clicking Edit button load respective data to entry page
  /* istanbul ignore next */
  updateRecord(rowIndex: any) {
    this.onClear();
    this.applicationInsightService.logEvent("VATException Edit by id Started");
    this._fcvatExForm.controls.vat.setValue(rowIndex.vat);
    this._fcvatExForm.controls.fromDate.setValue(rowIndex.fromDate);
    this._fcvatExForm.controls.toDate.setValue(rowIndex.toDate);
    this._fcvatExForm.controls.description.setValue(rowIndex.description);
    this._fcvatExForm.controls.fiscalCountryVATExceptionId.setValue(rowIndex.fiscalCountryVATExceptionId);
    this._fcvatExForm.controls.tempRowIndex.setValue(rowIndex.tempRowIndex);
    this._fcvatExForm.controls.description.setValue(rowIndex.description);
    this.clearproducts.updateProductTypeandGroup(rowIndex.productTypeGroupIds);
    this.clearproducts.getSelectedItems();
    if (rowIndex.productTypeGroupIds != null) {
      if (rowIndex.productTypeGroupIds.length == this.objCollection.datasource.length) {
        this.clearproducts._vatExForm.controls.allPTG.setValue(true);
      }
      else {
        this.clearproducts._vatExForm.controls.allPTG.setValue(false);
      }
    }
    this.typeOfConstructionIds = this.customsCustomerGroupIds = this.customsMaterialGroupIds = [];
    var temp = new Array();
    temp = rowIndex.typeOfConstructionIds.split(",");
    for (var a in temp) {
      temp[a] = parseInt(temp[a], 10);
    }
    this.typeOfConstructionIds = temp;
    this.typeOfConstructionIds.length == this.typeOfConMasterList.length ? this._fcvatExForm.controls.allTOC.setValue(true) : this._fcvatExForm.controls.allTOC.setValue(false);
    temp = [];
    temp = rowIndex.customsCustomerGroupIds.split(",");
    for (var a in temp) {
      temp[a] = parseInt(temp[a], 10);
    }
    this.customsCustomerGroupIds = temp;
    this.customsCustomerGroupIds.length == this.customsCustMasterList.length ? this._fcvatExForm.controls.allCCG.setValue(true) : this._fcvatExForm.controls.allCCG.setValue(false);
    temp = [];
    temp = rowIndex.customsMaterialGroupIds.split(",");
    for (var a in temp) {
      temp[a] = parseInt(temp[a], 10);
    }
    this.customsMaterialGroupIds = temp;
    this.customsMaterialGroupIds.length == this.customsMatMasterList.length ? this._fcvatExForm.controls.allCMG.setValue(true) : this._fcvatExForm.controls.allCMG.setValue(false);
    this._fcvatExForm.controls.typeOfConstructionIds.setValue(this.typeOfConstructionIds);
    this._fcvatExForm.controls.customsCustomerGroupIds.setValue(this.customsCustomerGroupIds);
    this._fcvatExForm.controls.customsMaterialGroupIds.setValue(this.customsMaterialGroupIds);
    this.applicationInsightService.logEvent("VATException Edit by id Ended");
  }
  onRemoveRow(rowIndex: any) {
    this.applicationInsightService.logEvent("VATException Delete Started");
    let index = rowIndex.fiscalCountryVATExceptionId > 0 ? this.rows.findIndex(d => d.fiscalCountryVATExceptionId === rowIndex.fiscalCountryVATExceptionId) : this.rows.findIndex(d => d.fromDate === rowIndex.fromDate); //find index in your array
    this.vatExceptionStatus = rowIndex.fiscalCountryVATExceptionId > 0 ? State.change : State.nochange;
    this.rows.splice(index, 1);
    this.dataSource = new MatTableDataSource(this.rows);
    this.vatExceptionStatus = (this.rows.length == 0 && this.TotalVatExLength > 0) ? State.delete : this.vatExceptionStatus;
    this.applicationInsightService.logEvent("VATException Delete Ended");
  }
  createVatExFormGroup() {
    return {
      vat: this.vat,
      fromDate: this.fromDate,
      toDate: this.toDate,
      description: this.description,
      lastUpdatedBy: this.lastUpdatedBy,
      typeOfConstructionIds: this.typeOfConstructionIds,
      customsCustomerGroupIds: this.customsCustomerGroupIds,
      productTypeGroupIds: this.productTypeGroupIds,
      customsMaterialGroupIds: this.customsMaterialGroupIds,
      fiscalCountryVATExceptionId: this.fiscalCountryVATExceptionId,
      tempRowIndex: (this.tempRowIndex = this.tempRowIndex + 1),
      isDelete: true,
      isFirstDraft: this.isFirstDraft
    };
  }
  onClear() {
    this.applicationInsightService.logEvent("VATException Clear Started");
    this._fcvatExForm.controls.vat.clearValidators();
    this._fcvatExForm.controls.fromDate.clearValidators();
    this._fcvatExForm.controls.toDate.clearValidators();
    this._fcvatExForm.controls.typeOfConstructionIds.clearValidators();
    this._fcvatExForm.controls.customsCustomerGroupIds.clearValidators();
    this._fcvatExForm.controls.customsMaterialGroupIds.clearValidators();
    this._fcvatExForm.controls.vat.setValue('');
    this._fcvatExForm.controls.fromDate.setValue('');
    this._fcvatExForm.controls.toDate.setValue('');
    this._fcvatExForm.controls.description.setValue('');
    this._fcvatExForm.controls.typeOfConstructionIds.setValue('');
    this._fcvatExForm.controls.customsCustomerGroupIds.setValue('');
    this._fcvatExForm.controls.productTypeGroupIds.setValue('');
    this._fcvatExForm.controls.customsMaterialGroupIds.setValue('');
    this._fcvatExForm.controls.fiscalCountryVATExceptionId.setValue('');
    this._fcvatExForm.controls.tempRowIndex.setValue('');
    /* istanbul ignore if */
    if (this.clearproducts._vatExForm != null || "") {
      this.clearproducts._vatExForm.controls.productTypeGroupIds.clearValidators();
      this.clearproducts._vatExForm.controls.productTypeGroupIds.setValue('');
      this.clearproducts._vatExForm.controls['productTypeGroupIds'].updateValueAndValidity();
      this.clearproducts._vatExForm.controls.allPTG.setValue(false);
      this.clearproducts.clearAll();
      this.productTypeGroupIds='';
      this.myArray = [];
    }
    this.productTypeGroupIds = '';
    this.typeofConstructions.reset();
    this.customCustomergroups.reset();
    this.productTypegroups.reset();
    this.customMaterialgroups.reset();
    this.fnSetControlsValidation();
    //if (this._fcvatExForm.controls != undefined) {
      this._fcvatExForm.controls.allTOC.setValue(false);
      this._fcvatExForm.controls.allCCG.setValue(false);
      this._fcvatExForm.controls.allCMG.setValue(false);
    //}
    this.typeOfConstructionIds = [];
    this.customsCustomerGroupIds = [];
    this.customsMaterialGroupIds = [];
    this.allSelectedCMG = false;
    this.allSelectedCCG = false;
    this.allSelectedTOC = false;
    this.applicationInsightService.logEvent("VATException Clear Ended");
  }

  //to clear after saving from parent component
  clearScope() {
    this.rows = [];
    this.dataSource = new MatTableDataSource([]);
    this.tempRowIndex = 0;
    this.fnSetControlsValidation();
  }

  toggleAllSelection(allSelected: boolean, field: string) {
    this.applicationInsightService.logEvent("VATException Toggle Started");
    if (allSelected) {
      if (field == 'toc') {
        this.allSelectedTOC = false;
        this.selectTOC.options.forEach((item: MatOption) => item.deselect());
      }
      /* istanbul ignore  else if  */
      else if (field == 'ccg') {
        this.allSelectedCCG = false;
        this.selectCCG.options.forEach((item: MatOption) => item.deselect());
      }
      /* istanbul ignore  else if  */
      else if (field == 'cmg') {
        this.allSelectedCMG = false;
        this.selectCMG.options.forEach((item: MatOption) => item.deselect());
      }

    } else {
      if (field == 'toc') {
        this.allSelectedTOC = true;
        this.selectTOC.options.forEach((item: MatOption) => item.select());
      }
      else if (field == 'ccg') {
        this.allSelectedCCG = true;
        this.selectCCG.options.forEach((item: MatOption) => item.select());
      }
      else if (field == 'cmg') {
        this.allSelectedCMG = true;
        this.selectCMG.options.forEach((item: MatOption) => item.select());
      }
    }
    this.applicationInsightService.logEvent("VATException Toggle Ended");
  }

  optionClick(field: string) {
    let newStatus = true;
    let i = 0;
    if (field == 'toc') {
      this.selectTOC.options.forEach((item: MatOption) => {
        if (!item.selected) {
          newStatus = false;
        }
        else i += 1;
      });
      this.allSelectedTOC = newStatus;
      i == this.typeOfConMasterList.length ? this._fcvatExForm.controls.allTOC.setValue(true) : this._fcvatExForm.controls.allTOC.setValue(false);
    }
    if (field == 'ccg') {
      this.selectCCG.options.forEach((item: MatOption) => {
        if (!item.selected) {
          newStatus = false;
        }
        else i += 1;
      });
      this.allSelectedCCG = newStatus;
      i == this.customsCustMasterList.length ? this._fcvatExForm.controls.allCCG.setValue(true) : this._fcvatExForm.controls.allCCG.setValue(false);
    }

    if (field == 'cmg') {
      this.selectCMG.options.forEach((item: MatOption) => {
        if (!item.selected) {
          newStatus = false;
        }
        else i += 1;
      });
      this.allSelectedCMG = newStatus;
      i == this.customsMatMasterList.length ? this._fcvatExForm.controls.allCMG.setValue(true) : this._fcvatExForm.controls.allCMG.setValue(false);
    }

  }
  fnFromDateValidation() {
    this.applicationInsightService.logEvent("VATException DateValidation Started");
    if (this._fcvatExForm.controls.fromDate.value != null && this._fcvatExForm.controls.fromDate.value != "") {
      let result = this._fiscalcountryService.fromDateValidatorfn(this.rows, "VATEXP", this._fcvatExForm.controls.fromDate);
      if (result == 'Invalid') {
        this._dialogService.openConfirmDialog('vat exp already exist  . Do you want to continue ?')
          .afterClosed().subscribe(res => {
            if (res) { }
            else {
              this._fcvatExForm.controls.fromDate.setValue('');
            }
          })
      }
      else if (result == 'NotAllowed') {
        this._fcvatExForm.get('fromDate').setValidators(Validators.required);
        this._fcvatExForm.controls['fromDate'].updateValueAndValidity();
        this._fcvatExForm.get('fromDate')?.setErrors({ invalidDate: 'Valid To already exists in selected vat %' });
      }
    }
    this.applicationInsightService.logEvent("VATException DateValidation Ended");
  }
  fnToDateValidation() {
    this.applicationInsightService.logEvent("VATException ToDateValidation Started");
    if (this._fcvatExForm.controls.fromDate.value != null && this._fcvatExForm.controls.toDate.value != "") {
      let result = this._fiscalcountryService.toDateValidatorfn(this.rows, "VATEXP", this._fcvatExForm.controls.toDate);
      if (result == 'Invalid') {
        this._fcvatExForm.get('toDate').setValidators(Validators.required);
        this._fcvatExForm.controls['toDate'].updateValueAndValidity();
        this._fcvatExForm.get('toDate')?.setErrors({ invalidDate: 'Valid To already exists in selected vat %' });
      }
      else if (result == 'Invaliddate') {
        this._fcvatExForm.get('toDate').setValidators(Validators.required);
        this._fcvatExForm.controls['toDate'].updateValueAndValidity();
        this._fcvatExForm.get('toDate')?.setErrors({ invalidDate: 'Valid To is greater than valid from' });

      }
    }
    this.applicationInsightService.logEvent("VATException ToDateValidation Ended");
  }

  _fcvatExForm: FormGroup = new FormGroup({
    fiscalCountryVATExceptionId: new FormControl(''),
    vat: new FormControl('', [Validators.required]),
    fromDate: new FormControl(null, Validators.required),
    toDate: new FormControl(null),
    description: new FormControl('', [Validators.maxLength(250)]),
    LastUpdatedBy: new FormControl(''),
    typeOfConstructionIds: new FormControl('', [Validators.required]),
    customsCustomerGroupIds: new FormControl('', [Validators.required]),
    productTypeGroupIds: new FormControl(''),
    customsMaterialGroupIds: new FormControl('', [Validators.required]),
    tempRowIndex: new FormControl(''),
    allTOC: new FormControl(false),
    allCCG: new FormControl(false),
    allCMG: new FormControl(false),
  })
  fnSetControlsValidation() {
    this._fcvatExForm.controls.vat.setValidators([Validators.required]);
    this._fcvatExForm.controls.fromDate.setValidators(Validators.required);
    this._fcvatExForm.controls.typeOfConstructionIds.setValidators(Validators.required);
    this._fcvatExForm.controls.customsCustomerGroupIds.setValidators(Validators.required);
    this._fcvatExForm.controls.customsMaterialGroupIds.setValidators(Validators.required);
  }
}
