import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';

@Component({
  selector: 'app-currencyMaster',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.css']
})
export class CurrencyMasterComponent implements OnInit {
  corporateBrandMasterList: any[] = [];
  corporateBrandFilterList: any[] = [];
  ISOCode: any = "";  
  currencyName : any=""; 
  HMCurrency:any=""; 
  status: any = "active";
  errorMsg: any;
  title ="Currency"  
  constructor( public route: ActivatedRoute,
    private applicationInsightService:ApplicationInsightService,
    private titleService:Title) { }

  ngOnInit(): void {this.titleService.setTitle(this.title);
  }

  receivedChildHandler(obj) 
  {    
    // this.corporateBrandFilterList = this.corporateBrandMasterList=obj.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
  }

  filterItemByISOCode(filterKey: string) 
  {
    // if (filterKey != null)
    // {
    //   this.corporateBrandFilterList =  this.corporateBrandMasterList.filter(cb => cb.corporateBrandName.toLowerCase().includes(filterKey.toLowerCase()));
    //   this.corporateBrandFilterList = this.corporateBrandFilterList.sort((a,b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));     
    // }
    // else
    // this.corporateBrandFilterList = this.corporateBrandMasterList.sort((a,b) =>  a.corporateBrandCode.localeCompare(b.corporateBrandCode));
  }
  filterItemBycurrencyName(filterKey: string) 
  {
    // if (filterKey != null)
    // {
    //   this.corporateBrandFilterList =  this.corporateBrandMasterList.filter(cb => cb.corporateBrandName.toLowerCase().includes(filterKey.toLowerCase()));
    //   this.corporateBrandFilterList = this.corporateBrandFilterList.sort((a,b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));     
    // }
    // else
    // this.corporateBrandFilterList = this.corporateBrandMasterList.sort((a,b) =>  a.corporateBrandCode.localeCompare(b.corporateBrandCode));
  }
  filterItemByHMCurrency(filterKey: string) 
  {
    // if (filterKey != null)
    // {
    //   this.corporateBrandFilterList =  this.corporateBrandMasterList.filter(cb => cb.corporateBrandName.toLowerCase().includes(filterKey.toLowerCase()));
    //   this.corporateBrandFilterList = this.corporateBrandFilterList.sort((a,b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));     
    // }
    // else
    // this.corporateBrandFilterList = this.corporateBrandMasterList.sort((a,b) =>  a.corporateBrandCode.localeCompare(b.corporateBrandCode));
  }
  //clear button click event
  onSearchClear() 
  {
    this.applicationInsightService.logEvent("Currency Search clear event started");
    this._searchform.controls['status'].setValue("active");   
    this.filterItemByISOCode("");
    this.filterItemBycurrencyName("");
    this.applicationInsightService.logEvent("Currency Search clear event ended");    
  }
  //corporatebrand  Search Form
  _searchform:FormGroup = new FormGroup(  {
    ISOCode: new FormControl(''),
    currencyName : new FormControl(''),
    HMCurrency :  new FormControl(''),
    status: new FormControl(),
  });

}
