import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SubindexService } from 'src/app/services/organization/subindex/subindex.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { DepartmentService } from 'src/app/services/organization/department/department.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SubIndexViewComponent } from './subindexview';
import { constant } from 'src/app/shared/const/constant';
import { ModulePermission } from '../../../../models/enum';



export interface ISubIndex {
  subIndexId: any;
  corporateBrandName: any;
  subIndexCode: any;
  subIndexSortOrder: any;
  active: any;
  draft: any;
  lastUpdatedTime: any;
}

@Component({
  selector: 'app-subindexlist',
  templateUrl: './subindexlist.component.html',
  styleUrls: ['./subindexlist.component.css']
})

export class SubindexlistComponent implements OnInit, OnChanges {
  @Input() corporateBrandSearch: string;
  @Input() subIndexCodeSearch: string;
  @Input() subIndexNameSearch: string;
  @Input() statusSearch: string;
  @Input() IndexCodeSearch: string;
  @Output() childButtonEvent = new EventEmitter();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // MatPaginator Inputs
  pageNumber: any = 1;
  length: any;
  pageSize: any = 15;
  SortOrderColumn: any = "SubIndexCode";
  sortByOrder: any = "asc";
  Serviceactive: any = true;
  ServiceDraft: any = false;
  // MatPaginator Output
  pageEvent: PageEvent;
  errorMsg: any;
  customDateTime: string;
  active: any;
  subscription: Subscription;
  public dataSource: MatTableDataSource<ISubIndex>;
  displayedColumns = [
    'corporateBrandName',
    'IndexCode',
    'subIndexName',
    'subIndexCode',
    'subIndexSortOrder',
    'lastUpdatedTime',
    'Actions'
  ];

  matDialogRef: MatDialogRef<SubIndexViewComponent>;
  userrole: boolean;
  users: any;
  constructor(
    public _SubindexService: SubindexService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public _departmentService: DepartmentService,
    private applicationInsightService: ApplicationInsightService
  ) { this.users = JSON.parse(localStorage.getItem("users") || "[]") }
  getpagesizeoptions(): number[] {
    if (this.length > 25)
      return [10, 20, 25, this.length];
    else if (this.length > 20)
      return [10, 20, this.length];
    else if (this.length > 10)
      return [10, this.length];
    return null;
  }

  ngOnInit(): void {
    this.userrole = this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this.subscription = this._SubindexService.refreshTableSubIndex$.subscribe(
      refreshTableSubIndex => {
        this.BindSubIndexData();
      });
  }
  public ngOnDestroy(): void {
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }
  ngOnChanges(changes: SimpleChanges) {
    this.pageNumber = 1;
    this.BindSubIndexData();
  }

  sortData(sort: Sort) {
    this.applicationInsightService.logEvent("Subindex sorting started");
    this.SortOrderColumn = sort.active != null ? sort.active.charAt(0).toUpperCase() + sort.active.slice(1) : sort.active;
    this.sortByOrder = (sort.direction == null || sort.direction == "") ? "desc" : sort.direction;
    this.BindSubIndexData();
    this.applicationInsightService.logEvent("Subindex sorting ended");
  }
  public GetSubindexDatawithfilters(event?: PageEvent) {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.BindSubIndexData();
    return event;
  }
  BindSubIndexData() {
    this.applicationInsightService.logEvent("Subindex sorting and listing started");
    let filterData: any[] = [];
    if (this.statusSearch != null) {
      if (this.statusSearch == "active") { this.Serviceactive = true; this.ServiceDraft = false; }
      if (this.statusSearch == "inactive") { this.Serviceactive = false; this.ServiceDraft = false; }
      if (this.statusSearch == "draft") { this.ServiceDraft = true; this.Serviceactive = ""; }
      if (this.statusSearch == "all") { this.ServiceDraft = ""; this.Serviceactive = ""; }
    }
    this._SubindexService.getSubIndexAll(this.pageNumber, this.pageSize, this.SortOrderColumn, this.sortByOrder, this.corporateBrandSearch == null ? "" : this.corporateBrandSearch, this.IndexCodeSearch == null ? "" : this.IndexCodeSearch, this.subIndexCodeSearch == null ? "" : this.subIndexCodeSearch, this.subIndexNameSearch == null ? "" : this.subIndexNameSearch, this.Serviceactive, this.ServiceDraft).subscribe((data: any) => {
      filterData = data.subIndex;
      this.dataSource = new MatTableDataSource(filterData);
      this.length = data.totalCount;
      this.sort.active = this.SortOrderColumn;
      this.sort.direction = this.sortByOrder;
      this.dataSource.sort = this.sort;
    },
      error => {
        this.errorMsg = error;
      });
    this.applicationInsightService.logEvent("Subindex sorting and listing ended");
  }
  //While clicking Edit button load respective data to entry page
  updateRecord(updateRowData: any) {
    this._SubindexService.getSubindexIDFromList(updateRowData.subIndexId);
  }

  openDialog(row: any) {
    let departmentList: any;
    let rowData: any;
    this._departmentService.getDepartmentBySubIndexIdandSeason(row.subIndexId, localStorage.getItem('isCurrentSeason')).subscribe((data: any) => {
      departmentList = data.department;
      this.matDialogRef = this.dialog.open(SubIndexViewComponent, {
        data: { departmentList: departmentList, rowData: row },
        disableClose: false
      });
    }, error => { this.toastr.error(error, "", { timeOut: constant.toasterTimeout }) });

    this.matDialogRef?.afterClosed()?.subscribe(res => {
      if ((res == true)) {
        departmentList = "";
      }
    });
  }
}

