<div class="mat-elevation-z8 add-form-field" *ngIf="depCustomCustomerGroupForm">
  <form [formGroup]="dptCustomCustomerGroupForm">
    <div class="example-container">
      <!-- <h3 mat-dialog-title>Custom Customer Group</h3> -->
      <input type="hidden" id="inpccgdepartmentid" formControlName="departmentId"
        [(ngModel)]="updateDepCustomCustomerGroupForm.departmentId">
      <input type="hidden" id="inpccgdepartmentcustomcustomergroupid" formControlName="departmentCustomCustomerGroupId"
        [(ngModel)]="updateDepCustomCustomerGroupForm.departmentCustomCustomerGroupId">
      <mat-form-field appearance="legacy" class="width-35">
        <mat-label>Custom Customer Group</mat-label>
        <input type="text" id="inpccgcustomcustomergroup" matInput [matAutocomplete]="autoCCG"
          (ngModelChange)="filterItemCCG($event)" formControlName="customCustomerGroupId"
          [(ngModel)]="updateDepCustomCustomerGroupForm.customsCustomerGroupId">
        <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
          <div *ngIf="dptCustomCustomerGroupForm.controls['customCustomerGroupId'].hasError(validation.type)">
            {{validation.message}}
          </div>
        </mat-error>
        <mat-autocomplete autoActiveFirstOption #autoCCG="matAutocomplete" [displayWith]="displayTextCCG.bind(this)">
          <mat-option *ngFor="let CCGname of customCustomerGroupFilterList" [value]="CCGname.customsCustomerGroupId">
            {{CCGname.customsCustomerGroupName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <br>
      <br>
      <div [style.display]="!dptCustomCustomerGroupForm.dirty==false ? 'block' : 'none'">
        <mat-dialog-actions *ngIf="!userrole">
          <button class="mat-raised-button mat-primary " id="ccgbtnsubmit" (click)="onSubmit()" type="submit">
            Save
          </button>
          <button class="mat-raised-button mat-primary ml-2" id="ccgbtncancel" (click)="onClear()">Cancel</button>
        </mat-dialog-actions>
      </div>
      <span *ngIf="userrole" style="color: #ff0202;font-size: 12px;font-weight: 400;">You can't save changes since you
        have Read access.</span>
    </div>
  </form>
</div>