import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CorporatebrandService } from 'src/app/services/organization/corporatebrand/corporatebrand.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogBoxService } from 'src/app/shared/services/dialog-box.service';
import { AccountService } from '../../../../shared/services/account.service'
import { Subscription } from 'rxjs';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { UserAccess } from 'src/app/models/userAccess';
import { ModulePermission } from 'src/app/models/enum';

@Component({
  selector: 'app-corporatebrandform',
  templateUrl: './corporatebrandform.component.html',
  styleUrls: ['./corporatebrandform.component.css']
})
export class CorporatebrandformComponent implements OnInit {
  updateCorporateBrandForm: any;
  isCorporateBrandEdit: any = false;
  isPublished: boolean = true;
  isActiveDisable: boolean = true;
  response: any;
  isEditCB: any = 'legacy';
  subscription: Subscription;
  userrole: boolean;
  users:any

  constructor(
    public _corporatebrandService: CorporatebrandService,
    private toastr: ToastrService,
    public _dialogService: DialogBoxService,
    private applicationInsightService: ApplicationInsightService,
    public account: AccountService,
    public _userAccess: UserAccess
  ) {
    this.updateCorporateBrandForm = Object.assign({ active: true, draft: true });
    this.users = JSON.parse(localStorage.getItem("users") || "[]");
  }

  ngOnInit() {
    this.userrole =this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this._corporateBrandform.controls.lastUpdatedBy.setValue(JSON.parse(localStorage.getItem('UserInfo')).Email);
    this.subscription =
      this._corporatebrandService.corporateBrandId$.subscribe(
        corporateBrandId => {
          this.updateCorporateBrand(corporateBrandId);
        });
  }
  public ngOnDestroy(): void {
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }
  updateCorporateBrand(corporateBrandId: any) {
    this.applicationInsightService.logEvent("CorporateBrand Edit GetCorporateBrandId Started");
    this.isCorporateBrandEdit = true;
    this.isActiveDisable = false;
    this._corporatebrandService.getCorporateBrandId(corporateBrandId)
      .subscribe((data: any) => {
        this.updateCorporateBrandForm = data;
        this.isEditCB = this.updateCorporateBrandForm.active == true ? 'fill' : 'legacy';
        this.isPublished = this.updateCorporateBrandForm.isPublished;
      });
    this.applicationInsightService.logEvent("CorporateBrand Edit GetCorporateBrandId Ended");
  }

  onSubmit() {
    this._corporateBrandform.controls['corporateBrandName'].updateValueAndValidity();
    this._corporateBrandform.controls['corporateBrandSortOrder'].updateValueAndValidity();
    console.log("Corporate Brand Insert: " + JSON.stringify(this._corporateBrandform.value))
    if (this._corporateBrandform.valid) {
      if (this.updateCorporateBrandForm.corporateBrandId === undefined || this.updateCorporateBrandForm.corporateBrandId === null) {
        this.applicationInsightService.logEvent("Create New Corporatebrand started");
        this._corporatebrandService.CreateCorporateBrand(this._corporateBrandform.value).subscribe(response => {
          this.toastr.success(response.message, "", { timeOut: 10000 });
          this.onClear();
          this._corporatebrandService.refreshCorporateBrandList();
        },
          error => { this.toastr.error(error, "", { timeOut: 10000 }) }
        )
        this.applicationInsightService.logEvent("Create New Corporatebrand ended");
      }
      else {
        this.applicationInsightService.logEvent("Update Corporatedbrand started");
        console.log("Corporate Brand Update: " + JSON.stringify(this._corporateBrandform.value))
        this._corporatebrandService.UpdateCorporatebrand(this._corporateBrandform.value).subscribe(response => {
          this.toastr.success(response.message, "", { timeOut: 10000 });
          this.onClear();
          this._corporatebrandService.refreshCorporateBrandList();
          this.applicationInsightService.logEvent("Update Corporatebrand ended");
        },
          error => { this.toastr.error(error, "", { timeOut: 10000 }) }
        )
      }
    }
  }
  deleteCorporateBrand() {
    this.applicationInsightService.logEvent("Delete Corporatebrand started");
    this._corporateBrandform.controls.corporateBrandId.setValue(this.updateCorporateBrandForm.corporateBrandId);
    this._dialogService.openConfirmDialog('Are you sure you want to delete this record?')
      .afterClosed().subscribe(res => {
        if (res) {
          this._corporatebrandService.deleteCorporatebrand(this._corporateBrandform.value).subscribe((response) => {
            this.toastr.success(response.message, "", { timeOut: 10000 });
            this.onClear();
            this._corporatebrandService.refreshCorporateBrandList();
          },
            error => { this.toastr.error(error, "", { timeOut: 10000 }) });
        }
      })
    this.applicationInsightService.logEvent("Delete Corporatebrand ended");
  }
  onClear() {
    this.applicationInsightService.logEvent("Corporatebrand clearform started");
    //Clearing validation 
    this._corporateBrandform.controls.corporateBrandId.clearValidators();
    this._corporateBrandform.controls.corporateBrandName.clearValidators();
    this._corporateBrandform.controls.corporateBrandSortOrder.clearValidators();
    //Clearing control values 
    this._corporateBrandform.controls.corporateBrandId.reset();
    this._corporateBrandform.controls.corporateBrandName.reset();
    this._corporateBrandform.controls.corporateBrandSortOrder.reset();
    this._corporateBrandform.controls.draft.setValue(true);
    this._corporateBrandform.controls.active.setValue(true);
    this._corporateBrandform.controls.corporateBrandName.setValidators([Validators.required, Validators.maxLength(30)]);
    this._corporateBrandform.controls.corporateBrandSortOrder.setValidators([Validators.required, Validators.min(1), Validators.max(9999)]);
    this.isCorporateBrandEdit = false;
    this.isActiveDisable = true;
    this.isEditCB = 'legacy';
    this.applicationInsightService.logEvent("Corporatebrand clearform ended");
  }
  //Create New corporate Brand Form Validation
  validPattern = "^[a-zA-Z0-9]+$";// alphanumeric exact 0 letters 
  _corporateBrandform: FormGroup = new FormGroup(
    {
      corporateBrandName: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      corporateBrandSortOrder: new FormControl('', [Validators.required, Validators.min(1), Validators.max(9999), Validators.pattern('^(?![-]).*')]),
      corporateBrandId: new FormControl(''),
      active: new FormControl(true),
      draft: new FormControl(true),
      lastUpdatedBy: new FormControl()
    });
}
