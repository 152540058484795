<div class="mat-elevation-z8 add-form-field">
  <form [formGroup]="_geocountryform" id="geographicalcountryform">
    <div class="example-container">
      <h2 mat-dialog-title>{{isGeoCountryEdit === true ? 'Update' : 'New'}} Geographical Country</h2>
      <mat-form-field [floatLabel]="'always'" appearance='outline' class="width-100">
        <mat-label>Geographical Country </mat-label>
        <input formControlName="geographicalCountryName" id=inpCountryName matInput [(ngModel)]="updateCountryForm.geographicalCountryName">
        <mat-error *ngIf="_geocountryform.controls['geographicalCountryName'].errors?.maxlength">
          Maximum 45 Characters
          allowed
        </mat-error>
      </mat-form-field>
      <div>
        <mat-form-field [floatLabel]="'always'" appearance='outline' class="width-48">
          <mat-label>ISO Alpha 2 Code </mat-label>
          <input formControlName="geographicalCountryAlpha2ISOCode" id=inpgeographicalCountryAlpha2ISOCode matInput [(ngModel)]="updateCountryForm.geographicalCountryAlpha2ISOCode">
          <mat-error *ngIf="_geocountryform.controls['geographicalCountryAlpha2ISOCode'].errors?.maxlength">Please enter a vaild input, Ex:- AB </mat-error>
        </mat-form-field>             &nbsp;
        <mat-form-field [floatLabel]="'always'" appearance='outline' class="width-48 ml-1">
          <mat-label>ISO Alpha 3 Code </mat-label>
          <input formControlName="geographicalCountryAlpha3ISOCode" id=inpgeographicalCountryAlpha3ISOCode matInput [(ngModel)]="updateCountryForm.geographicalCountryAlpha3ISOCode">
          <mat-error *ngIf="_geocountryform.controls['geographicalCountryAlpha3ISOCode'].errors?.maxlength">Please enter a vaild input, Ex:- ABC</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field [floatLabel]="'always'" appearance='outline' class="width-48">
          <mat-label>ISO Numeric Code </mat-label>
          <input formControlName="geographicalCountryNumericISOCode" id=inpnumbericCode [(ngModel)]="updateCountryForm.geographicalCountryNumericISOCode"
                 matInput>
          <mat-error *ngIf="_geocountryform.controls['geographicalCountryNumericISOCode'].errors?.max">The range for Numeric is 1-999. </mat-error>
          <mat-error *ngIf="_geocountryform.controls['geographicalCountryNumericISOCode'].errors?.pattern">Please enter a vaild input, Ex:- 001</mat-error>
        </mat-form-field> &nbsp;

        <mat-form-field [floatLabel]="'always'" appearance='outline' class="width-48 ml-1">
          <mat-label>Trade Union</mat-label>
          <mat-select id="tradeUnions" formControlName="tradeUnionId" [(ngModel)]="updateCountryForm.tradeUnionId">
            <mat-option *ngFor="let tradeUnion of tradeUnionList" [value]="tradeUnion.id">
              {{ tradeUnion.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <fieldset>
          <legend>
            <mat-checkbox formControlName="isFakeCountry" id=inpisFakeCountry [(ngModel)]="updateCountryForm.isFakeCountry"
                          [checked]=false (change)="activeFakeCountry()" class="example-margin width-15" color='primary'>Fake Country</mat-checkbox>
          </legend>
          <mat-form-field [floatLabel]="'always'" appearance='outline' class="width-100">
            <mat-label>Actual Geographical Country</mat-label>
            <input type="text" id="inpcountry" matInput [matAutocomplete]="autoGC"
                   formControlName="actualGeographicalCountryId" (ngModelChange)="filterItemGC($event)"
                   [(ngModel)]="updateCountryForm.actualGeographicalCountryId">
            <!-- <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
                <div *ngIf="_geocountryform.controls['actualGeographicalCountryId'].hasError(validation.type)">
                    {{validation.message}}</div>
            </mat-error>  -->
            <mat-autocomplete autoActiveFirstOption #autoGC="matAutocomplete"
                              [displayWith]="displayTextGC.bind(this)">
              <mat-option (onSelectionChange)="(updateCountryForm.actualGeographicalCountryId!=null || updateCountryForm.actualGeographicalCountryId!='')?updateCountryForm.actualGeographicalCountryId='':updateCountryForm.actualGeographicalCountryId"
                          *ngFor="let GCname of countryFilterList" [value]="GCname.geographicalCountryId">
                {{GCname.geographicalCountryName}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field [floatLabel]="'always'" appearance='outline' class="width-100">
            <mat-label>Fake Country Info </mat-label>
            <input formControlName="fakeCountryInfo" id=inpfakeinfor matInput [(ngModel)]="updateCountryForm.fakeCountryInfo">
            <mat-error *ngIf="displayErrorMsg">Please select a valid input. </mat-error>
          </mat-form-field>
        </fieldset>
      </div><br>
      <div>
        <section class="example-section">
          <mat-checkbox formControlName="hmCountry" id=inphmCountry [(ngModel)]="updateCountryForm.hmCountry"
                        class="example-margin width-45" color='primary'>HMCountry</mat-checkbox>

        </section>
        <br>
        <section class="example-section">
          <mat-checkbox formControlName="draft" id=inpcountrydraft [(ngModel)]="updateCountryForm.draft"
                        class="example-margin width-15" color='primary'>Draft</mat-checkbox>
        </section>
      </div>
      <input type="hidden" id=inpcountryid formControlName="geographicalCountryId" [(ngModel)]="updateCountryForm.geographicalCountryId">
      <br>
      <mat-dialog-actions *ngIf="userrole">
        <button [disabled]="(isGeoCountryEdit === true) ?  !_geocountryform.dirty : false"
                [ngClass]="(isGeoCountryEdit === true) ? 'mat-raised-button ml-1':'mat-raised-button ml-1 mat-primary'"
                id="btnSaveUpdate" type="submit" (click)="onSubmit()">
          {{isGeoCountryEdit === true ? 'Update' : 'Save' }}
        </button>
        <button class="mat-raised-button mat-primary ml-1" id="btnclear" (click)="onClear()">Cancel</button>
      </mat-dialog-actions>
      <span *ngIf="!userrole" style="color: #ff0202;font-size: 12px;font-weight: 400;">You can't save changes since you have Read access.</span>
    </div>
  </form>
</div>
