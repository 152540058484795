import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'seasonDate'
})
export class SeasonDatePipe extends
  DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    var convertDate = value;
    if(value!=undefined && value!=null && value!='')
    convertDate = new Date(value.substring(0, 4) + "-" + value.substring(4) + "-01");
    return super.transform(convertDate, "M-yyyy");
  }
}
