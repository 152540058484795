import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
  } from "@angular/common/http";
  import { Injectable } from "@angular/core";
  import { Observable, timer } from "rxjs";
  import { finalize } from "rxjs/operators";
  import { LoaderService } from "../../app/shared/services/loader.service";
  
  @Injectable()
  export class LoaderInterceptor implements HttpInterceptor {
    constructor(private loaderService: LoaderService) {}
  
    intercept(
      req: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {
      this.loaderService.isLoading$.next(true);
  
      return next.handle(req).pipe(
        finalize(() => {
          setTimeout(() => {
            this.loaderService.isLoading$.next(false);
          }, 3000);
        })
      );
    }
  }
  