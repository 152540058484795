import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonUtilityService } from 'src/app/services/organization/commonutility/commonutility.service';
import { AccountService } from '../../../../../shared/services/account.service';
import { MatTableDataSource } from '@angular/material/table';
import { FiscalcountryService } from '../../../../../services/country/fiscalcountry/fiscalcountry.service';
import { State } from '../../../../../models/enum';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
export interface iCurrency {
  fiscalCountryCurrencyId: any;
  currencyCode: any;
  fromSeasonNumber: any;
  toSeasonNumber: any;
  isDeleteFcCurrency: any;
  isFirstDraft: any;
}

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.css']
})
export class CurrencyComponent implements OnInit {

  @Output() isChildFormValid = new EventEmitter();
  dataSource: MatTableDataSource<iCurrency>;

  updatecurrencyForm: any;
  rows: any;
  fiscalCountryCurrencyId: any = 0;
  currencyCode: any;
  fromSeasonNumber: any;
  toSeasonNumber: any;
  lastUpdatedBy: any;
  validationMessages: any;
  validOptions;
  seasonDate: string;
  currencyFilterList: any[] = [];
  currencyMasterList: any[] = [];

  seasonList: any[] = [];
  fromSeasonMasterlist: any[];
  endSeasonMasterlist: any[];
  fromSeasonFilterList: any[];
  endSeasonFilterList: any[];
  tempSeasonList: any[];
  isEditFcCurrency: boolean = false;
  isDeleteFcCurrency: boolean = false;
  isFirstDraft: boolean = false;
  isFiscalCountryDraft: boolean = false;
  currencyStatus: any = 0;
  TotalCurrencyLength: number = 0;
  tempRowIndex: any = 0;
  displayedColumns: any;
  userrole: boolean;
  constructor(
    public _commonutilityService: CommonUtilityService,
    public _fiscalcountryService: FiscalcountryService,
    private toastr: ToastrService,
    private applicationInsightService: ApplicationInsightService,
    public account: AccountService, private fb: FormBuilder) {
    this.rows = [];
    this.updatecurrencyForm = Object.assign({ active: true, draft: true });

  }

  ngOnInit(): void {
    this.displayedColumns = ["currencyCode", "fromSeasonNumber", "toSeasonNumber", "Edit", "Delete"];
    this.validationMessages = this._commonutilityService.validationMsgs;
    this.account.getUserInfo();
    let list = this.account.userInfo.Role;
    let rolelist = list.split(",");

    this.userrole = rolelist.length <= 1 ? rolelist[0] == 'sds.Reader' ? true : false : false;
    if (rolelist.length > 1) {
      for (let i = 0; i < rolelist.length; i++) {
        if (rolelist[i] != 'sds.Reader') {
          this.userrole = false;
        }
      }
    }
    this.dataSource = new MatTableDataSource([]);
    this.GetSeasonList();
    this._fiscalcountryService.getCurrencyList().subscribe((data: any) => {
      this.currencyMasterList = this.currencyFilterList = data.currencies;
      this.currencyFilterList = this.currencyMasterList.sort((a, b) => a.currencyName.localeCompare(b.currencyName).toString());
    });

    this.validationMessages = this._commonutilityService.validationMsgs;
  }

  //to get season list

  GetSeasonList() {
    this.applicationInsightService.logEvent("Currency Get Season List Started");
    this._fiscalcountryService.getSeasonByRange(1).subscribe((data: any) => {
      this.seasonList = this.fromSeasonMasterlist = this.fromSeasonFilterList = data.seasonByRange;
      this.endSeasonMasterlist = this.endSeasonFilterList = this.tempSeasonList = data.seasonByRange;
      this.validOptions = this.fnGetRangeValues();

    });
    this._fiscalcountryService.getSeasonAll().subscribe((data: any) => {
      this.seasonList = data.seasonByRange;
      this.validOptions = this.fnGetRangeValues();
    });
    this.applicationInsightService.logEvent("Currency Get Season List Ended");
  }

  public validateChildForm(data: any) {
    if (data === true) {
      if (this._fccurrenciesForm.valid == true) {
        this.isChildFormValid.emit(true);
      }
      else {
        this.isChildFormValid.emit(false);
      }
    }
  }

  filterItemCurrencyName(filterKey: string) {
    this.applicationInsightService.logEvent("Currency Filter Currency Name Started");
    if (filterKey != null && filterKey != "") {
      this.currencyFilterList = this.currencyMasterList.filter(x => x.currencyName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
      this.currencyFilterList = this.currencyFilterList.sort((a, b) => a.currencyName.localeCompare(b.currencyName));
    } else
      this.currencyFilterList = this.currencyMasterList.sort((a, b) => a.currencyName.localeCompare(b.currencyName));
    this.applicationInsightService.logEvent("Currency Filter Currency Name Started");
  }

  displayTextCN(currencyCode: string) {
    if (currencyCode === null || currencyCode === "")
      return null;
    else if (this.currencyMasterList != undefined && currencyCode != null) {
      return this.currencyMasterList.
        find(x => x.currencyCode == currencyCode).currencyName;
    }
  }

  displayTextSeason(season: string) {
    if (season == null || season == "" || season == "209908")
      return null;
    else if (this.fromSeasonMasterlist != undefined && season != null)
      return this.fromSeasonMasterlist.find(x => x.seasonNumber === season).displayName;
  }

  filterStartSeason(filterKeySS: string) {
    this.applicationInsightService.logEvent("Currency Filter Start Season Started");
    if (filterKeySS != null && filterKeySS != "")
      this.fromSeasonFilterList = this.fromSeasonMasterlist.filter(rs => rs.displayName.toLowerCase().includes(filterKeySS.toString().toLowerCase()))
    else if (this.rows.length == 0)
      this.fromSeasonFilterList = this.fromSeasonMasterlist;
    else if (filterKeySS == null || filterKeySS == "")
      this.fromSeasonFilterList = this.tempSeasonList;
    this.applicationInsightService.logEvent("Currency Filter Start Season Ended");

  }
  filterendSeason(filterKeySS: string) {
    this.applicationInsightService.logEvent("Currency Filter End Season Started");
    if (filterKeySS != null && filterKeySS != "")
      this.endSeasonFilterList = this.endSeasonMasterlist.filter(rs => rs.displayName.toLowerCase().includes(filterKeySS.toString().toLowerCase()))
    else if (this.rows.length == 0)
      this.endSeasonFilterList = this.endSeasonMasterlist;
    else if (filterKeySS == null || filterKeySS == "")
      this.endSeasonFilterList = this.tempSeasonList;
    this.applicationInsightService.logEvent("Currency Filter End Season Ended");

  }

  onAddRow() {
    this.applicationInsightService.logEvent("Currency Temp row Started");
    this._fccurrenciesForm.controls.currencyCode.updateValueAndValidity();
    this._fccurrenciesForm.controls.fromSeasonNumber.updateValueAndValidity();
    /* istanbul ignore if */
    if (this._fccurrenciesForm.valid && this._fccurrenciesForm.controls.currencyCode.value != null && this._fccurrenciesForm.controls.currencyCode.value != '') {
      this.currencyCode = this._fccurrenciesForm.controls.currencyCode.value;
      this.fromSeasonNumber = this._fccurrenciesForm.controls.fromSeasonNumber.value;
      this.toSeasonNumber = this._fccurrenciesForm.controls.toSeasonNumber.value;
      this.lastUpdatedBy = this.account.userInfo.Email;
      if (this._fccurrenciesForm.controls.fiscalCountryCurrencyId.value > 0 || this._fccurrenciesForm.controls.tempRowIndex.value > 0) {
        this.currencyStatus = State.change;
        if (this.currencyStatus = 1) {
          this.isFirstDraft = true;
        }
        let index = this._fccurrenciesForm.controls.fiscalCountryCurrencyId.value > 0 ? this.rows.findIndex(d => d.fiscalCountryCurrencyId === this._fccurrenciesForm.controls.fiscalCountryCurrencyId.value)
          : this.rows.findIndex(d => d.tempRowIndex === this._fccurrenciesForm.controls.tempRowIndex.value); //find index in your array
        this.rows[index].fromSeasonNumber = this.fromSeasonNumber;
        this.rows[index].toSeasonNumber = this.toSeasonNumber;
        this.rows[index].lastUpdatedBy = this.lastUpdatedBy;
        this.rows[index].currencyCode = this.currencyCode;
        this.rows[index].isFirstDraft = this.isFirstDraft;
        this.rows[index].fiscalCountryCurrencyId = this._fccurrenciesForm.controls.fiscalCountryCurrencyId.value;
        this.UpdateRowList(this.rows.length);
        this.filteringDropDownSeason();
      }
      else {
        let rowindex = 0;
        this.currencyStatus = this.isEditFcCurrency == true ? State.change : State.nochange;
        if (this.currencyStatus = 1) {
          this.isFirstDraft = true;
        }
        this.rows.push(this.createCurrencyFormGroup());
        this.UpdateRowList(this.rows.length);
        this.filteringDropDownSeason();
      }
      this.dataSource = new MatTableDataSource(this.rows);
      this.onClear();

    }
    this.applicationInsightService.logEvent("Currency Temp row Ended");
  }
  filteringDropDownSeason() {
    this.applicationInsightService.logEvent("Currency Filter Dropdown Season Started");
    if (this.fromSeasonMasterlist != null || undefined) {
      this.fromSeasonFilterList = this.endSeasonFilterList = this.tempSeasonList = this.fromSeasonMasterlist.filter(x => x.seasonNumber != this.fromSeasonNumber);
    }
    /* istanbul ignore if */
    if (this.toSeasonNumber != undefined && this.toSeasonNumber != null && this.toSeasonNumber != '') {
      let collection = this.fromSeasonFilterList.filter(x => x.seasonNumber > this.fromSeasonNumber && x.seasonNumber < this.toSeasonNumber);
      for (var i = 0; i < collection.length; i++) {
        this.fromSeasonFilterList = this.endSeasonFilterList = this.tempSeasonList = this.fromSeasonFilterList.filter(x => x.seasonNumber != collection[i].seasonNumber);
      }
      this.fromSeasonFilterList = this.endSeasonFilterList = this.tempSeasonList = this.fromSeasonFilterList.filter(x => x.seasonNumber != this.toSeasonNumber);
    }
    /* istanbul ignore if */
    if (this.rows.length > 0) {
      let length = this.rows.length;
      for (var i = 0; i < length; i++) {
        this.fromSeasonFilterList = this.endSeasonFilterList = this.tempSeasonList = this.fromSeasonFilterList.filter(x => x.seasonNumber != this.rows[i].fromSeasonNumber);
        if (this.rows[i].toSeasonNumber != undefined && this.rows[i].toSeasonNumber != null && this.rows[i].toSeasonNumber != '') {
          let collection = this.fromSeasonFilterList.filter(x => x.seasonNumber > this.rows[i].fromSeasonNumber && x.seasonNumber < this.rows[i].toSeasonNumber);
          this.fromSeasonFilterList = this.endSeasonFilterList = this.tempSeasonList = collection.length > 0 ? this.fromSeasonFilterList.filter(x => x.seasonNumber != collection[0].seasonNumber) : this.fromSeasonFilterList;
          this.fromSeasonFilterList = this.endSeasonFilterList = this.tempSeasonList = this.fromSeasonFilterList.filter(x => x.seasonNumber != this.rows[i].toSeasonNumber);
        }
      }
    }
    this.applicationInsightService.logEvent("Currency Filter Dropdown Season Ended");
  }
  /* istanbul ignore next */
  UpdateRowList(index: any) {
    this.applicationInsightService.logEvent("Currency Edit Started");
    let rowindex = 0;
    if (index != 0)
      this.rows = this.rows.sort((a, b) => (a.fromSeasonNumber > b.fromSeasonNumber ? 1 : -1));
    for (var i = 0; i < index - 1; i++) {
      if (this.rows[i].toSeasonNumber == undefined || this.rows[i].toSeasonNumber == null || this.rows[i].toSeasonNumber == '')
        rowindex = i;

      if (index == 2 && (this.rows[i + 1].toSeasonNumber == undefined || this.rows[i + 1].toSeasonNumber == null || this.rows[i + 1].toSeasonNumber == ''))
        break;

    }
    let updateEndSeason = index > 1 ? this.fromSeasonMasterlist.filter(x => x.seasonNumber < this.rows[rowindex + 1].fromSeasonNumber) : [];
    this.rows[rowindex].toSeasonNumber = updateEndSeason.length > 0 ? updateEndSeason[updateEndSeason.length - 1].seasonNumber : this.rows[rowindex].toSeasonNumber;
    this.applicationInsightService.logEvent("Currency Edit Ended");
  }
  onRemoveRow(rowIndex: any) {
    this.applicationInsightService.logEvent("Currency Delete Started");
    let index = rowIndex.fiscalCountryCurrencyId > 0 ? this.rows.findIndex(d => d.fiscalCountryCurrencyId === rowIndex.fiscalCountryCurrencyId) : this.rows.findIndex(d => d.fromSeasonNumber === rowIndex.fromSeasonNumber); //find index in your array
    this.currencyStatus = rowIndex.fiscalCountryCurrencyId > 0 ? State.change : State.nochange;
    this.rows.splice(index, 1);
    this.dataSource = new MatTableDataSource(this.rows);
    this.currencyStatus = (this.rows.length == 0 && this.TotalCurrencyLength > 0) ? State.delete : this.currencyStatus;

    if (this.rows.length > 0) {
      for (var i = 0; i < this.rows.length; i++) {
        this.fromSeasonFilterList = this.endSeasonFilterList = this.tempSeasonList = this.fromSeasonMasterlist.filter(x => x.seasonNumber != this.rows[i].fromSeasonNumber);
        if (this.rows[i].toSeasonNumber != undefined && this.rows[i].toSeasonNumber != null && this.rows[i].toSeasonNumber != '') {
          let collection = this.fromSeasonFilterList.filter(x => x.seasonNumber > this.rows[i].fromSeasonNumber && x.seasonNumber < this.rows[i].toSeasonNumber);
          this.fromSeasonFilterList = this.endSeasonFilterList = this.tempSeasonList = collection.length > 0 ? this.fromSeasonFilterList.filter(x => x.seasonNumber != collection[0].seasonNumber) : this.fromSeasonFilterList;
          this.fromSeasonFilterList = this.endSeasonFilterList = this.tempSeasonList = this.fromSeasonFilterList.filter(x => x.seasonNumber != this.rows[i].toSeasonNumber);
        }
      }

    }
    else
      this.fromSeasonFilterList = this.endSeasonFilterList = this.tempSeasonList = this.fromSeasonMasterlist;
    this.applicationInsightService.logEvent("Currency Delete Ended");
  }
  updateRecord(rowIndex: any) {
    this.tempSeasonList = this.seasonList = this.fromSeasonMasterlist = this.fromSeasonFilterList = this.seasonList.filter(x => x.seasonNumber >= rowIndex.fromSeasonNumber);
    this.seasonList = this.endSeasonMasterlist = this.endSeasonFilterList = this.seasonList.filter(x => x.seasonNumber >= rowIndex.toSeasonNumber);
    this.applicationInsightService.logEvent("Currency Edit by id Started");
    this.isEditFcCurrency = true;
    this._fccurrenciesForm.controls.fiscalCountryCurrencyId.setValue(rowIndex.fiscalCountryCurrencyId);
    this._fccurrenciesForm.controls.currencyCode.setValue(rowIndex.currencyCode);
    this._fccurrenciesForm.controls.fromSeasonNumber.setValue(rowIndex.fromSeasonNumber);
    this._fccurrenciesForm.controls.toSeasonNumber.setValue(rowIndex.toSeasonNumber);
    this._fccurrenciesForm.controls.tempRowIndex.setValue(rowIndex.tempRowIndex);
    this.applicationInsightService.logEvent("Currency Edit by id Ended");
  }
  createCurrencyFormGroup() {
    return {
      fiscalCountryCurrencyId: this.fiscalCountryCurrencyId,
      currencyCode: this.currencyCode,
      fromSeasonNumber: this.fromSeasonNumber,
      toSeasonNumber: this.toSeasonNumber,
      lastUpdatedBy: this.lastUpdatedBy,
      tempRowIndex: (this.tempRowIndex = this.tempRowIndex + 1),
      isDeleteFcCurrency: true,
      isFirstDraft: this.isFirstDraft
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    this.onClear()
  }



  onClear() {
    this.applicationInsightService.logEvent("Currency Clear Started");
    this._fccurrenciesForm.controls.currencyCode.clearValidators();
    this._fccurrenciesForm.controls.fromSeasonNumber.clearValidators();
    this._fccurrenciesForm.controls.currencyCode.setValue('');
    this._fccurrenciesForm.controls.fromSeasonNumber.setValue('');
    this._fccurrenciesForm.controls.toSeasonNumber.setValue('');
    this._fccurrenciesForm.controls.lastUpdatedBy.setValue('');
    this.fnSetControlsValidation();
    this.endSeasonFilterList = this.fromSeasonFilterList = this.tempSeasonList;
    this.applicationInsightService.logEvent("Currency Clear Ended");
  }
  //to clear after saving from parent component
  clearScope() {
    this.rows = [];
    this.dataSource = new MatTableDataSource([]);
    this.tempRowIndex = 0;
  }



  _fccurrenciesForm: FormGroup = new FormGroup({
    fiscalCountryCurrencyId: new FormControl(''),
    currencyCode: new FormControl(null, [this._commonutilityService.ObjectValidator(), Validators.required]),
    fromSeasonNumber: new FormControl(null, [this.acStartSeasonValidator("New"), Validators.required]),
    toSeasonNumber: new FormControl(null, [this.acEndSeasonValidator()]),
    lastUpdatedBy: new FormControl(''),
    tempRowIndex: new FormControl('')
  })

  fnSetControlsValidation() {
    this._fccurrenciesForm.controls.currencyCode.setValidators([this._commonutilityService.ObjectValidator(), Validators.required]);
    this._fccurrenciesForm.controls.fromSeasonNumber.setValidators([this.acStartSeasonValidator("New"), Validators.required]);
    this._fccurrenciesForm.controls.toSeasonNumber.setValidators([this.acEndSeasonValidator()]);
  }

  acStartSeasonValidator(mode: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value != null && control.value != ""
        && control.value != undefined && this.validOptions != null
        && this.validOptions != undefined && this.validOptions.indexOf(control.value.toString()) !== -1) {
        let index = this.rows.length;
        if (index != 0 && this.rows[index - 1].fromSeasonNumber > control.value && this.rows[index - 1].fromSeasonNumber < this._fccurrenciesForm.controls.toSeasonNumber.value) {
          return { 'overLappingSeason': { value: control.value } };
        }
        if (this._fccurrenciesForm.controls.toSeasonNumber.value != null && this._fccurrenciesForm.controls.toSeasonNumber.value != undefined && this._fccurrenciesForm.controls.toSeasonNumber.value != "") {
          if (control.value > this._fccurrenciesForm.controls.toSeasonNumber.value) { return { 'invalidStartSeason': { value: control.value } }; }
        }
        return null  /* valid option selected */
      }
      else
        return (control.value != null) ? { 'invalidAutocomplete': { value: control.value } } : null;
    }
  }
  fnGetRangeValues() {
    let validOptions: any[] = [];
    if (this.seasonList != null || this.seasonList != undefined)
      this.seasonList.forEach(x => {
        if (x.seasonNumber != null) {
          validOptions.push(x.seasonNumber.toString());
        }
      })
    return validOptions;
  }

  acEndSeasonValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value != null && control.value != ""
        && control.value != undefined && this.validOptions != null
        && this.validOptions != undefined && this.validOptions.indexOf(control.value.toString()) !== -1) {
        let index = this.rows.length;
        if (index != 0 && this.rows[index - 1].fromSeasonNumber > this._fccurrenciesForm.controls.fromSeasonNumber.value && this.rows[index - 1].fromSeasonNumber < control.value) {
          return { 'overLappingSeason': { value: control.value } };
        }
        if (this._fccurrenciesForm.controls.fromSeasonNumber.value != null && this._fccurrenciesForm.controls.fromSeasonNumber.value != undefined && this._fccurrenciesForm.controls.fromSeasonNumber.value != "") {
          if (this._fccurrenciesForm.controls.fromSeasonNumber.value > control.value) { return { 'invalidEndSeason': { value: control.value } } }
        }
        return null  /* valid option selected */
      }
      else
        return (control.value != null && control.value != "") ? { 'invalidAutocomplete': { value: control.value } } : null;
    }
  }
}
