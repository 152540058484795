import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
@Component({
  selector: 'markupgroup',
  templateUrl: './markupgroup.component.html',
  styleUrls: ['./markupgroup.component.css']
})
export class MarkupgroupComponent implements OnInit {

  title = "Markup Group"
  markupgroupMasterList: any;
  cloneMarkupgroupMasterList: any[] = [];
  markupgroupFilterList: any;
  markupGroupCode: any = "";
  markupGroupNameMasterList: any[] = [];
  cloneMarkupGroupNameMasterList: any[] = [];
  markupGroupNameFilterList: any[] = [];
  markupGroupName: any = "";
  corporateBrandMasterList: any[] = [];
  corporateBrandFilterList: any[] = [];
  corporateBrandName: any = "";
  status: any = "active";
  obj: any[] = [];

  constructor(private titleService: Title,
    private applicationInsightService:ApplicationInsightService) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
  }
  receivedChildHandler(obj:any) {
    this.applicationInsightService.logEvent("Receive data from child started");
    this.corporateBrandMasterList = this.corporateBrandFilterList = obj.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));;
    this.applicationInsightService.logEvent("Receive data from child ended");
  }
  markupDropDownList(obj:any) {
    this.applicationInsightService.logEvent("Markupgroupname listing started");
    if(obj.sort!=null||undefined){
    this.markupgroupMasterList = this.markupgroupFilterList = obj.sort((a, b) => a.markupGroupCode > b.markupGroupCode ? 1 : -1);
    }
    this.markupGroupNameFilterList = this.markupGroupNameMasterList = obj;
    if(this.markupGroupNameMasterList.filter!=null||undefined){
    this.markupGroupNameFilterList = this.markupGroupNameMasterList = this.markupGroupNameMasterList.filter((item, i, arr) => arr.findIndex((t) => t.markupGroupName === item.markupGroupName) === i);
    }
    if(this.markupGroupNameMasterList.sort!=null||undefined){
    this.markupGroupNameFilterList = this.markupGroupNameMasterList.sort((a, b) =>(a.markupGroupCode > b.markupGroupCode ? 1 : -1));
    }
    this.applicationInsightService.logEvent("Markupgroupname listing ended");
  } 
 
  filterItemCorporateBrand(filterKey: any) {
    this.applicationInsightService.logEvent("Filter Corporatebrand Started");
    if (isNaN(filterKey)) {
      this.corporateBrandFilterList = this.corporateBrandMasterList.filter(cb => cb.corporateBrandName.toLowerCase().includes(filterKey.toLowerCase()));
      this.corporateBrandFilterList = this.corporateBrandFilterList.sort((a, b) => (a.corporateBrandCode > b.corporateBrandCode ? 1 : -1));
    } else {
      this.corporateBrandFilterList = this.corporateBrandMasterList.filter(cb => cb.corporateBrandCode.toLowerCase().includes(filterKey.toLowerCase()));
      this.corporateBrandFilterList = this.corporateBrandFilterList.sort((a, b) => (a.corporateBrandCode > b.corporateBrandCode ? 1 : -1));
    }
    this.onfilter()
    this.applicationInsightService.logEvent("Filter Corporatebrand Ended");
  }
  filterItemMarkupGroup(filterKeyMG: any) {
    this.applicationInsightService.logEvent("Markupgroupname filter started");
    if (filterKeyMG != null && filterKeyMG != "") {
      if (isNaN(filterKeyMG)) {
        this.markupGroupNameFilterList = this.markupGroupNameMasterList.filter(gg => gg.markupGroupName.toString().toLowerCase().includes(filterKeyMG.toString().toLowerCase()));
      } else {
        this.markupGroupNameFilterList = this.markupGroupNameMasterList.filter(gg => gg.markupGroupCode.toString().toLowerCase().includes(filterKeyMG.toString().toLowerCase()));
      }
      this.markupGroupNameFilterList = this.markupGroupNameFilterList.sort((a, b) => (a.markupGroupCode > b.markupGroupCode ? 1 : -1));
    }
    else { this.markupGroupNameFilterList = this.markupGroupNameMasterList.sort((a, b) =>(a.markupGroupCode > b.markupGroupCode ? 1 : -1)); }
    this.applicationInsightService.logEvent("Markupgroupname filter ended");
  }

  // filter the dropdowm based on selections 
  onfilter(){
    setTimeout(() => {
      if(this.cloneMarkupGroupNameMasterList.length === 0){
        this.cloneMarkupGroupNameMasterList = this.markupGroupNameMasterList;
      }

      if(this._searchform?.controls?.corporateBrandName?.value){
        this.markupGroupNameMasterList = this.cloneMarkupGroupNameMasterList.filter(x => x.corporateBrandCodeName === this._searchform?.controls?.corporateBrandName?.value)
        this.markupGroupNameFilterList =  this.markupGroupNameMasterList;
      }
      else {
        this.markupGroupNameFilterList = this.cloneMarkupGroupNameMasterList;
        this.markupGroupNameMasterList = this.cloneMarkupGroupNameMasterList;
      }

        // filter the markup names based on markupGroupCode dropdown
        if(this._searchform?.controls?.corporateBrandName?.value && 
          this._searchform.controls.markupGroupCode.value){
          this.markupGroupNameMasterList = this.cloneMarkupGroupNameMasterList.filter(x=>{
          return x.markupGroupCodeName === this._searchform.controls.markupGroupCode.value;
        })
        this.markupGroupNameFilterList = this.markupGroupNameMasterList;
      }
    }, 1000);
  }
  //clear button click event
  onSearchClear() {
    this.applicationInsightService.logEvent("Markupgroup clearfilter started");
    this._searchform.controls.markupGroupCode.setValue('');
    this._searchform.controls.markupGroupName.setValue('');
    this._searchform.controls.markupGroupCodeName.setValue('');
    this._searchform.controls.corporateBrandName.setValue('');
    this._searchform.controls.corporateBrandCodeName.setValue('');
    this._searchform.controls['status'].setValue("active");
    this.filterItemMarkupGroup("");
    this.applicationInsightService.logEvent("Markupgroup clearfilter ended");
  }

  //Markup group Search Form
  _searchform: FormGroup = new FormGroup({
    markupGroupCode: new FormControl(''),
    markupGroupName: new FormControl(''),
    markupGroupCodeName: new FormControl(''),
    corporateBrandName: new FormControl(''),
    corporateBrandCodeName: new FormControl(''),
    status: new FormControl()
  })

}
