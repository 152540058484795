import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CorporatebrandService } from 'src/app/services/organization/corporatebrand/corporatebrand.service';
import { IndexService } from 'src/app/services/organization/index/index.service';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { SubindexService } from 'src/app/services/organization/subindex/subindex.service';
import { ToastrService } from 'ngx-toastr';
import { DepartmentService } from 'src/app/services/organization/department/department.service';
import { CommonUtilityService } from 'src/app/services/organization/commonutility/commonutility.service';
import { AccountService } from '../../../../shared/services/account.service'
import { Subscription } from 'rxjs';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { UserAccess } from 'src/app/models/userAccess';
import { ModulePermission } from 'src/app/models/enum';
export class dropDownCollections {
  corporateBrandList: any;
  indexList: any;
}
@Component({
  selector: 'app-subindexform',
  templateUrl: './subindexform.component.html',
  styleUrls: ['./subindexform.component.css']
})

export class SubindexformComponent implements OnInit {
  @Output() childButtonEvent = new EventEmitter();
  corporateBrandMasterlist: any[] = [];
  corporateBrandFilterList: any[] = [];
  indexMasterlist: any[] = [];
  indexFilterList: any[] = [];
  indexCodeFilterList: any[] = [];
  updateSubIndexForm: any;
  isSubIndexEdit: any = false;
  isSubIndexEditCB: any = 'legacy';
  seasoncurrent: any;
  seasonSeasonList: any;
  seasonMasterlist: any;
  seasonFilterList: any;
  deptSeasonList: any[] = [];
  season: FormControl = new FormControl('', [this._commonutilityService.acStartSeasonValidator("", null)]);
  validationMessages: any;
  subscription: Subscription;
  userrole: boolean;
  users: any;
  constructor(public _corporatebrandService: CorporatebrandService,
    public _SubindexService: SubindexService,
    public _indexService: IndexService,
    public _departmentService: DepartmentService,
    public _commonutilityService: CommonUtilityService,
    private toastr: ToastrService,
    public account: AccountService,
    private applicationInsightService: ApplicationInsightService,
    public _userAccess: UserAccess
  ) {
    this.updateSubIndexForm = Object.assign({ active: true, draft: true });
    this.users = JSON.parse(localStorage.getItem("users") || "[]")
  }



  ngOnInit(): void {
    this.userrole = this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this._subindexform.controls.lastUpdatedBy.setValue(JSON.parse(localStorage.getItem("UserInfo")).Email || "[]");
    this.validationMessages = this._commonutilityService.validationMsgs;
    this._corporatebrandService.getCorporateBrandList().subscribe((data: any) => {
      this.applicationInsightService.logEvent("Subindex Corporatebrand listing started");
      this.corporateBrandMasterlist = this.corporateBrandFilterList
        = data.getCorporateBrandAll.filter(objCB => { return objCB['active'] !== false; });
      this.corporateBrandFilterList = this.corporateBrandMasterlist.sort((a, b) => a.corporateBrandName.localeCompare(b.corporateBrandName));
      this.loadDropDown();
      this.applicationInsightService.logEvent("Subindex Corporatebrand listing ended");
    });

    this._indexService.getIndexList().subscribe((data: any) => {
      this.applicationInsightService.logEvent("Subindex Index listing started");
      this.indexMasterlist = data.index.filter(x => { return x['active'] !== false; })
      this.loadDropDown();
      this.applicationInsightService.logEvent("Subindex Index listing ended");
    });
    this._departmentService.getDepartmentSeasonByRange(10).subscribe((data: any) => {
      this.applicationInsightService.logEvent("Subindex Season listing started");
      this.seasonSeasonList = this.seasonMasterlist = this.seasonFilterList = data.seasonByRange;
      this.season.setValue(data.seasonByRange.filter(objCB => { return objCB['isCurrentSeason'] !== false; }).map(({ seasonNumber }) => seasonNumber).toString());
      localStorage.setItem("isCurrentSeason", data.seasonByRange.filter(objCB => { return objCB['isCurrentSeason'] !== false; }).map(({ seasonNumber }) => seasonNumber).toString())
      this.applicationInsightService.logEvent("Subindex Season listing ended");
    });
    this.subscription =
      this._SubindexService.subIndexId$.subscribe(
        subindexid => {
          this.updateSubindex(subindexid)
        });
  }
  public ngOnDestroy(): void {
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }
  loadDropDown() {
    if (this.corporateBrandMasterlist.length > 0 && this.indexMasterlist.length > 0) {
      let obj = new dropDownCollections();
      obj.corporateBrandList = this.corporateBrandMasterlist;
      obj.indexList = this.indexMasterlist;
      this.childButtonEvent.emit(obj);
    }
  }
  updateSubindex(subindexid: any) {
    this.applicationInsightService.logEvent("updateSubindex  started");
    this.isSubIndexEdit = true;
    this._SubindexService.getSubIndexById(subindexid)
      .subscribe((data: any) => {
        this.updateSubIndexForm = data.subIndex;
        if (this.updateSubIndexForm.active != null || undefined) {
          this.isSubIndexEditCB = this.updateSubIndexForm.active == true ? 'fill' : 'legacy';
        }
      });
    this._departmentService.getDepartmentBySubIndexIdandSeason(subindexid, this.season.value,).subscribe((data: any) => {
      this.deptSeasonList = data.department
    });
    document.getElementById("scrolltop").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    this.applicationInsightService.logEvent("updateSubindex ended");
  }
  displayTextCB(corporateBrandId: string) {
    if (corporateBrandId === null || corporateBrandId === "")
      return null;
    else if (this.corporateBrandMasterlist != undefined && corporateBrandId != null)
      return this.corporateBrandMasterlist.find(x => x.corporateBrandId == corporateBrandId).corporateBrandCodeName;
  }
  filterItemCB(filterKey: any) {
    this.applicationInsightService.logEvent("Subindex form filter corporatebrand started");
    if (filterKey !== null && filterKey !== "") {
      this.corporateBrandFilterList = this.corporateBrandMasterlist.filter(cb => cb.corporateBrandName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()))
      this.corporateBrandFilterList = this.corporateBrandFilterList.sort((a, b) => a.corporateBrandName.localeCompare(b.corporateBrandName));
      if (typeof filterKey === "number")
        this.fnLoadIndexCde(this._subindexform.controls.corporateBrandId.value)
    }
    else {
      this.corporateBrandFilterList = this.corporateBrandMasterlist.sort((a, b) => a.corporateBrandName.localeCompare(b.corporateBrandName));
      this._subindexform.controls.indexId.setValue('');
      this.indexFilterList = [];
    };
    this.applicationInsightService.logEvent("Subindex form filter corporatebrand ended");
  }
  displayTextIndex(indexId: string) {
    this.applicationInsightService.logEvent("Subindex display indexCode started");
    if (indexId == null || indexId == "")
      return null;
    else if (this.indexMasterlist != undefined && indexId != null)
      return this.indexMasterlist.find(x => x.indexId === indexId).indexCodeName;
    this.applicationInsightService.logEvent("Subindex display indexCode ended");
  }
  filterItemIndex(filterKey: string) {
    this.applicationInsightService.logEvent("Subindex filter IndexCode started");
    if (filterKey != null && filterKey != "" && typeof this._subindexform.controls['corporateBrandId'].value === "number") {
      this.indexFilterList = this.indexCodeFilterList.filter(cb => cb.indexCode.toString().toLowerCase().includes(filterKey.toString().toLowerCase()))
      this.indexFilterList = this.indexCodeFilterList.sort((a, b) => a.indexCode.localeCompare(b.indexCode));
    } else if (typeof this._subindexform.controls.corporateBrandId.value === "number") { this.indexFilterList = this.indexCodeFilterList.sort((a, b) => a.indexCode.localeCompare(b.indexCode)); }
    this.applicationInsightService.logEvent("Subindex  filter Indexcode ended");
  }
  displayTextSeason(startSeason: any) {
    if (startSeason == null || startSeason == "")
      return null;
    else if (this.seasonMasterlist != undefined && startSeason != null)
      return this.seasonMasterlist.find(x => x.seasonNumber === startSeason).displayName;
  }
  filterSeason(filterKeySS: string) {
    this.applicationInsightService.logEvent("Subindex form filter Season started");
    let newSSList: any[] = [];
    newSSList = this.seasonMasterlist;
    if (filterKeySS != null && filterKeySS != "") {
      this.seasonFilterList = newSSList.filter(rs => rs.displayName.toLowerCase().includes(filterKeySS.toString().toLowerCase()))

      if (this.seasonMasterlist.find(x => x.seasonNumber == filterKeySS) != null && this._subindexform.controls.subIndexId.value != undefined) {
        this.deptSeasonList = [];
        this._departmentService.getDepartmentBySubIndexIdandSeason(this._subindexform.controls.subIndexId.value, this.season.value).subscribe((data: any) => {
          this.deptSeasonList = data.department
        });
      }
    }
    else { this.seasonFilterList = this.seasonMasterlist; }
    this.applicationInsightService.logEvent("Subindex form filter Season ended");
  }
  fnLoadIndexCde(corporateBrandId: any) {
    this.applicationInsightService.logEvent("Subindex load indexCode started");
    this.indexCodeFilterList = [];
    this._indexService.getIndexByCBId(corporateBrandId).subscribe((data: any) => {
      this.indexFilterList = this.indexCodeFilterList = data.index.filter(objCB => { return (objCB['active'] !== false && objCB['draft'] == false); });
      this.indexFilterList = this.indexCodeFilterList.sort((a, b) => a.indexCode.localeCompare(b.indexCode));

    })
    this.applicationInsightService.logEvent("Subindex load indexCode ended");
  }
  onSubmit() {
    this.applicationInsightService.logEvent("Subindex form submit started");
    this._subindexform.controls['corporateBrandId'].updateValueAndValidity();
    this._subindexform.controls['indexId'].updateValueAndValidity();
    this._subindexform.controls['subIndexCode'].updateValueAndValidity();
    this._subindexform.controls['subIndexName'].updateValueAndValidity();
    this._subindexform.controls['subIndexSortOrder'].updateValueAndValidity();
    this._subindexform.controls['draft'].updateValueAndValidity();

    if (this._subindexform.valid) {
      if (this._subindexform.value.subIndexId == undefined || this._subindexform.value.subIndexId == 0 || this._subindexform.value.subIndexId == null) {
        this._SubindexService.createSubIndex(this._subindexform.value).subscribe(data => {
          this.toastr.success(data.message, "", { timeOut: 10000 });
          this.loadDropDown();
          this.clearform();
          this._SubindexService.refreshSubindexList();
        },
          error => { this.toastr.error(error, "", { timeOut: 10000 }) }
        )
      }
      else {
        this._SubindexService.updateSubIndex(this._subindexform.value).subscribe(data => {
          this.toastr.success(data.message, "", { timeOut: 10000 });
          this.loadDropDown();
          this.clearform();
          this._SubindexService.refreshSubindexList();
        },
          error => { this.toastr.error(error, "", { timeOut: 10000 }) }
        )
      }
    }
    this.applicationInsightService.logEvent("Subindex form submit ended");
  }
  onClear() {
    this.clearform();
  }
  clearform() {
    this.applicationInsightService.logEvent("Subindex clearform started");
    //Clearing Autopopulate values
    this.displayTextCB("");
    this.displayTextIndex("");

    //Clearing validation 
    this._subindexform.controls.corporateBrandId.clearValidators();
    this._subindexform.controls.indexId.clearValidators();
    this._subindexform.controls.subIndexCode.clearValidators();
    this._subindexform.controls.subIndexName.clearValidators();
    this._subindexform.controls.subIndexSortOrder.clearValidators();

    //Clearing control values 
    this._subindexform.controls.subIndexId.reset();
    this._subindexform.controls.corporateBrandId.reset();
    this._subindexform.controls.indexId.reset();
    this._subindexform.controls.subIndexCode.reset();
    this._subindexform.controls.subIndexName.reset();
    this._subindexform.controls.subIndexSortOrder.reset();

    this._subindexform.controls.corporateBrandId.setValidators([this._commonutilityService.acObjectValidator(), Validators.required, Validators.maxLength(30)]);
    this._subindexform.controls.indexId.setValidators([this._commonutilityService.acObjectValidator(), , Validators.required, Validators.min(1), Validators.max(9999), Validators.pattern('^(?![-]).*')]);
    this._subindexform.controls.subIndexCode.setValidators([Validators.required, Validators.minLength(1), Validators.pattern('^([A-Z][0-9][0-9])')]);
    this._subindexform.controls.subIndexName.setValidators([Validators.required, Validators.maxLength(250)]);
    this._subindexform.controls.subIndexSortOrder.setValidators([Validators.required, Validators.min(1), Validators.max(9999), Validators.pattern('^(?![-]).*')]);
    this._subindexform.controls.draft.setValue(true);
    this._subindexform.controls.active.setValue(true);

    this.isSubIndexEdit = false;
    this.isSubIndexEditCB = 'legacy';
    this.applicationInsightService.logEvent("Subindex clearform ended");
  }

  _subindexform: FormGroup = new FormGroup({
    subIndexId: new FormControl(),
    subIndexName: new FormControl('', [Validators.required, Validators.maxLength(250)]),
    corporateBrandId: new FormControl('', [this._commonutilityService.acObjectValidator(), Validators.required, Validators.maxLength(30)]),
    indexId: new FormControl('', [this._commonutilityService.acObjectValidator(), Validators.required]),
    subIndexCode: new FormControl('', [Validators.required, Validators.pattern('^([A-Z][0-9][0-9])')]),
    subIndexSortOrder: new FormControl('', [Validators.required, Validators.min(1), Validators.max(9999), Validators.pattern('^(?![-]).*')]),
    active: new FormControl(true),
    lastUpdatedBy: new FormControl(''),
    draft: new FormControl(true)
  });
}
