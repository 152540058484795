import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MarkupgroupService } from 'src/app/services/organization/markupgroup/markupgroup.service';
import { Subscription } from 'rxjs';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { MarkupGroupViewComponent } from '../markupgrouplist/markupgroupview.component';
import { constant } from 'src/app/shared/const/constant';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModulePermission } from 'src/app/models/enum';


export interface iMarkupgroup {
  markupGroupId: any;
  corporateBrandName: any;
  markupGroupCode: any;
  corporateBrandId: any;
  markupGroupSortOrder: any;
  active: any;
  draft: any;
  lastUpdatedTime: any;
}
@Component({
  selector: 'app-markupgrouplist',
  templateUrl: './markupgrouplist.component.html',
  styleUrls: ['./markupgrouplist.component.css']
})
export class MarkupgrouplistComponent implements OnInit {
  @Input() markupGroupSearch;
  @Output() childButtonEvent = new EventEmitter();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<iMarkupgroup>;
  public dataSource: MatTableDataSource<iMarkupgroup>;
  active: any;
  draft: any;
  length: any;
  pageSize: any = 15;
  sortOrderColumn: any = "markupGroupSortOrder";
  sortByOrder: any = "asc";
  pageEvent: PageEvent;
  errorMsg: any;
  customDateTime: string;
  subscription: Subscription;
  emitter: number = 1;
  userrole: boolean;
  users: any;
  sortClick:boolean=false;
  getpagesizeoptions(): number[] {
    if (this.length > 25)
      return [10, 20, 25, this.length];
    else if (this.length > 20)
      return [10, 20, this.length];
    else if (this.length > 10)
      return [10, this.length];
    return null;
  }
  displayedColumns = [
    'corporateBrandName',
    'markupGroupCode',
    'markupGroupName',
    'markupGroupSortOrder',
    'lastUpdatedTime',
    'Actions'
  ];
  matDialogRef: MatDialogRef<MarkupGroupViewComponent>;
  constructor(
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private toastr: ToastrService,
    public _markupgroupService: MarkupgroupService,
    private applicationInsightService: ApplicationInsightService
  ) { this.users = JSON.parse(localStorage.getItem("users") || "[]") }
  ngOnInit(): void {
    this.userrole = this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this.subscription = this._markupgroupService.refreshTableMarkupgroup$.subscribe(
      x => {
        this.emitter = 1;
        this.BindMarkupgroupData();
      });
  }
  ngOnChanges(changes: SimpleChanges) {
    this.BindMarkupgroupData();
  }
  public ngOnDestroy(): void {
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }
  BindMarkupgroupData() {
    this.applicationInsightService.logEvent("Markupgroup listing and searching started");
    if (this.markupGroupSearch != null) {
      if (this.markupGroupSearch.status == "active") { this.active = true; this.draft = false; }
      if (this.markupGroupSearch.status == "inactive") { this.active = false; this.draft = false; }
      if (this.markupGroupSearch.status == "draft") { this.draft = true; this.active = ""; }
      if (this.markupGroupSearch.status == "all") { this.draft = ""; this.active = ""; }
      // below code is for number plus string search
      var hasNumber = /\d/;
      if(!this.sortClick){
      if(isNaN(this.markupGroupSearch?.corporateBrandName)){
        if(hasNumber.test(this.markupGroupSearch?.corporateBrandName)){
          this.markupGroupSearch.corporateBrandCodeName = this.markupGroupSearch.corporateBrandName;
          this.markupGroupSearch.corporateBrandCode ='';
          this.markupGroupSearch.corporateBrandName='';
        }else{
          this.markupGroupSearch.corporateBrandName = this.markupGroupSearch.corporateBrandName;
          this.markupGroupSearch.corporateBrandCode ='';
          this.markupGroupSearch.corporateBrandCodeName ='';
        }
      }else{
        this.markupGroupSearch.corporateBrandCode = this.markupGroupSearch.corporateBrandName;
        this.markupGroupSearch.corporateBrandName='';
        this.markupGroupSearch.corporateBrandCodeName ='';
      }
      if (isNaN(this.markupGroupSearch?.markupGroupName)) {
        this.markupGroupSearch.markupGroupCodeName = this.markupGroupSearch?.markupGroupName;
        this.markupGroupSearch.markupGroupCode = '';
      } else {
        this.markupGroupSearch.markupGroupCode = this.markupGroupSearch?.markupGroupName;
        this.markupGroupSearch.markupGroupName = '';
      }
    }
      this._markupgroupService.getMarkupgroupAll().subscribe((data: any) => {
        var filterData = data.markupGroup.filter(MG => MG.markupGroupCode.toString().toLowerCase().includes(this.markupGroupSearch.markupGroupCode == null ? "" : this.markupGroupSearch.markupGroupCode.toString().toLowerCase()))
          .filter(MG => MG.corporateBrandName == null ? "null" : MG.corporateBrandName.toString().toLowerCase().includes(this.markupGroupSearch.corporateBrandName.toString().toLowerCase()))
          .filter(MG => MG.corporateBrandName == null ? "null" : MG.corporateBrandCode.toString().toLowerCase().includes(this.markupGroupSearch.corporateBrandCode.toString().toLowerCase()))
          .filter(MG => MG.markupGroupCodeName.toString().includes(this.markupGroupSearch.markupGroupCodeName.toString()))
          .filter(MG => MG.corporateBrandCodeName.toString().includes(this.markupGroupSearch.corporateBrandCodeName.toString()))
          .filter(MG => MG.active.toString().includes(this.active.toString()))
          .filter(MG => MG.draft.toString().includes(this.draft.toString()));
        if (this.markupGroupSearch.corporateBrandName != undefined && this.markupGroupSearch.corporateBrandName != "" && this.markupGroupSearch.corporateBrandName != null) {
          filterData = filterData.filter(item => item.corporateBrandName !== null);
        }
        for (let i = 0; i < filterData.length; i++) {
          if (filterData[i].corporateBrandName != null || '') {
            if (filterData[i].corporateBrandName.length > 25) {
              filterData[i].isdisableCB = false;

            }
            else {
              filterData[i].isdisableCB = true;
            }
          }
          else {
            filterData[i].isdisableCB = true;
          }
          if (filterData[i].markupGroupName.length > 30) {
            filterData[i].isdisableMG = false;
          }
          else {
            filterData[i].isdisableMG = true;
          }
        }
        this.dataSource = new MatTableDataSource(filterData);
        this.sort.active = this.sortOrderColumn;
        this.sort.direction = this.sortByOrder;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.length = this.dataSource.data.length;
        this.sortClick = false;
        if (this.emitter == 1) {
          this.emitter = 2;
          this.childButtonEvent.emit(data.markupGroup);
        }
      },
        error => {
          this.errorMsg = error;
          this.dataSource = new MatTableDataSource([]);
        });
    }
    this.applicationInsightService.logEvent("Markupgroup listing and searching ended");
  }
  //While clicking Edit button load respective data to entry page
  updateRecord(updateRowData: any) {
    this._markupgroupService.getMarkupgroupIdFromList(updateRowData.markupGroupId);
  }
  sortData(sort: Sort) {
    this.sortOrderColumn = sort.active;
    this.sortByOrder = sort.direction;
    this.sortClick = true;
    this.BindMarkupgroupData();
  }
  public GetMarkupgroupDatawithfilters(event?: PageEvent) {
    this.pageSize = event.pageSize;
    this.sortClick = true;
    this.BindMarkupgroupData();
    return event;
  }
  openDialog(row: any) {
    let departmentList: any;
    let rowData: any;
    this._markupgroupService.getDepartmentByMarkUpGroupId(row.markupGroupId, parseInt(localStorage.getItem('CurrentSeason'))).subscribe((data: any) => {
      departmentList = data.markUpGroupDepartment;
      this.matDialogRef = this.dialog.open(MarkupGroupViewComponent, {
        data: { departmentList: departmentList, rowData: row },
        disableClose: false
      });
    }, error => { this.toastr.error(error, "", { timeOut: constant.toasterTimeout }) })

    this.matDialogRef?.afterClosed()?.subscribe(res => {
      if ((res == true)) {
        departmentList = "";
      }
    });
  }
}
