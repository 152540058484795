import { Component, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogBoxService } from 'src/app/shared/services/dialog-box.service';
import { AccountService } from '../../../../shared/services/account.service'
import { Subscription } from 'rxjs';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { UserAccess } from 'src/app/models/userAccess';
import { ModulePermission } from 'src/app/models/enum';
import { ToastrService } from 'ngx-toastr';
import { CurrencyService } from "src/app/services/currency/currency.service";

@Component({
  selector: 'app-currencyform',
  templateUrl: './currencyform.component.html',
  styleUrls: ['./currencyform.component.css']
})
export class CurrencyformComponent implements OnInit {
  updateCurrencyForm: any;
  isCurrencyEdit: any = false;
  isPublished: boolean = true;
  isActiveDisable: boolean = true;
  response: any;
  isEditCB: any = 'legacy';
  subscription: Subscription;
  userrole: boolean;
  users:any;
  selectedValue: string;

  options = [
    {value: 'YES', viewValue: 'YES'},
    {value: 'NO', viewValue: 'NO'},
  ];

  constructor(
    private toastr: ToastrService,
    public _dialogService: DialogBoxService,
    private applicationInsightService: ApplicationInsightService,
    public account: AccountService,
    public _userAccess: UserAccess,
    public _currencyService:CurrencyService) {
    this.updateCurrencyForm = Object.assign({ active: true, draft: true });
    this.users = JSON.parse(localStorage.getItem("users") || "[]");
     }

  ngOnInit(): void {
    this.userrole =this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this._currencyForm.controls.lastUpdatedBy.setValue(JSON.parse(localStorage.getItem('UserInfo')).Email);
    this.subscription =
      this._currencyService.currencyId$.subscribe(
        currencyId => {
          this.updateCurrency(currencyId);
        });
  }

  public ngOnDestroy(): void {
    // this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }
  updateCurrency(currencyId: any) {
    this.applicationInsightService.logEvent("Currency Edit GetCurrencyId Started");
    this.isCurrencyEdit = true;
    this.isActiveDisable = false;
    this._currencyService.getCurrencyById(currencyId)
      .subscribe((data: any) => {
        this.updateCurrencyForm = data.data;
      });
    this.applicationInsightService.logEvent("Currency Edit GetCurrencyId Ended");
  }

  onSubmit() {
    // this._currencyForm.controls['currencyName'].updateValueAndValidity();
    // this._currencyForm.controls['currencySortOrder'].updateValueAndValidity();
    // console.log("Corporate Brand Insert: " + JSON.stringify(this._currencyForm.value))
    // if (this._currencyForm.valid) {
    //   if (this.updateCurrencyForm.currencyId === undefined || this.updateCurrencyForm.currencyId === null) {
    //     this.applicationInsightService.logEvent("Create New Currency started");
    //     this._currencyService.CreateCurrency(this._currencyForm.value).subscribe(response => {
    //       this.toastr.success(response.message, "", { timeOut: 10000 });
    //       this.onClear();
    //       this._currencyService.refreshCurrencyList();
    //     },
    //       error => { this.toastr.error(error, "", { timeOut: 10000 }) }
    //     )
    //     this.applicationInsightService.logEvent("Create New Currency ended");
    //   }
    //   else {
    //     this.applicationInsightService.logEvent("Update Corporatedbrand started");
    //     console.log("Corporate Brand Update: " + JSON.stringify(this._currencyForm.value))
    //     this._currencyService.UpdateCurrency(this._currencyForm.value).subscribe(response => {
    //       this.toastr.success(response.message, "", { timeOut: 10000 });
    //       this.onClear();
    //       this._currencyService.refreshCurrencyList();
    //       this.applicationInsightService.logEvent("Update Currency ended");
    //     },
    //       error => { this.toastr.error(error, "", { timeOut: 10000 }) }
    //     )
    //   }
    // }
  }
  deleteCurrency() {
    // this.applicationInsightService.logEvent("Delete Currency started");
    // this._currencyForm.controls.currencyId.setValue(this.updateCurrencyForm.currencyId);
    // this._dialogService.openConfirmDialog('Are you sure you want to delete this record?')
    //   .afterClosed().subscribe(res => {
    //     if (res) {
    //       this._currencyService.deleteCurrency(this._currencyForm.value).subscribe((response) => {
    //         this.toastr.success(response.message, "", { timeOut: 10000 });
    //         this.onClear();
    //         this._currencyService.refreshCurrencyList();
    //       },
    //         error => { this.toastr.error(error, "", { timeOut: 10000 }) });
    //     }
    //   })
    // this.applicationInsightService.logEvent("Delete Currency ended");
  }
  onClear() {
    this.applicationInsightService.logEvent("Currency clearform started");
    //Clearing validation 
    
    this._currencyForm.controls.isoCode.clearValidators();
    this._currencyForm.controls.currencyId.clearValidators();
    this._currencyForm.controls.currencyName.clearValidators();
    this._currencyForm.controls.currencySortOrder.clearValidators();
    this._currencyForm.controls.symbol.clearValidators();
    //Clearing control values 
    this._currencyForm.controls.isoCode.reset();
    this._currencyForm.controls.currencyId.reset();
    this._currencyForm.controls.currencyName.reset();
    this._currencyForm.controls.currencySortOrder.reset();
    this._currencyForm.controls.symbol.reset();
    this._currencyForm.controls.draft.setValue(true);
    this._currencyForm.controls.currencyName.setValidators([Validators.required, Validators.maxLength(30)]);
    this._currencyForm.controls.currencySortOrder.setValidators([Validators.required, Validators.min(1), Validators.max(9999)]);
    this.isCurrencyEdit = false;
    this.isActiveDisable = true;
    this.applicationInsightService.logEvent("Currency clearform ended");
  }
  //Create New corporate Brand Form Validation
  validPattern = "^[a-zA-Z0-9]+$";// alphanumeric exact 0 letters 
  _currencyForm: FormGroup = new FormGroup(
    {
      isoCode:new FormControl(),
      currencyName:new FormControl(),
      symbol:new FormControl(),
      description:new FormControl(),
      hmCurrency:new FormControl(),
      sellCurrency:new FormControl(),
      buyCurrency:new FormControl(),
      currencySortOrder: new FormControl('', [Validators.required, Validators.min(1), Validators.max(9999), Validators.pattern('^(?![-]).*')]),
      currencyId:new FormControl(''),
      draft: new FormControl(true),
      lastUpdatedBy: new FormControl()
    });

}
