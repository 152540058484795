<div class="wrap">
    <div class="fleft">
      <div class="ribbon">
        <div class="ribbon-stitches-top"></div>
        <div class="ribbon-content">
          <p>&nbsp;&nbsp;Fiscal Country</p>
        </div>
        <div class="clearfix"></div>
        <div class="ribbon-stitches-bottom"></div>
      </div>
      <div class="add-div">
  
        <form [formGroup]="_searchform" >       
            <mat-form-field [floatLabel]="'always'" appearance="outline" class="width-15">
                <mat-label>Country Name</mat-label>
      
                <input type="text" id="SearchfiscalCountryName" matInput [matAutocomplete]="autofiscalCountryName" 
                formControlName="fiscalCountryName"  [(ngModel)]="fiscalCountryName" (ngModelChange)="filterItemfiscalcountryName($event)">
      
                <mat-autocomplete autoActiveFirstOption #autofiscalCountryName="matAutocomplete">
                    <mat-option *ngFor="let FCname of fiscalcountryNameFilterList" [value]="FCname.geographicalCountryName">
                        {{FCname.geographicalCountryName}}
                    </mat-option>
                </mat-autocomplete>
             </mat-form-field>
             <mat-form-field [floatLabel]="'always'" appearance="outline" class="width-15  ml-1">
                <mat-label>ISO Code</mat-label>
      
                <input type="text" id="SearchfiscalCountryISOCode" matInput [matAutocomplete]="autofiscalcountryIso" 
                formControlName="fiscalCountryISOCode"  [(ngModel)]="fiscalCountryISOCode" (ngModelChange)="filterItemfiscalcountryIso($event)">
      
                <mat-autocomplete autoActiveFirstOption #autofiscalcountryIso="matAutocomplete">
                    <mat-option *ngFor="let FCname of fiscalcountryIsoFilterList" [value]="FCname.geographicalCountryAlpha2ISOCode">
                        {{FCname.geographicalCountryAlpha2ISOCode}}
                    </mat-option>
                </mat-autocomplete>
             </mat-form-field>
             <mat-form-field [floatLabel]="'always'" appearance="outline" class="width-15  ml-1">
                <mat-label>Fiscal Country Code</mat-label>
      
                <input type="text" id="SearchfiscalCountryCode" matInput [matAutocomplete]="autofiscalcountryCode" 
                formControlName="fiscalCountryCode"  [(ngModel)]="fiscalCountryCode" (ngModelChange)="filterItemfiscalcountryCode($event)">
      
                <mat-autocomplete autoActiveFirstOption #autofiscalcountryCode="matAutocomplete">
                    <mat-option *ngFor="let FCname of fiscalcountryCodeFilterList" [value]="FCname.fiscalCountryCode">
                        {{FCname.fiscalCountryCode}}
                    </mat-option>
                </mat-autocomplete>
             </mat-form-field>
             <mat-form-field [floatLabel]="'always'" appearance="outline" class="width-11 ml-1">
              <mat-label>Status</mat-label>
              <mat-select id="Searchstatus" formControlName="status" [(ngModel)]="status">
                <mat-option value="all">
                  All
                </mat-option>
                <mat-option value="active">
                  Active
                </mat-option>
                <mat-option value="inactive">
                  Inactive
                </mat-option>
                <mat-option value="draft">
                  Draft
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button id="SearchClear" (click)="onSearchClear()" mat-raised-button class="add-button-container mat-raised-button mat-primary ml-2" >
              Clear
            </button>
            
             
             </form>
  
      </div>
      <app-fiscalcountrylist #fiscalCountryList (childButtonEvent)="receivedChildHandler($event)" [FiscalCountrySearch]=_searchform.value></app-fiscalcountrylist>
    </div>
    <div class="clear"></div>
    <div class="fright">
      <app-fiscalcountryform ></app-fiscalcountryform>
    </div>
  </div>
