import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { IndexService } from 'src/app/services/organization/index/index.service';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CorporatebrandService } from 'src/app/services/organization/corporatebrand/corporatebrand.service';
import { IndexgroupService } from 'src/app/services/organization/indexgroup/indexgroup.service';
import { AccountService } from '../../../../shared/services/account.service'
import { CommonValidationService } from 'src/app/services/organization/commonutility/commonValidation.service';
import { Subscription } from 'rxjs';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { UserAccess } from 'src/app/models/userAccess';
import { ModulePermission } from 'src/app/models/enum';

export class dropDownCollections {
  corporateBrandList: any;
  indexGroupList: any;
  indexCodeList: any;
}

@Component({
  selector: 'app-indexform',
  templateUrl: './indexform.component.html',
  styleUrls: ['./indexform.component.css']
})

export class IndexformComponent implements OnInit {
 
  @Output() childButtonEvent = new EventEmitter();
  updateIndexForm: any;
  isIndexEdit: any = false;
  isIndexEditCB: any = 'legacy';
  validationMessages: any;
  corporateBrandMasterlist: any[] = [];
  corporateBrandFilterList: any[] = [];
  corporateBrandIdGetIC: any = "";
  indexGroupMasterList: any[] = [];
  indexGroupFilterList: any[] = [];
  indexGroupNameList: any[] = [];
  acIndexCodeList: any;
  ICFilterList: any;
  subIndexForm: any;
  isDisableActive: boolean = true;
  subscription: Subscription;
  validOptions: any;
  errorMsg: any;
  userrole: boolean;
  users:any;
  constructor(public _corporatebrandService: CorporatebrandService, 
    public _indexgroupService: IndexgroupService,
    public _indexService: IndexService,
    public route: ActivatedRoute, 
    public _commonvalidationService: CommonValidationService, 
    private toastr: ToastrService, 
    public account: AccountService,
    private applicationInsightService:ApplicationInsightService,
    public _userAccess: UserAccess) { 
      this.users = JSON.parse(localStorage.getItem("users") || "[]")
    }

  ngOnInit(): void {
    this.applicationInsightService.logEvent("Index form page initialization started");
    this.userrole =this.users.includes(ModulePermission.Organization_Write) ? true : false;
     this._indexform.controls.lastUpdatedBy.setValue(JSON.parse(localStorage.getItem("UserInfo")).Email || "[]");
    this.validationMessages = this._commonvalidationService.validationMsgs;
    this.updateIndexForm = Object.assign({ active: true, draft: true });
    this._corporatebrandService.getCorporateBrandList().subscribe((data: any) => {
      this.corporateBrandMasterlist = this.corporateBrandFilterList = data.getCorporateBrandAll.filter(object => { return object['active'] !== false; });
      this.corporateBrandFilterList = this.corporateBrandMasterlist.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
      this.loadDropdown();
    });
    this._indexgroupService.getIndexGroupAll().subscribe((data: any) => {
      this.indexGroupMasterList = this.indexGroupNameList = data.indexGroup.filter(object => { return object['active'] !== false; });
      this.indexGroupNameList = this.indexGroupMasterList.sort((a, b) => a.indexGroupName.localeCompare(b.indexGroupName));
      this.loadDropdown();
    });
    this.fnLoadIndexCde("");
    this.subscription = this._indexService.indexId$.subscribe(indexId => { this.fnUpdateIndex(indexId) });
    this.applicationInsightService.logEvent("Index form page initialization ended");
  }

  public ngOnDestroy(): void { this.subscription.unsubscribe(); }

  loadDropdown() {
    this.applicationInsightService.logEvent("Index form page loadDropdown started");
    /* istanbul ignore if */
    if (this.corporateBrandMasterlist.length > 0 && this.indexGroupMasterList.length > 0) {
      let obj = new dropDownCollections();
      obj.corporateBrandList = this.corporateBrandMasterlist;
      obj.indexGroupList = this.indexGroupMasterList;
      this.childButtonEvent.emit(obj);
    }
    this.applicationInsightService.logEvent("Index form page loadDropdown ended");
  }

  fnUpdateIndex(indexId: any) {
    this.applicationInsightService.logEvent("UpdateIndex started");
    this.isDisableActive = false;
    this._indexform.controls.indexCode.setValidators([this.autocompleteIndexCodeValidator(false), Validators.required, Validators.maxLength(20)]);
    this.isIndexEdit = true;
    this._indexService.getIndexById(indexId)
      .subscribe((data: any) => {
        this.updateIndexForm = data.index;
        this.isIndexEditCB = this.updateIndexForm.active == true ? 'fill' : 'legacy';
      }),
      error => { this.toastr.error(error, "", { timeOut: 10000 }); };

    this._indexService.getSubIndexByIndexId().subscribe((data: any) => {
      this.subIndexForm = data.subIndex.filter(object => {
        return object['indexId'] == indexId;
      })
      if (this.subIndexForm.length == 0) {
        this.isDisableActive = false;
      }
    }),
      error => { this.toastr.error(error, "", { timeOut: 10000 }); };
      document.getElementById("scrolltop").scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      this.applicationInsightService.logEvent("UpdateIndex ended");
  }

  displayTextCB(corporateBrandId: string) {
     if (corporateBrandId === null || corporateBrandId === "")
      return null;
     else if (this.corporateBrandMasterlist != undefined && corporateBrandId != null)
       return this.corporateBrandMasterlist.find(x => x.corporateBrandId == corporateBrandId).corporateBrandCodeName;
  }

  filterItemCB(filterKeyCB: string) {
    this.applicationInsightService.logEvent("Index form filter corporateBrand started");
    let newCBList: any[] = [];
    newCBList = this.corporateBrandMasterlist;
    if (filterKeyCB !== null && filterKeyCB !== "" && filterKeyCB !== undefined) {
      this.corporateBrandFilterList = newCBList.filter(cb => cb.corporateBrandName.toLowerCase().includes(filterKeyCB.toString().toLowerCase()))
      this.corporateBrandFilterList = this.corporateBrandFilterList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
      if (typeof (filterKeyCB) === "number") {
        this.corporateBrandIdGetIC = filterKeyCB;
        this.fnLoadIndexCde(filterKeyCB)
      }
    } else {
      this._indexform.controls.indexCode.setValue('');
      this._indexform.controls.indexGroupId.reset();
      this.indexGroupFilterList = [];
      this.fnLoadIndexCde("")
      this.corporateBrandFilterList = this.corporateBrandMasterlist.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
    }
    this.applicationInsightService.logEvent("Index form filter corporateBrand ended");
  }

  fnLoadIndexCde(corporateBrandId: any) {
    this.applicationInsightService.logEvent("Index form LoadIndexCode started");
    this.corporateBrandIdGetIC = corporateBrandId;
    this._indexService.getIndexCode(corporateBrandId).subscribe((data: any) => {
      this.validOptions = this.acIndexCodeList = this.ICFilterList = data.indexCodes;
    })
    this.applicationInsightService.logEvent("Index form LoadIndexCode ended");
  }

  displayTextIG(indexGroupId: string) {
    if (indexGroupId == null || indexGroupId == "")
      return null;
    else if (this.indexGroupMasterList != undefined && indexGroupId != null)
      return this.indexGroupMasterList.find(x => x.indexGroupId === indexGroupId).indexGroupCodeName;
  }

  filterItemIG(filterKeyIG: string) {
    this.applicationInsightService.logEvent("Index form filter indexGroupName started");
    if (filterKeyIG != null && filterKeyIG != "" && typeof this._indexform.controls['corporateBrandId'].value == "number") {
      this.indexGroupFilterList = this.indexGroupNameList.filter(x => x.corporateBrandId == this._indexform.controls.corporateBrandId.value).filter(ig => ig.indexGroupName.toLowerCase().includes(filterKeyIG.toString().toLowerCase()))
      this.indexGroupFilterList = this.indexGroupFilterList.sort((a, b) => a.indexGroupName.localeCompare(b.indexGroupName));
    }
    else if (typeof this._indexform.controls.corporateBrandId.value == "number") {
      this.indexGroupFilterList = this.indexGroupNameList.filter(x => x.corporateBrandId == this._indexform.controls.corporateBrandId.value).sort((a, b) => a.indexGroupName.toString().localeCompare(b.indexGroupName.toString()));
    }
    this.applicationInsightService.logEvent("Index form filter indexGroupName ended");
  }

  filterItemIC(filterKeyIC: string) {
    this.applicationInsightService.logEvent("Index form filter indexGroupName started");
    let corporateBrandId = this._indexform.controls['corporateBrandId'].value
    if (filterKeyIC != undefined && filterKeyIC != "" && filterKeyIC != null
      && corporateBrandId != undefined && corporateBrandId != "" && corporateBrandId != null) {
      if (this.ICFilterList.includes(filterKeyIC.toUpperCase())) {
        this.ICFilterList = [filterKeyIC.toUpperCase()];
      }
    }
    else { this.ICFilterList = this.acIndexCodeList; }
    this.applicationInsightService.logEvent("Index form filter indexGroupName ended");
  }

  onSubmit() {
    this.applicationInsightService.logEvent("Index form onSubmit started");
    console.log("INDEX BEFORE " + JSON.stringify(this._indexform.value))
    if (this._indexform.controls.active.value === undefined || this._indexform.controls.active.value === null) { this._indexform.controls.active.setValue(false); }
    if (this._indexform.controls.draft.value === undefined || this._indexform.controls.draft.value === null) { this._indexform.controls.draft.setValue(true); }

    this._indexform.controls['corporateBrandId'].updateValueAndValidity();
    this._indexform.controls['indexGroupId'].updateValueAndValidity();
    this._indexform.controls['indexCode'].updateValueAndValidity();
    this._indexform.controls['indexName'].updateValueAndValidity();
    this._indexform.controls['indexSortOrder'].updateValueAndValidity();
    console.log("INDEX Before " + JSON.stringify(this._indexform.value))
    if (this._indexform.valid) {
      console.log("INDEX AFTER " + JSON.stringify(this._indexform.value))
      if (this._indexform.value.indexId == undefined || this._indexform.value.indexId == 0 || this._indexform.value.indexId == null) {
        this._indexService.createIndex(this._indexform.value).subscribe
          (data => {
            this.toastr.success(data.message, "", { timeOut: 10000 });
            this.onClear();
            this._indexService.refreshIndexList();
            this.childButtonEvent.emit();
          },
            error => { this.toastr.error(error, "", { timeOut: 10000 }) })
      }
      else {
        this._indexService.updateIndex(this._indexform.value).subscribe
          (data => {
            this.toastr.success(data.message, "", { timeOut: 10000 });
            this.onClear();
            this._indexService.refreshIndexList();
            this.childButtonEvent.emit();
          },
            error => { this.toastr.error(error, "", { timeOut: 10000 }) })
      }
    }
    this.applicationInsightService.logEvent("Index form onSubmit ended");
  }

  onClear() {
    this.applicationInsightService.logEvent("Index form onClear started");
    this.displayTextCB(null);
    this.displayTextIG(null);
    this.subIndexForm = null;
    this._indexform.controls.corporateBrandId.clearValidators();
    this._indexform.controls.indexGroupId.clearValidators();
    this._indexform.controls.indexCode.clearValidators();
    this._indexform.controls.indexName.clearValidators();
    this._indexform.controls.indexSortOrder.clearValidators();

    this._indexform.controls.indexId.reset();
    this._indexform.controls.corporateBrandId.reset();
    this._indexform.controls.indexGroupId.reset();
    this._indexform.controls.indexCode.reset();
    this._indexform.controls.indexName.reset();
    this._indexform.controls.indexSortOrder.reset();
    this._indexform.controls.active.setValue(true);
    this._indexform.controls.draft.setValue(true);

    this._indexform.controls.corporateBrandId.setValidators([this._commonvalidationService.acObjectValidator(), Validators.required]);
    this._indexform.controls.indexGroupId.setValidators([this._commonvalidationService.acObjectValidator(), Validators.required]);
    this._indexform.controls.indexName.setValidators([Validators.required, Validators.maxLength(250)]);
    this._indexform.controls.indexCode.setValidators([this.autocompleteIndexCodeValidator(true), Validators.required, Validators.maxLength(20)]);
    this._indexform.controls.indexSortOrder.setValidators([Validators.required, Validators.min(1), Validators.max(9999)])

    this.isIndexEdit = false;
    this.isIndexEditCB = 'legacy';
    this.subIndexForm = [];
    this.isDisableActive = true;
    this.applicationInsightService.logEvent("Index form onClear ended");
  }

  _indexform: FormGroup = new FormGroup({
    indexId: new FormControl(),
    corporateBrandId: new FormControl('', [this._commonvalidationService.acObjectValidator(), Validators.required]),
    indexGroupId: new FormControl('', [this._commonvalidationService.acObjectValidator(), Validators.required]),
    indexSortOrder: new FormControl('', [Validators.required, Validators.min(1), Validators.max(9999), Validators.pattern('^(?![-]).*')]),
    indexName: new FormControl('', [Validators.required, Validators.maxLength(250)]),
    indexCode: new FormControl('', [this.autocompleteIndexCodeValidator(true), Validators.required, Validators.maxLength(20)]),
    active: new FormControl(true),
    lastUpdatedBy: new FormControl(),
    draft: new FormControl(true),
  });

  autocompleteIndexCodeValidator(isValidate: boolean): ValidatorFn {
    this.applicationInsightService.logEvent("Index form autocompleteIndexCodeValidator started");
    if (isValidate) {
      this.validOptions = this.acIndexCodeList;
      return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.value != null && control.value != ""
          && control.value != undefined && this.validOptions != null
          && this.validOptions != undefined && this.validOptions.indexOf(control.value) !== -1) {
          this.applicationInsightService.logEvent("Index form autocompleteIndexCodeValidator ended");
          return null  /* valid option selected */
        }
        this.applicationInsightService.logEvent("Index form autocompleteIndexCodeValidator ended");
        return control.value != undefined ? { 'invalidAutocomplete': { value: control.value } } : null;
      } 
    }
    else {
      return (control: AbstractControl): { [key: string]: any } | null => {
        this.applicationInsightService.logEvent("Index form autocompleteIndexCodeValidator ended");
        return null;
      }
    } 
  }
  
}
