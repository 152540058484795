import { Component, OnInit, ViewChild} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CorporatebrandService } from 'src/app/services/organization/corporatebrand/corporatebrand.service';
import { IndexgroupService } from 'src/app/services/organization/indexgroup/indexgroup.service';
import { IndexService } from 'src/app/services/organization/index/index.service';
import { Title } from '@angular/platform-browser';
import { IndexlistComponent } from '../indexlist/indexlist.component';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
@Component({
  selector: 'index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
 
  @ViewChild('indexlist') public indexList: IndexlistComponent;
  title="Index";
  corporateBrandMasterList: any[] = [];
  corporateBrandFilterList: any[] = [];
  indexGroupMasterList: any[] = [];
  cloneIndexGroupMasterList: any[] = [];
  indexGroupFilterList: any[] = [];
  indexCodeMasterList: any[] = [];
  cloneindexCodeMasterList: any[] = [];
  indexCodeFilterList: any[] = [];
  indexNameFilterList: any[] = [];
  indexNameMasterList: any[] = [];
  cloneindexNameMasterList: any[] = [];
  obj: any[]=[];
  corporateBrandName: any = "";
  indexGroupName: any = "";
  indexCode: any = "";
  indexName: any = '';
  status: any = "active";
  
  constructor(public _corporatebrandService: CorporatebrandService,
    public _indexgroupService: IndexgroupService,
    public _indexService: IndexService, 
    private titleService:Title,
    private applicationInsightService:ApplicationInsightService) { }
  
  ngOnInit(): void {this.titleService.setTitle(this.title);}

  receivedChildHandler(obj:any) {
    this.applicationInsightService.logEvent("Index receivedChildHandler started");
    /* istanbul ignore if */
    if (obj.corporateBrandList != null) {
     this.corporateBrandMasterList = this.corporateBrandFilterList = obj.corporateBrandList.sort((a, b) => a.corporateBrandCode.toString().localeCompare(b.corporateBrandCode));
     var distinctIndexGroupName = obj.indexGroupList;
     this.indexGroupMasterList = this.indexGroupFilterList = distinctIndexGroupName.sort((a, b) => a.indexGroupCode.toString().localeCompare(b.indexGroupCode));
     this.indexGroupMasterList= this.indexGroupMasterList.sort((a, b) => a.indexGroupCode.toString().localeCompare(b.indexGroupCode))
    }
    this.applicationInsightService.logEvent("Index receivedChildHandler ended");
  }

  indexCodeDropDownList(obj:any) {
    this.applicationInsightService.logEvent("IndexCode DropDownList binding started");
    if (obj != null) {
      this.indexNameFilterList = this.indexNameMasterList = obj;
      this.indexCodeFilterList = this.indexCodeMasterList = obj.sort((a, b) => a.indexCode.toString().localeCompare(b.indexCode));
      this.indexNameFilterList = this.indexNameMasterList = this.indexNameMasterList.sort((a, b) => a.indexCode.toString().localeCompare(b.indexCode));
     }
    this.applicationInsightService.logEvent("IndexCode DropDownList binding ended");
  }

  filterItemCorporateBrand(filterKey: any) {
    this.applicationInsightService.logEvent("Filter Corporatebrand Started");
    this.onFilter()
    if (isNaN(filterKey)) {
      this.corporateBrandFilterList = this.corporateBrandMasterList.filter(cb => cb.corporateBrandName.toLowerCase().includes(filterKey.toLowerCase()));
      this.corporateBrandFilterList = this.corporateBrandFilterList.sort((a, b) => (a.corporateBrandCode > b.corporateBrandCode ? 1 : -1));
    } else {
      this.searchform.controls.indexGroupName.setValue('');
      this.corporateBrandFilterList = this.corporateBrandMasterList.filter(cb => cb.corporateBrandCode.toLowerCase().includes(filterKey.toLowerCase()));
      this.corporateBrandFilterList = this.corporateBrandFilterList.sort((a, b) => (a.corporateBrandCode > b.corporateBrandCode ? 1 : -1));
      this.onFilter();
    }
    this.applicationInsightService.logEvent("Filter Corporatebrand Ended");
  }
  
  filterItemIN(filterKeyIN: string) {
    this.applicationInsightService.logEvent("Index filterItemIndexName started");
    if(this.searchform?.controls?.corporateBrandName?.value){
      this.onFilter()
    }
    setTimeout(() => {
      if (filterKeyIN != null && filterKeyIN != "") {
        if(filterKeyIN.length===1){
          this.indexNameFilterList = this.indexNameMasterList.filter(ic => ic.indexCode.toString().toLowerCase().includes(filterKeyIN.toString().toLowerCase()));
          this.indexNameFilterList = this.indexNameFilterList.sort((a, b) => a.indexCode.localeCompare(b.indexCode));
        }else{
          this.indexNameFilterList = this.indexNameMasterList.filter(ic => ic.indexName.toString().toLowerCase().includes(filterKeyIN.toString().toLowerCase()));
          this.indexNameFilterList = this.indexNameFilterList.sort((a, b) => a.indexCode.localeCompare(b.indexCode));
        }
       
      }
      else { this.indexNameFilterList = this.indexNameMasterList.sort((a, b) => a.indexCode.localeCompare(b.indexCode)); }
      this.applicationInsightService.logEvent("Index filterItemIndexName ended");
    }, 500);
   
  }

  filterItemIndexGroup(filterKey: any) {
    this.applicationInsightService.logEvent("Index filterItemIndexGroupName started");
    if(this.searchform?.controls?.corporateBrandName?.value){
      this.onFilter()
    }
    setTimeout(() => {
   if (filterKey != null  && filterKey != ""){
    if (isNaN(filterKey)) {
      this.indexGroupFilterList = this.indexGroupMasterList.filter(gn => gn.indexGroupName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
    } else {
      this.indexGroupFilterList = this.indexGroupMasterList.filter(gg => gg.indexGroupCode.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
    }
    this.indexGroupFilterList = this.indexGroupFilterList.sort((a,b) => (a.indexGroupCode > b.indexGroupCode ? 1 : -1));
    }else
    {this.indexGroupFilterList=this.indexGroupMasterList.sort((a,b) => (a.indexGroupCode > b.indexGroupCode ? 1 : -1));}
  }, 500);
    this.applicationInsightService.logEvent("IndexGroupName filtering ended"); 
 }
// filter function based on selection
onFilter(){
  setTimeout(() => {
    if (this.cloneIndexGroupMasterList?.length === 0) {
      this.cloneIndexGroupMasterList = this.indexGroupMasterList;
    }

    if (this.cloneindexNameMasterList.length === 0) {
      this.cloneindexNameMasterList = this.indexNameMasterList;
    }

    // this code for filter the indexgroup,index code and indexname based on corporatebrand selection 
    if(this.searchform?.controls?.corporateBrandName?.value){
      this.indexGroupMasterList = this.cloneIndexGroupMasterList.filter(x => x.corporateBrandCodeName === this.searchform?.controls?.corporateBrandName?.value)
      this.indexGroupFilterList = this.indexGroupMasterList

      this.indexNameMasterList = this.cloneindexNameMasterList.filter(x => x.corporateBrandCodeName === this.searchform?.controls?.corporateBrandName?.value)
      this.indexNameFilterList = this.indexNameMasterList
    }
    else {
      this.indexGroupFilterList = this.cloneIndexGroupMasterList
      this.indexNameFilterList = this.cloneindexNameMasterList
      this.indexGroupMasterList = this.cloneIndexGroupMasterList
      this.indexNameMasterList =  this.cloneindexNameMasterList
    }

    if(this.searchform?.controls?.corporateBrandName?.value !== '' && 
      this.searchform.controls.indexGroupName.value){
        this.indexNameMasterList = this.cloneindexNameMasterList.filter(x=>{
        return x.indexGroupCodeName === this.searchform.controls.indexGroupName.value;
      }).filter(x=>x.corporateBrandCodeName === this.searchform?.controls?.corporateBrandName?.value)
      this.indexNameFilterList =  this.indexNameMasterList;
    }

  // this code for filter the indexgroup and index code based on index name dropdown
  if(this.searchform?.controls?.corporateBrandName?.value !== '' && 
      this.searchform.controls.indexName.value){
      this.indexGroupMasterList = this.cloneindexNameMasterList.filter(x=>{
      return x.indexCodeName ===  this.searchform.controls.indexName.value;
    }).filter(x=>x.corporateBrandCodeName === this.searchform?.controls?.corporateBrandName?.value)
    this.indexGroupFilterList =  this.indexGroupMasterList;
  }
  }, 500);
}
  //Clear button 
  onSearchClear() {
    this.applicationInsightService.logEvent("Index onSearchClear started");
    this.searchform.controls.corporateBrandName.setValue('');
    this.searchform.controls.corporateBrandCodeName.setValue('');
    this.searchform.controls.indexGroupName.setValue('');
    this.searchform.controls.indexGroupCodeName.setValue('');
    this.searchform.controls.indexCode.setValue('');
    this.searchform.controls.indexName.setValue('');
    this.searchform.controls.indexCodeName.setValue('');
    this.searchform.controls.status.reset();
    this.searchform.controls['status'].setValue("active");

    this.filterItemCorporateBrand("");
    this.filterItemIN("");
    this.filterItemIndexGroup("");
    this.applicationInsightService.logEvent("Index onSearchClear ended");
  }

  //Used for search option
  searchform: FormGroup = new FormGroup({
    corporateBrandName: new FormControl(''),
    corporateBrandCodeName: new FormControl(''),
    indexGroupName: new FormControl(''),  
    indexGroupCodeName: new FormControl(''),  
    indexCode: new FormControl(''),
    indexName: new FormControl(''),
    indexCodeName: new FormControl(''),
    status: new FormControl()
  })
}
