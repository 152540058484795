<div class="wrap">
  <div class="filter-div">
    <div class="org-container">
      <form [formGroup]="_searchform">
        <mat-form-field appearance="legacy" class="customform">
          <mat-label>Garment Group </mat-label>

          <input type="text" id="Searchgarmentgroupname" matInput [matAutocomplete]="autoGN"
            formControlName="garmentGroupName" [(ngModel)]="garmentGroupName"
            (ngModelChange)="filterItemgarmentgroupName($event)">

          <mat-autocomplete autoActiveFirstOption #autoGN="matAutocomplete">
            <mat-option *ngFor="let ggname of garmentGroupNameFilterList" [value]="ggname.garmentGroupCodeName" [matTooltipDisabled]="ggname.garmentGroupName.length<8"  matTooltip="{{ggname.garmentGroupCode}}&nbsp;{{ggname.garmentGroupName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
              {{ggname.garmentGroupCode}} {{ggname.garmentGroupName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>


        <mat-form-field appearance="legacy" class="statusclass">
          <mat-label>Status</mat-label>
          <mat-select id="Searchstatus" formControlName="status" [(ngModel)]="status">
            <mat-option value="all">
              All
            </mat-option>
            <mat-option value="active">
              Active
            </mat-option>
            <mat-option value="inactive">
              Inactive
            </mat-option>
            <mat-option value="draft">
              Draft
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-button class="add-button-container mat-raised-button mat-primary ml-2" 
        id="searchClear" type="submit" (click)="onSearchClear()" >Clear</button>
      </form>

    </div>
    <div class="clearfix"></div>
    <div class="tblleft">
      <app-garmentgrouplist (childButtonEvent)="receivedChildHandler($event)" [garmentGroupSearch]=_searchform.value>
      </app-garmentgrouplist>
    </div>
    <div class="frmright">
      <app-garmentgroupform></app-garmentgroupform>
    </div>
  </div>