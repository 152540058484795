<div class="data-table" class="org-table">
  <table id="tblSection" [dataSource]="dataSource" #table mat-table class="full-width-table"
    (matSortChange)="sortData($event)" matSort aria-label="Elements">

    <ng-container matColumnDef="corporateBrandName">
      <th matSort id="corporateBrandName" (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef
        mat-sort-header>Corporate Brand </th>
      <td mat-cell *matCellDef="let row"
        [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')"
        [matTooltipDisabled]="row.corporateBrandName.length<15" matTooltip={{row.corporateBrandName}}
        matTooltipPosition="above" matTooltipClass="my-custom-tooltip">
        {{row.corporateBrandCode}} {{row.corporateBrandName}}</td>
    </ng-container>

    <ng-container matColumnDef="divisionCode">
      <th matSort id="divisionCode" (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef
        mat-sort-header>Division
      </th>
      <td mat-cell *matCellDef="let row"
        [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')"
        [matTooltipDisabled]="row.isdisableDN" matTooltip="{{row.divisionCode+'-'+row.divisionName}}"
        matTooltipPosition="left">
        {{row.divisionCode}} {{row.divisionName}}</td>
    </ng-container>

    <ng-container matColumnDef="sectionCode">
      <th matSort id="sectionCode" (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header>
        Section <br>  Code
      </th>
      <td mat-cell *matCellDef="let row" id="custom-col100"
        [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.sectionCode}}</td>
    </ng-container>

    <ng-container matColumnDef="sectionName">
      <th matSort id="sectionName" (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header>
        Section
      </th>
      <td mat-cell *matCellDef="let row"
        [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')"
        [matTooltipDisabled]="row.sectionName.length<15" matTooltip={{row.sectionName}} matTooltipPosition="left">
        {{row.sectionName}}</td>
    </ng-container>

    <ng-container matColumnDef="sectionSortOrder">
      <th matSort id="sectionSortOrder" (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef
        mat-sort-header>Sort Order</th>
      <td mat-cell *matCellDef="let row" id="custom-col100"
        [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.sectionSortOrder}}</td>
    </ng-container>
    <ng-container matColumnDef="lastUpdatedTime">
      <th mat-header-cell matSort (matSortChange)="sortData($event)" *matHeaderCellDef mat-sort-header
        id="lastUpdatedTime">Updated Date</th>
      <td mat-cell *matCellDef="let row"
        [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        <span *ngIf="!customDateTime">{{ row.lastUpdatedTime | customDateTime }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef id="Actions"><span *ngIf="userrole">Edit | </span>View</th>
      <td mat-cell *matCellDef="let row" id="custom-col100"
        [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        <a *ngIf="userrole" id="btnEdit" color="primary" (click)="updateRecord(row)" matTooltip="Edit" matTooltipPosition="right"
          class="org-edit">Edit | </a>
        <a id="btnView" (click)="openDialog(row)" matTooltip="View Details" matTooltipPosition="right"
          class="org-edit">View</a>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell aligncenter" colspan="8">No records found</td>
    </tr>
  </table>
  <div id="main-wrap">
    <section id="sidebar">
      <div class="example-button-row">
        <div class="example-flex-container indicator">
          <button mat-icon-button aria-label="Datatable color code">
            <mat-icon><span class="material-icons activeindicator">
                stop
              </span></mat-icon>
            Active
          </button>
          <button mat-icon-button aria-label="Datatable color code">
            <mat-icon><span class="material-icons inactiveindicator">
                stop
              </span></mat-icon>
            Inactive
          </button>
          <button mat-icon-button aria-label="Datatable color code">
            <mat-icon><span class="material-icons draftindicator">
                stop
              </span></mat-icon>
            Draft
          </button>
          <button mat-icon-button aria-label="Datatable color code">
            <mat-icon><span class="material-icons firstdraftindicator">
                stop
              </span></mat-icon>
            First Draft
          </button>
        </div>
      </div>
    </section>
    <mat-paginator #paginator id="tblPaginator" [(length)]="length" [(pageSize)]="pageSize"
      [pageSizeOptions]="getpagesizeoptions()" (page)="pageEvent = GetSectionDatawithfilters($event)"
      showFirstLastButtons>
    </mat-paginator>
  </div>
</div>