<div class="fixed-content mat-elevation-z2 form-border" id="scrolltop">
  <form [formGroup]="_indexgroupform" id="indexgroupform">
    <section class="form-section">
      <h2 mat-dialog-title class="align">{{isIndexGroupEdit === true ? 'Update' : 'New'}} Index Group</h2>
    </section>
    <div class="example-container add-form-field">
      <mat-form-field [appearance]="isIndexGroupEditCB">
        <mat-label>Corporate Brand</mat-label>

        <input type="text" id=inpcorporatebrand matInput [matAutocomplete]="autoCB" formControlName="corporateBrandId"
          (ngModelChange)="filterItemCorporateBrand($event)" [(ngModel)]="updateIndexGroupForm.corporateBrandId"
          [readonly]="isIndexGroupEditCB=='fill' ?true:false">
        <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
          <div *ngIf="_indexgroupform.controls['corporateBrandId'].hasError(validation.type)">
            {{validation.message}}
          </div>
        </mat-error>
        <mat-error *ngIf="_indexgroupform.controls['corporateBrandId'].errors?.required"></mat-error>
        <mat-error *ngIf="_indexgroupform.controls['corporateBrandId'].errors?.maxlength">
          Maximum 30 Characters allowed
        </mat-error>
        <mat-autocomplete autoActiveFirstOption #autoCB="matAutocomplete" [displayWith]="displayTextCB.bind(this)">
          <mat-option *ngFor="let CBname of corporateBrandFilterIGList" [value]="CBname.corporateBrandId">
            {{CBname.corporateBrandCode}}  {{CBname.corporateBrandName}}
          </mat-option>
        </mat-autocomplete>

      </mat-form-field>


      <mat-form-field [appearance]="isIndexGroupEdit==true ? 'fill': 'legacy'">
        <mat-label>Index Group Code</mat-label>
        <input id=inpindexGroupCode formControlName="indexGroupCode" matInput type="number" min="0"
          [(ngModel)]="updateIndexGroupForm.indexGroupCode" pattern="^(0|[1-9][0-9]*)$" [readonly]=isIndexGroupEdit>
        <mat-error *ngIf="_indexgroupform.controls['indexGroupCode'].errors?.required"></mat-error>
        <mat-error *ngIf="_indexgroupform.controls['indexGroupCode'].errors?.minlength"> Negative values are not
          allowed.</mat-error>
        <mat-error *ngIf="_indexgroupform.controls['indexGroupCode'].errors?.max">The range for Index Group Code is
          1-1000</mat-error>
        <mat-error *ngIf="_indexgroupform.controls['indexGroupCode'].errors?.pattern">Only Numeric Values allowed
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="legacy">
        <mat-label>Index Group</mat-label>
        <input formControlName="indexGroupName" matInput id=inpindexgroupname
          [(ngModel)]="updateIndexGroupForm.indexGroupName">
        <mat-error *ngIf="_indexgroupform.controls['indexGroupName'].errors?.required"></mat-error>
        <mat-error *ngIf="_indexgroupform.controls['indexGroupName'].errors?.maxlength">Maximum 20 charactor allowed
        </mat-error>
        <mat-error *ngIf="_indexgroupform.controls['indexGroupName'].errors?.pattern">only alpha and numeric allowed
        </mat-error>
      </mat-form-field>
      <br>

      <mat-form-field appearance="legacy">
        <mat-label>Sort Order</mat-label>
        <input id="inpSortOrder" formControlName="indexGroupSortOrder" min="0" matInput type="number"
          [(ngModel)]="updateIndexGroupForm.indexGroupSortOrder" pattern="^(0|[1-9][0-9]*)$">
        <mat-error *ngIf="_indexgroupform.controls['indexGroupSortOrder'].errors?.max">The range for sort order is
          1-9999</mat-error>
        <mat-error *ngIf="_indexgroupform.controls['indexGroupSortOrder'].errors?.pattern">Only Numeric Values allowed
        </mat-error>
      </mat-form-field>
      <br />

      <section class="example-section">
        <mat-checkbox [disabled]="isDisableActive" id="inpactive" formControlName="active"
          [(ngModel)]="updateIndexGroupForm.active" class="example-margin width-25" color='primary'>Active
        </mat-checkbox>
        <mat-checkbox formControlName="draft" id="inpdraft" [(ngModel)]="updateIndexGroupForm.draft"
          class="example-margin width-15" color='primary'>Draft</mat-checkbox>
      </section>
      <br>
      <mat-dialog-actions *ngIf="userrole" style="display: block !important">

        <button [disabled]="(isIndexGroupEdit === true) ?  !_indexgroupform.dirty : false"
          [ngClass]="(isIndexGroupEdit === true) ? 'mat-raised-button ml-1':'mat-raised-button ml-1 mat-primary'"
          id="btnSaveUpdate" type="submit" (click)="onSubmit()">
          {{isIndexGroupEdit === true ? 'Update' : 'Save'}}
        </button>

        <button class="mat-raised-button mat-primary ml-1" id="btnclear" (click)="onClear()">Cancel</button>
      </mat-dialog-actions>
      <span *ngIf="!userrole" style="color: #ff0202;font-size: 12px;font-weight: 400;">You can't save changes since you
        have Read access.</span>
      <input type="hidden" id="inpindexgroupid" formControlName="indexGroupId"
        [(ngModel)]="updateIndexGroupForm.indexGroupId">
      <input type="hidden" id="inpispublished" formControlName="isPublished"
        [(ngModel)]="updateIndexGroupForm.isPublished">
    </div>
    <div class="mat-elevation-z8" id="indexheader" [ngClass]="(isIndexGroupEdit === true) ? 'displaycls':'hiddedencls'">
    <hr class="width-50">
      <div class="info2" id="inpindextable">        
      <h2 style="text-align: center;">Index</h2>
        <table>
          <thead>
            <tr style="color:aliceblue ;background-color:  #00558d;">
              <th>Index</th>
            </tr>
          </thead>
          <tbody class="mat-grid-tile">
            <tr *ngFor="let item of IndexForm">
              <td>&nbsp;&nbsp;{{item.indexCode}}</td>
            </tr>
            <tr *ngIf="IndexForm?.length === 0" style="border:none">
              <td style="border:none;width: 500px; text-align: center;">No records found</td>
            </tr>
          <tbody>
        </table>
      </div>
    </div>
  </form>
</div>
<style>
  table,
  td,
  th {
    border-spacing: 0;
    border: #e2e2e2 0.02em solid;
  }

  table th {
    font-family: 'HM Group Ampersand';
    font-size: 15px;
    font-weight: 500;
    color:#03538B  ;
    background-color:  #dfdfdfdf !important;
  }

  table {
    width: 50%;
    border-collapse: collapse;
    margin-right: 10em;
    margin-left: auto;
  }
</style>