
<div class="data-table subtable">
  <table id="tblDepartment" [dataSource]="dataSource" #table mat-table class="full-width-table" (matSortChange)="sortData($event)" matSort
    aria-label="Elements">
    <ng-container matColumnDef="corporateBrandCode">
      <th matSort (matSortChange)="sortData($event)"  mat-header-cell *matHeaderCellDef mat-sort-header id="corporateBrandColumn">Corporate Brand</th>
      <td mat-cell *matCellDef="let row"
        [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')"  [matTooltipDisabled]="row.corporateBrandName.length<15" matTooltip={{row.corporateBrandName}} matTooltipPosition="right" matTooltipClass="my-custom-tooltip">
        {{row.corporateBrandCode}} {{row.corporateBrandName}}</td>
    </ng-container>
<ng-container matColumnDef="departmentCode">
  <th matSort (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header id="departmentColumn">Department</th>
  <td mat-cell *matCellDef="let row"
      [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
    <span class="departmentlistwithouttoggle" [matTooltipDisabled]="row.departmentName.length<30" matTooltip="{{row.departmentCode+' '+row.departmentName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">{{row.departmentCode+" "+row.departmentName}}</span>
    <span class="departmentlistwithtoggle" [matTooltipDisabled]="row.departmentName.length<15" matTooltip="{{row.departmentCode+' '+row.departmentName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">{{row.departmentCode+" "+row.departmentName  | slice:0:15}}<span *ngIf="row.departmentName.length>15">...</span></span>
  </td>
</ng-container>
<ng-container matColumnDef="divisionCode" id="divisionColumn">
  <th matSort (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header>Division </th>
  <td mat-cell *matCellDef="let row" 
      [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
    <span class="departmentlistwithouttoggle" [matTooltipDisabled]="row.divisionName.length<30" matTooltip="{{row.divisionCode+' '+row.divisionName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">{{row.divisionCode+" "+row.divisionName}}</span>
    <span class="departmentlistwithtoggle" [matTooltipDisabled]="row.divisionName.length<15" matTooltip="{{row.divisionCode+' '+row.divisionName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">{{row.divisionCode+" "+row.divisionName  | slice:0:15}}<span *ngIf="row.divisionName.length>15">...</span></span>
  </td>
</ng-container>
<ng-container matColumnDef="sectionCode" id="sectionColumn">
  <th matSort (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header>Section</th>
  <td mat-cell *matCellDef="let row" 
      [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
    <span class="departmentlistwithouttoggle" [matTooltipDisabled]="row.sectionName.length<30" matTooltip="{{row.sectionCode+' '+row.sectionName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">{{row.sectionCode+" "+row.sectionName}}</span>
    <span class="departmentlistwithtoggle" [matTooltipDisabled]="row.sectionName.length<15" matTooltip="{{row.sectionCode+' '+row.sectionName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">{{row.sectionCode+" "+row.sectionName  | slice:0:15}}<span *ngIf="row.sectionName.length>15">...</span></span>
  </td>
</ng-container>

<ng-container matColumnDef="garmentGroupCode" id="garmentColumn">
  <th matSort (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header>Garment Group </th>
  <td mat-cell *matCellDef="let row" 
      [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')" [matTooltipDisabled]="row.garmentGroupName.length<18" matTooltip="{{row.garmentGroupName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
      {{row.garmentGroupCode}} {{row.garmentGroupName}}
  </td>
</ng-container>
<ng-container matColumnDef="subIndexCodeName" id="subIndexColumn">
  <th matSort (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header>Sub Index </th>
  <td mat-cell *matCellDef="let row"
      [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
    {{row.subIndexCodeName }}
  </td>
</ng-container>
<ng-container matColumnDef="lastUpdatedTime">
      <th matSort (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header id="lastUpdatedTimeColumn">Updated Date</th>
     <td mat-cell *matCellDef="let row"  matTooltipPosition="left" matTooltipClass="tooltip" [matTooltipDisabled]="isdisable" matTooltip="{{row.lastUpdatedTime | customDateTime | date:'yyyy-MM-dd'}}"
        [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
         <span class="departmentlistwithouttoggle"  *ngIf="!customDateTime">{{row.lastUpdatedTime | customDateTime }}</span>
        <span class="departmentlistwithtoggle"  *ngIf="!customDateTime">{{row.lastUpdatedTime | customDateTime | slice:0:11 }}</span></td>

    </ng-container>
    <ng-container matColumnDef="endSeason" id="endSeasonColumn">
      <th matSort (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header>End Season</th>
      <td mat-cell *matCellDef="let row"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
          <span *ngIf="!seasonDate">{{(row.endSeason !=null && row.endSeason!='' && row.endSeason.includes("99")) ? '':row.endSeason  | seasonDate }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef id="DepActionsColumn"><span *ngIf="userrole">Edit | </span>View</th>
      <td mat-cell *matCellDef="let row" 
        [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        <a id="btnEdit" *ngIf="userrole"(click)="updateDepartment(row)" matTooltip="Edit" matTooltipPosition="right"
        class="org-edit">Edit | </a>
        <a id="btnView"(click)="openDialog(row)" matTooltip="View Details" matTooltipPosition="right"
        class="org-edit">View</a>
       </td>
    </ng-container>
     <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
     <td class="mat-cell"  colspan="8" style="
     text-align: center;">No records found</td>
   </tr>
  </table>
  <div id="main-wrap">
    <section class="sidebar" style="background-color: #00558d;color:rgb(139, 139, 139);">
        <div class="example-button-row">
          <div class="example-flex-container">            
          <button mat-icon-button aria-label="Datatable color code" class="marginRight">
            <mat-icon><span class="material-icons activeindicator">
                stop
              </span></mat-icon>
            Active
          </button>
          <button mat-icon-button aria-label="Datatable color code"  class="marginRight-2">
            <mat-icon><span class="material-icons inactiveindicator">
                stop
              </span></mat-icon>
            Inactive
          </button>
          <button mat-icon-button aria-label="Datatable color code"  class="marginRight">
            <mat-icon><span class="material-icons draftindicator">
                stop
              </span></mat-icon>
            Draft
          </button>
          <button mat-icon-button aria-label="Datatable color code"  class="marginRight">
            <mat-icon><span class="material-icons firstdraftindicator">
                stop
              </span></mat-icon>
            First Draft
          </button>
          </div>
        </div>
    </section>
    <mat-paginator #paginator class="tblPaginator" [(length)]="length" [(pageSize)]="pageSize"
    [pageSizeOptions]="getpagesizeoptions()" (page)="pageEvent = GetDepartmentDatawithfilters($event)"
    showFirstLastButtons>
    </mat-paginator>

</div>
</div>

  <style>
    ::ng-deep .customcolor .mat-select-value{ color: #5a5555!important;}::ng-deep .customcolor .mat-select-arrow { color: #fff!important;}
    ::ng-deep .mat-icon-button {padding: 0;min-width: 0; width: 40px;height: 40px;flex-shrink: 0;line-height: 40px;border-radius: 50%;font-family: Roboto, "Helvetica Neue", sans-serif !important;font-size: 13px !important;font-weight: 500 !important;}
    @media all and (min-width: 802px) and (min-width:1024px) {::ng-deep #custom-col100{ width:100px !important;}::ng-deep #custom-col120{ width:120px !important;}::ng-deep #custom-col80{ width:80px !important;}::ng-deep #custom-col50{width:50px !important;padding-right: 0px !important;}::ng-deep #custom-col150{width:150px !important;}::ng-deep #custom-col200{width:200px !important;}}
    ::ng-deep .mat-tooltip.tooltip {font-size: 12px;width:100%;position: initial;}
    
  </style>
