import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { SubindexService } from 'src/app/services/organization/subindex/subindex.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
@Component({
  selector: 'app-subindex',
  templateUrl: './subindex.component.html',
  styleUrls: ['./subindex.component.css']
})
export class SubindexComponent implements OnInit {
  title = "Sub Index";
  corporateBrandMasterl: any[] = [];
  corporateBrandFilterL: any[] = [];
  IndexCodeFilterList: any[] = [];
  IndexCodeMasterList: any[] = [];
  cloneIndexCodeMasterList: any[] = [];
  subIndexCodeFilterList: any[] = [];
  subIndexCodeMasterList: any[] = [];
  clonesubIndexCodeMasterList: any[] = [];
  subIndexNameFilterList: any[] = [];
  subIndexNameMasterList: any[] = [];
  clonesubIndexNameMasterList: any[] = [];
  corporateBrandId: any = "";
  indexCode: any = "";
  subIndexCode: any = "";
  subIndexName: any = "";
  status: any = "active";
  constructor(private titleService: Title, public _subIndexService: SubindexService,
    private applicationInsightService: ApplicationInsightService) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
  }
  receivedChildHandler(obj: any) {
    this.applicationInsightService.logEvent("Receive data from child started");
    this.corporateBrandMasterl = this.corporateBrandFilterL = obj.corporateBrandList;
    /* istanbul ignore next */
    if (this.corporateBrandMasterl != undefined) {
      // this.corporateBrandFilterL = this.corporateBrandMasterl.sort((a,b) =>  a.corporateBrandName.localeCompare(b.corporateBrandName));
      this.corporateBrandFilterL.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode))
      this.IndexCodeFilterList = this.IndexCodeMasterList = obj.indexList
      this.IndexCodeFilterList = this.IndexCodeMasterList.sort((a, b) => a.indexCode.localeCompare(b.indexCode));
      this.subIndexDropDown();
    }
    this.applicationInsightService.logEvent("Receive data from child Ended");
  }
  /* istanbul ignore next */
  subIndexDropDown() {
    this.applicationInsightService.logEvent("Subindex dropdown data binding started");
    this._subIndexService.getSubIndexList().subscribe((data: any) => {
      this.subIndexCodeFilterList = this.subIndexCodeMasterList = data.subIndex;
      this.subIndexNameFilterList = this.subIndexNameMasterList = data.subIndex;
      this.subIndexNameFilterList = this.subIndexNameMasterList.sort((a, b) => a.subIndexName.localeCompare(b.subIndexName));
      this.subIndexCodeMasterList = this.subIndexCodeMasterList
      this.subIndexCodeFilterList = this.subIndexCodeMasterList.sort((a, b) => a.subIndexCode.localeCompare(b.subIndexCode));

    });
    this.applicationInsightService.logEvent("Subindex dropdown data binding Ended");
  }

  filterItemsubIN(filterKey: any) {
    /* istanbul ignore if */
    this.applicationInsightService.logEvent("Subindexname filter started");
    if (this.searchform?.controls?.corporateBrandId?.value) {
      this.onFilter();
    }
    setTimeout(() => {
      var hasNumber = /\d/;   
      if (filterKey != null && filterKey != "") {
        if (hasNumber.test(filterKey)) {
          this.subIndexNameFilterList = this.subIndexNameMasterList.filter(cb => cb.subIndexCode.toString().toLowerCase().includes(filterKey.toString().toLowerCase()))
          this.subIndexNameFilterList = this.subIndexNameFilterList.sort((a, b) => a.subIndexCode.localeCompare(b.subIndexCode));
        } else {
          this.subIndexNameFilterList = this.subIndexNameMasterList.filter(cb => cb.subIndexName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()))
          this.subIndexNameFilterList = this.subIndexNameFilterList.sort((a, b) => a.subIndexCode.localeCompare(b.subIndexCode));
        }

      } else { this.subIndexNameFilterList = this.subIndexNameMasterList.sort((a, b) => a.subIndexCode.localeCompare(b.subIndexCode)); }
      this.applicationInsightService.logEvent("Subindexname filter ended");
    }, 500);

  }
  filterItemIC(filterKey: string) {
    this.applicationInsightService.logEvent("Indexcode filter started");
    if (this.searchform?.controls?.corporateBrandId?.value) {
      this.onFilter();
    }
    setTimeout(() => {
      let newICList: any[] = [];
      newICList = this.IndexCodeMasterList;
      if (filterKey != null && filterKey != "") {
        if (filterKey.length==1) {
          this.IndexCodeFilterList = this.IndexCodeMasterList.filter(cb => cb.indexCode.toString().toLowerCase().includes(filterKey.toString().toLowerCase()))
          this.IndexCodeFilterList = this.IndexCodeFilterList.sort((a, b) => a.indexCode.localeCompare(b.indexCode));
        } else {
          this.IndexCodeFilterList = this.IndexCodeMasterList.filter(cb => cb.indexName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()))
          this.IndexCodeFilterList = this.IndexCodeFilterList.sort((a, b) => a.indexCode.localeCompare(b.indexCode));
        }

      } else { this.IndexCodeFilterList = this.IndexCodeMasterList.sort((a, b) => a.indexCode.localeCompare(b.indexCode)); }
      this.applicationInsightService.logEvent("Indexcode filter ended");
    }, 500);
  }

  filterItemCorporateBrand(filterKey: any) {
    this.applicationInsightService.logEvent("Filter Corporatebrand Started");
    if (isNaN(filterKey)) {
      this.corporateBrandFilterL = this.corporateBrandMasterl.filter(cb => cb.corporateBrandName.toLowerCase().includes(filterKey.toLowerCase()));
      this.corporateBrandFilterL = this.corporateBrandFilterL.sort((a, b) => (a.corporateBrandCode > b.corporateBrandCode ? 1 : -1));
    } else {
      this.searchform.controls['indexCode'].setValue("");
      this.corporateBrandFilterL = this.corporateBrandMasterl.filter(cb => cb.corporateBrandCode.toLowerCase().includes(filterKey.toLowerCase()));
      this.corporateBrandFilterL = this.corporateBrandFilterL.sort((a, b) => (a.corporateBrandCode > b.corporateBrandCode ? 1 : -1));
    }
    this.onFilter();
    this.applicationInsightService.logEvent("Filter Corporatebrand Ended");
  }

  // filter function based on selection
  onFilter() {
    setTimeout(() => {
      if (this.cloneIndexCodeMasterList?.length === 0) {
        this.cloneIndexCodeMasterList = this.IndexCodeMasterList;
      }

      if (this.clonesubIndexCodeMasterList.length === 0) {
        this.clonesubIndexCodeMasterList = this.subIndexCodeMasterList;
      }

      if (this.clonesubIndexNameMasterList.length === 0) {
        this.clonesubIndexNameMasterList = this.subIndexNameMasterList;
      }


      if (this.searchform?.controls?.corporateBrandId?.value) {
        this.IndexCodeMasterList = this.cloneIndexCodeMasterList.filter(x => x.corporateBrandCodeName === this.searchform?.controls?.corporateBrandId?.value)
        this.IndexCodeFilterList = this.IndexCodeMasterList

        this.subIndexCodeMasterList = this.clonesubIndexCodeMasterList.filter(x => x.corporateBrandCodeName === this.searchform?.controls?.corporateBrandId?.value)
        this.subIndexCodeFilterList = this.subIndexCodeMasterList

        this.subIndexNameMasterList = this.clonesubIndexNameMasterList.filter(x => x.corporateBrandCodeName === this.searchform?.controls?.corporateBrandId?.value)
        this.subIndexNameFilterList = this.subIndexNameMasterList
      }
      else {
        this.IndexCodeFilterList = this.cloneIndexCodeMasterList.filter((arr, index, self) => index === self.findIndex((t) => (t.indexCode === arr.indexCode)));
        this.subIndexCodeFilterList = this.clonesubIndexCodeMasterList.filter((arr, index, self) => index === self.findIndex((t) => (t.indexCode === arr.indexCode)));
        this.subIndexNameFilterList = this.subIndexNameMasterList
        this.subIndexNameMasterList = this.clonesubIndexCodeMasterList
        this.IndexCodeFilterList = this.cloneIndexCodeMasterList
        this.IndexCodeMasterList = this.cloneIndexCodeMasterList
      }


      if (this.searchform?.controls?.corporateBrandId?.value !== '' &&
        this.searchform.controls.indexCode.value) {
        this.subIndexCodeMasterList = this.clonesubIndexCodeMasterList.filter(x => {
          return x.indexCodeName === this.searchform.controls.indexCode.value;
        }).filter(x => x.corporateBrandCodeName === this.searchform?.controls?.corporateBrandId?.value)
        this.subIndexCodeFilterList = this.subIndexCodeMasterList;
      }

      if (this.searchform?.controls?.corporateBrandId?.value !== '' &&
        this.searchform.controls.indexCode.value) {
        this.subIndexNameMasterList = this.subIndexNameMasterList.filter(x => {
          return x.indexCodeName === this.searchform.controls.indexCode.value;
        }).filter(x => x.corporateBrandCodeName === this.searchform?.controls?.corporateBrandId?.value)
        this.subIndexNameFilterList = this.subIndexNameMasterList;
      }


      if (this.searchform?.controls?.corporateBrandId?.value !== '' &&
        this.searchform.controls.subIndexName.value) {
        this.IndexCodeMasterList = this.subIndexNameMasterList.filter(x => {
          return x.subIndexCodeName === this.searchform.controls.subIndexName.value;
        }).filter(x => x.corporateBrandCodeName === this.searchform?.controls?.corporateBrandId?.value)
        this.IndexCodeFilterList = this.IndexCodeMasterList;
      }

    }, 500);

  }
  //clear button click event
  onSearchClear() {
    this.applicationInsightService.logEvent("Subindex clear search  started");
    this.searchform.controls['corporateBrandId'].setValue("");
    this.searchform.controls['subIndexCode'].setValue("");
    this.searchform.controls['subIndexName'].setValue("");
    this.searchform.controls['indexCode'].setValue("");
    this.searchform.controls['status'].setValue("active");
    this.filterItemCorporateBrand("");
    this.applicationInsightService.logEvent("Subindex  clear search  ended");
  }
  //subindex  Search Form
  searchform: FormGroup = new FormGroup({
    corporateBrandId: new FormControl(''),
    subIndexCode: new FormControl(''),
    subIndexName: new FormControl(''),
    indexCode: new FormControl(),
    indexCodeName : new FormControl(),
    status: new FormControl()
  })
}
