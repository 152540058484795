<div class="data-table subtable">
  <table id="tblDepMarkupGroup" [dataSource]="dataSource" #table mat-table class="full-width-table" (matSortChange)="sortData($event)" matSort aria-label="Elements">
  <ng-container matColumnDef="startSeason">
      <th (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header class="custom-color" id="depMGStartSeasonColumn">Start Season </th>
      <td mat-cell *matCellDef="let row">
        {{row.startSeason| seasonDate}}
      </td>
    </ng-container>
    <ng-container matColumnDef="endSeason">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-color" id="depMGEndSeasonColumn">End Season</th>   
      <td mat-cell *matCellDef="let row" >{{(row.endSeason !=null && row.endSeason!='' && row.endSeason.includes("99")) ? '':row.endSeason| seasonDate}}</td>
    </ng-container>
    <ng-container matColumnDef="markupGroupCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-color" id="depMGCodeColumn">Mark Group</th>
      <td mat-cell *matCellDef="let row" [matTooltipDisabled]="row.markUpGroupName.length<22"
      matTooltip="{{row.markupGroupCode+'-'+row.markUpGroupName}}" matTooltipPosition="left"  matTooltipClass="my-custom-tooltip">
        {{row.markupGroupCode+'-'+row.markUpGroupName | slice:0:22}}<span *ngIf="row.markUpGroupName.length>22">...</span>
      </td>
    </ng-container>
    <ng-container style="display:none;" matColumnDef="markUpGroupName" id="depMGNameColumn">
      <th style="display:none;" mat-header-cell *matHeaderCellDef mat-sort-header class="custom-color">Mark Group</th>
      <td style="display:none;" mat-cell *matCellDef="let row">
        {{row.markUpGroupName}}
      </td>
    </ng-container>
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef class="custom-color" id="depMGActionsColumn">Edit</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon  [ngClass]="(row.isDeleteDepMarkupGroup === true) ? 'isdeletetrue':'isdeletefalse'" matTooltipPosition="right" (click)="!row.isDeleteDepMarkupGroup?'':updateRecord(row)" style="background-color: rgb(255 255 255 / 0%);cursor: pointer;">
          drive_file_rename_outline
        </mat-icon>
      </td>
    </ng-container>
   <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="8" style="
       text-align: center;">
        No records found
      </td>
    </tr>
  </table>
</div>
<department-markupgroupform id="markupgroupform" (markupgroupcall)="receivedChildHandlerMG()" [departmentDraft]="departmentDraft" [markupGroupList]="markupGroupList" [parentCorporateBrandName]=parentCorporateBrandName [parentDepartmentId]=parentDepartmentId [markupSeaasonlist]=markupSeaasonlist [seasonList]=seasonList [depMarkupGroupList]=depMarkupGroup></department-markupgroupform>
