<div class="fixed-content mat-elevation-z2 form-border" id="scrolltop">
  <form [formGroup]="_subindexform" id="subindexform">
    <section class="form-section">
      <h2 mat-dialog-title class="align">{{isSubIndexEdit === true ? 'Update' : 'New'}} Sub Index</h2>
    </section>
    <div class="example-container add-form-field ">
      <mat-form-field [appearance]="isSubIndexEditCB">
        <mat-label>Corporate Brand</mat-label>
        <input type="text" id=inpcorporatebrand matInput [matAutocomplete]="autoCB" formControlName="corporateBrandId"
          (ngModelChange)="filterItemCB($event)" [(ngModel)]="updateSubIndexForm.corporateBrandId"
          [readonly]="isSubIndexEditCB=='fill' ?true:false">
        <mat-error *ngIf="_subindexform.controls['corporateBrandId'].errors?.maxlength">
          Maximum 30 Characters allowed
        </mat-error>
        <mat-error *ngFor="let validation of  validationMessages.autoCompleteControl">
          <div *ngIf="_subindexform.controls['corporateBrandId'].hasError(validation.type)">
            {{validation.message}}
          </div>
        </mat-error>
        <mat-autocomplete autoActiveFirstOption #autoCB="matAutocomplete" [displayWith]="displayTextCB.bind(this)">
          <mat-option (onSelectionChange)="updateSubIndexForm.indexId=''"
            *ngFor="let CBname of corporateBrandFilterList" [value]="CBname.corporateBrandId">
            {{CBname.corporateBrandCode}} {{CBname.corporateBrandName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <br />
      <mat-form-field appearance="legacy">
        <mat-label>Index</mat-label>
        <input type="text" id=inpIndexCode matInput [matAutocomplete]="autoIndex" formControlName="indexId"
          (ngModelChange)="filterItemIndex($event)" [(ngModel)]="updateSubIndexForm.indexId">
        <mat-error *ngFor="let validation of  validationMessages.autoCompleteControl">
          <div *ngIf="_subindexform.controls['indexId'].hasError(validation.type)">
            {{validation.message}}
          </div>
        </mat-error>
        <mat-autocomplete autoActiveFirstOption #autoIndex="matAutocomplete"
          [displayWith]="displayTextIndex.bind(this)">
          <mat-option *ngFor="let Indexname of indexFilterList" [value]="Indexname.indexId">
            {{Indexname.indexCode}} {{Indexname.indexName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <br />
      <mat-form-field appearance="legacy">
        <mat-label>Sub Index</mat-label>
        <input id="inpSubIndexName" formControlName="subIndexName" matInput
          [(ngModel)]="updateSubIndexForm.subIndexName">
        <mat-error *ngIf="_subindexform.controls['subIndexName'].errors?.maxlength">Maximum 250 charactor allowed
        </mat-error>
      </mat-form-field>
      <br />
      <mat-form-field [appearance]="isSubIndexEdit==true ? 'fill': 'legacy'">
        <mat-label>Sub Index Code</mat-label>
        <input id="inpSubIndexCode" formControlName="subIndexCode" matInput
          [(ngModel)]="updateSubIndexForm.subIndexCode" [readonly]=isSubIndexEdit>
        <mat-error *ngIf="_subindexform.controls['subIndexCode'].errors?.pattern">
          Please enter a vaild input, Ex:- A10
        </mat-error>
      </mat-form-field>
      <br />
      <mat-form-field appearance="legacy">
        <mat-label>Sort Order</mat-label>
        <input id="inpSortOrder" formControlName="subIndexSortOrder" min="0" matInput type="number"
          [(ngModel)]="updateSubIndexForm.subIndexSortOrder" pattern="^(0|[1-9][0-9]*)$">
        <mat-error *ngIf="_subindexform.controls['subIndexSortOrder'].errors?.max">The range for sort order is 1-9999
        </mat-error>
        <mat-error *ngIf="_subindexform.controls['subIndexSortOrder'].errors?.pattern">Only Numeric Values allowed
        </mat-error>
      </mat-form-field>
      <br />

      <section class="example-section">
        <mat-checkbox id="inpactive" formControlName="active" [(ngModel)]="updateSubIndexForm.active" [checked]="true"
          class="example-margin width-25" color='primary' [disabled]=!isSubIndexEdit>Active</mat-checkbox>
        <mat-checkbox id="inpdraft" formControlName="draft" [(ngModel)]="updateSubIndexForm.draft"
          class="example-margin width-15" color='primary'>
          Draft
        </mat-checkbox>
      </section>

      <input type="hidden" id="inpsubindexid" formControlName="subIndexId" [(ngModel)]="updateSubIndexForm.subIndexId">
      <br>
      <mat-dialog-actions *ngIf="userrole" style="display: block !important">

        <button [disabled]="(isSubIndexEdit === true) ?  !_subindexform.dirty : false"
          [ngClass]="(isSubIndexEdit === true) ? 'mat-raised-button ml-1':'mat-raised-button ml-1 mat-primary'"
          id="btnSaveUpdate" type="submit" (click)="onSubmit()">
          {{isSubIndexEdit === true ? 'Update' : 'Save'}}
        </button>

        <button class="mat-raised-button mat-primary  ml-1" id="btnclear" (click)="onClear()">Cancel</button>
      </mat-dialog-actions>
      <span *ngIf="!userrole" style="color: #ff0202;font-size: 12px;font-weight: 400;">You can't save changes since you
        have Read access.</span>
    </div>
    <div id="departmentheader" [ngClass]="(isSubIndexEdit === true) ? 'displaycls':'hiddedencls'">
      <hr class="width-50">
      <h2 mat-dialog-title class="margin-title">Department</h2>
      <mat-form-field appearance="legacy" class="width-45">
        <mat-label>Season</mat-label>
        <input type="text" id="inpseason" matInput [matAutocomplete]="autoSN" (ngModelChange)="filterSeason($event)"
          [formControl]="season">
        <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
          <div *ngIf="this.season.hasError(validation.type)">{{validation.message}}</div>
        </mat-error>
        <mat-autocomplete autoActiveFirstOption #autoSN="matAutocomplete" [displayWith]="displayTextSeason.bind(this)">
          <mat-option *ngFor="let season of seasonFilterList" [value]="season.seasonNumber">
            {{season.displayName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div class="info2" id="department">
        <div class="mat-table-container width-75">
          <table class="full-width-table">
            <thead>
              <tr style="color:#03538B  ;background-color:  #dfdfdfdf !important;text-align: left;">
                <th style="padding:10px 0px 10px 20px;">Department</th>
              </tr>
            </thead>
            <tbody class="mat-grid-tile scroller" Id="tblscroll">
              <tr *ngFor="let item of deptSeasonList">
                <td style="width: 500px;padding:10px 0px 10px 20px;border-bottom: 0.5px solid #dfdfdfdf;">
                  {{item.departmentCode}}-{{item.departmentName}}
                </td>
              </tr>
              <tr *ngIf="deptSeasonList?.length === 0">
                <td style="border-bottom: 1px solid #dfdfdfdf;width: 500px; text-align: center; ">No records found</td>
              </tr>
            <tbody>
          </table>
        </div>
      </div>
    </div>
  </form>
</div>
