import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CorporatebrandService } from 'src/app/services/organization/corporatebrand/corporatebrand.service';
import { Title } from '@angular/platform-browser';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { EarlierplanningService } from 'src/app/services/organization/earlierplanning/earlierplanning.service';
import { SectionService } from 'src/app/services/organization/section/section.service';

@Component({
  selector: 'app-southernhemisphere',
  templateUrl: './southernhemisphere.component.html',
  styleUrls: ['./southernhemisphere.component.css']
})
export class SouthernhemisphereComponent implements OnInit {
  title = "Southernhemisphere";
  corporateBrandMasterList: any[] = [];
  corporateBrandFilterList: any[] = [];
  channelnameFilterList: any[] = [];
  channelnameMasterFilterList: any[] = [];
  departmentFilterList: any[] = [];
  departmentMasterFilterList: any[] = [];
  sectionFilterList: any[] = [];
  sectionMasterFilterList: any[] = [];
  seasonnumberFilterList: any[] = [];
  seasonnumberMasterFilterList: any[] = [];
  seasoncurrent: any = "";
  seasoncurrentList: any = "";
  seasonMasterlist: any;
  seasonFilterList: any;
  statusFilterList: any;
  statusFilterMasterList: any;
  corporateBrandName: any = "";
  season: any = "";
  sectionName: any = "";
  sectionCode: any = "";
  division: any = "";
  status: any = "active";
  seasonList: any[] = [];
  constructor(public titleService: Title, public _corporatebrandService: CorporatebrandService, public _earlierplanningService: EarlierplanningService, private applicationInsightService: ApplicationInsightService, public _sectionService: SectionService) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);

    // Get sections from BOCOS service
    this._sectionService.getSectionAllBOCOS().subscribe((data: any) => {
      this.applicationInsightService.logEvent("Section listing Started");
      var distinctSectionName = data.section.filter((arr, index, self) => index === self.findIndex((t) => (t.sectionName === arr.sectionName)));
      this.sectionFilterList = this.sectionMasterFilterList = distinctSectionName.sort((a, b) => a.sectionName.toString().localeCompare(b.sectionName));
      this.applicationInsightService.logEvent("Section listing Ended");
    });

    this._earlierplanningService.getChannels().subscribe((data: any) => {
      this.channelnameMasterFilterList = this.channelnameFilterList = data.getChannelAll;
      this.channelnameFilterList = this.channelnameFilterList.sort((a, b) => (a.channelName > b.channelName ? 1 : -1));
    });
  }

  GetdepartmentDropDown() {
    // Get Departments from BOCOS service
    this._earlierplanningService.getBOCOSDepartments(this.seasoncurrent).subscribe((data: any) => {
      this.departmentMasterFilterList = this.departmentFilterList = data.department;
      if (this.departmentFilterList != null || undefined) {
        this.departmentFilterList = this.departmentFilterList.sort((a, b) => (a.departmentCode > b.departmentCode ? 1 : -1));
      }

    });
  }

  dropDownCollections(obj: any) {
    this.applicationInsightService.logEvent("Southern Hemisphere DropDown Started");
    this.seasonMasterlist = this.seasonFilterList = obj.SeasonFilterList;
    this.seasonList = this.seasonMasterlist;
    this.seasoncurrentList = '';
    if (this.seasonList != null || undefined) {
      for (let i = 0; i < this.seasonList.length; i++) {
        var seasonno = this.seasonList[i].seasonNumber;
        this.seasoncurrentList = this.seasoncurrentList + "," + seasonno;
      }
    }
    this.seasoncurrentList = this.seasoncurrentList.replace(/^,/, '');
    if (this.seasonList != undefined) {
      this.seasoncurrent = this.seasonList.filter(objCB => { return objCB['isCurrentSeason'] !== false; }).map(({ seasonNumber }) => seasonNumber).toString();
    }
    this._searchform.controls.seasonNumber.setValue(this.seasoncurrent);
    if (this._searchform.controls.seasonNumber.value == this.seasoncurrent) {
      // Calling Bocos services to load department dropdown based of current season.    
      this.GetdepartmentDropDown();
    }
    this._searchform.controls.seasonlist.setValue(this.seasoncurrentList);
    this.corporateBrandFilterList = this.corporateBrandMasterList = obj.CorporateBrandFilterList;
    this.corporateBrandFilterList = this.corporateBrandMasterList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));

    this.channelnameFilterList = this.channelnameMasterFilterList = obj.ChannelnameFilterList.filter((arr, index, self) => index === self.findIndex((t) => (t.channelName === arr.channelName))).sort((a, b) => a.channelName.localeCompare(b.channelName));
    this.applicationInsightService.logEvent("Southern Hemisphere DropDown Ended");
  }
  displayTextSeason(seasonNumber: any) {
    if (seasonNumber == null || seasonNumber == "")
      return null;
    else if (this.seasonMasterlist != undefined && seasonNumber != null)
      return this.seasonMasterlist.find(x => x.seasonNumber === seasonNumber).displayName;
  }
  filterSeason(filterKeySS: string) {
    this.applicationInsightService.logEvent("filter Season started");
    this.seasonFilterList = this.seasonMasterlist.filter(cb => cb.displayName.toString().toLowerCase().includes(filterKeySS.toString().toLowerCase()));
    this.seasonFilterList = this.seasonFilterList?.sort((a, b) => (a?.seasonNumber < b?.seasonNumber ? 1 : -1));
    this.applicationInsightService.logEvent("filter Season ended");
  }
  filterchannelName(filterKey: any) {
    this.applicationInsightService.logEvent("Southern Hemisphere filtering sectionname Started");
    if (filterKey != null && filterKey != "") {
      this.channelnameFilterList = this.channelnameMasterFilterList
      this.channelnameFilterList = this.channelnameFilterList.sort((a, b) => a.channelName.toString().localeCompare(b.channelName));
    } else { this.channelnameFilterList = this.channelnameMasterFilterList.sort((a, b) => a.channelName.toString().localeCompare(b.channelName)); }
    this.applicationInsightService.logEvent("Southern Hemisphere filtering sectionname Ended");
  }
  filterItemCorporateBrand(filterKey: string) {
    this.applicationInsightService.logEvent("Southern Hemipshere filtering CorporateBrand Started");
    if (filterKey != null && filterKey != "") {
      this.corporateBrandFilterList = this.corporateBrandMasterList.filter(cb => cb.corporateBrandName.toLowerCase().includes(filterKey.toString().toLowerCase()));
    } else { this.corporateBrandFilterList = this.corporateBrandMasterList}
    this.applicationInsightService.logEvent("Southern Hemisphere filtering CorporateBrand Ended");
  }
  filtersectionName(filterKey: any) {
    this.applicationInsightService.logEvent("Southern Hemisphere filtering sectioncode Started");
    if (filterKey != null && filterKey != "") {
      this.sectionFilterList = this.sectionMasterFilterList.filter(sn => sn.sectionName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
    } else { this.sectionFilterList = this.sectionMasterFilterList; }
    this.applicationInsightService.logEvent("Southern Hemisphere filtering sectioncode Ended");
  }
  filterdepartment(filterKey: any) {
    this.applicationInsightService.logEvent("Southern Hemisphere filtering department Started");
    if (filterKey != null && filterKey != "") {
      this.departmentFilterList = this.departmentMasterFilterList.filter(sn => sn.departmentName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
    } else { this.departmentFilterList = this.departmentMasterFilterList; }
    this.applicationInsightService.logEvent("Southern Hemisphere filtering department Ended");
  }
  filterstatus(filterstatus: string) {
    this.applicationInsightService.logEvent("filter Status started");
    this.statusFilterList = this.statusFilterMasterList.filter(cb => cb.toString().toLowerCase().includes(filterstatus.toString().toLowerCase()));
    this.applicationInsightService.logEvent("filter Status ended");
  }
  removeStatusTagOnBackspace(event) {
    console.log(event.keyCode);
    if (event.keyCode === 8) {
      this._searchform.controls.status.setValue("");
    }
  }
  //clear button click event
  onSearchClear() {
    this.applicationInsightService.logEvent("Southern Hemisphere Search clear event started");
    this._searchform.controls.channelName.setValue('');
    this._searchform.controls.corporateBrandName.setValue('');
    this._searchform.controls.sectionName.setValue('');
    this._searchform.controls.departmentName.setValue('');
    this._searchform.controls.status.setValue("active");
    this._searchform.controls.seasonNumber.setValue('');
    this._searchform.controls.seasonlist.setValue(this.seasoncurrent);
    this.filterSeason("");
    this.filterchannelName("");
    this.filterItemCorporateBrand("");
    this.filtersectionName("");
    this.filterdepartment("");
    this.filterstatus("");
    this.applicationInsightService.logEvent("Southern Hemisphere Search clear event ended");
  }

  //Southern Hemisphere group Search Form
  _searchform: FormGroup = new FormGroup({
    corporateBrandName: new FormControl(''),
    sectionName: new FormControl(''),
    seasonNumber: new FormControl(),
    channelName: new FormControl(''),
    departmentName: new FormControl(''),
    status: new FormControl(),
    seasonlist: new FormControl(),
  })
}
