import { Component, OnInit, ViewChild, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CurrencyService } from "src/app/services/currency/currency.service";
import { CurrencyviewComponent } from './currencyviewComponent';
import { ModulePermission } from 'src/app/models/enum';
export interface currencyModel {
  currencyId: any,
  ISOcode: any,
  Symbol: any,
  Name: any,
  HMCurrency: any,
  lastUpdatedTime: any,
  currencySortOrder:any,
  active: any,
  draft: any,
  isPublished:any
}

@Component({
  selector: 'app-currencylist',
  templateUrl: './currencylist.component.html',
  styleUrls: ['./currencylist.component.css']
})


export class CurrencylistComponent implements OnInit, OnChanges  {
  @Input() corporateBrandSearch: any;
  @Output() childButtonEvent = new EventEmitter();
  @ViewChild(MatPaginator, {static:false}) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<currencyModel>;
  // public dataSource: MatTableDataSource<CurrencyModel>; Uncooment this after real data
  public dataSource: MatTableDataSource<currencyModel>; 
  // MatPaginator Inputs
  pageNumber: any = 1;
  length: any;
  pageIndex:any;
  pageSize: any = 15;
  sortByColumnName: any = "SortOrder";
  sortByOrder: any = "asc";
  pageEvent: PageEvent;
  errorMsg:any;
  customDateTime:any="";
  active:any;
  draft:any;
  subscription: Subscription;
  emitter:number=1;
  displayedColumns = [
    'isoCode',
    'currencyName',
    'symbol',
    'hmCurrency',
    'currencySortOrder',
    'lastUpdatedBy',
    'Actions'
  ];
  filteredCBList: any;
  matDialogRef: any;
  dialog: any;
  userrole: boolean;
  users: any;
  constructor(
    public _currencyService:CurrencyService,
    public route: ActivatedRoute,
    private toastr: ToastrService,
    private applicationInsightService:ApplicationInsightService,) { this.users = JSON.parse(localStorage.getItem("users") || "[]") }

  getpagesizeoptions(): number[] 
  {
    if (this.length > 25)
      return [10, 20, 25, this.length];
    else if (this.length > 20)
      return [10, 20, this.length];
    else if (this.length > 10)
      return [10, this.length];
     return null;
  }

  ngOnInit(): void {
    this.userrole = this.users.includes(ModulePermission.Organization_Write) ? true : true;
    this.emitter=1
    this.BindCurrencyData();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }
  ngOnChanges(changes: SimpleChanges)
  {
    this.pageNumber = 1;
    this.BindCurrencyData();
  }
      /* istanbul ignore next */
  sortData(sort: Sort) 
  {
    console.log("sort",sort)
    this.applicationInsightService.logEvent("Sorting started");
    this.sortByColumnName = sort.active;
    this.sortByOrder = sort.direction;
    this.applicationInsightService.logEvent("Sorting ended");
    this.BindCurrencyData(); 
  }

  BindCurrencyData() 
  {
    setTimeout(() => {
      this._currencyService.getCurrencyList().subscribe((data: any) => {
        //filtering
        currencyList = data.sampleData[0].currency;
      },
      error => {
        this.errorMsg = error;
        this.toastr.error(error);
      });
      this.dataSource = new MatTableDataSource(currencyList);
      this.sort.active = this.sortByColumnName;
      this.sort.direction= this.sortByOrder;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.length = this.dataSource.data.length;
      if(this.emitter==1)
      {
        this.emitter=2;
        this.childButtonEvent.emit()
      }  
    }, 100);
     
    this.applicationInsightService.logEvent("Bind Currency started");
    let currencyList:any[]=[]; 
    let filteredCBList:any[]=[];
    if (this.corporateBrandSearch.status === "draft") { this.draft = true; this.active = ""; }
    if (this.corporateBrandSearch.status === "inactive") { this.active = false; this.draft = false; }
    if (this.corporateBrandSearch.status === "active") { this.active = true; this.draft = false; }
    if (this.corporateBrandSearch.status === "all") { this.active = ""; this.draft = ""; }  
    this._currencyService.getCurrencyList().subscribe((data: any) => 
    {
      this.applicationInsightService.logEvent("Corporate listing and filter started");  
      //filtering
      currencyList = data.getCurrencyAll;
      if(currencyList != null)
      {
       filteredCBList = currencyList
      .filter(CB=>CB.corporateBrandName.toString().toLowerCase().includes(this.corporateBrandSearch.corporateBrandName.toString().toLowerCase()))
      .filter(CB=>CB.active.toString().includes(this.active.toString()))
      .filter(CB=>CB.draft.toString().includes(this.draft.toString())); 
      for(let i=0;i<filteredCBList.length;i++){
        if(filteredCBList[i].corporateBrandName.length>28){
          filteredCBList[i].isdisableCB=false;
         }
         else{
          filteredCBList[i].isdisableCB=true;
          }
        }
      this.dataSource = new MatTableDataSource(filteredCBList);
      this.sort.active = this.sortByColumnName;
      this.sort.direction= this.sortByOrder;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.length = this.dataSource.data.length;
       if(this.emitter==1)
      {
        this.emitter=2;
        this.childButtonEvent.emit(data.getCurrencyAll);
      }        
      } 
      this.applicationInsightService.logEvent("Corporate listing and filter ended");     
    },
      error => 
      {
        this.errorMsg = error;
        this.toastr.error(error);
      });
    this.applicationInsightService.logEvent("Bind Corporateband ended");
  }
//While clicking Edit button load respective data to entry page
updateRecord(updateRowData: any) {
  this._currencyService.getCurrencyIdFromList(updateRowData.currencyId);
}

openDialog(row: any) {
  this.matDialogRef = this.dialog.open(CurrencyviewComponent, {
        data: { rowData:row },
        disableClose: false
      });
this.matDialogRef.afterClosed().subscribe(res => {
  if ((res == true)) {
    row = "";
  }
});
}

}
