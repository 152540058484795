
<div class="mat-elevation-z8 data-table subtable">
  <table id="tblVatEx" [dataSource]="dataSource" mat-table class="full-width-table" matSort matSortActive="vat" matSortStart="desc"
         aria-label="Elements">
    <ng-container matColumnDef="vat">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-color" id="vatColumn">VAT</th>
      <td mat-cell *matCellDef="let row" [ngClass]="row.isFirstDraft==true ? 'FiscalDraftBg' : 'ActiveBg'">
        {{row.vat}}
      </td>
    </ng-container>
    <ng-container matColumnDef="fromDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-color" id="fromDateColumn"> Valid From</th>
      <td mat-cell *matCellDef="let row" [ngClass]="row.isFirstDraft==true ? 'FiscalDraftBg' : 'ActiveBg'">{{row.fromDate | customDate }}</td>
    </ng-container>
    <ng-container matColumnDef="toDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-color" id="toDateColumn"> Valid To</th>
      <td mat-cell *matCellDef="let row" [ngClass]="row.isFirstDraft==true ? 'FiscalDraftBg' : 'ActiveBg'">{{row.toDate | customDate }}</td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-color" id="descriptionColumn">Description</th>
      <td mat-cell *matCellDef="let row" [ngClass]="row.isFirstDraft==true ? 'FiscalDraftBg' : 'ActiveBg'" matTooltip="{{row.description}}"  matTooltipPosition="left" matTooltipClass="tooltip" >{{row.description | slice:0:12 }}</td>
    </ng-container>
    <ng-container matColumnDef="Edit">
      <th mat-header-cell *matHeaderCellDef class="custom-color" id="vatEditColumn">Edit</th>
      <td mat-cell *matCellDef="let row" [ngClass]="row.isFirstDraft==true ? 'FiscalDraftBg' : 'ActiveBg'">
        <mat-icon color="primary" (click)="updateRecord(row)"
                  style="background-color: rgb(255 255 255 / 0%);cursor: pointer;">drive_file_rename_outline</mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="Delete">
      <th mat-header-cell *matHeaderCellDef class="custom-color" id="vatDeleteColumn">Delete</th>
      <td mat-cell *matCellDef="let row" [ngClass]="row.isFirstDraft==true ? 'FiscalDraftBg' : 'ActiveBg'">
        <mat-icon [ngClass]="(row.isDelete === true) ? 'isdeletetrue':'isdeletefalse'" (click)="row.isDelete ? onRemoveRow(row) :''" matTooltip="Delete" matTooltipPosition="right"
                  style="cursor: pointer;">delete_outline</mat-icon>
      </td>
    </ng-container>
   <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="8" style="text-align: center;">No records found</td>
    </tr>
  </table>
  <div id="main-wrap">
    <section style="background-color: #00558d;color:#fff">
  <div class="example-button-row">
    <div class="example-flex-container">
      <button mat-icon-button style="width:25%;" aria-label="Datatable color code">

        <mat-icon>
          <span class="material-icons"
                style="color:#fff; text-shadow: 0px 1px 0 #000, 1px 1px 0 #000, 1px 1px 0 #000, 1px 1px 0 #000;">
            stop
          </span>
        </mat-icon>
        Saved
      </button>
      <button mat-icon-button style="width:27%;" aria-label="Datatable color code">
         <mat-icon>
          <span class="material-icons"
                style="color: #d7bd5e;text-shadow: 0px 1px 0 #000, 1px 1px 0 #000, 1px 1px 0 #000, 1px 1px 0 #000;">
            stop
          </span>
        </mat-icon>
        Unsaved
      </button>
    </div>
  </div>
    </section>
  </div>
</div>

<div class="mat-elevation-z8 add-form-field" *ngIf="vatForm">
  <form [formGroup]="_fcvatExForm">
    <div class="example-container">

      <mat-form-field floatLabel='always' appearance='outline' class="width-20">
        <mat-label>VAT</mat-label>
        <input type="number" min="1" matInput formControlName="vat" id="inpvatEx">
      </mat-form-field>
      <span style="font-size: 20px;color: #00558d;margin-top: 2%;">&nbsp;%</span>
      <mat-form-field floatLabel='always' appearance='outline' class="width-32 ml-2">
        <mat-label>Valid From</mat-label>
        <input [matDatepicker]="pickerfrom" (ngModelChange)=fnFromDateValidation() matInput formControlName="fromDate" id="inpvatExvalidfrom">
        <mat-datepicker-toggle matSuffix [for]="pickerfrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerfrom></mat-datepicker>

        <mat-error *ngIf="_fcvatExForm.get('fromDate').errors">{{_fcvatExForm.get('fromDate').errors['invalidDate']}}</mat-error>
      </mat-form-field>
      <mat-form-field floatLabel='always' appearance='outline' class="width-32 ml-2">
        <mat-label>Valid To</mat-label>
        <input [matDatepicker]="pickerto" (ngModelChange)=fnToDateValidation() matInput formControlName="toDate" id="inpvatExvalidto">
        <mat-datepicker-toggle matSuffix [for]="pickerto"></mat-datepicker-toggle>
        <mat-datepicker #pickerto></mat-datepicker>
        <mat-error *ngIf="_fcvatExForm.get('toDate').errors">{{_fcvatExForm.get('toDate').errors['invalidDate']}}</mat-error>
      </mat-form-field>
      <input type="hidden" id="inpvatExceptionId" formControlName="fiscalCountryVATExceptionId">
      <br>
      <mat-form-field floatLabel='always' appearance='outline' class="full-width">
        <mat-label>Type of construction</mat-label>
        <mat-select #selectTOC [(value)]="typeOfConstructionIds" id="inpvatExToc" formControlName="typeOfConstructionIds" multiple>
          <div class="select-all">
            <mat-checkbox class="checklist-leaf-node" id="custom-All" formControlName="allTOC" (change)="toggleAllSelection(allSelectedTOC,'toc')" style="margin: 17px;">All</mat-checkbox>
          </div>
          <mat-option (click)="optionClick('toc')" *ngFor="let typeOfCon of typeOfConMasterList" [value]="typeOfCon.typeOfConstructionId">{{ typeOfCon.typeOfConstructionName  }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field floatLabel='always' appearance='outline' class="full-width ml-2">
        <mat-label>Customs Customer Group</mat-label>
        <mat-select id="inpvatExCcg" #selectCCG [(value)]="customsCustomerGroupIds" formControlName="customsCustomerGroupIds" multiple>
          <div class="select-all">
            <mat-checkbox class="checklist-leaf-node" formControlName="allCCG" id="custom-All" (change)="toggleAllSelection(allSelectedCCG,'ccg')" style="margin: 17px;">All</mat-checkbox>
          </div>
          <mat-option (click)="optionClick('ccg')" *ngFor="let customsCust of customsCustMasterList" [value]="customsCust.customsCustomerGroupId">{{ customsCust.customsCustomerGroupName }}</mat-option>
        </mat-select>
      </mat-form-field>
      <br>
      <app-producttypeandgroup #clearproducts id="emitproductType" (emitproductType)="receivedChildHandler($event)"></app-producttypeandgroup>

      <mat-form-field floatLabel='always' appearance='outline' class="full-width  ml-2">
        <mat-label>Custom Material Group</mat-label>
        <mat-select id="inpvatExCcm" #selectCMG [(value)]="customsMaterialGroupIds" formControlName="customsMaterialGroupIds" multiple>
          <div class="select-all">
            <mat-checkbox class="checklist-leaf-node" formControlName="allCMG" id="custom-All" (change)="toggleAllSelection(allSelectedCMG,'cmg')" style="margin: 17px;">All</mat-checkbox>
          </div>
          <mat-option (click)="optionClick('cmg')" *ngFor="let customsMat of customsMatMasterList" [value]="customsMat.customsMaterialGroupId">{{ customsMat.customsMaterialGroupName }}</mat-option>
        </mat-select>
      </mat-form-field>

      <br>
      <mat-form-field floatLabel='always' appearance='outline' class="width-95">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" id="inpVatExdes"></textarea>
        <mat-error *ngIf="_fcvatExForm.controls['description'].errors?.maxlength">Maximum 250 Characters allowed</mat-error>
      </mat-form-field>
      <br>
      <mat-dialog-actions *ngIf="!userrole">
        <button id="inpbtnvatExsave" mat-raised-button class="add-button-container mat-raised-button mat-primary" (click)="onAddRow()">Add</button>
        <button id="inpbtnvatExclear" mat-raised-button class="add-button-container mat-raised-button mat-primary ml-2" (click)="onClear()">Clear</button>
      </mat-dialog-actions>
      <span *ngIf="userrole" style="color: #ff0202;font-size: 12px;font-weight: 400;">You can't save changes since you have Read access.</span>
    </div>
  </form>

</div>
<style>
  ::ng-deep .mat-option:first-child .mat-pseudo-checkbox {
    display: none;
  }
  ::ng-deep .mat-tooltip.tooltip {
  /* background-color: #00558d; */
  font-size: 12px;
width:100%;
position: initial;
}
::ng-deep .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color:#673ab7 !important;
}
</style>
