import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, Subject } from 'rxjs';
import { ServiceApi } from '../../../services/ServiceApi';

let headers = new HttpHeaders()
if (!headers.has('content-type')) {
  headers = headers.append('content-type', 'application/json')
}

@Injectable({
  providedIn: 'root'
})

export class GarmentgroupService {
  headers = new HttpHeaders().set('content-type', 'application/json').set('accept', 'application/json');
  httpOptions = {
    headers: this.headers
  }

  // Observable string sources
  public garmentGroupIdSource = new Subject<string>();
  // Observable string streams
  public garmentGroupId$ = this.garmentGroupIdSource.asObservable();

  // Service message commands
  getgarmentGroupIdFromList(mission: string) {
    this.garmentGroupIdSource.next(mission);
  }
  // Observable string sources
  public refreshTable = new Subject<string>();
  // Observable string streams
  public refreshTableGarmentGroup$ = this.refreshTable.asObservable();

  // Service message commands
  refreshGarmentGroupList() {
    this.refreshTable.next();
  }

  constructor(private http: HttpClient,public _serviceApi: ServiceApi) { }

  //List GarmentGroup from Service
  getGarmentGroupList(): Observable<any> {
    return this.http.get<any>(this._serviceApi.GarmentGroupGetAll);
  }

  //Create corporate Brand 
  CreateGarmentGroup(createnew: any): Observable<any> {
    return this.http.post(this._serviceApi.GarmentGroupCreate, JSON.stringify(createnew), { 'headers': headers });
  }

  //Get GarmentGroup By Id
  getgarmentGroupId(garmentGroupId: number): Observable<any> {
    return this.http.get<any>(this._serviceApi.GarmentGroupGetById + garmentGroupId);
  }

  getDepartmentByGarmentGroupId(garmentGroupId: number): Observable<any> {
    console.log(this._serviceApi.GarmentGroupGetDepById + garmentGroupId)
    return this.http.get<any>(this._serviceApi.GarmentGroupGetDepById + garmentGroupId);
  }

  //Update GarmentGroup By Id
  UpdateGarmentGroup(updatedData: any): Observable<any> {
    return this.http.put(this._serviceApi.GarmentGroupUpdate, JSON.stringify(updatedData), { 'headers': headers });
  }


}
