import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonUtilityService } from 'src/app/services/organization/commonutility/commonutility.service';
import { AccountService } from '../../../../../shared/services/account.service';
import { MatTableDataSource } from '@angular/material/table';
import { CustomeDateValidator } from 'src/app/shared/components/customedatevalidator/customedatevalidator'
import { FiscalcountryService } from 'src/app/services/country/fiscalcountry/fiscalcountry.service';
import { State } from '../../../../../models/enum';
import { DialogBoxService } from 'src/app/shared/services/dialog-box.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
export interface iGBC {
  fromDate: any;
  toDate: any;
  gbcCost: any;
  fiscalCountryGBCCostId: any;
  isDelete: any;
  isFirstDraft: any;
}
@Component({
  selector: 'app-gbccost',
  templateUrl: './gbccost.component.html',
  styleUrls: ['./gbccost.component.css']
})
export class GbccostComponent implements OnInit {
  @Output() isGBCCostChildFormValid = new EventEmitter();
  isedit: boolean = false;
  displayedColumns: any;
  dataSource: MatTableDataSource<iGBC>;
  validationMessages: any;
  updatefcgbcForm: any;
  gbcForm = true;
  rows: any;
  gbcControl = new FormControl();
  gbc: any;
  actualStartDate: any;
  fromDate: any;
  toDate: any;
  lastUpdateBy: any;
  tempRowIndex: number = 0;
  TotalGbcCostLength: number = 0;
  isDelete: boolean = false;
  fiscalCountryGBCCostId: any = 0;
  gbcCostStatus: any = 0;
  isEditFcGbcCost: boolean = false;
  isFiscalCountryDraft: boolean;
  isFirstDraft: boolean = false;
  userrole: boolean;
  constructor(
    public _fiscalcountryService: FiscalcountryService,
    public _commonutilityService: CommonUtilityService,
    public _dialogService: DialogBoxService,
    private applicationInsightService: ApplicationInsightService,
    public account: AccountService, public datevalidator: CustomeDateValidator) {
    this.rows = [];
    this.updatefcgbcForm = Object.assign({ active: true, draft: true });
  }
  ngOnInit(): void {
    this.displayedColumns = ["gbcCost", "fromDate", "toDate", "Edit", "Delete"];
    this.fromDate = new FormControl(new Date());
    this.toDate = new FormControl(new Date());
    this.validationMessages = this._commonutilityService.validationMsgs;
    this.account.getUserInfo();
    let list = this.account.userInfo.Role;
    let rolelist = list.split(",");

    this.userrole = rolelist.length <= 1 ? rolelist[0] == 'sds.Reader' ? true : false : false;
    if (rolelist.length > 1) {
      for (let i = 0; i < rolelist.length; i++) {
        if (rolelist[i] != 'sds.Reader') {
          this.userrole = false;
        }
      }
    }
    this.dataSource = new MatTableDataSource([]);
  }
  public validateGBCChildForm(data: any) {
    if (data === true) {
      if (this._fcgbcForm.valid == true) {
        this.isGBCCostChildFormValid.emit(true);
      }
      else {
        this.isGBCCostChildFormValid.emit(false);
      }
    }
  }
  onAddRow() {
    this.applicationInsightService.logEvent("GBCCost Add Row Started");
    let isvalid = true;
    this._fcgbcForm.controls.gbcCost.updateValueAndValidity();
    this._fcgbcForm.controls.fromDate.updateValueAndValidity();
    if (this._fcgbcForm.valid && this._fcgbcForm.controls.gbcCost.value != null && this._fcgbcForm.controls.gbcCost.value != '') {
      let todaysdate = new Date().getFullYear();
      if (this.rows.length >= 1 && todaysdate > new Date(this._fcgbcForm.controls.fromDate.value).getFullYear()) {
        if (this._fcgbcForm.controls.toDate.value == null || this._fcgbcForm.controls.toDate.value == '') {
          this._fcgbcForm.get('toDate').setValidators(Validators.required);
          this._fcgbcForm.controls['toDate'].updateValueAndValidity();
          isvalid = false;
        }
      }
      else {
        this._fcgbcForm.controls.toDate.clearValidators();
      }
      /* istanbul ignore next */
      if (isvalid) {
        this.gbc = this._fcgbcForm.controls.gbcCost.value;
        this.fromDate = this._fcgbcForm.controls.fromDate.value;  
        this.fromDate = this._fiscalcountryService.fnToConvertDate(this.fromDate);
        this.toDate = this._fcgbcForm.controls.toDate.value == '' ? null : this._fcgbcForm.controls.toDate.value;
        this.toDate = this.toDate!=null ? this._fiscalcountryService.fnToConvertDate(this.toDate) : this.toDate;
        this.lastUpdateBy = this.account.userInfo.Email;
        if (this._fcgbcForm.controls.fiscalCountryGBCCostId.value > 0 || this._fcgbcForm.controls.tempRowIndex.value > 0) {
          this.gbcCostStatus = State.change;
          if (this.gbcCostStatus = 1) {
            this.isFirstDraft = true;
          }
          let index = this._fcgbcForm.controls.fiscalCountryGBCCostId.value > 0 ? this.rows.findIndex(d => d.fiscalCountryGBCCostId === this._fcgbcForm.controls.fiscalCountryGBCCostId.value)
            : this.rows.findIndex(d => d.tempRowIndex === this._fcgbcForm.controls.tempRowIndex.value); //find index in your array
          this.rows[index].fromDate = this.fromDate;
          this.rows[index].toDate = this.toDate;
          this.rows[index].lastUpdatedBy = this.lastUpdateBy;
          this.rows[index].gbc = this.gbc;
          this.rows[index].isFirstDraft = this.isFirstDraft;
          this.rows[index].fiscalCountryGBCCostId = this._fcgbcForm.controls.fiscalCountryGBCCostId.value;
          if (this.rows.length != 0)
            this.rows = this._fiscalcountryService.updateValidTo(this.rows, this._fcgbcForm);

        }
        else {
          this.gbcCostStatus = this.isEditFcGbcCost == true ? State.change : State.nochange;
          if (this.rows.length != 0)
            this.rows = this._fiscalcountryService.updateValidTo(this.rows, this._fcgbcForm);
          if (this.gbcCostStatus = 1) {
            this.isFirstDraft = true;
          }
          this.rows.push(this.createGbcFormGroup());
        }

        this.dataSource = new MatTableDataSource(this.rows);
        this.onClear();
      }
    }
    this.applicationInsightService.logEvent("GBCCost Add Row Ended");
  }
  onRemoveRow(rowIndex: any) {
    this.applicationInsightService.logEvent("GBCCost Delete Started");
    let index = rowIndex.fiscalCountryGBCCostId > 0 ? this.rows.findIndex(d => d.fiscalCountryGBCCostId === rowIndex.fiscalCountryGBCCostId) : this.rows.findIndex(d => d.fromDate === rowIndex.fromDate); //find index in your array
    this.gbcCostStatus = rowIndex.fiscalCountryGBCCostId > 0 ? State.change : State.nochange;
    this.rows.splice(index, 1);
    this.dataSource = new MatTableDataSource(this.rows);
    this.gbcCostStatus = (this.rows.length == 0 && this.TotalGbcCostLength > 0) ? State.delete : this.gbcCostStatus;
    this.applicationInsightService.logEvent("GBCCost Delete Ended");
  }
  updateRecord(rowIndex: any) {
    this.applicationInsightService.logEvent("GBCCost Edit by Id Started");
    this._fcgbcForm.controls.toDate.setValue(rowIndex.toDate);
    this._fcgbcForm.controls.fromDate.setValue(rowIndex.fromDate);
    this._fcgbcForm.controls.gbcCost.setValue(rowIndex.gbcCost);
    this._fcgbcForm.controls.fiscalCountryGBCCostId.setValue(rowIndex.fiscalCountryGBCCostId);
    this._fcgbcForm.controls.tempRowIndex.setValue(rowIndex.tempRowIndex);
    this.applicationInsightService.logEvent("GBCCost Edit by Id Ended");
  }

  createGbcFormGroup() {
    return {
      gbcCost: this.gbc,
      fromDate: this.fromDate,
      toDate: this.toDate,
      lastUpdatedBy: this.lastUpdateBy,
      fiscalCountryGBCCostId: this.fiscalCountryGBCCostId,
      tempRowIndex: (this.tempRowIndex = this.tempRowIndex + 1),
      isDelete: true,
      isFirstDraft: this.isFirstDraft
    };
  }
  ngOnChanges(changes: SimpleChanges) {
    this.onClear()
  }
  onClear() {
    this.applicationInsightService.logEvent("GBCCost Clear Started");
    this._fcgbcForm.controls.fromDate.clearValidators();
    this._fcgbcForm.controls.toDate.clearValidators();
    this._fcgbcForm.controls.fromDate.clearValidators();
    this._fcgbcForm.controls.gbcCost.clearValidators();
    this._fcgbcForm.controls.fromDate.setValue('');
    this._fcgbcForm.controls.toDate.setValue('');
    this._fcgbcForm.controls.gbcCost.setValue('');
    this._fcgbcForm.controls.fiscalCountryGBCCostId.setValue('');
    this._fcgbcForm.controls.tempRowIndex.setValue('');
    this.fnSetControlsValidation();
    this.applicationInsightService.logEvent("GBCCost Clear Ended");
  }
  //to clear after saving from parent component
  clearScope() {
    this.rows = [];
    this.dataSource = new MatTableDataSource([]);
    this.tempRowIndex = 0;
    this.fnSetControlsValidation();
  }

  fnFromDateValidation() {
    this.applicationInsightService.logEvent("GBCCost FromDateValidation Started");
    if (this._fcgbcForm.controls.fromDate.value != null && this._fcgbcForm.controls.fromDate.value != "") {
      let result = this._fiscalcountryService.fromDateValidatorfn(this.rows, "GBC", this._fcgbcForm.controls.fromDate);
      if (result == 'Invalid') {
        this._dialogService.openConfirmDialog('GBC cost already exist  . Do you want to continue ?')
          .afterClosed().subscribe(res => {
            if (res) { }
            else {
              this._fcgbcForm.controls.fromDate.setValue('');
            }
          })
      }
      else if (result == 'NotAllowed') {
        this._fcgbcForm.get('fromDate').setValidators(Validators.required);
        this._fcgbcForm.controls['fromDate'].updateValueAndValidity();
        this._fcgbcForm.get('fromDate')?.setErrors({ invalidDate: 'Valid To already exists in selected vat %' });
      }
    }
    this.applicationInsightService.logEvent("GBCCost FromDateValidation Ended");
  }
  fnToDateValidation() {
    this.applicationInsightService.logEvent("GBCCost ToDateValidation Started");
    if (this._fcgbcForm.controls.fromDate.value != null && this._fcgbcForm.controls.toDate.value != "") {
      let result = this._fiscalcountryService.toDateValidatorfn(this.rows, "GBC", this._fcgbcForm.controls.toDate);
      if (result == 'Invalid') {
        this._fcgbcForm.get('toDate').setValidators(Validators.required);
        this._fcgbcForm.controls['toDate'].updateValueAndValidity();
        this._fcgbcForm.get('toDate')?.setErrors({ invalidDate: 'Valid To already exists in selected vat %' });
      }
    }
    this.applicationInsightService.logEvent("GBCCost ToDateValidation Ended");
  }

  _fcgbcForm: FormGroup = new FormGroup({
    fiscalCountryGBCCostId: new FormControl(''),
    gbcCost: new FormControl('', [Validators.required]),
    fromDate: new FormControl('', Validators.required),
    toDate: new FormControl(''),
    lastUpdatedBy: new FormControl(''),
    tempRowIndex: new FormControl(''),
  })
  fnSetControlsValidation() {
    this._fcgbcForm.controls.gbcCost.setValidators([Validators.required]);
    this._fcgbcForm.controls.fromDate.setValidators([Validators.required]);
  }

}
function moment(arg0: Date) {
  throw new Error('Function not implemented.');
}

