<div class="wrap">
  <div class="fleft">
    <div class="ribbon">
      <div class="ribbon-stitches-top"></div>
      <div class="ribbon-content">
        <p>&nbsp;&nbsp;Geographical Country</p>
      </div>
      <div class="ribbon-stitches-bottom"></div>
    </div>
    <div class="add-div">
      <form [formGroup]="searchform">
        <mat-form-field [floatLabel]="'always'" appearance="outline" class="width-20 ml-1">
          <mat-label>Geographical Country</mat-label>
          <input type="text" id="SearchCountrylName" matInput [matAutocomplete]="autoC"
                 formControlName="geographicalCountryName" [(ngModel)]="geographicalCountryName" (ngModelChange)="filterItemGC($event)">
          <mat-autocomplete autoActiveFirstOption #autoC="matAutocomplete">
            <mat-option *ngFor="let GC of geographicalCountryNameFilterList" [value]="GC.geographicalCountryName">
              {{GC.geographicalCountryName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" appearance="outline" class="width-15 ml-1">
          <mat-label>ISO Alpha 2 Code</mat-label>
          <input type="text" id="SearchAlphaTwoCode" matInput [matAutocomplete]="autoGCAlpha2"
                 formControlName="geographicalCountryAlpha2ISOCode" [(ngModel)]="geographicalCountryAlpha2ISOCode" (ngModelChange)="filterItemGCAlpha2($event)">
          <mat-autocomplete autoActiveFirstOption #autoGCAlpha2="matAutocomplete">
            <mat-option *ngFor="let GC of geographicalCountryAlpha2ISOCodeFilterList" [value]="GC.geographicalCountryAlpha2ISOCode">
              {{GC.geographicalCountryAlpha2ISOCode}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" appearance="outline" class="width-15 ml-1">
          <mat-label>ISO Alpha 3 Code</mat-label>
          <input type="text" id="SearchAlphaThreeCode" matInput [matAutocomplete]="autoGCAlpha3"
                 formControlName="geographicalCountryAlpha3ISOCode" [(ngModel)]="geographicalCountryAlpha3ISOCode" (ngModelChange)="filterItemGCAlpha3($event)">
          <mat-autocomplete autoActiveFirstOption #autoGCAlpha3="matAutocomplete">
            <mat-option *ngFor="let GC of geographicalCountryAlpha3ISOCodeFilterList" [value]="GC.geographicalCountryAlpha3ISOCode">
              {{GC.geographicalCountryAlpha3ISOCode}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" appearance="outline" class="width-15 ml-1">
          <mat-label>ISO Numeric code</mat-label>
          <input type="text" id="SearchCountrylNumeric" matInput [matAutocomplete]="autoGCNumeric"
                 formControlName="geographicalCountryNumericISOCode" [(ngModel)]="geographicalCountryNumericISOCode" (ngModelChange)="filterItemGCNumeric($event)">
          <mat-autocomplete autoActiveFirstOption #autoGCNumeric="matAutocomplete">
            <mat-option *ngFor="let GC of geographicalCountryNumericFilterList" [value]="GC.geographicalCountryNumericISOCode">
              {{GC.geographicalCountryNumericISOCode}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <!-- <mat-form-field [floatLabel]="'always'" appearance="outline" class="width-11 ml-1">
            <mat-label>Status</mat-label>
            <mat-select id="Searchstatus" formControlName="status" [(ngModel)]="status">
                <mat-option value="all">
                    All
                </mat-option>
                <mat-option value="active">
                    Active
                </mat-option>
                <mat-option value="inactive">
                    Inactive
                </mat-option>
                <mat-option value="draft">
                    Draft
                </mat-option>
            </mat-select>
        </mat-form-field> -->
        <mat-checkbox formControlName="hMCountry" id=SearchhmCountry [(ngModel)]="hMCountry"
                      [checked]=false class="example-margin width-45 ml-2" color='primary'>HMCountry</mat-checkbox>

        <button id="SearchClear" type="submit" (click)="onSearchClear()" mat-raised-button
                class="add-button-container mat-raised-button mat-primary ml-2">
          Clear
        </button>
      </form>
    </div>
    <app-geographicalcountrylist (childButtonEvent)="receivedChildHandler($event)" [countrySearch]=searchform.value></app-geographicalcountrylist>
  </div>
  <div class="clear">
  </div>
  <div class="fright">
    <app-geographicalcountryform [countryList]=geographicalCountryNameFilterList></app-geographicalcountryform>
  </div>
</div>
