<div class="fixed-content mat-elevation-z2 form-border" id="scrolltop">
  <form [formGroup]="_southernhemisphereform">
    <section style="background-color: #dfdfdfdf !important;">
      <h2 mat-dialog-title class="align">{{isShEdit === true ? 'Update' : 'New'}} Southern Hemisphere</h2>
    </section>
    <div class="example-container add-form-field">
      <mat-form-field appearance="legacy">
        <mat-label>Season</mat-label>
        <input type="text" id="Searchseason" matInput [matAutocomplete]="autoSN" formControlName="seasonNumber"
        (keydown)="changeInput($event)" (ngModelChange)="filterSeason($event)" [(ngModel)]="updateShform.seasonNumber">
        <mat-autocomplete autoActiveFirstOption #autoSN="matAutocomplete" [displayWith]="displayTextSeason.bind(this)">
          <mat-option *ngFor="let season of seasonFilterList" [value]="season.seasonNumber">
            {{season.displayName}}
          </mat-option>
           </mat-autocomplete>
           <mat-error *ngFor="let validation of validationMessages?.autoCompleteControl">
            <div *ngIf="_southernhemisphereform.controls['seasonNumber'].hasError(validation.type)">
              {{validation.message}}
            </div>
          </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field appearance="legacy">
        <mat-label>Corporate Brand</mat-label>
        <input type="text" id=inpcorporatebrand matInput [matAutocomplete]="autoCB" formControlName="corporateBrandId"
          (keydown)="changeInput($event)" (ngModelChange)="filterItemCorporateBrand($event)" [(ngModel)]="updateShform.corporateBrandId"
          [readonly]="isShEdit=='fill'?true:false">
        <mat-autocomplete autoActiveFirstOption #autoCB="matAutocomplete" [displayWith]="displayTextCB.bind(this)">
          <mat-option *ngFor="let CBname of corporateBrandFilterList" [value]="CBname.corporateBrandId">
            {{CBname.corporateBrandName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <br>
      <mat-form-field appearance="legacy">
        <mat-label>Channel</mat-label>
        <input type="text" id=inpchannel matInput [matAutocomplete]="autoCL" formControlName="channelId"
          (keydown)="changeInput($event)" (ngModelChange)="filterchannel($event)" [(ngModel)]="updateShform.channelId"
          [readonly]="isShEdit=='fill'?true:false">
        <mat-autocomplete autoActiveFirstOption #autoCL="matAutocomplete" [displayWith]="displayTextCL.bind(this)">
          <mat-option *ngFor="let CLname of channelnameFilterList" [value]="CLname.channelId">
            {{CLname.channelName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <br>
      <app-southernhemispheredepartment #seasonDepartments id="emitSouthernhemispheredep"
        [departmentparam]=_southernhemisphereform.value (emitSouthernhemispheredep)="receivedChildHandler($event)">
      </app-southernhemispheredepartment>
      <section class="example-section">
        <mat-checkbox id="inpactive" formControlName="active" [(ngModel)]="updateShform.active" [checked]="true"
          class="example-margin width-25" color='primary' [disabled]=isActiveDisable>Active
        </mat-checkbox>
        <mat-checkbox id="inpdraft" formControlName="draft" [(ngModel)]="updateShform.draft"
          class="example-margin width-15" color='primary'>Draft
        </mat-checkbox>
      </section>
      <input type="hidden" formControlName="southernHemisphereId" [(ngModel)]="updateShform.southernHemisphereId">
      <br>
      <mat-dialog-actions *ngIf="userrole">
        <button [disabled]="(isSHEdit === true) ? !_southernhemisphereform.dirty : false"
          [ngClass]="(isSHEdit === true) ? 'mat-raised-button ml-1':'mat-raised-button ml-1 mat-primary'"
          id="btnSaveUpdate" type="submit" (click)="onSubmit()">{{isShEdit === true ? 'Update' : 'Save'}}</button>
        <button id="btnCancel" class="mat-raised-button ml-1 mat-primary" (click)="onClear()">Cancel</button>
      </mat-dialog-actions>
      <div clas="clearfix"></div>
      <br>
      <span *ngIf="!userrole" class="form-note">You can't save changes since you
        have Read access.</span>
   </div>
    <div>
    </div>
  </form>
</div>
