<mat-form-field appearance="legacy" class="width-73">
  <mat-label>Select Departments</mat-label>
	<mat-select #selectids (openedChange)="openedChange($event)" [formControl]="selectFormControl"  multiple>
		<mat-select-trigger>
			{{selectFormControl.value ? departmentName : ''}}
			<span *ngIf="selectFormControl.value?.length > 1" class="additional-selection">
        (+{{selectFormControl.value.length - 1}} {{selectFormControl.value?.length === 2 ? 'other' : 'others'}})
      </span>
    </mat-select-trigger>
    <div class="select-container">
    <mat-optgroup >
  <mat-form-field style="width:100%;">
    <input #search autocomplete="off" placeholder="Search Departments" aria-label="Search" matInput [formControl]="searchTextboxControl">
    <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch($event)">
    <mat-icon >close</mat-icon>
  </button>
         </mat-form-field>
    </mat-optgroup>
    <mat-optgroup *ngIf="(filteredOptions | async).length == 0">
      <div>No results found!</div>
    </mat-optgroup>
 <mat-option (onSelectionChange)="selectionChange(option.departmentId,$event)" *ngFor="let option of filteredOptions | async" [value]="option.departmentId">
  {{option.departmentCode}}-{{option.departmentName}}
      </mat-option>
</div>
</mat-select>
</mat-form-field>