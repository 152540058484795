<div class="wrap">
  <div class="filter-div">
    <div class="org-container">
      <form [formGroup]="_searchform">
        <mat-form-field  appearance="legacy" class="customform">
          <mat-label>Corporate Brand</mat-label>
          <input type="text" id="Searchcorporatebrand" matInput [matAutocomplete]="autoCB"
            formControlName="corporateBrandName" [(ngModel)]="corporateBrandName"
            (ngModelChange)="filterItemCorporateBrand($event)">
          <mat-autocomplete autoActiveFirstOption #autoCB="matAutocomplete">
            <mat-option *ngFor="let CBname of corporateBrandFilterList" [value]="CBname.corporateBrandCodeName" [matTooltipDisabled]="CBname.corporateBrandName.length<8"  matTooltip="{{CBname.corporateBrandCode}}&nbsp;{{CBname.corporateBrandName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
              {{CBname.corporateBrandCode}} {{CBname.corporateBrandName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
       <mat-form-field  appearance="legacy" class="customform">
          <mat-label>Markup Group </mat-label>
          <input type="text" id="SearchmarkupGroupName" matInput [matAutocomplete]="autoMGN"
            formControlName="markupGroupName" [(ngModel)]="markupGroupName"
            (ngModelChange)="filterItemMarkupGroup($event)">
          <mat-autocomplete autoActiveFirstOption #autoMGN="matAutocomplete">
            <mat-option *ngFor="let markupn of markupGroupNameFilterList" [value]="markupn.markupGroupCodeName" [matTooltipDisabled]="markupn.markupGroupName.length<8"  matTooltip="{{markupn.markupGroupCode}}&nbsp;{{markupn.markupGroupName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
              {{markupn.markupGroupCode}} {{markupn.markupGroupName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field  appearance="legacy" class="statusclass">
          <mat-label>Status</mat-label>
          <mat-select id="Searchstatus" formControlName="status" [(ngModel)]="status">
            <mat-option value="all">
              All
            </mat-option>
            <mat-option value="active">
              Active
            </mat-option>
            <mat-option value="inactive">
              Inactive
            </mat-option>
            <mat-option value="draft">
              Draft
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-button class="add-button-container mat-raised-button mat-primary ml-2" 
        id="searchClear" type="submit" (click)="onSearchClear()" >Clear</button>
      </form>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="tblleft">
    <app-markupgrouplist (childButtonEvent)="markupDropDownList($event)" [markupGroupSearch]=_searchform.value>
    </app-markupgrouplist>
  </div>
  <div class="frmright">
    <app-markupgroupform (childButtonEvent)="receivedChildHandler($event)"></app-markupgroupform>
  </div>
</div>