import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { MatConfirmDialogBoxComponent } from './../../../app/shared/components/mat-confirm-dialog-box/mat-confirm-dialog-box.component'

@Injectable({
  providedIn: 'root'
})
export class DialogBoxService {

  constructor( private dialog:MatDialog) { }

  openConfirmDialog(msg: string){
    return this.dialog.open(MatConfirmDialogBoxComponent,{
       width:'400px',
       panelClass:'confirm-dialog-container',
       disableClose:true,
       position:{top:"5%"},
       data:msg
     });
   }
}
