import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FiscalcountryService } from '../../../../services/country/fiscalcountry/fiscalcountry.service';
import { FiscalcountrylistComponent } from '../fiscalcountrylist/fiscalcountrylist.component';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
@Component({
  selector: 'fiscalcountry',
  templateUrl: './fiscalcountry.component.html',
  styleUrls: ['./fiscalcountry.component.css']
})

export class FiscalcountryComponent implements OnInit {
  @ViewChild('fiscalCountryList') public fiscalCountryDropDown: FiscalcountrylistComponent;
  title = "Fiscal Country"
  fiscalcountryMasterList: any;
  fiscalcountryNameFilterList: any;
  fiscalcountryIsoFilterList: any;
  fiscalcountryCodeFilterList: any;
  fiscalCountryName: any = "";
  fiscalCountryISOCode: any = "";
  fiscalCountryCode: any = "";
  status: any = "active";
  errorMsg: any;
  obj: string[];
  constructor(public _fiscalcountryService: FiscalcountryService,
    private router: Router,
    public route: ActivatedRoute,
    private applicationInsightService: ApplicationInsightService,
    private toastr: ToastrService, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
  }
  /* istanbul ignore next */
  receivedChildHandler(obj: any) {
    this.applicationInsightService.logEvent("FiscalCountry receivedChildHandler Started");
    this.fiscalcountryMasterList = this.fiscalcountryNameFilterList = this.fiscalcountryCodeFilterList = this.fiscalcountryIsoFilterList = obj;
    if (this.fiscalcountryMasterList.filter != null || undefined) {
      this.fiscalcountryCodeFilterList = this.fiscalcountryMasterList.filter((item, i, arr) => arr.findIndex((t) => t.fiscalCountryCode === item.fiscalCountryCode) === i);
      this.fiscalcountryCodeFilterList = this.fiscalcountryCodeFilterList.sort((a, b) => a.fiscalCountryCode.localeCompare(b.fiscalCountryCode));
      this.fiscalcountryNameFilterList = this.fiscalcountryMasterList.sort((a, b) => a.geographicalCountryName.localeCompare(b.geographicalCountryName));
      this.fiscalcountryIsoFilterList = this.fiscalcountryMasterList.filter((item, i, arr) => arr.findIndex((t) => t.geographicalCountryAlpha2ISOCode === item.geographicalCountryAlpha2ISOCode) === i);
      this.fiscalcountryIsoFilterList = this.fiscalcountryIsoFilterList.sort((a, b) => a.geographicalCountryAlpha2ISOCode.localeCompare(b.geographicalCountryAlpha2ISOCode));
    }
    this.applicationInsightService.logEvent("FiscalCountry receivedChildHandler Ended");
  }

  filterItemfiscalcountryName(filterKey: string) {
    this.applicationInsightService.logEvent("FiscalCountry Filter FiscalCountryName Started");
    if (filterKey != null && filterKey != "") {
      /* istanbul ignore next */
      this.fiscalcountryNameFilterList = this.fiscalcountryMasterList.filter(x => x.geographicalCountryName.toLowerCase().includes(filterKey.toString().toString().toLowerCase()));
      this.fiscalcountryNameFilterList = this.fiscalcountryNameFilterList.sort((a, b) => a.geographicalCountryName.localeCompare(b.geographicalCountryName));
    }
    else
      this.fiscalcountryNameFilterList = this.fiscalcountryMasterList != undefined ? this.fiscalcountryMasterList.sort((a, b) => a.geographicalCountryName.localeCompare(b.geographicalCountryName)) : this.fiscalcountryMasterList;
    this.applicationInsightService.logEvent("FiscalCountry Filter FiscalCountryName Ended");
  }
  filterItemfiscalcountryIso(filterKey: string) {
    this.applicationInsightService.logEvent("FiscalCountry Filter FiscalCountryIso Started");
    /* istanbul ignore next */
    if (filterKey != null && filterKey != "") {
      this.fiscalcountryIsoFilterList = this.fiscalcountryMasterList.filter(x => x.geographicalCountryAlpha2ISOCode.toLowerCase().includes(filterKey.toString().toLowerCase()));
      this.fiscalcountryIsoFilterList = this.fiscalcountryIsoFilterList.sort((a, b) => a.geographicalCountryAlpha2ISOCode.localeCompare(b.geographicalCountryAlpha2ISOCode));
    }
    else
      this.fiscalcountryIsoFilterList = this.fiscalcountryMasterList != undefined ? this.fiscalcountryMasterList.sort((a, b) => a.geographicalCountryAlpha2ISOCode.localeCompare(b.geographicalCountryAlpha2ISOCode)) : this.fiscalcountryMasterList;
    this.applicationInsightService.logEvent("FiscalCountry Filter FiscalCountryIso Ended");
  }
  filterItemfiscalcountryCode(filterKey: string) {
    this.applicationInsightService.logEvent("FiscalCountry Filter FiscalCountryCode Started");
    /* istanbul ignore if */
    if (filterKey != null && filterKey != "") {
      this.fiscalcountryCodeFilterList = this.fiscalcountryMasterList.filter(x => x.fiscalCountryCode.toLowerCase().includes(filterKey.toString().toLowerCase()));
      this.fiscalcountryCodeFilterList = this.fiscalcountryCodeFilterList.sort((a, b) => (a.fiscalCountryCode > b.fiscalCountryCode ? 1 : -1));
    }
    else
      this.fiscalcountryCodeFilterList = this.fiscalcountryMasterList != undefined ? this.fiscalcountryMasterList.sort((a, b) => (a.fiscalCountryCode > b.fiscalCountryCode ? 1 : -1)) : this.fiscalcountryMasterList;
    this.applicationInsightService.logEvent("FiscalCountry Filter FiscalCountryCode Ended");
  }

  //clear button click event
  onSearchClear() {
    this.applicationInsightService.logEvent("FiscalCountry Clear Started");
    this._searchform.controls.fiscalCountryName.setValue("");
    this._searchform.controls.fiscalCountryISOCode.setValue("");
    this._searchform.controls.fiscalCountryCode.setValue("");
    this._searchform.controls['status'].setValue("active");
    this.applicationInsightService.logEvent("FiscalCountry Clear Ended");
  }
  _searchform: FormGroup = new FormGroup({
    fiscalCountryName: new FormControl(''),
    fiscalCountryISOCode: new FormControl(''),
    fiscalCountryCode: new FormControl(''),
    status: new FormControl('')
  })
}
