import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) { }
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log("inside intercept");
    return next.handle(httpRequest).pipe(
      catchError(this.handleError)
    );
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.statusText}`;

    } else {
      // Server-side errors  
      if (error.status === 500) {
        errorMessage = `Error Message: System Error Please Contact Administrator`;
      }
      else if (error.status === 400) {
        errorMessage = `Error Message: ${error.error.Message}`;
      }
      else if (error.status === 404) {
        errorMessage = `Error Message: ${error.statusText}`;
      }
      else {
        errorMessage = `Error Message: ${error.statusText}`;
      }

    }
   return throwError(errorMessage);
  }
 }
