import { Component, OnInit, ViewChild, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { CorporatebrandService } from 'src/app/services/organization/corporatebrand/corporatebrand.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CorporateBrandModel } from 'src/app/models/organization/corporateBrand/corporatebrandmodel'
import { Subscription } from 'rxjs';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { CorporateviewComponent } from './corporatebrandView.component';
import { ModulePermission } from '../../../../models/enum';

@Component({
  selector: 'app-corporatebrandlist',
  templateUrl: './corporatebrandlist.component.html',
  styleUrls: ['./corporatebrandlist.component.css']
})
export class CorporatebrandlistComponent implements OnInit, OnChanges {
  @Input() corporateBrandSearch: any;
  @Output() childButtonEvent = new EventEmitter();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<CorporateBrandModel>;
  public dataSource: MatTableDataSource<CorporateBrandModel>;
  // MatPaginator Inputs
  pageNumber: any = 1;
  length: any;
  pageIndex: any;
  pageSize: any = 15;
  sortByColumnName: any = "corporateBrandSortOrder";
  sortByOrder: any = "asc";
  pageEvent: PageEvent;
  errorMsg: any;
  customDateTime: any = "";
  active: any;
  draft: any;
  subscription: Subscription;
  emitter: number = 1;
  displayedColumns = [
    'corporateBrandCode',
    'corporateBrandName',
    'corporateBrandSortOrder',
    'lastUpdatedTime',
    'Actions'
  ];
  matDialogRef: MatDialogRef<CorporateviewComponent>;
  filteredCBList: any;
  userrole: boolean;
  users: any;
  constructor(
    public _corporatebrandService: CorporatebrandService,
    public route: ActivatedRoute,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private applicationInsightService: ApplicationInsightService,
  ) { this.users = JSON.parse(localStorage.getItem("users") || "[]") }

  getpagesizeoptions(): number[] {
    if (this.length > 25)
      return [10, 20, 25, this.length];
    else if (this.length > 20)
      return [10, 20, this.length];
    else if (this.length > 10)
      return [10, this.length];
    return null;
  }
  ngOnInit(): void {
    this.userrole = this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this.subscription = this._corporatebrandService.refreshTableCorporateBrand$.subscribe(
      refreshTableCorporateBrand => {
        this.emitter = 1
        this.BindCorporateBrandData();
      });
  }
  public ngOnDestroy(): void {
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }
  ngOnChanges(changes: SimpleChanges) {
    this.pageNumber = 1;
    this.BindCorporateBrandData();
  }
  /* istanbul ignore next */
  sortData(sort: Sort) {
    this.applicationInsightService.logEvent("Sorting started");
    this.sortByColumnName = sort.active;
    this.sortByOrder = sort.direction;
    this.applicationInsightService.logEvent("Sorting ended");
    this.BindCorporateBrandData();
  }

  BindCorporateBrandData() {
    this.applicationInsightService.logEvent("Bind Corporatebrand started");
    let corporateBrandList: any[] = [];
    let filteredCBList: any[] = [];
    if (this.corporateBrandSearch.status === "draft") { this.draft = true; this.active = ""; }
    if (this.corporateBrandSearch.status === "inactive") { this.active = false; this.draft = false; }
    if (this.corporateBrandSearch.status === "active") { this.active = true; this.draft = false; }
    if (this.corporateBrandSearch.status === "all") { this.active = ""; this.draft = ""; }
    // below code is for number plus string search
    var hasNumber = /\d/;
    if(isNaN(this.corporateBrandSearch?.corporateBrandName)){
      if(hasNumber.test(this.corporateBrandSearch?.corporateBrandName)){
        this.corporateBrandSearch.corporateBrandCodeName = this.corporateBrandSearch.corporateBrandName;
        this.corporateBrandSearch.corporateBrandCode ='';
        this.corporateBrandSearch.corporateBrandName='';
      }else{
        this.corporateBrandSearch.corporateBrandName = this.corporateBrandSearch.corporateBrandName;
        this.corporateBrandSearch.corporateBrandCode ='';
        this.corporateBrandSearch.corporateBrandNameCode ='';
      }
    }else{
      this.corporateBrandSearch.corporateBrandCode = this.corporateBrandSearch.corporateBrandName;
      this.corporateBrandSearch.corporateBrandName='';
      this.corporateBrandSearch.corporateBrandNameCode ='';
    }
    this._corporatebrandService.getCorporateBrandList().subscribe((data: any) => {
      this.applicationInsightService.logEvent("Corporate listing and filter started");
      //filtering
      corporateBrandList = data.getCorporateBrandAll;
      if (corporateBrandList != null) {
        filteredCBList = corporateBrandList
        .filter(c => c.corporateBrandCode.toString().includes(this.corporateBrandSearch.corporateBrandCode.toString()))
        .filter(c => c.corporateBrandCodeName.toString().includes(this.corporateBrandSearch.corporateBrandCodeName.toString()))
        .filter(CB => CB.corporateBrandName.toString().toLowerCase().includes(this.corporateBrandSearch.corporateBrandName.toString().toLowerCase()))
        .filter(CB => CB.active.toString().includes(this.active.toString()))
        .filter(CB => CB.draft.toString().includes(this.draft.toString()));
         for (let i = 0; i < filteredCBList.length; i++) {
          if (filteredCBList[i].corporateBrandName.length > 28) {
            filteredCBList[i].isdisableCB = false;
          }
          else {
            filteredCBList[i].isdisableCB = true;
          }
        }
        this.dataSource = new MatTableDataSource(filteredCBList);
        this.sort.active = this.sortByColumnName;
        this.sort.direction = this.sortByOrder;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.length = this.dataSource.data.length;
        if (this.emitter == 1) {
          this.emitter = 2;
          this.childButtonEvent.emit(data.getCorporateBrandAll);
        }
      }
      this.applicationInsightService.logEvent("Corporate listing and filter ended");
    },
      error => {
        this.errorMsg = error;
        this.toastr.error(error);
      });
    this.applicationInsightService.logEvent("Bind Corporateband ended");
  }
  //While clicking Edit button load respective data to entry page
  updateRecord(updateRowData: any) {
    this._corporatebrandService.getCorporateBrandIdFromList(updateRowData.corporateBrandId);
  }
  openDialog(row: any) {
    this.matDialogRef = this.dialog.open(CorporateviewComponent, {
      data: { rowData: row },
      disableClose: false
    });
    this.matDialogRef.afterClosed().subscribe(res => {
      if ((res == true)) {
        row = "";
      }
    });
  }
}
