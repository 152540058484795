import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { IndexService } from 'src/app/services/organization/index/index.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { constant } from 'src/app/shared/const/constant';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import {IndexviewComponent} from './indexview.component';
import { ModulePermission } from '../../../../models/enum';


export interface iIndex {
  indexId: any;
  corporateBrandName: any;
  indexCode: any;
  indexGroupId: any;
  indexGroupName: any;
  indexSortOrder: any;
  active: any;
  draft: any;
  lastUpdatedTime: any;
}

@Component({
  selector: 'app-indexlist',
  templateUrl: './indexlist.component.html',
  styleUrls: ['./indexlist.component.css']
})
export class IndexlistComponent implements OnInit {

  @Input() indexSearch: any;
  @Output() childButtonEvent = new EventEmitter();
  public dataSource: MatTableDataSource<iIndex>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<iIndex>;
  corporateBrandName: any;
  indexGroupName: any;
  indexCode: any;
  active: any;
  draft: any;
  indexList: any;
  // MatPaginator Inputs
  pageNumber: any = 1;
  length: any;
  pageSize: any = 15;
  sortByColumnName: any = "indexSortOrder";
  sortByOrder: any = "asc";
  pageEvent: PageEvent;
  subscription: Subscription;
  errorMsg:any;
  emitter:number=1;
  customDateTime: string;
  userrole: boolean;
  users: any;
  sortClick:boolean=false;
  getpagesizeoptions(): number[] {
      /* istanbul ignore next */
    if (this.length > 25)
      return [10, 20, 25, this.length];
    else if (this.length > 20)
      return [10, 20, this.length];
    else if (this.length > 10)
      return [10, this.length];
    return null;
  }

  displayedColumns = [
    'corporateBrandName',
    'indexGroupName',
    'indexName',
    'indexCode',
    'indexSortOrder',
    'lastUpdatedTime',
    'Actions'
  ];
  matDialogRef: MatDialogRef<IndexviewComponent>;

  constructor(public _indexService: IndexService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private applicationInsightService:ApplicationInsightService) { this.users = JSON.parse(localStorage.getItem("users") || "[]") }

  ngOnInit(): void {
    this.userrole = this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this.subscription = this._indexService.refreshTableIndex$.subscribe(
      x => {
        this.emitter=1;
        this.BindIndexData();
      });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }

  ngOnChanges(changes: SimpleChanges) {
    this.pageNumber = 1;
    this.BindIndexData();
  }
  /* istanbul ignore next */
  sortData(sort: Sort) {
    this.sortByColumnName = sort.active;
    this.sortByOrder = sort.direction;
    this.sortClick = true;
    this.BindIndexData();
  }
/* istanbul ignore next */
  public GetIndexDatawithfilters(event?: PageEvent) {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.sortClick = true;
    this.BindIndexData();
    return event;
  }

  BindIndexData() {
    this.applicationInsightService.logEvent("BindIndexData started");
      let newindexList: any[] = [];
      let filterData: any[] = [];
      if(this.indexSearch !=null){
        if (this.indexSearch.status == "active") { this.active = true; this.draft = false; }
        if (this.indexSearch.status == "inactive") { this.active = false; this.draft = false; }
        if (this.indexSearch.status == "draft") { this.draft = true; this.active = ""; }
        if (this.indexSearch.status == "all") { this.draft = ""; this.active = ""; }
      }
      // below code is for number plus string search
      var hasNumber = /\d/;
      if(!this.sortClick){
      if(isNaN(this.indexSearch?.corporateBrandName)){
        if(hasNumber.test(this.indexSearch?.corporateBrandName)){
          this.indexSearch.corporateBrandCodeName = this.indexSearch.corporateBrandName;
          this.indexSearch.corporateBrandCode ='';
          this.indexSearch.corporateBrandName='';
        }else{
        this.indexSearch.corporateBrandName = this.indexSearch.corporateBrandName;
        this.indexSearch.corporateBrandCode ='';
        this.indexSearch.corporateBrandCodeName = ''
        }
      }else{
        this.indexSearch.corporateBrandCode = this.indexSearch.corporateBrandName;
        this.indexSearch.corporateBrandName='';
        this.indexSearch.corporateBrandCodeName = ''
      }

      if(this.indexSearch?.indexName?.length===1){
        this.indexSearch.indexCode = this.indexSearch.indexName;
        this.indexSearch.indexName='';
      }else{
        this.indexSearch.indexCodeName =  this.indexSearch.indexName;
        this.indexSearch.indexCode ='';
      }
      if(isNaN(this.indexSearch?.indexGroupName)){
        if(hasNumber.test(this.indexSearch?.indexGroupName)){
          this.indexSearch.indexGroupCodeName = this.indexSearch?.indexGroupName;
          this.indexSearch.indexGroupCode =''
          this.indexSearch.indexGroupName =''
        }else{
          this.indexSearch.indexGroupName = this.indexSearch?.indexGroupName;
          this.indexSearch.indexGroupCode ='';
          this.indexSearch.indexGroupCodeName =''
        }
      }else{
        this.indexSearch.indexGroupCode = this.indexSearch?.indexGroupName;
        this.indexSearch.indexGroupName ='';
        this.indexSearch.indexGroupCodeName ='';
      }
    }
      this._indexService.getIndexList().subscribe((data: any) => {     
        this.indexList = newindexList = data.index;
        if(newindexList != null)
        {
          filterData = newindexList
            .filter(x => x.corporateBrandName.toString().toLowerCase().includes(this.indexSearch.corporateBrandName.toString().toLowerCase()))
            .filter(x => x.corporateBrandCodeName.toString().includes(this.indexSearch.corporateBrandCodeName.toString()))
            .filter(c => c.corporateBrandCode.includes(this.indexSearch.corporateBrandCode))
            .filter(x => x.indexCodeName.toString().includes(this.indexSearch.indexCodeName.toString()))
            .filter(x => x.indexCode.toString().toLowerCase().includes(this.indexSearch.indexCode.toString().toLowerCase()))
            .filter(x => x.indexGroupCode.toString().includes(this.indexSearch.indexGroupCode.toString()))
            .filter(x => x.indexGroupName.toString().toLowerCase().includes(this.indexSearch.indexGroupName.toString().toLowerCase()))
            .filter(x => x.indexGroupCodeName.toString().includes(this.indexSearch.indexGroupCodeName.toString()))
            .filter(x => x.active.toString().includes(this.active.toString()))
            .filter(x => x.draft.toString().includes(this.draft.toString()));            
          this.dataSource = new MatTableDataSource(filterData);
          this.sort.active = this.sortByColumnName;
          this.sort.direction = this.sortByOrder;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.length = this.dataSource.data.length;
          this.sortClick = false;
             /* istanbul ignore next */
          if(this.emitter==1)
          {
            this.emitter=2;
            this.childButtonEvent.emit(data.index);
          }
        }
      },
        error => { this.errorMsg = error; });
        this.applicationInsightService.logEvent("BindIndexData ended");  
  }

  //While clicking Edit button load respective data to entry page
  updateRecord(updateRowData: any) {
    this._indexService.getIndexIdFromList(updateRowData.indexId);
  }

  openDialog(row: any) {
    let subIndexList:any;
    let rowData:any;
    this._indexService.getSubIndexByIndexId().subscribe(data=>{
      subIndexList = data.subIndex.filter(object => {
        return object['indexId'] == row.indexId;
      })
      this.matDialogRef = this.dialog.open(IndexviewComponent, {
            data: { subIndexList: subIndexList,rowData:row },
            disableClose: false
          });
    },error => { this.toastr.error(error, "", { timeOut: constant.toasterTimeout }) })
    
    this.matDialogRef.afterClosed().subscribe(res => {
      if ((res == true)) {
        subIndexList = "";
      }
    });
  }
}
