import { Component, OnInit, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { IndexgroupService } from 'src/app/services/organization/indexgroup/indexgroup.service';
import { IndexService } from 'src/app/services/organization/index/index.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import {IndexGroupviewComponent} from './indexgroupview.component';
import { constant } from 'src/app/shared/const/constant';
import { ModulePermission } from '../../../../models/enum';

export interface getindexgroupAll {
  indexGroupId: any;
  corporateBrandName: any;
  indexGroupCode: any;
  indexGroupSortOrder: any;
  indexGroupName: any;
  active: any;
  draft: any;
}

@Component({
  selector: 'app-indexgrouplist',
  templateUrl: './indexgrouplist.component.html',
  styleUrls: ['./indexgrouplist.component.css']
})
export class IndexgrouplistComponent implements OnInit {

  @Input() indexGroupSearch: any;
  public dataSource: MatTableDataSource<getindexgroupAll>;
  customDateTime: string;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<getindexgroupAll>;
  @Output() childButtonEvent = new EventEmitter();
  corporateBrandName: any = '';
  indexGroupCode: any = '';
  indexGroupName: any = '';
  active: any;
  draft: any;
  errorMsg: any;
  // MatPaginator Inputs
  pageNumber: any = 1;
  length: any;
  pageIndex: any;
  pageSize: any = 15;
  sortByColumnName: any = "indexGroupSortOrder";
  sortByOrder: any = "asc";
  pageEvent: PageEvent;
  Serviceactive: any = true;
  ServiceDraft: any = false;
  subscription: Subscription;
  indexGroupList: any;
  emitter:number=1;
  userrole: boolean;
  users: any;
  sortClick:boolean=false;
  displayedColumns = [
    'corporateBrandName',
    'indexGroupCode',
    'indexGroupName',
    'indexGroupSortOrder',
    'lastUpdatedTime',
    'Actions'
  ];
  matDialogRef: MatDialogRef<IndexGroupviewComponent>;

  constructor(public _indexgroupservice: IndexgroupService, 
    public route: ActivatedRoute,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private applicationInsightService:ApplicationInsightService,
    private indexService:IndexService) { this.users = JSON.parse(localStorage.getItem("users") || "[]") }

  getpagesizeoptions(): number[] {
    if (this.length > 25)
      return [10, 20, 25, this.length];
    else if (this.length > 20)
      return [10, 20, this.length];
    else if (this.length > 10)
      return [10, this.length];
    return null;
  }

  ngOnInit() {
    this.applicationInsightService.logEvent("Indexgroup list page Initialization started");
    this.userrole = this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this.subscription = this._indexgroupservice.refreshTableIndexGroup$.subscribe(
      refreshTableIndexGroup => {
        this.emitter=1;
        this.BindIndexGroupData();
      });
      this.applicationInsightService.logEvent("Indexgroup list page Initialization ended");
  }

  ngOnChanges(changes: SimpleChanges) {
    this.applicationInsightService.logEvent("Indexgroup list page ngOnChanges started");
    this.pageNumber = 1;
    this.BindIndexGroupData();
    this.paginator.pageIndex = 0;
    this.applicationInsightService.logEvent("Indexgroup list page ngOnChanges ended");
  }

  sortData(sort: Sort) {
    this.applicationInsightService.logEvent("Indexgroup list page sortData started");
    this.sortByColumnName = sort.active;
    this.sortByOrder = sort.direction;
    this.sortClick = true;
    this.BindIndexGroupData();
    this.applicationInsightService.logEvent("Indexgroup list page sortData ended");
  }

  public GetIndexGroupDatawithfilters(event?: PageEvent) {
    this.pageSize = event.pageSize;
    this.sortClick = true;
    this.BindIndexGroupData();
    return event;
  }

  BindIndexGroupData() {
    this.applicationInsightService.logEvent("BindIndexGroupData started");
    let newindexGroupList: any[] = [];
    var filterData: any[] = [];
    if (this.indexGroupSearch.status == "active") { this.Serviceactive = true; this.ServiceDraft = false; }
    if (this.indexGroupSearch.status == "inactive") { this.Serviceactive = false; this.ServiceDraft = false; }
    if (this.indexGroupSearch.status == "draft") { this.ServiceDraft = true; this.Serviceactive = ""; }
    if (this.indexGroupSearch.status == "all") { this.ServiceDraft = ""; this.Serviceactive = ""; }
    // below code is for number plus string search
    var hasNumber = /\d/;
    if(!this.sortClick){
      if (isNaN(this.indexGroupSearch?.corporateBrandName)) {
        if(hasNumber.test(this.indexGroupSearch?.corporateBrandName)){
          this.indexGroupSearch.corporateBrandCodeName = this.indexGroupSearch.corporateBrandName;
          this.indexGroupSearch.corporateBrandCode = '';
          this.indexGroupSearch.corporateBrandName = '';
        }else{
        this.indexGroupSearch.corporateBrandName = this.indexGroupSearch.corporateBrandName;
        this.indexGroupSearch.corporateBrandCode = '';
        this.indexGroupSearch.corporateBrandCodeName ='';
        }
      } else {
        this.indexGroupSearch.corporateBrandCode = this.indexGroupSearch.corporateBrandName;
        this.indexGroupSearch.corporateBrandName = '';
        this.indexGroupSearch.corporateBrandCodeName ='';
      }
      
      if(isNaN(this.indexGroupSearch?.indexGroupName)){
        if(hasNumber.test(this.indexGroupSearch?.indexGroupName)){
          this.indexGroupSearch.indexGroupCodeName = this.indexGroupSearch?.indexGroupName;
          this.indexGroupSearch.indexGroupCode ='';
          this.indexGroupSearch.indexGroupName ='';
        }else{
          this.indexGroupSearch.indexGroupName = this.indexGroupSearch?.indexGroupName;
          this.indexGroupSearch.indexGroupCode ='';
          this.indexGroupSearch.indexGroupCodeName ='';
        }
      }else{
        this.indexGroupSearch.indexGroupCode = this.indexGroupSearch?.indexGroupName;
        this.indexGroupSearch.indexGroupName ='';
        this.indexGroupSearch.indexGroupCodeName = '';
      }
    }
    
    this._indexgroupservice.getIndexGroupAll().subscribe((data: any) => {
      this.indexGroupList = newindexGroupList = data.indexGroup;
      if (newindexGroupList != null) {
        filterData = newindexGroupList
          .filter(x => x.corporateBrandName.toString().toLowerCase().includes(this.indexGroupSearch.corporateBrandName.toString().toLowerCase()))
          .filter(x => x.corporateBrandCodeName.toString().includes(this.indexGroupSearch.corporateBrandCodeName.toString()))
          .filter(c => c.corporateBrandCode.includes(this.indexGroupSearch.corporateBrandCode))
          .filter(x => x.indexGroupCode.toString().toLowerCase().includes(this.indexGroupSearch.indexGroupCode.toString().toLowerCase()))
          .filter(x => x.indexGroupName.toString().toLowerCase().includes(this.indexGroupSearch.indexGroupName.toString().toLowerCase()))
          .filter(x => x.indexGroupCodeName.toString().includes(this.indexGroupSearch.indexGroupCodeName.toString()))
          .filter(x => x.active.toString().includes(this.Serviceactive.toString()))
          .filter(x => x.draft.toString().includes(this.ServiceDraft.toString()));         
        this.dataSource = new MatTableDataSource(filterData);
        this.sort.active = this.sortByColumnName;
        this.sort.direction = this.sortByOrder;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.length = this.dataSource.data.length;
        this.sortClick = false;
        if(this.emitter==1)
        { 
          this.emitter=2;
          this.childButtonEvent.emit(data.indexGroup);
        }        
      }
    },
      error => { this.errorMsg = error; })
      this.applicationInsightService.logEvent("BindIndexGroupData ended");
  }

  //While clicking Edit button load respective data to entry page
  updateRecord(updateRowData: any) {
    this._indexgroupservice.getIndexGroupIDFromList(updateRowData.indexGroupId);
  }

  openDialog(row: any) {
    let indexList:any;
    let rowData:any;
    this.indexService.getIndexList().subscribe(data=>{
      indexList = data.index.filter(object => {
        return object['indexGroupId'] == row.indexGroupId;
      })
     this.matDialogRef = this.dialog.open(IndexGroupviewComponent, {
        data: { index: indexList, rowData:row },
        disableClose: false
      });
    },error => { this.toastr.error(error, "", { timeOut: constant.toasterTimeout }) })
    
    this.matDialogRef.afterClosed().subscribe(res => {
      if ((res == true)) {
        indexList = "";
        row="";
      }
    });
  }
}
