import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ServiceApi } from '../../../services/ServiceApi';



let headers = new HttpHeaders()
if (!headers.has('content-type')) {
  headers = headers.append('content-type', 'application/json')
}

@Injectable({
  providedIn: 'root'
})
export class ProductbrandService {
  resfreshCall = new BehaviorSubject<boolean>(false);
  headers = new HttpHeaders().set('content-type', 'application/json').set('accept', 'application/json');
  httpOptions = {
    headers: this.headers
  }
 
  constructor(private http: HttpClient,
    public _serviceApi: ServiceApi) {

  }

  getProductBrandList(): Observable<any> {
    let url = this._serviceApi.ProductBrandGetAll;
    return this.http.get<any>(url)
  }

  getGetProductBrandDepartmentsAndSectionsAndDivisions(data:any): Observable<any>{
    let url = this._serviceApi.GetProductBrandDepartmentsAndSectionsAndDivisions;  
    var body=data;
    return this.http.post(url,body,{'headers':headers}).pipe();
  }

  GetProductBrandId(id):Observable<any> {
    let url = this._serviceApi.GetProductBrandId;
    return this.http.get<any>(url+id)
  }

  UpdateProductBrand(updatedData: any): Observable<any>
  {
    let url = this._serviceApi.UpdateProductBrand
    return this.http.put(url, updatedData, { 'headers': headers }).pipe();
  }
}
