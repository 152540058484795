
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, Subject } from 'rxjs';
import { ServiceApi } from '../../../services/ServiceApi';
let headers = new HttpHeaders()
if (!headers.has('content-type')) {
  headers = headers.append('content-type', 'application/json')
}

@Injectable({
  providedIn: 'root'
})
export class IndexService {

  constructor(private http: HttpClient,public _serviceApi: ServiceApi) {
  }

  headers = new HttpHeaders().set('content-type', 'application/json').set('accept', 'application/json');
  httpOptions = {
    headers: this.headers
  }

  // Observable string sources
  public indexIdSource = new Subject<string>();

  // Observable string streams
  public indexId$ = this.indexIdSource.asObservable();

   // Service message commands
   getIndexIdFromList(mission: string) {
    this.indexIdSource.next(mission);
  }

  // Observable string sources
  public refreshTable = new Subject<string>();
  // Observable string streams
   public refreshTableIndex$ = this.refreshTable.asObservable();

  // Service message commands
  refreshIndexList() {
    this.refreshTable.next();
  }

  //List Index from Service
  getIndexList(): Observable<any> {
    return this.http.get(this._serviceApi.IndexGetAll);
  }

  //Create Index
  createIndex(createNewIndex: any): Observable<any> {
    return this.http.post(this._serviceApi.IndexCreate, JSON.stringify(createNewIndex), { 'headers': headers });
  }

  //Update Index
  updateIndex(updateIndex: any): Observable<any> {
   return this.http.put(this._serviceApi.IndexUpdate, JSON.stringify(updateIndex), { 'headers': headers });
  }

  //Get Index By Id
  getIndexById(indexId: any): Observable<any> {
    return this.http.get(this._serviceApi.IndexGetByID + indexId);
  }

  //Get Index Codes
  getIndexCode(corporateBrandId: any) {
   var hasCorporateBrandId =corporateBrandId!==""? "?corporateBrandId="+corporateBrandId:corporateBrandId;
   return this.http.get(this._serviceApi.IndexCodeGet+hasCorporateBrandId);
  }
getIndexByCBId(corporateBrandId: any) {
 return this.http.get(this._serviceApi.IndexCodeGetByCBId+corporateBrandId);
}

  //Get Sub Index Code by Index Id
  getSubIndexByIndexId(): Observable<any> 
  { return this.http.get(this._serviceApi.SubIndexByIndexId);}
  


}
