import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CorporatebrandService } from 'src/app/services/organization/corporatebrand/corporatebrand.service';
import { DivisionService } from 'src/app/services/organization/division/division.service';
import { SectionService } from 'src/app/services/organization/section/section.service';
import { SectionlistComponent } from '../sectionlist/sectionlist.component';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})
export class SectionComponent implements OnInit {

  @ViewChild('sectionlist') public sectionList: SectionlistComponent;

  corporateBrandMasterIGList: any[] = [];
  corporateBrandFilterIGList: any[] = [];
  sectionnameFilterList: any[] = [];
  sectionnameMasterList: any[] = [];
  sectionCodeFilterList: any[] = [];
  sectionCodeMasterList: any[] = [];
  divisionFilterList: any[] = [];
  divisionMasterList: any[] = [];
  cloneDivisionMasterList: any[] = [];
  clonesectionCodeMasterList: any[] = [];
  clonesectionnameMasterList: any[] = [];
  corporateBrandName: any = "";
  sectionName: any = "";
  sectionCode: any = "";
  division: any = "";
  status: any = "active";
  divisionList: any[] = [];
  obj: any[] = [];

  constructor(public _corporatebrandService: CorporatebrandService, public _divisionService: DivisionService, private applicationInsightService: ApplicationInsightService, public _sectionService: SectionService) { }

  ngOnInit(): void {
  }

  receivedChildHandler(obj: any) {
    this.applicationInsightService.logEvent("Section receivedChildHandler Started");
    if (obj.corporateBrandList != null || undefined) {
      this.corporateBrandMasterIGList = this.corporateBrandFilterIGList = obj.corporateBrandList;
      this.divisionMasterList = this.divisionFilterList = obj.divisionList.sort((a, b) => (a.divisionCode > b.divisionCode ? 1 : -1));
      this.sectionDropDown();
    }
    this.applicationInsightService.logEvent("Section receivedChildHandler Ended");
  }
  sectionDropDown() {
    this.applicationInsightService.logEvent("Section DropDown Started");
    this._sectionService.getSectionAll().subscribe((data: any) => {
      var distinctSectionName = data.section;
      this.sectionnameFilterList = this.sectionnameMasterList = distinctSectionName.sort((a, b) => a.sectionCode.toString().localeCompare(b.sectionCode));
      var distinctSectionCode = data.section
      this.sectionCodeFilterList = this.sectionCodeMasterList = distinctSectionCode.sort((a, b) => a.sectionCode.toString().localeCompare(b.sectionCode));
    });
    this.applicationInsightService.logEvent("Section DropDown Ended");
  }
  
  filterItemDN(filterKeyDiN) {
    if(this._searchform?.controls?.corporateBrandName?.value){
      this.onFilter()
    }
    this.applicationInsightService.logEvent("Filtering DivisionName Started");
    setTimeout(() => {
      if (filterKeyDiN != null && filterKeyDiN != "") {
        if(isNaN(filterKeyDiN)){
          this.divisionFilterList = this.divisionMasterList.filter(din => din.divisionName.toString().toLowerCase().includes(filterKeyDiN.toString().toLowerCase()));
          this.divisionFilterList = this.divisionFilterList.sort((a, b) => a.divisionCode.toString().localeCompare(b.divisionCode));
        }else{
          this.divisionFilterList = this.divisionMasterList.filter(din => din.divisionCode.toString().toLowerCase().includes(filterKeyDiN.toString().toLowerCase()));
          this.divisionFilterList = this.divisionFilterList.sort((a, b) => a.divisionCode.toString().localeCompare(b.divisionCode));
        }
      }
      else {
        this.divisionFilterList = this.divisionMasterList.sort((a, b) => (a.divisionCode > b.divisionCode ? 1 : -1));
      }
    }, 500);
    

    this.applicationInsightService.logEvent("Filtering DivisionName Ended");
  }
  filterItemSN(filterKey: any) {
    if (this._searchform?.controls?.corporateBrandName?.value) {
      this.onFilter();
    }

    setTimeout(() => {
      this.applicationInsightService.logEvent("Section filtering sectionname Started");
      if (filterKey != null && filterKey != "") {
        if (isNaN(filterKey)) {
          this.sectionnameFilterList = this.sectionnameMasterList.filter(sn => sn.sectionName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
          this.sectionnameFilterList = this.sectionnameFilterList.sort((a, b) => (a.sectionCode > b.sectionCode ? 1 : -1));
        } else {
          this.sectionnameFilterList = this.sectionnameMasterList.filter(sn => sn.sectionCode.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
          this.sectionnameFilterList = this.sectionnameFilterList.sort((a, b) => (a.sectionCode > b.sectionCode ? 1 : -1));
        }

      } else { this.sectionnameFilterList = this.sectionnameMasterList.sort((a, b) => (a.sectionCode > b.sectionCode ? 1 : -1)); }
      this.applicationInsightService.logEvent("Section filtering sectionname Ended");
    }, 500);
  }

  filterItemSNO(filterKey: any) {
    if (this._searchform?.controls?.corporateBrandName?.value) {
      this.onFilter();
    }

    setTimeout(() => {
      this.applicationInsightService.logEvent("Section filtering sectioncode Started");
      if (filterKey != null && filterKey != "") {
        this.sectionCodeFilterList = this.sectionCodeMasterList.filter(sn => sn.sectionCode.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
      } else { this.sectionCodeFilterList = this.sectionCodeMasterList; }
      this.applicationInsightService.logEvent("Section filtering sectioncode Ended");
    }, 500);

  }

 filterItemCorporateBrand(filterKey: any) {
    this.applicationInsightService.logEvent("Filter Corporatebrand Started");
    if (isNaN(filterKey)) {
      this.corporateBrandFilterIGList = this.corporateBrandMasterIGList.filter(cb => cb.corporateBrandName.toLowerCase().includes(filterKey.toLowerCase()));
      this.corporateBrandFilterIGList = this.corporateBrandFilterIGList.sort((a, b) => (a.corporateBrandCode > b.corporateBrandCode ? 1 : -1));
    } else {
      this._searchform.controls.division.setValue('');
      this.corporateBrandFilterIGList = this.corporateBrandMasterIGList.filter(cb => cb.corporateBrandCode.toLowerCase().includes(filterKey.toLowerCase()));
      this.corporateBrandFilterIGList = this.corporateBrandFilterIGList.sort((a, b) => (a.corporateBrandCode > b.corporateBrandCode ? 1 : -1));
   }
    this.onFilter();
    this.applicationInsightService.logEvent("Filter Corporatebrand Ended");
  }
  // filter function based on selection
  onFilter() {
    setTimeout(() => {
      if (this.cloneDivisionMasterList?.length === 0) {
        this.cloneDivisionMasterList = this.divisionMasterList;
      }
      if (this.clonesectionnameMasterList.length === 0) {
        this.clonesectionnameMasterList = this.sectionnameMasterList;
      }

      // this code for filter the divion,section code and section based on co-brand selection 
      if (this._searchform?.controls?.corporateBrandName?.value) {
        this.divisionMasterList = this.cloneDivisionMasterList.filter(x => x.corporateBrandCodeName === this._searchform?.controls?.corporateBrandName?.value)
        this.divisionFilterList = this.divisionMasterList

        this.sectionnameMasterList = this.clonesectionnameMasterList.filter(x => x.corporateBrandCodeName === this._searchform?.controls?.corporateBrandName?.value)
        this.sectionnameFilterList = this.sectionnameMasterList
      }
      else {
        this.divisionFilterList = this.cloneDivisionMasterList;
        this.divisionMasterList = this.cloneDivisionMasterList;
        this.sectionnameFilterList = this.clonesectionnameMasterList.filter((arr, index, self) => index === self.findIndex((t) => (t.sectionName === arr.sectionName)));
        this.sectionnameFilterList.sort((a, b) => a.sectionCode.toString().localeCompare(b.sectionCode));
        this.sectionnameMasterList = this.clonesectionnameMasterList.filter((arr, index, self) => index === self.findIndex((t) => (t.sectionName === arr.sectionName)));
      }

      // this code for filter the section code and section based on divison selection
      if (this._searchform?.controls?.corporateBrandName?.value !== '' &&
        this._searchform.controls.division.value) {
        this.sectionnameMasterList = this.clonesectionnameMasterList.filter(x => {
          return x.divisionCodeName === this._searchform.controls.division.value;
        }).filter(x => x.corporateBrandCodeName === this._searchform?.controls?.corporateBrandName?.value)
        this.sectionnameFilterList = this.sectionnameMasterList;
      }

      // this code for filter the divion and setion code based on section name dropdown
      if (this._searchform?.controls?.corporateBrandName?.value !== '' &&
        this._searchform.controls.sectionName.value) {
        this.divisionMasterList = this.clonesectionnameMasterList.filter(x => {
          return x.sectionCodeName === this._searchform.controls.sectionName.value;
        }).filter(x => x.corporateBrandCodeName === this._searchform?.controls?.corporateBrandName?.value)
        this.divisionFilterList = this.divisionMasterList;
      }
    }, 500);

  }
  //clear button click event
  onSearchClear() {
    this.applicationInsightService.logEvent("Section Search clear event started");
    this._searchform.controls.corporateBrandName.setValue('');
    this._searchform.controls.sectionCode.setValue('');
    this._searchform.controls.sectionName.setValue('');
    this._searchform.controls.division.setValue('');
    this._searchform.controls['status'].setValue("active");

    this.filterItemCorporateBrand("");
    this.filterItemDN("");
    this.filterItemSN("");
    this.filterItemSNO("");
    this.applicationInsightService.logEvent("Section Search clear event ended");
  }

  //Section group Search Form
  _searchform: FormGroup = new FormGroup({
    corporateBrandName: new FormControl(''),
    sectionName: new FormControl(''),
    sectionCode: new FormControl(''),
    division: new FormControl(''),
    status: new FormControl()
  })

}
