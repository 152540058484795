import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexgrouplistComponent } from './components/organization/indexgroup/indexgrouplist/indexgrouplist.component';
import { CustomergroupComponent } from './components/organization/customergroup/customergroup/customergroup.component';
import { DivisionComponent } from './components/organization/division/division/division.component';
import { SubindexComponent } from './components/organization/subindex/subindex/subindex.component';
import { IndexComponent } from './components/organization/index/index/index.component';

import { DepartmentComponent } from './components/organization/department/department/department.component';
import { GarmentgroupComponent } from './components/organization/garmentgroups/garmentgroup/garmentgroup.component';
import { CorporatebrandComponent } from './components/organization/corporatebrand/corporatebrand/corporatebrand/corporatebrand.component';

import { IndexgroupComponent } from './components/organization/indexgroup/indexgroup/indexgroup.component';
import { SectionComponent } from './components/organization/section/section/section.component';
import { MarkupgroupComponent } from './components/organization/markupgroup/markupgroup/markupgroup.component';
import { MsalGuard } from '@azure/msal-angular';
import { ChannelComponent } from './components/market/channel/channel/channel.component';
import { GeographicalcountryComponent } from './components/country/geographicalcountry/geographicalcountry/geographicalcountry.component';
import { FiscalcountryComponent } from './components/country/fiscalcountry/fiscalcountry/fiscalcountry.component';
import { EarlierplanningComponent } from './components/organization/earlierplanning/earlierplanning/earlierplanning.component';
import { SouthernhemisphereComponent } from './components/organization/southernhemisphere/southernhemisphere/southernhemisphere.component';
import { AppComponent } from './app.component';
import { CanActivateRouteGuard } from './can-activate-route.guard';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import {CurrencyMasterComponent} from './components/currency/currency/currency/currency.component'
import { ProuctbrandcomponentComponent } from './components/organization/productbrand/productbrandlist/prouctbrandcomponent.component';
import { HmcountryListComponent } from './components/country/hmcountry/hmcountry-list/hmcountry-list.component';
import { Ab2departmentlistComponent } from './components/organization/ab2department/ab2departmentlist/ab2departmentlist/ab2departmentlist.component';

const routes: Routes = [

  { path: 'corporatebrand', component: CorporatebrandComponent, canActivate: [CanActivateRouteGuard] },
  { path: 'division', component: DivisionComponent, canActivate: [CanActivateRouteGuard] },
  { path: 'subindex', component: SubindexComponent, canActivate: [CanActivateRouteGuard] },
  { path: 'garmentgroup', component: GarmentgroupComponent, canActivate: [CanActivateRouteGuard] },
  { path: 'department', component: DepartmentComponent, canActivate: [CanActivateRouteGuard] },
  { path: 'customergroup', component: CustomergroupComponent, canActivate: [CanActivateRouteGuard] },
  { path: 'indexgroup', component: IndexgroupComponent, canActivate: [CanActivateRouteGuard] },
  { path: 'index', component: IndexComponent, canActivate: [CanActivateRouteGuard] },
  { path: 'section', component: SectionComponent, canActivate: [CanActivateRouteGuard] },
  { path: 'markupgroup', component: MarkupgroupComponent, canActivate: [CanActivateRouteGuard] },
  { path: 'channel', component: ChannelComponent, canActivate: [CanActivateRouteGuard] },
  { path: 'geographicalcountry', component: GeographicalcountryComponent},
  { path: 'fiscalcountry', component: FiscalcountryComponent },
  { path: 'hmcountry', component: HmcountryListComponent},
  { path: 'currency', component: CurrencyMasterComponent },
  { path: 'productbrand', component: ProuctbrandcomponentComponent, canActivate: [CanActivateRouteGuard] },
  { path: 'ab2department', component: Ab2departmentlistComponent },
  { path: 'earlierplanning', component: EarlierplanningComponent, canActivate: [CanActivateRouteGuard] },
  { path: 'southernhemisphere', component: SouthernhemisphereComponent, canActivate: [CanActivateRouteGuard] },
  { path: 'pagenotfound', component: NotFoundComponent}


];

const isIframe = window !== window.parent && !window.opener;



@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !isIframe ? 'enabled' : 'disabled' // Don't perform initial navigation in iframes
    , onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [

  CorporatebrandComponent,
  IndexgrouplistComponent,
  CustomergroupComponent,
  DivisionComponent,
  SubindexComponent,
  DepartmentComponent,
  IndexComponent


]
