import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CorporatebrandService } from 'src/app/services/organization/corporatebrand/corporatebrand.service';
import { DivisionService } from 'src/app/services/organization/division/division.service';
import { SectionService } from 'src/app/services/organization/section/section.service';
import { Title } from '@angular/platform-browser';
import { AccountService } from '../../../../shared/services/account.service'
import { Subscription } from 'rxjs';
import { CommonValidationService } from 'src/app/services/organization/commonutility/commonValidation.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { UserAccess } from 'src/app/models/userAccess';
import { ModulePermission } from 'src/app/models/enum';
export class dropDownCollections {
  corporateBrandList: any;
  divisionList: any;
}
@Component({
  selector: 'app-sectionform',
  templateUrl: './sectionform.component.html',
  styleUrls: ['./sectionform.component.css']
})
export class SectionformComponent implements OnInit {
  @Output() childButtonEvent = new EventEmitter();
  title = "Section";
  validationMessages: any;
  corporateBrandMasterList: any[] = [];
  corporateBrandFilterList: any[] = [];
  divisionNameFilterList: any[] = [];
  divisionNameMasterList: any[] = [];
  isSectionEditCB = 'legacy';
  updateSectionForm: any;
  issectionEdit: any = false;
  isDisableActive: any = true;
  subscription: Subscription;
  userrole: boolean;
  users: any;
  constructor(public _corporatebrandService: CorporatebrandService,
    public _commonvalidationService: CommonValidationService,
    public _divisionService: DivisionService,
    public _sectionService: SectionService,
    private toastr: ToastrService,
    public route: ActivatedRoute,
    private titleService: Title,
    private applicationInsightService: ApplicationInsightService,
    public account: AccountService,
    public _userAccess: UserAccess
  ) {
    this.updateSectionForm = Object.assign({ active: true, draft: true });
    this.users = JSON.parse(localStorage.getItem("users") || "[]")
  }

  ngOnInit(): void {
    this.userrole = this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this._sectionform.controls.lastUpdatedBy.setValue(JSON.parse(localStorage.getItem("UserInfo")).Email || "[]");
    this.validationMessages = this._commonvalidationService.validationMsgs;
    this.titleService.setTitle(this.title);
    this.onClear();
    this._corporatebrandService.getCorporateBrandList().subscribe((data: any) => {
      this.applicationInsightService.logEvent("Section form CorporateBrand listing Started");
      this.corporateBrandMasterList = this.corporateBrandFilterList = data.getCorporateBrandAll.filter(object => {
        return object['active'] !== false;
      })
      this.corporateBrandFilterList = this.corporateBrandMasterList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
      this.loadDropdown();
      this.applicationInsightService.logEvent("Section form CorporateBrand listing Ended");
    });

    this._divisionService.getDivisionAll().subscribe((data: any) => {
      this.applicationInsightService.logEvent("Section form Division listing Started");
      this.divisionNameMasterList = data.division.filter(object => {
        return object['active'] !== false;
      })
      this.loadDropdown();
      this.applicationInsightService.logEvent("Section form Division listing Ended");
    });

    this.subscription = this._sectionService.sectionId$.subscribe(
      sectionId => {
        this.updateSection(sectionId)
      });

  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }

  loadDropdown() {
    this.applicationInsightService.logEvent("Section LoadDropdown Started");
    if (this.corporateBrandMasterList.length > 0 && this.divisionNameMasterList.length > 0) {
      let obj = new dropDownCollections();
      obj.corporateBrandList = this.corporateBrandMasterList;
      obj.divisionList = this.divisionNameMasterList;
      this.childButtonEvent.emit(obj);
    }
    this.applicationInsightService.logEvent("Section LoadDropdown Ended");
  }

  updateSection(sectionId: any) {
    this.applicationInsightService.logEvent("Section Edit GetSectionId Started");
    this.issectionEdit = true;
    this.isDisableActive = true;
    this._sectionService.getsectionById(sectionId)
      .subscribe((data: any) => {
        this.isDisableActive = false;
        this.updateSectionForm = data.section;
        this.isSectionEditCB = data.section.active == true ? 'fill' : 'legacy';
      });
    document.getElementById("scrolltop").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    this.applicationInsightService.logEvent("Section Edit GetSectionId Ended");
  }

  displayTextDIV(divisionId: string) {
    if (divisionId == null || divisionId == "")
      return null;
    else if (this.divisionNameMasterList != undefined && divisionId != null) { return this.divisionNameMasterList.find(x => x.divisionId === divisionId).divisionCodeName; }
  }

  filterItemDIV(filterKey: string) {
    this.applicationInsightService.logEvent("Section form filtering divisionname Started");
    if (filterKey != null && filterKey != "" && typeof this._sectionform.controls.corporateBrandId.value == "number") {
      this.divisionNameFilterList = this.divisionNameMasterList.filter(x => x.corporateBrandId == this._sectionform.controls.corporateBrandId.value).filter(gn => gn.divisionName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
      this.divisionNameFilterList = this.divisionNameFilterList.sort((a, b) => (a.divisionCodeName > b.divisionCodeName ? 1 : -1));
    } else if (typeof this._sectionform.controls.corporateBrandId.value == "number")
      this.divisionNameFilterList = this.divisionNameMasterList.filter(x => x.corporateBrandId == this._sectionform.controls.corporateBrandId.value).sort((a, b) => (a.divisionCodeName > b.divisionCodeName ? 1 : -1));
    this.applicationInsightService.logEvent("Section form filtering divisionname Ended");
  }

  displayTextCB(corporateBrandId: any) {
    if (corporateBrandId === null || corporateBrandId === "")
      return null;
    else if (this.corporateBrandMasterList != undefined && corporateBrandId != null)
      return this.corporateBrandMasterList.find(x => x.corporateBrandId == corporateBrandId).corporateBrandCodeName;
  }

  filterItemCorporateBrand(filterKey: string) {
    this.applicationInsightService.logEvent("Section form filtering corporatebrand Started");
    let newCBList: any[] = [];
    newCBList = this.corporateBrandMasterList;
    if (filterKey !== null && filterKey !== "") {
      this.corporateBrandFilterList = newCBList.filter(cb => cb.corporateBrandName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
      this.corporateBrandFilterList = this.corporateBrandFilterList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
      if (typeof filterKey === "number")
        this.fnFilterDivisionByCB(this._sectionform.controls.corporateBrandId.value);
    }
    else {
      this.corporateBrandFilterList = this.corporateBrandMasterList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
      this.divisionNameFilterList = [];
      this._sectionform.controls.divisionId.reset();
    }
    this.applicationInsightService.logEvent("Section form filtering corporatebrand Ended");
  }

  fnFilterDivisionByCB(corporatebrandId: number) {
    this.applicationInsightService.logEvent("Section form filtering corporatebrand id Started");
    this.divisionNameFilterList = this.divisionNameMasterList.filter(x => x.corporateBrandId == corporatebrandId && x.active == true);
    this.divisionNameFilterList = this.divisionNameFilterList.sort((a, b) => (a.divisionCodeName > b.divisionCodeName ? 1 : -1));
    this.applicationInsightService.logEvent("Section form filtering corporatebrand id Ended");
  }

  onSubmit() {
    if (this._sectionform.controls.sectionName.value != undefined && this._sectionform.controls.sectionName.value != null && this._sectionform.controls.sectionName.value != '') { this._sectionform.controls.sectionName.setValue(this._sectionform.controls.sectionName.value.trim()) }
    this._sectionform.controls['corporateBrandId'].updateValueAndValidity();
    this._sectionform.controls['divisionId'].updateValueAndValidity();
    this._sectionform.controls['sectionCode'].updateValueAndValidity();
    this._sectionform.controls['sectionName'].updateValueAndValidity();
    this._sectionform.controls['sectionSortOrder'].updateValueAndValidity();

    if (this._sectionform.controls.draft.value === undefined || this._sectionform.controls.draft.value === null) { this._sectionform.controls.draft.setValue(true); }
    console.log("Section Save: " + JSON.stringify(this._sectionform.value))
    if (this._sectionform.valid) {
      if (this._sectionform.value.sectionId == undefined || this._sectionform.value.sectionId == 0 || this._sectionform.value.sectionId == null) {
        this._sectionService.createsection(this._sectionform.value).subscribe
          (data => {
            this.toastr.success(data.message, "", { timeOut: 10000 });
            this.loadDropdown();
            this.onClear();
            this._sectionService.refreshsectionList();
          },
            error => { this.toastr.error(error, "", { timeOut: 10000 }) })
      }
      else {
        this._sectionService.updatesection(this._sectionform.value).subscribe
          (data => {
            this.toastr.success(data.message, "", { timeOut: 10000 });
            this.loadDropdown();
            this.onClear();
            this._sectionService.refreshsectionList();
          },
            error => { this.toastr.error(error, "", { timeOut: 10000 }) })
      }
    }
  }

  onClear() {
    this.applicationInsightService.logEvent("Section form Clear Started");
    //Clearing validation 
    this._sectionform.controls.corporateBrandId.clearValidators();
    this._sectionform.controls.divisionId.clearValidators();
    this._sectionform.controls.sectionCode.clearValidators();
    this._sectionform.controls.sectionName.clearValidators();
    this._sectionform.controls.sectionSortOrder.clearValidators();

    //Clearing control values 
    this._sectionform.controls.sectionId.reset();
    this._sectionform.controls.divisionId.reset();
    this._sectionform.controls.corporateBrandId.reset();
    this._sectionform.controls.divisionId.reset();
    this._sectionform.controls.sectionCode.reset();
    this._sectionform.controls.sectionName.reset();
    this._sectionform.controls.sectionSortOrder.reset();
    this._sectionform.controls.draft.reset();

    //Setting up the validation
    this._sectionform.controls.corporateBrandId.setValidators([this._commonvalidationService.acObjectValidator(), Validators.required]);
    this._sectionform.controls.divisionId.setValidators([this._commonvalidationService.acObjectValidator(), Validators.required]);
    this._sectionform.controls.sectionCode.setValidators([Validators.required, Validators.pattern('[0-9][0-9]')]);
    this._sectionform.controls.sectionName.setValidators([Validators.required, Validators.maxLength(50)]);
    this._sectionform.controls.sectionSortOrder.setValidators([Validators.required, Validators.min(1), Validators.max(9999)]);

    this._sectionform.controls.draft.setValue(true);
    this._sectionform.controls.active.setValue(true);

    this.filterItemDIV("");
    this.issectionEdit = false;
    this.isDisableActive = true;
    this.isSectionEditCB = 'legacy';
    this.applicationInsightService.logEvent("Section form Clear Ended");
  }

  //Used for New Division Creation.It will build the JSON
  _sectionform: FormGroup = new FormGroup({
    divisionId: new FormControl('', [this._commonvalidationService.acObjectValidator(), Validators.required]),
    sectionId: new FormControl(''),
    corporateBrandId: new FormControl('', [this._commonvalidationService.acObjectValidator(), Validators.required]),
    sectionCode: new FormControl('', [Validators.required, Validators.pattern('[0-9][0-9]')]),
    sectionName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    sectionSortOrder: new FormControl('', [Validators.required, Validators.min(1), Validators.max(9999)]),
    active: new FormControl(true),
    lastUpdatedBy: new FormControl(''),
    draft: new FormControl(true),
  });

}

