<div class="mat-elevation-z2 form-border" id="scrolltop">
  <form [formGroup]="_customergroupform" id="inpcustomergroupform">
    <section class="form-section">
      <h2 mat-dialog-title class="align">{{isCustomerGroupEdit === true ? 'Update' : 'New'}} Customer Group</h2>
    </section>
    <div class="example-container add-form-field ">
      <mat-form-field [appearance]="isCustomerGroupEditCB">
        <mat-label>Corporate Brand</mat-label>
        <input type="text" id="inpcorporatebrand" matInput [matAutocomplete]="autoCB" formControlName="corporateBrandId"
          (ngModelChange)="filterItemCorporateBrand($event)" [(ngModel)]="updatecustomerGroupForm.corporateBrandId"
          [readonly]="isCustomerGroupEditCB=='fill' ?true:false">

        <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
          <div *ngIf="_customergroupform.controls['corporateBrandId'].hasError(validation.type)">{{validation.message}}
          </div>
        </mat-error>

        <mat-autocomplete autoActiveFirstOption #autoCB="matAutocomplete" [displayWith]="displayTextCB.bind(this)">
          <mat-option *ngFor="let CBname of corporateBrandFilterCGList" [value]="CBname.corporateBrandId">
            {{CBname.corporateBrandCode}} {{CBname.corporateBrandName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field [appearance]="isCustomerGroupEdit==true ? 'fill': 'legacy'">
        <mat-label> Customer Group Code</mat-label>
        <input id="inpcustomergroupcode" formControlName="customerGroupCode"
          [(ngModel)]="updatecustomerGroupForm.customerGroupCode" matInput [readonly]=isCustomerGroupEdit>
        <mat-error *ngFor="let validation of  validationMessages.autoCompleteControl">
          <div *ngIf="_customergroupform.controls['customerGroupCode'].hasError(validation.type)">
            {{validation.message}}</div>
        </mat-error>
        <mat-error *ngIf="_customergroupform.controls['customerGroupCode'].errors?.pattern">Please enter a vaild input,
          Ex:- 01</mat-error>
      </mat-form-field>
      <mat-form-field appearance="legacy">
        <mat-label>Customer Group</mat-label>
        <input id="inpcustomergroupname" formControlName="customerGroupName"
          [(ngModel)]="updatecustomerGroupForm.customerGroupName" matInput aria-label="Enter corporate brand name"
          ng-trim="false">
        <mat-error *ngIf="_customergroupform.controls['customerGroupName'].errors?.maxlength">Maximum 50 Characters
          allowed</mat-error>
      </mat-form-field>
      <br>
      <mat-form-field appearance="legacy">
        <mat-label>Sort Order</mat-label>
        <input formControlName="customerGroupSortOrder" id="inpcustomergroupsortorder"
          [(ngModel)]="updatecustomerGroupForm.customerGroupSortOrder" matInput min="0" type="number"
          pattern="^(0|[1-9][0-9]*)$">
        <mat-error *ngIf="_customergroupform.controls['customerGroupSortOrder'].errors?.max">The range for sort order is
          1-9999. </mat-error>
        <mat-error *ngIf="_customergroupform.controls['customerGroupSortOrder'].errors?.pattern">Only Numeric Values
          allowed</mat-error>
      </mat-form-field>
      <br>

      <section class="example-section">
        <mat-checkbox [disabled]="isDisableActive" id="inpactive" formControlName="active"
          [(ngModel)]="updatecustomerGroupForm.active" class="example-margin width-25" color='primary'>Active
        </mat-checkbox>
        <mat-checkbox formControlName="draft" id="inpdraft" [(ngModel)]="updatecustomerGroupForm.draft"
          class="example-margin width-15" color='primary'>Draft</mat-checkbox>
      </section>
      <br>
      <mat-dialog-actions *ngIf="userrole">

        <button [disabled]="(isCustomerGroupEdit === true) ?  !_customergroupform.dirty : false"
          [ngClass]="(isCustomerGroupEdit === true) ? 'mat-raised-button ml-1':'mat-raised-button ml-1 mat-primary'"
          id="btnSaveUpdate" type="submit" (click)="onSubmit()">
          {{isCustomerGroupEdit === true ? 'Update' : 'Save'}}
        </button>

        <button class="mat-raised-button mat-primary ml-1" id="btnclear" (click)="onClear()">Cancel</button>
      </mat-dialog-actions>
      <span *ngIf="!userrole" style="color: #ff0202;font-size: 12px;font-weight: 400;">You can't save changes since you
        have Read access.</span>
      <input type="hidden" formControlName="customerGroupId" id="inpcustomergroupid"
        [(ngModel)]="updatecustomerGroupForm.customerGroupId">

    </div>
  </form>
</div>