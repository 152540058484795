import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, Subject } from 'rxjs';
import { ServiceApi } from '../../../services/ServiceApi';
import { FormControl, ValidatorFn } from '@angular/forms';
let headers = new HttpHeaders()

if (!headers.has('content-type')) {

  headers = headers.append('content-type', 'application/json')

}
@Injectable({
  providedIn: 'root'
})
export class FiscalcountryService {
  constructor(private http: HttpClient, public _serviceApi: ServiceApi) { }

  headers = new HttpHeaders().set('content-type', 'application/json').set('accept', 'application/json');
  httpOptions = {
    headers: this.headers
  }
  // Observable string sources
  public fiscalCountryIdSource = new Subject<string>();
  // Observable string streams
  public fiscalCountryId$ = this.fiscalCountryIdSource.asObservable();
  // Service message commands
  getFiscalCountryIdFromList(mission: string) {
    this.fiscalCountryIdSource.next(mission);
  }
  // Observable string sources
  public refreshTable = new Subject<string>();
  // Observable string streams
  public refreshTableFiscalCountry$ = this.refreshTable.asObservable();
  isValidDate: boolean;
  error: { isError: boolean; errorMessage: string; };
  FromDate: any = '';
  ToDate: any;
  FromControl: FormControl;
  ToControl: FormControl;
  VatCollection: any[];
  VatExpCollection: any[];
  GbcCostCollection: any[];
  /* istanbul ignore next */
  public assignCollection(vat: any, vatexp: any, gbc: any) {
    this.VatCollection = vat;
    this.VatExpCollection = vatexp;
    this.GbcCostCollection = gbc;
  }
  /* istanbul ignore next */
  public updateValidTo(row: any, formControl: any) {
    let presentDate = formControl.controls.fromDate.value;
    var date = new Date(formControl.controls.fromDate.value);
    date.setDate(date.getDate() - 1);
    let index = row.length;
    if (row[index - 1].toDate == undefined || row[index - 1].toDate == null || row[index - 1].toDate == '') {
      if (presentDate > new Date(row[index - 1].fromDate)) {
        row[index - 1].toDate = this.fnToConvertDate(date);
      }
    }
    return row;
  }
  /* istanbul ignore next */
  fromDateValidatorfn(row: any, method: any, control: any) {
    this.FromDate = control.value;
    this.FromControl = control;
    if (control.hasError('invalidDate')) {
      const { invalidDate, ...errors } = control.errors;
      control.setErrors(errors);
      control.updateValueAndValidity();
    }
    if (this.ToControl != null) {
      if (this.ToControl.hasError('invalidDate')) {
        const { invalidDate, ...errors } = this.ToControl.errors;
        this.ToControl.setErrors(errors);
        this.ToControl.updateValueAndValidity();
      }
    }
    if (row.length > 0 || (this.VatCollection != undefined && this.VatCollection.length > 0) || (this.VatExpCollection != undefined && this.VatExpCollection.length > 0) || (this.GbcCostCollection != undefined && this.GbcCostCollection.length > 0)) {
      let newlist = row.length > 0 ? row : method == "VAT" ? this.VatCollection : method == "VATEXP" ? this.VatExpCollection : method == "GBC" ? this.GbcCostCollection : null;
      let index = newlist.length;
      if (index > 0 && index < 2) {
        if (newlist[index - 1].fromDate != this.FromDate) {
          if (newlist[index - 1].toDate != null && newlist[index - 1].toDate != '') {
            if (new Date(newlist[index - 1].toDate) > this.FromDate && this.FromDate > new Date(newlist[index - 1].fromDate))
              return "NotAllowed";

            return (new Date(newlist[index - 1].toDate)) > this.FromDate ? "Invalid" : null;
          }
          else {
            return (new Date(newlist[index - 1].fromDate)) > this.FromDate ? "Invalid" : this.ToDate != '' ? this.ToDate < this.FromDate ? "Invalid" : null : null;
          }
        }
        else return null;
      }
      else if (index >= 2) {
        for (var i = 0; i < index;) {
          if (newlist[i].fromDate != this.FromDate) {
            if (newlist[i].toDate != null && newlist[i].toDate != '') {
              if (new Date(newlist[i].toDate) > this.FromDate && this.FromDate > new Date(newlist[i].fromDate))
                return "NotAllowed";

              return (new Date(newlist[i].toDate)) > this.FromDate ? "Invalid" : null;
            }
            else {
              return (new Date(newlist[i].fromDate)) > this.FromDate ? "Invalid" : this.ToDate != '' ? this.ToDate < this.FromDate ? "Invalid" : null : null;
            }
          }
          else return null;
        }
      }
      else return null;
    }
    else
      return null;
    return null;
  }
  /* istanbul ignore next */
  toDateValidatorfn(row: any, method: any, control: any) {
    this.ToDate = control.value;
    this.ToControl = control;
    if (control.hasError('invalidDate')) {
      const { invalidDate, ...errors } = control.errors;
      control.setErrors(errors);
      control.updateValueAndValidity();
    }
    if (this.FromControl != null) {
      if (this.FromControl.hasError('invalidDate')) {
        const { invalidDate, ...errors } = this.FromControl.errors;
        this.FromControl.setErrors(errors);
        this.FromControl.updateValueAndValidity();
      }
    }
    if (this.ToDate != null && this.ToDate != '') {
      return this.ToDate < this.FromDate ? "Invaliddate" : null;
    }

    if (row.length > 0 || (this.VatCollection != undefined && this.VatCollection.length > 0) || (this.VatExpCollection != undefined && this.VatExpCollection.length > 0) || (this.GbcCostCollection != undefined && this.GbcCostCollection.length > 0)) {
      let newlist = row.length > 0 ? row : method == "VAT" ? this.VatCollection : method == "VATEXP" ? this.VatExpCollection : method == "GBC" ? this.GbcCostCollection : null;
      let index = newlist.length;
      if (index > 0) {
        if (newlist[index - 1].fromDate != this.ToDate) {
          if (newlist[index - 1].toDate != null && newlist[index - 1].toDate != '') {
            let currentdate = new Date().getFullYear();
            if ((currentdate < new Date(newlist[index - 1].toDate).getFullYear()) && (new Date(newlist[index - 1].fromDate).getFullYear() < new Date(this.ToDate).getFullYear()))
              return "Invalid";

            if (index > 1) {
              for (var i = 0; i < index; i++) {
                let fromyear = new Date(newlist[i].fromDate).getFullYear();
                let toyear = newlist[i].toDate != null ? new Date(newlist[i].toDate).getFullYear() : null;
                let newyear = this.ToDate.getFullYear();
                if (toyear != null && fromyear < newyear && newyear < toyear) {
                  if (new Date(newlist[i].fromDate) < this.ToDate && this.ToDate > new Date(newlist[i].toDate)) {
                    return "Invalid"
                  }
                }
                else if (toyear == null && fromyear <= newyear && new Date(newlist[i].fromDate) < this.ToDate) {
                  return "Invalid"
                }
              }
            }
            else
              return (new Date(newlist[index - 1].fromDate)) > this.ToDate ? "Invalid" : this.ToDate != '' ? this.ToDate < this.FromDate ? "Invalid" : new Date(newlist[index - 1].fromDate) < this.ToDate ? this.ToDate < new Date(newlist[index - 1].toDate) ? "Invalid" : null : null : null;

            return null
          }
          else {
            return (new Date(newlist[index - 1].fromDate)) < this.ToDate ? "Invalid" : (this.ToDate != '' && this.ToDate != null) ? this.ToDate < this.FromDate ? "Invalid" : null : null;
          }
        }
        else return null;
      }
      else return null;
    }


    else
      return null;
  }
  /* istanbul ignore next */
  fnToConvertDate(value: any) {
    if(value.length!=19){
    let month = (value.getMonth() + 1);
    month = month < 10 ? "0" + month : month;
    let date = value.getDate();
     date = date < 10 ? "0" + date : date;
     value = value.getFullYear() + '-' + month + '-' + date + "T00:00:00";
    
    }
    return value;
  }
  /* istanbul ignore next */
  // Service message commands
  refreshFiscalCountryList() {
    this.refreshTable.next();
  }
  /* istanbul ignore next */
  //List FiscalCountry from Service
  getFiscalCountryList(): Observable<any> {
    return this.http.get(this._serviceApi.FiscalCountryGetAll);
  }
  /* istanbul ignore next */
  //Create Fiscalcountry
  CreateFiscalCountry(createNewFiscalCountry: any): Observable<any> {
    return this.http.post(this._serviceApi.FiscalCountryCreate, JSON.stringify(createNewFiscalCountry), { 'headers': headers });
  }
  /* istanbul ignore next */
  //get currency list
  getCurrencyList(): Observable<any> {
    return this.http.get(this._serviceApi.FiscalCountryGetCurrency);
  }
  /* istanbul ignore next */
  //get season list
  getSeasonByRange(prec: any): Observable<any> {
    var serviceURL = this._serviceApi.DepartmentGetSeasonByRange + "?current=current&prec=" + prec + "&succ=3";
    return this.http.get(serviceURL);
  }
  /* istanbul ignore next */
  //Get Fiscalcountry By Id
  getFiscalCountryId(fiscalCountryId: number): Observable<any> {
    return this.http.get(this._serviceApi.FiscalCountryGetById + fiscalCountryId)
  }
  /* istanbul ignore next */
  //Update Fiscalcountry By Id
  UpdateFiscalcountry(updatedData: any): Observable<any> {
    return this.http.put(this._serviceApi.FiscalCountryUpdate, JSON.stringify(updatedData), { 'headers': headers });
  }
  /* istanbul ignore next */
  //List TypeOfConstructionALL from Service
  getTypeOfConstructionALL(): Observable<any> {
    return this.http.get(this._serviceApi.FiscalGetTypeOfConstructionALL);
  }
  /* istanbul ignore next */
  //List CustomMaterialGroupALL from Service
  getCustomMaterialGroupALL(): Observable<any> {
    return this.http.get(this._serviceApi.FiscalGetCustomMaterialGroupALL);
  }
  /* istanbul ignore next */
  //List CustomCustomGroupALL from Service
  getCustomCustomGroupALL(): Observable<any> {
    return this.http.get(this._serviceApi.FiscalGetCustomCustomerGroupALL);
  }
  /* istanbul ignore next */
  //List CustomCustomGroupALL from Service
  getProductTypeAndGroupAll(): Observable<any> {
    return this.http.get(this._serviceApi.GetProductTypeAndGroupAll);
  }
  /* istanbul ignore next */
  getSeasonAll(): Observable<any> {
    var serviceURL = this._serviceApi.DepartmentGetSeasonByRange + "?current=current&prec=" + parseInt(new Date().getFullYear().toString().slice(-2)) * 2 + "&succ=2";
    return this.http.get(serviceURL);
  }
  /* istanbul ignore next */
  //Get token from service Head.
  getToken() {
    return localStorage.getItem('token')
  }
}
