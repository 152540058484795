import { Component, Input, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "ab2displaydepartmentlist",
  template: `
    <div style="width:300px">
      <h2 mat-dialog-title>Departments</h2>
      <div mat-dialog-content>
      <span *ngIf="isdepartment === true">The selected Department is Ended, Kindly add new department </span>
      <tr *ngFor="let p of department">
      <td>
        {{p.departmentCodeAndName}}
      </td>
     </tr>
      </div>
      <div mat-dialog-actions align="end">
        <span>
         <button
            type="button"
            mat-stroked-button
            color="primary"
            (click)="CloseDialog()">
            Cancel
          </button>
        </span>
      </div>
    </div>
  `
})
export class ab2displaydepartmentlist {
  department: any;
  isdepartment: boolean = false;
  constructor(
    private _mdr: MatDialogRef<ab2displaydepartmentlist>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    if (data?.department===null || data?.department===''){
      this.isdepartment = true;
    }else{
      this.department = data?.department.sort((a: { departmentCode: number; }, b: { departmentCode: number; }) => a.departmentCode - b.departmentCode);
      if (this.department[0].departmentCode == null) {
        this.isdepartment = true;
      }
    }
    
  }
  CloseDialog() {
    this._mdr.close(false)
  }
}
