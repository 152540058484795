
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, Subject, throwError } from 'rxjs';
import { ServiceApi } from '../../../services/ServiceApi';

let headers = new HttpHeaders()
if (!headers.has('content-type')) {
  headers = headers.append('content-type', 'application/json');
}

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  headers = new HttpHeaders().set('content-type', 'application/json').set('accept', 'application/json');
  httpOptions = {
    headers: this.headers
  }

  constructor(private http: HttpClient, public _serviceApi: ServiceApi) {
  }

  // Observable string sources
  public departmentIdSource = new Subject<string>();

  // Observable string streams
  public departmentRowData$ = this.departmentIdSource.asObservable();

  // Service message commands
  getdepartmentRowFromList(mission: string) {
    this.departmentIdSource.next(mission);
  }

  // Observable string sources
  public refreshTable = new Subject<string>();
  // Observable string streams
  public refreshTableDepartment$ = this.refreshTable.asObservable();

  // Service message commands
  refreshDepartmentList() {
    this.refreshTable.next();
  }

  //Get all Department from the Service

  getDepartmentByBrand(searchParams: any): Observable<any> {
    return this.http.post(this._serviceApi.DepartmentGetAll, JSON.stringify(searchParams), { 'headers': headers });
  }

 // Get Department for Sub Index
 getDepartmentBySubIndexIdandSeason(subIndexId: string, current: string): Observable<any> {    
  return this.http.post(this._serviceApi.DepartmentGetAll,JSON.stringify({"subIndexId" : subIndexId ,"seasonNumber":current}), { 'headers': headers });
}

  getDepartmentAll(): Observable<any> {
    return this.http.get(this._serviceApi.DepartmentAll);
  }

  //Create Department
  createDepartment(createNewDepartment: any): Observable<any> {
    return this.http.post(this._serviceApi.DepartmentCreate, JSON.stringify(createNewDepartment), { 'headers': headers });
  }

  //Update Department
  updateDepartment(updateDepartment: any): Observable<any> {
    return this.http.put(this._serviceApi.DepartmentUpdate, JSON.stringify(updateDepartment), { 'headers': headers });
  }

  //Get Department By Id
  getDepartmentById(departmentId: any): Observable<any> {
    console.log(this._serviceApi.DepartmentGetByID + departmentId)
    return this.http.get(this._serviceApi.DepartmentGetByID + departmentId);
  }

  getDepartmentSeasonByRange(prec: any): Observable<any> {
    var serviceURL = this._serviceApi.DepartmentGetSeasonByRange + "?current=current&prec=" + prec + "&succ=4";
    return this.http.get(serviceURL);
  }

  getSectionAll() { return this.http.get(this._serviceApi.SectionGetAll); }

  getSeasonAll(): Observable<any> {
    var serviceURL = this._serviceApi.DepartmentGetSeasonByRange + "?current=current&prec=" + parseInt(new Date().getFullYear().toString().slice(-2)) * 2 + "&succ=4";
    return this.http.get(serviceURL);
  }
  getGarmentGroupAll() { return this.http.get(this._serviceApi.GarmentGroupGetAll); }

}
