import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CustomerGroupService } from '../../../../services/organization/customerGroup/customer-group.service'
import { CorporatebrandService } from '../../../../services/organization/corporatebrand/corporatebrand.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';

@Component({
  selector: 'customergroup',
  templateUrl: './customergroup.component.html',
  styleUrls: ['./customergroup.component.css']
})
export class CustomergroupComponent implements OnInit {
  title = "Customer Group"
  corporateBrandMasterCGList: any[] = [];
  corporateBrandFilterCGList: any[] = [];
  customerGroupCodeFilterCGList: any[] = [];
  customerGroupCodeMasterCGList: any[] = [];
  customerGroupNameFilterCGList: any[] = [];
  customerGroupNameMasterCGList: any[] = [];
  cloneCustomerGroupNameMasterCGList: any[] = [];
  cloneCustomerGroupCodeMasterCGList: any[] = [];
  corporateBrandName: any = "";
  customerGroupCode: any = "";
  cloneCustomerGroupCode: any[] = [];
  customerGroupName: any = "";
  status: any = "active";
  customerGroupList: any[] = [];
  distinctCustomerGroupCode: any[] = [];
  distinctCustomerGroupName: any[] = [];
  obj: any[] = [];
  constructor(
    public _corporatebrandService: CorporatebrandService,
    public route: ActivatedRoute, public _customerGroup: CustomerGroupService,
    private applicationInsightService: ApplicationInsightService,
    private titleService: Title) { }
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
  }
  receivedChildHandler(obj: any) {
    this.applicationInsightService.logEvent("CustomerGroup receivedChildHandler Started");
    if (obj.sort != null || undefined) {
      this.corporateBrandMasterCGList = this.corporateBrandFilterCGList = obj.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
    }
    this.applicationInsightService.logEvent("CustomerGroup receivedChildHandler Ended");
  }
  customergroupDropDownList(obj: any) {
    this.applicationInsightService.logEvent("CustomerGroup dropdownlist Started");
    if (obj.filter != null || undefined) {
      this.distinctCustomerGroupCode = obj.filter((arr, index, self) => index === self.findIndex((t) => (t.customerGroupCode === arr.customerGroupCode)));
      this.distinctCustomerGroupName = obj
    }
    this.customerGroupCodeFilterCGList = this.customerGroupCodeMasterCGList = this.distinctCustomerGroupCode.sort((a, b) => a.customerGroupCode.localeCompare(b.customerGroupCode));
    this.customerGroupNameFilterCGList = this.customerGroupNameMasterCGList = this.distinctCustomerGroupName.sort((a, b) => a.customerGroupCode.localeCompare(b.customerGroupCode));
    this.applicationInsightService.logEvent("CustomerGroup dropdownlist Ended");
  }

  filterItemGNAM(filterKey) {
    this.applicationInsightService.logEvent("CustomerGroup filter customerGroupName Started");
    if (filterKey != null && filterKey != "") {
      if (isNaN(filterKey)) {
        this.customerGroupNameFilterCGList = this.customerGroupNameMasterCGList.filter(gn => gn.customerGroupName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
        this.customerGroupNameFilterCGList = this.customerGroupNameFilterCGList.sort((a, b) => (a.customerGroupCode > b.customerGroupCode ? 1 : -1));
      } else {
        this.customerGroupNameFilterCGList = this.customerGroupNameMasterCGList.filter(gn => gn.customerGroupCode.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
      }
    } else { this.customerGroupNameFilterCGList = this.customerGroupNameMasterCGList.sort((a, b) => (a.customerGroupCode > b.customerGroupCode ? 1 : -1)); }
    this.applicationInsightService.logEvent("CustomerGroup filter customerGroupName Ended");
  }

  filterItemCorporateBrand(filterKey: any) {
    this.onFilter()
    this.applicationInsightService.logEvent("CustomerGroup filter Corporatebrand Started");
    if (isNaN(filterKey)) {
      this.corporateBrandFilterCGList = this.corporateBrandMasterCGList.filter(cb => cb.corporateBrandName.toLowerCase().includes(filterKey.toLowerCase()));
      this.corporateBrandFilterCGList = this.corporateBrandFilterCGList.sort((a, b) => (a.corporateBrandCode > b.corporateBrandCode ? 1 : -1));
    } else {
      this.corporateBrandFilterCGList = this.corporateBrandMasterCGList.filter(cb => cb.corporateBrandCode.toLowerCase().includes(filterKey.toLowerCase()));
      this.corporateBrandFilterCGList = this.corporateBrandFilterCGList.sort((a, b) => (a.corporateBrandCode > b.corporateBrandCode ? 1 : -1));
    }
    this.applicationInsightService.logEvent("CustomerGroup filter Corporatebrand Ended");
  }

  // filter function based on selection
  onFilter() {
    setTimeout(() => {
      if (this.cloneCustomerGroupNameMasterCGList.length === 0) {
        this.cloneCustomerGroupNameMasterCGList = this.customerGroupNameMasterCGList;
      }
      if (this._searchform?.controls?.corporateBrandName?.value) {
        this.customerGroupNameMasterCGList = this.cloneCustomerGroupNameMasterCGList.filter(x => x.corporateBrandCodeName === this._searchform?.controls?.corporateBrandName?.value)
        this.customerGroupNameFilterCGList = this.customerGroupNameMasterCGList;
      }
      else {
        this.customerGroupNameFilterCGList = this.cloneCustomerGroupNameMasterCGList;
        this.customerGroupNameMasterCGList = this.cloneCustomerGroupNameMasterCGList;
      }

      // if (this._searchform?.controls?.corporateBrandName?.value !== '' &&
      //   this._searchform.controls.customerGroupCode.value) {
      //   this.customerGroupNameMasterCGList = this.cloneCustomerGroupNameMasterCGList.filter(x => {
      //     return x.customerGroupCode === this._searchform.controls.customerGroupCode.value;
      //   })
      //   this.customerGroupNameFilterCGList = this.customerGroupNameMasterCGList;
      // }
    }, 1000);
  }

  //clear button click event
  onSearchClear() {
    this.applicationInsightService.logEvent("CustomerGroup Search clear event started");
    this._searchform.controls.corporateBrandName.setValue('');
    this._searchform.controls.corporateBrandCodeName.setValue('');
    this._searchform.controls.customerGroupCode.setValue('');
    this._searchform.controls.customerGroupName.setValue('');
    this._searchform.controls.customerGroupCodeName.setValue('');
    this._searchform.controls.status.setValue('');
    this._searchform.controls['status'].setValue("active");
    this.filterItemCorporateBrand("");
    this.filterItemGNAM("");
    this.applicationInsightService.logEvent("CustomerGroup Search clear event ended");
  }
  //Customer group Search Form
  _searchform: FormGroup = new FormGroup({
    corporateBrandName: new FormControl(''),
    corporateBrandCodeName: new FormControl(''),
    customerGroupCode: new FormControl(''),
    customerGroupName: new FormControl(''),
    customerGroupCodeName: new FormControl(''),
    status: new FormControl(''),
  })
}
