<div class="mat-elevation-z2 form-border" id="scrolltop">
  <form [formGroup]="_currencyForm">
    <section class="form-section">
      <h2 mat-dialog-title class="align">{{isCurrencyEdit === true ? 'Update' : 'New'}} Currency</h2>
    </section>
    <div class="example-container add-form-field ">
      <mat-form-field appearance="legacy">
        <mat-label>ISO Code</mat-label>
        <input id="inpisoCode" formControlName="isoCode" matInput [(ngModel)]="updateCurrencyForm.isoCode"
          aria-label="Enter ISO Code">
      </mat-form-field>
      <br>
      <mat-form-field appearance="legacy">
        <mat-label>Name</mat-label>
        <input id="inpcurrencyName" formControlName="currencyName" matInput
          [(ngModel)]="updateCurrencyForm.currencyName" aria-label="Enter Currency Name">
        </mat-form-field>
        <br>
        <mat-form-field appearance="legacy">
          <mat-label>Symbol</mat-label>
          <input id="inpsymbol" formControlName="symbol" matInput [(ngModel)]="updateCurrencyForm.symbol"
            aria-label="Enter Symbol">

        </mat-form-field>
        <br>
        <mat-form-field appearance="legacy">
            <mat-label>HM Currency</mat-label>
            <mat-select id="inpHmCurrency" formControlName="hmCurrency" [(ngModel)]="updateCurrencyForm.hmCurrency" name="option">
                <mat-option *ngFor="let option of options" [value]="option.value">
                  {{option.viewValue}}
                </mat-option>
              </mat-select>
          </mat-form-field>
          <br>

          <mat-form-field appearance="legacy">
            <mat-label>Sell Currency</mat-label>
            <mat-select id="inpsellCurrency" formControlName="sellCurrency"  [(ngModel)]="updateCurrencyForm.sellCurrency"  name="option">
                <mat-option *ngFor="let option of options" [value]="option.value">
                  {{option.viewValue}}
                </mat-option>
              </mat-select>
          </mat-form-field>
          <br>

          <mat-form-field appearance="legacy">
            <mat-label>Buy Currency</mat-label>
            <mat-select id="inpbuyCurrency" formControlName="buyCurrency"  [(ngModel)]="updateCurrencyForm.buyCurrency" name="option">
                <mat-option *ngFor="let option of options" [value]="option.value">
                  {{option.viewValue}}
                </mat-option>
              </mat-select>
          </mat-form-field>
          <br>

          <mat-form-field appearance="legacy">
            <mat-label>Sort Order</mat-label>
            <input id="inpcurrencySortOrder" formControlName="currencySortOrder" matInput
              [(ngModel)]="updateCurrencyForm.currencySortOrder" type="number" min="1" step="1"
              pattern="^(0|[1-9][0-9]*)$">
            <mat-error *ngIf="_currencyForm.controls['currencySortOrder'].errors?.max"> The range for sort order
              is 1-9999. </mat-error>
            <mat-error *ngIf="_currencyForm.controls['currencySortOrder'].errors?.pattern">Only Numeric Values
              allowed</mat-error>
          </mat-form-field>
        <br>
        
        <mat-form-field appearance="legacy">
          <mat-label>Description</mat-label>
          <input id="inpsymbol" formControlName="description" matInput 
            aria-label="Enter Symbol">

        </mat-form-field>
        <br>
        <section class="example-section">
         <mat-checkbox id="inpdraft" formControlName="draft" [(ngModel)]="updateCurrencyForm.draft"
            class="example-margin width-15" color='primary'>Draft
          </mat-checkbox>
        </section>
        <br>
        <input type="hidden" formControlName="currencyId" [(ngModel)]="updateCurrencyForm.currencyId">
        <br>
        <mat-dialog-actions *ngIf="userrole">
          <button [disabled]="(isCurrencyEdit === true) ? !_currencyForm.dirty : false"
            [ngClass]="(isCurrencyEdit === true) ? 'mat-raised-button ml-1':'mat-raised-button ml-1 mat-primary'"
            id="btnSaveUpdate" type="submit" (click)="onSubmit()">{{isCurrencyEdit === true ? 'Update' :
            'Save'}}</button>

          <button id="btnCancel" class="mat-raised-button ml-1 mat-primary" (click)="onClear()">Cancel</button>
        </mat-dialog-actions>
        <span *ngIf="!userrole" style="color: #ff0202;font-size: 12px;font-weight: 400;">You can't save changes since
          you
          have Read access.</span>
    </div>
  </form>
</div>