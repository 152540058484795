import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class UserAccess {
  public ismenuEnable: any = [];
  public loggedInUser: any;
  public userRole: any;
  public userId:any;
  constructor() { }
  set setismenuEnable(val: any) {
    this.ismenuEnable = val;
  }

  set setloggedInUser(val: any) {
    this.loggedInUser = val;
  }
  set setuserId(val: any) {
    this.userId = val;
  }

  get getismenuEnable(): any {
    return this.ismenuEnable;
  }

  get getloggedInUser(): any {
    return this.loggedInUser;
  }
  get getuserId(): any {
    return this.userId;
  }

}
