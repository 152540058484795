import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ChannelService } from  'src/app/services/market/channel/channel.service';
import { Router, ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';


interface ChannelList {
  channelId: any;
  channelName: any;
}

@Component({
  selector: 'channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.css']
})


export class ChannelComponent implements OnInit {
  title ="Channel";
  ddChannelNameList: ChannelList[];
  channelNameMasterList: any[] = [];
  channelNameFilterList: any[] = [];
  channelName: any = "";
  status: any = "active";

  constructor(public _channelService: ChannelService,
    private router:Router,
    public route: ActivatedRoute,
    private toastr: ToastrService,private titleService:Title) { }

  ngOnInit(): void {
    this.receivedChildHandler();
  }

  receivedChildHandler() {
    this.titleService.setTitle(this.title);
    this._channelService.getChannelAll().subscribe((data: any) => {
      this.ddChannelNameList = this.channelNameMasterList = this.channelNameFilterList = data.getChannelAll;
      this.channelNameFilterList = this.channelNameMasterList.filter((item, i, arr) => arr.findIndex((t) => t.channelName=== item.channelName) === i);
     // this.channelNameMasterList = this.channelNameFilterList = data.getChannelAll;
    });
  }

  filterItemCN(filterKey: string) {
    if (filterKey != null  && filterKey != ""){
    this.channelNameFilterList = this.channelNameMasterList.filter(x => x.channelName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
    this.channelNameFilterList = this.channelNameFilterList.filter((item, i, arr) => arr.findIndex((t) => t.channelName=== item.channelName) === i);
    }else
    this.channelNameFilterList = this.channelNameMasterList.filter((item, i, arr) => arr.findIndex((t) => t.channelName=== item.channelName) === i);
  }  

  onSearchClear() {
    this.searchform.controls['channelName'].setValue('');
    this.searchform.controls['status'].setValue("active");
  }

  searchform: FormGroup = new FormGroup({
    channelName: new FormControl(''),
    status: new FormControl('')
  })

}

