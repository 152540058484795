import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ModulePermission } from 'src/app/models/enum';
import { UserAccess } from 'src/app/models/userAccess';
import { CommonUtilityService } from 'src/app/services/organization/commonutility/commonutility.service';
import { CorporatebrandService } from 'src/app/services/organization/corporatebrand/corporatebrand.service';
import { DepartmentService } from 'src/app/services/organization/department/department.service';
import { MarkupgroupService } from 'src/app/services/organization/markupgroup/markupgroup.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { AccountService } from '../../../../shared/services/account.service'

@Component({
  selector: 'app-markupgroupform',
  templateUrl: './markupgroupform.component.html',
  styleUrls: ['./markupgroupform.component.css']
})
export class MarkupgroupformComponent implements OnInit {
  @Output() childButtonEvent = new EventEmitter();
  corporateBrandMasterCGList: any[] = [];
  corporateBrandFilterCGList: any[] = [];
  updateMarkupgroupForm: any;
  ismarkupgroupEdit: any = false;
  isDisableActive: any = true;
  isMarkupGroupEditCB: any = 'legacy';
  validationMessages: any;
  seasonMasterlist: any;
  seasonFilterList: any;
  seasonDeptList: any[] = [];
  seasonNumber: FormControl = new FormControl('', [this._commonutilityService.acStartSeasonValidator("", null)]);
  subscription: Subscription;
  userrole: boolean;
  users:any;
  constructor(public _corporatebrandService: CorporatebrandService,
    public _markupgroupService: MarkupgroupService,
    public _departmentService: DepartmentService,
    public _commonutilityService: CommonUtilityService,
    private toastr: ToastrService,
    public route: ActivatedRoute,
    public account: AccountService,
    private applicationInsightService:ApplicationInsightService,
    public _userAccess: UserAccess
  ) {
    this.updateMarkupgroupForm = Object.assign({ active: true, draft: true });
    this.users = JSON.parse(localStorage.getItem("users") || "[]")
  }
  ngOnInit(): void {
    this.applicationInsightService.logEvent("Markupgroupform Initialization started");
    this.userrole =this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this._markupform.controls.lastUpdatedBy.setValue(JSON.parse(localStorage.getItem("UserInfo")).Email || "[]");
    this.validationMessages = this._commonutilityService.validationMsgs;
    this.onClear();

    this._corporatebrandService.getCorporateBrandList().subscribe((data: any) => {
      this.corporateBrandMasterCGList = this.corporateBrandFilterCGList = data.getCorporateBrandAll.filter(object => {
        return object['active'] !== false;
      });
      this.corporateBrandFilterCGList = this.corporateBrandMasterCGList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
      this.childButtonEvent.emit(this.corporateBrandFilterCGList);
    });

    this._departmentService.getDepartmentSeasonByRange(10).subscribe((data: any) => {
      this.seasonNumber.setValue(data.seasonByRange.filter(objCB => { return objCB['isCurrentSeason'] !== false; }).map(({ seasonNumber }) => seasonNumber).toString());
      localStorage.setItem("CurrentSeason",data.seasonByRange.filter(objCB => { return objCB['isCurrentSeason'] !== false; }).map(({ seasonNumber }) => seasonNumber).toString())
      this.seasonMasterlist = this.seasonFilterList = data.seasonByRange;
    });
    this.subscription =
      this._markupgroupService.markupgroupId$.subscribe(
        markupGroupId => { this.updateMarkupgroup(markupGroupId) });
        this.applicationInsightService.logEvent("Markupgroupform Initialization ended");
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }
  updateMarkupgroup(markupGroupId: any) {
    this.applicationInsightService.logEvent("UpdateMarkupgroup started");
    this._markupform.controls.corporateBrandId.setValue('');
    this.ismarkupgroupEdit = true;
    this.isDisableActive = true;
    this._markupgroupService.getMarkupgroupById(markupGroupId).subscribe((data: any) => {
      this.isDisableActive = false; this.updateMarkupgroupForm = data.markupGroup;
      //this.isMarkupGroupEditCB=this.updateMarkupgroupForm.draft==true?'legacy':'fill';
      this.seasonDeptList = [];
      this._markupgroupService.getDepartmentByMarkUpGroupId(markupGroupId, this.seasonNumber.value).subscribe((data: any) => { this.seasonDeptList = data.markUpGroupDepartment })
    });
    document.getElementById("scrolltop").scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    this.applicationInsightService.logEvent("UpdateMarkupgroup ended");
  }
  displayTextCB(corporateBrandId: any) {
    if (corporateBrandId === null || corporateBrandId === "")
      return null;
    else if (this.corporateBrandMasterCGList != undefined && corporateBrandId != null) {
      return this.corporateBrandMasterCGList.find(x => x.corporateBrandId == corporateBrandId).corporateBrandCodeName;

    }
  }
  filterItemCorporateBrand(filterKey: string) {
    this.applicationInsightService.logEvent("Markupgroup corporatebrand filter started");
    if (filterKey !== null && filterKey !== "") {
      this.corporateBrandFilterCGList = this.corporateBrandMasterCGList.filter(cb => cb.corporateBrandName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
      this.corporateBrandFilterCGList = this.corporateBrandFilterCGList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
      if (filterKey === null || filterKey === "") {
        this.corporateBrandFilterCGList = this.corporateBrandMasterCGList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
      }
    }
    this.applicationInsightService.logEvent("Markupgroup corporatebrand filter ended");
  }
  displayTextSeason(startSeason: any) {
    this.applicationInsightService.logEvent("Markupgroup disply seasonname started");
    if (startSeason == null || startSeason == "")
      return null;
    else if (this.seasonMasterlist != undefined && startSeason != null)
      return this.seasonMasterlist.find(x => x.seasonNumber === startSeason).displayName;
      this.applicationInsightService.logEvent("Markupgroup disply seasonname ended");
  }
  filterSeason(filterKeyS: string) {
    this.applicationInsightService.logEvent("Markupgroup filter season started");
    if (filterKeyS != null && filterKeyS != "") {
      this.seasonFilterList = this.seasonMasterlist.filter(rs => rs.displayName.toLowerCase().includes(filterKeyS.toString().toLowerCase()))
      if (this.seasonMasterlist.find(x => x.seasonNumber == filterKeyS) != null) {
        this.seasonDeptList = [];
        this._markupgroupService.getDepartmentByMarkUpGroupId(this.updateMarkupgroupForm.markupGroupId, this.seasonNumber.value).subscribe((data: any) => {
          this.seasonDeptList = data.markUpGroupDepartment
        })
      }
    }
    else { this.seasonFilterList = this.seasonMasterlist; }
    this.applicationInsightService.logEvent("Markupgroup filter season ended");
  }
  onSubmit() {
    this.applicationInsightService.logEvent("Markupgroup form submit started");
    if (this._markupform.controls.markupGroupName.value != undefined && this._markupform.controls.markupGroupName.value != null && this._markupform.controls.markupGroupName.value != '') { this._markupform.controls.markupGroupName.setValue(this._markupform.controls.markupGroupName.value.trim()) }
    if (this._markupform.controls.corporateBrandId.value === '') { this._markupform.controls.corporateBrandId.setValue(null) }
    this._markupform.controls['corporateBrandId'].updateValueAndValidity();
    this._markupform.controls['markupGroupCode'].updateValueAndValidity();
    this._markupform.controls['markupGroupName'].updateValueAndValidity();
    this._markupform.controls['markUpGroupSortOrder'].updateValueAndValidity();
    console.log("Mark UP Group Before:" + JSON.stringify(this._markupform.value))
    if (this._markupform.controls.draft.value === undefined || this._markupform.controls.draft.value === null) { this._markupform.controls.draft.setValue(true); }
    if (this._markupform.valid) {
      console.log("Mark UP Group:" + JSON.stringify(this._markupform.value))
      if (this._markupform.value.markupGroupId == undefined || this._markupform.value.markupGroupId == 0 || this._markupform.value.markupGroupId == null) {
        this._markupgroupService.createMarkupgroup(this._markupform.value).subscribe
          (data => {
            this.toastr.success(data.message, "", { timeOut: 10000 });
            this.onClear();
            this._markupgroupService.refreshMarkupgroupList();
          },
            error => { this.toastr.error(error, "", { timeOut: 10000 }) })
      }
      else {
        this._markupgroupService.updateMarkupgroup(this._markupform.value).subscribe
          (data => {
            this.toastr.success(data.message, "", { timeOut: 10000 });
            this.onClear();
            this._markupgroupService.refreshMarkupgroupList();
          },
            error => { this.toastr.error(error, "", { timeOut: 10000 }) })
      }
    }
    this.applicationInsightService.logEvent("Markupgroup form submit ended");
  }
  onClear() {
    this.applicationInsightService.logEvent("Markupgroup clear form  started");
    //Clearing validation 
    this._markupform.controls.corporateBrandId.clearValidators();
    this._markupform.controls.markupGroupCode.clearValidators();
    this._markupform.controls.markupGroupName.clearValidators();
    this._markupform.controls.markUpGroupSortOrder.clearValidators();
    //Clearing control values 
    this._markupform.controls.corporateBrandId.reset();
    this._markupform.controls.markupGroupId.reset();
    this._markupform.controls.markupGroupCode.reset();
    this._markupform.controls.markupGroupName.reset();
    this._markupform.controls.markUpGroupSortOrder.reset();
    this._markupform.controls.draft.reset();
    //Setting up the validation
    this._markupform.controls.corporateBrandId.setValidators([this._commonutilityService.acObjectValidator()]);
    this._markupform.controls.markupGroupCode.setValidators([Validators.required, Validators.min(10000), Validators.max(99999)]);
    this._markupform.controls.markupGroupName.setValidators([Validators.required, Validators.maxLength(250)]);
    this._markupform.controls.markUpGroupSortOrder.setValidators([Validators.required, Validators.min(1), Validators.max(9999)]);
    this._markupform.controls.active.setValue(true);
    this._markupform.controls.draft.setValue(true);
    this.ismarkupgroupEdit = false;
    this.isDisableActive = true;
    this.isMarkupGroupEditCB = 'legacy';
    this.applicationInsightService.logEvent("Markupgroup clear form  ended");
  }

  //Used for New MarkupGroup Creation.It will build the JSON
  _markupform: FormGroup = new FormGroup({
    corporateBrandId: new FormControl(null, [this._commonutilityService.acObjectValidator()]),
    markupGroupId: new FormControl(''),
    markupGroupCode: new FormControl('', [Validators.required, Validators.min(10000), Validators.max(99999)]),
    markupGroupName: new FormControl('', [Validators.maxLength(250)]),
    markUpGroupSortOrder: new FormControl('', [Validators.required, Validators.min(1), Validators.max(9999), Validators.pattern('^(?![-]).*')]),
    active: new FormControl(true),
    lastUpdatedBy: new FormControl(),
    draft: new FormControl(true),
  });

}


