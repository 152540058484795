
import { Component, Inject, Injectable } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { BehaviorSubject } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { ProductbrandService } from 'src/app/services/organization/productbrand/productbrand.service'
import { HttpClient } from "@angular/common/http";
import { DepartmentService } from "src/app/services/organization/department/department.service";
import { CorporatebrandService } from 'src/app/services/organization/corporatebrand/corporatebrand.service';
import { CommonUtilityService } from "src/app/services/organization/commonutility/commonutility.service";
import { ToastrService } from "ngx-toastr";
import { LoaderService } from "src/app/shared/services/loader.service";
import { ModulePermission } from "src/app/models/enum";

/**
 * Node for to-do item
 */
export class DepartmentNode {
  children?: DepartmentNode[];
  item: string;
}

interface CorporateBrandNameList {
  corporateBrandId: any;
  corporateBrandName: any;
  corporateBrandCodeName: any;
}

/** Flat to-do item node with expandable and level information */
export class DepartmentFlatNode {
  item: string;
  level: number;
  expandable: boolean;
}
export class getDepCollections {
  corporateBrandId: any = null;
  startSeason: any = null;
  endSeason: any = null;
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable({ providedIn: "root" })
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<DepartmentNode[]>([]);
  treeData: any[];
  dataList: any[];
  recivedData: any;
  copIdWhenChange: any;
  getDep = new getDepCollections();
  get data(): DepartmentNode[] {
    return this.dataChange.value;
  }

  constructor(private http: HttpClient, private productbrandService: ProductbrandService,
    private _mdr: MatDialogRef<ProductbrandformComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {
    _mdr.disableClose = true;
    this.recivedData = data?.rowData?.data;
    //this.initialize();
  }

  getAllNodes(array: any) {
    let arr = [];
    let finalArr = [];
    array.forEach((item: any) => {
      let childArr = [];
      item.section.forEach((children: any) => {
        if (children?.department?.length) {
          let childArr2 = [];
          children.department.forEach(ele => {
            childArr2.push({ item: ele?.departmentCodeAndName + ',' + ele?.departmentId + ',' + ele?.startSeason + ',' + ele?.endSeason })
          })
          childArr.push({ item: children?.sectionCodeAndName, 'children': childArr2 })
        }
      })
      arr.push({ 'item': item?.divisionCodeAndName, 'children': childArr })
    });

    arr.forEach(a => {
      if (a.children.length) {
        finalArr.push(a)
      }
    })


    return finalArr;
  }

  initialize(getCollection?: any) {
    this.treeData = [];
    this.dataList = [];
    this.copIdWhenChange = getCollection.corporateBrandId;
    if (getCollection.corporateBrandId !== null && getCollection.startSeason !== null) {
      this.productbrandService.getGetProductBrandDepartmentsAndSectionsAndDivisions(getCollection).subscribe(data => {
        this.dataList = this.getAllNodes(data.division);
        const TREE_DATA: DepartmentNode[] = this.dataList;
        this.treeData = TREE_DATA;
        const datan = TREE_DATA;
        this.dataChange.next(datan);
      })
    }
    else {
      const TREE_DATA: DepartmentNode[] = [];
      this.treeData = TREE_DATA;
      const datan = TREE_DATA;
      this.dataChange.next(datan);
    }
  }
  transformDate(value: any, args?: any): any {
    if (value != undefined && value != null && value != '')
      return value.substring(0, 7).replace("-", "");
  }
  transformWeek(value: any, args?: any): any {
    if (value != undefined && value != null && value != '')
      return value.replace("-W", "");
  }
  public filter(filterText: string) {
    let filteredTreeData: any;
    if (filterText) {
      // Filter the tree
      function filter(array, text) {
        const getChildren = (result, object) => {
          var re = new RegExp(text, 'gi');
          if (object?.item?.match(re)) {
            result.push(object);
            return result;
          }
          if (Array.isArray(object.children)) {
            const children = object.children.reduce(getChildren, []);
            if (children.length) result.push({ ...object, children });
          }
          return result;

        };

        return array.reduce(getChildren, []);
      }

      filteredTreeData = filter(this.treeData, filterText);
    } else {
      // Return the initial tree
      filteredTreeData = this.treeData;
    }

    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    // file node as children.
    const dataMain = filteredTreeData;
    // Notify the change.
    this.dataChange.next(dataMain);
  }
}
@Component({
  selector: "ProductbrandformComponent",
  styleUrls: ['../productbrandlist/prouctbrandcomponent.component.css'],
  templateUrl: './productbrandform.component.html',
  providers: [ChecklistDatabase]
})
export class ProductbrandformComponent {
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<DepartmentFlatNode, DepartmentNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<DepartmentNode, DepartmentFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: DepartmentFlatNode | null = null;

  /** The new item's name */
  newItemName = "";

  treeControl: FlatTreeControl<DepartmentFlatNode>;

  treeFlattener: MatTreeFlattener<DepartmentNode, DepartmentFlatNode>;

  dataSource: MatTreeFlatDataSource<DepartmentNode, DepartmentFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<DepartmentFlatNode>(true /* multiple */);
  selectedDepartments: any[];
  connectedDepartments: any[];
  /// Filtering
  myControl = new FormControl();
  options: string[] = ["One", "Two", "Three"];

  filteredOptions: Observable<string[]>;
  searchTextboxControl = new FormControl();
  public searchString = '';
  productBrandData: any;
  validationMessages: any;
  eventfromView: boolean = false;
  updateDepartId: any = [];
  corporateBrandMasterCGList: any[] = [];
  corporateBrandFilterCGList: any[] = [];
  ddCorporateBrandNameCGList: CorporateBrandNameList[];
  checkReadOnly: boolean = false;
  covertedFromdate: any;
  covertedTodate: any;
  isdropdownChange: boolean = false;
  userrole: boolean;
  users: any;
  AtleastOneCheck: boolean = false;
  activeCheck: boolean = false;
  FirstDarf: boolean = false;
  draftCheckdisabled: boolean = false;
  isCBselected: boolean = true;
  isRequired: boolean = true;
  getDep = new getDepCollections();
  dStartSeason: any;
  dEndSeason: any;
  pStartSeason: any;
  pEndSeason: any;
  depdisable: boolean = false;
  constructor(
    private _database: ChecklistDatabase,
    public loaderService: LoaderService,
    private productbrandService: ProductbrandService,
    private _mdr: MatDialogRef<ProductbrandformComponent>,
    public _departmentService: DepartmentService,
    public _commonutilityService: CommonUtilityService,
    private _corporatebrandService: CorporatebrandService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    if (data?.msg === "viewHit") {
      this.eventfromView = true;
    } else {
      this.eventfromView = false;
    }
    this.validationMessages = this._commonutilityService.validationMsgs;
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<DepartmentFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
    _database.dataChange.subscribe(dataMain => {
      this.dataSource.data = dataMain;
      if (dataMain.length != 0) {

        this.isCBselected = true;
      }
      else {
        this.isCBselected = false;
      }
      // calling and binding getProductBrandbyId data into form
      this.productbrandService.GetProductBrandId(this._database?.recivedData?.productBrandId).subscribe(data => {
        if (data) {
          this.productBrandData = data?.productBrands
          this.checkByEdit(data?.productBrands);
          if (this.productBrandData[0]?.corporateBrandId) {
            this.checkReadOnly = true
          } else {
            this.checkReadOnly = false
          }
          if (this.productBrandData[0]?.active) {
            this.activeCheck = true
          } else {
            this.activeCheck = false;
          }

          if ((this.productBrandData[0]?.validFrom !== null || this.productBrandData[0]?.validFrom !== ""
            || this.productBrandData[0]?.validFrom !== undefined) && this.activeCheck === true) {
            this.FirstDarf = true;
            this.draftCheckdisabled = true
          } else {
            this.FirstDarf = false;
            this.draftCheckdisabled = false;
          }
          // setting the form values 2020207
          this.productBrandForm.controls.corporateBrandName.setValue(this.productBrandData[0]?.corporateBrandId ? this.productBrandData[0]?.corporateBrandId : _database.copIdWhenChange)
          if (!this.isdropdownChange) {
            this.productBrandForm.controls.productBrandName.setValue(this.productBrandData[0]?.productBrandName)
            this.productBrandForm.controls.productBrandSortOrder.setValue(this.productBrandData[0]?.sortOrder)
            this.productBrandForm.controls.validFrom.setValue(this.getWeekNumber(this.productBrandData[0]?.startWeek))
            this.productBrandForm.controls.validTo.setValue(this.getWeekNumber(this.productBrandData[0]?.endWeek))
            this.productBrandForm.controls.active.setValue(this.productBrandData[0]?.active)
            this.productBrandForm.controls.draft.setValue(this.productBrandData[0]?.active ? false : true)
            setTimeout(() => {
              this.getDepartments();
            }, 500);
          }
        }
      })
      this._corporatebrandService.getCorporateBrandList().subscribe((data: any) => {
        this.ddCorporateBrandNameCGList = this.corporateBrandMasterCGList = this.corporateBrandFilterCGList = data.getCorporateBrandAll.filter(object => {
          return object['active'] !== false;
        });
        this.corporateBrandFilterCGList = this.corporateBrandMasterCGList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
      });
    });
    this.users = JSON.parse(localStorage.getItem("users") || "[]");
  }
  ngOnInit() {
    this.userrole = this.users.includes(ModulePermission.Organization_Write) ? true : false;
  }
  getDepartments() {
    this.getDep.corporateBrandId = (this.productBrandForm.controls.corporateBrandName.value === null) ? null : (this.productBrandForm.controls.corporateBrandName.value === '') ? null : (this.productBrandForm.controls.corporateBrandName.value == undefined) ? null : this.productBrandForm.controls.corporateBrandName.value;
    this.getDep.startSeason = (this.productBrandForm.controls.validFrom.value === null) ? null : (this.productBrandForm.controls.validFrom.value === '') ? null : (this.productBrandForm.controls.validFrom.value == undefined) ? null : this.productBrandForm.controls.validFrom.value?.replace("-W", "");
    this.getDep.endSeason = (this.productBrandForm.controls.validTo.value === null) ? null : (this.productBrandForm.controls.validTo.value === '') ? null : (this.productBrandForm.controls.validTo.value == undefined) ? null : this.productBrandForm.controls.validTo.value?.replace("-W", "");
    this._database.initialize(this.getDep);
    this.isdropdownChange = true;
    this.fnSetControlsValidation();
  }

  getLevel = (node: DepartmentFlatNode) => node.level;

  isExpandable = (node: DepartmentFlatNode) => node.expandable;

  getChildren = (node: DepartmentNode): DepartmentNode[] => node.children;

  hasChild = (_: number, _nodeData: DepartmentFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: DepartmentFlatNode) => _nodeData.item === "";

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: DepartmentNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item
        ? existingNode
        : new DepartmentFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: DepartmentFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );

    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: DepartmentFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: DepartmentFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
    this.productBrandForm.markAsDirty();
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: DepartmentFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: DepartmentFlatNode): void {
    let parent: DepartmentFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
    this.productBrandForm.controls.allDpt.setValue(false);
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: DepartmentFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
    this.productBrandForm.markAsDirty();
  }

  checkAll() {
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      this.checklistSelection.deselect(this.treeControl.dataNodes[i]);
      this.treeControl.collapse(this.treeControl.dataNodes[i])
      if (!this.checklistSelection.isSelected(this.treeControl.dataNodes[i]) && this.productBrandForm.controls.allDpt.value == true) {
        this.checklistSelection.toggle(this.treeControl.dataNodes[i]);
        this.treeControl.expand(this.treeControl.dataNodes[i])
      }
      else {
        this.checklistSelection.deselect(this.treeControl.dataNodes[i]);
        this.treeControl.collapse(this.treeControl.dataNodes[i])
      }
    }
  }
  /* Get the parent node of a node */
  getParentNode(node: DepartmentFlatNode): DepartmentFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  displayTextCB(corporateBrandId: string) {
    if (corporateBrandId === null || corporateBrandId === "")
      return null;
    else if (this.ddCorporateBrandNameCGList != undefined && corporateBrandId != null)
      return this.ddCorporateBrandNameCGList.
        find(x => x.corporateBrandId == corporateBrandId).corporateBrandCodeName;
  }

  filterItemCorporateBrand(filterKey: any) {
    let newCBList: any[] = [];
    newCBList = this.corporateBrandMasterCGList;
    if (filterKey != null && filterKey != "" && isNaN(filterKey)) {
      this.corporateBrandFilterCGList = newCBList.filter(cb => cb.corporateBrandName.toLowerCase().includes(filterKey.toString().toLowerCase()));
      this.corporateBrandFilterCGList = this.corporateBrandFilterCGList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
    } else
      this.corporateBrandFilterCGList = this.corporateBrandMasterCGList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
    if (filterKey === "") {
      this.clearTree();
    }
  }

  getSelectedItems(): string {
    let connectDepId: any;
    this.updateDepartId = [];
    if (!this.checklistSelection?.selected?.length) {
      return "Connect Departments";
    } else {
      for (let i = 0; i < this.checklistSelection?.selected?.length; i++) {
        if (this.checklistSelection.selected[i].level == 2) {
          connectDepId = this.checklistSelection?.selected[i]?.item?.split(',').slice(-3).reverse().pop();
          if (this.updateDepartId?.indexOf(connectDepId) == -1) {
            this.updateDepartId?.push(connectDepId);
          }
        }
      }
      return (this.checklistSelection?.selected?.filter(s => s.level != 0)?.map(s => s.item?.split(',')[0])?.join(","));
    }
  }

  filterChanged(filterText: string) {
    // ChecklistDatabase.filter method which actually filters the tree and gives back a tree structure
    this._database.filter(filterText);
    if (filterText) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
  }
  cancel() {
    this._mdr.close(false)
    this._database.recivedData = ''
  }
  onSubmit() {
    // this._mdr.close(false)
    this.productBrandForm.value.department = this.updateDepartId?.map(Number);
    this.updateDepartment(this.productBrandForm)

  }

  updateDepartment(formdata) {
    if (formdata.value.corporateBrandName === undefined) {
      formdata.value.corporateBrandName = null;
    }
    let updatePayload = {
      productBrandId: this.productBrandData[0]?.productBrandId,
      corporateBrandId: formdata?.value?.corporateBrandName,
      sortOrder: formdata?.value?.productBrandSortOrder,
      validFrom: this.getDateOfWeekValidFrom(formdata?.value?.validFrom?.split('-')[1]?.substring(1), formdata?.value?.validFrom?.split('-')[0]),
      validTo: this.getDateOfWeek(formdata?.value?.validTo?.split('-')[1]?.substring(1), formdata?.value?.validTo?.split('-')[0]),
      active: formdata?.value?.active,
      draft: formdata?.value?.draft,
      lastUpdatedBy: JSON.parse(localStorage.getItem("UserInfo")).Email,
      departments: formdata?.value?.department,
      startWeek: formdata?.value?.validFrom?.replace('-W', ''),
      endWeek: formdata?.value?.validTo?.replace('-W', '')
    }

    this.productbrandService.UpdateProductBrand(updatePayload).subscribe(res => {
      this._mdr.close(false)
      this.toastr.success(res.message, "", { timeOut: 10000 });
      this.productbrandService.resfreshCall.next(true)
    },
      error => { this.toastr.error(error, "", { timeOut: 10000 }) }
    )
  }

  clearTree() {
    this.checklistSelection.clear();
    this.getSelectedItems()
  }
  checkByEdit(connectedDepartments: any[]) {
    this.clearTree();
    let actualvalue: any = '';
    let exactvalue: any = '';
    let actualselectedId: any = '';
    let depId: any = "";
    if (connectedDepartments?.length != 0) {
      for (let i = 0; i < connectedDepartments?.length; i++) {
        for (let j = 0; j < connectedDepartments[i].departments?.length; j++) {
          depId = connectedDepartments[i]?.departments[j]?.departmentCode
          this.selectedDepartments = depId;
          for (let i = 0; i < this.selectedDepartments?.length; i++) {
            for (let j = 0; j < this.dataSource?.data?.length; j++) {
              for (let k = 0; k < this.dataSource?.data[j]?.children?.length; k++) {
                for (let p = 0; p < this.dataSource.data[j].children[k].children.length; p++) {
                  let str1 = this.selectedDepartments.toString();
                  let str2 = this.dataSource.data[j].children[k].children[p].item.split(' ')[0];
                  if (str2 == str1) {
                    actualvalue = this.dataSource.data[j].children[k].children[p].item.split(' ')[1];
                    for (let q = 0; q < this.treeControl.dataNodes.length; q++) {
                      exactvalue = this.treeControl.dataNodes[q].item.split(' ')[1];
                      let str3 = this.treeControl.dataNodes[q].item.split(' ')[0];
                      if (actualvalue == exactvalue && str3 == str1) {
                        actualselectedId = [q];
                        if (!this.checklistSelection.isSelected(this.treeControl.dataNodes[actualselectedId])) {
                          this.checklistSelection.toggle(this.treeControl.dataNodes[actualselectedId]);
                          this.treeControl.expand(this.treeControl.dataNodes[actualselectedId])
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // search filter logic start
  filterLeafNode(node: DepartmentFlatNode): boolean {
    if (!this.searchString) {
      return false
    }
    return node.item.split(',')[0].toLowerCase()
      .indexOf(this.searchString?.toLowerCase()) === -1

  }

  filterParentNode(node: DepartmentFlatNode): boolean {
    if (
      !this.searchString ||
      node.item.split(',')[0].toLowerCase().indexOf(this.searchString?.toLowerCase()) !==
      -1
    ) {
      return false
    }
    const descendants = this.treeControl.getDescendants(node)

    if (
      descendants.some(
        (descendantNode) =>
          descendantNode.item.split(',')[0]
            .toLowerCase()
            .indexOf(this.searchString?.toLowerCase()) !== -1
      )
    ) {
      return false
    }

    return true
  }

  //getting weak number 
  getDateOfWeek(w: any, y: any) {
    let date = new Date(y, 0, (2 + (w - 1) * 7)); // Elle's method
    date.setDate(date.getDate() + (1 - date.getDay())); // 0 - Sunday, 1 - Monday etc
    return date
  }
  // this function only usingin update payload 
  getDateOfWeekValidFrom(w: any, y: any) {
    let date = new Date(y, 0, (2 + (w - 1) * 7)); // Elle's method
    date.setDate(date.getDate() + (1 - date.getDay())); // 0 - Sunday, 1 - Monday etc
    console.log(date)
    // Add a day
    date.setDate(date.getDate() + 1)
    return date
  }




  // setting the recived date to week date 
  getWeekNumber(d: any) {
    if (d === null || d === '' || d === undefined) {
      return d;
    }
    else {
      let my_string: any = d;
      let my_insert: any = "-W";
      let my_insert_location = 4;
      my_string = my_string.split('');
      my_string.splice(my_insert_location, 0, my_insert);
      my_string = my_string.join('');
      return my_string
    }
  }
  // search filter logic end

  /**
   * Clearing search textbox value 
   */
  clearSearch(event: any) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
  }

  isDepartmentEnable(node: any) {
    this.depdisable = false;
    this.dStartSeason = node.item.split(",")[2];
    this.dEndSeason = node.item.split(",")[3];
    this.pStartSeason = (this.productBrandForm.controls.validFrom.value === null) ? null : (this.productBrandForm.controls.validFrom.value === '') ? null : (this.productBrandForm.controls.validFrom.value == undefined) ? null : this.productBrandForm.controls.validFrom.value?.replace("-W", "");
    this.pEndSeason = (this.productBrandForm.controls.validTo.value === null) ? null : (this.productBrandForm.controls.validTo.value === '') ? null : (this.productBrandForm.controls.validTo.value == undefined) ? null : this.productBrandForm.controls.validTo.value?.replace("-W", "");
    if (this.dEndSeason < this.pEndSeason) {
      this.depdisable = true;
    }
    else if (this.dStartSeason > this.pStartSeason) {
      this.depdisable = true;
    }
    return this.depdisable;
  }
  validPattern = "^[a-zA-Z0-9]+$";// alphanumeric exact 0 letters 
  productBrandForm: FormGroup = new FormGroup(
    {
      corporateBrandName: new FormControl('', [Validators.required]),
      productBrandName: new FormControl(''),
      productBrandSortOrder: new FormControl('', [Validators.required, Validators.min(1), Validators.max(9999)]),
      department: new FormControl(''),
      validFrom: new FormControl(''),
      validTo: new FormControl(''),
      active: new FormControl(''),
      draft: new FormControl(''),
      lastUpdatedBy: new FormControl(),
      allDpt: new FormControl(false),
    });

  ActiveCheck(active) {
    if (active.checked) {
      this.AtleastOneCheck = false;
      this.productBrandForm.controls['draft'].setValue(false)
      if (this.productBrandForm.controls.validFrom.value == null || this.productBrandForm.controls.validFrom.value == '' || this.productBrandForm.controls.validFrom.value == undefined) {
        this.isRequired = false;
      }
      this.activeCheck = true
    } else {
      this.isRequired = true;
      this.AtleastOneCheck = true;
    }
  }

  DraftCheck(draft) {
    if (draft.checked) {
      this.AtleastOneCheck = false;
      this.productBrandForm.controls['active'].setValue(false);
      this.activeCheck = false
      this.isRequired = true;
      if ((this.productBrandForm.controls.validFrom.value == null || this.productBrandForm.controls.validFrom.value == '' ||
        this.productBrandForm.controls.validFrom.value == undefined) &&
        (this.productBrandForm.controls.validTo.value == null || this.productBrandForm.controls.validTo.value == '' || this.productBrandForm.controls.validTo.value == undefined)) {
        setTimeout(() => {
          this.productBrandForm.controls['validFrom'].setErrors(null);
        }, 500);

      }
      else if (this.productBrandForm.controls.validFrom.value == null || this.productBrandForm.controls.validFrom.value == '' || this.productBrandForm.controls.validFrom.value == undefined) {
        setTimeout(() => {
          this.productBrandForm.controls['validFrom'].setErrors({ 'invalidAutocomplete': { value: true } });
        }, 500);
      }
    } else {
      this.AtleastOneCheck = true;
    }
  }



  GetFromdate(fdate: any) {
    this.fnSetControlsValidation()
  }

  GetTodate(tdate: any) {
    this.fnSetControlsValidation()
  }

  fnSetControlsValidation() {
    this.acvalidFormValidator(this.getDateOfWeek(this.productBrandForm?.controls?.validTo?.value?.split('-')[1]?.substring(1), this.productBrandForm?.controls?.validTo?.value?.split('-')[0]), this.getDateOfWeek(this.productBrandForm?.controls?.validFrom?.value?.split('-')[1]?.substring(1), this.productBrandForm?.controls?.validFrom?.value?.split('-')[0]))
    this.acvalidToValidator(this.getDateOfWeek(this.productBrandForm?.controls?.validFrom?.value?.split('-')[1]?.substring(1), this.productBrandForm?.controls?.validFrom?.value?.split('-')[0]), this.getDateOfWeek(this.productBrandForm?.controls?.validTo?.value?.split('-')[1]?.substring(1), this.productBrandForm?.controls?.validTo?.value?.split('-')[0]))

  }

  acvalidFormValidator(Todate: any, Fromdate: any) {
    if (Todate == "Invalid Date") {
      Todate = null
    }
    if (Todate != null && Todate != "" && Todate != undefined) {
      if (Todate != null && Todate != undefined && Todate != "" && Fromdate >= Todate) {
        this.productBrandForm.controls['validFrom'].setErrors({ 'invalidValidFrom': { value: true } });
      }
      this.productBrandForm.controls['validTo'].setErrors(null);
    }
    else
      this.productBrandForm.controls['validFrom'].setErrors({ 'invalidAutocomplete': { value: true } });
  }

  acvalidToValidator(Fromdate: any, Todate: any) {
    if (Fromdate != null && Fromdate != "" && Fromdate != undefined && Fromdate != "Invalid Date") {
      if (Fromdate != null && Fromdate != undefined && Fromdate != "" && Todate <= Fromdate) {
        this.productBrandForm.controls['validTo'].setErrors({ 'invalidValidTo': { value: true } });
      }
      this.productBrandForm.controls['validFrom'].setErrors(null);
      this.isRequired = true;
    }
    else if (Fromdate == null && Todate == "Invalid Date" && !this.productBrandForm.controls.active) {
      this.productBrandForm.controls['validFrom'].setErrors(null);
    }
    else {
      this.productBrandForm.controls['validFrom'].setErrors({ 'invalidAutocomplete': { value: true } });
    }

    // adding special case condition when draft is checked and valid to and from are empty then update is enabled 
    if (this.productBrandForm.controls.active.value === false &&
      (Fromdate == null || Fromdate == "" || Fromdate == undefined || Fromdate == "Invalid Date") &&
      (Todate == null || Todate == "" || Todate == undefined || Todate == "Invalid Date")) {
      this.productBrandForm.controls['validFrom'].setErrors(null);
    }
  }
}
