import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ServiceApi } from '../../../services/ServiceApi';
import { Observable, Subject } from 'rxjs';

let headers = new HttpHeaders()
if (!headers.has('content-type')) {
  headers = headers.append('content-type', 'application/json')
}

@Injectable({
  providedIn: 'root'
})
export class CustomCustomerGroupService {

headers = new HttpHeaders().set('content-type','application/json').set('accept','application/json');
httpOptions ={
headers:this.headers
}

  constructor(private http: HttpClient,public _serviceApi: ServiceApi) { }

  // Observable string sources
  public departmentCustomCustomerGroupIdSource = new Subject<string>();

  // Observable string streams
  public departmentCustomCustomerGroupId$ = this.departmentCustomCustomerGroupIdSource.asObservable();

   // Service message commands
   getdepartmentCustomCustomerGroupIdFromList(mission: string) {
    this.departmentCustomCustomerGroupIdSource.next(mission);
  }

  // Observable string sources
 public refreshTable = new Subject<string>();
 // Observable string streams
 public refreshTableDepartmentCustomCustomerGroup$ = this.refreshTable.asObservable();

 // Service message commands
 refreshCustomCustomerGroupList() {
   this.refreshTable.next();
 }

 //Create Department Custom Customer Group
 createDepartmentCustomCustomerGroup(createDeptmentCustomCustomerGroup: any): Observable<any> {
   return this.http.post(this._serviceApi.DptCustomCustomerGroupCreate, JSON.stringify(createDeptmentCustomCustomerGroup), { 'headers': headers });
 }

 //Update Department Custom Customer Group
 updateDepartmentCustomCustomerGroup(updateDeptmentCustomCustomerGroup: any): Observable<any> {
  return this.http.put(this._serviceApi.DptCustomCustomerGroupUpdate, JSON.stringify(updateDeptmentCustomCustomerGroup), { 'headers': headers });
}

getDepartmentCustomCustomerGroupAll(){
  return this.http.get(this._serviceApi.DptCustomCustomerGroupGetAll)
}

 //Delete Department Custom Customer Group
 DeleteDepartmentCustomCustomerGroup(deleteDepartmenCustomCustomerGroup: any): Observable<any> {  
   
  return this.http.post(this._serviceApi.DptCustomCustomerGroupDelete, JSON.stringify(deleteDepartmenCustomCustomerGroup), { 'headers': headers });
}


}
