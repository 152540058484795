import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CustomCustomerGroupService } from 'src/app/services/organization/department/customcustomergroup.service';
import { DepartmentService } from 'src/app/services/organization/department/department.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { DialogBoxService } from 'src/app/shared/services/dialog-box.service';
import { AccountService } from '../../../../../shared/services/account.service'

export interface iDepartmentCustomCustomerGroupAll {
  DepartmentCustomCustomerGroupInfoId: any;
  departmentId: any;
  customCustomerGroupId: any;
  customCustomerGroupName: any;
  lastUpdatedBy: any;
}

@Component({
  selector: 'department-customcustomergrouplist',
  templateUrl: './customcustomergrouplist.component.html',
  styleUrls: ['./customcustomergrouplist.component.css']
})

export class DepartmentcustomcustomergrouplistComponent implements OnInit {

  @Output() departmentcall = new EventEmitter();
  @Input() depCustomCustomerGroup;
  @Input() parentDepartmentId;
  @Input() departmentDraft;
  @Input() customCustomerGrouplist;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatTable) table: MatTable<iDepartmentCustomCustomerGroupAll>;
  displayedColumns = ["customCustomerGroupName", "Actions"];
  subscription: Subscription;
  constructor(
    public _depCustomCustomerGroupService: CustomCustomerGroupService,
    public _departmentService: DepartmentService,
    public _dialogService: DialogBoxService,
    private toastr: ToastrService,
    public account: AccountService,
    private applicationInsightService: ApplicationInsightService) {
  }

  ngOnInit(): void {
  }


  receivedChildHandlerCCG() {
    this.departmentcall.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.BindCustomCustomerGroupData()
  }

  deleteCustomCustomerGroup(DepartmentCustomCustomerGroupInfoId: string) {
    this.applicationInsightService.logEvent("Department DeleteCustomCustomerGroup started");
    if (DepartmentCustomCustomerGroupInfoId != null && DepartmentCustomCustomerGroupInfoId != undefined && DepartmentCustomCustomerGroupInfoId != "" && DepartmentCustomCustomerGroupInfoId != "0") {
      this._dialogService.openConfirmDialog('Are you sure you want to delete this record?')
        .afterClosed().subscribe(res => {
          if (res) {
            this._depCustomCustomerGroupService.DeleteDepartmentCustomCustomerGroup({ "departmentCustomCustomerGroupInfoId": DepartmentCustomCustomerGroupInfoId, "lastUpdatedBy": this.account.userInfo.Email, "departmentId": this.parentDepartmentId, "departmentDraft": this.departmentDraft }).subscribe
              (data => {
                //this.childButtonEventCCG.emit();
                this.toastr.success(data.message, "", { timeOut: 10000 });
                this.departmentcall.emit();
                //this._depCustomCustomerGroupService.refreshCustomCustomerGroupList();
              },
                error => { this.toastr.error(error, "", { timeOut: 10000 }) });
          }
        })
    }
    this.applicationInsightService.logEvent("Department DeleteCustomCustomerGroup ended");
  }

  BindCustomCustomerGroupData() {
    this.applicationInsightService.logEvent("Department BindCustomCustomerGroupData started");
    this.dataSource = new MatTableDataSource(this.depCustomCustomerGroup);
    this.applicationInsightService.logEvent("Department BindCustomCustomerGroupData ended");
  }

}


