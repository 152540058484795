import { Component, OnInit, ViewChild, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router,ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomerGroupService } from 'src/app/services/organization/customerGroup/customer-group.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { customerGroupviewComponent } from './customergroupview.component';
import { ToastrService } from 'ngx-toastr';
import { ModulePermission } from '../../../../models/enum';
export interface getcustomergroupAll {
  corporateBrandName: any;
  customerGroupCode: any;
  customerGroupName: any;
  active: any;
  draft: any;
}
@Component({
  selector: 'app-customergrouplist',
  templateUrl: './customergrouplist.component.html',
  styleUrls: ['./customergrouplist.component.css']
})
export class CustomergrouplistComponent implements OnInit {   
    @Input() customerGroupSearch: any; 
    @Output() childButtonEvent = new EventEmitter();
    @ViewChild(MatPaginator, {static:false}) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatTable) table: MatTable<getcustomergroupAll>; 
    // MatPaginator Inputs
    pageNumber: any = 1;
    sortClick:boolean=false
    length: any;
    pageIndex:any;
    pageSize: any = 15;
    sortByColumnName: any = "customerGroupSortOrder";
    sortByOrder: any = "asc";
    pageEvent: PageEvent;
    Serviceactive: any = true;
    ServiceDraft: any = false;
    errorMsg: any;
    customDateTime: string;
    corporateBrandName: string;
    subscription: Subscription;
    emitter=1;
    public dataSource:MatTableDataSource<getcustomergroupAll>;
    displayedColumns = [
    'corporateBrandName',
    'customerGroupCode',
    'customerGroupName',
    'customerGroupSortOrder',
    'lastUpdatedTime',
    'Actions'
  ];
  matDialogRef: MatDialogRef<customerGroupviewComponent>;
  userrole: boolean;
  users: any;
constructor(
  public _customerGroup: CustomerGroupService,
  public route: ActivatedRoute,
  private toastr: ToastrService,
  public dialog: MatDialog,
  private applicationInsightService:ApplicationInsightService,
) { this.users = JSON.parse(localStorage.getItem("users") || "[]") } 
getpagesizeoptions(): number[] {
  if (this.length > 25)
    return [10, 20, 25, this.length];
  else if (this.length > 20)
    return [10, 20, this.length];
  else if (this.length > 10)
    return [10, this.length];
  return null;
}  
  ngOnInit() {
    this.userrole = this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this.subscription=this._customerGroup.refreshTableCustomerGroup$.subscribe(
      x => {
        this.emitter=1;
        this.BindCustomerGroupData();
      });
  }
  public ngOnDestroy(): void {
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }
  ngOnChanges(changes: SimpleChanges) {
    this.pageNumber = 1;
    this.BindCustomerGroupData();
  }
  sortData(sort: Sort) {
    this.applicationInsightService.logEvent("Sorting started");
    this.sortByColumnName = sort.active;
    this.sortByOrder = sort.direction;
    this.applicationInsightService.logEvent("Sorting ended");
    this.sortClick =true
    this.BindCustomerGroupData();
  }
  public GetcustomerGroupDatawithfilters(event?: PageEvent) {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.sortClick =true
    this.BindCustomerGroupData();
    return event;
  }
  BindCustomerGroupData() { 
    this.applicationInsightService.logEvent("Bind CustomerGroup started");
  let newcustomergroupList: any[] = [];
  let filterData:any[]=[]; 
   if (this.customerGroupSearch != null)
    {
    if (this.customerGroupSearch.status == "active") { this.Serviceactive = true; this.ServiceDraft = false; }
    if (this.customerGroupSearch.status == "inactive") { this.Serviceactive = false; this.ServiceDraft = false; }
    if (this.customerGroupSearch.status == "draft") { this.ServiceDraft = true; this.Serviceactive = ""; }
    if (this.customerGroupSearch.status == "all") { this.ServiceDraft = ""; this.Serviceactive = ""; }
    }
    // below code is for number plus string search
    var hasNumber = /\d/;
    if(!this.sortClick){
      if(isNaN(this.customerGroupSearch?.corporateBrandName)){
        if(hasNumber.test(this.customerGroupSearch?.corporateBrandName)){
          this.customerGroupSearch.corporateBrandCodeName = this.customerGroupSearch.corporateBrandName;
          this.customerGroupSearch.corporateBrandCode ='';
          this.customerGroupSearch.corporateBrandName='';
        }else{
        this.customerGroupSearch.corporateBrandName = this.customerGroupSearch.corporateBrandName;
        this.customerGroupSearch.corporateBrandCode ='';
        this.customerGroupSearch.corporateBrandCodeName ='';
        }
      }else{
        this.customerGroupSearch.corporateBrandCode = this.customerGroupSearch.corporateBrandName;
        this.customerGroupSearch.corporateBrandName='';
        this.customerGroupSearch.corporateBrandCodeName ='';
      }

      if(isNaN(this.customerGroupSearch?.customerGroupName)){
        if(hasNumber.test(this.customerGroupSearch.customerGroupName)){
          this.customerGroupSearch.customerGroupCodeName = this.customerGroupSearch.customerGroupName;
          this.customerGroupSearch.customerGroupCode ='';
          this.customerGroupSearch.customerGroupName='';
        }else{
          this.customerGroupSearch.customerGroupName = this.customerGroupSearch.customerGroupName;
          this.customerGroupSearch.customerGroupCode ='';
          this.customerGroupSearch.customerGroupCodeName='';
        }
      }else{
        this.customerGroupSearch.customerGroupCode = this.customerGroupSearch.customerGroupName;
        this.customerGroupSearch.customerGroupName='';
        this.customerGroupSearch.customerGroupCodeName='';
      }
    }
   
    this._customerGroup.getCustomerGroupAll().subscribe((data: any) => {
      this.applicationInsightService.logEvent("CustomerGroup listing and filter started");       
      newcustomergroupList = data.customerGroup
      if(newcustomergroupList != null)
      {
        if(this.customerGroupSearch.customerGroupCode === null){
          this.customerGroupSearch.customerGroupCode =''
        }
        filterData = newcustomergroupList.filter(c => c.corporateBrandName.toString().toLowerCase().includes(this.customerGroupSearch.corporateBrandName.toString().toLowerCase()))
        .filter(c => c.corporateBrandCode.toString().includes(this.customerGroupSearch.corporateBrandCode.toString()))
        .filter(c => c.corporateBrandCodeName.toString().includes(this.customerGroupSearch.corporateBrandCodeName.toString()))
        .filter(c => c.customerGroupCode.toString().includes(this.customerGroupSearch.customerGroupCode.toString()))
        .filter(c => c.customerGroupName.toLowerCase().includes(this.customerGroupSearch.customerGroupName.toLowerCase()))
        .filter(c => c.customerGroupCodeName.toString().includes(this.customerGroupSearch.customerGroupCodeName.toString()))
        .filter(c => c.active.toString().includes(this.Serviceactive.toString()))
        .filter(c => c.draft.toString().includes(this.ServiceDraft.toString()));        
      this.dataSource = new MatTableDataSource(filterData);
      this.sort.active = this.sortByColumnName;
      this.sort.direction = this.sortByOrder;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.length = this.dataSource.data.length;
      this.sortClick =false
      if(this.emitter==1)
      {
        this.emitter=2;
        this.childButtonEvent.emit(data.customerGroup);
      }     
    }
    this.applicationInsightService.logEvent("CustomerGroup listing and filter ended");  
  },
    error => {
      this.errorMsg = error;
    });
    this.applicationInsightService.logEvent("Bind CustomerGroup ended");
  }
//While clicking Edit button load respective data to entry page
updateRecord(updateRowData: any) {
  this._customerGroup.getCustomerGroupIDFromList(updateRowData.customerGroupId);
}

openDialog(row: any) {
  this.matDialogRef = this.dialog.open(customerGroupviewComponent, {
        data: { rowData:row },
        disableClose: false
      });
this.matDialogRef.afterClosed().subscribe(res => {
  if ((res == true)) {
    row = "";
  }
});
}
}
