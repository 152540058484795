import { AbstractControl, FormControl, ValidatorFn } from "@angular/forms";
export class CustomeDateValidator {
    isValidDate: boolean;
    error: { isError: boolean; errorMessage: string; };
    FromDate: any='';
    ToDate: any;
    FromControl: FormControl;
    ToControl: FormControl;
    public updateValidTo(row: any, formControl: any){
        let presentDate=formControl.controls.fromDate.value;
        var date = new Date(formControl.controls.fromDate.value);
        date.setDate(date.getDate() - 1);
        let index=row.length;
       if(row[index-1].toDate==undefined || row[index-1].toDate==null ||  row[index-1].toDate==''){
         if(presentDate>row[index-1].fromDate){
          row[index-1].toDate=date;
         }

       }
       return row;
      }
      fromDateValidator(): ValidatorFn {
      return (control: FormControl): {[key: string]: any} | null => {
          this.FromDate=control.value;
          this.FromControl=control;
          if (control.hasError('invalidDate') ) {
            const { invalidDate, ...errors } = control.errors;
            control.setErrors(errors);
            control.updateValueAndValidity();
          }
          if(this.ToControl!=null){
          if(this.ToControl.hasError('invalidDate') ) {
            const { invalidDate, ...errors } = this.ToControl.errors;
            this.ToControl.setErrors(errors);
            this.ToControl.updateValueAndValidity();
          }
        }
         
          if(this.ToDate!=null && this.ToDate!=''){
          return this.ToDate < this.FromDate 
          ? {invalidDate: 'Valid From should be less than Valid To' } 
          : null;
          }
          else
          return null;
        }
      } 
    
      toDateValidator(): ValidatorFn {
        return (control: FormControl): {[key: string]: any} | null => {
          this.ToDate=control.value;
          this.ToControl=control;
          if (control.hasError('invalidDate') ) {
            const { invalidDate, ...errors } = control.errors;
            control.setErrors(errors);
            control.updateValueAndValidity();
          }
          if(this.FromControl!=null){
          if(this.FromControl.hasError('invalidDate') ) {
            const { invalidDate, ...errors } = this.FromControl.errors;
            this.FromControl.setErrors(errors);
            this.FromControl.updateValueAndValidity();
          }
        }
        if(this.ToDate!=null && this.ToDate!=''){
          return this.ToDate < this.FromDate 
             ? {invalidDate: 'Valid To should be greater than Valid From' } 
             : null;
         }
         else
          return null;
        }
       }
     
}