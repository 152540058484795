<div class="wrap">
  <div class="filter-div">
    <div class="org-container">
      <form [formGroup]="searchform">
        <mat-form-field  appearance="legacy" class="customform">
          <mat-label>Corporate Brand</mat-label>
          <input type="text" id="Searchcorporatebrand" matInput [matAutocomplete]="autoCB"
                 formControlName="corporateBrandName" [(ngModel)]="corporateBrandName"
                 (ngModelChange)="filterItemCorporateBrand($event)">
          <mat-autocomplete autoActiveFirstOption #autoCB="matAutocomplete">
            <mat-option *ngFor="let CBname of corporateBrandFilterList" [value]="CBname.corporateBrandCodeName" [matTooltipDisabled]="CBname.corporateBrandName.length<8"  matTooltip="{{CBname.corporateBrandCode}}&nbsp;{{CBname.corporateBrandName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
              {{CBname.corporateBrandCode}} {{CBname.corporateBrandName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field  appearance="legacy" class="customform">
          <mat-label>Index Group</mat-label>
          <input type="text" id="Searchindexgroup" matInput [matAutocomplete]="autoIG"
                 formControlName="indexGroupName" [(ngModel)]="indexGroupName"
                 (ngModelChange)="filterItemIndexGroup($event)">
          <mat-autocomplete autoActiveFirstOption #autoIG="matAutocomplete">
            <mat-option *ngFor="let IGname of indexGroupFilterList" [value]="IGname.indexGroupCodeName" [matTooltipDisabled]="IGname.indexGroupName.length<8"  matTooltip="{{IGname.indexGroupCode}}&nbsp;{{IGname.indexGroupName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
              {{IGname.indexGroupCode}}  {{IGname.indexGroupName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field  appearance="legacy" class="customform">
          <mat-label>Index</mat-label>
          <input type="text" id="searchindexName" matInput [matAutocomplete]="autoIN"
                 formControlName="indexName" [(ngModel)]="indexName" (ngModelChange)="filterItemIN($event)">
          <mat-autocomplete autoActiveFirstOption #autoIN="matAutocomplete">
            <mat-option *ngFor="let indexName of indexNameFilterList" [value]="indexName.indexCodeName" [matTooltipDisabled]="indexName.indexName.length<8"  matTooltip="{{indexName.indexCode}}&nbsp;{{indexName.indexName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
              {{indexName.indexCode}} {{indexName.indexName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field  appearance="legacy" class="statusclass">
          <mat-label>Status</mat-label>
          <mat-select id="Searchstatus" formControlName="status" [(ngModel)]="status">
            <mat-option value="all">
              All
            </mat-option>
            <mat-option value="active">
              Active
            </mat-option>
            <mat-option value="inactive">
              Inactive
            </mat-option>
            <mat-option value="draft">
              Draft
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-button class="add-button-container mat-raised-button mat-primary ml-2" 
        id="searchClear" type="submit" (click)="onSearchClear()" >Clear</button>
      </form>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="tblleft">
    <app-indexlist (childButtonEvent)="indexCodeDropDownList($event)" [indexSearch]=searchform.value>
    </app-indexlist>
  </div>
  <div class="frmright">
    <app-indexform (childButtonEvent)="receivedChildHandler($event)"></app-indexform>
  </div>
</div>
