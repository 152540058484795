import { Component, OnInit, ViewChild } from '@angular/core';
import { GarmentgroupService } from 'src/app/services/organization/garmentgroup/garmentgroup.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DepartmentService } from 'src/app/services/organization/department/department.service';
import { DialogBoxService } from 'src/app/shared/services/dialog-box.service';
import { AccountService } from '../../../../shared/services/account.service'
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MultiselectComponent } from '../multiselect/multiselect.component';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { UserAccess } from 'src/app/models/userAccess';
import { ModulePermission } from 'src/app/models/enum';

export interface igarmentdepartment {
  departmentName: any;
  departmentCode: any;
  departmentId: any
}
@Component({
  selector: 'app-garmentgroupform',
  templateUrl: './garmentgroupform.component.html',
  styleUrls: ['./garmentgroupform.component.css']
})
export class GarmentgroupformComponent implements OnInit {
  @ViewChild('selectids') selectids: MatSelect;
  @ViewChild('multiselect') public multiselect: MultiselectComponent;
  rows: any;
  Department: any = {};
  updategarmentGroupForm: any;
  isgarmentGroupEdit: any = false;
  isPublished: boolean = true;
  isActiveDisable: boolean = true;
  isAddRow: boolean = false;
  isAddon: boolean = true;
  departmentFilterList: any[];
  departmentdepList: any;
  departmentMasterlist: any = [];
  editdepList: any = [];
  subscription: Subscription;
  dataSource: MatTableDataSource<igarmentdepartment>;
  displayedColumns: any;
  selected: any[] = [];
  
  multiselectenable:any=false;
  departmentName:any;
  departmentCode: any;
  userrole: boolean;
  users:any;
  constructor(
    public _garmentGroupService: GarmentgroupService,
    public _departmentService: DepartmentService,
    private toastr: ToastrService,
    public route: ActivatedRoute,
    public _dialogService: DialogBoxService,
    public account: AccountService,
    public applicationInsightService:ApplicationInsightService,
    public _userAccess: UserAccess
   ) {
    this.updategarmentGroupForm = Object.assign({ active: true, draft: true });
    this.rows = [];
    this.users = JSON.parse(localStorage.getItem("users") || "[]")
  }

  ngOnInit(): void {
    this.applicationInsightService.logEvent("Garmentgroup form Initialization started");
    this.userrole =this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this._garmentgroupform.controls.lastUpdatedBy.setValue(JSON.parse(localStorage.getItem("UserInfo")).Email || "[]");
    this.displayedColumns = ["department"];
    this.dataSource = new MatTableDataSource([]);
    this.departmentFilterList = [];
    this._garmentGroupService.getDepartmentByGarmentGroupId(0).subscribe((data: any) => {
      this.departmentdepList = this.departmentMasterlist = this.departmentFilterList = data.departmentGarmentGroup;
      if (this.departmentFilterList != null || undefined) {
        this.departmentFilterList = this.departmentFilterList.sort((a, b) => (a.departmentCode > b.departmentCode ? 1 : -1));
        }
        this.multiselectenable=true;
    });
    this.subscription =
      this._garmentGroupService.garmentGroupId$.subscribe(
        garmentGroupId => {
          this.updategarmentGroup(garmentGroupId);
        });

    this.fnSetValidation();
    this.applicationInsightService.logEvent("Garmentgroup form Initialization ended");
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }
  updategarmentGroup(garmentGroupId: any) {
    this.applicationInsightService.logEvent("Garmentgroup form update started");
    this.isgarmentGroupEdit = true;
    this.multiselect.selectedValues.length=0;
    this.isActiveDisable = false;
    this.isAddRow = true;
    this._garmentGroupService.getgarmentGroupId(garmentGroupId).subscribe((data: any) => {
      this.updategarmentGroupForm = data.garmentGroup;
    });
    this.editdepList = [];
    this._garmentGroupService.getDepartmentByGarmentGroupId(garmentGroupId).subscribe((data: any) => {
      this.editdepList = data.departmentGarmentGroup;
       if (this.editdepList != null && this.editdepList !=undefined) {
        this.dataSource = this.rows = this.editdepList.sort((a, b) => (a.departmentCode > b.departmentCode ? 1 : -1));
        this.selected = [];       
        for (var i = 0; i < this.editdepList.length; i++) {
          this.selected.push(this.editdepList[i].departmentId);
          this.multiselect.selectedValues.push(this.editdepList[i].departmentId);
        }
      }
      this.multiselect.editdeps=this.selected;
      this.multiselect.selectFormControl.setValue(this.multiselect.editdeps);
    });
    document.getElementById("scrolltop").scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    this.applicationInsightService.logEvent("Garmentgroup form update ended");
  }
  onAddRow() {
    this.applicationInsightService.logEvent("Garmentgroup Add new row from multiselect started");
    //this.multiselect.selectedValues
    this.isAddRow = true;
    this.isAddon = false;
    this._garmentgroupform.markAsDirty();
    if(this.multiselect.selectedValues !=null){
      for (var i = 0; i < this.multiselect.selectedValues.length; i++){
        if (!this.rows.some((item) => item.departmentId == this.multiselect.selectedValues[i])) {
          this.departmentCode=this.displayDepartmentcode(this.multiselect.selectedValues[i]);
          this.departmentName=this.displayDepartmentname(this.multiselect.selectedValues[i]);
          this.rows.push(this.createItemFormGroup(this.multiselect.selectedValues[i],this.departmentCode,this.departmentName));
        }
      }
    }
    this.rows =  this.rows.sort((a, b) => (a.departmentCode > b.departmentCode ? 1 : -1));
    this.dataSource = new MatTableDataSource(this.rows);
    this.applicationInsightService.logEvent("Garmentgroup Add new row from multiselect ended");
  }
   displayDepartmentname(departmentId: string) {
    this.applicationInsightService.logEvent("Garmentgroup display departmentname started");
    if (departmentId == null || departmentId == "")
      return null;
    else if (this.departmentFilterList != undefined && departmentId != null)
      return this.departmentFilterList.find(x => x.departmentId === departmentId).departmentName;
      this.applicationInsightService.logEvent("Garmentgroup display departmentname ended");
  }
  displayDepartmentcode(departmentId: string) {
    this.applicationInsightService.logEvent("Garmentgroup display departmentcode started");
    if (departmentId == null || departmentId == "")
      return null;
    else if (this.departmentFilterList != undefined && departmentId != null)
      return this.departmentFilterList.find(x => x.departmentId === departmentId).departmentCode;
      this.applicationInsightService.logEvent("Garmentgroup display departmentcode ended");
  }
 
  createItemFormGroup(id: number, code: any, name: string) {
   return {
      departmentId: id,
      departmentCode: code,
      departmentName: name
    };
  }
  onSubmit() {
    this.applicationInsightService.logEvent("Garmentgroup formsubmit started");
    if (this._garmentgroupform.controls.garmentGroupName.value != undefined && this._garmentgroupform.controls.garmentGroupName.value != null && this._garmentgroupform.controls.garmentGroupName.value != '') { this._garmentgroupform.controls.garmentGroupName.setValue(this._garmentgroupform.controls.garmentGroupName.value.trim()) }
    this._garmentgroupform.controls.departmentId.setValue(this.multiselect.selectedValues.toString());
    if (this._garmentgroupform.controls.draft.value === undefined || this._garmentgroupform.controls.draft.value === null) { this._garmentgroupform.controls.draft.setValue(true); }
    this._garmentgroupform.controls['garmentGroupSortOrder'].updateValueAndValidity();
    this._garmentgroupform.controls['garmentGroupName'].updateValueAndValidity();
    console.log("Garment Group Save: " + JSON.stringify(this._garmentgroupform.value))
    if (this._garmentgroupform.valid) {
      if (this.updategarmentGroupForm.garmentGroupId == undefined || this.updategarmentGroupForm.garmentGroupId == 0 || this.updategarmentGroupForm.garmentGroupId == null) {
        this._garmentGroupService.CreateGarmentGroup(this._garmentgroupform.value).subscribe(response => {
          this.toastr.success(response.message, "", { timeOut: 10000 });
          this.onClear();
          this._garmentGroupService.refreshGarmentGroupList();

        },
          error => { this.toastr.error(error, "", { timeOut: 10000 }) }
        )
      }
      else {
        this._garmentgroupform.controls.garmentGroupId.setValue(this.updategarmentGroupForm.garmentGroupId);
        this._garmentGroupService.UpdateGarmentGroup(this._garmentgroupform.value).subscribe(response => {
          this.toastr.success(response.message, "", { timeOut: 10000 });
          this.onClear();
          this._garmentGroupService.refreshGarmentGroupList();
        },
          error => { this.toastr.error(error, "", { timeOut: 10000 }) }
        )
      }
    }
    this.applicationInsightService.logEvent("Garmentgroup formsubmit ended");
  }

  onClear() {
    this.applicationInsightService.logEvent("Garmentgroup formclear started");
    this._garmentgroupform.controls.garmentGroupSortOrder.clearValidators();
    this._garmentgroupform.controls.garmentGroupName.clearValidators();
    //Clearing control values 
    this._garmentgroupform.controls.draft.setValue(true);
    this._garmentgroupform.controls.active.setValue(true);
    this._garmentgroupform.controls.garmentGroupId.reset();
    this._garmentgroupform.controls.departmentId.reset();
    this._garmentgroupform.controls.garmentGroupCode.reset();
    this._garmentgroupform.controls.garmentGroupSortOrder.reset();
    this._garmentgroupform.controls.garmentGroupName.reset();
    this._garmentgroupform.controls.garmentGroupSortOrder.reset();
    this.isgarmentGroupEdit = false;
    this.isActiveDisable = true;
    this.isAddRow = false;
    this.isAddon = true;
    this.rows = [];
    this.selected = [];
    this.dataSource = new MatTableDataSource([]);
    this.editdepList = null;
    this.multiselect.editdeps=[];
    this.multiselect.selectedValues=[];
    if(this.multiselect.selectFormControl!=undefined){
      this.multiselect.selectFormControl.reset();
    }
    this.fnSetValidation();
    this.applicationInsightService.logEvent("Garmentgroup formclear ended");
  }

  _garmentgroupform: FormGroup = new FormGroup({
    draft: new FormControl(true),
    active: new FormControl(true),
    garmentGroupCode: new FormControl(''),
    garmentGroupName: new FormControl('', [Validators.required, Validators.maxLength(250)]),
    garmentGroupSortOrder: new FormControl('', [Validators.required, Validators.min(1), Validators.max(9999), Validators.pattern('^(?![-]).*')]),
    garmentGroupId: new FormControl(''),
    lastUpdatedBy: new FormControl(),
    departmentId: new FormControl(''),
   });

  fnSetValidation() {
    this._garmentgroupform.controls.garmentGroupName.setValidators([Validators.required, Validators.min(1), Validators.maxLength(250)]);
    this._garmentgroupform.controls.garmentGroupSortOrder.setValidators([Validators.required, Validators.min(1), Validators.max(9999), Validators.pattern('^(?![-]).*')]);
  }


}
