<div class="wrap">
  <div class="filter-div">
    <div class="org-container">
      <form [formGroup]="_searchform">
        <mat-form-field appearance="legacy" class="customform">
          <mat-label>ISO Code</mat-label>
          <input type="text" id="SearchISOCode" matInput [matAutocomplete]="autoISOCode"
            formControlName="ISOCode" [(ngModel)]="ISOCode"
            (ngModelChange)="filterItemByISOCode($event)">
          <mat-autocomplete autoActiveFirstOption #autoISOCode="matAutocomplete">
            <mat-option *ngFor="let CBname of corporateBrandFilterList" [value]="CBname.corporateBrandName" [matTooltipDisabled]="CBname.corporateBrandName.length<8"  matTooltip={{CBname.corporateBrandName}} matTooltipPosition="left" matTooltipClass="my-custom-tooltip">         
              {{CBname.corporateBrandName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="customform">
          <mat-label>Name</mat-label>
          <input type="text" id="SearchcurrencyName" matInput [matAutocomplete]="autocurrencyName"
            formControlName="currencyName" [(ngModel)]="currencyName"
            (ngModelChange)="filterItemBycurrencyName($event)">
          <mat-autocomplete autoActiveFirstOption #autocurrencyName="matAutocomplete">
            <mat-option *ngFor="let CBname of corporateBrandFilterList" [value]="CBname.corporateBrandName" [matTooltipDisabled]="CBname.corporateBrandName.length<8"  matTooltip={{CBname.corporateBrandName}} matTooltipPosition="left" matTooltipClass="my-custom-tooltip">         
              {{CBname.corporateBrandName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="customform">
          <mat-label>HM Currency</mat-label>
          <input type="text" id="SearchHMCurrency" matInput [matAutocomplete]="autoHMCurrency"
            formControlName="HMCurrency" [(ngModel)]="HMCurrency"
            (ngModelChange)="filterItemByHMCurrency($event)">
          <mat-autocomplete autoActiveFirstOption #autoHMCurrency="matAutocomplete">
            <mat-option *ngFor="let CBname of corporateBrandFilterList" [value]="CBname.corporateBrandName" [matTooltipDisabled]="CBname.corporateBrandName.length<8"  matTooltip={{CBname.corporateBrandName}} matTooltipPosition="left" matTooltipClass="my-custom-tooltip">         
              {{CBname.corporateBrandName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="customform">
          <mat-label>Status</mat-label>
          <mat-select id="Searchstatus" formControlName="status" [(ngModel)]="status">
            <mat-option value="all">
              All
            </mat-option>
            <mat-option value="active">
              Active
            </mat-option>
            <mat-option value="inactive">
              Inactive
            </mat-option>
            <mat-option value="draft">
              Draft
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-icon (click)="onSearchClear()" id="SearchClear" class="custombutton" color="primary" matTooltip="Clear"
          matTooltipPosition="right" style="background-color: rgb(255 255 255 / 0%);cursor: pointer;"><span
            class="material-icons">
            clear
          </span></mat-icon>
      </form>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="tblleft">
    <app-currencylist #corporatebandlist (childButtonEvent)="receivedChildHandler($event)"
      [corporateBrandSearch]="_searchform.value"></app-currencylist>
  </div>
  <div class="frmright">
    <app-currencyform></app-currencyform>
  </div>
</div>