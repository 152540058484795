import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, of, Subject } from 'rxjs';
import { ServiceApi } from '../../services/ServiceApi';
  let headers = new HttpHeaders()
  if (!headers.has('content-type'))
  {
      headers=headers.append('content-type','application/json')
  }
  @Injectable({
  providedIn: 'root'
  })
export class CurrencyService {

  constructor(
    private http: HttpClient,
    public _serviceApi: ServiceApi
  ) { }

  headers = new HttpHeaders().set('content-type','application/json').set('accept','application/json');
  httpOptions ={
      headers:this.headers
  }
   
  //Observable string sources
    public currencyIdSource = new Subject<string>();
  //Observable string streams
    public currencyId$ = this.currencyIdSource.asObservable();
    getCurrencyIdFromList(mission: string)
    {
      this.currencyIdSource.next(mission);
    }
  //Observable string sources
    public refreshTable = new Subject<string>();
  //Observable string streams
    public refreshTableCurrency$ = this.refreshTable.asObservable();
  // Service message commands
  refreshCurrencyList()
  {
    this.refreshTable.next();
  }

 //List Currency from Service
 getCurrencyList(): Observable<any> 
 {   
  const sampleData = [{
    "currency": [
      {
        "currencyId": 62,
        "isoCode": 1646,
        "symbol": "$",
        "currencyName": "Fine Knitwear",
        "hmCurrency": 1,
        "sellCurrency":1,
        "buyCurrency":1,
        "lastUpdatedBy": "2022-11-24T08:56:53.763",
        "currencySortOrder":"1",
        "active": true,
        "draft": false,
        "isPublished":true
    },
    {
      "currencyId": 62,
      "isoCode": 1647,
      "symbol": "#",
      "currencyName": "test 63",
      "hmCurrency": 1,
      "sellCurrency":1,
      "buyCurrency":1,
      "lastUpdatedBy": "2022-10-24T08:56:53.763",
      "currencySortOrder":"2",
      "active": true,
      "draft": false,
      "isPublished":true
  },
  {
    "currencyId": 62,
    "isoCode": 1648,
    "symbol": "$",
    "currencyName": "test 64",
    "hmCurrency": 1,
    "sellCurrency":1,
    "buyCurrency":1,
    "lastUpdatedBy": "2022-12-24T08:56:53.763",
    "currencySortOrder":"3",
    "active": true,
    "draft": false,
    "isPublished":true
},
{
  "currencyId": 62,
  "isoCode": 1649,
  "symbol": "$",
  "currencyName": "test 65",
  "hmCurrency": 1,
  "sellCurrency":1,
  "buyCurrency":1,
  "lastUpdatedBy": "2022-11-25T08:56:53.763",
  "currencySortOrder":"4",
  "active": true,
  "draft": false,
  "isPublished":true
},
{
  "currencyId": 62,
  "isoCode": 1650,
  "symbol": "$",
  "currencyName": "test 66",
  "hmCurrency": 1,
  "sellCurrency":1,
  "buyCurrency":1,
  "lastUpdatedBy": "2022-11-27T08:56:53.763",
  "currencySortOrder":"5",
  "active": true,
  "draft": false,
  "isPublished":true
},
{
  "currencyId": 62,
  "isoCode": 1646,
  "symbol": "$",
  "currencyName": "Fine Knitwear",
  "hmCurrency": 1,
  "sellCurrency":1,
  "buyCurrency":1,
  "lastUpdatedBy": "2022-11-24T08:56:53.763",
  "currencySortOrder":"1",
  "active": true,
  "draft": false,
  "isPublished":true
},
{
  "currencyId": 62,
  "isoCode": 1646,
  "symbol": "$",
  "currencyName": "Fine Knitwear",
  "hmCurrency": 1,
  "sellCurrency":1,
  "buyCurrency":1,
  "lastUpdatedBy": "2022-11-24T08:56:53.763",
  "currencySortOrder":"1",
  "active": true,
  "draft": false,
  "isPublished":true
},
{
  "currencyId": 62,
  "isoCode": 1646,
  "symbol": "$",
  "currencyName": "Fine Knitwear",
  "hmCurrency": 1,
  "sellCurrency":1,
  "buyCurrency":1,
  "lastUpdatedBy": "2022-11-24T08:56:53.763",
  "currencySortOrder":"1",
  "active": true,
  "draft": false,
  "isPublished":true
},
{
  "currencyId": 62,
  "isoCode": 1646,
  "symbol": "$",
  "currencyName": "Fine Knitwear",
  "hmCurrency": 1,
  "sellCurrency":1,
  "buyCurrency":1,
  "lastUpdatedBy": "2022-11-24T08:56:53.763",
  "currencySortOrder":"1",
  "active": true,
  "draft": false,
  "isPublished":true
},
{
  "currencyId": 62,
  "isoCode": 1646,
  "symbol": "$",
  "currencyName": "Fine Knitwear",
  "hmCurrency": 1,
  "sellCurrency":1,
  "buyCurrency":1,
  "lastUpdatedBy": "2022-11-24T08:56:53.763",
  "currencySortOrder":"1",
  "active": true,
  "draft": false,
  "isPublished":true
},
{
  "currencyId": 62,
  "isoCode": 1646,
  "symbol": "$",
  "currencyName": "Fine Knitwear",
  "hmCurrency": 1,
  "sellCurrency":1,
  "buyCurrency":1,
  "lastUpdatedBy": "2022-11-24T08:56:53.763",
  "currencySortOrder":"1",
  "active": true,
  "draft": false,
  "isPublished":true
},
{
  "currencyId": 62,
  "isoCode": 1646,
  "symbol": "$",
  "currencyName": "Fine Knitwear",
  "hmCurrency": 1,
  "sellCurrency":1,
  "buyCurrency":1,
  "lastUpdatedBy": "2022-11-24T08:56:53.763",
  "currencySortOrder":"1",
  "active": true,
  "draft": false,
  "isPublished":true
},
{
  "currencyId": 62,
  "isoCode": 1646,
  "symbol": "$",
  "currencyName": "Fine Knitwear",
  "hmCurrency": 1,
  "sellCurrency":1,
  "buyCurrency":1,
  "lastUpdatedBy": "2022-11-24T08:56:53.763",
  "currencySortOrder":"1",
  "active": true,
  "draft": false,
  "isPublished":true
},
{
  "currencyId": 62,
  "isoCode": 1646,
  "symbol": "$",
  "currencyName": "Fine Knitwear",
  "hmCurrency": 1,
  "sellCurrency":1,
  "buyCurrency":1,
  "lastUpdatedBy": "2022-11-24T08:56:53.763",
  "currencySortOrder":"1",
  "active": true,
  "draft": false,
  "isPublished":true
},
{
  "currencyId": 62,
  "isoCode": 1646,
  "symbol": "$",
  "currencyName": "Fine Knitwear",
  "hmCurrency": 1,
  "sellCurrency":1,
  "buyCurrency":1,
  "lastUpdatedBy": "2022-11-24T08:56:53.763",
  "currencySortOrder":"1",
  "active": true,
  "draft": false,
  "isPublished":true
},
{
  "currencyId": 62,
  "isoCode": 1646,
  "symbol": "$",
  "currencyName": "Fine Knitwear",
  "hmCurrency": 1,
  "sellCurrency":1,
  "buyCurrency":1,
  "lastUpdatedBy": "2022-11-24T08:56:53.763",
  "currencySortOrder":"1",
  "active": true,
  "draft": false,
  "isPublished":true
},
{
  "currencyId": 62,
  "isoCode": 1646,
  "symbol": "$",
  "currencyName": "Fine Knitwear",
  "hmCurrency": 1,
  "sellCurrency":1,
  "buyCurrency":1,
  "lastUpdatedBy": "2022-11-24T08:56:53.763",
  "currencySortOrder":"1",
  "active": true,
  "draft": false,
  "isPublished":true
}
    ],
    "totalCount": 1,
    "totalPage": 1
  }]
   return of({sampleData})    
 }
 getCurrencyById(currencyId:number): Observable<any> {

  return of({ 
    data:   
    {
      "currencyId": 62,
      "isoCode": 1646,
      "symbol": "$",
      "currencyName": "Fine Knitwear",
      "hmCurrency": 1,
      "sellCurrency":1,
      "buyCurrency":1,
      "lastUpdatedBy": "2022-11-24T08:56:53.763",
      "currencySortOrder":"1",
      "active": true,
      "draft": false,
      "isPublished":true
      }
    });
     
} 

 
}
