import { Component, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { BehaviorSubject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ProductbrandService } from 'src/app/services/organization/productbrand/productbrand.service';
import { Ab2departmentsService } from 'src/app/services/organization/ab2departments/ab2departments.service';
import { HttpClient } from '@angular/common/http';
import { DepartmentService } from 'src/app/services/organization/department/department.service';
import { CorporatebrandService } from 'src/app/services/organization/corporatebrand/corporatebrand.service';
import { CommonUtilityService } from 'src/app/services/organization/commonutility/commonutility.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { ModulePermission } from 'src/app/models/enum';

/**
 * Node for to-do item
 */
export class DepartmentNode {
  children?: DepartmentNode[];
  item: string;
}

interface CorporateBrandNameList {
  corporateBrandId: any;
  corporateBrandName: any;
  corporateBrandCodeName: any;
}

/** Flat to-do item node with expandable and level information */
export class DepartmentFlatNode {
  item: string;
  level: number;
  expandable: boolean;
}
export class getDepCollections {
  corporateBrandId: any = null;
  startSeason: any = null;
  endSeason: any = null;
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable({ providedIn: 'root' })
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<DepartmentNode[]>([]);
  treeData: any[];
  dataList: any[];
  recivedData: any;
  copIdWhenChange: any;
  getDep = new getDepCollections();
  get data(): DepartmentNode[] {
    return this.dataChange.value;
  }

  constructor(
    private http: HttpClient,
    private productbrandService: ProductbrandService,
    private ab2departmentsService: Ab2departmentsService,
    private _mdr: MatDialogRef<Ab2departmentformComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    _mdr.disableClose = true;
    this.recivedData = data?.rowData?.data;
    //this.initialize();
  }

  getAllNodes(array: any) {
    let arr = [];
    let finalArr = [];
    array.forEach((item: any) => {
      let childArr = [];
      item.section.forEach((children: any) => {
        if (children?.department?.length) {
          let childArr2 = [];
          children.department.forEach((ele) => {
            childArr2.push({
              item:
                ele?.departmentCodeAndName +
                ',' +
                ele?.departmentId +
                ',' +
                ele?.startSeason +
                ',' +
                ele?.endSeason,
            });
          });
          childArr.push({
            item: children?.sectionCodeAndName,
            children: childArr2,
          });
        }
      });
      arr.push({ item: item?.divisionCodeAndName, children: childArr });
    });

    arr.forEach((a) => {
      if (a.children.length) {
        finalArr.push(a);
      }
    });

    return finalArr;
  }

  initialize(getCollection?: any) {
    this.treeData = [];
    this.dataList = [];
    this.copIdWhenChange = getCollection.corporateBrandId;
    if (
      getCollection.corporateBrandId !== null &&
      getCollection.corporateBrandId !== '' &&
      getCollection.corporateBrandId !== undefined &&
      getCollection.startSeason !== null &&
      getCollection.startSeason !== '' &&
      getCollection.startSeason !== undefined
    ) {
      this.productbrandService
        .getGetProductBrandDepartmentsAndSectionsAndDivisions(getCollection)
        .subscribe((data) => {
          this.dataList = this.getAllNodes(data.division);
          const TREE_DATA: DepartmentNode[] = this.dataList;
          this.treeData = TREE_DATA;
          const datan = TREE_DATA;
          this.dataChange.next(datan);
        });
    } else {
      const TREE_DATA: DepartmentNode[] = [];
      this.treeData = TREE_DATA;
      const datan = TREE_DATA;
      this.dataChange.next(datan);
    }
  }

  public filter(filterText: string) {
    let filteredTreeData: any;
    if (filterText) {
      // Filter the tree
      function filter(array, text) {
        const getChildren = (result, object) => {
          var re = new RegExp(text, 'gi');
          if (object?.item?.match(re)) {
            result.push(object);
            return result;
          }
          if (Array.isArray(object.children)) {
            const children = object.children.reduce(getChildren, []);
            if (children.length) result.push({ ...object, children });
          }
          return result;
        };

        return array.reduce(getChildren, []);
      }

      filteredTreeData = filter(this.treeData, filterText);
    } else {
      // Return the initial tree
      filteredTreeData = this.treeData;
    }

    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    // file node as children.
    const dataMain = filteredTreeData;
    // Notify the change.
    this.dataChange.next(dataMain);
  }
}
@Component({
  selector: 'app-ab2departmentform',
  templateUrl: './ab2departmentform.component.html',
  styleUrls: ['./ab2departmentform.component.css'],
  providers: [ChecklistDatabase],
})
export class Ab2departmentformComponent {
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<DepartmentFlatNode, DepartmentNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<DepartmentNode, DepartmentFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: DepartmentFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<DepartmentFlatNode>;

  treeFlattener: MatTreeFlattener<DepartmentNode, DepartmentFlatNode>;

  dataSource: MatTreeFlatDataSource<DepartmentNode, DepartmentFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<DepartmentFlatNode>(
    true /* multiple */
  );
  selectedDepartments: any[];
  connectedDepartments: any[];
  /// Filtering
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];

  filteredOptions: Observable<string[]>;
  searchTextboxControl = new FormControl();
  public searchString = '';
  abDepartmentsData: any;
  validationMessages: any;
  eventfromView: boolean = false;
  updateDepartId: any = [];
  corporateBrandMasterCGList: any[] = [];
  corporateBrandFilterCGList: any[] = [];
  startSeasonMasterlist: any[] = [];
  startSeasonFilterList: any[] = [];
  ddCorporateBrandNameCGList: CorporateBrandNameList[];
  checkReadOnly: boolean = false;
  covertedFromdate: any;
  covertedTodate: any;
  isdropdownChange: boolean = false;
  userrole: boolean;
  users: any;
  AtleastOneCheck: boolean = false;
  activeCheck: boolean = false;
  FirstDarf: boolean = false;
  draftCheckdisabled: boolean = false;
  isCBselected: boolean = true;
  isRequired: boolean = true;
  getDep = new getDepCollections();
  dStartSeason: any;
  dEndSeason: any;
  pStartSeason: any;
  editScreenDisable:boolean=false;
  pEndSeason: any;
  depdisable: boolean = false;
  displayHedingmessage: string = '';
  myspinner:boolean=false;
  constructor(
    private _database: ChecklistDatabase,
    public loaderService: LoaderService,
    private productbrandService: ProductbrandService,
    private ab2departmentsService: Ab2departmentsService,
    private _mdr: MatDialogRef<Ab2departmentformComponent>,
    public _departmentService: DepartmentService,
    public _commonutilityService: CommonUtilityService,
    private _corporatebrandService: CorporatebrandService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    
    if (data?.msg === 'viewHit') {
      this.myspinner=true
      this.eventfromView = true;
      this.displayHedingmessage = 'View';
    } else if (data?.msg === 'createHit') {
      this.displayHedingmessage = 'Create';
      this.eventfromView = false;
    } else {
      this.editScreenDisable=true
      this.myspinner=true
      this.displayHedingmessage = 'Update';
    }
    this.validationMessages = this._commonutilityService.validationMsgs;
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<DepartmentFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
    _database.dataChange.subscribe((dataMain) => {
      this.dataSource.data = dataMain;
      if (dataMain.length != 0) {
        this.isCBselected = true;
      } else {
        this.isCBselected = false;
      }
  
      if (data?.msg !== 'createHit') {
        this.ab2departmentsService
          .GetAb2DepartmentsById(this._database?.recivedData?.abDepartmentId)
          .subscribe((data) => {
            if (data) {
              
              this.abDepartmentsData = data?.abDepartments;
              if (
                this.abDepartmentForm.controls.startSeason?.value ===
                this.abDepartmentsData[0]?.seasonNumber
              ) {
                this.checkByEdit(data?.abDepartments);
              }
              
              // setting the form values
              if (!this.isdropdownChange) {
                setTimeout(() => {
                  this.abDepartmentForm.controls.corporateBrandName.setValue(
                    this.abDepartmentsData[0]?.corporateBrandId);
                  this.abDepartmentForm.controls.startSeason?.setValue(
                    this.abDepartmentsData[0]?.seasonNumber
                  );
                  this.abDepartmentForm.controls.active?.setValue(
                    this.abDepartmentsData[0]?.active
                  );
                  this.abDepartmentForm.controls.draft?.setValue(
                    this.abDepartmentsData[0]?.draft
                  );
                  this.getStartSeasonValues();
                }, 500);
                
              }
            }
          });
      }

      this._corporatebrandService
        .getCorporateBrandList()
        .subscribe((data: any) => {
          this.ddCorporateBrandNameCGList =
            this.corporateBrandMasterCGList =
            this.corporateBrandFilterCGList =
              data.getCorporateBrandAll.filter((object) => {
                return object['active'] !== false;
              });
          this.corporateBrandFilterCGList =
            this.corporateBrandMasterCGList.sort((a, b) =>
              a.corporateBrandCode.localeCompare(b.corporateBrandCode)
            );
        });

      this._departmentService
        .getDepartmentSeasonByRange(1)
        .subscribe((data: any) => {
          this.startSeasonMasterlist = this.startSeasonFilterList =
            data.seasonByRange;
          this.startSeasonMasterlist = this.startSeasonMasterlist.sort((a, b) =>
            a?.seasonNumber < b?.seasonNumber ? 1 : -1
          );
        });
    });
    this.users = JSON.parse(localStorage.getItem('users') || '[]');
  }
  ngOnInit() {
    this.userrole = this.users.includes(ModulePermission.Organization_Write)
      ? true
      : false;
  }

  getCbDropdownValues(cbvalues?) {
    // added test
    this.clearTree();
    this.getDep.corporateBrandId = cbvalues === null ? null : cbvalues;
    this.getDep.startSeason = this.abDepartmentForm.controls.startSeason?.value;
    this._database.initialize(this.getDep);
    this.isdropdownChange = true;
  }
  getStartSeasonValues(seasonval?) {
    this.clearTree();
    this.getDep.corporateBrandId =
      this.abDepartmentForm.controls.corporateBrandName?.value === null
        ? null
        : this.abDepartmentForm.controls.corporateBrandName.value === ''
        ? null
        : this.abDepartmentForm.controls.corporateBrandName.value == undefined
        ? null
        : this.abDepartmentForm.controls.corporateBrandName.value;
    this.getDep.startSeason =
      seasonval === null || seasonval == undefined
        ? this.abDepartmentForm.controls.startSeason?.value
        : seasonval;
    this._database.initialize(this.getDep);
    this.isdropdownChange = true;
  }

  getLevel = (node: DepartmentFlatNode) => node.level;

  isExpandable = (node: DepartmentFlatNode) => node.expandable;

  getChildren = (node: DepartmentNode): DepartmentNode[] => node.children;

  hasChild = (_: number, _nodeData: DepartmentFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: DepartmentFlatNode) =>
    _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: DepartmentNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item
        ? existingNode
        : new DepartmentFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: DepartmentFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );

    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: DepartmentFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: DepartmentFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
    this.abDepartmentForm.markAsDirty();
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: DepartmentFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: DepartmentFlatNode): void {
    let parent: DepartmentFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
    this.abDepartmentForm.controls.allDpt.setValue(false);
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: DepartmentFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
    this.abDepartmentForm.markAsDirty();
  }

  checkAll() {
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      this.checklistSelection.deselect(this.treeControl.dataNodes[i]);
      this.treeControl.collapse(this.treeControl.dataNodes[i]);
      if (
        !this.checklistSelection.isSelected(this.treeControl.dataNodes[i]) &&
        this.abDepartmentForm.controls.allDpt.value == true
      ) {
        this.checklistSelection.toggle(this.treeControl.dataNodes[i]);
        this.treeControl.expand(this.treeControl.dataNodes[i]);
      } else {
        this.checklistSelection.deselect(this.treeControl.dataNodes[i]);
        this.treeControl.collapse(this.treeControl.dataNodes[i]);
      }
    }
  }
  /* Get the parent node of a node */
  getParentNode(node: DepartmentFlatNode): DepartmentFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  displayTextCB(corporateBrandId: string) {
    if (corporateBrandId === null || corporateBrandId === '') return null;
    else if (
      this.ddCorporateBrandNameCGList != undefined &&
      corporateBrandId != null
    )
      return this.ddCorporateBrandNameCGList.find(
        (x) => x.corporateBrandId == corporateBrandId
      ).corporateBrandCodeName;
  }

  filterItemCorporateBrand(filterKey: any) {
    if (filterKey === '') {
      this.getCbDropdownValues();
    }
    let newCBList: any[] = [];
    newCBList = this.corporateBrandMasterCGList;
    if (filterKey != null && filterKey != '') {
      this.corporateBrandFilterCGList = newCBList.filter((cb) =>
        cb.corporateBrandCodeName
          .toLowerCase()
          .includes(filterKey.toString().toLowerCase())
      );
      this.corporateBrandFilterCGList = this.corporateBrandFilterCGList.sort(
        (a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode)
      );
    } else
      this.corporateBrandFilterCGList = this.corporateBrandMasterCGList.sort(
        (a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode)
      );
    if (filterKey === '') {
      this.clearTree();
    }
  }

  displayTextSeason(season: string) {
    if (season == null || season == '' || season.includes('99')) return null;
    else if (this.startSeasonMasterlist != undefined && season != null)
      return this.startSeasonMasterlist?.find((x) => x?.seasonNumber === season)
        ?.displayName;
  }
  filterStartSeason(filterKeySS: string) {
    if (filterKeySS === '') {
      this.getStartSeasonValues();
    }
    // this.applicationInsightService.logEvent("Department form StartSeason filter started");
    if (filterKeySS != null && filterKeySS != '') {
      this.startSeasonFilterList = this.startSeasonMasterlist.filter((rs) =>
        rs.displayName
          .toLowerCase()
          .includes(filterKeySS.toString().toLowerCase())
      );
      // this.fnSetControlsValidation();
    } else {
      this.startSeasonFilterList = this.startSeasonMasterlist;
    }
    // this.applicationInsightService.logEvent("Department form StartSeason filter ended");
  }

  getSelectedItems(): string {
    let connectDepId: any;
    this.updateDepartId = [];
    if (!this.checklistSelection?.selected?.length) {
      return 'Connect Departments';
    } else {
      for (let i = 0; i < this.checklistSelection?.selected?.length; i++) {
        if (this.checklistSelection.selected[i].level == 2) {
          connectDepId = this.checklistSelection?.selected[i]?.item
            ?.split(',')
            .slice(-3)
            .reverse()
            .pop();
          if (this.updateDepartId?.indexOf(connectDepId) == -1) {
            this.updateDepartId?.push(connectDepId);
          }
        }
      }
      return this.checklistSelection?.selected
        ?.filter((s) => s.level != 0)
        ?.map((s) => s.item?.split(',')[0])
        ?.join(',');
    }
  }

  filterChanged(filterText: string) {
    // ChecklistDatabase.filter method which actually filters the tree and gives back a tree structure
    // this._database.filter(filterText);
    if (filterText) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
  }
  cancel() {
    this._mdr.close(false);
    this._database.recivedData = '';
  }
  onSubmit(reqType) {
    if (reqType === 'Create') {
      this.abDepartmentForm.value.department = this.updateDepartId?.map(Number);
      this.createDeapartment(this.abDepartmentForm);
    } else {
      this.abDepartmentForm.value.department = this.updateDepartId?.map(Number);
      this.updateDepartment(this.abDepartmentForm);
    }
  }

  createDeapartment(formdata) {
    if (formdata) {
      let createPayload = {
        seasonNumber: formdata?.value?.startSeason,
        corporateBrandId: formdata?.value?.corporateBrandName,
        active:
          formdata?.value?.active === '' ? false : formdata?.value?.active,
        draft: formdata?.value?.draft === '' ? false : formdata?.value?.draft,
        lastUpdatedBy: JSON.parse(localStorage.getItem('UserInfo')).Email,
        departments: formdata?.value?.department,
      };
      this.ab2departmentsService.CreateAb2Departments(createPayload).subscribe(
        (res) => {
          this._mdr.close(false);
          this.toastr.success(res.message, '', { timeOut: 10000 });
          this.ab2departmentsService.resfreshCall.next(true);
        },
        (error) => {
          this.toastr.error(error.substr(44).replaceAll("\"}", ""), '', { timeOut: 10000 });
        }
      );
    }
  }

  updateDepartment(formdata) {
    if (formdata.value.corporateBrandName === undefined) {
      formdata.value.corporateBrandName = null;
    }

    let updatePayload = {
        abDepartmentId:this.abDepartmentsData[0]?.abDepartmentId ,
        corporateBrandId:formdata?.value?.corporateBrandName ,
        seasonNumber:formdata?.value?.startSeason,
        active:formdata?.value?.active,
        draft:formdata?.value?.draft,
        lastUpdatedBy:JSON.parse(localStorage.getItem('UserInfo')).Email,
        departments: formdata?.value?.department
    };

    this.ab2departmentsService.UpdateAb2Departments(updatePayload).subscribe(
      (res) => {
        this._mdr.close(false);
        this.toastr.success(res.message, '', { timeOut: 10000 });
        this.ab2departmentsService.resfreshCall.next(true);
      },
      (error) => {
        this.toastr.error(error, '', { timeOut: 10000 });
      }
    );
  }

  clearTree() {
    this.checklistSelection.clear();
    this.getSelectedItems();
    this.searchTextboxControl.patchValue('');
  }
  checkByEdit(connectedDepartments: any[]) {
    this.clearTree();
    let actualvalue: any = '';
    let exactvalue: any = '';
    let actualselectedId: any = '';
    let depId: any = '';
    if (connectedDepartments?.length != 0) {
      for (let i = 0; i < connectedDepartments?.length; i++) {
        for (let j = 0; j < connectedDepartments[i].departments?.length; j++) {
          depId = connectedDepartments[i]?.departments[j]?.departmentCode;
          this.selectedDepartments = depId;
          for (let i = 0; i < this.selectedDepartments?.length; i++) {
            for (let j = 0; j < this.dataSource?.data?.length; j++) {
              for (
                let k = 0;
                k < this.dataSource?.data[j]?.children?.length;
                k++
              ) {
                for (
                  let p = 0;
                  p < this.dataSource.data[j].children[k].children.length;
                  p++
                ) {
                  let str1 = this.selectedDepartments.toString();
                  let str2 =
                    this.dataSource.data[j].children[k].children[p].item.split(
                      ' '
                    )[0];
                  if (str2 == str1) {
                    actualvalue =
                      this.dataSource.data[j].children[k].children[
                        p
                      ].item.split(' ')[1];
                    for (
                      let q = 0;
                      q < this.treeControl.dataNodes.length;
                      q++
                    ) {
                      exactvalue =
                        this.treeControl.dataNodes[q].item.split(' ')[1];
                      let str3 =
                        this.treeControl.dataNodes[q].item.split(' ')[0];
                      if (actualvalue == exactvalue && str3 == str1) {
                        actualselectedId = [q];
                        if (
                          !this.checklistSelection.isSelected(
                            this.treeControl.dataNodes[actualselectedId]
                          )
                        ) {
                          this.checklistSelection.toggle(
                            this.treeControl.dataNodes[actualselectedId]
                          );
                          this.treeControl.expand(
                            this.treeControl.dataNodes[actualselectedId]
                          );
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    this.myspinner=false
  }
  // search filter logic start
  filterLeafNode(node: DepartmentFlatNode): boolean {
    if (!this.searchString) {
      return false;
    }
    return (
      node.item
        .split(',')[0]
        .toLowerCase()
        .indexOf(this.searchString?.toLowerCase()) === -1
    );
  }

  filterParentNode(node: DepartmentFlatNode): boolean {
    if (
      !this.searchString ||
      node.item
        .split(',')[0]
        .toLowerCase()
        .indexOf(this.searchString?.toLowerCase()) !== -1
    ) {
      return false;
    }
    const descendants = this.treeControl.getDescendants(node);

    if (
      descendants.some(
        (descendantNode) =>
          descendantNode.item
            .split(',')[0]
            .toLowerCase()
            .indexOf(this.searchString?.toLowerCase()) !== -1
      )
    ) {
      return false;
    }

    return true;
  }

  //handle active draf checkbox event
  ActiveCheck(active) {
    if (active.checked) {
      this.abDepartmentForm.controls.draft.setValue(false);
    }
  }

  DraftCheck(draft) {
    if (draft.checked) {
      this.abDepartmentForm.controls.active.setValue(false);
    }
  }

  /**
   * Clearing search textbox value
   */
  clearSearch(event: any) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
    this.treeControl.collapseAll();
  }

  isDepartmentEnable(node: any) {
    this.depdisable = false;
    this.dStartSeason = node.item.split(',')[2];
    this.dEndSeason = node.item.split(',')[3];
    this.pStartSeason = this.abDepartmentForm.controls.startSeason?.value;

    if (this.dEndSeason < this.pEndSeason) {
      this.depdisable = true;
    } else if (this.dStartSeason > this.pStartSeason) {
      this.depdisable = true;
    }
    return this.depdisable;
  }
  validPattern = '^[a-zA-Z0-9]+$'; // alphanumeric exact 0 letters
  abDepartmentForm: FormGroup = new FormGroup({
    corporateBrandName: new FormControl('', [Validators.required]),
    department: new FormControl(''),
    startSeason: new FormControl('', [Validators.required]),
    active: new FormControl('', [Validators.required]),
    draft: new FormControl(true,[Validators.required]),
    lastUpdatedBy: new FormControl(),
    allDpt: new FormControl(false),
  });
}
