import { Component, Inject, OnInit } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { UserInfo } from '../app/models/UserInfo'
import { AccountService } from './shared/services/account.service';
import { EnvService } from './shared/services/env.service';
import { ApplicationInsightService } from './shared/services/application-insight.service';
import { CommonUtilityService } from './services/organization/commonutility/commonutility.service';
import { state } from '@angular/animations';
import { UserAccess } from "./models/userAccess"
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'SDS APP';
  isIframe = false;
  loginDisplay = false;
  userInfo: UserInfo;
  RunEnvironment: string;
  loggedInUser: any;
  ismenuEnable: any = [];
  upn: any;
  externalId: any;
  userRole: any;
  endDate: Date;
  currentDate: Date;
  private readonly _destroying$ = new Subject<void>();
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private broadcastService: MsalBroadcastService,
    private authService: MsalService, private account: AccountService, private envservice: EnvService, private applicationInsightService: ApplicationInsightService, public _commonutilityService: CommonUtilityService, public _userAccess: UserAccess) {
  }
  ngOnInit() {
    console.log('app component init');
    this.isIframe = window !== window.parent && !window.opener;
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
       if (this.authService.instance.getAllAccounts()) {
        if (localStorage.getItem("UserInfo") != null) {
          this.userInfo = JSON.parse(localStorage.getItem("UserInfo"));
          this.loggedInUser = this.userInfo.Email;
          this.getUserInfo( this.loggedInUser);
        }
        this.setLoginDisplay();
        }
        else {
          this.login();
        }
      })
      this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        this.upn = this.authService.instance.getAllAccounts()[0]?.username;
        this._userAccess.setuserId = this.upn;
        this.getUserInfo(this.upn);
       
       });
  }
  getUserInfo(upn:any) {
    this._commonutilityService.getUserInfo(upn).subscribe((data: any) => {
       this._userAccess.setuserId= upn;
       for (let i = 0; i < data.functions.length; i++) {
         this.externalId = data.functions[i].externalId;
         this.endDate = data.functions[i].endDate;
         if (!this.ismenuEnable.includes(data.functions[i].externalId)) {//checking whether array contain the value
           if(this.endDate==null){
             this.ismenuEnable.push(data.functions[i].externalId);
           }
           else{
             this.currentDate = new Date();
             this.endDate = new Date(this.endDate);
             if (this.endDate > this.currentDate) {
               this.ismenuEnable.push(data.functions[i].externalId);//adding to array because value doesnt exists
             }
           }
         }
       }
       //storing the user access key into sessions 
       localStorage.setItem("users", JSON.stringify(this.ismenuEnable));
       this._userAccess.setismenuEnable = this.ismenuEnable;
       this._userAccess.loggedInUser = this.loggedInUser;
     });
   }

  login() {
  if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);

    } else {

      this.authService.loginRedirect();
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}

