<div class="data-table" class="org-table">
    <table id="currencyTable" [dataSource]="dataSource" mat-table class="full-width-table" matSort
      (matSortChange)="sortData($event)">
      <!-- IsoCode Column -->
      <ng-container matColumnDef="isoCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="isoCode">ISO Code</th>
        <td mat-cell *matCellDef="let row" id="custom-col150"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
          {{row.isoCode}}
        </td>
      </ng-container>
      <!-- currency currencyName Column -->
      <ng-container matColumnDef="currencyName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="currencyName">currencyName</th>
        <td mat-cell *matCellDef="let row" 
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')"
          [matTooltipDisabled]="row.isdisableCB" matTooltip={{row.currencyName}} matTooltipPosition="above"
          matTooltipClass="tooltip">
          {{row.currencyName | slice:0:28}}<span *ngIf="!row.isdisableCB">...</span></td>
      </ng-container>
      <!-- symbol currencyName Column -->
      <ng-container matColumnDef="symbol">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="symbol">symbol</th>
        <td mat-cell *matCellDef="let row" 
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')"
          >
          {{row.symbol}}</td>
      </ng-container>
      <!-- HMcurrency currencyName Column -->
      <ng-container matColumnDef="hmCurrency">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="hmCurrency">HM Currency</th>
        <td mat-cell *matCellDef="let row" 
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')"
          >
          {{row.hmCurrency}}</td>
      </ng-container>
      <!-- Sort Column -->
      <ng-container matColumnDef="currencySortOrder">
        <th matSort (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header
          id="currencySortOrder">Sort Order</th>
        <td mat-cell *matCellDef="let row"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
          {{row.currencySortOrder}}
        </td>
      </ng-container>
      <!-- LastUpdate Column -->
      <ng-container matColumnDef="lastUpdatedBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="lastUpdatedBy">Updated date</th>
        <td mat-cell *matCellDef="let row"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
          <span *ngIf="!customDateTime">{{ row.lastUpdatedBy | customDateTime }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef id="Actions"><span *ngIf="userrole">Edit | </span>View</th>
        <td mat-cell *matCellDef="let row" id="custom-col100"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
          <a id="btnEdit" *ngIf="userrole" color="primary" (click)="updateRecord(row)" matTooltip="Edit" matTooltipPosition="right"
            class="org-edit">Edit | </a>
          <a id="btnView" (click)="openDialog(row)" matTooltip="View Details" matTooltipPosition="right"
            class="org-edit">View</a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell aligncenter" colspan="8">No records found</td>
      </tr>
    </table>
    <div id="main-wrap">
      <section id="sidebar">
        <div class="example-button-row">
          <div class="example-flex-container indicator">
            <button mat-icon-button aria-label="Datatable color code">
              <mat-icon><span class="material-icons activeindicator">
                  stop
                </span></mat-icon>
              Active
            </button>
            <button mat-icon-button aria-label="Datatable color code">
              <mat-icon><span class="material-icons inactiveindicator">
                  stop
                </span></mat-icon>
              Inactive
            </button>
            <button mat-icon-button aria-label="Datatable color code">
              <mat-icon><span class="material-icons draftindicator">
                  stop
                </span></mat-icon>
              Draft
            </button>
            <button mat-icon-button aria-label="Datatable color code">
              <mat-icon><span class="material-icons firstdraftindicator">
                  stop
                </span></mat-icon>
              First Draft
            </button>
          </div>
        </div>
      </section>
      <mat-paginator #paginator id="tblPaginator" [(length)]="length" [(pageSize)]="pageSize"
        [pageSizeOptions]="getpagesizeoptions()" (page)="pageEvent = $event" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
