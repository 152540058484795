<div class="mat-elevation-z8 add-form-field">
  <form [formGroup]="_channelform" id="channelform">
    <div class="example-container">
      <h2 mat-dialog-title>{{isChannelEdit === true ? 'Update' : 'New'}} Channel</h2>
      <mat-form-field [floatLabel]="'always'" appearance='outline' class="width-50">
        <mat-label>Channel </mat-label>
        <input formControlName="channelName" id=inpChannelName [(ngModel)]="updateChannelForm.channelName"
               matInput>
        <mat-error *ngIf="_channelform.controls['channelName'].errors?.maxlength">
          Maximum 30 Characters
          allowed
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" [floatLabel]="'always'" class="width-45">
        <mat-label>Sort Order</mat-label>
        <input formControlName="channelSortOrder" id=inpChannelsortorder type="number" min="1"
               [(ngModel)]="updateChannelForm.channelSortOrder" matInput type="number">
        <mat-error *ngIf="_channelform.controls['channelSortOrder'].errors?.max">
          The range for sort order is
          1-9999.
        </mat-error>
      </mat-form-field>
      <section class="example-section">
        <mat-checkbox formControlName="active" id=inpchannelactive [disabled]="isDisableActive"
                      [(ngModel)]="updateChannelForm.active" class="example-margin width-15" color='primary'>
          Active
        </mat-checkbox>
      </section>
      <br>
      <section class="example-section">
        <mat-checkbox formControlName="draft" id=inpchanneldraft [(ngModel)]="updateChannelForm.draft"
                      class="example-margin width-15" color='primary'>Draft</mat-checkbox>
      </section>
      <input type="hidden" id=inpchannelid formControlName="channelId" [(ngModel)]="updateChannelForm.channelId">
      <br>
      <mat-dialog-actions *ngIf="chuserrole">
        <button [disabled]="(isChannelEdit === true) ?  !_channelform.dirty : false"
                [ngClass]="(isChannelEdit === true) ? 'mat-raised-button ml-1':'mat-raised-button ml-1 mat-primary'"
                id="btnSaveUpdate" type="submit" (click)="onSubmit()">
          {{isChannelEdit === true ? 'Update' :'Save'}}
        </button>
        <button class="mat-raised-button mat-primary ml-1" id="btnclear" (click)="onClear()">Cancel</button>
      </mat-dialog-actions>
      <span *ngIf="!chuserrole" style="color: #ff0202;font-size: 12px;font-weight: 400;">You can't save changes since you have Read access.</span>
    </div>
  </form>
</div>
