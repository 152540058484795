<div class="mat-elevation-z8 add-form-field">
    <form [formGroup]="_fiscalcountryForm">
      <div class="example-container">
        <h2 mat-dialog-title>{{isFiscalcountryEdit === true ? 'Update' : 'New'}} Fiscal Country</h2>

        <mat-form-field [floatLabel]="'always'" [appearance]="isFiscalcountryEditCN" class="width-40">
          <mat-label>Country Name</mat-label>
          <input type="text" id=inpcountryname matInput [matAutocomplete]="autoCountryName" formControlName="geographicalCountryId"
                 (ngModelChange)="filterItemCountryName($event)" [(ngModel)]="update_fiscalcountryForm.geographicalCountryId"
                 [readonly]="isFiscalcountryEditCN=='fill' ?true:false">
          <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
            <div *ngIf="_fiscalcountryForm.controls['geographicalCountryId'].hasError(validation.type)">
              {{validation.message}}
            </div>
          </mat-error>
          <mat-error *ngIf="_fiscalcountryForm.controls['geographicalCountryId'].errors?.required"></mat-error>
          <mat-autocomplete autoActiveFirstOption #autoCountryName="matAutocomplete" [displayWith]="displayTextCN.bind(this)">
            <mat-option *ngFor="let Cname of countryNameFilterList" [value]="Cname.geographicalCountryId">
              {{Cname.geographicalCountryName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field [floatLabel]="'always'" appearance="fill" class="width-25  ml-2">
          <mat-label> ISO Code </mat-label>
          <input id="inpisocode" matInput [readonly]=true formControlName="iSOCode" [(ngModel)]="update_fiscalcountryForm.iSOCode">
        </mat-form-field>
        <input type="hidden" id=inpfiscalcountryid formControlName="fiscalCountryId" [(ngModel)]="update_fiscalcountryForm.fiscalCountryId">
        <mat-form-field [floatLabel]="'always'" appearance="outline" class="width-25  ml-2">
          <mat-label> Fiscal Country Code </mat-label>
          <input id="inpfccode" matInput formControlName="fiscalCountryCode" [(ngModel)]="update_fiscalcountryForm.fiscalCountryCode">
          <mat-error *ngIf="_fiscalcountryForm.controls['fiscalCountryCode'].errors?.maxlength">Please enter a vaild input</mat-error>
          <mat-error *ngIf="_fiscalcountryForm.controls['fiscalCountryCode'].errors?.minlength">Please enter a vaild input</mat-error>
        </mat-form-field> <br>
        <mat-checkbox formControlName="active" [disabled]="!isFiscalcountryEdit" [(ngModel)]="update_fiscalcountryForm.active"
                      id="inpactive" class="example-margin width-20 ml-1" color='primary'>Active</mat-checkbox>
        <mat-checkbox formControlName="draft" id=inpdraft [(ngModel)]="update_fiscalcountryForm.draft"
                      class="example-margin width-12" color='primary'>Draft</mat-checkbox>


        <input type="hidden" id="fiscalCountryId" formControlName="fiscalCountryId" [(ngModel)]="update_fiscalcountryForm.fiscalCountryId">
        <br>
        <div class="tab" id="tab" *ngIf=fcSubTab>
          <button id="currenciesTab" [ngClass]="{'tabActive' : currenciesTab==true}"
                  (click)="loadTabComponentOnClck('FC-Currencies')">
            Currencies
          </button>
          <button id="vatTab" [ngClass]="{'tabActive' : vatTab==true}"
                  (click)="loadTabComponentOnClck('FC-Vat')">
            VAT
          </button>
          <button id="vatExceptionsTab" [ngClass]="{'tabActive' : vatExceptionsTab==true}"
                  (click)="loadTabComponentOnClck('FC-VatExceptions')">
            VAT Exceptions
          </button>
          <button id="gbcTab" [ngClass]="{'tabActive' : gbcTab==true}"
                  (click)="loadTabComponentOnClck('FC-Gbc')">
            GBC Cost
          </button>
        </div>

        <app-currency [style.display]="currenciesTab ? 'block' : 'none'" #validateCurrencyChildComponentForm (isChildFormValid)="isChildFormValid($event)" id="currencylist">
        </app-currency>
        <app-vat [style.display]="vatTab ? 'block' : 'none'" #validateVatChildComponentForm (isVatFormValid)="isChildVatFormValid($event)" id="vatlist">
        </app-vat>
        <app-vatexception [style.display]="vatExceptionsTab ? 'block' : 'none'" #validateVatExChildComponentForm (isVatExFormValid)="isChildVatExFormValid($event)" id="vatExceptionslist">
        </app-vatexception>
        <app-gbccost [style.display]="gbcTab ? 'block' : 'none'" #GBCCostChildComponent (isGBCCostChildFormValid)="isGBCCostChildFormValid($event)" id="gbclist">
        </app-gbccost>
        <br>
        <mat-dialog-actions *ngIf="userrole">
          <button [ngClass]="(isFiscalcountryEdit === true) ? 'mat-raised-button ml-1':'mat-raised-button ml-1 mat-primary'"
                  id="inpbtnsubmit" type="submit"
                  (click)="onSubmit()">
            {{isFiscalcountryEdit === true ? 'Update' : 'Save'}}
          </button>
          <button class="mat-raised-button mat-primary  ml-2" id="inpbtncancel" (click)="onClear()">Cancel</button>
        </mat-dialog-actions>
        <span *ngIf="!userrole" style="color: #ff0202;font-size: 12px;font-weight: 400;">You can't save changes since you have Read access.</span>
      </div>
    </form>
  </div>
  <style>
  ::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex {
    border-radius: 4px 4px 0 0;
    padding: 0.00em 1.10em 0.20em;
}
::ng-deep .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color:#673ab7 !important;
}
  </style>
