<div class="wrap">
  <div class="filter-div">
    <div class="org-container">
      <form [formGroup]="searchform">
        <mat-form-field appearance="legacy" class="customform">
          <mat-label>Corporate Brand</mat-label>
          <input type="text" id="searchCorporateBrand" matInput [matAutocomplete]="autoCB"
            formControlName="corporateBrandId" [(ngModel)]="corporateBrandId"
            (ngModelChange)="filterItemCorporateBrand($event)">
          <mat-autocomplete autoActiveFirstOption #autoCB="matAutocomplete">
            <mat-option *ngFor="let CBname of corporateBrandFilterL" [value]="CBname.corporateBrandCodeName"
              [matTooltipDisabled]="CBname.corporateBrandName.length<8" matTooltip="{{CBname.corporateBrandCode}}&nbsp;{{CBname.corporateBrandName}}"
              matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
              {{CBname.corporateBrandCode}} {{CBname.corporateBrandName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="customform">
          <mat-label>Index </mat-label>
          <input type="text" id="searchIndexCode" matInput [matAutocomplete]="autoIC" formControlName="indexCode"
            [(ngModel)]="indexCode" (ngModelChange)="filterItemIC($event)">
          <mat-autocomplete autoActiveFirstOption #autoIC="matAutocomplete">
              <mat-option *ngFor="let ICname of IndexCodeFilterList" [value]="ICname.indexCodeName">
              {{ICname.indexCode}} {{ICname.indexName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="customform">
          <mat-label>Sub Index</mat-label>
          <input type="text" id="searchsubIndexName" matInput [matAutocomplete]="autosubIN"
            formControlName="subIndexName" [(ngModel)]="subIndexName" (ngModelChange)="filterItemsubIN($event)">
          <mat-autocomplete autoActiveFirstOption #autosubIN="matAutocomplete">
            <mat-option *ngFor="let subIndex of subIndexNameFilterList" [value]="subIndex.subIndexCodeName"
              [matTooltipDisabled]="subIndex.subIndexName.length<8" matTooltip="{{subIndex.subIndexCode}}&nbsp;{{subIndex.subIndexName}}"
              matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
              {{subIndex.subIndexCode}} {{subIndex.subIndexName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="statusclass">
          <mat-label>Status</mat-label>
          <mat-select id="searchStatus" formControlName="status" [(ngModel)]="status">
            <mat-option value="all">
              All
            </mat-option>
            <mat-option value="active">
              Active
            </mat-option>
            <mat-option value="inactive">
              Inactive
            </mat-option>
            <mat-option value="draft">
              Draft
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-button class="add-button-container mat-raised-button mat-primary ml-2" 
        id="searchClear" type="submit" (click)="onSearchClear()" >Clear</button>
      </form>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="tblleft">
    <app-subindexlist [corporateBrandSearch]="corporateBrandId" [subIndexCodeSearch]="subIndexCode"
      [subIndexNameSearch]="subIndexName" [statusSearch]="status" [IndexCodeSearch]="indexCode"></app-subindexlist>
  </div>
  <div class="frmright">
    <app-subindexform (childButtonEvent)="receivedChildHandler($event)"></app-subindexform>
  </div>
</div>