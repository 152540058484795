import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, Subject } from 'rxjs';
import { ServiceApi } from '../../../services/ServiceApi';
let headers = new HttpHeaders()
if (!headers.has('content-type')) {
  headers = headers.append('content-type', 'application/json')
}

@Injectable({
  providedIn: 'root'
})
export class DivisionService {

  constructor(private http: HttpClient, public _serviceApi: ServiceApi) {
  }

  headers = new HttpHeaders().set('content-type', 'application/json').set('accept', 'application/json');
  httpOptions = {
    headers: this.headers
  }
  // Observable string sources
  public refreshTable = new Subject<string>();
  // Observable string streams
  public refreshTabledivision$ = this.refreshTable.asObservable();
  // Service message commands
  refreshDivisionList()
  {
    this.refreshTable.next();
  }
  // Observable string sources
  public divisionIdSource = new Subject<string>();
  // Observable string streams
  public divisionId$ = this.divisionIdSource.asObservable();
  getdivisionIDFromList(mission: string)
  {
    this.divisionIdSource.next(mission);
  }
  //Get All Divisions
  getDivisionAll(): Observable<any>
  {
    return this.http.get(this._serviceApi.DivisionGetAll);
  }
  //List of Divisions from Service
  getDivisionList(): Observable<any>
  {
    return this.http.get(this._serviceApi.DivisionGetAll);
  }
  //Create the new division
  createDivision(createNewDivision: any): Observable<any>
  {
    return this.http.post(this._serviceApi.DivisionCreate, JSON.stringify(createNewDivision), { 'headers': headers });
  }
  //Update the exisiting division
  updateDivision(updateDivision: any): Observable<any>
  {
    return this.http.put(this._serviceApi.DivisionUpdate, JSON.stringify(updateDivision), { 'headers': headers });
  }
  //Get Division By Id
  getDivisionById(divisionId: any): Observable<any>
  {
    return this.http.get(this._serviceApi.DivisionGetByID + divisionId)
  }

}
