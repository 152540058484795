
   
  <div class="mat-elevation-z8 data-table subtable"> 
     <table id="tblCurrency" [dataSource]="dataSource" #table mat-table class="full-width-table" matSort aria-label="Elements">
        <ng-container matColumnDef="currencyCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-color" id="currencyname">Currency</th>
          <td mat-cell *matCellDef="let row"  [ngClass]="row.isFirstDraft==true ? 'FiscalDraftBg' : 'ActiveBg'">
            {{row.currencyCode}}
          </td>
        </ng-container>
        <ng-container matColumnDef="fromSeasonNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-color" id="fromSeasonNumber">From Season</th>
          <td mat-cell *matCellDef="let row" [ngClass]="row.isFirstDraft==true ? 'FiscalDraftBg' : 'ActiveBg'">{{row.fromSeasonNumber | seasonDate }}</td>
        </ng-container>
        <ng-container matColumnDef="toSeasonNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-color" id="toSeasonNumber">End Season</th>
          <td mat-cell *matCellDef="let row" [ngClass]="row.isFirstDraft==true ? 'FiscalDraftBg' : 'ActiveBg'">{{row.toSeasonNumber| seasonDate }}</td>
        </ng-container>
        <ng-container matColumnDef="Edit">
          <th mat-header-cell *matHeaderCellDef class="custom-color" id="edit">Edit</th>
          <td mat-cell *matCellDef="let row" [ngClass]="row.isFirstDraft==true ? 'FiscalDraftBg' : 'ActiveBg'">
            <mat-icon color="primary" (click)="updateRecord(row)"
                      style="background-color: rgb(255 255 255 / 0%);cursor: pointer;">drive_file_rename_outline</mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="Delete">
          <th mat-header-cell *matHeaderCellDef class="custom-color" id="delete">Delete</th>
          <td mat-cell *matCellDef="let row" [ngClass]="row.isFirstDraft==true ? 'FiscalDraftBg' : 'ActiveBg'">
            <mat-icon  [ngClass]="(row.isDeleteFcCurrency === true) ? 'isdeletetrue':'isdeletefalse'" (click)="row.isDeleteFcCurrency ? onRemoveRow(row) :''" matTooltip="Delete" matTooltipPosition="right"
                      style="cursor: pointer;">delete_outline</mat-icon>                      
          </td>
        </ng-container>
       
       <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="8" style="text-align: center;">No records found</td>
        </tr>
      </table>
      <div id="main-wrap">
        <section style="background-color: #00558d;color:#fff">
      <div class="example-button-row">
        <div class="example-flex-container">
          <button mat-icon-button style="width:25%;" aria-label="Datatable color code">

            <mat-icon>
              <span class="material-icons"
                    style="color:#fff; text-shadow: 0px 1px 0 #000, 1px 1px 0 #000, 1px 1px 0 #000, 1px 1px 0 #000;">
                stop
              </span>
            </mat-icon>
            Saved
          </button>
          <button mat-icon-button style="width:27%;" aria-label="Datatable color code">
             <mat-icon>
              <span class="material-icons"
                    style="color: #d7bd5e;text-shadow: 0px 1px 0 #000, 1px 1px 0 #000, 1px 1px 0 #000, 1px 1px 0 #000;">
                stop
              </span>
            </mat-icon>
            Unsaved
          </button>
        </div>
      </div>
        </section>
      </div>
    </div>
      <!-- Form Section -->
  <div class="mat-elevation-z8 add-form-field">
    <form [formGroup]="_fccurrenciesForm">
      <input type="number" hidden formControlName="fiscalCountryCurrencyId" [(ngModel)]="updatecurrencyForm.fiscalCountryCurrencyId">
      <input type="number" hidden formControlName="tempRowIndex" [(ngModel)]="updatecurrencyForm.tempRowIndex">
      <mat-form-field [floatLabel]="'always'" appearance="outline" class="width-30">
        <mat-label>Currency</mat-label>
        <input type="text" id=inpcurrency matInput [matAutocomplete]="autoCurrency" formControlName="currencyCode"
               (ngModelChange)="filterItemCurrencyName($event)" [(ngModel)]="updatecurrencyForm.currencyCode">
        <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
          <div *ngIf="_fccurrenciesForm.controls['currencyCode'].hasError(validation.type)">
            {{validation.message}}
          </div>
        </mat-error>
        <mat-error *ngIf="_fccurrenciesForm.controls['currencyCode'].errors?.required"></mat-error>
        <mat-autocomplete autoActiveFirstOption #autoCurrency="matAutocomplete" [displayWith]="displayTextCN.bind(this)">
          <mat-option *ngFor="let Cname of currencyFilterList" [value]="Cname.currencyCode">
            {{Cname.currencyName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field [floatLabel]="'always'" appearance="outline" class="width-30 ml-2">
        <mat-label>From Season</mat-label>
        <input type="text" matInput [matAutocomplete]="autoRSS" formControlName="fromSeasonNumber"
               (ngModelChange)="filterStartSeason($event)" [(ngModel)]="updatecurrencyForm.fromSeasonNumber"
               id="inpcurfromseason">
        <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
          <div *ngIf="_fccurrenciesForm.controls['fromSeasonNumber'].hasError(validation.type)" style="margin-right: 50px;">
            {{validation.message}}
          </div>
        </mat-error>
        <mat-autocomplete autoActiveFirstOption #autoRSS="matAutocomplete"
                          [displayWith]="displayTextSeason.bind(this)">
          <mat-option *ngFor="let season of fromSeasonFilterList" [value]="season.seasonNumber">
            {{season.displayName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field [floatLabel]="'always'" appearance="outline" class="width-30 ml-2">
        <mat-label>End Season</mat-label>
        <input type="text" matInput [matAutocomplete]="autoES" formControlName="toSeasonNumber"
               (ngModelChange)="filterendSeason($event)" [(ngModel)]="updatecurrencyForm.toSeasonNumber"
               id="inpcurendseason">
        <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
          <div *ngIf="_fccurrenciesForm.controls['toSeasonNumber'].hasError(validation.type)" style="margin-right: 50px;">
            {{validation.message}}
          </div>
        </mat-error>
        <mat-autocomplete autoActiveFirstOption #autoES="matAutocomplete"
                          [displayWith]="displayTextSeason.bind(this)">
          <mat-option *ngFor="let season of endSeasonFilterList" [value]="season.seasonNumber">
            {{season.displayName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <br>
      <mat-dialog-actions *ngIf="!userrole">
        <button mat-raised-button id="inpbtnsavecurrency" class="add-button-container mat-raised-button mat-primary" (click)="onAddRow()">Add</button>

        <button mat-raised-button id="inpbtnclearcurrency" class="add-button-container mat-raised-button mat-primary ml-2" (click)="onClear()">Clear</button>
      </mat-dialog-actions>
      <span *ngIf="userrole" style="color: #ff0202;font-size: 12px;font-weight: 400;">You can't save changes since you have Read access.</span>
    </form>
</div>
    
 
