import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog'

@Component({
  selector: 'app-mat-confirm-dialog-box',
  templateUrl: './mat-confirm-dialog-box.component.html',
  styleUrls: ['./mat-confirm-dialog-box.component.css']
})
export class MatConfirmDialogBoxComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data:any,
  public dialogRef:MatDialogRef<MatConfirmDialogBoxComponent>
  ) { }

  ngOnInit(): void {
  }

  closeDialog(){
    this.dialogRef.close(false);
  }
}
