<section class="titlebg">
    <h2 mat-dialog-title class="align">{{eventfromView?"View":"Update"}} Product Brand</h2>
</section>
<form [formGroup]="productBrandForm">
    <div mat-dialog-content>
        <mat-form-field [appearance]="eventfromView || checkReadOnly===true ? 'fill': 'legacy'">
            <mat-label>Corporate Brand</mat-label>
            <input type="text" [readonly]="eventfromView || checkReadOnly" matInput [matAutocomplete]="autoCB"
                formControlName="corporateBrandName" (ngModelChange)="filterItemCorporateBrand($event)">
            <mat-autocomplete autoActiveFirstOption #autoCB="matAutocomplete" [displayWith]="displayTextCB.bind(this)">
                <ng-container *ngIf="!checkReadOnly">
                    <mat-option *ngFor="let CBname of corporateBrandFilterCGList" [value]="CBname.corporateBrandId">
                        {{CBname.corporateBrandCode}} {{CBname.corporateBrandName}}
                    </mat-option>
                </ng-container>
            </mat-autocomplete>
        </mat-form-field>
         <ng-container *ngIf="eventfromView">
            <mat-form-field [appearance]="eventfromView===true ? 'fill': 'legacy'">
                <mat-label>Valid From</mat-label>
                <input type="text" [readonly]="eventfromView" matInput [value]="productBrandForm.get('validFrom').value"
                    formControlName="validFrom">
            </mat-form-field>
            <mat-form-field [appearance]="eventfromView===true ? 'fill': 'legacy'">
                <mat-label>Valid To</mat-label>
                <input type="text" [readonly]="eventfromView" matInput [value]="productBrandForm.get('validTo').value"
                    formControlName="validTo">
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="!eventfromView">
            <mat-form-field [appearance]="FirstDarf===true ? 'fill': 'legacy'">
                <mat-label>Valid From</mat-label>
                <input type="week" matInput [required]="activeCheck" [readonly]="FirstDarf"
                    (change)="getDepartments()" [value]="productBrandForm.get('validFrom').value"
                    formControlName="validFrom">
                <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
                    <div *ngIf="productBrandForm.controls['validFrom'].hasError(validation.type)">
                        {{validation.message}}
                    </div>
                </mat-error>
            </mat-form-field>
            <mat-form-field [appearance]="eventfromView===true ? 'fill': 'legacy'">
                <mat-label>Valid To</mat-label>
                <input type="week" matInput (change)="getDepartments()"
                    [value]="productBrandForm.get('validTo').value" formControlName="validTo">
                <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
                    <div *ngIf="productBrandForm.controls['validTo'].hasError(validation.type)">
                        {{validation.message}}
                    </div>
                </mat-error>
            </mat-form-field>
        </ng-container>
        <div class="deptsection">
            <mat-form-field floatLabel="always" [appearance]="eventfromView===true ? 'fill': 'legacy'">
                <mat-label>Departments</mat-label>
                <span>{{getSelectedItems() |
                    slice:0:66}}<span *ngIf="getSelectedItems().length>66">&nbsp;....</span>&nbsp;&nbsp;<span
                        class=spinner *ngIf="loaderService.isLoading$ | async">
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </span></span>
                <mat-select panelClass="product-custom-select" autoActiveFirstOption formControlName="department">
                    <mat-form-field class="depsearch" [appearance]="eventfromView===true ? 'fill': 'legacy'">
                        <input #search autocomplete="off" [placeholder]="isCBselected===true ? 'Search Departments': ''" aria-label="Search" matInput
                            [formControl]="searchTextboxControl" [(ngModel)]="searchString">
                        <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear"
                            (click)="clearSearch($event)">
                            <mat-icon>close</mat-icon>
                        </button>
                        <span *ngIf="!isCBselected" class="validerror">Enter a valid combination of Corporate Brand and Valid From or To, to get Departments.</span>
                    </mat-form-field>
                    <mat-checkbox class="checklist-leaf-node custom-All" [disabled]="eventfromView" formControlName="allDpt" (change)="checkAll()">All
                    </mat-checkbox>
                    <mat-option [ngStyle]="{display :'none'}"></mat-option>
                    <mat-tree class="panelinner" [dataSource]="dataSource" [treeControl]="treeControl">
                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding
                            [style.display]=" filterLeafNode(node) ? 'none' : 'block'">
                            <button mat-icon-button disabled></button>
                            <mat-checkbox [disabled]="eventfromView" class="checklist-leaf-node"
                                [checked]="checklistSelection.isSelected(node)"
                                (change)="todoLeafItemSelectionToggle(node)">{{node.item.split(',')[0]}}</mat-checkbox>
                            <span [style.display]="isDepartmentEnable(node)==true ? 'block' : 'none'" class="validerror">This department will be available from {{node.item.split(',').slice(-2).reverse().pop()}} to till {{node.item.split(',').slice(-1)[0]}}.</span>
                               </mat-tree-node> 
                            <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
                            [style.display]="filterParentNode(node) ? 'none' : 'block'">
                            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
                                <mat-icon class="mat-icon-rtl-mirror">
                                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                            </button>
                            <mat-checkbox [disabled]="eventfromView" [checked]="descendantsAllSelected(node)"
                                [indeterminate]="descendantsPartiallySelected(node)"
                                (change)="todoItemSelectionToggle(node)">
                                {{node.item}}</mat-checkbox>
                        </mat-tree-node>
                    </mat-tree>
                </mat-select>
            </mat-form-field>
        </div>
        <mat-form-field [appearance]="eventfromView || checkReadOnly===true ? 'fill': 'legacy'">
            <mat-label>Product Brand</mat-label>
            <input [readonly]="true" formControlName="productBrandName" matInput placeholder="ProductBrand">
        </mat-form-field>
        
        <mat-form-field [appearance]="eventfromView===true ? 'fill': 'legacy'">
            <mat-label>Sort Order</mat-label>
            <input formControlName="productBrandSortOrder" [readonly]="eventfromView" matInput placeholder="Sort Oder"
                min="0" type="number" pattern="^(0|[1-9][0-9]*)$">
            <mat-error *ngIf="this.productBrandForm.controls['productBrandSortOrder'].errors?.max">The range for sort
                order is
                1-9999. </mat-error>
            <mat-error *ngIf="productBrandForm.controls['productBrandSortOrder'].errors?.pattern">Only Numeric Values
                allowed</mat-error>
        </mat-form-field>
        <section class="example-section">
            <mat-checkbox (change)="ActiveCheck($event)" id="inpactive" formControlName="active"
                [disabled]="eventfromView || draftCheckdisabled" class="example-margin width-15" color='primary'
                [required]="AtleastOneCheck">Active
            </mat-checkbox>
            <mat-checkbox (change)="DraftCheck($event)" id="inpdraft" formControlName="draft"
                [disabled]="eventfromView || draftCheckdisabled" class="example-margin width-15" color='primary'
                [required]="AtleastOneCheck">Draft
            </mat-checkbox>
        </section>
    </div>
    <div mat-dialog-actions *ngIf="userrole">
        <button *ngIf="!eventfromView" [disabled]="!(productBrandForm.valid && productBrandForm.dirty)" mat-button
            class="add-button-container mat-raised-button mat-primary ml-2" type="submit"
            (click)="onSubmit()">Update</button>
        <button mat-button class="add-button-container mat-raised-button mat-primary ml-2"
            (click)="cancel()">Cancel</button>
    </div>
    <br>
    <span *ngIf="!isRequired" class="validerror">You can't update changes since From date is required.</span>
    <span *ngIf="!userrole" class="validerror">You can't update changes since you
        have Read access.
        <br> <button mat-button class="add-button-container mat-raised-button mat-primary ml-2"
            (click)="cancel()">Cancel</button></span>
           </form>
