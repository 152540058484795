import { EventEmitter, Injectable } from '@angular/core';
import { UserInfo } from '../../models/UserInfo';

@Injectable({
  providedIn: 'root'
})
 export class AccountService {
  public userInfo:UserInfo ={Name:"",Email:"",Role:"",isLoggedIn:false};
   
  constructor() {    
    
  }

  getUserInfo()
  {
    if(localStorage.getItem("UserInfo") != null)
    {
       this.userInfo = JSON.parse(localStorage.getItem("UserInfo")); 
       console.log("from service" +this.userInfo )
    }
  
    return this.userInfo;
  }
}
