import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonUtilityService } from 'src/app/services/organization/commonutility/commonutility.service';
import { GeographicalcountryService } from 'src/app/services/country/geographicalcountry/geographicalcountry.service';
import { AccountService } from '../../../../shared/services/account.service'
import { constant } from '../../../../shared/const/constant'
import { CurrencyComponent } from '../currencies/currency/currency.component'
import { GbccostComponent } from '../gbccost/gbccost/gbccost.component';
import { VatComponent } from '../vat/vat/vat.component';
import { VatexceptionComponent } from '../vatexception/vatexception/vatexception.component';
import { FiscalcountryService } from '../../../../services/country/fiscalcountry/fiscalcountry.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { UserAccess } from 'src/app/models/userAccess';
import { ModulePermission } from 'src/app/models/enum';
export class productTypeGroup {
  productTypeGroupId: any;
  productTypeIds:any;
  
}
@Component({
  selector: 'app-fiscalcountryform',
  templateUrl: './fiscalcountryform.component.html',
  styleUrls: ['./fiscalcountryform.component.css']
})
export class FiscalcountryformComponent implements OnInit {
  @ViewChild('validateCurrencyChildComponentForm') public CurrencyChildComponent: CurrencyComponent;
  @ViewChild('GBCCostChildComponent') public GbccostComponent: GbccostComponent;
  @ViewChild('validateVatChildComponentForm') public VatChildComponent: VatComponent;
  @ViewChild('validateVatExChildComponentForm') public VatExChildComponent: VatexceptionComponent;

  errorMsg: any;
  childFormValidCheck: boolean;
  vatFormValidCheck: boolean;
  vatExFormValidCheck: boolean;
  GBCchildFormValidCheck: boolean;
  submitted: any;
  isFiscalcountryEdit: any = false;
  isFiscalcountryEditCN = 'outline';
  update_fiscalcountryForm: any;
  public fcSubTab = true;
  public currenciesTab = true;
  public vatTab = false;
  public vatExceptionsTab = false;
  public gbcTab = false;
  countryMasterList: any[] = [];
  countryNameFilterList: any[] = [];
  countryIsoFilterList: any[] = [];
  validationMessages: any;
  userrole:boolean;
  productGroupId:any;
  productTypeId:any;
  myArray = [];
  productTypeGroupIds: any;
  constructor(
    public _geographicalcountryService: GeographicalcountryService,
    public _fiscalcountryService: FiscalcountryService,
    public toastr: ToastrService,
    public _commonutilityService: CommonUtilityService,
    private applicationInsightService: ApplicationInsightService,
    public account: AccountService,
    public _userAccessService: UserAccess
  ) {
    this.update_fiscalcountryForm = Object.assign({ active: true, draft: true });

  }
  ngOnInit(): void {
    this.userrole =this._userAccessService.getismenuEnable.some((_m: any)=> this._userAccessService.getismenuEnable.includes(ModulePermission.Country_Write) ? true : false);
    let filterData: any[] = [];
    this.validationMessages = this._commonutilityService.validationMsgs;
    this._geographicalcountryService.getCountryAll().subscribe((data: any) => {
      filterData = data.geographicalCountry;
      if (filterData != null) {
        this.countryMasterList = this.countryNameFilterList = this.countryIsoFilterList = filterData
          .filter(object => {
            return object['active'] !== false;
          });
      }
      this.countryNameFilterList = this.countryMasterList.sort((a, b) => a.geographicalCountryName.localeCompare(b.geographicalCountryName).toString());
      this.countryIsoFilterList = this.countryMasterList.filter((item, i, arr) => arr.findIndex((t) => t.geographicalCountryAlpha2ISOCode === item.geographicalCountryAlpha2ISOCode) === i);
    },
      error => {
        this.toastr.error(error);
      });
    this._fiscalcountryService.fiscalCountryId$.subscribe(fiscalCountryId => { this.updateFiscalCountry(fiscalCountryId) });
    this.fnSetControlsValidation();
  }
  updateFiscalCountry(fiscalCountryId: any) {
    this.applicationInsightService.logEvent("FiscalCountryForm Edit by Id Started");
    this.VatExChildComponent.onClear();
    this.onClear();
    /* istanbul ignore if */
    if (fiscalCountryId != undefined) {
      this.isFiscalcountryEdit = true;
      this._fiscalcountryService.getFiscalCountryId(fiscalCountryId).subscribe((data: any) => {
        this.update_fiscalcountryForm = data.fiscalCountry;
        this.isFiscalcountryEditCN = 'fill';
        this.UpdateCurrencyScope(data);
        if (data.fiscalCountry != null || undefined) {
          this.GbccostComponent.dataSource = this.GbccostComponent.rows = data.fiscalCountry.fiscalCountryGBCCost;
          this.GbccostComponent.TotalGbcCostLength = data.fiscalCountry.fiscalCountryGBCCost.length;
          this.GbccostComponent.isEditFcGbcCost = true;
          this.GbccostComponent.isFiscalCountryDraft = data.fiscalCountry.draft;
          this.UpdateIsDeleteCondition(this.GbccostComponent.rows, data.fiscalCountry.draft);
          this.VatChildComponent.dataSource = this.VatChildComponent.rows = data.fiscalCountry.fiscalCountryVAT;
          this.VatChildComponent.TotalVatLength = data.fiscalCountry.fiscalCountryVAT.length;
          this.VatChildComponent.isEditFcVat = true;
          this.VatChildComponent.isFiscalCountryDraft = data.fiscalCountry.draft;
          this.UpdateIsDeleteCondition(this.VatChildComponent.rows, data.fiscalCountry.draft);
          this.VatExChildComponent.dataSource = this.VatExChildComponent.rows = data.fiscalCountry.fiscalCountryVATException;
          this.getProductTypeGroup(this.VatExChildComponent.rows,'Edit')
          this.VatExChildComponent.TotalVatExLength = data.fiscalCountry.fiscalCountryVATException.length;
          this.VatExChildComponent.isEditFcVatEx = true;
          this.VatExChildComponent.isFiscalCountryDraft = data.fiscalCountry.draft;
          this.UpdateIsDeleteCondition(this.VatExChildComponent.rows, data.fiscalCountry.draft);
          this._fiscalcountryService.assignCollection(this.VatChildComponent.rows, this.VatExChildComponent.rows, this.GbccostComponent.rows);
        }
      });
    }
    this.applicationInsightService.logEvent("FiscalCountryForm Edit by Id Ended");
  }
  UpdateCurrencyScope(data: any) {
    this.applicationInsightService.logEvent("FiscalCountryForm Currency Edit Started");
    console.log(JSON.stringify(data));
    /* istanbul ignore else */
    if (data.fiscalCountry != null || undefined) {
      this.CurrencyChildComponent.dataSource = this.CurrencyChildComponent.rows = data.fiscalCountry.fiscalCountryCurrency;
      if (data.fiscalCountry.fiscalCountryCurrency.length != null) {
        this.CurrencyChildComponent.TotalCurrencyLength = data.fiscalCountry.fiscalCountryCurrency.length;
        this.CurrencyChildComponent.isEditFcCurrency = true;
        this.CurrencyChildComponent.isFiscalCountryDraft = data.fiscalCountry.draft;
        if (this.CurrencyChildComponent.rows.length > 0) {
          let length = this.CurrencyChildComponent.rows.length;
          for (var i = 0; i < length; i++) {
            if (this.CurrencyChildComponent.isFiscalCountryDraft)
              this.CurrencyChildComponent.rows[i].isDeleteFcCurrency = true;
            else {
              if (new Date(this.CurrencyChildComponent.rows[i].fromSeasonNumber.substring(0, 4) + "-" + this.CurrencyChildComponent.rows[i].fromSeasonNumber.slice(-2) + "-01") >= new Date(new Date().getFullYear() + "-" + new Date().getMonth() + "-01")) { this.CurrencyChildComponent.rows[i].isDeleteFcCurrency = true; }
              else if (this.CurrencyChildComponent.rows[i].toSeasonNumber == null || this.CurrencyChildComponent.rows[i].toSeasonNumber == '') { this.CurrencyChildComponent.rows[i].isDeleteFcCurrency = true; }
     
            }
            let filterdata: any[] = []
            filterdata = this.CurrencyChildComponent.fromSeasonFilterList;
            if (filterdata != null || undefined) {
              this.CurrencyChildComponent.fromSeasonFilterList = this.CurrencyChildComponent.endSeasonFilterList = this.CurrencyChildComponent.tempSeasonList = filterdata.filter(x => x.seasonNumber != this.CurrencyChildComponent.rows[i].fromSeasonNumber);
            }
            if (this.CurrencyChildComponent.rows[i].toSeasonNumber != undefined && this.CurrencyChildComponent.rows[i].toSeasonNumber != null && this.CurrencyChildComponent.rows[i].toSeasonNumber != '') {
              let filterSeason: any[] = []
              filterSeason = this.CurrencyChildComponent.fromSeasonFilterList
              if (filterSeason != null || undefined) {
                let collection = filterSeason.filter(x => x.seasonNumber > this.CurrencyChildComponent.rows[i].fromSeasonNumber && x.seasonNumber < this.CurrencyChildComponent.rows[i].toSeasonNumber);
                this.CurrencyChildComponent.fromSeasonFilterList = this.CurrencyChildComponent.endSeasonFilterList = this.CurrencyChildComponent.tempSeasonList = collection.length > 0 ? this.CurrencyChildComponent.fromSeasonFilterList.filter(x => x.seasonNumber != collection[0].seasonNumber) : this.CurrencyChildComponent.fromSeasonFilterList;
                this.CurrencyChildComponent.fromSeasonFilterList = this.CurrencyChildComponent.endSeasonFilterList = this.CurrencyChildComponent.tempSeasonList = this.CurrencyChildComponent.fromSeasonFilterList.filter(x => x.seasonNumber != this.CurrencyChildComponent.rows[i].toSeasonNumber);
              }

            }
          }
        }
      }
    }
    this.applicationInsightService.logEvent("FiscalCountryForm Currency Edit Ended");
  }
  UpdateIsDeleteCondition(data: any, isDraft: boolean) {
    for (var i = 0; i < data.length; i++) {
      /* istanbul ignore next */
      let fromDate = new Date(data[i].fromDate);
      if (isDraft)
        data[i].isDelete = true;
      else {
        if (data[i].toDate == null || data[i].toDate == '') { data[i].isDelete = true; }
        else if (new Date(fromDate.getFullYear()) > new Date(new Date().getFullYear())) { data[i].isDelete = true; }
      }
    }
  }
  displayTextCN(geographicalCountryId: string) {
    if (geographicalCountryId === null || geographicalCountryId === "") {
      return null;
    }
    else if (this.countryMasterList != undefined && geographicalCountryId != null) {
      this._fiscalcountryForm.controls.iSOCode.setValue(this.countryMasterList.
        find(x => x.geographicalCountryId == geographicalCountryId).geographicalCountryAlpha2ISOCode);
      return this.countryMasterList.
        find(x => x.geographicalCountryId == geographicalCountryId).geographicalCountryName;
    }
  }
  displayTextIsoCode(geographicalCountryAlpha2ISOCode: string) {
    if (geographicalCountryAlpha2ISOCode === null || geographicalCountryAlpha2ISOCode === "")
      return null;
    else if (this.countryMasterList != undefined && geographicalCountryAlpha2ISOCode != null)
      return this.countryMasterList.
        find(x => x.geographicalCountryAlpha2ISOCode == geographicalCountryAlpha2ISOCode).geographicalCountryAlpha2ISOCode;
  }
  filterItemCountryName(filterKey: string) {
    this.applicationInsightService.logEvent("FiscalCountryForm Filter CountryName Started");
    if (filterKey != null && filterKey != "") {
      this.countryNameFilterList = this.countryMasterList.filter(x => x.geographicalCountryName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
      this.countryNameFilterList = this.countryNameFilterList.sort((a, b) => a.geographicalCountryName.localeCompare(b.geographicalCountryName).toString());
    } else {
      this._fiscalcountryForm.controls.iSOCode.setValue('');
      this.countryNameFilterList = this.countryMasterList != undefined ? this.countryMasterList.sort((a, b) => a.geographicalCountryName.localeCompare(b.geographicalCountryName).toString()) : this.countryMasterList;
    }
    this.applicationInsightService.logEvent("FiscalCountryForm Filter CountryName Ended");
  }
  filterItemCountryIso(filterKey: string) {
    this.applicationInsightService.logEvent("FiscalCountryForm Filter CountryISO Started");
    if (filterKey != null && filterKey != "") {
      this.countryIsoFilterList = this.countryMasterList.filter(x => x.geographicalCountryAlpha2ISOCode.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
      this.countryIsoFilterList = this.countryIsoFilterList.sort((a, b) => a.geographicalCountryAlpha2ISOCode.localeCompare(b.geographicalCountryAlpha2ISOCode).toString());
    } else
      this.countryIsoFilterList = this.countryMasterList != undefined ? this.countryMasterList.sort((a, b) => a.geographicalCountryAlpha2ISOCode.localeCompare(b.geographicalCountryAlpha2ISOCode).toString()) : this.countryMasterList;
    this.applicationInsightService.logEvent("FiscalCountryForm Filter CountryISO Ended");
  }
  public isChildFormValid(formValid: any) {
    this.childFormValidCheck = formValid;
  }
  public isGBCCostChildFormValid(formValid: any) {
    this.GBCchildFormValidCheck = formValid;
  }
  public isChildVatFormValid(formValid: any) {
    this.vatFormValidCheck = formValid;
  }
  public isChildVatExFormValid(formValid: any) {
    this.vatExFormValidCheck = formValid;
  }

  onSubmit() {
   
    this.submitted = true;
    let valid = true;
    let msg = '';
    this._fiscalcountryForm.controls['fiscalCountryCode'].updateValueAndValidity();
    this._fiscalcountryForm.controls['geographicalCountryId'].updateValueAndValidity();
    this._fiscalcountryForm.controls['iSOCode'].updateValueAndValidity();
    this.CurrencyChildComponent.validateChildForm(this.submitted);
    /* istanbul ignore if */
    if (this._fiscalcountryForm.controls.draft.value == undefined || this._fiscalcountryForm.controls.draft.value == false) {
      if (!(this.childFormValidCheck) && (this.CurrencyChildComponent.rows.length == 0)) {
        msg += ", Currency";
        valid = false;
      }
      this.VatChildComponent.validateVatChildForm(this.submitted);
      if (!(this.vatFormValidCheck) && (this.VatChildComponent.rows.length == 0)) {
        msg += ", VAT";
        valid = false;
      }
      this.VatExChildComponent.validateVatExChildForm(this.submitted);
      if (!(this.vatExFormValidCheck) && (this.VatExChildComponent.rows.length == 0)) {
        msg += ", VAT Exception";
        valid = false;
      }
      this.GbccostComponent.validateGBCChildForm(this.submitted);
      if (!(this.GBCchildFormValidCheck) && (this.GbccostComponent.rows.length == 0)) {
        msg += ', GBC Cost';
        valid = false;
      }
    }
    if (valid) {
      if (this._fiscalcountryForm.valid) {
        this._fiscalcountryForm.controls.lastUpdatedBy.setValue(this.account.userInfo.Email)
        this._fiscalcountryForm.controls.fiscalCountryCurrencys.setValue(this.CurrencyChildComponent.rows.length == 0 ? null : this.CurrencyChildComponent.rows);
        this._fiscalcountryForm.controls.fiscalCountryGBCCosts.setValue(this.GbccostComponent.rows.length == 0 ? null : this.GbccostComponent.rows);
        this._fiscalcountryForm.controls.fiscalCountryVAT.setValue(this.VatChildComponent.rows.length == 0 ? null : this.VatChildComponent.rows);
        this._fiscalcountryForm.controls.fiscalCountryVATExceptions.setValue(this.VatExChildComponent.rows.length == 0 ? null : this.VatExChildComponent.rows);
        console.log("FiscalCoutry:" + JSON.stringify(this._fiscalcountryForm.value));
        if (this._fiscalcountryForm.value.fiscalCountryId == undefined || this._fiscalcountryForm.value.fiscalCountryId == 0 || this._fiscalcountryForm.value.fiscalCountryId == null) {
          this._fiscalcountryService.CreateFiscalCountry(this._fiscalcountryForm.value).subscribe
            (data => {

              this.toastr.success(data.message, "", { timeOut: constant.toasterTimeout });
              this._fiscalcountryService.refreshFiscalCountryList();
              this.onClear();

            },
              error => { this.toastr.error(error, "", { timeOut: constant.toasterTimeout }) })
        }
        else {
          this._fiscalcountryForm.controls.currencyStatus.setValue(this.CurrencyChildComponent.currencyStatus);
          this._fiscalcountryForm.controls.gbcCostStatus.setValue(this.GbccostComponent.gbcCostStatus);
          this._fiscalcountryForm.controls.vatStatus.setValue(this.VatChildComponent.vatStatus);
          this._fiscalcountryForm.controls.vatExceptionStatus.setValue(this.VatExChildComponent.vatExceptionStatus);
          this._fiscalcountryService.UpdateFiscalcountry(this._fiscalcountryForm.value).subscribe
            (data => {
              this.toastr.success(data.message, "", { timeOut: constant.toasterTimeout });
              this._fiscalcountryService.refreshFiscalCountryList();
              this.onClear();

            },
              error => { this.toastr.error(error, "", { timeOut: constant.toasterTimeout }) })
        }

      }
    }
    else {
      this.toastr.error("Fill mandatory Fields" + msg);
    }
  }
  onClear() {
    this.applicationInsightService.logEvent("FiscalCountryForm Clear Started");
    this._fiscalcountryService.assignCollection([], [], []);
    this._fiscalcountryForm.controls.fiscalCountryCode.clearValidators();
    this._fiscalcountryForm.controls.geographicalCountryId.clearValidators();
    this._fiscalcountryForm.controls.iSOCode.clearValidators();
    this._fiscalcountryForm.controls.fiscalCountryId.setValue('');
    this._fiscalcountryForm.controls.geographicalCountryId.setValue('');
    this._fiscalcountryForm.controls.fiscalCountryCode.setValue('');
    this._fiscalcountryForm.controls.iSOCode.setValue('');
    this._fiscalcountryForm.controls.draft.setValue(true);
    this._fiscalcountryForm.controls.fiscalCountryCurrencys.setValue('');
    this._fiscalcountryForm.controls.fiscalCountryVAT.setValue('');
    this._fiscalcountryForm.controls.fiscalCountryVATExceptions.setValue('');
    this._fiscalcountryForm.controls.fiscalCountryGBCCosts.setValue('');
    this._fiscalcountryForm.controls.currencyStatus.setValue('');
    this._fiscalcountryForm.controls.gbcCostStatus.setValue('');
    this._fiscalcountryForm.controls.vatStatus.setValue('');
    this._fiscalcountryForm.controls.vatExceptionStatus.setValue('');
    this.isFiscalcountryEditCN = "outline";
    this.isFiscalcountryEdit = false;
    this.CurrencyChildComponent.onClear();
    this.CurrencyChildComponent.GetSeasonList();
    this.CurrencyChildComponent.clearScope();
    this.VatChildComponent.onClear();
    this.VatChildComponent.clearScope();
    this.VatExChildComponent.onClear();
    this.VatExChildComponent.clearScope();
    this.GbccostComponent.onClear();
    this.GbccostComponent.clearScope();
    this.fnSetControlsValidation();
    this.applicationInsightService.logEvent("FiscalCountryForm Clear Ended");
  }
   /* istanbul ignore next */
  loadTabComponentOnClck(tapComponent: string) {
    if (tapComponent === "FC-Currencies") {
      this.currenciesTab = true;
      this.vatTab = false;
      this.vatExceptionsTab = false;
      this.gbcTab = false;
    }
    else if (tapComponent === "FC-Vat") {
      this.currenciesTab = false;
      this.vatTab = true;
      this.vatExceptionsTab = false;
      this.gbcTab = false;
    }
    else if (tapComponent === "FC-VatExceptions") {
      this.currenciesTab = false;
      this.vatTab = false;
      this.vatExceptionsTab = true;
      this.gbcTab = false;
    }
    else if (tapComponent === "FC-Gbc") {
      this.currenciesTab = false;
      this.vatTab = false;
      this.vatExceptionsTab = false;
      this.gbcTab = true;

    }
  }
  getProductTypeGroup(collections:any,method:any)
  {
    let productType:any=0;
    let groupId:any=0;
    let typeId:any=0;
    let parenttemp:any=0;
    let i: any;
    let temp = collections;
    let knowledgeData = this.VatExChildComponent.objCollection.datasource;
    for(let j=0;j<collections.length;j++){
      this.myArray=[];
      parenttemp=0;
      typeId="";
      groupId=0;
    let pvalue = method=='create'? temp[j].productTypeGroupIds[0].productTypeIds :temp[j].productTypeGroupIds;
    pvalue = pvalue.replace(/,\s*$/, "").split(",");
      for (var a in pvalue) {
        pvalue[a] = parseInt(pvalue[a], 10);
      }
     for(i=0;i<pvalue.length;i++){
      productType = pvalue[i];
      let result = knowledgeData.filter(cl => cl.children.some(c => c.item.split(',')[1] == productType));
      groupId = result[0].item.split(',')[1]; 
      typeId += productType;
      typeId += ',';
      if (parenttemp != 0 && parenttemp!= undefined && parenttemp != groupId) {
              typeId=""+productType+',';
               this.push(j);
             }
      parenttemp = groupId;
      this.productGroupId = groupId;      
      this.productTypeId=typeId;
    }
     if(pvalue.length==i) {
      this.push(j);
     } 
    }
     
 }

push(rowindex:any){
let commentData = {} as productTypeGroup;
commentData.productTypeGroupId =  this.productGroupId;
commentData.productTypeIds = this.productTypeId;
var index = this.myArray.findIndex(x => x.productTypeGroupId==this.productGroupId); 
this.myArray.push(commentData);
this.VatExChildComponent.rows[rowindex].productTypeGroupIds=this.myArray;
this.VatExChildComponent.rows[rowindex].tempRowIndex =rowindex+1;
}
  _fiscalcountryForm: FormGroup = new FormGroup({
    fiscalCountryId: new FormControl(''),
    fiscalCountryCode: new FormControl('', [Validators.required,]),
    iSOCode: new FormControl('', [Validators.required,]),
    geographicalCountryId: new FormControl('', [this._commonutilityService.acObjectValidator(), Validators.required]),
    active: new FormControl(true),
    draft: new FormControl(true),
    lastUpdatedBy: new FormControl(this.account.userInfo.Email),
    fiscalCountryCurrencys: new FormControl(''),
    fiscalCountryVAT: new FormControl(''),
    fiscalCountryVATExceptions: new FormControl(''),
    fiscalCountryGBCCosts: new FormControl(''),
    currencyStatus: new FormControl(''),
    gbcCostStatus: new FormControl(''),
    vatStatus: new FormControl(''),
    vatExceptionStatus: new FormControl('')
  })
  fnSetControlsValidation() {
    this._fiscalcountryForm.controls.fiscalCountryCode.setValidators([Validators.required, Validators.minLength(2), Validators.maxLength(2)]);
    this._fiscalcountryForm.controls.iSOCode.setValidators([Validators.required]);
    this._fiscalcountryForm.controls.geographicalCountryId.setValidators([this._commonutilityService.acObjectValidator(), Validators.required]);
  }
}


