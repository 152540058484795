import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ServiceApi } from '../../../services/ServiceApi';

let headers = new HttpHeaders()
if (!headers.has('content-type')) {
  headers = headers.append('content-type', 'application/json')
}

@Injectable({
  providedIn: 'root'
})

export class Ab2departmentsService {
  resfreshCall = new BehaviorSubject<boolean>(false);
  headers = new HttpHeaders().set('content-type', 'application/json').set('accept', 'application/json');
  httpOptions = {
    headers: this.headers
  }
  constructor(private http: HttpClient,
    public _serviceApi: ServiceApi) { }

    CreateAb2Departments(createnew: any): Observable<any>
    {
      let url = this._serviceApi.CreateAb2Departments;  
      var body=(JSON.stringify(createnew));
      return this.http.post(url,body,{'headers':headers}).pipe();
    }

    UpdateAb2Departments(updatedData: any): Observable<any>
    {
      let url = this._serviceApi.UpdateAb2Departments
      return this.http.put(url, updatedData, { 'headers': headers }).pipe();
    }

    getAllAb2DepartmentsList(): Observable<any> {
      let url = this._serviceApi.Ab2DepartmentsGetAll;
      return this.http.get<any>(url)
    }

    GetAb2DepartmentsById(id):Observable<any> {
      let url = this._serviceApi.Ab2DepartmentsGetById;
      return this.http.get<any>(url+id)
    }
    
}
