import { Pipe, PipeTransform } from '@angular/core';

    

@Pipe({

  name: 'nextlinebr'

})

export class nextlinebrPipe implements PipeTransform {

     

  transform(value: string): string {

      return value.replace(/\n/g, '<br/>');

 }

     

}