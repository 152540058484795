<div class="fixed-content mat-elevation-z2 form-border" id="scrolltop">
  <form [formGroup]="_markupform">
    <section class="form-section">
      <h2 mat-dialog-title class="align">{{ismarkupgroupEdit === true ? 'Update' : 'New'}} Markup Group</h2>
    </section>
    <div class="example-container add-form-field ">
      <mat-form-field [appearance]="isMarkupGroupEditCB">
        <mat-label>Corporate Brand</mat-label>
        <input type="text" id=inpcorporatebrand matInput [matAutocomplete]="autoCB" formControlName="corporateBrandId"
               (ngModelChange)="filterItemCorporateBrand($event)" [(ngModel)]=" updateMarkupgroupForm.corporateBrandId" [readonly]="isMarkupGroupEditCB=='fill' ?true:false">

        <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
          <div *ngIf="_markupform.controls['corporateBrandId'].hasError(validation.type)">{{validation.message}}</div>
        </mat-error>

        <mat-autocomplete autoActiveFirstOption #autoCB="matAutocomplete" [displayWith]="displayTextCB.bind(this)">
          <mat-option *ngFor="let CBname of corporateBrandFilterCGList" [value]="CBname.corporateBrandId">
            {{CBname.corporateBrandCode}} {{CBname.corporateBrandName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="legacy">
        <mat-label>Markup Group Code</mat-label>
        <input id="inpmarkupgroupcode" formControlName="markupGroupCode" [(ngModel)]="updateMarkupgroupForm.markupGroupCode" matInput min="10000" type="number" pattern="^(0|[1-9][0-9]*)$">
        <mat-error *ngIf="_markupform.controls['markupGroupCode'].errors?.min">
          Markup Group Code should be 5 digit number Ex:- 10000 to 99999
        </mat-error>
        <mat-error *ngIf="_markupform.controls['markupGroupCode'].errors?.max">
          Markup Group Code should be 5 digit number Ex:- 10000 to 99999
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="legacy">
        <mat-label>Markup Group </mat-label>
        <input id=inpmarkupgroupname formControlName="markupGroupName" matInput
               [(ngModel)]="updateMarkupgroupForm.markupGroupName">
        <mat-error *ngIf="_markupform.controls['markupGroupName'].errors?.maxlength">Maximum 250 Characters allowed</mat-error>
      </mat-form-field>
      <mat-form-field appearance="legacy">
        <mat-label>Sort Order</mat-label>
        <input id="inpSortOrder" formControlName="markUpGroupSortOrder" [(ngModel)]=" updateMarkupgroupForm.markupGroupSortOrder" min="0" matInput type="number" pattern="^(0|[1-9][0-9]*)$">
        <mat-error *ngIf="_markupform.controls['markUpGroupSortOrder'].errors?.max">The range for sort order is 1-9999</mat-error>
        <mat-error *ngIf="_markupform.controls['markUpGroupSortOrder'].errors?.pattern">Only Numeric Values allowed</mat-error>

      </mat-form-field>
      <section class="example-section">
        <mat-checkbox id="inpactive" formControlName="active" [disabled]="isDisableActive" [(ngModel)]=" updateMarkupgroupForm.active"
                      class="example-margin width-25" color='primary'>Active</mat-checkbox>
        <mat-checkbox id="inpdraft" formControlName="draft" [(ngModel)]=" updateMarkupgroupForm.draft"
                      class="example-margin width-15" color='primary'>Draft</mat-checkbox>
      </section>
      <input type="hidden" id="inpmarkupgroupid" formControlName="markupGroupId" [(ngModel)]=" updateMarkupgroupForm.markupGroupId">
      <br>
      <mat-dialog-actions *ngIf="userrole" style="display: block !important">
        <button [disabled]="(ismarkupgroupEdit === true) ?  !_markupform.dirty : false" [ngClass]="(ismarkupgroupEdit === true) ? 'mat-raised-button ml-1':'mat-raised-button ml-1 mat-primary'" id="btnSaveUpdate"
                type="submit" (click)="onSubmit()">
          {{ismarkupgroupEdit === true ? 'Update' : 'Save'}}
        </button>

        <button class="mat-raised-button mat-primary ml-1" id="btnclear" (click)="onClear()">Cancel</button>
      </mat-dialog-actions>
      <span *ngIf="!userrole" style="color: #ff0202;font-size: 12px;font-weight: 400;">You can't save changes since you have Read access.</span>
    </div>
  </form>
  <div id="seasonDept" *ngIf=ismarkupgroupEdit>
    <mat-form-field [floatLabel]="'always'" appearance="legacy" class="width-45">
      <mat-label>Season</mat-label>
      <input type="text" id="inpseason" matInput [matAutocomplete]="autoSN"
             (ngModelChange)="filterSeason($event)" [formControl]="seasonNumber">
      <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
        <div *ngIf="this.seasonNumber.hasError(validation.type)">{{validation.message}}</div>
      </mat-error>
      <mat-autocomplete autoActiveFirstOption #autoSN="matAutocomplete"
                        [displayWith]="displayTextSeason.bind(this)">
        <mat-option *ngFor="let season of seasonFilterList" [value]="season.seasonNumber">
          {{season.displayName}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="info2" id="seasonDeptList">
      <table class="full-width-table">
        <thead>
          <tr style="color:#03538B  ;background-color:  #dfdfdfdf !important;text-align: left;">
            <th style="padding:10px 0px 10px 20px;">Department</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of seasonDeptList">
            <td style="width: 500px;padding:10px 0px 10px 20px;border-bottom: 0.5px solid #dfdfdfdf;">
              {{item.departmentCode}}-{{item.departmentName}}
            </td>
          </tr>
          <tr *ngIf="seasonDeptList?.length === 0" style="border:none"><td style="border:none;width: 500px; text-align: center;">No records found</td></tr>
        <tbody>
      </table>
      <br>
    </div>
  </div>
</div>
<style>
  .mat-raised-button.ml-1:not([disabled]) {
    color: #FFFFFF;
    background-color: #00558d !important
  }

  table, td, th {
    border-spacing: 0;
    border: #e2e2e2 0.02em solid;
  }

    table th {
       font-family: 'HM Group Ampersand';
      font-size: 15px;
      font-weight: 500;
    color:#03538B  ;
    background-color:  #dfdfdfdf !important;
    }

  table {
    width: 80%;
    border-collapse: collapse;    
    margin-right: auto;
    margin-left: 10.5%; 
  }

  table tbody{    
    max-height: 15em;
    overflow-y: auto;
    display: inline-block;
    background: #ffffff;    
  }
</style>
