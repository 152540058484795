import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GarmentgroupService } from 'src/app/services/organization/garmentgroup/garmentgroup.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';

@Component({
  selector: 'garmentgroup',
  templateUrl: './garmentgroup.component.html',
  styleUrls: ['./garmentgroup.component.css']
})
export class GarmentgroupComponent implements OnInit {
  obj: any
  title = "Garment Group"
  garmentGroupMasterList: any[] = [];
  garmentGroupFilterList: any[] = [];
  garmentGroupCode: any = "";
  garmentGroupName: any = "";
  status: any = "active";
  garmentGroupNameMasterList: any;
  garmentGroupNameFilterList: any;

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    private applicationInsightService:ApplicationInsightService,
    private toastr: ToastrService, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
  }
  receivedChildHandler(obj: any) {
    this.applicationInsightService.logEvent("Receive data from child started");
    if(obj !=null||undefined){
    this.garmentGroupMasterList = this.garmentGroupFilterList = this.garmentGroupNameMasterList = obj;
    this.garmentGroupFilterList = this.garmentGroupMasterList.sort((a, b) => (a.garmentGroupCode > b.garmentGroupCode ? 1 : -1));
    this.garmentGroupNameFilterList = this.garmentGroupNameMasterList.sort((a, b) => (a.garmentGroupCode > b.garmentGroupCode ? 1 : -1));
    }
    this.applicationInsightService.logEvent("Receive data from child ended");
  }
 
  filterItemgarmentgroupName(filterKey: any) {
    this.applicationInsightService.logEvent("Garmentgroupname filtering started");
    if (filterKey != null && filterKey != "") {
      if(this.garmentGroupNameMasterList!=null){
        if (isNaN(filterKey)) {
          this.garmentGroupNameFilterList = this.garmentGroupNameMasterList.filter(gg => gg.garmentGroupName.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
          this.garmentGroupNameFilterList = this.garmentGroupNameFilterList.sort((a, b) => (a.garmentGroupCode > b.garmentGroupCode ? 1 : -1));
        } else {
          this.garmentGroupNameFilterList = this.garmentGroupNameMasterList.filter(gg => gg.garmentGroupCode.toString().toLowerCase().includes(filterKey.toString().toLowerCase()));
        }
       }
      this.garmentGroupNameFilterList = this.garmentGroupNameFilterList.sort((a, b) =>(a.garmentGroupCode > b.garmentGroupCode ? 1 : -1));
    } else
      {this.garmentGroupNameFilterList = this.garmentGroupMasterList.sort((a, b) => (a.garmentGroupCode > b.garmentGroupCode ? 1 : -1));}
      this.applicationInsightService.logEvent("Garmentgroupname filtering ended");
  }

  //clear button click event
  onSearchClear() {
    this.applicationInsightService.logEvent("Garmentgroup clearfilter started");
    this._searchform.controls['status'].setValue("active");
    this._searchform.controls['garmentGroupCode'].setValue("");
    this._searchform.controls['garmentGroupName'].setValue("");
    this._searchform.controls['garmentGroupCodeName'].setValue("");
    this.filterItemgarmentgroupName("");
    this.applicationInsightService.logEvent("Garmentgroup clearfilter ended");
  }
  //garmentGroup  Search Form
  _searchform: FormGroup = new FormGroup({
    garmentGroupCode: new FormControl(''),
    garmentGroupName: new FormControl(''),
    garmentGroupCodeName: new FormControl(''),
    status: new FormControl(),
  });

}

