<div>
    <mat-spinner [diameter]="50" *ngIf="myspinner"></mat-spinner>
</div>


<div *ngIf="!myspinner">
<section class="titlebg">
    <h2 mat-dialog-title class="align">{{displayHedingmessage}} Assortment Build</h2>
</section>
<form [formGroup]="abDepartmentForm">
    <div mat-dialog-content class="modelclass" >
        <mat-form-field [appearance]="eventfromView || editScreenDisable===true ? 'fill': 'legacy'">
            <mat-label>Corporate Brand </mat-label>
            <input type="text" [readonly]="eventfromView || editScreenDisable" matInput [matAutocomplete]="autoCB"
                formControlName="corporateBrandName" (ngModelChange)="filterItemCorporateBrand($event)">
            <mat-autocomplete autoActiveFirstOption #autoCB="matAutocomplete" [displayWith]="displayTextCB.bind(this)">
                <ng-container *ngIf="!checkReadOnly">
                    <mat-option *ngFor="let CBname of corporateBrandFilterCGList"
                    (onSelectionChange)="getCbDropdownValues(CBname.corporateBrandId)" [value]="CBname.corporateBrandId">
                        {{CBname.corporateBrandCodeName}} 
                    </mat-option>
                </ng-container>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field [appearance]="eventfromView || editScreenDisable===true ? 'fill': 'legacy'" >
            <mat-label>Season</mat-label>
            <input type="text" matInput [matAutocomplete]="autoRSS" formControlName="startSeason"
                   (ngModelChange)="filterStartSeason($event)" [readonly]="eventfromView || editScreenDisable"
                   id="inpstartseason">
            <mat-autocomplete autoActiveFirstOption #autoRSS="matAutocomplete"
                              [displayWith]="displayTextSeason.bind(this)">
              <mat-option *ngFor="let season of startSeasonFilterList" 
              (onSelectionChange)="getStartSeasonValues(season.seasonNumber)"
              [value]="season.seasonNumber">
                {{season.displayName}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

        <div class="deptsection">
            <mat-form-field floatLabel="always" [appearance]="eventfromView===true ? 'fill': 'legacy'">
                <mat-label>Departments</mat-label>
                <span>{{getSelectedItems() |
                    slice:0:66}}<span *ngIf="getSelectedItems().length>66">&nbsp;....</span>&nbsp;&nbsp;<span
                        class=spinner *ngIf="loaderService.isLoading$ | async">
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </span></span>
                <mat-select #sl panelClass="custom-select" autoActiveFirstOption formControlName="department">   
                <div>
                    <mat-form-field class="depsearch" [appearance]="eventfromView===true ? 'fill': 'legacy'">
                        <input #search autocomplete="off" [placeholder]="isCBselected===true ? 'Search Departments': ''" aria-label="Search" matInput
                            [formControl]="searchTextboxControl" (input)="filterChanged($event.target.value)" [(ngModel)]="searchString">
                        <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear"
                            (click)="clearSearch($event)">
                            <mat-icon>close</mat-icon>
                        </button>
                        <span *ngIf="!isCBselected" class="validerror">Enter a valid combination of Corporate Brand and Season to get Departments.</span>
                    </mat-form-field>
                    <mat-icon class="selectClose" (click)="sl.close()">close</mat-icon>
                </div> 
                <div style="overflow: auto;">
                    <mat-checkbox *ngIf="isCBselected" class="checklist-leaf-node custom-All" [disabled]="eventfromView" formControlName="allDpt" (change)="checkAll()">All
                    </mat-checkbox>
                    <mat-option [ngStyle]="{display :'none'}"></mat-option>
                    <mat-tree class="panelinner" [dataSource]="dataSource" [treeControl]="treeControl">
                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding
                            [style.display]=" filterLeafNode(node) ? 'none' : 'block'">
                            <button mat-icon-button disabled></button>
                            <mat-checkbox [disabled]="eventfromView" class="checklist-leaf-node"
                                [checked]="checklistSelection.isSelected(node)"
                                (change)="todoLeafItemSelectionToggle(node)">{{node.item.split(',')[0]}}</mat-checkbox>
                            <span [style.display]="isDepartmentEnable(node)==true ? 'block' : 'none'" class="validerror">This department will be available from {{node.item.split(',').slice(-2).reverse().pop()}} to till {{node.item.split(',').slice(-1)[0]}}.</span>
                               </mat-tree-node> 
                            <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
                            [style.display]="filterParentNode(node) ? 'none' : 'block'">
                            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
                                <mat-icon class="mat-icon-rtl-mirror">
                                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                            </button>
                            <mat-checkbox [disabled]="eventfromView" [checked]="descendantsAllSelected(node)"
                                [indeterminate]="descendantsPartiallySelected(node)"
                                (change)="todoItemSelectionToggle(node)">
                                {{node.item}}</mat-checkbox>
                        </mat-tree-node>
                    </mat-tree>
                </div>
                </mat-select>
            </mat-form-field>
        </div>
        <section class="example-section">
            <mat-checkbox id="inpactive" formControlName="active" (change)="ActiveCheck($event)"
                [disabled]="eventfromView" class="example-margin width-15" color='primary'
                [required]="AtleastOneCheck">Active
            </mat-checkbox>
            <mat-checkbox  id="inpdraft" formControlName="draft" (change)="DraftCheck($event)"
                [disabled]="eventfromView" class="example-margin width-15" color='primary'
                [required]="AtleastOneCheck">Draft
            </mat-checkbox>
        </section>
    </div>
    <div mat-dialog-actions style="margin-left: -11px;" *ngIf="userrole">
        <button *ngIf="!eventfromView" [disabled]="!(checklistSelection?.selected?.length && abDepartmentForm.dirty)" mat-button
            class="add-button-container mat-raised-button mat-primary ml-2" type="submit"
            (click)="onSubmit(displayHedingmessage)">{{displayHedingmessage}}</button>
        <button mat-button class="add-button-container mat-raised-button mat-primary ml-2"
            (click)="cancel()">Cancel</button>
    </div>
    <br>
    <span *ngIf="!isRequired" class="validerror">You can't update changes since From date is required.</span>
    <span *ngIf="!userrole" class="validerror">You can't update changes since you
        have Read access.
        <br> <button mat-button class="add-button-container mat-raised-button mat-primary ml-2"
            (click)="cancel()">Cancel</button></span>
           </form>
</div>