import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, Subject } from 'rxjs';
import { ServiceApi } from '../../../services/ServiceApi';
let headers = new HttpHeaders()
if (!headers.has('content-type')) {
  headers = headers.append('content-type', 'application/json')
}
@Injectable({
  providedIn: 'root'
})

export class SouthernhemisphereService {

  constructor(
    private http: HttpClient,
    public _serviceApi: ServiceApi
  ) { }

  headers = new HttpHeaders().set('content-type', 'application/json').set('accept', 'application/json');
  httpOptions = {
    headers: this.headers
  }

  //Observable string sources
  public southernhemisphereServiceIdSource = new Subject<string>();
  public southernhemisphereServiceDepartmentSource = new Subject<string>();

  //Observable string streams
  public southernHemisphereId$ = this.southernhemisphereServiceIdSource.asObservable();
  getSouthernHemisphereIdFromList(mission: string) {
    this.southernhemisphereServiceIdSource.next(mission);
  }
  public southernhemisphereServiceDepartment$ = this.southernhemisphereServiceDepartmentSource.asObservable();
  getSouthernHemisphereDepartmentFromList(mission: any) {
    this.southernhemisphereServiceDepartmentSource.next(mission);
  }

  //Observable string sources
  public refreshTable = new Subject<string>();

  //Observable string streams
  public refreshTableSouthernHemisphere$ = this.refreshTable.asObservable();

  // Service message commands
  refreshSouthernHemisphereList() {
    this.refreshTable.next();
  }

  getSeasonByRange(prec: any): Observable<any> {
    var serviceURL = this._serviceApi.DepartmentGetSeasonByRange + "?current=current&prec=" + prec + "&succ=4";
    return this.http.get(serviceURL);
  }

  //List SouthernHemisphere from Service
  getSouthernHemisphereAll(searchParams: any): Observable<any> {
    return this.http.post(this._serviceApi.SouthernHemisphereGetAll, JSON.stringify(searchParams), { 'headers': headers });
  }

  getChannels(): Observable<any> {
    return this.http.get<any>(this._serviceApi.ChannelGetAll);
  }

  //Create SouthernHemisphere 
  createSouthernHemisphere(createnew: any): Observable<any> {
    return this.http.post(this._serviceApi.SouthernHemisphereCreate, JSON.stringify(createnew), { 'headers': headers });
  }

  getSouthernHemisphereDepartmentsAndSections(getdepparams: any): Observable<any> {
   return this.http.post(this._serviceApi.SouthernHemisphereGetDepartmentsAndSections, JSON.stringify(getdepparams), { 'headers': headers });
  }

  //Get SouthernHemisphere By Id
  getSouthernHemisphereById(southernHemisphereId: number): Observable<any> {
    return this.http.get(this._serviceApi.SouthernHemisphereGetById + southernHemisphereId)
  }

  updateSouthernHemisphere(updatedData: any): Observable<any> {
    var body = (JSON.stringify(updatedData));
    return this.http.put(this._serviceApi.SouthernHemisphereUpdate, body, { 'headers': headers });
  }

  //Get token from service Head.
  getToken() {
    return localStorage.getItem('token')
  }
}
