import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { CommonUtilityService } from './services/organization/commonutility/commonutility.service';
import { Country, ModulePermission, Organization } from 'src/app/models/enum';
import { Market } from 'src/app/models/enum';
import { UserAccess } from './models/userAccess';
import { ApplicationConfig } from './shared/services/ApplicationConfig';
@Injectable()
export class CanActivateRouteGuard implements CanActivate {
  exist: any;
  users:any;
  childurls: any = [];
  assignedUrls: any = [];
  isurlExist: boolean = false;
  constructor(
    public _commonutilityService: CommonUtilityService,
    public _ApplicationConfig: ApplicationConfig,
    public _userAccess: UserAccess,
    private router: Router
  ) {
    this.users = JSON.parse(localStorage.getItem("users") || "[]")
   }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const url = state.url.replace(/[^\w\s]/gi, '');
    const prevurl = next.url;
    const modules = this.users
    const RedirectURL = 'https://sds-' + this._ApplicationConfig.APP_Environment.trim() + '.hmgroup.tech/';
    const RedirectURLProd= 'https://sds.hmgroup.com/';
    if ((modules.indexOf(ModulePermission.Organization_Read) != -1)||(modules.indexOf(ModulePermission.Organization_Write) != -1)) {
      if (Object.values(Organization).includes(url)) {
        this.isurlExist = true;

      }
    }
    if ((modules.indexOf(ModulePermission.Market_Read) != -1)||(modules.indexOf(ModulePermission.Market_Write) != -1)) {
      if (Object.values(Market).includes(url)) {
        this.isurlExist = true;
      }
    }
    if ((modules.indexOf(ModulePermission.Country_Read) != -1)||(modules.indexOf(ModulePermission.Cost_Write) != -1)) {
      if (Object.values(Country).includes(url)) {
        this.isurlExist = true;
      }
    }
    if (this.isurlExist != true) {
      const urlnotfound = this.router.serializeUrl(
        this.router.createUrlTree([`/pagenotfound`])
      );
      window.location.href=urlnotfound;
   }
    return this.isurlExist;
  }
}
