<div class="add-form-field" *ngIf="depSubIndexForm">
    <form [formGroup]="dptSubIndexForm">
      <div class="example-container">
        <!-- <h3 mat-dialog-title>{{isEditDepSubIndex === true ? 'Update' : 'New'}} Sub Index</h3> -->
        <mat-form-field [appearance]="(isEditDepSubIndex == true && isDeleteDepSubIndex==false) ?'fill': 'legacy'"
                        class="width-30">
          <mat-label>Start Season</mat-label>
          <input type="text" matInput [matAutocomplete]="autoRSS" formControlName="startSeason"
                 (ngModelChange)="filterStartSeason($event)" [(ngModel)]="updateDepSubIndexForm.startSeason"
                 id="inpdsistartseason" [readonly]="(isEditDepSubIndex == true && isDeleteDepSubIndex==false) ?true:false">
          <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
            <div *ngIf="dptSubIndexForm.controls['startSeason'].hasError(validation.type)">
              {{validation.message}}
            </div>
          </mat-error>
          <mat-autocomplete autoActiveFirstOption #autoRSS="matAutocomplete"
                            [displayWith]="displayTextSeason.bind(this)">
            <mat-option *ngFor="let season of startSeasonFilterList" [value]="season.seasonNumber">
              {{season.displayName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field [appearance]="(isEditDepSubIndex == true && isDeleteDepSubIndex==false) ?'fill': 'legacy'" class="width-30 ml-2">
          <mat-label>End Season</mat-label>
          <input type="text" matInput [matAutocomplete]="autoRES" formControlName="endSeason"
                 (ngModelChange)="filterEndSeason($event)" [(ngModel)]="updateDepSubIndexForm.endSeason"
                 id="inpdsiendseason" [readonly]="(isEditDepSubIndex == true && isDeleteDepSubIndex==false) ?true:false">
          <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
            <div *ngIf="dptSubIndexForm.controls['endSeason'].hasError(validation.type)">
              {{validation.message}}
            </div>
          </mat-error>
          <mat-autocomplete autoActiveFirstOption #autoRES="matAutocomplete"
                            [displayWith]="displayTextSeason.bind(this)">
            <mat-option *ngFor="let season of endSeasonFilterList" [value]="season.seasonNumber">
              {{season.displayName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field [appearance]="(isEditDepSubIndex == true && isDeleteDepSubIndex==false) ?'fill': 'legacy'" class="width-30 ml-2">
          <mat-label>Sub Index</mat-label>
          <input type="text" matInput [matAutocomplete]="autoSI" formControlName="subIndexId"
                 (ngModelChange)="filterItemSubIndex($event)" [(ngModel)]="updateDepSubIndexForm.subIndexId"
                 id="inpdsisubindexid" [readonly]="(isEditDepSubIndex == true && isDeleteDepSubIndex==false) ?true:false">
          <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
            <div *ngIf="dptSubIndexForm.controls['subIndexId'].hasError(validation.type)">
              {{validation.message}}
            </div>
          </mat-error>
          <mat-autocomplete autoActiveFirstOption #autoSI="matAutocomplete"
                            [displayWith]="displayTextSubIndex.bind(this)">
            <mat-option *ngFor="let subIndex of subIndexCodeFilterList" [value]="subIndex.subIndexId">
              {{subIndex.subIndexCode}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <input type="hidden" id="inpdsidepartmentid" formControlName="departmentId"
               [(ngModel)]="updateDepSubIndexForm.departmentId">
        <input type="hidden" id="inpdsidepartmentsubindexinfoid" formControlName="departmentSubIndexInfoId"
               [(ngModel)]="updateDepSubIndexForm.departmentSubIndexInfoId">
        <br>
        <br>
        <div [style.display]="!dptSubIndexForm.dirty==false ? 'block' : 'none'" > 
          <mat-dialog-actions *ngIf="!userrole">
            <button [disabled]="(isEditDepSubIndex == true && isDeleteDepSubIndex==false) ?true:false"
                    [ngClass]="(isEditDepSubIndex === true) ? 'mat-raised-button ml-1':'mat-raised-button ml-1 mat-primary'"
                    id="inpdsibtnsubmit" type="submit" (click)="onSubmit()">
              {{isEditDepSubIndex === true ? 'Update' : 'Save'}}
            </button>
            <button *ngIf="isDeleteDepSubIndex" class="mat-raised-button mat-primary  ml-2" id="inpdsibtndelete" type="button"
                    (click)="deleteDepSubIndex()">
              Delete
            </button>
            <button class="mat-raised-button mat-primary  ml-2" id="inpdsibtncancel" (click)="onClear()"
                    type="button">
              Cancel
            </button>
          </mat-dialog-actions>
        </div>
     
        <span *ngIf="userrole" style="color: #ff0202;font-size: 12px;font-weight: 400;">You can't save changes since you have Read access.</span>
      </div>
    </form>
</div>
