import { Component, OnInit, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DepartmentService } from 'src/app/services/organization/department/department.service';
import { DepartmentSubIndexService } from 'src/app/services/organization/department/subindex.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';

export interface iDepartmentSubIndexAll {
  departmentSubIndexId: any;
  departmentId: any;
  subIndexId: any;
  subIndexCode: any;
  startSeason: any;
  endSeason: any;
  lastUpdatedBy: any;
}

@Component({
  selector: 'department-subindexlist',
  templateUrl: './subindexlist.component.html',
  styleUrls: ['./subindexlist.component.css']
})

export class DepartmentsubindexlistComponent implements OnInit {

  @Output() departmentcall = new EventEmitter();
  @Input() depSubIndex;
  @Input() parentDepartmentId;
  @Input() parentCorporateBrandName;
  @Input() subIndexSeasonlist;
  @Input() seasonList;
  @Input() subIndexList;
  @Input() departmentDraft;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatTable) table: MatTable<iDepartmentSubIndexAll>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  sortByColumnName: any = "startSeason";
  sortByOrder: any = "asc";
  subscription: Subscription;
  displayedColumns = ["startSeason", "endSeason", "subIndexCode", "Actions"];
  errorMsg: any;
  seasonDate:string;

  constructor(
    public _departmentService: DepartmentService,
    public _departmentSubIndexService: DepartmentSubIndexService,
    private toastr: ToastrService,
    private applicationInsightService:ApplicationInsightService) { }

  ngOnInit(): void {
  }
  sortData(sort: Sort) {
    this.sortByColumnName = sort.active;
    this.sortByOrder = sort.direction;
  }
  receivedChildHandlerSI() {
    this.departmentcall.emit();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.BindSubIndexData()
  }
  BindSubIndexData() {
    this.applicationInsightService.logEvent("BindSubIndexData started");
    if(this.depSubIndex != undefined || null){
    if( this.depSubIndex.length!=null || undefined)
    {
    for(let i =0 ;i<this.depSubIndex.length;i++){
      this.depSubIndex[i].isDeleteDepSubIdex = false;
        if (new Date(this.depSubIndex[i].startSeason.substring(0, 4) + "-" + this.depSubIndex[i].startSeason.slice(-2) + "-01") >= new Date(new Date().getFullYear() + "-" + new Date().getMonth() + "-01")) { this.depSubIndex[i].isDeleteDepSubIdex = true; }
        else if (this.depSubIndex[i].endSeason == null || this.depSubIndex[i].endSeason == '') { this.depSubIndex[i].isDeleteDepSubIdex = true; }
        else if ((new Date(this.depSubIndex[i].startSeason.substring(0, 4) + "-" + this.depSubIndex[i].startSeason.slice(-2) + "-01")) <= new Date() && this.depSubIndex[i].endSeason != '' && this.depSubIndex[i].endSeason != null && this.depSubIndex[i].endSeason.includes("99")) { this.depSubIndex[i].isDeleteDepSubIdex = true; }
        else if ((new Date(this.depSubIndex[i].startSeason.substring(0, 4) + "-" + this.depSubIndex[i].startSeason.slice(-2) + "-01")) <= new Date() && this.depSubIndex[i].endSeason != '' && this.depSubIndex[i].endSeason != null && (new Date(this.depSubIndex[i].endSeason.substring(0, 4) + "-" + this.depSubIndex[i].endSeason.slice(-2) + "-01")) >= new Date()) { this.depSubIndex[i].isDeleteDepSubIdex = true; }
       }
      }
      this.dataSource = new MatTableDataSource(this.depSubIndex);
      this.sort.active = this.sortByColumnName;
      this.sort.direction = this.sortByOrder;
      this.dataSource.sort = this.sort;
    }
    this.applicationInsightService.logEvent("BindSubIndexData ended");   
  }
  //While clicking Edit button load respective data to entry page
  updateRecord(updateRowData: any) {
    this._departmentSubIndexService.getdepartmentSubIndexIdFromList(updateRowData.departmentSubIndexInfoId);
  }


}

