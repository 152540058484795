import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DepartmentService } from 'src/app/services/organization/department/department.service';
import { MarkupGroupService } from 'src/app/services/organization/department/markupgroup.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';

export interface iDepartmentMarkupGroupAll {
  departmentMarkupGroupId: any;
  departmentId: any;
  markupGroupId: any;
  markupGroupCode: any;
  markUpGroupName: any;
  startSeason: any;
  endSeason: any;
  lastUpdatedBy: any;
}

@Component({
  selector: 'department-markupgrouplist',
  templateUrl: './markupgrouplist.component.html',
  styleUrls: ['./markupgrouplist.component.css']
})
export class DepartmentmarkupgrouplistComponent implements OnInit {

  @Output() departmentcall = new EventEmitter();
  @Input() depMarkupGroup;
  @Input() parentDepartmentId;
  @Input() parentCorporateBrandName;
  @Input() markupSeaasonlist;
  @Input() seasonList;
  @Input() markupGroupList;
  @Input() departmentDraft;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatTable) table: MatTable<iDepartmentMarkupGroupAll>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  sortByColumnName: any = "startSeason";
  sortByOrder: any = "asc";
  subscription: Subscription;
  seasonDate:string;
  displayedColumns = ["startSeason", "endSeason", "markupGroupCode", "markUpGroupName", "Actions"];
  errorMsg: any;
  isDeleteDepMarkupGroup: boolean;

  constructor(
    public _depMarkupGroupService: MarkupGroupService,
    public _departmentService: DepartmentService,
    private toastr: ToastrService,
    private applicationInsightService:ApplicationInsightService) { }

  ngOnInit(): void {
 }
  /* istanbul ignore next */
  sortData(sort: Sort) {
    this.sortByColumnName = sort.active;
    this.sortByOrder = sort.direction;
 }
  /* istanbul ignore next */
  receivedChildHandlerMG() {
    this.departmentcall.emit();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.BindMarkupGroupData()
  }
  BindMarkupGroupData() {
    this.applicationInsightService.logEvent("Department BindMarkupGroupData started");
    if(this.depMarkupGroup != undefined || null){
      if(this.depMarkupGroup.length !=null || undefined){
    for(let i =0 ;i<this.depMarkupGroup.length;i++){
    this.depMarkupGroup[i].isDeleteDepMarkupGroup = false;
      if (new Date(this.depMarkupGroup[i].startSeason.substring(0, 4) + "-" + this.depMarkupGroup[i].startSeason.slice(-2) + "-01") >= new Date(new Date().getFullYear() + "-" + new Date().getMonth() + "-01")) { this.depMarkupGroup[i].isDeleteDepMarkupGroup = true; }
      else if (this.depMarkupGroup[i].endSeason == null || this.depMarkupGroup[i].endSeason == '') { this.depMarkupGroup[i].isDeleteDepMarkupGroup = true; }
      else if ((new Date(this.depMarkupGroup[i].startSeason.substring(0, 4) + "-" + this.depMarkupGroup[i].startSeason.slice(-2) + "-01")) <= new Date() && this.depMarkupGroup[i].endSeason != '' && this.depMarkupGroup[i].endSeason != null && this.depMarkupGroup[i].endSeason.includes("99")) { this.depMarkupGroup[i].isDeleteDepMarkupGroup = true; }
      else if ((new Date(this.depMarkupGroup[i].startSeason.substring(0, 4) + "-" + this.depMarkupGroup[i].startSeason.slice(-2) + "-01")) <= new Date() && this.depMarkupGroup[i].endSeason != '' && this.depMarkupGroup[i].endSeason != null && (new Date(this.depMarkupGroup[i].endSeason.substring(0, 4) + "-" + this.depMarkupGroup[i].endSeason.slice(-2) + "-01")) >= new Date()) { this.depMarkupGroup[i].isDeleteDepMarkupGroup = true; }
    }
  }
  this.dataSource = new MatTableDataSource(this.depMarkupGroup);
  this.sort.active = this.sortByColumnName;
  this.sort.direction = this.sortByOrder;
  this.dataSource.sort = this.sort;
  }
    this.applicationInsightService.logEvent("Department BindMarkupGroupData ended");
  }
  //While clicking Edit button load respective data to entry page
    /* istanbul ignore next */
  updateRecord(updateRowData: any) {
    this._depMarkupGroupService.getdepartmentMarkupGroupIdFromList(updateRowData.departmentMarkupGroupInfoId);
  }

}
