<div class="wrap">
  <div class="filter-div">
    <div class="org-container">
      <form [formGroup]="_searchform">
        <mat-form-field appearance="legacy" class="customform">
          <mat-label>Corporate Brand</mat-label>
          <input type="text" id="Searchcorporatebrand" matInput [matAutocomplete]="autoCB"
            formControlName="corporateBrandName" [(ngModel)]="corporateBrandName"
            (ngModelChange)="filterItemCorporateBrand($event)">
          <mat-autocomplete autoActiveFirstOption #autoCB="matAutocomplete">
            <mat-option *ngFor="let CBname of corporateBrandFilterIGList" [value]="CBname.corporateBrandCodeName"
              [matTooltipDisabled]="CBname.corporateBrandName.length<8" matTooltip="{{CBname.corporateBrandCode}}&nbsp;{{CBname.corporateBrandName}}"
              matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
              {{CBname.corporateBrandCode}} {{CBname.corporateBrandName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="customform">
          <mat-label>Division</mat-label>
          <input type="text" id="Searchdivision" matInput [matAutocomplete]="autoDN" formControlName="division"
            [(ngModel)]="division" (ngModelChange)="filterItemDN($event)">
          <mat-autocomplete autoActiveFirstOption #autoDN="matAutocomplete">
            <mat-option *ngFor="let division of divisionFilterList" [value]="division.divisionCodeName"
              [matTooltipDisabled]="division.divisionName.length<8" matTooltip="{{division.divisionCode}}&nbsp;{{division.divisionName}}"
              matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
              {{division.divisionCode}} {{division.divisionName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="customform">
          <mat-label>Section</mat-label>
          <input type="text" id="Searchsectionname" matInput [matAutocomplete]="autoSN" formControlName="sectionName"
            [(ngModel)]="sectionName" (ngModelChange)="filterItemSN($event)">
          <mat-autocomplete autoActiveFirstOption #autoSN="matAutocomplete">
            <mat-option *ngFor="let section of sectionnameFilterList" [value]="section.sectionCodeName"
              [matTooltipDisabled]="section.sectionName.length<8" matTooltip="{{section.sectionCode}}&nbsp;{{section.sectionName}}"
              matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
              {{section.sectionCode}} {{section.sectionName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="statusclass">
          <mat-label>Status</mat-label>
          <mat-select id="Searchstatus" formControlName="status" [(ngModel)]="status">
            <mat-option value="all">
              All
            </mat-option>
            <mat-option value="active">
              Active
            </mat-option>
            <mat-option value="inactive">
              Inactive
            </mat-option>
            <mat-option value="draft">
              Draft
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-button class="add-button-container mat-raised-button mat-primary ml-2" 
         id="searchClear" type="submit" (click)="onSearchClear()" >Clear</button>
      </form>
    </div>
    <div class="clearfix"></div>
    <div class="tblleft">
      <app-sectionlist [sectionSearch]=_searchform.value></app-sectionlist>
    </div>
    <div class="frmright">
      <app-sectionform (childButtonEvent)="receivedChildHandler($event)"></app-sectionform>
    </div>
  </div>