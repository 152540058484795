import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonValidationService } from 'src/app/services/organization/commonutility/commonValidation.service';
import { CorporatebrandService } from 'src/app/services/organization/corporatebrand/corporatebrand.service';
import { CustomerGroupService } from 'src/app/services/organization/customerGroup/customer-group.service';
import { AccountService } from '../../../../shared/services/account.service'
import { Subscription } from 'rxjs';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { UserAccess } from 'src/app/models/userAccess';
import { ModulePermission } from 'src/app/models/enum';
interface CorporateBrandNameList {
  corporateBrandId: any;
  corporateBrandName: any;
  corporateBrandCodeName: any;
}
@Component({
  selector: 'app-customergroupform',
  templateUrl: './customergroupform.component.html',
  styleUrls: ['./customergroupform.component.css']
})
export class CustomergroupformComponent implements OnInit {
  @Output() childButtonEvent = new EventEmitter();
  ddCorporateBrandNameCGList: CorporateBrandNameList[];
  corporateBrandMasterCGList: any[] = [];
  corporateBrandFilterCGList: any[] = [];
  validationMessages: any;
  updatecustomerGroupForm: any;
  isCustomerGroupEdit: any = false;
  isCustomerGroupEditCB: any = 'legacy';
  isDisableActive: any = true;
  IndexForm: any;
  subscription: Subscription;
  userrole: boolean;
  users: any;
  constructor(
    public _customerGroupService: CustomerGroupService,
    private _corporatebrandService: CorporatebrandService,
    private toastr: ToastrService,
    public _commonvalidationService: CommonValidationService,
    private applicationInsightService: ApplicationInsightService,
    public account: AccountService,
    public _userAccess: UserAccess
  ) {
    this.updatecustomerGroupForm = Object.assign({ active: true, draft: true });
    this.users = JSON.parse(localStorage.getItem("users") || "[]")
  }
  ngOnInit(): void {
    this.userrole = this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this._customergroupform.controls.lastUpdatedBy.setValue(JSON.parse(localStorage.getItem("UserInfo")).Email || "[]");
    this.validationMessages = this._commonvalidationService.validationMsgs;
    this._corporatebrandService.getCorporateBrandList().subscribe((data: any) => {
      this.applicationInsightService.logEvent("CustomerGroup form CorporateBrand listing Started");
      this.ddCorporateBrandNameCGList = this.corporateBrandMasterCGList = this.corporateBrandFilterCGList = data.getCorporateBrandAll.filter(object => {
        return object['active'] !== false;
      });
      this.corporateBrandFilterCGList = this.corporateBrandMasterCGList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
      this.childButtonEvent.emit(data.getCorporateBrandAll);
      this.applicationInsightService.logEvent("CustomerGroup form CorporateBrand listing Ended");
    });
    this.subscription =
      this._customerGroupService.customerGroupId$.subscribe(
        customerGroupId => {
          this.updateCustomerGroup(customerGroupId)
        });
  }
  public ngOnDestroy(): void {
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }
  displayTextCB(corporateBrandId: string) {
    if (corporateBrandId === null || corporateBrandId === "")
      return null;
    else if (this.ddCorporateBrandNameCGList != undefined && corporateBrandId != null)
      return this.ddCorporateBrandNameCGList.
        find(x => x.corporateBrandId == corporateBrandId).corporateBrandCodeName;
  }
  filterItemCorporateBrand(filterKey: string) {
    this.applicationInsightService.logEvent("CustomerGroup form filtering CorporateBrand Started");
    let newCBList: any[] = [];
    newCBList = this.corporateBrandMasterCGList;
    if (filterKey != null && filterKey != "") {
      this.corporateBrandFilterCGList = newCBList.filter(cb => cb.corporateBrandName.toLowerCase().includes(filterKey.toString().toLowerCase()));
      this.corporateBrandFilterCGList = this.corporateBrandFilterCGList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
    } else
      this.corporateBrandFilterCGList = this.corporateBrandMasterCGList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
    this.applicationInsightService.logEvent("CustomerGroup form filtering CorporateBrand Ended");
  }
  updateCustomerGroup(customerGroupId: any) {
    this.applicationInsightService.logEvent("CustomerGroup Edit GetCustomerGroupId Started");
    this.isCustomerGroupEdit = true;
    this.isDisableActive = true;
    this._customerGroupService.getCustomerGroupById(customerGroupId)
      .subscribe((data: any) => {
        this.isDisableActive = false;
        this.updatecustomerGroupForm = data.customerGroup;
        this.isCustomerGroupEditCB = this.updatecustomerGroupForm.active == true ? 'fill' : 'legacy';
      });
    document.getElementById("scrolltop").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    this.applicationInsightService.logEvent("CustomerGroup Edit GetCustomerGroupId Ended");
  }
  onSubmit() {
    this._customergroupform.controls['corporateBrandId'].updateValueAndValidity();
    this._customergroupform.controls['customerGroupCode'].updateValueAndValidity();
    this._customergroupform.controls['customerGroupName'].updateValueAndValidity();
    this._customergroupform.controls['customerGroupSortOrder'].updateValueAndValidity();
    console.log("Customer Group Save: " + JSON.stringify(this._customergroupform.value))
    if (this._customergroupform.valid) {
      this._customergroupform.controls.customerGroupName.setValue(this._customergroupform.value.customerGroupName.trim());
      if (this._customergroupform.value.customerGroupId == undefined || this._customergroupform.value.customerGroupId == 0 || this._customergroupform.value.customerGroupId == null) {
        this._customerGroupService.createCustomerGroup(this._customergroupform.value).subscribe(data => {

          this.toastr.success(data.message, "", { timeOut: 10000 });
          this.onClear();
          this._customerGroupService.refreshCustomerGroupList();
        },
          error => { this.toastr.error(error, "", { timeOut: 10000 }) }
        )
      }
      else {
        this._customerGroupService.updateCustomerGroup(this._customergroupform.value).subscribe(data => {

          this.toastr.success(data.message, "", { timeOut: 10000 });
          this.onClear();
          this._customerGroupService.refreshCustomerGroupList();
        },
          error => { this.toastr.error(error, "", { timeOut: 10000 }) }
        )
      }
    }
  }

  onClear() {
    this.applicationInsightService.logEvent("CustomerGroup form clear Started");
    //Clearing validation 
    this._customergroupform.controls.corporateBrandId.clearValidators();
    this._customergroupform.controls.customerGroupCode.clearValidators();
    this._customergroupform.controls.customerGroupName.clearValidators();
    this._customergroupform.controls.customerGroupSortOrder.clearValidators();
    //Clearing control values 
    this._customergroupform.controls.corporateBrandId.reset();
    this._customergroupform.controls.customerGroupCode.reset();
    this._customergroupform.controls.customerGroupName.reset();
    this._customergroupform.controls.customerGroupSortOrder.reset();
    this._customergroupform.controls.customerGroupId.reset();
    this._customergroupform.controls.draft.reset();
    this._customergroupform.controls.customerGroupSortOrder.reset();
    this._customergroupform.controls.corporateBrandId.setValidators([this._commonvalidationService.acObjectValidator(), Validators.required]);
    this._customergroupform.controls.customerGroupCode.setValidators([Validators.required, Validators.pattern('[0-9][0-9]')]),
      this._customergroupform.controls.customerGroupName.setValidators([Validators.required, Validators.maxLength(50)]);
    this._customergroupform.controls.customerGroupSortOrder.setValidators([Validators.required, Validators.min(1), Validators.max(9999)]);
    this._customergroupform.controls.draft.setValue(true);
    this._customergroupform.controls.active.setValue(true);
    this.isCustomerGroupEdit = false;
    this.isDisableActive = true;
    this.isCustomerGroupEditCB = 'legacy';
    this.applicationInsightService.logEvent("CustomerGroup form clear Ended");
  }

  _customergroupform: FormGroup = new FormGroup({
    customerGroupId: new FormControl(''),
    corporateBrandId: new FormControl('', [this._commonvalidationService.acObjectValidator(), Validators.required]),
    customerGroupCode: new FormControl('', [Validators.required, Validators.pattern('[0-9][0-9]')]),
    customerGroupSortOrder: new FormControl('', [Validators.required, Validators.min(1), Validators.max(9999)]),
    customerGroupName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    active: new FormControl(true),
    lastUpdatedBy: new FormControl(),
    draft: new FormControl(true)
  })
}
