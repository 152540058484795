


import { Component, OnInit, ViewChild, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FiscalcountryService } from '../../../../services/country/fiscalcountry/fiscalcountry.service';
import { fiscalCountryModel } from '../../../../models/country/fiscalcountry/fiscalCountryModel'
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
@Component({
  selector: 'app-fiscalcountrylist',
  templateUrl: './fiscalcountrylist.component.html',
  styleUrls: ['./fiscalcountrylist.component.css']
})
export class FiscalcountrylistComponent implements OnInit, OnChanges {
  @Output() childButtonEvent = new EventEmitter();
  @Input() FiscalCountrySearch: any;


  public dataSource: MatTableDataSource<fiscalCountryModel>;
  displayedColumns = [
    'geographicalCountryName',
    'geographicalCountryAlpha2ISOCode',
    'fiscalCountryCode',
    'lastUpdatedTime',
    'Actions'
  ];

  constructor(
    public _fiscalcountryService: FiscalcountryService,
    private router: Router,
    public route: ActivatedRoute,
    private applicationInsightService: ApplicationInsightService,
    private toastr: ToastrService
  ) { }

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<fiscalCountryModel>;


  // MatPaginator Inputs
  pageNumber: any = 1;
  length: any;
  pageIndex: any;
  pageSize: any = 15;
  sortByColumnName: any = "lastUpdatedTime";
  sortByOrder: any = "asc";
  pageEvent: PageEvent;
  errorMsg: any;
  customDateTime: any = "";
  emitter: number = 1;
  getpagesizeoptions(): number[] {
    if (this.length > 25)
      return [10, 20, 25, this.length];
    else if (this.length > 20)
      return [10, 20, this.length];
    else if (this.length > 10)
      return [10, this.length];
    return null;

  }


  ngOnInit(): void {
    this._fiscalcountryService.refreshTableFiscalCountry$.subscribe(
      refreshTableFiscalCountry => {
        this.emitter = 1;
        this.bindFiscalCountryData();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.pageNumber = 1;
    this.bindFiscalCountryData();
  }

  sortData(sort: Sort) {
    this.applicationInsightService.logEvent("FiscalCountryList Sort Started");
    this.sortByColumnName = sort.active;
    this.sortByOrder = sort.direction;
    this.bindFiscalCountryData();
    this.applicationInsightService.logEvent("FiscalCountryList Sort Ended");
  }

  bindFiscalCountryData() {
    this.applicationInsightService.logEvent("FiscalCountryList bindFiscalCountryData Started");
    let FiscalCountryList: any[] = [];
    let active: any;
    let draft: any;
    let filterData: any[] = [];
    if (this.FiscalCountrySearch != null) {
      if (this.FiscalCountrySearch.status === "draft") { draft = true; active = ""; }
      if (this.FiscalCountrySearch.status === "inactive") { active = false; draft = false; }
      if (this.FiscalCountrySearch.status === "active") { active = true; draft = false; }
      if (this.FiscalCountrySearch.status === "all") { active = ""; draft = ""; }
    }
    this._fiscalcountryService.getFiscalCountryList().subscribe((data: any) => {
      //filtering      
      FiscalCountryList = data.fiscalCountry;
      if (FiscalCountryList != null) {
        filterData = FiscalCountryList.filter(x => x.geographicalCountryName.toString().toLowerCase().includes(this.FiscalCountrySearch.fiscalCountryName.toString().toLowerCase()))
          .filter(x => x.geographicalCountryAlpha2ISOCode.toString().toLowerCase().includes(this.FiscalCountrySearch.fiscalCountryISOCode.toString().toLowerCase()))
          .filter(x => x.fiscalCountryCode.toString().toLowerCase().includes(this.FiscalCountrySearch.fiscalCountryCode.toString().toLowerCase()))
          .filter(x => x.active.toString().includes(active.toString()))
          .filter(x => x.draft.toString().includes(draft.toString()));
        this.dataSource = new MatTableDataSource(filterData);
        this.sort.active = this.sortByColumnName;
        this.sort.direction = this.sortByOrder;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.length = this.dataSource.data.length;
        if (this.emitter == 1) {
          this.emitter = 2;
        this.childButtonEvent.emit(data.fiscalCountry);
        }
      }
    },
      error => {
        //this.errorMsg = error;
        //this.toastr.error(error);
      });
    this.applicationInsightService.logEvent("FiscalCountryList bindFiscalCountryData Started");
  }

  //While clicking Edit button load respective data to entry page
  updateRecord(updateRowData: any) {
    this._fiscalcountryService.getFiscalCountryIdFromList(updateRowData.fiscalCountryId);

  }

  public GetFiscalCountryDataWithFilters(event?: PageEvent) {
    this.pageSize = event.pageSize;
    this.bindFiscalCountryData();
    return event;
  }

}
