import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CorporatebrandService } from 'src/app/services/organization/corporatebrand/corporatebrand.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CorporatebrandlistComponent } from '../../corporatebrandlist/corporatebrandlist.component';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';

@Component({
  selector: 'corporatebrand',
  templateUrl: './corporatebrand.component.html',
  styleUrls: ['./corporatebrand.component.css']
})

export class CorporatebrandComponent implements OnInit {
  @ViewChild('corporatebandlist') public corporatebandDropDown: CorporatebrandlistComponent;
  title = "Corporate Brand"
  /* ---------------declarations--------- */
  corporateBrandMasterList: any[] = [];
  corporateBrandFilterList: any[] = [];
  corporateBrandName: any = "";
  status: any = "active";
  errorMsg: any;
  constructor(public _corporatebrandService: CorporatebrandService,
    public route: ActivatedRoute,
    private applicationInsightService: ApplicationInsightService,
    private titleService: Title) { }

  ngOnInit(): void { this.titleService.setTitle(this.title); }

  receivedChildHandler(obj) {
    this.corporateBrandFilterList = this.corporateBrandMasterList = obj.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
  }

  filterItemCorporateBrand(filterKey: any) {
    if (isNaN(filterKey)) {
      this.corporateBrandFilterList = this.corporateBrandMasterList.filter(cb => cb.corporateBrandName.toLowerCase().includes(filterKey.toLowerCase()));
      this.corporateBrandFilterList = this.corporateBrandFilterList.sort((a, b) => (a.corporateBrandCode > b.corporateBrandCode ? 1 : -1));
    } else {
      this.corporateBrandFilterList = this.corporateBrandMasterList.filter(cb => cb.corporateBrandCode.toLowerCase().includes(filterKey.toLowerCase()));
      this.corporateBrandFilterList = this.corporateBrandFilterList.sort((a, b) => (a.corporateBrandCode > b.corporateBrandCode ? 1 : -1));
    }
  }
  //clear button click event
  onSearchClear() {
    this.applicationInsightService.logEvent("Corporatebrand Search clear event started");
    this._searchform.controls['status'].setValue("active");
    this._searchform.controls['corporateBrandName'].setValue("");
    this._searchform.controls['corporateBrandCodeName'].setValue("");
    this.filterItemCorporateBrand("");
    this.applicationInsightService.logEvent("Corporatebrand Search clear event ended");
  }
  //corporatebrand  Search Form
  _searchform: FormGroup = new FormGroup({
    corporateBrandName: new FormControl(''),
    corporateBrandCodeName: new FormControl(''),
    status: new FormControl(),
  });
}
