<div class="wrap">
  <div class="filter-div">
    <div class="org-container">
      <form [formGroup]="_searchform">
        <mat-form-field appearance="legacy" class="customform">
          <mat-label>Corporate Brand</mat-label>

          <input type="text" id="Searchcorporatebrand" matInput [matAutocomplete]="autoCB"
                 formControlName="corporateBrandName" [(ngModel)]="corporateBrandName" (ngModelChange)="filterItemCorporateBrand($event)">

          <mat-autocomplete autoActiveFirstOption #autoCB="matAutocomplete">
            <mat-option *ngFor="let CBname of corporateBrandFilterCGList" [value]="CBname.corporateBrandCodeName" [matTooltipDisabled]="CBname.corporateBrandName.length<8"  matTooltip="{{CBname.corporateBrandCode}}&nbsp;{{CBname.corporateBrandName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
              {{CBname.corporateBrandCode}}  {{CBname.corporateBrandName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="customform">
          <mat-label>Customer Group</mat-label>
          <input id="Searchcustomergroupname" matInput [matAutocomplete]="autoGN"
                 formControlName="customerGroupName" [(ngModel)]="customerGroupName"
                 (ngModelChange)="filterItemGNAM($event)">
          <mat-autocomplete autoActiveFirstOption #autoGN="matAutocomplete">
            <mat-option *ngFor="let customerGroupNM of customerGroupNameFilterCGList" [value]="customerGroupNM.customerGroupCodeName"  [matTooltipDisabled]="customerGroupNM.customerGroupName.length<8"  matTooltip="{{customerGroupNM.customerGroupCode}}&nbsp;{{customerGroupNM.customerGroupName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
              {{customerGroupNM.customerGroupCode}} {{customerGroupNM.customerGroupName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <!-- <mat-form-field appearance="legacy" class="customform">
          <mat-label>Division Code</mat-label>
          <input type="text" id="SearchdivisionCode" matInput [matAutocomplete]="autoCGN"
                 formControlName="divisionCode" [(ngModel)]="divisionCode"
                 (ngModelChange)="filterItemGNUM($event)" min="0">
          <mat-autocomplete autoActiveFirstOption #autoCGN="matAutocomplete">
            <mat-option *ngFor="let division of divisionCodeFilterCGList" [value]="division.divisionCode">
              {{division.divisionCode}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field> -->
        <mat-form-field appearance="legacy" class="customform">
          <mat-label>Division</mat-label>
          <input type="text" id="Searchdivisionname" matInput [matAutocomplete]="autoDiN"
                 formControlName="divisionName" [(ngModel)]="divisionName"
                 (ngModelChange)="filterItemDivisionName($event)">
          <mat-autocomplete autoActiveFirstOption #autoDiN="matAutocomplete">
            <mat-option *ngFor="let DiName of divisionNameFilterList" [value]="DiName.divisionCodeName" [matTooltipDisabled]="DiName.divisionName.length<8"  matTooltip="{{DiName.divisionCode}}&nbsp;{{DiName.divisionName}}" matTooltipPosition="left" matTooltipClass="my-custom-tooltip">
              {{DiName.divisionCode}} {{DiName.divisionName}} 
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="statusclass">
          <mat-label>Status</mat-label>
          <mat-select id="Searchstatus" formControlName="status" [(ngModel)]="status">
            <mat-option value="all">
              All
            </mat-option>
            <mat-option value="active">
              Active
            </mat-option>
            <mat-option value="inactive">
              Inactive
            </mat-option>
            <mat-option value="draft">
              Draft
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-button class="add-button-container mat-raised-button mat-primary ml-2" 
        id="searchClear" type="submit" (click)="onSearchClear()" >Clear</button>
      </form>
    </div>
    <div class="clearfix"></div>
    <div class="tblleft">
    <app-divisionlist (childButtonEvent)="divisionDropDownList($event)" [divisionSearch]=_searchform.value></app-divisionlist>
  </div>
  <div class="frmright">
    <app-divisionform (childButtonEvent)="receivedChildHandler($event)"></app-divisionform>
  </div>
</div>
