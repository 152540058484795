<div class="mat-elevation-z8 data-table">
  <table id="tblFiscalCountry" [dataSource]="dataSource" mat-table class="full-width-table" matSort (matSortChange)="sortData($event)" matSortActive="lastUpdatedTime" matSortStart="desc"
         aria-label="Elements">

    <!-- FiscalCountryName Column -->
    <ng-container matColumnDef="geographicalCountryName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="fiscalCountryName">Country Name</th>
      <td mat-cell *matCellDef="let row"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.geographicalCountryName}}
      </td>
    </ng-container>



    <!-- ISO Column -->
    <ng-container matColumnDef="geographicalCountryAlpha2ISOCode">
      <th matSort (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header id="fiscalCountryISOCode">ISO</th>
      <td mat-cell *matCellDef="let row"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.geographicalCountryAlpha2ISOCode}}
      </td>
    </ng-container>
    <!-- FiscalCountryCode Column -->
    <ng-container matColumnDef="fiscalCountryCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="fiscalCountryCode">Fiscal Country Code</th>
      <td mat-cell *matCellDef="let row"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.fiscalCountryCode}}
      </td>
    </ng-container>
    <!-- A Column -->
    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="active">Active</th>
      <td mat-cell *matCellDef="let row"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.active === true ? 'Y' : 'N'}}
      </td>
    </ng-container>

    <!-- Draft Column -->
    <ng-container matColumnDef="isApproved">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="isApproved">Approved</th>
      <td mat-cell *matCellDef="let row"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.isApproved === true ? 'Y' : 'N'}}
      </td>
    </ng-container>



    <!-- LastUpdate Column -->
    <ng-container matColumnDef="lastUpdatedTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="lastUpdatedTime">Updated date</th>
      <td mat-cell *matCellDef="let row"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">

        <span *ngIf="!customDateTime">{{ row.lastUpdatedTime | customDateTime }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef id="Actions">Edit</th>
      <td mat-cell *matCellDef="let row"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        <mat-icon id="btnEdit" color="primary" (click)="updateRecord(row)" matTooltip="Edit" matTooltipPosition="right"
                  style="background-color: rgb(255 255 255 / 0%);cursor: pointer;">drive_file_rename_outline</mat-icon>
      </td>
    </ng-container>
   <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="8" style="
        text-align: center;">
        No records found
      </td>
    </tr>

  </table>
  <div id="main-wrap">

    <section id="sidebar" style="background-color: #00558d;color:#fff">

      <div class="example-button-row">
        <div class="example-flex-container">

          <button mat-icon-button style="width:25%;" aria-label="Datatable color code">

            <mat-icon>
              <span class="material-icons"
                    style="color:#fff; text-shadow: 0px 1px 0 #000, 1px 1px 0 #000, 1px 1px 0 #000, 1px 1px 0 #000;">
                stop
              </span>
            </mat-icon>
            Active
          </button>
          <button mat-icon-button style="width:27%;" aria-label="Datatable color code">

            <mat-icon>
              <span class="material-icons"
                    style="color: #cc8985;text-shadow: 0px 1px 0 #000, 1px 1px 0 #000, 1px 1px 0 #000, 1px 1px 0 #000;">
                stop
              </span>
            </mat-icon>
            Inactive
          </button>
          <button mat-icon-button style="width:25%;" aria-label="Datatable color code">

            <mat-icon>
              <span class="material-icons"
                    style="color: #e4de8e;text-shadow: 0px 1px 0 #000, 1px 1px 0 #000, 1px 1px 0 #000, 1px 1px 0 #000;">
                stop
              </span>
            </mat-icon>
            Draft
          </button>

          <button mat-icon-button style="width:23%;" aria-label="Datatable color code">

            <mat-icon>
              <span class="material-icons"
                    style="color: #f59e73;text-shadow: 0px 1px 0 #000, 1px 1px 0 #000, 1px 1px 0 #000, 1px 1px 0 #000;">
                stop
              </span>
            </mat-icon>
            First Draft
          </button>
        </div>
      </div>
    </section>
    <mat-paginator #paginator id="tblPaginator" class="customcolor" style="color: #fff;" [(length)]="length" [(pageSize)]="pageSize"
                   [pageSizeOptions]="getpagesizeoptions()" (page)="pageEvent = GetFiscalCountryDataWithFilters($event)"
                   showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
<style>
  ::ng-deep .customcolor .mat-select-value {
    color: #fff !important;
  }

  ::ng-deep .customcolor .mat-select-arrow {
    color: #fff !important;
  }

  ::ng-deep .mat-icon-button {
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
    font-size: 13px !important;
    font-weight: 500 !important;
  }
</style>
