<div class="data-table" class="org-table">

  <table [dataSource]="dataSource" id="tblDivision" #table mat-table class="full-width-table" (matSortChange)="sortData($event)" matSort aria-label="Elements">

    <ng-container matColumnDef="divisionId">
      <th style="display: none;" mat-header-cell *matHeaderCellDef mat-sort-header id="divisionId">Division ID</th>
      <td style="display: none;" mat-cell *matCellDef="let row"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.divisionId}}
      </td>
    </ng-container>

    <ng-container matColumnDef="corporateBrandId" style="display: none;">
      <th style="display: none;" mat-header-cell *matHeaderCellDef mat-sort-header id="corporateBrandId">Corporate Brand ID</th>
      <td style="display: none;" mat-cell *matCellDef="let row"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.corporateBrandId}}
      </td>
    </ng-container>

    <ng-container matColumnDef="customerGroupId" style="display: none;">
      <th style="display: none;" mat-header-cell *matHeaderCellDef mat-sort-header id="customerGroupId">Customer Group Id</th>
      <td style="display: none;" mat-cell *matCellDef="let row"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.customerGroupId}}
      </td>
    </ng-container>

    <ng-container matColumnDef="divisionSortOrder">
      <th matSort (matSortChange)="sortData($event)" mat-header-cell *matHeaderCellDef mat-sort-header id="divisionSortOrder">Sort Order</th>
      <td mat-cell *matCellDef="let row" id="custom-col100"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.divisionSortOrder}}
      </td>
    </ng-container>
    <ng-container matColumnDef="corporateBrandName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="corporateBrandName">Corporate Brand </th>
      <td mat-cell *matCellDef="let row"  [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')"  [matTooltipDisabled]="row.corporateBrandName.length<15" matTooltip={{row.corporateBrandName}} matTooltipPosition="right" matTooltipClass="tooltip">
        {{row.corporateBrandCode}} {{row.corporateBrandName}}<span *ngIf="row.corporateBrandName.length>15">...</span></td>
    </ng-container>

    <ng-container matColumnDef="customerGroupName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="customerGroupName">Customer Group</th>
      <td mat-cell *matCellDef="let row" [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')" matTooltip={{row.customerGroupName}} [matTooltipDisabled]="row.customerGroupName==null || row.customerGroupName=='' || row.customerGroupName.length<15" matTooltipPosition="left"  matTooltipClass="my-custom-tooltip">
        {{row.customerGroupCode}} {{row.customerGroupName}}</td>
    </ng-container>

    <ng-container matColumnDef="divisionCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="divisionCode"> Division Code</th>
      <td mat-cell *matCellDef="let row" id="custom-col100"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.divisionCode}}
      </td>
    </ng-container>

    <ng-container matColumnDef="divisionName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="divisionName"> Division</th>
      <td mat-cell *matCellDef="let row" 
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')" [matTooltipDisabled]="row.divisionName.length<15" matTooltip={{row.divisionName}} matTooltipPosition="before">
          {{row.divisionName}}
      </td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="active"> Active </th>
      <td mat-cell *matCellDef="let row" aligncenter
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.active === true ? 'Y' : 'N'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="draft" style="display: none;">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="draft">Draft</th>
      <td mat-cell *matCellDef="let row" aligncenter
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        {{row.draft === true ? 'Y' : 'N'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="lastUpdatedTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header id="lastUpdatedTime">Updated Date</th>
      <td mat-cell *matCellDef="let row"
          [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
        <span *ngIf="!customDateTime">{{ row.lastUpdatedTime | customDateTime }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
     <th mat-header-cell *matHeaderCellDef id="Actions"><span *ngIf="userrole">Edit | </span>View</th>
    <td mat-cell *matCellDef="let row" id="custom-col100"
      [ngClass]="row.draft==true && row.isPublished==0 ? 'FirstTimeDraftBg' : row.draft==true ? 'DraftBg': ( row.active==true ? 'ActiveBg' : 'InactiveBg')">
      <a id="btnEdit" *ngIf="userrole" color="primary" (click)="updateRecord(row)" matTooltip="Edit" matTooltipPosition="right"
        class="org-edit">Edit | </a>
        <a id="btnView"(click)="openDialog(row)" matTooltip="View Details" matTooltipPosition="right"
        class="org-edit">View</a>
    </td>
  </ng-container>
 <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell aligncenter" colspan="8">No records found</td>
  </tr>
</table>
<div id="main-wrap">
  <section id="sidebar">
    <div class="example-button-row">
      <div class="example-flex-container indicator">
        <button mat-icon-button aria-label="Datatable color code">
          <mat-icon><span class="material-icons activeindicator">
              stop
            </span></mat-icon>
          Active
        </button>
        <button mat-icon-button aria-label="Datatable color code">
          <mat-icon><span class="material-icons inactiveindicator">
              stop
            </span></mat-icon>
          Inactive
        </button>
        <button mat-icon-button aria-label="Datatable color code">
          <mat-icon><span class="material-icons draftindicator">
              stop
            </span></mat-icon>
          Draft
        </button>
        <button mat-icon-button aria-label="Datatable color code">
          <mat-icon><span class="material-icons firstdraftindicator">
              stop
            </span></mat-icon>
          First Draft
        </button>
      </div>
    </div>
  </section>
  <mat-paginator #paginator id="tblPaginator" [(length)]="length" [(pageSize)]="pageSize"
    [pageSizeOptions]="getpagesizeoptions()" (page)="pageEvent = GetdivisionDatawithfilters($event)" showFirstLastButtons>
  </mat-paginator>
</div>
</div>
