import { Component, OnInit, ViewChild, ElementRef, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table'; import { DepartmentService } from 'src/app/services/organization/department/department.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DepartmentviewComponent } from './departmentview.component';
import { constant } from 'src/app/shared/const/constant';
import { isNumeric } from 'rxjs/internal-compatibility';
import { CorporatebrandService } from 'src/app/services/organization/corporatebrand/corporatebrand.service';
import { ModulePermission } from '../../../../models/enum';
export interface iDepartment {
  departmentId: any;
  departmentCode: any;
  departmentName: any;
  corporateBrandId: any;
  corporateBrandNumber: any;
  corporateBrandName: any;
  divisionCode: any;
  divisionName: any;
  divisionId: any;
  sectionCode: any;
  sectionName: any;
  sectionId: any;
  garmentGroupCode: any;
  garmentGroupName: any;
  garmentGroupId: any;
  customerGroupCode: any;
  customerGroupName: any;
  customerGroupId: any;
  markupGroupCode: any;
  markUpGroupName: any;
  lastUpdatedTime: any;
  active: any;
  draft: any;
}


@Component({
  selector: 'app-departmentlist',
  templateUrl: './departmentlist.component.html',
  styleUrls: ['./departmentlist.component.css']
})

export class DepartmentlistComponent implements OnInit {
  @Input() departmentSearch;
  @Output() childButtonEvent = new EventEmitter();
  dataSource: MatTableDataSource<iDepartment>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  //Tabs for Sub index, Custom customer group,Markup group
  public depSubTab = false;
  public depSubIndexTab = false;
  public depCustomCustomerGroupTab = false;
  depSubIndexList: any;
  active: any;
  draft: any;
  // MatPaginator Inputs
  pageNumber: any = 1;
  length: any;
  pageSize: any = 15;
  sortByColumnName: any = "DepartmentCode";
  sortByOrder: any = "asc";
  Serviceactive: any = true;
  ServiceDraft: any = false;
  // MatPaginator Output
  pageEvent: PageEvent;
  errorMsg: any;
  customDateTime: string;
    subscription: Subscription;
    isdisable: any = true;
  searchParams: any;
  corporateBrandNumber: any;
  corporateBrandFilterList: any;
  userrole: boolean;
  users: any;
  getpagesizeoptions(): number[] {
    if (this.length > 25)
      return [10, 20, 25, this.length];
    else if (this.length > 20)
      return [10, 20, this.length];
    else if (this.length > 10)
      return [10, this.length];
    return null;
  }
  displayedColumns = ['corporateBrandCode','departmentCode', 'sectionCode','divisionCode','subIndexCodeName', 'garmentGroupCode' ,'lastUpdatedTime', 'endSeason','Actions'];
  matDialogRef: MatDialogRef<DepartmentviewComponent>;
  department: string = "";
  constructor(public _departmentService: DepartmentService,
    public _corporatebrandService: CorporatebrandService,
    private toastr: ToastrService,public dialog: MatDialog,
    private applicationInsightService:ApplicationInsightService) { this.users = JSON.parse(localStorage.getItem("users") || "[]") }
  BindDepartmentData() {
    this.applicationInsightService.logEvent("Bind DepartmentData started");
    let filterData:any[]=[]; 
    if (this.departmentSearch != null) {
      if (this.departmentSearch.status == "active") { this.Serviceactive = true; this.ServiceDraft = false; }
      if (this.departmentSearch.status == "inactive") { this.Serviceactive = false; this.ServiceDraft = false; }
      if (this.departmentSearch.status == "draft") { this.ServiceDraft = true; this.Serviceactive = null; }
      if (this.departmentSearch.status == "all") { this.ServiceDraft = null; this.Serviceactive = null; }
      this.searchParams = this.departmentSearch;
      this.searchParams.departmentCode = this.departmentSearch.departmentCode == null ? "" : this.departmentSearch.departmentCode;
      this.searchParams.departmentCode = this.searchParams.departmentCode.toString();
      if(isNumeric(this.departmentSearch.corporateBrandName)){
        this.corporateBrandNumber=this.departmentSearch.corporateBrandName; 
        this.departmentSearch.corporateBrandName='';
        this.departmentSearch.corporateBrandName = (this.corporateBrandFilterList.filter(cb => cb.corporateBrandCode.toString().toLowerCase().includes(this.corporateBrandNumber.toString())))[0].corporateBrandName;
      }else{
      this.searchParams.corporateBrandName = this.departmentSearch.corporateBrandName == null ? "" : this.departmentSearch.corporateBrandName;
      }
      this.searchParams.departmentName = this.departmentSearch.departmentName == null ? "" : this.departmentSearch.departmentName;
      this.searchParams.divisionName = this.departmentSearch.divisionName == null ? "" : this.departmentSearch.divisionName;
      this.searchParams.section = this.departmentSearch.section == null ? "" : this.departmentSearch.section;
      this.searchParams.garmentGroup = this.departmentSearch.garmentGroup == null ? "" : this.departmentSearch.garmentGroup;
      this.searchParams.subIndex = this.departmentSearch.subIndex == null ? "" : this.departmentSearch.subIndex;
      this.searchParams.markUpGroup = this.departmentSearch.markUpGroup == null ? "" : this.departmentSearch.markUpGroup;
      this.searchParams.pageNumber = this.pageNumber;
      this.searchParams.pageSize = this.pageSize;
      this.searchParams.sortByColumnName = this.sortByColumnName;
      this.searchParams.sortByOrder = this.sortByOrder;
      this.searchParams.active = this.Serviceactive;
      this.searchParams.draft = this.ServiceDraft;
      this.searchParams.seasonNumber = "current";
      this._departmentService.getDepartmentByBrand(this.searchParams).subscribe((data: any) => {
        filterData=data.department;
        for(let i =0 ;i<filterData.length;i++){
          if (filterData[i]?.endSeason?.includes("99")) { 
            filterData[i].endSeason = null; }
        }
        this.dataSource = new MatTableDataSource(filterData);
        this.length = data.totalCount;
        this.sort.active = this.sortByColumnName;
        this.sort.direction = this.sortByOrder;
        this.dataSource.sort = this.sort;
      },
        error => {
          this.dataSource = new MatTableDataSource([]);
          this.errorMsg = error;
        });
    }
    this.applicationInsightService.logEvent("Bind DepartmentData ended");
  }
  public GetDepartmentDatawithfilters(event?: PageEvent) {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.BindDepartmentData();
    return event;
  }
  ngOnInit(): void {
    this.userrole = this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this._corporatebrandService.getCorporateBrandList().subscribe((data: any) => {
      this.corporateBrandFilterList = data.getCorporateBrandAll.filter(object => {
       return object['active'] !== false;
     })
  });
    this.subscription = this._departmentService.refreshTableDepartment$.subscribe(
      x => {
        this.BindDepartmentData();
      });
  }
  ngOnChanges(changes: SimpleChanges) {
    this.pageNumber = 1;
    this.BindDepartmentData();
  }
  public ngOnDestroy(): void {
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }
  updateDepartment(updateRowRecord: any) {
    this.childButtonEvent.emit()
    this._departmentService.getdepartmentRowFromList(updateRowRecord.departmentId);
  }
  sortData(sort: Sort) {
    this.applicationInsightService.logEvent("Sorting and Bind DepartmentData started");
    this.sortByColumnName = sort.active != null ? sort.active.charAt(0).toUpperCase() + sort.active.slice(1) : sort.active;
    this.sortByOrder = (sort.direction == null || sort.direction == "") ? "desc" : sort.direction;
    this.BindDepartmentData();
    this.applicationInsightService.logEvent("Sorting and Bind DepartmentData ended")
  }
      openDialog(row: any) {
        let rowData:any;
      this._departmentService.getDepartmentById(row.departmentId).subscribe((data: any) => {
        this.matDialogRef = this.dialog.open(DepartmentviewComponent, {
          data: { department: data.department,rowData:row },
          disableClose: false
        });
        },
        error => { this.toastr.error(error, "", { timeOut: constant.toasterTimeout }) })
      this.matDialogRef.afterClosed().subscribe(res => {
        if ((res == true)) {
          this.department = "";
        }
      });
    }
}




