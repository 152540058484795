import { Injectable } from '@angular/core';
import { AbstractControl,  ValidatorFn } from '@angular/forms';

    @Injectable(
    {
    providedIn: 'root'
    })

    export class CommonValidationService 
    {
        
    acObjectValidator(): ValidatorFn 
    {    
        return (control: AbstractControl): { [key: string]: any } | null => 
        {
        if (control.value != null && control.value.length != 0 && typeof control.value === 'string') 
        {
        return { 'invalidAutocomplete': { value: control.value } }
        }
        return null  /* valid option selected */
        }
    }
    
    public validationMsgs = 
    {
    'autoCompleteControl':
      [{ type:'invalidAutocomplete', message: 'Please select a valid input' },
    ]
    }
}