import { Pipe, PipeTransform } from '@angular/core';
   import { DatePipe } from '@angular/common';
   
   @Pipe({
     name: 'customDate'
     
   })
   export class CustDatePipe extends 
                DatePipe implements PipeTransform {
     transform(value: any, args?: any): any { 
       if(value!=undefined && value!=null && value!=''){  
       var convertDate = new Date(value +'Z');
       return super.transform(convertDate, "yyyy-MM-dd");
       }
       else return null;
     }
   }
  