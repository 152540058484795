<div class="fixed-content mat-elevation-z2 form-border" id="scrolltop">
  <form [formGroup]="_indexform">
    <section class="form-section">
      <h2 mat-dialog-title class="align">{{isIndexEdit === true ? 'Update' : 'New'}} Index</h2>
    </section>
    <div class="add-form-field">
      <mat-form-field [appearance]="isIndexEditCB" >
        <mat-label>Corporate Brand</mat-label>
        <input type="text" id="inpcorporatebrand" matInput [matAutocomplete]="autoCB"
               formControlName="corporateBrandId" (ngModelChange)="filterItemCB($event)"
               [(ngModel)]="updateIndexForm.corporateBrandId" [readonly]="isIndexEditCB=='fill' ?true:false">
        <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
          <div *ngIf="_indexform.controls['corporateBrandId'].hasError(validation.type)">
            {{validation.message}}
          </div>
        </mat-error>
        <mat-autocomplete autoActiveFirstOption #autoCB="matAutocomplete"
                          [displayWith]="displayTextCB.bind(this)">
          <mat-option (onSelectionChange)="(updateIndexForm.indexGroupId!=null || updateIndexForm.indexGroupId!='')?updateIndexForm.indexGroupId='':updateIndexForm.indexGroupId;
                      (updateIndexForm.indexCode!=null && updateIndexForm.indexCode!='')?updateIndexForm.indexCode='':updateIndexForm.indexCode" *ngFor="let CBname of corporateBrandFilterList" [value]="CBname.corporateBrandId">
                      {{CBname.corporateBrandCode}} {{CBname.corporateBrandName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <br />
      <mat-form-field [appearance]="'legacy'">
        <mat-label>Index Group</mat-label>
        <input type="text" id="inpindexgroupname" matInput [matAutocomplete]="autoIG"
               formControlName="indexGroupId" (ngModelChange)="filterItemIG($event)"
               [(ngModel)]="updateIndexForm.indexGroupId">
        <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
          <div *ngIf="_indexform.controls['indexGroupId'].hasError(validation.type)">
            {{validation.message}}
          </div>
        </mat-error>
        <mat-autocomplete autoActiveFirstOption #autoIG="matAutocomplete"
                          [displayWith]="displayTextIG.bind(this)">
          <mat-option *ngFor="let IGname of indexGroupFilterList" [value]="IGname.indexGroupId">
            {{IGname.indexGroupCode}} {{IGname.indexGroupName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <br />
      <mat-form-field appearance="legacy">
        <mat-label>Index</mat-label>
        <input id="inpindexName" formControlName="indexName" matInput [(ngModel)]="updateIndexForm.indexName">
        <mat-error *ngIf="_indexform.controls['indexName'].errors?.maxlength">Maximum 250 charactor allowed</mat-error>
      </mat-form-field>
      <br />
      <mat-form-field [appearance]="isIndexEdit==true ? 'fill': 'legacy'">
        <mat-label>Index Code </mat-label>
        <input type="text" id="inpindexcode" matInput [matAutocomplete]="autoIC" formControlName="indexCode"
               (ngModelChange)="filterItemIC($event)" [(ngModel)]="updateIndexForm.indexCode" [readonly]="isIndexEdit==true ?true:false">
        <mat-error *ngFor="let validation of validationMessages.autoCompleteControl">
          <div *ngIf="_indexform.controls['indexCode'].hasError(validation.type)">{{validation.message}}</div>
        </mat-error>
        <mat-autocomplete autoActiveFirstOption #autoIC="matAutocomplete">
          <mat-option *ngFor="let ICname of ICFilterList" [value]="ICname">
            {{ICname}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <br />
      <mat-form-field [floatLabel]="'legacy'">
        <mat-label>Sort Order</mat-label>
        <input id="inpindexSortOrder" formControlName="indexSortOrder" type="number" min="1"
               [(ngModel)]="updateIndexForm.indexSortOrder" matInput type="number" pattern="^(0|[1-9][0-9]*)$">
        <mat-error *ngIf="_indexform.controls['indexSortOrder'].errors?.max">The range for sort order is 1-9999. </mat-error>
        <mat-error *ngIf="_indexform.controls['indexSortOrder'].errors?.pattern">Only Numeric Values allowed</mat-error>
      </mat-form-field>
      <section class="example-section">
        <mat-checkbox [disabled]="isDisableActive" id="inpactive" formControlName="active" [(ngModel)]="updateIndexForm.active"
                      [checked]="true" class="example-margin width-25" color='primary'>
          Active
        </mat-checkbox>
        <mat-checkbox id="inpdraft" formControlName="draft" [(ngModel)]="updateIndexForm.draft"
                      class="example-margin width-15" color='primary'>Draft</mat-checkbox>
      </section>
      <input type="hidden" id="indexId" formControlName="indexId" [(ngModel)]="updateIndexForm.indexId">
      <br>
      <mat-dialog-actions *ngIf="userrole" style="display: block !important">

        <button [disabled]="(isIndexEdit === true) ?  !_indexform.dirty : false" [ngClass]="(isIndexEdit === true) ? 'mat-raised-button ml-1':'mat-raised-button ml-1 mat-primary'" id="btnSaveUpdate"
                type="submit" (click)="onSubmit()">
          {{isIndexEdit === true ? 'Update' : 'Save'}}
        </button>

        <button class="mat-raised-button mat-primary  ml-1" id="btnClear" (click)="onClear()">Cancel</button>
      </mat-dialog-actions>
      <span *ngIf="!userrole" style="color: #ff0202;font-size: 12px;font-weight: 400;">You can't save changes since you have Read access.</span>
    </div>
  </form>
  <div class="mat-elevation-z8" id="indexheader" [ngClass]="(isIndexEdit === true) ? 'displaycls':'hiddedencls'">    
  <hr class="width-50">  
    <div class="info2">      
      <h2 mat-dialog-title>Sub Index</h2>
      <table>
        <thead>
          <tr style="color:aliceblue ;background-color:  #00558d;">
            <th>Sub Index</th>
          </tr>
        </thead>
        <tbody class="mat-grid-tile">
          <tr *ngFor="let item of subIndexForm">
            <td>&nbsp;&nbsp;{{item.subIndexCode}}</td>
          </tr>
          <tr *ngIf="subIndexForm?.length === 0" style="border:none"><td style="border:none;width: 500px; text-align: center;">No records found</td></tr>
        <tbody>
      </table>
    </div>
  </div>
</div>
<style>
  table,
  td,
  th {
    border-spacing: 0;
    border: #e2e2e2 0.02em solid;
  }

    table th {
       font-family: 'HM Group Ampersand';
      font-size: 15px;
      font-weight: 500;
      background-color:  #dfdfdfdf !important;
      color: #00558d;
    }

  table {
    width: 50%;
    border-collapse: collapse;
    margin-right: 10em;
    margin-left: auto;
  }
</style>
